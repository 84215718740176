import { toInteger } from 'lodash'
import moment, { Moment } from 'moment'
import { pluralize } from './textUtils'

export const formatSecondsToHourMins = (
  seconds = 0,
  showSeconds = false
): string => {
  const duration = moment.duration(seconds * 1000)

  const years = duration.years()
  const yearsStr = years ? `${years} ${pluralize('yr', years)}` : ''
  const months = duration.months()
  const monthsStr = months ? `${months} ${pluralize('mo', months)}` : ''
  const days = duration.days()
  const daysStr = days ? `${days} ${pluralize('day', days)}` : ''
  const hours = duration.hours()
  const hoursStr = hours ? `${hours} ${pluralize('hr', hours)}` : ''
  const minutes = duration.minutes()
  const minsStr = minutes ? `${minutes} ${pluralize('min', minutes)}` : ''
  const secs = duration.seconds()
  const secondsStr = secs ? `${secs} ${pluralize('sec', secs)}` : ''

  if (years) {
    return `${yearsStr} ${monthsStr}`
  }
  if (months) {
    return `${monthsStr} ${daysStr}`
  }
  if (days) {
    return `${daysStr} ${hoursStr}`
  }
  if (hours) {
    return `${hoursStr} ${minsStr}`
  }
  if (minutes) {
    if (showSeconds) {
      return `${minsStr} ${secondsStr}`
    }
    return minsStr
  }
  if (showSeconds && secs) {
    return secondsStr
  }
  return 'NA'
}

export type DateRangeType = '7_DAYS' | '14_DAYS' | '30_DAYS' | 'ALL_TIME'

export const getDateRangeByType = (rangeType: DateRangeType) => {
  switch (rangeType) {
    case '7_DAYS':
      return [moment().subtract(7, 'd'), moment()]

    case '14_DAYS':
      return [moment().subtract(14, 'd'), moment()]

    case '30_DAYS':
      return [moment().subtract(30, 'd'), moment()]

    default:
      return null as unknown as [Moment, Moment]
  }
}

export const getSortedDates = (dateArray: string[]) => {
  return dateArray.sort((a, b) => {
    const momentA = moment(a, 'YYYY-MM-DD')
    const momentB = moment(b, 'YYYY-MM-DD')
    return momentA.diff(momentB)
  })
}

export const getTimestampsForInterval = (
  value: number,
  type: 'weeks' | 'months'
) => {
  const currentDate = moment()
  let pastDate = moment()

  if (type === 'weeks') {
    pastDate = moment().subtract(value * 7, 'days')
  }
  if (type === 'months') {
    pastDate = moment().subtract(value * 28, 'days')
  }

  let start = pastDate.clone().add(1, 'days').startOf('day')
  let end = currentDate.endOf('day')

  if (start.isDST()) {
    start = start.add(1, 'h')
  }
  if (end.isDST()) {
    end = end.subtract(1, 'h')
  }

  return {
    startTime: start.valueOf(),
    endTime: end.valueOf(),
  }
}

export const computeTimestamp = (intervalOption: string) => {
  const intervalType = intervalOption.includes('W') ? 'W' : 'M'
  const [value] = intervalOption.split(intervalType)
  const { startTime, endTime } = getTimestampsForInterval(
    Number(value),
    intervalType === 'W' ? 'weeks' : 'months'
  )

  return { startTime, endTime }
}

export const getCurrentTimeStamp = () => {
  return moment().valueOf()
}

export const isDateOlderThan24Hours = (timeStamp: number) => {
  const date = moment(timeStamp)
  return date.isBefore(moment().subtract(24, 'hours'))
}

export const getMomentForSecondsTs = (ts: any) => {
  // seconds * 1000 = milliseconds
  return moment(toInteger(ts) * 1000)
}
