import { Input } from 'antd'
import styled from 'styled-components'
import { ClusterType } from '../../../../types'

type renderClusterNameProps = {
  value: string
  editingRecord?: ClusterType
  record: ClusterType
  isCreateLoading: boolean
  isUpdateLoading: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const renderClusterName = ({
  value,
  record,
  editingRecord,
  isCreateLoading,
  isUpdateLoading,
  onChange,
}: renderClusterNameProps) => {
  if (!editingRecord || record._id !== editingRecord._id) {
    return <NameWrapper>{value}</NameWrapper>
  }
  return (
    <NameWrapper>
      <Input
        name="name"
        placeholder="Tag Name"
        className="cluster-name-input"
        value={editingRecord.name}
        onChange={onChange}
        disabled={isCreateLoading || isUpdateLoading}
      />
    </NameWrapper>
  )
}

export const NameWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.14px !important;
  color: var(--color-black-1);
  .cluster-name-input {
    border-radius: 5px;
  }
`
