import { ConfigProvider, Select, Tooltip, Typography } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { Info } from 'phosphor-react'
import React from 'react'

import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form'
const { Text } = Typography

type TFieldValues = Record<string, any>
type TName = FieldPath<TFieldValues>

type Props = {
  name: string
  title?: string
  loading?: boolean
  className?: string
  placeholder?: string
  allowClear?: boolean
  defaultValue?: string
  onChange?: (value?: string) => void
  control?: Control<FieldValues, any>
  options: { key: string; value: string }[]
  disabled?: boolean
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  dropdownRender?: any
  tooltip?: boolean
  size?: SizeType
  dropdownMatchSelectWidth?: boolean
}

export const SelectUI: React.FC<Props> = ({
  className,
  title,
  control,
  options,
  defaultValue = '',
  name,
  loading = false,
  disabled = false,
  allowClear = false,
  placeholder,
  rules,
  onChange,
  dropdownRender,
  tooltip,
  size,
  dropdownMatchSelectWidth = true,
}) => {
  const renderEmpty = () => {
    if (
      title ||
      name === 'primaryCSM' ||
      name === 'solutionEngineer' ||
      name === 'primaryExecSponsor' ||
      name === 'customerPOC' ||
      name === 'customerExecSponsor'
    ) {
      return 'No Data'
    }
    return (
      <div className="text-default">
        {' '}
        Do you have private channels? Click{' '}
        <a
          href="https://help.thena.ai/docs/request-management-custom-config"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{' '}
        to know how to add private channels
      </div>
    )
  }
  return (
    <div className={className}>
      <div className="flex items-center justify-start gap-1">
        {Boolean(title) && <Text className="font-medium">{title}</Text>}
        {tooltip && (
          <Tooltip title="The Account Owner could be the Customer Success Manager (CSM), Account Executive (AE) or the Account Manager (AM)">
            <Info size={16} weight="bold" className="cursor-pointer" />
          </Tooltip>
        )}
      </div>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ field }) => (
          <ConfigProvider
            renderEmpty={() => {
              return loading ? 'Loading Channels' : renderEmpty()
            }}
          >
            <Select
              {...field}
              showSearch
              disabled={disabled}
              size={size ? size : 'middle'}
              className="w-full"
              loading={loading}
              allowClear={allowClear}
              placeholder={placeholder || 'Please Configure'}
              value={
                !disabled
                  ? typeof field.value === 'object'
                    ? field.value?.name
                    : field.value || undefined
                  : undefined
              }
              {...(onChange
                ? { onChange }
                : {
                    onChange: (v) => {
                      field.onChange(v || null)
                    },
                  })}
              filterOption={(input, option) => {
                const label = option?.label as string
                return label.toLowerCase().includes(input.toLowerCase())
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label?.toString() ?? '')
                  .toLowerCase()
                  .localeCompare(
                    (optionB?.label?.toString() ?? '').toLowerCase()
                  )
              }
              dropdownRender={dropdownRender}
              dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            >
              {options.map((option: { key: string; value: string }) => (
                <Select.Option key={option.key} label={option.value}>
                  <Text>{option.value}</Text>
                </Select.Option>
              ))}
            </Select>
          </ConfigProvider>
        )}
      />
    </div>
  )
}
