import { Input, Select } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import React from 'react'
import styled from 'styled-components'

interface BaseProps {
  title: string
  disabled?: boolean
}

interface TextInputProps extends BaseProps {
  type: 'text'
  placeholder?: string
  value?: string
  name?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

interface TextAreaProps extends BaseProps {
  type: 'textarea'
  rows?: number
  placeholder?: string
  value?: string
  name?: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

interface SelectInputProps extends BaseProps {
  type: 'select'
  placeholder?: string
  size?: SizeType
  options?: Array<{ value: string; label: string }>
  value?: string
  onChange: (value: string) => void
}

interface TextAreaDescProps extends BaseProps {
  type: 'description'
  rows?: number
  placeholder?: string
  value?: string
}

type InputProps =
  | TextInputProps
  | TextAreaProps
  | SelectInputProps
  | TextAreaDescProps

function FormInput<T extends InputProps>(props: T) {
  const { title, type, disabled = false, ...inputProps } = props

  const renderInput = () => {
    switch (type) {
      case 'text':
        return (
          <Input
            {...(inputProps as unknown as TextInputProps)}
            disabled={disabled}
            className="input-item"
          />
        )
      case 'textarea':
        return (
          <Input.TextArea
            {...(inputProps as unknown as TextAreaProps)}
            disabled={disabled}
            className="textarea-item"
          />
        )
      case 'description':
        return (
          <Input.TextArea
            {...(inputProps as unknown as TextAreaDescProps)}
            autoSize={{ minRows: 1, maxRows: 4 }}
            bordered={false}
            className="textarea-desc"
          />
        )
      case 'select':
        // eslint-disable-next-line no-case-declarations
        const {
          options = [],
          onChange,
          value,
          placeholder,
          size = 'large',
        } = inputProps as unknown as SelectInputProps
        return (
          <Select
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            size={size}
            className="select-item"
            disabled={disabled}
          >
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )
      default:
        return null
    }
  }

  return (
    <Wrapper>
      <div className="item-label">{title}</div>
      {renderInput()}
    </Wrapper>
  )
}

export default FormInput

const Wrapper = styled.div`
  .item-label {
    margin-top: 24px;
    margin-bottom: 8px;
    color: var(--color-black-1);
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .input-item {
    padding: 8px 12px 8px 16px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--color-gray-3);
  }

  .textarea-item {
    padding: 8px 12px 8px 16px;
    align-items: center;
    resize: none;
    border-radius: 8px;
    border: 1px solid var(--color-gray-3);
  }
  .textarea-desc {
    padding: 0 !important;
    resize: none;
    color: var(--color-gray-6);
  }

  .select-item {
    width: 100%;
  }
`
