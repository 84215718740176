import { DownOutlined, MinusSquareFilled } from '@ant-design/icons'
import { Popover, Row } from 'antd'
import styled from 'styled-components'

export const BulkSelect = ({
  onSelectAll,
  onDeselectAll,
  selectedRowsCount,
  isOpen,
  setOpen,
}: {
  onSelectAll: () => void
  onDeselectAll: () => void
  selectedRowsCount: number
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}) => {
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  const content = (
    <>
      <Row
        onClick={onSelectAll}
        style={{
          padding: '6px 8px',
          marginBottom: '4px',
          cursor: 'pointer',
        }}
        align="middle"
        justify="start"
      >
        Select all
      </Row>
      <Row
        justify="start"
        onClick={onDeselectAll}
        style={{
          padding: '6px 8px',
          cursor: 'pointer',
        }}
      >
        Clear all
      </Row>
    </>
  )

  return (
    <Wrapper align="middle">
      <Popover
        showArrow={false}
        placement="bottomLeft"
        content={content}
        className="custom-popover"
        open={isOpen}
        trigger="click"
        onOpenChange={handleOpenChange}
      >
        <MinusSquareFilled className="minus-icon" />
        <DownOutlined className="popover-suffix" />
      </Popover>
      {selectedRowsCount} Selected
    </Wrapper>
  )
}

const Wrapper = styled(Row)`
  gap: 8px;
  color: var(--color-purple-1);
  cursor: pointer;
  .custom-popover {
    display: flex;
    align-items: center;
  }
  .minus-icon {
    font-size: 16px;
    margin-right: 4px;
  }
  .popover-suffix {
    font-size: 6px;
    color: var(--color-gray-4);
  }
`
