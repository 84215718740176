import moment from 'moment'

export type EventValue<DateType> = DateType | null
export type RangeValue<DateType> =
  | [EventValue<DateType>, EventValue<DateType>]
  | null

export const ACCOUNT_TYPE_LIST = [
  'customer',
  'prospect',
  'partner',
  'vendor',
  'lead',
  'other',
] as const

export type AccountType = (typeof ACCOUNT_TYPE_LIST)[number]

export type AdditonalFieldSelectType =
  | 'text'
  | 'radio'
  | 'select'
  | 'boolean'
  | 'checkbox'
  | 'multiline'
  | 'multiselect'

export type UserType = {
  role: 'Unassigned' | 'csm'
  user: {
    slack: {
      id: string
      profile: {
        image_original: string
      }
    }
    name: string
    _id: string
  }
}

export type AccountFilterType = {
  id: AccountType
  name: string
}

export type FiltersType = {
  accountTypes: AccountFilterType[]
  accountOwners: UserType[]
  contractStartDateRange: moment.Moment[] | null
  renewalDateRange: moment.Moment[] | null
  crmFilter: {
    field_name: string
    values: string[]
    _id: string
  }[]
  // filters to be added from CRM config
}

export type CRMAdditonalFieldType = {
  field_name: string
  values: Array<{
    name: string
    value: string
    _id: string
  }>
  _id: string
}

export type CRMValuesType = {
  name: string
  type: AccountType
  contract_term: string
  contract_value: number
  synced_with_crm: boolean
  contract_end_date: string // or date
  contract_start_date: string // or date
  internal_slack_channel_name: string
  additional_fields: CRMAdditonalFieldType[]
  last_updated_time: string
  contract_currency?: string
}

export type RelationshipType = {
  _id: string
  users: UserType[]
  crm_values: CRMValuesType
  channel: {
    name: string
  }
  customer_name: string
}

export type AdditonalFieldType = {
  _id: string
  type: AdditonalFieldSelectType
  field_name: string
  field_name_normalized: string
  placeholder_text: string
  available_values: Array<{
    _id: string
    name: string
    value: string
  }>
  isFromCRM?: boolean
  isDeleted?: boolean
}

export type CRMConfigType = {
  additional_fields: AdditonalFieldType[]
  type: string
  fetchAccountsWorkflowMap: Record<string, string>
  fetchSummariesWorkflows: string[]
}

export type EventMetadataType = {
  summary: string
  title: string
  tags: string[]
  sentiment: {
    indicator: string
    reason: string
  }
  important_dates: {
    date: string
    topic: string
  }[]
  action_items: {
    date: string
    item: string
  }[]
  links: {
    file_name: string
    file_link: string
  }[]
  participants: {
    user: {
      profile: {
        image_24: string
        real_name: string
        display_name: string
      }
    }
  }[]
}

export type SummaryDetail = {
  customerLogo: string
  customerName: string
  vendorName: string
  createdAt: string
  event_metadata: {
    external: EventMetadataType
    internal: EventMetadataType
    oldest: string
    latest: string
  }
  relationship_id: string
}
export type AccountsStateType = {
  aiConsent: boolean
  filters: FiltersType
  isApiLoading: boolean
  crmConfig: CRMConfigType
  relationships: RelationshipType[]
  updatedAt: number
  dynamicFilters: string[]
  drawer: null | {
    data: any
    type: 'ACCOUNT_DRAWER'
  }
  crmData: null | { icon: string; name: string; type: string }
  selectedTab: 'home' | 'automation-log' | 'configuration'
}

export type UserFilterType = {
  id: string
  src: string
  name: string
}

export const AUTOMATION_LOG_STATUS_LIST = [
  'failed',
  'succeeded',
  'executing',
  'delayed',
  'paused',
] as const
