import { Button, Divider, Modal } from 'antd'
import { useAuth } from '../../../../context'
import { useZendeskAdditionalFields } from './AdditionFieldProvider'
import { InputField } from './InputFields'
import { DEFAULT_FIELDS } from './detaultFields'
import { AdditionalFieldType } from './types'

interface PreviewProps {
  additionalFields: AdditionalFieldType[]
  open: boolean
  handleModalOpen: (open: boolean) => void
}

export const PreviewTicketModal = ({
  additionalFields = [],
  open,
  handleModalOpen,
}: PreviewProps) => {
  const { user } = useAuth()
  const { reSyncZendeskFieldMutation, loading } = useZendeskAdditionalFields()

  const handleModalClose = () => handleModalOpen(false)
  const handleResync = async () => {
    await reSyncZendeskFieldMutation?.mutateAsync()
  }

  return (
    <Modal
      title="Zendesk Ticket Preview Modal"
      destroyOnClose
      open={open}
      centered
      width={580}
      onCancel={handleModalClose}
      onOk={handleModalClose}
      footer={[
        <Button onClick={handleModalClose}>Close</Button>,
        <Button
          onClick={handleResync}
          loading={loading}
          className="text-primary border-primary/40 "
        >
          Re-Sync
        </Button>,
      ]}
      bodyStyle={{
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 16,
        paddingTop: 8,
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 350px)',
      }}
    >
      <div className="mt-2">Hi 👋👋 {user?.name}. Tell us more</div>

      <Divider className="my-2 mb-4" />
      {DEFAULT_FIELDS.filter((f) => f.enabled && !f.hidden).map((field) => (
        <InputField key={`${field.label}-${field.key}`} field={field} />
      ))}
      {additionalFields
        .filter((f) => f.enabled && !f.hidden)
        .map((field) => (
          <InputField key={`${field.label}-${field.key}`} field={field} />
        ))}
    </Modal>
  )
}
