import { Spin } from 'antd'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { fetchSettingUpStatus } from '../../api/misc'

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const Info = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
  .counter-badge {
    font-size: 20px;
    margin-left: 4px;
  }
`

export const SettingUp = () => {
  const navigate = useNavigate()

  const { data } = useQuery('setting-up-status', fetchSettingUpStatus, {
    onSettled: (apiData) => {
      if (apiData && apiData.onboarded) {
        navigate('/requests')
      }
    },
    refetchInterval: 3 * 1000,
  })

  const hideSpinner = data?.onboarded

  return (
    <Wrapper>
      {!hideSpinner && <Spin size="large" className="mb-[16px]" />}
      {(data?.channels?.status === 'not_started' ||
        data?.channels?.status === 'syncing') && (
        <Info>Syncing Channels: {data.channels.count}</Info>
      )}
      {data?.channels?.status === 'synced' && (
        <Info>All Channels Synced ✅</Info>
      )}
      {data?.channels?.status === 'rate_limited' && (
        <Info
          style={{
            color: 'var(--color-red-3)',
          }}
        >
          Syncing Channels - Rate Limited!
        </Info>
      )}
      {(data?.members?.status === 'not_started' ||
        data?.members?.status === 'syncing') && (
        <Info>Syncing Users: {data.members.count}</Info>
      )}
      {data?.members?.status === 'synced' && <Info>All Users Synced ✅</Info>}
      {data?.members?.status === 'rate_limited' && (
        <Info
          style={{
            color: 'var(--color-red-3)',
          }}
        >
          Syncing Users - Rate Limited!
        </Info>
      )}
    </Wrapper>
  )
}
