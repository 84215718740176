import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  configuredFields,
  Filters,
} from '../reducer/kanbanReducer/kanbanReducerState'
import { useAccountsStore } from '../store/accountsStore'
import { DateRangeType, getDateRangeByType } from '../utils/dateUtils'
import { emptyList, emptyObject } from '../utils/empty'
import { KanbanFiltersType } from './useKanbanQuery'
import useQueryFilters from './useQueryFilters'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type SetterType = (args: any) => void

type HookProps = {
  setCsm: SetterType
  setSearch: SetterType
  setSortBy: SetterType
  setUrgency: SetterType
  setAllTags: SetterType
  setCardType: SetterType
  setSentiment: SetterType
  setEscalated: SetterType
  setCustomers: SetterType
  setDateRange: SetterType
  setAssignedTo: SetterType
  setAssignedToMe: SetterType
  setInitialDateRange: SetterType
  setSolutionEngineers: SetterType
  setConfiguredFieldValues: SetterType
  setCrmFieldValues: SetterType
  setAccountTypes: SetterType
}

const QUERY_KEYS = [
  'csm',
  'sortBy',
  'search',
  'urgency',
  'allTags',
  'cardType',
  'sentiment',
  'customers',
  'dateRange',
  'assignedTo',
  'isEscalated',
  'assignedToMe',
  'solutionEngineers',
  'isInitialDateRange',
]

const useKanbanQueryInit = (
  setterObj: HookProps,
  queryTrackRef: React.MutableRefObject<boolean>,
  setAllFilter: (args: any) => void,
  setMountQueryUpdateHandler: (bool: boolean) => void
) => {
  const { getParam } = useQueryFilters()

  const setters = useMemo(() => {
    return Object.keys(setterObj).reduce((acc, k) => {
      const key = k as keyof HookProps
      return { ...acc, [key.toLowerCase()]: setterObj[key] }
    }, {}) as HookProps
  }, [setterObj])

  const data = useQuery<Filters>(['kanbanFilters'])
  const { data: configuredFields, isSuccess } = useQuery(['configured-fields'])
  const isApiFetched = data.isSuccess && isSuccess
  const crmConfig = useAccountsStore((state) => state.crmConfig) ?? emptyObject
  useEffect(() => {
    if (isApiFetched && !queryTrackRef.current) {
      const configuredFieldKeys = (configuredFields as configuredFields[]).map(
        (item) => item.category_name
      )
      const crmFilterKeys = crmConfig?.additional_fields?.map(
        (item) => item.field_name
      )
      ;[
        ...QUERY_KEYS,
        ...(crmFilterKeys || []),
        ...configuredFieldKeys,
      ].forEach((k) => {
        const key = k as keyof KanbanFiltersType

        const value = getParam(key)

        if (value) {
          if (key === 'urgency') {
            setAllFilter((prev: string[]) => [...prev, 'Urgency'])
          }
          if (key === 'sentiment') {
            setAllFilter((prev: string[]) => [...prev, 'Sentiment'])
          }
          if (key === 'allTags') {
            setAllFilter((prev: string[]) => [...prev, 'Tags'])
          }
          if (key === 'solutionEngineers') {
            setAllFilter((prev: string[]) => [...prev, 'Solutions Engineer'])
          }

          switch (key) {
            case 'isInitialDateRange':
              setterObj.setInitialDateRange(value)
              break

            case 'isEscalated':
              setterObj.setEscalated(value)
              break

            case 'csm': {
              const csmList = data.data.csm.members.filter((item) =>
                (value || emptyList).includes(item.id)
              )
              setterObj.setCsm(csmList)
              break
            }

            case 'solutionEngineers': {
              const list = data.data.solutionEngineers.members.filter((item) =>
                (value || emptyList).includes(item.id)
              )
              setterObj.setSolutionEngineers(list)
              break
            }

            case 'dateRange': {
              const dateRangeType = getParam('dateRangeType')
              if (dateRangeType) {
                const rangeList = getDateRangeByType(
                  dateRangeType as DateRangeType
                )
                setterObj.setDateRange(rangeList)
              } else {
                const momentList = [moment(value[0]), moment(value[1])]
                setterObj.setDateRange(momentList)
              }
              break
            }

            default:
              {
                if (configuredFieldKeys.includes(key)) {
                  setterObj.setConfiguredFieldValues((prev: any) => {
                    return { ...prev, ...{ [key]: value } }
                  })
                  setAllFilter((prev: string[]) => [...prev, key])
                }
                if (crmFilterKeys.includes(key)) {
                  setterObj.setCrmFieldValues((prev: any) => {
                    return { ...prev, ...{ [key]: value } }
                  })
                  setAllFilter((prev: string[]) => [...prev, key])
                } else {
                  const setFunc =
                    setters[`set${key.toLowerCase()}` as keyof HookProps]

                  if (setFunc) {
                    setFunc(value)
                  }
                }
              }
              break
          }
        }
      })
      queryTrackRef.current = true
      setMountQueryUpdateHandler(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiFetched])
}

export default useKanbanQueryInit
