/* eslint-disable max-lines */
import { isEmpty } from 'lodash'
import moment from 'moment'
import { CheckCircle, Clock, Pause, SpinnerGap, XCircle } from 'phosphor-react'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { fetchVendors } from '../../api/personas'
import { useAuth } from '../../context'
import {
  AccountsActionType,
  accountsInitialState,
} from '../../reducer/accountsReducer/accountsReducer'
import { useAccountsStore } from '../../store/accountsStore'
import { useParagonStore } from '../../store/paragonStore'
import {
  AccountFilterType,
  AdditonalFieldType,
  FiltersType,
  UserType,
} from '../../types/accountsTypes'
import { emptyObject } from '../../utils/empty'
import { getParsedJsonArray } from '../Requests/utils'

export const formatCurrency = (
  figure: string | number,
  formatStyle?: undefined | 'currency' | 'decimal'
) => {
  return Number(figure).toLocaleString('en-US', {
    currency: 'USD',
    style: formatStyle,
    minimumFractionDigits: 0,
  })
}

export const getUniqueArrayByObjectKey = (
  array: any[],
  propertyName: string
) => {
  return Array.from(new Set(array.map((obj) => obj[propertyName])), (id) =>
    array.find((obj) => obj[propertyName] === id)
  )
}

export const defaultFieldsForMapping = [
  {
    label: 'Domain',
    value: 'domain',
  },
  {
    label: 'Contract Value',
    value: 'contract_value',
  },
  {
    label: 'Contract Currency',
    value: 'contract_currency',
  },
  {
    label: 'Account Type',
    value: 'type',
  },
]

export const dynamicFieldMapping = (
  integrationType: string,
  additionalFields: AdditonalFieldType[]
) => {
  let objectName = 'Account'
  switch (integrationType) {
    case 'hubspot':
      objectName = 'Companies'
      break
    case 'salesforce':
    default:
      objectName = 'Account'
  }

  const mappedFields = [...defaultFieldsForMapping]

  const defaultFields = [
    'domain',
    'contract_value',
    'contract_currency',
    'type',
  ]

  additionalFields?.forEach((field) => {
    if (field.isFromCRM || field.isDeleted) {
      return
    }
    mappedFields.push({
      label: field?.field_name,
      value: field?.field_name_normalized,
    })
    defaultFields.push(field?.field_name)
  })

  return {
    mapObjectFields: {
      [objectName]: {
        fields: mappedFields,
        userCanRemoveMappings: true,
        defaultFields,
        userCanCreateFields: true,
      },
    },
  }
}

export const useQueryInit = () => {
  const searchParams = useSearchParams()[0]
  const dispatch = useAccountsStore((state) => state.dispatch)
  const [isQueryInitComplete, setIsQueryInitComplete] = useState(false)

  const updateState = (
    dispatchType: AccountsActionType['type'],
    field: string,
    value: any,
    isArray = false
  ) => {
    if (isArray) {
      const parsedValue = getParsedJsonArray(value)
      if (parsedValue) {
        dispatch({
          type: dispatchType,
          payload: {
            [field]: parsedValue,
          },
        })
      }
      return
    }
    dispatch({
      type: dispatchType,
      payload: {
        [field]: value,
      },
    })
  }

  useEffect(() => {
    if (!isQueryInitComplete) {
      ;[...Object.keys(accountsInitialState), 'tab'].forEach((key) => {
        const typedKey = key as keyof FiltersType | 'tab'
        const value = searchParams.get(typedKey)
        const isValueValid =
          value !== null && value !== undefined && value !== ''
        if (isValueValid) {
          switch (typedKey) {
            case 'accountTypes':
              updateState('UPDATE_FILTER_KEY', 'accountTypes', value, true)
              break

            case 'accountOwners':
              updateState('UPDATE_FILTER_KEY', 'accountOwners', value, true)
              break
            case 'tab': {
              const updatedValue = value.includes('home')
                ? 'home'
                : value.includes('automation-log')
                ? 'automation-log'
                : 'configuration'

              dispatch({
                type: 'SET_SELECTED_TAB',
                payload: {
                  selectedTab: updatedValue,
                },
              })
              break
            }
            case 'contractStartDateRange': {
              const parsedValue = getParsedJsonArray(value)
              if (parsedValue) {
                const momentList = [
                  moment(parsedValue[0]),
                  moment(parsedValue[1]),
                ]
                dispatch({
                  type: 'UPDATE_FILTER_KEY',
                  payload: {
                    data: momentList,
                    key: 'contractStartDateRange',
                  },
                })
              }
              break
            }
            case 'renewalDateRange': {
              const parsedValue = getParsedJsonArray(value)
              if (parsedValue) {
                const momentList = [
                  moment(parsedValue[0]),
                  moment(parsedValue[1]),
                ]
                dispatch({
                  type: 'UPDATE_FILTER_KEY',
                  payload: {
                    data: momentList,
                    key: 'renewalDateRange',
                  },
                })
              }
              break
            }

            default:
              break
          }
        }
      })

      setIsQueryInitComplete(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isQueryInitComplete])

  const crmConfigList = useAccountsStore.getState().crmConfig

  const [isCrmFieldQueryInit, setIsCrmFieldQueryInit] = useState(false)

  useEffect(() => {
    if (!isCrmFieldQueryInit && !isEmpty(crmConfigList?.additional_fields)) {
      const crmFieldKeys = crmConfigList?.additional_fields?.map(
        (item) => item.field_name
      )
      crmFieldKeys.forEach((key) => {
        const paramValue = searchParams.get(key)
        if (paramValue) {
          try {
            const parsedValue = JSON.parse(paramValue)
            if (!Array.isArray(parsedValue)) {
              throw new Error('Invalid value! Query: crmFields')
            }
            dispatch({
              type: 'UPDATE_FILTER_KEY',
              payload: {
                data: parsedValue,
                key: 'crmFilter',
              },
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
    setIsCrmFieldQueryInit(true)
  }, [
    isCrmFieldQueryInit,
    crmConfigList?.additional_fields,
    dispatch,
    searchParams,
  ])

  return isQueryInitComplete && isCrmFieldQueryInit
}

export const useQueryUpdate = (isQueryInitComplete: boolean) => {
  const filters = useAccountsStore((state) => state.filters)
  const setSearchParams = useSearchParams()[1]
  const selectedTab = useAccountsStore((state) => state.selectedTab)
  const cardQuery = useAccountsStore((state) => state.drawer?.data?.record?._id)
  useEffect(() => {
    if (isQueryInitComplete) {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params)
        Object.keys(filters)
          .filter((key) => key !== 'dispatch')
          .forEach((key) => {
            const typedKey = key as keyof FiltersType
            switch (typedKey) {
              case 'accountTypes':
              case 'accountOwners': {
                const val = filters[typedKey]
                if (!isEmpty(val)) {
                  typedKey === 'accountTypes'
                    ? newParams.set(
                        typedKey,
                        JSON.stringify(
                          (val as AccountFilterType[]).map(
                            (item: any) => item?.id
                          )
                        )
                      )
                    : newParams.set(
                        typedKey,
                        JSON.stringify(
                          (val as UserType[]).map(
                            (item) => item?.user?.slack?.id
                          )
                        )
                      )
                } else {
                  newParams.delete(typedKey)
                }
                break
              }
              case 'renewalDateRange': {
                const val = filters[typedKey]
                if (val) {
                  const startDate = moment(val[0]).toISOString(true)
                  const endDate = moment(val[1]).toISOString(true)
                  newParams.set(typedKey, JSON.stringify([startDate, endDate]))
                } else {
                  newParams.delete(typedKey)
                }
                break
              }
              case 'contractStartDateRange': {
                const val = filters[typedKey]
                if (val) {
                  const startDate = moment(val[0]).toISOString(true)
                  const endDate = moment(val[1]).toISOString(true)
                  newParams.set(typedKey, JSON.stringify([startDate, endDate]))
                } else {
                  newParams.delete(typedKey)
                }
                break
              }

              case 'crmFilter': {
                if (filters) {
                  const val = filters[typedKey]
                  const crmFieldKeys = Object.keys(val)
                  crmFieldKeys.forEach((key: any) => {
                    const fieldValue = val[key]
                    if (fieldValue.values.length > 0) {
                      newParams.set(
                        fieldValue.field_name,
                        JSON.stringify(fieldValue.values)
                      )
                    } else {
                      newParams.delete(fieldValue.field_name)
                    }
                  })
                }
                break
              }
              default:
                break
            }
          })

        if (selectedTab) {
          newParams.set('tab', selectedTab)
        }
        if (cardQuery) {
          newParams.set('relationshipId', cardQuery)
        } else {
          newParams.delete('relationshipId')
        }
        return newParams
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isQueryInitComplete, selectedTab, cardQuery])
}

export const getWorkflowStatusIcon = (statusValue: string, size = 22) => {
  if (statusValue === 'FAILED') {
    return <XCircle size={size} color="#F86464" className="mt-[2px]" />
  }
  if (statusValue === 'SUCCEEDED') {
    return <CheckCircle size={size} color="#18c7bc" className="mt-[2px]" />
  }
  if (statusValue === 'EXECUTING') {
    return <SpinnerGap size={size} color="#18c7bc" className="mt-[2px]" />
  }
  if (statusValue === 'DELAYED') {
    return <Clock size={size} color="#18c7bc" className="mt-[2px]" />
  }
  if (statusValue === 'PAUSED') {
    return <Pause size={size} color="#18c7bc" className="mt-[2px]" />
  }
}

export const useGetUsersList = (channel_id: string) => {
  const { data: vendors } = useQuery(['fetchVendors', channel_id], () =>
    fetchVendors({
      channelIds: [channel_id?.toString()],
      page: 0,
    })
  )

  const { user } = useAuth()

  const assigneeFilterItems = useMemo(() => {
    if (!vendors?.members) {
      return []
    }
    const { assignees } = vendors || emptyObject
    const assigneeList = (assignees?.members as any[]) || []

    let list = [...assigneeList].filter((item: any) => item.id !== user?.id)

    list = list.map((item: any) => {
      return {
        ...item,
        key: item.id,
        label: item.name,
      }
    })
    list = [...list.sort((a: any, b: any) => a.label.localeCompare(b.label))]

    return list
  }, [user, vendors])

  const accountOwners = useMemo(() => {
    if (vendors) {
      return vendors?.members || []
    }
    return []
  }, [vendors])

  return { assigneeFilterItems, accountOwners }
}

export const useCurrentIntegration = () => {
  const dispatch = useAccountsStore((state) => state.dispatch)
  const crmConfig = useAccountsStore((state) => state.crmConfig)
  const { paragon, paragonUser, isParagonLoading } = useParagonStore(
    (state) => ({
      paragon: state?.paragon,
      paragonUser: state?.paragonUser,
      isParagonLoading: state?.loading,
    })
  )
  const enabledIntegrationDetails = useMemo(() => {
    const integrationDetails = { icon: '', name: '', type: '' }
    try {
      const integrationsEnabledThroughParagon = Object.keys(
        paragonUser?.integrations || {}
      ).filter((integration) => {
        return paragonUser?.integrations[integration]?.enabled
      })
      if ((integrationsEnabledThroughParagon || []).length === 0) {
        return integrationDetails
      }
      const { type: crmConfigType = '' } = crmConfig || {}
      const [formattedConfig] = crmConfigType.split('-')
      paragon?.getIntegrationMetadata()?.forEach((integration: any) => {
        if (integration.type.toLowerCase() === formattedConfig.toLowerCase()) {
          integrationDetails.icon = integration.icon
          integrationDetails.name = integration.name
          integrationDetails.type = integration.type
          return
        }
      })
      dispatch({
        type: 'INIT_CRM_DATA',
        payload: { crmData: integrationDetails },
      })
    } catch (error) {
      console.log('useCurrentIntegration error: ', error)
    }
    return integrationDetails
  }, [paragonUser?.integrations, crmConfig, paragon, dispatch])
  return { enabledIntegrationDetails, isParagonLoading }
}
