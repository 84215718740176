import { Button, Typography } from 'antd'
import { useState } from 'react'
import { useZendeskAdditionalFields } from './AdditionFieldProvider'
import { PreviewTicketModal } from './PreviewModal'
import { AdditionalFieldType } from './types'

const { Text } = Typography

interface Props {
  additionalFields: AdditionalFieldType[]
}

export const ZendeskAdditionalFields = ({ additionalFields }: Props) => {
  const { reSyncZendeskFieldMutation, loading } = useZendeskAdditionalFields()
  const [openModal, setModalOpen] = useState<boolean>(false)

  const handleModalOpen = (open = false) => {
    setModalOpen(open)
  }
  const handleResync = async () => {
    await reSyncZendeskFieldMutation?.mutateAsync()
  }
  return (
    <div>
      <Text strong></Text>
      <h4 className="m-0 mb-1 font-semibold text-sm 3xl:text-[length:var(--sm)]">
        Additional Fields
      </h4>
      <PreviewTicketModal
        additionalFields={additionalFields}
        open={openModal}
        handleModalOpen={handleModalOpen}
      />
      <div className="flex items-center justify-start gap-2 justify-end">
        <Button onClick={() => handleModalOpen(true)} type="link">
          Preview Modal
        </Button>
        <Button onClick={handleResync} loading={loading}>
          Re-Sync
        </Button>
      </div>
    </div>
  )
}
