import React, { useContext, useState } from 'react'
import { ChannelType } from '../channels/definitions'

interface PersonaContextType {
  selectedChannelList: ChannelType[]
  setSelectedChannelList: React.Dispatch<React.SetStateAction<ChannelType[]>>
}

const PersonaContext = React.createContext<PersonaContextType>({
  selectedChannelList: [],
  setSelectedChannelList: () => null,
})

export const PersonaProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [selectedChannelList, setSelectedChannelList] = useState<ChannelType[]>(
    []
  )

  return (
    <PersonaContext.Provider
      value={{
        selectedChannelList,
        setSelectedChannelList,
      }}
    >
      {children}
    </PersonaContext.Provider>
  )
}

export const usePersona = () => {
  return useContext(PersonaContext)
}
