import { useFlags } from 'launchdarkly-react-client-sdk'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ConnectSDK } from '../../paragon'

type WindowType = {
  paragon: ConnectSDK
}

export default function useParagonGlobal() {
  const flags = useFlags()
  const mountedParagon = useRef(false)
  const [paragonReady, setParagonReady] = useState(false)

  const initParagon = useCallback(async () => {
    setParagonReady(true)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Paragon Active:', flags.paragon)
  }, [flags.paragon])

  useEffect(() => {
    if (
      flags.paragon &&
      typeof window !== 'undefined' &&
      !paragonReady &&
      !mountedParagon.current
    ) {
      if ((window as unknown as WindowType).paragon) {
        initParagon()
      } else {
        mountedParagon.current = true
        const paragonSrc = document.createElement('script')
        paragonSrc.src = 'https://cdn.useparagon.com/latest/sdk/index.js'
        paragonSrc.onload = initParagon
        document.body.appendChild(paragonSrc)
      }
    }
  }, [paragonReady, initParagon, flags.paragon])

  if (paragonReady && (window as unknown as WindowType).paragon) {
    return (window as unknown as WindowType).paragon
  }
  return undefined
}
