import { Row } from 'antd'
import styled from 'styled-components'
import ActivationIcon from '../../../../images/icons/activation.svg'
import BulkEditMenu from './BulkEditMenu'

export const BulkActivate = ({
  onActive,
  onDeactive,
  setOpen,
  isOpen,
}: {
  onActive: () => void
  onDeactive: () => void
  setOpen: (isOpen: boolean) => void
  isOpen: boolean
}) => {
  const content = (
    <Wrapper>
      <Row
        onClick={onActive}
        className="content-item"
        align="middle"
        justify="start"
      >
        Activate
      </Row>
      <Row justify="start" onClick={onDeactive} className="content-item">
        Deactivate
      </Row>
    </Wrapper>
  )

  return (
    <BulkEditMenu
      label="Activation"
      iconAlt="activation"
      iconSrc={ActivationIcon}
      className="qna-filter"
      content={content}
      isOpen={isOpen}
      setOpen={setOpen}
    />
  )
}

const Wrapper = styled.div`
  .content-item {
    padding: 6px 8px;
    margin-bottom: 4px;
    gap: 8px;
    cursor: pointer;
  }
`
