import { Avatar, Input, Popover, Tooltip, Typography, message } from 'antd'
import VirtualList from 'rc-virtual-list'
import { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import styled from 'styled-components'
import { updateKanban } from '../../../../api/kanban'
import { useAuth } from '../../../../context'
import useEvents from '../../../../hooks/useEvents'
import TickIcon from '../../../../images/icons/ic_tick.svg'
import UnassignedIcon from '../../../../images/icons/ic_unassigned.svg'
import { useKanbanBoardStore } from '../../../../store/kanbanBoardStore'
import { useGetUsersList } from '../../utils'
const { Text } = Typography

const Wrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  cursor: pointer;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`

type Props = {
  data: any
  isReadonly?: boolean
}

const Assignee = ({ data, isReadonly = false }: Props) => {
  const { user } = useAuth()
  const trackEvent = useEvents()
  const [isOpen, setOpen] = useState(false)
  const { assigneeFilterItems } = useGetUsersList(data.teamId)
  const [searchQuery, setSearchQuery] = useState('')
  const kanbanBoardDispatch = useKanbanBoardStore((state) => state.dispatch)

  const assignItems = useMemo(() => {
    const filteredAssignedItems = assigneeFilterItems.filter(
      (item) => item.label !== 'Unassigned'
    )
    return filteredAssignedItems.map((el) =>
      el.id === user?.id ? { ...el, label: 'Assign To Me' } : el
    )
  }, [assigneeFilterItems, user?.id])

  const selectMutation = useMutation(updateKanban, {
    onMutate: (variables) => {
      const prevData =
        useKanbanBoardStore.getState().records[variables?._id ?? '']
      kanbanBoardDispatch({
        type: 'UPDATE_RECORD',
        payload: {
          _id: variables?._id,
          data: {
            status: variables?.status,
          },
        },
      })
      return { prevData }
    },
    onSuccess: () => {
      message.success('Updated')
    },
    onError: (err: any, variables, ctx) => {
      console.log(err)
      kanbanBoardDispatch({
        type: 'UPDATE_RECORD',
        payload: {
          _id: ctx?.prevData?._id,
          data: {
            status: ctx?.prevData?.status,
          },
        },
      })
      message.error('Something went wrong!')
    },
  })

  const content = useMemo(() => {
    const onSearch = (e: any) => {
      e.stopPropagation()
      setSearchQuery(e.target.value)
    }

    const usersList = assignItems?.filter((it) =>
      it.label.toLowerCase().includes(searchQuery.toLowerCase())
    )

    if (!usersList) {
      return
    }

    return (
      <div>
        <Input
          onChange={onSearch}
          placeholder="Search"
          onClick={(e) => e.stopPropagation()}
        />
        <div
          style={{
            width: '250px',
            height: '250px',
            overflow: 'auto',
            marginTop: '0.5rem',
          }}
        >
          <VirtualList
            height={250}
            itemKey="item"
            itemHeight={36}
            data={usersList}
          >
            {(item: any) => {
              const defaultItemClass =
                data.assignedTo?.id === item.key
                  ? 'popover-item-selected-avatar-list'
                  : 'popover-item-avatar-list'

              return (
                <div
                  key={item.key}
                  className={defaultItemClass}
                  onClick={(e) => {
                    e.stopPropagation()
                    kanbanBoardDispatch({
                      type: 'UPDATE_RECORD',
                      payload: {
                        _id: data._id,
                        data: {
                          status: 'IN_PROGRESS',
                          assignedTo: {
                            id: item.id,
                            name:
                              assignItems?.find(
                                (ele: any) => ele._id === item._id
                              )?.label === 'Assign To Me'
                                ? user?.name
                                : item.label,
                            _id: item._id,
                          },
                        },
                      },
                    })
                    setOpen(false)
                    selectMutation.mutate({
                      _id: data._id,
                      type: 'REQUEST',
                      assignee: item._id,
                      status: 'IN_PROGRESS',
                    })
                    trackEvent('Row Assignee Change', {
                      assignedTo: item.label,
                    })
                  }}
                >
                  {item.label.includes('Assign To Me') ? (
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <Avatar src={item.image} size="small" />
                        <Text
                          className="ml-1 font-medium text-info"
                          ellipsis={{ tooltip: false }}
                        >
                          {item.label}{' '}
                        </Text>
                      </div>
                      {data.assignedTo?.id === item.key ? (
                        <img
                          src={TickIcon}
                          alt="ticket logo icon"
                          className="w-4 h-4 mr-4"
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-1">
                        <Avatar src={item.image} size="small" />
                        <Text
                          className="ml-1 font-normal text-default"
                          ellipsis={{ tooltip: false }}
                        >
                          {item.label}{' '}
                        </Text>
                      </div>
                      {data.assignedTo?.id === item.key ? (
                        <img
                          src={TickIcon}
                          alt="ticket logo icon"
                          className="w-4 h-4 mr-4"
                        />
                      ) : null}
                    </div>
                  )}
                </div>
              )
            }}
          </VirtualList>
        </div>
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data._id,
    user?.name,
    assignItems,
    searchQuery,
    selectMutation,
    data.assignedTo?.id,
  ])

  if (data.cardType !== 'REQUEST') {
    return null
  }

  return (
    <Wrapper>
      <Popover
        style={{
          width: '150px',
        }}
        open={isOpen}
        trigger="click"
        content={content}
        showArrow={false}
        onOpenChange={isReadonly ? undefined : setOpen}
        placement="bottomLeft"
        getPopupContainer={(trigger) => trigger.parentElement || document.body}
      >
        {data.assignedTo?.id ? (
          <Tooltip
            showArrow={false}
            placement="topLeft"
            destroyTooltipOnHide
            mouseEnterDelay={0.5}
            title={'Assigned to ' + data.assignedTo?.name}
          >
            <Avatar
              src={
                assignItems?.find((item) => item.key === data.assignedTo?.id)
                  ?.image || data.assignedTo?.image
              }
              size={16}
              style={{ cursor: 'pointer' }}
              onClick={(e) => e?.stopPropagation()}
            />
          </Tooltip>
        ) : (
          <img
            alt=""
            src={UnassignedIcon}
            className="w-[16px] h-[16px]"
            style={{ cursor: 'pointer' }}
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </Popover>
    </Wrapper>
  )
}

export default Assignee
