/* eslint-disable max-lines */
/* eslint-disable no-console */
import { MenuProps, message } from 'antd'
import axios from 'axios'
import { useLiveQuery } from 'dexie-react-hooks'
import { isEmpty } from 'lodash'
import moment from 'moment'
import pMap from 'p-map'
import {
  CheckCircle,
  CircleDashed,
  CircleNotch,
  File,
  FileCsv,
  FileImage,
  FilePdf,
  FileText,
  FileVideo,
} from 'phosphor-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import ClosedBoardIcon from '../../images/icons/closedBoardIcon.svg'
import UnassignedIcon from '../../images/icons/ic_unassigned.svg'
import InProgressBoardIcon from '../../images/icons/inProgressBoardIcon.svg'
import OnholdBoardIcon from '../../images/icons/onholdBoardIcon.svg'
import OpenBoardIcon from '../../images/icons/openBoardIcon.svg'

import { customStatusesDB } from 'DB/customStatusesDB'
import { updateFetchAfterEpoch } from 'pages/Requests/utils'
import { kanbanDrawerDB } from '../../DB/kanbanDrawerDB'
import {
  fetchConfigureFields,
  fetchCustomStatus,
  fetchKanban,
  fetchKanbanFilters,
  fetchKanbanRequest,
  fetchSlackConversation,
} from '../../api/kanban'
import { useAuth } from '../../context'
import useFirstRenderSkip from '../../hooks/useFirstRenderSkip'
import EmailIcon from '../../images/icons/email.svg'
import HighPriorityIcon from '../../images/icons/high-priority.svg'
import LowPriorityIcon from '../../images/icons/low-priority.svg'
import MedPriorityIcon from '../../images/icons/med-priority.svg'
import {
  Filters,
  configuredFields,
} from '../../reducer/kanbanReducer/kanbanReducerState'
import { useAccountsStore } from '../../store/accountsStore'
import { useKanbanBoardStore } from '../../store/kanbanBoardStore'
import { useKanbanStore2 } from '../../store/kanbanStore2'
import {
  ActionType,
  initialState,
  useKanbanStorePersist,
} from '../../store/kanbanStorePersist'
import { KanbanStorePersist } from '../../types/kanbanTypes'
import { DateRangeType, getDateRangeByType } from '../../utils/dateUtils'
import { emptyObject } from '../../utils/empty'
import { MODAL_TYPES } from '../Requests/constants'

export const getParsedJsonArray = (value: any) => {
  let parsedValue = null
  try {
    parsedValue = JSON.parse(value)
    if (!Array.isArray(parsedValue)) {
      throw new Error(`Invalid value! ${value}`)
    }
  } catch (error) {
    console.log(error)
  }
  return parsedValue
}

const useQueryInit = () => {
  const searchParams = useSearchParams()[0]
  const dispatch = useKanbanStorePersist((state) => state.dispatch)
  const [isQueryInitComplete, setIsQueryInitComplete] = useState(false)

  const updateState = (
    dispatchType: ActionType['type'],
    field: string,
    value: any,
    isArray = false
  ) => {
    if (isArray) {
      const parsedValue = getParsedJsonArray(value)
      if (parsedValue) {
        dispatch({
          type: dispatchType,
          payload: {
            [field]: parsedValue,
          },
        })
      }
      return
    }
    dispatch({
      type: dispatchType,
      payload: {
        [field]: value,
      },
    })
  }

  useEffect(() => {
    if (!isQueryInitComplete) {
      ;[...Object.keys(initialState), 'tab'].forEach((key) => {
        const typedKey = key as keyof KanbanStorePersist | 'tab'
        const value = searchParams.get(typedKey)
        const isValueValid =
          value !== null && value !== undefined && value !== ''

        if (isValueValid) {
          switch (typedKey) {
            case 'search':
              updateState('SET_SEARCH', 'search', value)
              break

            case 'sortBy':
              updateState('SET_SORT_BY', 'sortBy', value)
              break

            case 'tab': {
              updateState('SET_SELECTED_TAB', 'selectedTab', value)
              break
            }
            case 'currentViewName': {
              updateState('SET_CURRENT_VIEW_NAME', 'currentViewName', value)
              break
            }
            case 'isEscalated':
              updateState('SET_IS_ESCALATED', 'isEscalated', value)
              break

            case 'assignedTo':
              updateState('SET_ASSIGNED_TO', 'assignedTo', value, true)
              break

            case 'customers':
              updateState('SET_CUSTOMERS', 'customers', value, true)
              break

            case 'dateRange': {
              const dateRangeType = searchParams.get('dateRangeType')
              if (dateRangeType) {
                const dateRange = getDateRangeByType(
                  dateRangeType as DateRangeType
                )
                if (dateRange) {
                  dispatch({
                    type: 'SET_DATE_RANGE',
                    payload: {
                      dateRange: dateRange,
                    },
                  })
                  break
                }
              }
              const parsedValue = getParsedJsonArray(value)
              if (parsedValue) {
                const momentList = [
                  moment(parsedValue[0]),
                  moment(parsedValue[1]),
                ]
                dispatch({
                  type: 'SET_DATE_RANGE',
                  payload: {
                    dateRange: momentList,
                  },
                })
              }
              break
            }

            case 'dateRangeType':
              updateState('SET_DATE_RANGE_TYPE', 'dateRangeType', value)
              break

            case 'cardType':
              updateState('SET_CARD_TYPE', 'cardType', value, true)
              break

            case 'source':
              updateState('SET_SOURCE', 'source', value, true)
              break

            case 'workspaces':
              updateState('SET_WORKSPACES', 'workspaces', value, true)
              break

            case 'csm':
              updateState('SET_CSM', 'csm', value, true)
              break

            case 'solutionEngineers':
              updateState(
                'SET_SOLUTION_ENGINEERS',
                'solutionEngineers',
                value,
                true
              )
              break

            case 'urgency':
              updateState('SET_URGENCY', 'urgency', value, true)
              break

            case 'sentiment':
              updateState('SET_SENTIMENT', 'sentiment', value, true)
              break

            case 'allTags':
              updateState('SET_ALL_TAGS', 'allTags', value, true)
              break

            case 'accountTypes':
              updateState('SET_ACCOUNT_TYPES', 'accountTypes', value, true)
              break

            default:
              break
          }
        }
      })

      setIsQueryInitComplete(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isQueryInitComplete])

  const [isConfiguredFieldQueryInit, setIsConfiguredFieldQueryInit] =
    useState(false)

  const { data, isSuccess } = useQuery(
    ['configured-fields'],
    fetchConfigureFields
  )
  const crmConfigList = useAccountsStore.getState().crmConfig

  const [isCrmFieldQueryInit, setIsCrmFieldQueryInit] = useState(false)

  useEffect(() => {
    if (!isConfiguredFieldQueryInit && isSuccess) {
      const configuredFieldKeys = (data as configuredFields[])?.map(
        (item) => item.category_name
      )
      configuredFieldKeys?.forEach((key) => {
        const paramValue = searchParams.get(key)
        if (paramValue) {
          try {
            const parsedValue = JSON.parse(paramValue)
            if (!Array.isArray(parsedValue)) {
              throw new Error('Invalid value! Query: configuredFields')
            }
            dispatch({
              type: 'SET_CONFIGURED_FIELD',
              payload: {
                fieldName: key,
                fieldValue: parsedValue,
              },
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
      setIsConfiguredFieldQueryInit(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfiguredFieldQueryInit, data, isSuccess, searchParams])

  useEffect(() => {
    if (!isCrmFieldQueryInit && !isEmpty(crmConfigList?.additional_fields)) {
      const crmFieldKeys = crmConfigList?.additional_fields?.map(
        (item) => item.field_name
      )
      crmFieldKeys.forEach((key) => {
        const paramValue = searchParams.get(key)
        if (paramValue) {
          try {
            const parsedValue = JSON.parse(paramValue)
            if (!Array.isArray(parsedValue)) {
              throw new Error('Invalid value! Query: crmFields')
            }
            dispatch({
              type: 'SET_CRM_FIELD',
              payload: {
                fieldName: key,
                fieldValue: parsedValue,
              },
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
    setIsCrmFieldQueryInit(true)
  }, [
    isCrmFieldQueryInit,
    crmConfigList?.additional_fields,
    dispatch,
    searchParams,
  ])

  return (
    isQueryInitComplete && isConfiguredFieldQueryInit && isCrmFieldQueryInit
  )
}

const useQueryUpdate = (isQueryInitComplete: boolean) => {
  const filters = useKanbanStorePersist((state) => state)
  const setSearchParams = useSearchParams()[1]
  const cardQuery = useKanbanStore2((state) => state.modal?.data?.query)
  const cardQueryValue = useKanbanStore2(
    (state) => state.modal?.data?.queryValue
  )

  useEffect(() => {
    if (isQueryInitComplete) {
      setSearchParams((params) => {
        const newParams = new URLSearchParams(params)

        Object.keys(filters)
          .filter((key) => key !== 'dispatch')
          .forEach((key) => {
            const typedKey = key as keyof KanbanStorePersist
            const value = filters[typedKey]

            switch (typedKey) {
              case 'search':
                if ((value as string).length > 0) {
                  newParams.set(typedKey, value as string)
                } else {
                  newParams.delete(typedKey)
                }
                break

              case 'selectedTab':
                newParams.set('tab', value as string)
                break

              case 'currentViewName':
                newParams.set('currentViewName', value as string)
                break

              case 'sortBy':
                if ((value as KanbanStorePersist['sortBy']) !== 'Newest') {
                  newParams.set(typedKey, value as string)
                } else {
                  newParams.delete(typedKey)
                }
                break

              case 'isEscalated':
                if ((value as boolean) === true) {
                  newParams.set(typedKey, value as string)
                } else {
                  newParams.delete(typedKey)
                }
                break

              case 'dateRangeType': {
                if (value) {
                  newParams.set(typedKey, value as string)
                } else {
                  newParams.delete(typedKey)
                }
                break
              }

              case 'dateRange': {
                if (filters.dateRangeType) {
                  newParams.delete(typedKey)
                  break
                }
                if (value) {
                  const startDate = moment((value as string[])[0]).toISOString()
                  const endDate = moment((value as string[])[1]).toISOString()
                  newParams.set(typedKey, JSON.stringify([startDate, endDate]))
                } else {
                  newParams.delete(typedKey)
                }
                break
              }

              case 'csm':
              case 'source':
              case 'urgency':
              case 'allTags':
              case 'cardType':
              case 'customers':
              case 'sentiment':
              case 'assignedTo':
              case 'workspaces':
              case 'solutionEngineers':
              case 'accountTypes': {
                if ((value as string[]).length) {
                  newParams.set(typedKey, JSON.stringify(value))
                } else {
                  newParams.delete(typedKey)
                }
                break
              }

              case 'configuredFieldValues': {
                const configuredFieldKeys = Object.keys(
                  value as KanbanStorePersist['configuredFieldValues']
                )
                configuredFieldKeys.forEach((key) => {
                  const fieldValue = (
                    value as KanbanStorePersist['configuredFieldValues']
                  )[key]
                  if (fieldValue.length) {
                    newParams.set(key, JSON.stringify(fieldValue))
                  } else {
                    newParams.delete(key)
                  }
                })
                break
              }
              case 'crmFieldValues': {
                const crmFieldKeys = Object.keys(
                  value as KanbanStorePersist['crmFieldValues']
                )

                crmFieldKeys.forEach((key) => {
                  const fieldValue = (
                    value as KanbanStorePersist['crmFieldValues']
                  )[key]
                  if (fieldValue.length) {
                    newParams.set(key, JSON.stringify(fieldValue))
                  } else {
                    newParams.delete(key)
                  }
                })
                break
              }
              default:
                break
            }
          })

        if (cardQuery && cardQueryValue) {
          newParams.delete('teamId')
          newParams.delete('ticketId')
          newParams.delete('requestId')
          const query = cardQuery.split(';')
          const queryValue = cardQueryValue.split(';')
          newParams.set(query[0], queryValue[0])
          newParams.set(query[1], queryValue[1])
        }

        return newParams
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isQueryInitComplete, cardQuery, cardQueryValue])
}

const useGetUsersList = (teamId?: string) => {
  const { data } = useQuery<Filters>(['kanbanFilters'], fetchKanbanFilters, {
    onError: () => {
      message.error('Error fetching filters')
    },
  })

  const { user } = useAuth()

  const assigneeFilterItems = useMemo(() => {
    if (!data?.assignees) {
      return []
    }
    const { assignees } = data || emptyObject
    const assigneeList = (assignees?.members as any[]) || []
    let list = [...assigneeList].filter((item: any) => item.id !== user?.id)
    list = list.map((item: any) => {
      return {
        ...item,
        key: item.id,
        label: item.name,
      }
    })
    list = [...list.sort((a: any, b: any) => a.label.localeCompare(b.label))]
    const myInfo = assigneeList?.find((item: any) => item.id === user?.id)
    if (myInfo) {
      list.unshift({
        ...myInfo,
        name: 'Me',
        label: 'Me',
        key: myInfo.id,
      })
    }
    list.unshift({
      _id: '',
      team_id: '',
      id: 'Unassigned',
      key: 'Unassigned',
      name: 'Unassigned',
      label: 'Unassigned',
      image: UnassignedIcon,
    })

    return list
  }, [user, data])

  const accountOwners = useMemo(() => {
    if (data?.csm) {
      return data.csm?.members || []
    }
    return []
  }, [data])

  const solutionEngineerItems = useMemo(() => {
    if (data?.solutionEngineers) {
      return data.solutionEngineers?.members || []
    }
    return []
  }, [data])

  const filteredAssigneeFilterItems = useMemo(() => {
    if (!teamId) {
      return assigneeFilterItems
    }

    return assigneeFilterItems.filter((item) => {
      if (item.team_id) {
        return item.team_id === teamId
      }
      return true
    })
  }, [assigneeFilterItems, teamId])

  return {
    assigneeFilterItems: filteredAssigneeFilterItems,
    accountOwners,
    solutionEngineerItems,
  }
}

const useConfiguredFields = () => {
  const { data, isSuccess } = useQuery(
    ['configured-fields'],
    fetchConfigureFields
  )

  const configuredFields = useMemo(() => {
    const fields: Array<{
      type: string | null
      name: string | null
      initialValue: string[]
      availableValues: string[]
      mandatoryOnClose: boolean
    }> = []

    if (!isSuccess) {
      return fields
    }

    const { configuredFieldValues } = useKanbanStorePersist.getState()

    data?.forEach((field: configuredFields) => {
      // if (field.type !== 'link') {
      const availableValues = field.available_values?.map((elem) => {
        return elem.value
      })
      const initialValue = configuredFieldValues[field?.category_name] || []

      fields.push({
        initialValue,
        availableValues,
        type: field.type,
        name: field.category_name,
        mandatoryOnClose: field.mandatory_on_close,
      })
      // }
    })

    return fields
  }, [data, isSuccess])

  return useMemo(() => {
    return {
      configuredFields,
      isFetched: data?.length > 0,
      listOfFields: configuredFields
        .filter((field) => field.type !== 'link')
        .map((field) => field.name),
    }
  }, [configuredFields, data?.length])
}

const useCrmFields = () => {
  const crmConfigList = useAccountsStore.getState().crmConfig

  const crmFields = useMemo(() => {
    const fields: Array<{
      type: string | null
      name: string | null
      initialValue: string[]
      availableValues: string[]
    }> = []

    if (isEmpty(crmConfigList)) {
      return fields
    }

    const { crmFieldValues } = useKanbanStorePersist.getState()

    crmConfigList?.additional_fields?.forEach((field) => {
      const availableValues = field.available_values?.map((elem) => {
        return elem.value
      })
      const initialValue = crmFieldValues[field?.field_name] || []

      fields.push({
        initialValue,
        availableValues,
        type: field.type,
        name: field.field_name,
      })
    })

    return fields
  }, [crmConfigList])

  return useMemo(() => {
    return {
      crmFields,
      isFetched: crmConfigList?.additional_fields?.length > 0,
      listOfFields: crmFields
        ?.filter((field) => field.type !== 'text')
        ?.map((field) => field.name),
    }
  }, [crmConfigList?.additional_fields?.length, crmFields])
}

export {
  useConfiguredFields,
  useCrmFields,
  useGetUsersList,
  useQueryInit,
  useQueryUpdate,
}

export const getRequestSourceInfo = (source?: string) => {
  if (source === 'EMAIL') {
    return { source: 'Email', icon: <img src={EmailIcon} alt="" /> }
  }
  if (source === 'WEB') {
    return { source: 'Web', icon: '🌐' }
  }
  return null
}

const useFetchSlackConversation = (card: any = {}) => {
  const { channelId, mergedTs } = card
  const ts = card.requestThreadTs || card.requestTs || card.ticketTs || card.ts

  const { isFetching: threadLoading, refetch } = useQuery(
    ['fetchSlackConversation', channelId, ts, mergedTs],
    ({ signal }) => {
      const abortController = signal ?? new AbortController().signal
      return fetchSlackConversation(
        {
          channelId,
          ts,
          mergedTs: mergedTs || [],
        },
        abortController
      )
    },
    {
      enabled: !!channelId,
      refetchOnMount: true,
      refetchInterval: 1000 * 15,
      onSuccess: async (data) => {
        try {
          await kanbanDrawerDB.slackConversations.put(
            {
              ts,
              channelId,
              data: data.data,
              channelIdAndTs: `${channelId}-${ts}`,
            },
            `${channelId}-${ts}`
          )
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      },
      onError: () => {
        message.error('Error fetching conversations')
      },
    }
  )

  const conv = useLiveQuery(async () => {
    const data = await kanbanDrawerDB.slackConversations.get(
      `${channelId}-${ts}`
    )
    return data
  }, [channelId, ts])

  return useMemo(() => {
    return {
      refetch,
      threadLoading,
      threadDetails: conv?.data || [],
    }
  }, [conv?.data, refetch, threadLoading])
}

export default useFetchSlackConversation

export const getTime = (timestamp: any) => {
  if (timestamp.includes('a few seconds ago')) {
    return 'just now'
  }
  return timestamp.replace('minutes', 'mins')
}

export const renderFileType = (fileType: string) => {
  if (fileType === 'image/png') {
    return <FileImage size={18} color="#E05D86" />
  }
  if (fileType === 'text/csv') {
    return <FileCsv size={18} color="#E05D86" />
  }
  if (fileType === 'image/jpeg') {
    return <FileImage size={18} color="#E05D86" />
  }
  if (fileType === 'video/mp4') {
    return <FileVideo size={18} color="#E05D86" />
  }
  if (fileType === 'application/pdf') {
    return <FilePdf size={18} color="#E05D86" />
  }
  if (fileType === 'text/plain') {
    return <FileText size={18} color="#E05D86" />
  } else {
    return <File size={18} color="#E05D86" />
  }
}

export const getLabelLogo = (
  statusIcon: string | undefined,
  status: string
) => {
  if (statusIcon !== undefined) {
    return <img src={statusIcon} alt={`${statusIcon} img`} />
  } else {
    if (status === 'Backlog' || status === 'To Do') {
      return (
        <CircleDashed color="var(--color-gray-8)" weight="bold" size="20" />
      )
    }
    if (status === 'Done' || status === 'Finished') {
      return (
        <CheckCircle color="var(--color-green-1)" weight="bold" size="20" />
      )
    }
    return <CircleNotch color="var(--color-blue-1)" weight="bold" size="20" />
  }
}

export const getLabelBgCol = (status: string) => {
  if (status === 'Backlog' || status === 'To Do') {
    return '#F1F0F0'
  }
  if (status === 'Done' || status === 'Finished') {
    return '#daecda'
  }
  return '#D2E4EF'
}

export const useActiveCard = () => {
  const cardId = useKanbanStore2((state) => state.modal?.data?.cardId)
  const isDrawerOpen = useKanbanStore2(
    (state) => state.modal?.type === MODAL_TYPES.KANBAN_DRAWER
  )
  const card = useKanbanBoardStore((state) => state.records[cardId])
  return (isDrawerOpen ? card : {}) as any
}

// NOT to be used for bulk updates
export const useKanbanDBUpdate = () => {
  const [prevCard, setPrevCard] = useState<any>(null)

  const updateDB = useCallback(
    async (_id: string, payload: Record<string, any>) => {
      const prevCardData = useKanbanBoardStore.getState().records[_id]
      setPrevCard(prevCardData)
      try {
        useKanbanBoardStore.dispatch({
          type: 'UPDATE_RECORD',
          payload: {
            _id,
            data: payload,
          },
        })
      } catch (error) {
        console.log(error)
      }
    },
    []
  )

  return useMemo(() => {
    return {
      updateDB,
      prevCard,
    }
  }, [prevCard, updateDB])
}

export const getStatusIndicatorColor = (status: any) => {
  switch (status) {
    case 'OPEN':
    case 'UNASSIGNED':
      return 'var(--color-blue-5)'

    case 'IN_PROGRESS':
      return 'var(--color-orange-2)'

    case 'ON_HOLD':
      return 'var(--color-gray-14)'

    case 'CLOSED':
      return 'var(--color-green-3)'

    default:
      return ''
  }
}

export const getPriorityIcon = (data: any) => {
  const pri =
    data.cardType === 'REQUEST' ? data.AI_requestUrgency : data.priority
  if (pri?.toLocaleLowerCase() === 'low') {
    return LowPriorityIcon
  }
  if (pri?.toLocaleLowerCase() === 'medium') {
    return MedPriorityIcon
  }
  if (pri?.toLocaleLowerCase() === 'high') {
    return HighPriorityIcon
  }
  return LowPriorityIcon
}

export const getPriorityTitle = (data: any) => {
  if (data.cardType === 'REQUEST') {
    return `${data.AI_requestUrgency} priority`
  }
  return `${data.priority} priority`
}

const defaultProfileUrl =
  'https://thena-medias.s3.us-west-1.amazonaws.com/profile.png'

export const getWorkspaceInfo = (data: any) => {
  if (data.isInternalHelpdesk) {
    if (data?.requestor?.avatar) {
      return [data.requestor?.name, data.requestor.avatar]
    }
    if (data?.createdBy?.avatar) {
      return [data.createdBy?.name, data.createdBy.avatar]
    }
    if (data?.customer_team?.icon?.image_230) {
      return [data.customer_team?.name, data.customer_team.icon.image_230]
    }
    return ['', defaultProfileUrl]
  }
  if (data?.customer_team?.icon?.image_230) {
    return [data.customer_team?.name, data.customer_team.icon.image_230]
  }
  if (data?.requestor?.avatar) {
    return [data.requestor?.name, data.requestor.avatar]
  }
  if (data?.createdBy?.avatar) {
    return [data.createdBy?.name, data.createdBy.avatar]
  }
  return ['', defaultProfileUrl]
}

export const isNewerData = (newData: any, existingData: any) => {
  // Assuming 'updatedAt' is a Date object or timestamp in milliseconds
  return newData.updatedAt > existingData.updatedAt
}

export const parallelUpdate = async (
  payloadList: Array<Record<string, any>>,
  apiFunction: any
) => {
  const mapper = async (data: any) => {
    try {
      return await apiFunction(data)
    } catch (error: any) {
      return { error: true, _id: data._id, errorMessage: error.message }
    }
  }

  return pMap(payloadList, mapper, { concurrency: 3 })
}

export const useUpdateRequest = () => {
  return useCallback(async (requestId: string) => {
    const data = await fetchKanbanRequest(requestId)
    if (data._id) {
      useKanbanBoardStore.dispatch({
        type: 'UPDATE_RECORD',
        payload: {
          _id: data._id,
          data,
        },
      })
    }
  }, [])
}

export const getLogoByIntegration = (integration: string) => {
  const text = integration?.toLowerCase() ?? ''

  switch (text) {
    case 'linear':
      return 'https://res.cloudinary.com/dqjkjie4a/image/upload/v1703748925/linear-logo_uy20fq.svg'

    case 'jira':
      return 'https://res.cloudinary.com/dqjkjie4a/image/upload/v1703587944/jira_wz1nuj.svg'

    case 'shortcut':
      return 'https://res.cloudinary.com/dqjkjie4a/image/upload/v1703751593/shortcut-icon_xopva6.svg'

    default:
      return ''
  }
}

export const logSocketUpdate = (data: any) => {
  console.log('SOCKET UPDATE TO STORE: ', data)
}

export const validateEmail = (inputText: string) => {
  const mailFormat = /\S+@\S+\.\S+/
  if (inputText.match(mailFormat)) {
    return true
  }
  return false
}

export const useSubStatusList = () => {
  const { data, isLoading } = useQuery(['subStatusList'], fetchCustomStatus, {
    onSuccess: (data) => {
      try {
        customStatusesDB.list.bulkPut(
          data.map((item: any, index: number) => ({ ...item, order: index }))
        )
      } catch (error) {
        console.log('CustomStatusesDB IndexedDB error: ', error)
      }
    },
    onError: () => {
      message.error('Error fetching substatus')
    },
  })
  return { data: data || [], isLoading }
}

export const getIcon = (parent: string) => {
  if (parent === 'INPROGRESS') {
    return (
      <img
        src={InProgressBoardIcon}
        alt="In progress"
        style={{ height: '20px', width: '20px' }}
      />
    )
  }
  if (parent === 'ONHOLD')
    return (
      <img
        src={OnholdBoardIcon}
        alt="On hold"
        style={{ height: '20px', width: '20px' }}
      />
    )
  if (parent === 'OPEN') {
    return (
      <img
        src={OpenBoardIcon}
        alt="Open"
        style={{ height: '18px', width: '18px' }}
      />
    )
  }
  if (parent === 'CLOSED') {
    return (
      <img
        src={ClosedBoardIcon}
        alt="Closed"
        style={{ height: '20px', width: '20px' }}
      />
    )
  }
}

export type MenuItem = Required<MenuProps>['items'][number]

export const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'divider' | 'group'
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const getDateRangeAndType = (dateRange: any, dateRangeType: any) => {
  if (dateRangeType === 'ALL_TIME') {
    return {
      filters: {
        startDate: '',
        endDate: '',
      },
    }
  }

  const isDateRangeAvailable = !!(dateRange && dateRange?.[0])
  if (isDateRangeAvailable) {
    let startDateTimeStamp = moment(dateRange?.[0] || null)
      .startOf('day')
      .valueOf()
      .toString()

    let endDateTimeStamp = moment(dateRange?.[1] || null)
      .endOf('day')
      .valueOf()
      .toString()

    if (dateRangeType) {
      const todayTimeStamp = moment().endOf('day').valueOf().toString()
      if (endDateTimeStamp !== todayTimeStamp) {
        const range = getDateRangeByType(dateRangeType as DateRangeType)
        useKanbanStorePersist.dispatch({
          type: 'SET_DATE_RANGE',
          payload: {
            dateRange: range,
          },
        })
        startDateTimeStamp = moment(range?.[0] || null)
          .startOf('day')
          .valueOf()
          .toString()
        endDateTimeStamp = moment(range?.[1] || null)
          .endOf('day')
          .valueOf()
          .toString()
      }
    }

    return {
      filters: {
        startDate: startDateTimeStamp,
        endDate: endDateTimeStamp,
      },
    }
  }

  return {
    filters: {
      startDate: '',
      endDate: '',
    },
  }
}

const setAITagsToLocalStorage = (records: any) => {
  setTimeout(
    (records) => {
      try {
        const storedTags = JSON.parse(
          localStorage.getItem('kanbanTags') ?? '[]'
        )
        const allTags = new Set(storedTags)
        if (records) {
          records?.forEach((item: any) => {
            item?.AI_generatedTags?.forEach((tags: string) => {
              allTags?.add(tags)
            })
          })
        }
        localStorage.setItem('kanbanTags', JSON.stringify([...allTags]))
      } catch (error) {
        console.log(error)
      }
    },
    0,
    records
  )
}

const cancelTokenAndRegenerate = () => {
  const cancelToken = useKanbanStore2.getState().cancelToken
  if (cancelToken) {
    cancelToken.cancel('Cancelled by user')
  }
  const newToken = axios.CancelToken.source()
  useKanbanStore2.dispatch({
    type: 'SET_CANCEL_TOKEN',
    payload: {
      cancelToken: newToken,
    },
  })

  return newToken.token
}

const fetchKanbanData = async (args: any) => {
  const { dateRange, fetchAfterEpoch } = args
  const dateRangeType = useKanbanStorePersist.getState().dateRangeType
  const payload: any = getDateRangeAndType(dateRange, dateRangeType)
  if (fetchAfterEpoch) {
    payload.filters.fetchAfterEpoch = fetchAfterEpoch.toString()
  }
  message.loading({
    duration: 0,
    content: fetchAfterEpoch ? 'Checking for updates' : 'Fetching requests',
    key: 'kanban-loading',
  })
  const cancelToken = cancelTokenAndRegenerate()
  const response = await fetchKanban(payload, cancelToken)
  return response
}

export const useKanbanQueries = () => {
  const dateRange = useKanbanStorePersist((state) => state.dateRange)
  const fetchAfterEpoch = useKanbanStorePersist(
    (state) => state.fetchAfterEpoch
  )

  const kanbanBoardDispatch = useKanbanBoardStore((state) => state.dispatch)
  const dispatch = useKanbanStore2((state) => state.dispatch)

  useEffect(() => {
    return () => {
      message.destroy('kanban-loading')
      const cancelToken = useKanbanStore2.getState().cancelToken
      if (cancelToken) {
        cancelToken.cancel('Cancelled on unmount')
      }
    }
  }, [])

  useQuery(
    ['kanban-initial-data'],
    () =>
      fetchKanbanData({
        dateRange,
        fetchAfterEpoch,
      }),
    {
      onSuccess: (data) => handleSuccess(data, fetchAfterEpoch),
      refetchOnMount: true,
    }
  )

  const isFirstRender = useRef(true)

  useFirstRenderSkip(() => {
    isFirstRender.current = false
  }, [dateRange])

  useQuery(
    ['kanban-data-on-date-change', dateRange],
    () =>
      fetchKanbanData({
        dateRange,
        fetchAfterEpoch: null,
      }),
    {
      onSuccess: (data) => handleSuccess(data, null),
      enabled: !isFirstRender.current,
    }
  )

  const handleSuccess = (data: any, fetchAfterEpoch: string | null) => {
    const records = data.records || []
    const recordsObject = records.reduce(
      (acc: any, item: any) => ({ ...acc, [item._id]: item }),
      {}
    )
    if (isEmpty(recordsObject)) {
      message.destroy('kanban-loading')
      updateFetchAfterEpoch()
      return
    }
    const drawerId = useKanbanStore2.getState().modal?.data?.cardId
    const drawerCard = useKanbanBoardStore.getState().records[drawerId]
    if (!recordsObject[drawerId] && drawerCard) {
      recordsObject[drawerId] = drawerCard
    }
    const isDummyDataPresent = useKanbanStore2.getState().isSampleData
    if (isDummyDataPresent) {
      useKanbanBoardStore.dispatch({
        type: 'PUT_RECORDS',
        payload: {
          records: recordsObject,
        },
      })
    } else {
      kanbanBoardDispatch({
        type: fetchAfterEpoch ? 'BULK_UPDATE_RECORDS' : 'PUT_RECORDS',
        payload: { records: recordsObject },
      })
    }
    updateFetchAfterEpoch()
    setAITagsToLocalStorage(records)
    dispatch({
      type: 'INIT',
      payload: {
        total: data?.total,
        isSampleData: data?.isSampleData,
      },
    })
    message.destroy('kanban-loading')
  }
}

export const getStatusName = (status: string) => {
  if (status === 'INPROGRESS') {
    return 'IN-PROGRESS'
  } else if (status === 'ONHOLD') {
    return 'ON HOLD'
  } else if (status === 'OPEN') {
    return 'UNASSIGNED'
  }
  return status
}

export const getLocalStorageOrderKey = (
  tabValue: string | null,
  isTicketsPage: boolean,
  currentViewName: string
) => {
  if (tabValue === 'customer-requests') {
    if (currentViewName === 'Default View') {
      return !isTicketsPage ? 'requestDefaultView' : 'ticketDefaultView'
    } else {
      return 'saved'
    }
  } else if (tabValue === 'internal-helpdesk') {
    if (currentViewName === 'Default View') {
      return !isTicketsPage
        ? 'internalRequestDefaultView'
        : 'internalTicketDefaultView'
    } else {
      return 'saved'
    }
  }
  return ''
}
