import { CheckCircleOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import styled from 'styled-components'
import Loading from '../../../../images/icons/loading.svg'
import StatusIcon from '../../../../images/icons/status.svg'
import BulkEditMenu from './BulkEditMenu'

export const BulkVerify = ({
  onVerify,
  onInvalidate,
  setOpen,
  isOpen,
}: {
  onVerify: () => void
  onInvalidate: () => void
  setOpen: (isOpen: boolean) => void
  isOpen: boolean
}) => {
  const content = (
    <Wrapper>
      <Row
        onClick={onVerify}
        className="content-item"
        align="middle"
        justify="start"
      >
        <CheckCircleOutlined
          style={{ color: 'var(--color-green-3)', fontSize: '16px' }}
        />
        Verified
      </Row>
      <Row justify="start" onClick={onInvalidate} className="content-item">
        <img src={Loading} style={{ width: '16px' }} alt="unverified" />
        Unverified
      </Row>
    </Wrapper>
  )

  return (
    <BulkEditMenu
      label="Status"
      iconAlt="status"
      iconSrc={StatusIcon}
      className="qna-filter"
      content={content}
      isOpen={isOpen}
      setOpen={setOpen}
    />
  )
}

const Wrapper = styled.div`
  .content-item {
    padding: 6px 8px;
    margin-bottom: 4px;
    gap: 8px;
    cursor: pointer;
  }
`
