import Dexie, { Table } from 'dexie'

export interface Conversation {
  data: any
  ts: string
  channelId: string
  channelIdAndTs: string
}

export class KanbanDrawerDB extends Dexie {
  slackConversations!: Table<Conversation>

  constructor() {
    super('kanbanDrawerDB')
    this.version(1)
      .stores({
        slackConversations: 'channelIdAndTs, channelId, ts', // Primary key and indexed props
      })
      .upgrade(() => {
        this.on('versionchange', function (event: any) {
          console.log('KanbanDrawerDb VersionChange', event)
          window.location.reload()
        })
      })
  }
}

export const kanbanDrawerDB = new KanbanDrawerDB()
