import { AxiosResponse } from 'axios'
import { api } from '.'
import { SendInviteType, UpdateRoleType } from '../modules/admins/definitions'

export interface FetchMembersForAdminsType {
  members: Member[]
  total: number
}

export interface Member {
  _id: string
  id: string
  name: string
  team_id: string
  real_name: string
  display_name: string
  image: string
  email: string
  isAdmin: boolean
  isActive: boolean
}

export interface FetchAdminsType {
  admins: Admin[]
  total: number
}

export interface Admin {
  _id: string
  id: string
  name: string
  team_id: string
  real_name: string
  display_name: string
  image: string
  email: string
  owner: boolean
}
export const fetchMembersForAdmins = ({ page = 0, search = '' }) =>
  api
    .get<AxiosResponse<FetchMembersForAdminsType>>(`/v1/admins/member-list`, {
      params: { search, page, showEmail: true },
    })
    .then(({ data: { data } }) => data)

export const fetchAdmins = () =>
  api
    .get<AxiosResponse<FetchAdminsType>>('/v1/admins')
    .then(({ data }) => data.data)

export const addAdmin = (userId: string) =>
  api.post('/v1/admins', { userId }).then(({ data }) => data.data)

export const deleteAdmin = (userId: string) =>
  api.delete('/v1/admins', { data: { userId } }).then(({ data }) => data.data)

export const getPaymentsPortal = () =>
  api
    .get('/v1/payments/createPortalSession')
    .then(({ data: { data: dt } }) => dt)

export const fetchMembers = () =>
  api.get('/v1/users/manage').then(({ data }) => data)

export const sendInvite = (data: SendInviteType) =>
  api.post('/v1/users/send-invites', data).then((data) => data)

export const updateRole = (data: UpdateRoleType) =>
  api.patch('/v1/user/role', data).then((data) => data)

export const sharedSlackChannel = (create: boolean) =>
  api
    .patch('/v1/create-channel-with-us', { createChannel: create })
    .then(({ data }) => data.data)
