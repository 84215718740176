import { Button, Checkbox, Collapse, Input, Spin, Typography } from 'antd'
import {
  CaretDown,
  CaretUp,
  CheckCircle,
  PencilLine,
  Warning,
} from 'phosphor-react'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { fetchFreshdeskIntegration } from '../../../api/integrations'
import { helphubUtil } from '../../../utils/helphub'
import { useConnectedApp } from '../../ConnectedApps'
import { ExtendedConnectorConfiguration } from '../ConnectorConfiguration'
import { FreshdeskConfigurationDetailsType } from './../definitions'
import { useFreshdesk } from './FreshdeskProvider'

const { Panel } = Collapse
const { Text } = Typography

interface FreshdeskConfigurationType {
  apiKey: string
  baseurl: string
  requestTypes: string[]
  helpdesk_name: string
}
type Props = {
  requestType: {
    label: string
    value: string
    disabled: boolean
  }[]
}

export const FreshdeskSetUp = ({ requestType }: Props) => {
  const { isEdition } = useConnectedApp()
  const {
    isSetUpOpen,
    connectionPassed,
    connectionFailed,
    saveRequestTypesMutation,
    testFreshdeskConnectionMutation,
    setIsSetUpOpen,
  } = useFreshdesk()

  const { data: freshdeskConfiguration, isLoading } = useQuery(
    'fetch-freshdesk-integration',
    fetchFreshdeskIntegration,
    {
      select: (
        data: FreshdeskConfigurationDetailsType
      ): FreshdeskConfigurationType => {
        return {
          apiKey: data?.apiKey as string,
          baseurl: data?.baseurl as string,
          requestTypes: data?.requestTypes as string[],
          helpdesk_name: data?.helpdesk_name as string,
        }
      },
    }
  )

  const onSubmit = (data: any) => {
    testFreshdeskConnectionMutation?.mutate({
      apiKey: data.apiKey,
      baseurl: data.baseurl,
      requestTypes: data.requestTypes,
      helpdesk_name: data.helpdesk_name,
    })
  }

  const onSave = (data: any) => {
    saveRequestTypesMutation?.mutate({
      apiKey: data.apiKey,
      baseurl: data.baseurl,
      requestTypes: data.requestTypes,
      helpdesk_name: data.helpdesk_name,
    })
  }

  const { handleSubmit, reset, control, formState } = useForm()

  useEffect(() => {
    reset(freshdeskConfiguration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freshdeskConfiguration])

  const { errors, isDirty } = formState
  const disableSave =
    (!isEdition && !connectionPassed) ||
    (isEdition && isDirty && !connectionPassed)

  // Loader
  if (isLoading)
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Spin size="large" />
      </div>
    )

  return (
    <Collapse
      ghost
      accordion
      expandIconPosition="end"
      activeKey={isSetUpOpen ? '1' : '0'}
      className="border border-solid border-brand-border"
      expandIcon={() => {
        if (isEdition) return <PencilLine size={18} color="#696F7B" />
        if (!isSetUpOpen) return <CaretDown size={18} color="#7A00FF" />
        return <CaretUp size={18} color="#7A00FF" />
      }}
      onChange={() => {
        setIsSetUpOpen((p) => !p)
      }}
    >
      <Panel
        header={
          <div className="flex items-center justify-between">
            <h4 className="m-0 ml-3 font-semibold text-sm 3xl:text-[length:var(--sm)]">
              Setup Integration
            </h4>
            <p
              className="text-primary m-0 font-medium cursor-pointer"
              onClick={() => helphubUtil(133832)}
              role="button"
              data-intercom-target="Settings Integrations Salesforce Setup"
            >
              Where do I find this?
            </p>
          </div>
        }
        key="1"
      >
        <div>
          <div className="mt-3">
            <Text className="font-medium">Helpdesk Name</Text>
            <Controller
              name="helpdesk_name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="middle"
                  className="mt-1"
                  placeholder="Helpdesk Name"
                  disabled={connectionPassed}
                  {...field}
                  required
                  status={errors.helpdesk_name ? 'error' : undefined}
                />
              )}
            />
            {Boolean(errors.helpdesk_name) && (
              <Text className="font-small text-red-500">
                {errors?.helpdesk_name?.type === 'required' &&
                  'Helpdesk Name is required'}
              </Text>
            )}
          </div>
          <div className="mt-3">
            <Text className="font-medium">API Key</Text>
            <Controller
              name="apiKey"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  size="middle"
                  className="mt-1"
                  placeholder="API Key"
                  disabled={connectionPassed}
                  {...field}
                  required
                  status={errors.apiKey ? 'error' : undefined}
                />
              )}
            />
            {Boolean(errors.apiKey) && (
              <Text className="font-small text-red-500">
                {errors?.apiKey?.type === 'required' && 'API Key is required'}
              </Text>
            )}
          </div>
          <div className="space-y-4 mt-3">
            <div>
              <Text className="font-medium">Base URL</Text>
              <div className="flex space-x-2 mt-1">
                <div className="w-full">
                  <Controller
                    name="baseurl"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Input
                        placeholder="Base URL"
                        className="mt-1"
                        size="middle"
                        {...field}
                        status={
                          connectionFailed || Boolean(errors.baseurl)
                            ? 'error'
                            : undefined
                        }
                        disabled={connectionPassed}
                        required
                      />
                    )}
                  />
                  {Boolean(errors.baseurl) && (
                    <Text className="font-small text-red-500">
                      {errors?.baseurl?.type === 'required' &&
                        'Base Url is required'}
                    </Text>
                  )}
                </div>
                <Button
                  className="text-primary border-primary/40 mt-1"
                  size="middle"
                  type="default"
                  onClick={handleSubmit(onSubmit)}
                  disabled={connectionPassed}
                  loading={testFreshdeskConnectionMutation?.isLoading}
                >
                  Test connection
                </Button>
              </div>
              <div className="flex space-x-2 mt-1">
                {connectionPassed && (
                  <span className="flex items-center">
                    <CheckCircle className="mr-1" size={14} color="#2BBC58" />{' '}
                    <Text className="text-xs text-success font-medium">
                      Connection Successful!
                    </Text>
                  </span>
                )}
                {connectionFailed && !connectionPassed && (
                  <span className="flex items-center">
                    <Warning className="mr-1" size={14} color="#D91029" />{' '}
                    <Text className="text-xs text-error font-medium">
                      Test connection failed!
                    </Text>
                  </span>
                )}
                {!connectionFailed && !connectionPassed && (
                  <Text className="text-xs text-muted font-medium">
                    Testing connection required
                  </Text>
                )}
              </div>
            </div>
            <div className="col-span-2">
              <Text className="font-medium">Request type(s) to send...</Text>
              <div className="mt-1 w-full">
                <Controller
                  name="requestTypes"
                  control={control}
                  render={({ field }) => (
                    <Checkbox.Group {...field} className="flex">
                      {requestType.map((el) => (
                        <div className="">
                          <Checkbox
                            key={el.value}
                            value={el.value}
                            disabled={disableSave || el.disabled}
                          >
                            <span className="mr-2 text-sm font-normal">
                              {el.label}
                            </span>
                          </Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  )}
                />
              </div>
            </div>
            <div className="w-full flex justify-end ">
              <Button
                className="text-primary border-primary/40 mt-4 mb-1 font-bold px-8"
                size="middle"
                type="default"
                disabled={disableSave}
                onClick={handleSubmit(onSave)}
                loading={saveRequestTypesMutation?.isLoading}
              >
                Save
              </Button>
            </div>
            <ExtendedConnectorConfiguration
              isEdition={isEdition}
              className="mb-8"
            />
          </div>
        </div>
      </Panel>
    </Collapse>
  )
}
