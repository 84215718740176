import { Button, Divider, Typography } from 'antd'
import { Info, LockSimple, MapTrifold } from 'phosphor-react'
import { useMutation } from 'react-query'
import { Link as LinkRoute } from 'react-router-dom'
import { sharedSlackChannel } from '../../api/admins'
import { AddToSlackButton, FormContainer, Stepper } from '../../components'
import { useAuth } from '../../context'
import { useAddToSlack } from '../../context/AddToSlackProvider'
import GDPR from '../../images/icons/gdpr.webp'
import SOC from '../../images/icons/soc.webp'
import SlackIcon from '../../images/logos/slack.svg'
import { welcomeInfo } from '../Welcome/definitions'
import { addToSlackInfo } from './definitions'

const { Paragraph, Title, Link, Text } = Typography

interface Props {
  children?: JSX.Element
}

export const AddToSlack = (props: Props) => {
  const { checked } = useAddToSlack()
  const sharedSlackChannelMutation = useMutation(sharedSlackChannel)
  const onClickHandler = () => {
    sharedSlackChannelMutation.mutate(checked)
  }
  const { user } = useAuth()
  return (
    <div className="welcomeQuestion flex flex-col justify-center items-center left-0 absolute top-0 m-auto w-full z-[400] mt-[180px]">
      <div className="w-2/6">
        <Stepper current={2} />
      </div>
      <FormContainer className="w-[45%] border border-solid border-brand-border rounded-md mt-14">
        <div className="flex flex-col gap-8">
          <div className="space-y-6">
            <Title
              className="text-center"
              level={3}
              style={{ marginBottom: 0 }}
            >
              Add Thena to Slack
            </Title>
            <Paragraph className="text-left text-gray-500">
              Thena empowers organisations through :
            </Paragraph>
            <ul
              style={{
                padding: 0,
                margin: 0,
                paddingBottom: 0,
                marginLeft: '18px',
                marginTop: '14px',
              }}
            >
              <li>
                Bi-directional integrations: Improve{' '}
                <strong>customer service productivity by 25%.</strong>
              </li>
              <li>
                First response alerts: Reduce{' '}
                <strong>customer response times by 40%.</strong>
              </li>
              <li>
                <strong>Unified analytics </strong> on customer engagement and
                request resolutions.
              </li>
            </ul>
          </div>

          <AddToSlackButton className="mt-2" />
          <div className="flex justify-end items-center gap-2">
            <LinkRoute to="/product-tour">
              <Button
                type="link"
                className="font-medium text-brand-accent flex items-center gap-1"
                size="large"
              >
                <MapTrifold size={30} color="#4A299C" /> Take a Tour
              </Button>
            </LinkRoute>
            <Button
              onClick={onClickHandler}
              href={addToSlackInfo.card.buttonLink}
              type="default"
              icon={
                <img
                  className="w-6 h-6 mr-3 mt-6"
                  src={SlackIcon}
                  alt="slack icon"
                />
              }
              size="large"
              className="flex justify-center items-center btn py-6 px-6 font-bold border border-solid border-gray-dark rounded-[4px]"
            >
              <span className="mt-6">{addToSlackInfo.card.buttonText}</span>
            </Button>
          </div>
          <div className="flex items-start justify-start gap-1 bg-label-default px-1 py-[2px] w-fit">
            <Info size={16} weight="bold" />
            <Text className="text-xs 3xl:text-[length:var(--xs)] text-default font-semibold">
              If you are logged into multiple workspaces, select the{' '}
              {user?.team_name} option on the next screen.
            </Text>
          </div>
        </div>
      </FormContainer>
      <div className="flex mt-4">
        <div className="flex items-center gap-1">
          <LockSimple size={14} color="#696F7B" />
          <span className="text-muted">
            We do not store any conversation data{' '}
          </span>
        </div>
        <div>
          <Divider className="py-2 text-gray-500" type="vertical" />
        </div>
        <Link
          href={welcomeInfo.links.terms.url}
          target="_blank"
          className="text-muted visited:text-muted welcome"
        >
          <span className="underline"> {welcomeInfo.links.terms.text}</span>
        </Link>
        <div>
          <Divider className="py-2 text-gray-500" type="vertical" />
        </div>
        <Link
          href={welcomeInfo.links.privacy.url}
          target="_blank"
          className="text-muted visited:text-muted welcome"
        >
          <span className="underline"> {welcomeInfo.links.privacy.text}</span>
        </Link>
        <div>
          <Divider className="py-2 text-gray-500" type="vertical" />
        </div>
        <div className="flex items-center gap-1">
          <img src={GDPR} alt="gdpr-compliance" className="w-5 h-5" />
          <img src={SOC} alt="soc" className="w-5 h-5" />
        </div>
      </div>
    </div>
  )
}
