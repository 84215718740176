import { Spin } from 'antd'
import { useLiveQuery } from 'dexie-react-hooks'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Virtuoso } from 'react-virtuoso'
import styled, { css } from 'styled-components'
import { kanbanBoardDB } from '../../../DB/kanbanBoardDB'
import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut'
import RequestEmpty from '../../../images/icons/empty-requests.svg'
import { useAccountsStore } from '../../../store/accountsStore'
import { useKanbanStore2 } from '../../../store/kanbanStore2'
import { useKanbanStorePersist } from '../../../store/kanbanStorePersist'
import Row from '../../Requests/List/Row/Row'
import Section from '../../Requests/List/Section'
import { MODAL_TYPES, SECTION_LIST } from '../../Requests/constants'
import { EmptyState } from './EmptyState'

const Wrapper = styled.div<{ $addPadding?: boolean }>`
  height: 100%;
  ${({ $addPadding }) => {
    if ($addPadding) {
      return css`
        padding-bottom: 88px;
      `
    }
  }}
  position: relative;
`

const SpinWrapper = styled.div`
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
`

const RequestList = () => {
  const dispatch = useKanbanStore2((state) => state.dispatch)
  const dispatchPersist = useKanbanStorePersist((state) => state.dispatch)
  const openSections = useKanbanStorePersist((state) => state.openSections)
  const drawer = useAccountsStore((state) => state.drawer)
  const data = useLiveQuery(async () => {
    const data = await kanbanBoardDB.conversations.toArray()
    return data ?? []
  }, [])
  const filteredRecords = data?.filter(
    (item) => item.relationship_id === drawer?.data?.record?._id
  )

  const handlePanelChange = (key: string) => {
    if (openSections.includes(key)) {
      return dispatchPersist({
        type: 'SET_OPEN_SECTIONS',
        payload: {
          openSections: openSections.filter((sectionKey) => sectionKey !== key),
        },
      })
    }
    dispatchPersist({
      type: 'SET_OPEN_SECTIONS',
      payload: {
        openSections: [...openSections, key],
      },
    })
  }

  const processedList = useMemo(() => {
    const unassignedItems = (filteredRecords || []).filter(
      (item) => item.status === 'OPEN' && openSections.includes('UNASSIGNED')
    )
    const inProgressItems = (filteredRecords || []).filter(
      (item) =>
        item.status === 'IN_PROGRESS' && openSections.includes('IN_PROGRESS')
    )
    const onHoldItems = (filteredRecords || []).filter(
      (item) => item.status === 'ON_HOLD' && openSections.includes('ON_HOLD')
    )
    const closedItems = (filteredRecords || []).filter(
      (item) => item.status === 'CLOSED' && openSections.includes('CLOSED')
    )

    const processedList = [
      SECTION_LIST[0],
      ...unassignedItems,
      SECTION_LIST[1],
      ...inProgressItems,
      SECTION_LIST[2],
      ...onHoldItems,
      SECTION_LIST[3],
      ...closedItems,
    ]

    return processedList
  }, [filteredRecords, openSections])

  const firstItemIndex = processedList.findIndex((item) => !!item._id)
  const firstItem = processedList[firstItemIndex]

  const [keyFocusedRowId, setKeyFocusedRowId] = useState('')

  useEffect(() => {
    if (firstItemIndex > -1) {
      setKeyFocusedRowId(firstItem?._id)
    }
  }, [firstItemIndex, firstItem?._id])

  const scrollRef = useRef<any>(null)
  const checkedList = useKanbanStore2((state) => state.checkedList)

  const currentRowIdRef = useRef(keyFocusedRowId)

  // TODO: need to try +1, +2 and +3 (down)
  // TODO: need to try -1, -2 and -3 (up)

  const scrollToIndex = (rowId: string) => {
    setKeyFocusedRowId(rowId)
    const index = processedList.findIndex((item) => item._id === rowId)
    scrollRef.current.scrollIntoView({
      index,
      align: 'center',
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      const currentRowIndex = processedList.findIndex(
        (item) => item._id === keyFocusedRowId
      )
      currentRowIdRef.current = keyFocusedRowId
      if (event.key === 'ArrowDown') {
        if (processedList[currentRowIndex + 1]?._id) {
          scrollRef.current.scrollIntoView({
            align: 'center',
            index: currentRowIndex + 1,
          })
          currentRowIdRef.current = processedList[currentRowIndex + 1]._id
          setKeyFocusedRowId(processedList[currentRowIndex + 1]._id)
        } else if (processedList[currentRowIndex + 2]?._id) {
          scrollRef.current.scrollIntoView({
            align: 'center',
            index: currentRowIndex + 2,
          })
          currentRowIdRef.current = processedList[currentRowIndex + 2]._id
          setKeyFocusedRowId(processedList[currentRowIndex + 2]._id)
        }
      } else if (event.key === 'ArrowUp') {
        if (processedList[currentRowIndex - 1]?._id) {
          scrollRef.current.scrollIntoView({
            align: 'center',
            index: currentRowIndex - 1,
          })
          currentRowIdRef.current = processedList[currentRowIndex - 1]._id
          setKeyFocusedRowId(processedList[currentRowIndex - 1]._id)
        } else if (processedList[currentRowIndex - 2]?._id) {
          scrollRef.current.scrollIntoView({
            align: 'center',
            index: currentRowIndex - 2,
          })
          currentRowIdRef.current = processedList[currentRowIndex - 2]._id
          setKeyFocusedRowId(processedList[currentRowIndex - 2]._id)
        }
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [processedList, keyFocusedRowId, checkedList])

  useKeyboardShortcut(['x'], (event) => {
    event.preventDefault()
    const isChecked = checkedList.includes(currentRowIdRef.current)
    if (isChecked) {
      dispatch({
        type: 'SET_CHECKED_LIST',
        payload: {
          checkedList: checkedList.filter(
            (id) => id !== currentRowIdRef.current
          ),
        },
      })
    } else {
      dispatch({
        type: 'SET_CHECKED_LIST',
        payload: {
          checkedList: [...checkedList, currentRowIdRef.current],
        },
      })
    }
  })

  useKeyboardShortcut(['\r'], (event) => {
    event.preventDefault()
    const data = processedList.find((item) => item._id === keyFocusedRowId)
    if (data) {
      const currentColDetails =
        data.status === 'OPEN' ? 'UNASSIGNED' : data.status
      dispatch({
        type: 'SET_MODAL',
        payload: {
          modal: {
            type: MODAL_TYPES.KANBAN_DRAWER,
            data: {
              cardId: data._id,
              currentCol: currentColDetails,
              query:
                data.cardType === 'REQUEST'
                  ? 'requestId;teamId'
                  : 'ticketId;teamId',
              queryValue:
                data.cardType === 'REQUEST'
                  ? `${data.requestId};${data.teamId}`
                  : `${data._id};${data.teamId}`,
            },
          },
        },
      })
    }
  })
  // TODO: prevent all key actions when the kanban drawer is open

  const messagesLoading = useKanbanStore2((state) => state.messagesLoading)

  const renderSectionWithItem = (item: any, index: number) => {
    if (item.sectionKey) {
      return (
        <Section
          Icon={item.Icon}
          name={item.name}
          key={item.sectionKey}
          sectionKey={item.sectionKey}
          onPanelToggle={handlePanelChange}
          backgroundColor={item.backgroundColor}
          isOpen={openSections.includes(item.sectionKey)}
          itemsList={(filteredRecords || []).filter(
            (obj) =>
              (obj.status === 'OPEN' ? 'UNASSIGNED' : obj.status) ===
              item.sectionKey
          )}
        />
      )
    }
    return (
      <Row
        data={item}
        index={index}
        key={item._id}
        scrollRef={scrollRef}
        setKeyFocusedRowId={setKeyFocusedRowId}
        isKeyFocused={item._id === keyFocusedRowId}
        isReadonly={true}
      />
    )
  }
  if (processedList.length === 4) {
    return (
      <EmptyState
        title="No requests yet!"
        description="When you have any requests or issues raised by this customer, they'll be organized here for quick access and management"
        footer="Learn how requests work in our"
        helpText="Help Document"
        image={RequestEmpty}
      />
    )
  }

  return (
    <Wrapper $addPadding={checkedList.length > 0}>
      {messagesLoading && (
        <SpinWrapper>
          <Spin size="large" />
        </SpinWrapper>
      )}
      <Virtuoso
        ref={scrollRef}
        data={processedList}
        overscan={32 * 5}
        style={{ height: '100vh' }}
        totalCount={200}
        itemContent={(index, item) => {
          return renderSectionWithItem(item, index)
        }}
      />
    </Wrapper>
  )
}
export default RequestList
