import { Button, Checkbox, Row } from 'antd'
import styled from 'styled-components'
import { CustomSpinner } from '../Spinner'
import { PrimaryBtn } from '../StyledComponent'

type ActionType = 'update' | 'delete'

type RemoveResourceFormProps = {
  title: string
  description: string
  actionType: ActionType
  onCancel: () => void
  onAction: () => void
  onChange: (checkStatus: boolean) => void
  okText?: string
  checkboxLabels?: string[]
  isSaving?: boolean
}

const RemoveResourceForm = ({
  title,
  description,
  actionType,
  onCancel,
  onAction,
  onChange,
  okText = 'OK',
  checkboxLabels = [],
  isSaving = false,
}: RemoveResourceFormProps) => {
  return (
    <Wrapper>
      <div className="remove-form-content">
        <span className="remove-form-title">{title}</span>
        <span className="remove-form-title-desc">{description}</span>
        {actionType === 'update' && checkboxLabels.length > 0 && (
          <Checkbox
            onChange={(event) => onChange(event.target.checked)}
            className="update-form-checkbox"
          >
            {checkboxLabels[0]}
          </Checkbox>
        )}
      </div>
      {actionType === 'delete' && (
        <div style={{ paddingTop: '24px' }}>
          {checkboxLabels.map((label, index) => (
            <div className="remove-form-checkbox">
              <Checkbox key={index}>{label}</Checkbox>
            </div>
          ))}
        </div>
      )}
      <Row className="remove-form-btns" justify="center">
        <Button
          onClick={onCancel}
          disabled={isSaving}
          className="custom-cancel-btn"
        >
          Cancel
        </Button>
        <PrimaryBtn
          onClick={onAction}
          disabled={isSaving}
          icon={isSaving ? <CustomSpinner /> : null}
        >
          {okText}
        </PrimaryBtn>
      </Row>
    </Wrapper>
  )
}

export default RemoveResourceForm

const Wrapper = styled.div`
  .remove-form-content {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .remove-form-title {
    font-size: 20px;
    font-weight: 600;
  }
  .remove-form-title-desc {
    color: var(--color-gray-6);
    font-size: 14px;
    font-weight: 400;
  }
  .update-form-checkbox {
    margin-top: 24px;
    justify-content: center;
  }
  .remove-form-checkbox {
    padding-left: 52px;
    .ant-checkbox-wrapper {
      margin-left: 0 !important;
    }
  }
  .remove-form-btns {
    padding-top: 24px;
    gap: 8px;
  }
  .custom-cancel-btn:hover {
    color: var(--color-purple-1);
    border-color: var(--color-purple-1);
  }
`
