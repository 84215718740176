/* eslint-disable max-lines */
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import {
  Avatar,
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Empty,
  Input,
  Menu,
  MenuProps,
  Modal,
  Popover,
  Spin,
  TimePicker,
  Tooltip,
  Typography,
  message,
} from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useFlags } from 'launchdarkly-react-client-sdk'
import _, { debounce, isEmpty } from 'lodash'
import moment from 'moment'
import {
  CaretDown,
  CaretLeft,
  CaretUp,
  Hash,
  Info,
  Lock,
  MagnifyingGlass,
  PencilSimple,
  TrashSimple,
} from 'phosphor-react'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { fetchAllChannels, fetchSelectedChannels } from '../../api/channels'
import {
  createChannelGroup,
  deleteChannelGroup,
  getChannelGroup,
  sendCampaign,
  updateCampaign,
  updateChannelGroup,
} from '../../api/marketingAutomation'
import { syncSlack } from '../../api/misc'
import { useAuth } from '../../context'
import useEvents from '../../hooks/useEvents'
import CloseIcon from '../../images/icons/close_icon.svg'
import { ChannelApiResponseType } from '../../modules/channels/definitions'
import { useCurrentIntegration } from '../../pages/Accounts/utils'
import { useMaEditorStore } from '../../store/maEditor'
import {
  getCombinedChannelLength,
  getSelectedChannelLength,
} from '../../utils/editor'
import { UnsendCampaign } from './UnsendCampaign'
import './index.css'
const { Panel } = Collapse
const { Text } = Typography

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  position: relative;
  height: 32px;
  .add-filter-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-gray-4);
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .plus-icon {
      margin-right: 2px;
    }
    cursor: pointer;
    padding: 15px 6px;
  }
  .reset-btn {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-gray-4);
    height: 24px;
    padding: 15px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 4px;
    }
    cursor: pointer;
  }
  .add-filter-btn:hover,
  .reset-btn:hover {
    background-color: var(--color-gray-3);
    border-radius: 4px;
  }
`

export type ChannelType = {
  id: string
  name: string
  is_ext_shared?: boolean
  is_private: boolean
  externalMembers?: number
  external_members?: number
  isThenaAdded?: boolean
}

type SaveCampaignType =
  | 'send_campaign'
  | 'schedule_campaign'
  | 'unsend_campaign'
  | undefined
interface SendCampaignModalProps {
  edit: boolean
  campaign: any
  gotoMainPage: () => void
  closeSaveCampaignModal: () => void
  blocks: any
  campaignType: SaveCampaignType
  nameError: boolean
  setNameError: React.Dispatch<React.SetStateAction<boolean>>
  data: any
  sendAsUser: boolean
  sendingUserId: string
  showUnsendModal?: boolean
}

export const SendCampaignModal = ({
  campaign,
  campaignType,
  gotoMainPage,
  closeSaveCampaignModal,
  blocks,
  nameError,
  setNameError,
  data,
  sendAsUser,
  sendingUserId,
  edit = false,
  showUnsendModal,
}: SendCampaignModalProps) => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const track = useEvents()

  const [search, setSearch] = useState<string>('')
  const [showScheduling, setShowScheduling] = useState<boolean>(false)
  const [selectedChannels, setSelectedChannels] = useState<string[]>([
    ...new Set<string>(campaign?.campaign_message?.channels || []),
  ])
  const state = useMaEditorStore((state) => state.initialState)
  const [campaignName, setCampaignName] = useState<string | undefined>(
    state.campaignName
  )

  const [schedulingDate, setSchedulingDate] = useState<moment.Moment | null>(
    campaignType !== 'send_campaign' &&
      campaign?.campaign_message?.scheduled_date
      ? moment(campaign?.campaign_message?.scheduled_date)
      : null
  )
  const [schedulingTime, setSchedulingTime] = useState<moment.Moment | null>(
    campaignType !== 'send_campaign' &&
      campaign?.campaign_message?.scheduled_date
      ? moment(campaign?.campaign_message?.scheduled_date)
      : null
  )
  const [audience, setAudience] = useState<any>(null)
  const [channelGroup, setChannelGroup] = useState<string | undefined>(
    undefined
  )
  const [updateAudience, setUpdateAudience] = useState<any>(null)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    setError((e) => ({
      ...e,
      campaignName: (campaignName || '').includes('(copy)'),
    }))
  }, [campaignName])

  const [error, setError] = useState<{
    campaignName: boolean
  }>({
    campaignName: false,
  })
  const [selectedChannelGroup, setSelectedChannelGroup] = useState<string[]>([])
  const [hashMappedChannels, setHashMappedChannels] = useState<any>({})
  const [hashMappedNotAddedChannels, setHashMappedNotAddedChannels] =
    useState<any>({})

  const [channelsArray, setChannelArray] = useState<ChannelType[]>([])
  const [notAddedChannelsArray, setNotAddedChannelArray] = useState<
    ChannelType[]
  >([])

  const [filteredChannelArray, setFilteredChannelArray] = useState<
    ChannelType[]
  >([])
  const [filteredNotAddedChannelArray, setFilteredNotAddedChannelArray] =
    useState<ChannelType[]>([])

  const sendCampaignMutation = useMutation(
    !edit ? sendCampaign : updateCampaign,
    {
      onSuccess: (data: any) => {
        closeSaveCampaignModal()
        gotoMainPage()

        if (data.campaign_message.status === 'SCHEDULED') {
          queryClient.invalidateQueries(['all-marketing-campaigns'])
        } else {
          queryClient.invalidateQueries(['all-marketing-campaigns'])
          queryClient.setQueryData(['all-marketing-campaigns'], (old: any) => {
            return old.map((item: any) => {
              return item._id === campaign._id
                ? {
                    ...data,
                    campaign_message: {
                      ...data.campaign_message,
                      status:
                        item._id === campaign._id
                          ? campaignType === 'send_campaign'
                            ? 'SENDING'
                            : 'SCHEDULED'
                          : data.campaign_message.status,
                    },
                  }
                : item
            })
          })
        }
      },
      onError(error) {
        track('MA Editor Send Campaign Error', {
          campaignId: campaign?._id,
          error: JSON.stringify(error),
        })
      },
    }
  )

  const createChannelGroupMutation = useMutation(createChannelGroup, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(['all-channel-groups'])
      setIsModalOpen(false)
      setSelectedChannelGroup((p) => [...p, data?.groupName])
    },
    onError: (error: any) => {
      message.error(error?.response?.data?.message || 'Something went wrong')
    },
  })

  const { data: channelGroupList, isLoading: loading } = useQuery(
    'all-channel-groups',
    getChannelGroup,
    {
      onError: () => {
        message.error('Something went wrong')
      },
    }
  )

  const { isLoading, refetch, isFetching } = useQuery(
    ['fetching-channels', { type: 'MARKETING_AUTOMATION' }],
    () => fetchSelectedChannels({ type: 'MARKETING_AUTOMATION' }),
    {
      onError: () => {
        message.error('Something went wrong')
      },
      onSettled: (data) => {
        setChannelArray(
          data.channels.map((ele: any) => ({ ...ele, isThenaAdded: true })) ||
            []
        )
        setFilteredChannelArray(
          data.channels.map((ele: any) => ({ ...ele, isThenaAdded: true })) ||
            []
        )
        setHashMappedChannels(() => {
          const channelObj: any = {}
          ;(data.channels || []).forEach(
            (ch: ChannelType) => (channelObj[ch.id] = ch)
          )
          return channelObj
        })
      },
    }
  )

  const {
    isLoading: isNonAddedChannelLoading,
    refetch: refetchNonAddedChannels,
    isFetching: isNonAddedChannelFetching,
  } = useQuery<ChannelApiResponseType>(
    ['fetch-non-added-channels'],
    () =>
      fetchAllChannels({
        search: '',
        page: 0,
        sort: 'ascending',
        getMemberCounts: true,
        filter: {
          members: [],
          channelType: 'all',
        },
      }),
    {
      onError: () => {
        message.error('Something went wrong')
      },
      onSettled: (d) => {
        setNotAddedChannelArray(
          d?.channels
            ?.filter((item) => !item.is_archived)
            ?.map((ele: any) => ({ ...ele, isThenaAdded: false })) || []
        )
        setFilteredNotAddedChannelArray(
          d?.channels
            ?.filter((item) => !item.is_archived)
            ?.map((ele: any) => ({ ...ele, isThenaAdded: false })) || []
        )
        setHashMappedNotAddedChannels(() => {
          const channelObj: any = {}
          ;(d?.channels || []).forEach(
            (ch: ChannelType) => (channelObj[ch.id] = ch)
          )
          return channelObj
        })
      },
    }
  )

  useEffect(() => {
    if (
      (data || []).some(
        (ele: any) =>
          ele?.campaign_name?.trim().toLowerCase() !==
          campaignName?.trim().toLowerCase()
      )
    ) {
      setNameError(false)
    }
  }, [])

  useEffect(() => {
    if (
      (data || []).some(
        (ele: any) =>
          ele?.campaign_name?.trim().toLowerCase() ===
            campaignName?.trim().toLowerCase() && ele._id === campaign._id
      )
    ) {
      setNameError(false)
    }
  }, [campaignName])

  useEffect(() => {
    if (!search) {
      setFilteredChannelArray(
        channelsArray.map((ele: any) => ({ ...ele, isThenaAdded: true }))
      )
      setFilteredNotAddedChannelArray(
        notAddedChannelsArray.map((ele: any) => ({
          ...ele,
          isThenaAdded: false,
        }))
      )
    } else {
      setFilteredChannelArray(
        channelsArray
          .map((ele: any) => ({ ...ele, isThenaAdded: true }))
          .filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))
      )
      setFilteredNotAddedChannelArray(
        notAddedChannelsArray
          .map((ele: any) => ({
            ...ele,
            isThenaAdded: false,
          }))
          .filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))
      )
    }
  }, [search])

  useEffect(() => {
    refetch()
    refetchNonAddedChannels()
  }, [])

  // const totalReach = useMemo(() => {
  //   return (channelsArray || []).reduce(
  //     (acc, ch) => acc + (ch.externalMembers || 0),
  //     0
  //   )
  // }, [channelsArray])

  // const expectedReach = useMemo(() => {
  //   return (selectedChannels || []).reduce(
  //     (acc, ch) => acc + (hashMappedChannels[ch]?.externalMembers || 0),
  //     0
  //   )
  // }, [selectedChannels, hashMappedChannels])

  // const totalNotAddedChannelsReach = useMemo(() => {
  //   return (notAddedChannelsArray || []).reduce(
  //     (acc, ch) => acc + (ch.external_members || 0),
  //     0
  //   )
  // }, [notAddedChannelsArray])

  // const expectedNotAddedChannelsReach = useMemo(() => {
  //   return (selectedChannels || []).reduce(
  //     (acc, ch) =>
  //       acc + (hashMappedNotAddedChannels[ch]?.external_members || 0),
  //     0
  //   )
  // }, [hashMappedNotAddedChannels, selectedChannels])

  const handleOnBlur = () => {
    if (campaignName) {
      setError((st) => ({ ...st, campaignName: false }))
      dispatch({
        type: 'SET_CAMPAIGN_NAME',
        payload: { campaignName: campaignName },
      })
    } else {
      setError((st) => ({ ...st, campaignName: true }))
    }
  }
  const savingLoader = useMaEditorStore((state) => state.savingLoader)

  const initEventRef = useRef(false)
  useEffect(() => {
    const timerId = setTimeout(() => {
      initEventRef.current = true
    }, 5 * 1000)
    return () => clearTimeout(timerId)
  }, [])

  useEffect(() => {
    if (initEventRef.current && (nameError || savingLoader)) {
      track('MA Editor Send Campaign Disabled', {
        campaignId: campaign?._id,
      })
    }
  }, [track, nameError, savingLoader, campaign?._id])

  const handleDate = (date: any) => {
    setSchedulingDate(date)
  }
  const handleTime = (time: moment.Moment | null, dateString: any) => {
    setSchedulingTime(time)
  }
  const handleSchedulingScreen = () => {
    setShowScheduling(true)
    ;(window as any).analytics.track('Schedule Campaign Modal open action')
  }

  const handleSchedulingScreenClose = () => {
    setShowScheduling(false)
  }
  const dispatch = useMaEditorStore((state) => state.dispatch)

  const handleCampaignName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      data.some(
        (ele: any) =>
          ele?.campaign_name?.toLowerCase() ===
            e.target.value.trim().toLowerCase() &&
          ele?.campaign_name?.toLowerCase() !==
            campaignName?.trim().toLowerCase()
      )
    ) {
      setNameError(true)
      setCampaignName(e.target.value)
    } else if (campaignName !== e.target.value) {
      setCampaignName(e.target.value)
      setNameError(false)
    } else if (e.target.value.trim() === '') {
      setNameError(true)
    } else {
      setNameError(true)
    }
  }

  const handleAddAllChannels = (e: any, key: string) => {
    e.stopPropagation()
    if (key === '1') {
      setSelectedChannels((prevSelectedChannels) => [
        ...prevSelectedChannels,
        ...filteredChannelArray.map(({ id }) => id),
      ])
    }
    if (key === '2') {
      setSelectedChannels((prevSelectedChannels) => [
        ...prevSelectedChannels,
        ...filteredNotAddedChannelArray.map(({ id }) => id),
      ])
    }
  }

  const handleClearAllChannels = (e: any, key: string) => {
    e.stopPropagation()
    if (key === '1') {
      const filteredSelectedState = selectedChannels.filter(
        (id) => ![...filteredChannelArray].map(({ id }) => id).includes(id)
      )
      setSelectedChannels(filteredSelectedState)
    }
    if (key === '2') {
      const filteredSelectedState = selectedChannels.filter(
        (id) =>
          ![...filteredNotAddedChannelArray].map(({ id }) => id).includes(id)
      )
      setSelectedChannels(filteredSelectedState)
    }
  }

  const handleCheckBox = ({
    checked,
    channelId,
  }: {
    checked: boolean
    channelId: string
  }) => {
    setSelectedChannels((channelIdArray) => {
      const index = channelIdArray.indexOf(channelId)
      if (checked && index >= -1) {
        return [...channelIdArray, channelId]
      }

      if (!checked && index >= -1) {
        return [...channelIdArray].filter((pCh) => pCh !== channelId)
      }

      return channelIdArray
    })
  }

  const handleGroupFromChannelsSelected = (channels: string[]) => {
    if (isUpdating && isEmpty(selectedChannelGroup)) {
      return
    }
    channelGroupList?.forEach((grp: any) => {
      if (grp.channelIds.length <= channels.length) {
        const hasGrpChannels = grp.channelIds.every((c: string) =>
          channels.includes(c)
        )
        if (hasGrpChannels) {
          setSelectedChannelGroup((gps) => [
            ...new Set([...gps, grp.groupName]),
          ])
        } else {
          setSelectedChannelGroup((gps) =>
            gps.filter((gp) => gp !== grp.groupName)
          )
        }
      } else {
        setSelectedChannelGroup((gps) =>
          gps.filter((gp) => gp !== grp.groupName)
        )
      }
    })
  }

  useEffect(() => {
    handleGroupFromChannelsSelected(selectedChannels)
  }, [selectedChannels])

  const thenaNonAddedChannels = filteredNotAddedChannelArray.filter(
    (channel: ChannelType) => selectedChannels.includes(channel.id)
  )
  const [hasThenaNonAddedChannels, setHasThenaNonAddedChannels] =
    useState(false)
  const handleSubmit = (state: string) => {
    let hasError = false
    if (!campaignName) {
      setError((st) => ({ ...st, campaignName: true }))
      hasError = true
    }
    if (selectedChannels.length <= 0) {
      hasError = true
      message.error('Select at least one channel')
    }
    if (thenaNonAddedChannels.length > 0) {
      hasError = true
      setHasThenaNonAddedChannels(true)
    }
    if (!hasError && !hasThenaNonAddedChannels) {
      state === 'schedule'
        ? (window as any).analytics.track('Schedule Campaign button clicked')
        : (window as any).analytics.track('Send Campaign button clicked')
      localStorage.setItem(
        `campaign_${campaign?._id}_timer`,
        JSON.stringify(30)
      )
      const finalChannelsList = selectedChannels?.filter(
        (channel) =>
          filteredChannelArray?.filter((c) => c?.id?.includes(channel)).length >
            0 ||
          filteredNotAddedChannelArray?.filter((c) => c?.id?.includes(channel))
            .length > 0
      )
      sendCampaignMutation.mutate({
        _id: campaign?._id,
        channels: [...new Set<string>(finalChannelsList)],
        campaign_name: campaignName as string,
        blocks: JSON.stringify(blocks),
        scheduled_date:
          state === 'schedule' && schedulingTime !== null
            ? schedulingDate === null
              ? moment(
                  `${moment().format('YYYY-MM-DD')} ${moment(
                    schedulingTime
                  ).format('HH:mm:ss')}`,
                  'YYYY-MM-DD HH:mm:ss'
                )
              : moment(
                  `${moment(schedulingDate).format('YYYY-MM-DD')} ${moment(
                    schedulingTime
                  ).format('HH:mm:ss')}`,
                  'YYYY-MM-DD HH:mm:ss'
                )
            : null,
        text: campaign?.text,
        username: campaign?.yourApp || user?.team_name,
        icon_url: campaign?.campaign_message?.icon_url || user?.team_image,
        groups: (channelGroupList || []).map((ele: any) => ele._id),
        sendAsUser: sendAsUser,
        sendingUserId: sendingUserId,
      })
    }
  }

  // const reachPercentage = Math.ceil(
  //   (100 * (expectedReach + expectedNotAddedChannelsReach)) /
  //     (totalReach + totalNotAddedChannelsReach)
  // )

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today
    return current && current < moment().subtract(1, 'days')
  }

  const combinedData = getCombinedChannelLength(
    filteredChannelArray,
    filteredNotAddedChannelArray
  )

  const selectedIdsLength = getSelectedChannelLength(
    filteredChannelArray,
    filteredNotAddedChannelArray,
    selectedChannels
  )

  const handleEditAudience = (e: any, data: any) => {
    e.stopPropagation()
    setAudience(data)
    channelsFromGroup([data.groupName])
  }

  const showDeleteConfirm = (data: any) => {
    confirm({
      title: 'Are you sure to delete this audience?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAudienceMutation.mutate(data)
        removeChannelsFromGroup([data.groupName])
      },
      onCancel() {},
    })
  }
  const deleteAudienceMutation = useMutation(deleteChannelGroup, {
    onSuccess: () => {
      message.success('Audience deleted!')
      queryClient.invalidateQueries(['all-channel-groups'])
      setIsUpdating(false)
    },
    onError: () => {
      message.error('Something went wrong!')
    },
  })
  const updateAudienceMutation = useMutation(updateChannelGroup, {
    onSuccess: () => {
      message.success('Audience updated!')
      queryClient.invalidateQueries(['all-channel-groups'])
      setAudience(null)
      setIsUpdating(false)
    },
    onError: (error: any) => {
      message.error(error?.response?.data?.message || 'Something went wrong')
    },
  })
  const updateAudienceHandler = () => {
    updateAudienceMutation.mutate({
      groupName: channelGroup as string,
      channelIds: selectedChannels,
      internalChannelIds: [],
      _id: audience._id,
    })
  }
  const items: MenuProps['items'] = (channelGroupList || []).map(
    (record: any) => ({
      key: record.groupName,
      label: (
        <div className="flex items-center justify-between">
          <Text ellipsis={{ tooltip: true }}>{record.groupName}</Text>

          <div className="flex items-center gap-3">
            <Tooltip title="Edit">
              <Button
                size="small"
                type="link"
                icon={
                  <PencilSimple
                    size="15"
                    weight="bold"
                    className="hover:text-[var(--color-purple-4)] text-[var(--color-gray-4)] mt-1"
                  />
                }
                onClick={(e) => {
                  handleEditAudience(e, record)
                  setIsUpdating(true)
                }}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                size="small"
                type="link"
                icon={
                  <TrashSimple
                    size="15"
                    weight="bold"
                    className="hover:text-[var(--color-red-1)] text-[var(--color-gray-4)] mt-1"
                  />
                }
                onClick={(e) => {
                  e.stopPropagation()
                  setIsUpdating(true)
                  showDeleteConfirm(record)
                }}
                disabled={deleteAudienceMutation.isLoading}
              />
            </Tooltip>
          </div>
        </div>
      ),
    })
  )

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  const handleSave = () => {
    // create new channel grouping logic
    let hasError = false

    if (!channelGroup) {
      hasError = true
      message.error('Enter audience name')
    }
    if (selectedChannels.length <= 0) {
      hasError = true
      message.error('Select at least one channel')
    }
    if (!hasError) {
      createChannelGroupMutation.mutate({
        groupName: channelGroup as string,
        channelIds: selectedChannels,
        internalChannelIds: [],
      })
      setChannelGroup('')
      window.analytics.track('New audience created', {
        groupName: channelGroup,
        channelIds: selectedChannels,
        campaignId: campaign?._id,
      })
    }
  }

  const handleSaveGroup = () => {
    setIsModalOpen(true)
  }
  const handleGroupName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChannelGroup(e.target.value)
  }

  const handleChannelsFromGroup = (selectedKeys: string[]) => {
    const channelSet = new Set<string>()
    const groups = channelGroupList?.filter((ele: any) =>
      selectedKeys.includes(ele.groupName)
    )
    groups.forEach((grp: any) => {
      ;(grp?.channelIds || []).forEach((c: string) => {
        channelSet.add(c)
      })
    })
    const grpChannelIds: string[] = channelGroupList
      .map((c: any) => c.channelIds)
      .reduce((acc: string[], channels: string[]) => [...acc, ...channels], [])
    const channelsNotInGrp = selectedChannels.filter(
      (c) => !grpChannelIds.includes(c)
    )
    setSelectedChannels([
      ...new Set<string>([...channelSet, ...channelsNotInGrp]),
    ])
  }

  const channelsFromGroup = (selectedKeys: string[]) => {
    const channelSet = new Set<string>()
    const groups = channelGroupList?.filter((ele: any) =>
      selectedKeys.includes(ele.groupName)
    )
    groups.forEach((grp: any) => {
      ;(grp?.channelIds || []).forEach((c: string) => {
        channelSet.add(c)
      })
    })
    setSelectedChannels([...new Set<string>([...channelSet])])
  }

  const removeChannelsFromGroup = (selectedKeys: string[]) => {
    const groupsChannel = channelGroupList?.find((ele: any) =>
      selectedKeys.includes(ele.groupName)
    )
    // Logic to handle common channels in multiple groups
    const selectedGroups = channelGroupList?.filter((ele: any) =>
      selectedChannelGroup.includes(ele.groupName)
    )

    const firstObject = selectedGroups[0]
    const otherObjects = selectedGroups.slice(1)
    let commonChannel: any[] = []
    if (!isEmpty(otherObjects)) {
      commonChannel = firstObject.channelIds
      for (const object of otherObjects) {
        commonChannel = commonChannel.filter((channelId: any) =>
          object.channelIds.includes(channelId)
        )
      }
    }
    setSelectedChannelGroup((gps) =>
      gps.filter((gp) => gp !== groupsChannel.groupName)
    )
    const filteredArray = selectedChannels.filter(
      (item) => !groupsChannel.channelIds.includes(item)
    )
    if (commonChannel.length > 0) {
      setSelectedChannels([...filteredArray, ...commonChannel])
    } else {
      setSelectedChannels(filteredArray)
    }
  }

  const handleMenuSelect = ({ selectedKeys }: { selectedKeys: string[] }) => {
    setSelectedChannelGroup(selectedKeys)
    handleChannelsFromGroup(selectedKeys)
  }
  const handleMenuDeselect = ({ selectedKeys }: { selectedKeys: string[] }) => {
    setSelectedChannelGroup(selectedKeys)
    handleChannelsFromGroup(selectedKeys)
  }

  const slackSync = useMutation(syncSlack, {
    onSuccess: () => {
      window.localStorage.setItem('slackSynced', 'true')
      queryClient.invalidateQueries(['fetchAllChannels'])
      queryClient.invalidateQueries(['fetchSelectedChannels'])
    },
  })

  const renderThenaAddedChannels = () => {
    if (_.isEmpty(filteredChannelArray)) {
      return (
        <div className="flex justify-center items-center  w-full">
          <Empty description="Please Add Thena to Channels!" />{' '}
        </div>
      )
    } else {
      return (
        <div className="grid grid-cols-2 gap-4 my-4">
          {filteredChannelArray
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((channel: ChannelType) => (
              <div key={channel.id} className="inline-flex items-center h-full">
                <Checkbox
                  className="border-black"
                  checked={selectedChannels.includes(channel.id)}
                  onChange={({
                    target: { checked },
                  }: CheckboxChangeEvent): void => {
                    handleCheckBox({
                      checked,
                      channelId: channel.id,
                    })
                  }}
                >
                  <div
                    className="flex justify-between w-full items-center font-medium text-ellipsis"
                    title={channel.name}
                  >
                    <div className={`flex items-center gap-1 text-default`}>
                      {channel.is_private ? (
                        <Lock size={16} />
                      ) : (
                        <Hash size={16} />
                      )}
                      <Text
                        ellipsis={{ tooltip: true }}
                        className={`w-36 text-default`}
                      >
                        {channel.name}
                      </Text>
                      {/* <Tooltip title="Channel Reach">
            <Badge
              count={
                hashMappedChannels[channel.id]
                  ?.externalMembers ||
                hashMappedNotAddedChannels[channel.id]
                  ?.external_members ||
                0
              }
              showZero
              color="#E5EDFF"
              style={{
                color: '#2156DD',
                fontWeight: 600,
              }}
            />
          </Tooltip> */}
                    </div>
                  </div>
                </Checkbox>
              </div>
            ))}
        </div>
      )
    }
  }

  const renderThenaNonAddedChannels = () => {
    if (_.isEmpty(filteredNotAddedChannelArray)) {
      return (
        <div className="flex justify-center items-center  w-full">
          <Empty description="Please add channels!" />{' '}
        </div>
      )
    } else {
      return (
        <div className="grid grid-cols-2 gap-4 my-4">
          {filteredNotAddedChannelArray
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((channel: ChannelType) => (
              <div key={channel.id} className="inline-flex items-center h-full">
                <Checkbox
                  className="border-black"
                  checked={selectedChannels.includes(channel.id)}
                  onChange={({
                    target: { checked },
                  }: CheckboxChangeEvent): void => {
                    handleCheckBox({
                      checked,
                      channelId: channel.id,
                    })
                  }}
                >
                  <div
                    className="flex justify-between w-full items-center font-medium text-ellipsis"
                    title={channel.name}
                  >
                    <div className={`flex items-center gap-1 text-default`}>
                      {channel.is_private ? (
                        <Lock size={16} />
                      ) : (
                        <Hash size={16} />
                      )}
                      <Text
                        ellipsis={{ tooltip: true }}
                        className={`w-36 text-default`}
                      >
                        {channel.name}
                      </Text>
                      {/* <Tooltip title="Channel Reach">
              <Badge
                count={
                  hashMappedChannels[channel.id]
                    ?.externalMembers ||
                  hashMappedNotAddedChannels[channel.id]
                    ?.external_members ||
                  0
                }
                showZero
                color="#E5EDFF"
                style={{
                  color: '#2156DD',
                  fontWeight: 600,
                }}
              />
            </Tooltip> */}
                    </div>
                  </div>
                </Checkbox>
              </div>
            ))}
        </div>
      )
    }
  }

  const getExtra = (key: string) => (
    <FilterButton className="wsa-filter-chip">
      <div
        className="add-filter-btn"
        onClick={(e) => handleAddAllChannels(e, key)}
      >
        <PlusOutlined className="plus-icon" />
        Add All
      </div>
      <div
        className="reset-btn"
        onClick={(e) => handleClearAllChannels(e, key)}
      >
        <img src={CloseIcon} alt="refresh icon" className="w-3 h-3" />
        Clear All
      </div>
    </FilterButton>
  )

  const { confirm } = Modal

  const showConfirm = () => {
    confirm({
      title: 'Confirm Channel Addition',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          Add Thena to{' '}
          <Tooltip
            title={
              <div className="flex flex-col">
                {thenaNonAddedChannels.map((item) => (
                  <span>{item.name}</span>
                ))}
              </div>
            }
          >
            <span className="text-[var(--color-blue-1)] cursor-pointer">
              {thenaNonAddedChannels.length}
              {thenaNonAddedChannels.length === 1 ? ' channel' : ' channels'}
            </span>
          </Tooltip>
        </div>
      ),
      onOk() {
        setHasThenaNonAddedChannels(false)
        sendCampaignMutation.mutate({
          _id: campaign?._id,
          channels: [...new Set<string>(selectedChannels)],
          campaign_name: campaignName as string,
          blocks: JSON.stringify(blocks),
          scheduled_date: moment(
            `${moment(schedulingDate).format('YYYY-MM-DD')} ${moment(
              schedulingTime
            ).format('HH:mm:ss')}`,
            'YYYY-MM-DD HH:mm:ss'
          ),
          text: campaign?.text,
          username: campaign?.yourApp || user?.team_name,
          icon_url: campaign?.campaign_message?.icon_url || user?.team_image,
          groups: (channelGroupList || []).map((ele: any) => ele._id),
          sendAsUser: sendAsUser,
          sendingUserId: sendingUserId,
        })
      },
      onCancel() {
        setHasThenaNonAddedChannels(false)
      },
      okText: 'Confirm',
      centered: true,
    })
  }
  useEffect(() => {
    const updateGroup = channelGroupList?.find(
      (item: any) => item._id === audience?._id
    )
    setUpdateAudience(updateGroup)
    setChannelGroup(updateGroup?.groupName)
  }, [audience])
  const flags = useFlags()
  const { enabledIntegrationDetails } = useCurrentIntegration()

  return (
    <>
      {hasThenaNonAddedChannels && showConfirm()}
      {campaignType === 'unsend_campaign' ? (
        <UnsendCampaign
          showUnsendModal={showUnsendModal}
          closeSaveCampaignModal={closeSaveCampaignModal}
          campaign={campaign}
          setSearch={setSearch}
          search={search}
        />
      ) : (
        <>
          <Modal
            centered
            style={{ top: 20 }}
            open={true}
            footer={null}
            title={
              campaignType === 'send_campaign' && !showScheduling
                ? 'Send Campaign'
                : 'Schedule Campaign'
            }
            width={!showScheduling ? '60%' : '35%'}
            onCancel={
              showScheduling
                ? handleSchedulingScreenClose
                : closeSaveCampaignModal
            }
          >
            {!showScheduling ? (
              <>
                <div className="flex items-center w-full justify-start mb-3">
                  <Text className="mr-3" strong>
                    Campaign Name:
                  </Text>
                  <div>
                    <Input
                      name="campaignName"
                      size="large"
                      id="campaignName"
                      maxLength={500}
                      value={campaignName}
                      placeholder="Enter Campaign Name"
                      autoComplete={'off'}
                      status={error.campaignName || nameError ? 'error' : ''}
                      onChange={handleCampaignName}
                      onBlur={handleOnBlur}
                      className="w-[250px]"
                    />

                    {error.campaignName &&
                      !(campaignName || '').includes('(copy)') && (
                        <p className="text-red-500 mb-0">
                          Campaign name required
                        </p>
                      )}
                    {nameError && (
                      <Text type="danger">Please change campaign name</Text>
                    )}
                  </div>
                </div>
                {/* Reach vs potential reach */}
                {/* <div className="flex items-start flex-col justify-center gap-1 w-full mt-6 border border-solid border-brand-border p-2">
            <Text className="font-medium font-base">
              Estimated Reach vs Potential Reach
            </Text>
            <div className="w-[100%]">
              With the selected channels, this post would reach out to{' '}
              {expectedReach + expectedNotAddedChannelsReach} external user(s)
              out of a potential reach of{' '}
              {totalReach + totalNotAddedChannelsReach} external user(s)
            </div>
            <div className="flex justify-start w-[100%] mt-6 gap-3 mb-6 ml-3">
              <Progress
                type="circle"
                percent={reachPercentage}
                format={(percent) => (
                  <span className="font-semibold text-[#1d6561]">
                    {percent}%
                  </span>
                )}
                strokeColor="#18C7BC"
                strokeWidth={10}
                trailColor="#E7E7E7"
                showInfo={true}
                width={100}
              />
              <div className="flex flex-col items-start justify-center">
                <Text>
                  <strong>Estimated Reach: </strong>
                  {expectedReach + expectedNotAddedChannelsReach}{' '}
                </Text>
                <Text>
                  <strong>Total Reach: </strong>
                  {totalReach + totalNotAddedChannelsReach}{' '}
                </Text>
              </div>
            </div>
          </div> */}
                {/** Channel Container */}

                <div className="mt-6">
                  <div className="text-sm font-medium">
                    Select channels ({selectedIdsLength}/{combinedData})
                  </div>
                  <div
                    className="mt-3 border border-solid border-brand-border p-2 rounded h-96"
                    style={{ position: 'relative' }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center justify-start gap-3 relative">
                        <Input
                          name="search"
                          size="large"
                          allowClear
                          id="search"
                          className="form-input w-60 h-8 shadow-sm col-span-2"
                          placeholder="Search"
                          prefix={<MagnifyingGlass />}
                          autoComplete={'off'}
                          onChange={debounce(
                            (e) => setSearch(e.target.value),
                            300
                          )}
                          disabled={isLoading || isFetching}
                        />
                        <Button
                          title="Refresh"
                          icon={<ReloadOutlined color="#4A299C" />}
                          type="default"
                          loading={isFetching || isNonAddedChannelFetching}
                          onClick={() => {
                            refetch()
                            refetchNonAddedChannels()
                            slackSync.mutate()
                            message.success(
                              'Refreshing… this might take a few mins'
                            )
                          }}
                          className="text-brand-accent border-brand-accent border-solid border w-7 h-7"
                        />
                        {/* {enabledIntegrationDetails?.name && (
                          <CRMFilters
                            setFilteredChannelArray={setFilteredChannelArray}
                            channelsArray={channelsArray}
                          />
                        )} */}
                      </div>
                      <div className="flex items-center">
                        <Popover
                          style={{ width: '100px' }}
                          placement="topRight"
                          content={
                            <div className="flex flex-col gap-4">
                              <Input
                                placeholder="Enter name"
                                onChange={handleGroupName}
                                value={channelGroup}
                              />{' '}
                              <div className="flex items-center gap-2">
                                <Button type="default" onClick={handleCancel}>
                                  Cancel
                                </Button>
                                <Button
                                  type="primary"
                                  onClick={handleSave}
                                  loading={createChannelGroupMutation.isLoading}
                                >
                                  Save
                                </Button>
                              </div>
                            </div>
                          }
                          title="Create new audience"
                          trigger="click"
                          open={isModalOpen}
                          // onOpenChange={handleOpenChange}
                        >
                          <Button
                            className="inline-flex items-center text-primary font-medium"
                            type="link"
                            onClick={handleSaveGroup}
                            // icon={<Plus />}
                          >
                            Save Audience
                          </Button>
                        </Popover>
                      </div>
                    </div>
                    <Divider className="m-0 mt-2" />
                    {isLoading || isNonAddedChannelLoading ? (
                      <div className=" my-4 flex justify-center items-center">
                        <Spin />
                      </div>
                    ) : (
                      <div className="relative h-5/6 w-full">
                        <div
                          className={`mb-3 px-2 pr-2 mt-2 border-solid border border-brand-border rounded bg-white h-full w-[70%]`}
                        >
                          <Collapse
                            bordered={false}
                            defaultActiveKey={['1', '2']}
                            expandIconPosition="end"
                            expandIcon={({ isActive }) => {
                              return isActive ? (
                                <CaretUp size={20} color="#4A299C" />
                              ) : (
                                <CaretDown size={20} color="#4A299C" />
                              )
                            }}
                            className="ml-5 border-none mb-3 mt-3 h-[95%] overflow-y-auto overflow-x-hidden bg-white"
                          >
                            <Panel
                              header={
                                <div className="text-base font-semibold text-[#4a299c]">
                                  Thena Added Channels (
                                  {filteredChannelArray.length})
                                </div>
                              }
                              key="1"
                              extra={getExtra('1')}
                            >
                              {renderThenaAddedChannels()}
                            </Panel>
                            <Panel
                              header={
                                <div className="text-[#117B75] font-semibold text-base">
                                  Channels Without Thena (
                                  {filteredNotAddedChannelArray.length})
                                </div>
                              }
                              key="2"
                              extra={getExtra('2')}
                            >
                              {renderThenaNonAddedChannels()}
                            </Panel>
                          </Collapse>
                        </div>
                        <div className="grid gap-1 mb-4 right-0 top-0 absolute h-full border border-solid border-brand-border rounded">
                          <div
                            style={{
                              height: '100%',
                              backgroundColor: '#fff',
                              width: '250px',
                              transition: 'width 0.3s',
                              overflow: 'scroll',
                            }}
                          >
                            <>
                              <div
                                className={`bg-white w-full flex items-center filter  justify-start gap-1`}
                              >
                                <div className="mt-1 mb-2 p-5 text-base font-semibold text-[#4a299c]">
                                  Audience
                                </div>
                              </div>
                              {items?.length === 0 && (
                                <div className="w-full bg-white h-full">
                                  {
                                    <span className="bg-[var(--color-gray-1)] p-1 rounded text-[var(--color-gray-4)] flex flex-col items-start justify-center w-11/12 mx-2 mt-2 text-xs 3xl:text-[length:var(--xs)] font-medium">
                                      <span>No Groups to show</span>
                                      <span>Please create a new group!</span>
                                    </span>
                                  }
                                </div>
                              )}

                              <Menu
                                multiple={true}
                                mode="inline"
                                theme="light"
                                items={items}
                                style={{
                                  width: '100%',
                                  backgroundColor: '#fff',
                                }}
                                selectedKeys={selectedChannelGroup}
                                onSelect={handleMenuSelect}
                                onDeselect={handleMenuDeselect}
                              />
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-end gap-3 mt-3 w-full">
                  <div className="flex gap-2 items-center">
                    <Button
                      type="ghost"
                      onClick={handleSchedulingScreen}
                      className="inline-flex items-center text-brand-accent border-hover-accent"
                      disabled={nameError}
                    >
                      Scheduling options
                    </Button>
                    <Button
                      onClick={() =>
                        campaignType === 'send_campaign'
                          ? handleSubmit('send')
                          : handleSchedulingScreen()
                      }
                      type="primary"
                      loading={sendCampaignMutation.isLoading}
                      disabled={nameError || savingLoader}
                    >
                      {campaignType === 'send_campaign'
                        ? 'Send Campaign'
                        : 'Create Schedule'}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col mb-5">
                  <Text>Scheduled date</Text>
                  <DatePicker
                    onChange={handleDate}
                    className="w-[50%]"
                    value={schedulingDate}
                    allowClear={false}
                    disabledDate={disabledDate}
                  />
                </div>
                <div className="flex flex-col mb-5">
                  <Text>Scheduled time</Text>
                  <TimePicker
                    use12Hours
                    format="hh: mm A"
                    allowClear={false}
                    className="w-[50%]"
                    onChange={handleTime}
                    value={schedulingTime}
                    onSelect={(value) => {
                      setSchedulingTime(value)
                    }}
                  />
                </div>
                <div className="flex items-center gap-1 bg-label-default px-1 py-[2px] w-fit mb-3">
                  <Info size={16} weight="bold" />
                  <Text className="text-xs 3xl:text-[length:var(--xs)] text-default font-medium">
                    Your current timezone is:{' '}
                    {Intl.DateTimeFormat().resolvedOptions().timeZone}
                  </Text>
                </div>
                <div className="flex justify-between">
                  <Button
                    icon={<CaretLeft color="#4A299C" />}
                    type="default"
                    className="inline-flex items-center text-brand-accent border-brand-accent"
                    onClick={handleSchedulingScreenClose}
                  >
                    Back
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit('schedule')}
                  >
                    Schedule Campaign
                  </Button>
                </div>
              </>
            )}
          </Modal>
          <Modal
            centered
            style={{ top: 20 }}
            open={!!audience}
            footer={null}
            title="Update Audience"
            width="60%"
            onCancel={() => setAudience(null)}
          >
            {
              <>
                <div className="flex justify-between">
                  <div className="flex items-center w-3/5 justify-start mb-3">
                    <Text className="mr-3" strong>
                      Audience Name:
                    </Text>
                    <div>
                      <Input
                        name="audienceName"
                        size="large"
                        id="audienceName"
                        maxLength={500}
                        value={channelGroup}
                        placeholder="Enter Audience Name"
                        autoComplete={'off'}
                        onChange={handleGroupName}
                        className="w-[250px]"
                      />
                      {!channelGroup && (
                        <p className="text-red-500 mb-0">
                          Audience name required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 text-xs">
                    <span>
                      <span className="text-[var(--color-gray-4)] font-semibold">
                        Created By:{' '}
                      </span>
                      <Avatar
                        src={audience?.createdBy?.image}
                        size="small"
                        className="mr-1"
                      />
                      {audience?.createdBy?.full_name}
                    </span>
                    <span>
                      <span className="text-[var(--color-gray-4)] font-semibold">
                        Created At:{' '}
                      </span>
                      {moment(audience?.createdAt).format('MMM DD').toString() +
                        ' at ' +
                        moment(audience?.createdAt)
                          .format('hh:mm A')
                          .toString()}
                    </span>
                  </div>
                </div>
                {/** Channel Container */}
                <div className="mt-6">
                  <div className="text-sm font-medium">
                    Total channels ({updateAudience?.channelIds?.length})
                  </div>
                  <div
                    className="mt-3 border border-solid border-brand-border p-2 rounded h-96"
                    style={{ position: 'relative' }}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center justify-start gap-3 relative">
                        <Input
                          name="search"
                          size="large"
                          allowClear
                          id="search"
                          className="form-input w-60 h-8 shadow-sm col-span-2"
                          placeholder="Search"
                          prefix={<MagnifyingGlass />}
                          autoComplete={'off'}
                          onChange={debounce(
                            (e) => setSearch(e.target.value),
                            300
                          )}
                          disabled={isLoading || isFetching}
                        />
                        <Button
                          title="Refresh"
                          icon={<ReloadOutlined color="#4A299C" />}
                          type="default"
                          loading={isFetching || isNonAddedChannelFetching}
                          onClick={() => {
                            refetch()
                            refetchNonAddedChannels()
                            slackSync.mutate()
                            message.success(
                              'Refreshing… this might take a few mins'
                            )
                          }}
                          className="text-brand-accent border-brand-accent border-solid border w-7 h-7"
                        />
                      </div>
                    </div>
                    <Divider className="m-0 mt-2" />
                    {isLoading || isNonAddedChannelLoading ? (
                      <div className=" my-4 flex justify-center items-center">
                        <Spin />
                      </div>
                    ) : (
                      <div className="relative h-5/6 w-full">
                        <div
                          className={`mb-3 px-2 mt-2 border-solid border border-brand-border rounded bg-white h-full w-full`}
                        >
                          <Collapse
                            bordered={false}
                            defaultActiveKey={['1', '2']}
                            expandIconPosition="end"
                            expandIcon={({ isActive }) => {
                              return isActive ? (
                                <CaretUp size={20} color="#4A299C" />
                              ) : (
                                <CaretDown size={20} color="#4A299C" />
                              )
                            }}
                            className="ml-5 border-none mb-3 mt-3 h-[95%] overflow-y-auto overflow-x-hidden bg-white"
                          >
                            <Panel
                              header={
                                <div className="text-base font-semibold text-[#4a299c]">
                                  Thena Added Channels (
                                  {filteredChannelArray.length})
                                </div>
                              }
                              key="1"
                              extra={getExtra('1')}
                            >
                              {renderThenaAddedChannels()}
                            </Panel>
                            <Panel
                              header={
                                <div className="text-[#117B75] font-semibold text-base">
                                  Channels Without Thena (
                                  {filteredNotAddedChannelArray.length})
                                </div>
                              }
                              key="2"
                              extra={getExtra('2')}
                            >
                              {renderThenaNonAddedChannels()}
                            </Panel>
                          </Collapse>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center justify-end gap-3 mt-3 w-full">
                  <div className="flex gap-2 items-center">
                    <Button
                      onClick={updateAudienceHandler}
                      type="primary"
                      loading={updateAudienceMutation.isLoading}
                      disabled={nameError}
                    >
                      Update Audience
                    </Button>
                  </div>
                </div>
              </>
            }
          </Modal>
        </>
      )}
    </>
  )
}
