/* eslint-disable max-lines */
import { PlusOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  MenuProps,
  message,
  Modal,
  Popover,
  Radio,
  Select,
  Typography,
} from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { startCase, toLower } from 'lodash'
import debounce from 'lodash/debounce'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import moment, { Moment } from 'moment'
import {
  ArrowRight,
  CaretDown,
  DownloadSimple,
  MagnifyingGlass,
  X,
} from 'phosphor-react'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import React, {
  createContext,
  ForwardedRef,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { fetchAssignee } from '../api/assignee'
import { fetchIntegrations } from '../api/integrations'
import { fetchConfigureFields, fetchKanbanFilters } from '../api/kanban'
import { fetchCSM } from '../api/personas'
import {
  FetchRequestsRelationships,
  fetchRequestsRelationships,
} from '../api/requests'
import CustomSelect from '../components/common/customSelect/customSelect'
import HorizontalList from '../components/common/HorizontalList/HorizontalList'
import { DynamicMultiSelect } from '../components/Dynamic/DynamicMultiSelect'
import { MultiSelect } from '../components/MultiSelect/MultiSelect'
import { API_ROOT } from '../config'
import useKanbanQuery from '../hooks/useKanbanQuery'
import useKanbanQueryInit from '../hooks/useKanbanQueryInit'
import CloseIcon from '../images/icons/close_icon.svg'
import AccountOwnerIcon from '../images/icons/ic_account_owner.svg'
import EscalatedIcon from '../images/icons/ic_escalated.svg'
import GenericFilterIcon from '../images/icons/ic_gen_filter.svg'
import OpenRequestIcon from '../images/icons/ic_open_requests.svg'
import SentimentIcon from '../images/icons/ic_sentiment.svg'
import SortIcon from '../images/icons/ic_sort.svg'
import AddUser from '../images/icons/ic_unassigned.svg'
import SolutionEngineerIcon from '../images/icons/solutionEngineerIcon.svg'
import TagFilterIcon from '../images/icons/tagFilterIcon.svg'
import TypeIcon from '../images/icons/typeIcon.svg'
import UrgencyIcon from '../images/icons/urgencyIcon.svg'
import UserFilterIcon from '../images/icons/UserFilterIcon.svg'
import {
  configuredFields,
  Filters,
} from '../reducer/kanbanReducer/kanbanReducerState'
import { useAccountsStore } from '../store/accountsStore'
import { useKanbanStore } from '../store/kanbanStore'
import { ACCOUNT_TYPE_LIST, AdditonalFieldType } from '../types/accountsTypes'
import { DateRangeType, getDateRangeByType } from '../utils/dateUtils'
import { emptyList, emptyObject } from '../utils/empty'
import { isNumber } from '../utils/numberUtils'
import { useAuth } from './AuthProvider'
import Assignees from './Filters/Assignees'
import './style.css'

const { Text } = Typography
const { Option } = Select

const Wrapper = styled.div`
  width: calc(100% - 240px);
  .scroll-wrapper {
    height: 35px;
    border-radius: 10px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  width: calc(100% - 270px);
`
const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  position: relative;
  width: 270px;
  height: 32px;
  .add-filter-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-gray-4);
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .plus-icon {
      margin-right: 2px;
    }
    cursor: pointer;
    padding: 15px 6px;
  }
  .reset-btn {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-gray-4);
    height: 24px;
    padding: 15px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 4px;
    }
    cursor: pointer;
  }

  .add-filter-btn:hover,
  .reset-btn:hover {
    background-color: var(--color-gray-3);
    border-radius: 4px;
  }

  .download-btn {
    margin-left: 10px;
  }
`

export type SelectedChannelTypeV2 = null | 'all' | string[]

export type CardTypeV2 =
  | 'TICKETS'
  | 'REQUESTS'
  | 'REQUEST'
  | 'CONVERTED_TO_TICKET'
  | undefined
export type SortTypeV2 = 'NEWEST' | 'OLDEST' | 'RECENT ACTIVITY'

const tags = [
  { label: 'Meeting', value: 'Meeting' },
  { label: 'Bug', value: 'Bug' },
  { label: 'Backend', value: 'Backend' },
  { label: 'AI', value: 'AI' },
  { label: 'Documentation', value: 'Documentation' },
]
interface IApiError {
  code: string
}
interface KanbanFilterContextType {
  search: string
  cardType: string[]
  sortBy: SortTypeV2
  period: number
  selectedFilterChannels: SelectedChannelTypeV2
  csm: string[]
  drawer: boolean
  setChannelsForMessages: React.Dispatch<
    React.SetStateAction<SelectedChannelTypeV2>
  >
  assignedTo: string[]
  setCsvData: React.Dispatch<any>
  csvLink: React.MutableRefObject<any> | null
  dateRange: Array<[]>
  urgency: string[]
  sentiment: string[]
  configuredFieldValues: any
  customers: string[]
  isEscalated: boolean
  isInitialDateRange: boolean
  allTags: string[]
  solutionEngineers: string[]
  dateRangeType: string
  setDateRange: (list: any[]) => void
  crmFieldValues: any
  accountTypes: string[]
}

const KanbanFilterDefaultValues: KanbanFilterContextType = {
  search: '',
  cardType: [],
  csm: [],
  period: 7,
  selectedFilterChannels: 'all',
  sortBy: 'NEWEST',
  drawer: false,
  setChannelsForMessages: () => {},
  assignedTo: [],
  setCsvData: () => {},
  csvLink: null,
  dateRange: [],
  urgency: [],
  sentiment: [],
  configuredFieldValues: {},
  customers: [],
  isEscalated: false,
  isInitialDateRange: true,
  allTags: [],
  solutionEngineers: [],
  dateRangeType: '',
  setDateRange: (list: any[]) => {},
  crmFieldValues: {},
  accountTypes: [],
}

const KanbanFilterContext = createContext<KanbanFilterContextType>(
  KanbanFilterDefaultValues
)
const urgencyFilterList = ['Low', 'Medium', 'High']
const sentimentFilterList = ['Positive', 'Neutral', 'Negative']
const download: MenuProps['items'] = [
  {
    label: 'Filtered Requests in View',
    key: 'filtered_requests_in_view',
  },
  {
    label: 'All Requests in Time Frame',
    key: 'all_requests_in_time_frame',
  },
]

export const KanbanFilterProviderV2 = forwardRef(
  (
    {
      children,
      showChannelFilter = true,
      channelId,
      isHelpdesk,
      selectedTab,
      tabEnabled,
    }: {
      children: React.ReactNode
      showChannelFilter?: boolean
      key?: string
      channelId?: string[]
      isHelpdesk: boolean
      selectedTab: string
      tabEnabled: boolean
    },
    ref: ForwardedRef<any>
  ) => {
    const { data: integrations } = useQuery(['integrations'], fetchIntegrations)
    const { data: configuredFields } = useQuery(
      ['configured-fields'],
      fetchConfigureFields
    )
    const typeFilterList = integrations?.some(
      (ele: any, index: any) => ele.isEnabled === true
    )
      ? ['REQUESTS', 'TICKETS']
      : ['REQUESTS']
    const [drawer, setDrawer] = useState(false)
    const debouncedFunction = (func: any) => debounce(func, 300)
    const StoredKanbanFilters = JSON.parse(
      localStorage.getItem('kanbanFilters') || '{}'
    )
    const StoredKanbanTags = JSON.parse(
      localStorage.getItem('kanbanTags') || '[]'
    )
    const [cardType, setCardType] = useState<string[]>(
      StoredKanbanFilters?.cardType ?? []
    )
    const [sortBy, setSortBy] = useState<any>(
      StoredKanbanFilters?.sortBy ?? KanbanFilterDefaultValues.sortBy
    )
    const [search, setSearch] = useState<string>(StoredKanbanFilters.search)
    const [csm, setCsm] = useState<Array<any>>(StoredKanbanFilters?.csm ?? [])
    const [solutionEngineers, setSolutionEngineers] = useState<Array<any>>(
      StoredKanbanFilters?.solutionEngineers ?? []
    )
    const [assignedTo, setAssignedTo] = useState<string[]>(
      StoredKanbanFilters?.assignedTo ?? []
    )
    const [assignedToMe, setAssignedToMe] = useState<boolean>(
      StoredKanbanFilters?.assignedToMe ?? false
    )
    const [isEscalated, setEscalated] = useState<boolean>(
      StoredKanbanFilters?.isEscalated ?? false
    )
    const [customers, setCustomers] = useState<string[]>(
      StoredKanbanFilters?.customers ?? []
    )
    const [urgency, setUrgency] = useState<string[]>(
      StoredKanbanFilters?.urgency ?? []
    )
    const [sentiment, setSentiment] = useState<string[]>(
      StoredKanbanFilters?.sentiment ?? []
    )
    const [allTags, setAllTags] = useState<string[]>(
      StoredKanbanFilters?.allTags ?? []
    )
    const [accountTypes, setAccountTypes] = useState<string[]>([])
    const [allProduct, setAllProduct] = useState<string[]>(['Marketing'])
    const [configuredFieldValues, setConfiguredFieldValues] = useState<any>(
      StoredKanbanFilters?.configuredFieldValues ?? {}
    )
    const [crmFieldValues, setCrmFieldValues] = useState<any>({})
    const getInitalDateRange = () => {
      const isDateRangePresent = Object.prototype.hasOwnProperty.call(
        StoredKanbanFilters || emptyObject,
        'dateRange'
      )

      const dateRangeType = StoredKanbanFilters.dateRangeType
      if (dateRangeType) {
        return getDateRangeByType(dateRangeType as DateRangeType)
      }

      if (isDateRangePresent) {
        if (StoredKanbanFilters.dateRange) {
          const { dateRange } = StoredKanbanFilters
          return [moment(dateRange[0]), moment(dateRange[1])]
        }
        return null
      }

      return [moment().subtract(30, 'd'), moment()]
    }

    useImperativeHandle(ref, () => ({
      childFunction() {
        setCustomers([])
      },
    }))

    // const [csvData, setCsvData] = useState<any>([])
    // const csvLink = useRef<any>(null)

    const accountTypesFilterList = ACCOUNT_TYPE_LIST.map((item) =>
      startCase(item)
    )
    const flag = useFlags()
    const [searchQuery, setSearchQuery] = useState('')
    const [customerSearchQuery, setCustomerSearchQuery] = useState('')
    const initDateRangeRef = useRef(getInitalDateRange())
    const [dateRange, setDateRange] = useState<any>(getInitalDateRange())
    const [isInitialDateRange, setInitialDateRange] = useState<boolean>(true)
    const requestIds = useKanbanStore((state) => state.requestIds)
    const dateRangeType = useKanbanStore((state) => state.dateRangeType)
    const storeDispatch = useKanbanStore((state) => state.dispatch)
    const [spinner, setSpinner] = useState(false)
    const [save, setSave] = useState(false)
    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(e.target.value)
    }
    const dispatch = useAccountsStore((state) => state.dispatch)
    const onCustomerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCustomerSearchQuery(e.target.value)
    }
    const { data: kanbanFilters } = useQuery<Filters>(
      ['kanbanFilters'],
      () => fetchKanbanFilters(),
      {
        onError: () => {
          message.error('Error fetching filters')
        },
      }
    )

    const [allFilter, setAllFilter] = useState<string[]>([
      'Assignee',
      'Customer',
      'Date',
      'Type',
      'Account Owner',
    ])

    useEffect(() => {
      const urgencyList = StoredKanbanFilters?.urgency ?? []
      const sentimentList = StoredKanbanFilters?.sentiment ?? []
      const tagsList = StoredKanbanFilters?.allTags ?? []
      const solutionEngineerList = StoredKanbanFilters?.solutionEngineers ?? []
      const configuredFieldValues =
        StoredKanbanFilters?.configuredFieldValues ?? {}
      if (urgencyList?.length) {
        setAllFilter((old) => {
          return [...old, 'Urgency']
        })
      }
      if (accountTypesFilterList?.length) {
        setAllFilter((old) => [...old, 'AccountTypes'])
      }
      if (sentimentList?.length) {
        setAllFilter((old) => [...old, 'Sentiment'])
      }
      if (tagsList?.length) {
        setAllFilter((old) => [...old, 'AI Tags'])
      }
      if (solutionEngineerList?.length) {
        setAllFilter((old) => [...old, 'Solutions Engineer'])
      }
      if (Object.keys(configuredFieldValues)?.length) {
        setAllFilter((old) => [...old, ...Object.keys(configuredFieldValues)])
      }
      if (Object.keys(crmFieldValues)?.length) {
        setAllFilter((old) => [...old, ...Object.keys(configuredFieldValues)])
      }
    }, [])

    const onChange = (e: CheckboxChangeEvent, value: string) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setAllFilter((current: any) => {
          return [...current, value]
        })
      } else {
        const array = [...allFilter] // make a separate copy of the array
        if (value === 'Urgency') {
          setUrgency([])
        }
        if (value === 'Sentiment') {
          setSentiment([])
        }
        if (value === 'AI Tags') {
          setAllTags([])
        }
        if (value === 'Solutions Engineer') {
          setSolutionEngineers([])
        }
        if (value === 'AccountTypes') {
          setAccountTypes([])
        }
        const index = array.indexOf(value)
        if (index !== -1) {
          array.splice(index, 1)
          setAllFilter(array)
        }
      }
    }

    const assignToMe = () => {
      if (assignedTo.length === 1 && assignedTo.includes(user?.id || '')) {
        setAssignedTo([])
        setAssignedToMe(false)
      } else {
        setAssignedToMe(true)
        setAssignedTo([user?.id || ''])
      }
      setEscalated(false)
    }

    const changeDateRange: RangePickerProps['onChange'] = (
      dates,
      dateStrings
    ) => {
      setInitialDateRange(false)
      setDateRange(dates)
    }

    const changeType = (type: string) => {
      setModalDetails((prev) => ({ ...prev, type: type }))
      setSave(false)
      setSpinner(false)
    }

    const onAssigneeChange = (e: CheckboxChangeEvent, value: string) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setAssignedTo((current: any) => {
          return [...current, value]
        })
      } else {
        const array = [...assignedTo] // make a separate copy of the array
        const index = array.indexOf(value)
        if (index !== -1) {
          array.splice(index, 1)
          setAssignedTo(array)
        }
      }
    }

    const onCsmChange = (e: CheckboxChangeEvent, item: any) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setCsm((current: any) => {
          return [...current, item]
        })
      } else {
        const array = [...csm] // make a separate copy of the array
        const index = array.findIndex((a) => a?.id === item?.id)
        if (index !== -1) {
          array.splice(index, 1)
          setCsm(array)
        }
      }
    }

    const onSolutionEngineerChange = (e: CheckboxChangeEvent, item: any) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setSolutionEngineers((current: any) => {
          return [...current, item]
        })
      } else {
        const array = [...solutionEngineers] // make a separate copy of the array
        const index = array.findIndex((a) => a?.id === item?.id)
        if (index !== -1) {
          array.splice(index, 1)
          setSolutionEngineers(array)
        }
      }
    }

    const onCustomerChange = (e: CheckboxChangeEvent, value: string) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setCustomers((current: any) => {
          return [...current, value]
        })
      } else {
        const array = [...customers] // make a separate copy of the array
        const index = array.indexOf(value)
        if (index !== -1) {
          array.splice(index, 1)
          setCustomers(array)
        }
      }
    }

    const onUrgencyChange = (e: CheckboxChangeEvent, value: string) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setUrgency((current: any) => {
          return [...current, value]
        })
      } else {
        const array = [...urgency] // make a separate copy of the array
        const index = array.indexOf(value)
        if (index !== -1) {
          array.splice(index, 1)
          setUrgency(array)
        }
      }
    }

    const onSentimentChange = (e: CheckboxChangeEvent, value: string) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setSentiment((current: any) => {
          return [...current, value]
        })
      } else {
        const array = [...sentiment] // make a separate copy of the array
        const index = array.indexOf(value)
        if (index !== -1) {
          array.splice(index, 1)
          setSentiment(array)
        }
      }
    }
    const onAccountTypeChange = (e: CheckboxChangeEvent, value: string) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setAccountTypes((current: any) => {
          return [...current, value]
        })
      } else {
        const array = [...accountTypes] // make a separate copy of the array
        const index = array.indexOf(value)
        if (index !== -1) {
          array.splice(index, 1)
          setAccountTypes(array)
        }
      }
    }

    const onTypeChange = (e: CheckboxChangeEvent, value: string) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setCardType((current: any) => {
          return [...current, value]
        })
      } else {
        const array = [...cardType] // make a separate copy of the array
        const index = array.indexOf(value)
        if (index !== -1) {
          array.splice(index, 1)
          setCardType(array)
        }
      }
    }

    const onProductChange = (e: CheckboxChangeEvent, value: string) => {
      const {
        target: { checked },
      } = e
      if (checked) {
        setAllProduct((current: any) => {
          return [...current, value]
        })
      } else {
        const array = [...allProduct] // make a separate copy of the array
        const index = array.indexOf(value)
        if (index !== -1) {
          array.splice(index, 1)
          setAllProduct(array)
        }
      }
    }

    const csmList = () => {
      return (
        <Avatar.Group size="small" maxCount={3}>
          {csm?.map((csmItem: any, index: any) => {
            const cs = sortedAssignItems?.find(
              (item: any) => item.key == csmItem?.id
            )
            if (csm.length == 1 && index == 0) {
              return (
                <Avatar
                  src={cs?.image}
                  size="small"
                  className="mr-1"
                  key={cs?.key}
                />
              )
            }
            if (csm.length > 1 && csm.length - 1 == index) {
              return <Avatar src={cs?.image} size="small" className="mr-1" />
            }

            return <Avatar key={cs?.key} src={cs?.image} size="small" />
          })}
        </Avatar.Group>
      )
    }
    const solutionEngineerList = () => {
      return (
        <Avatar.Group size="small" maxCount={3}>
          {solutionEngineers?.map((csmItem: any, index: any) => {
            const cs = sortedAssignItems?.find(
              (item: any) => item.key == csmItem?.id
            )
            if (solutionEngineers.length == 1 && index == 0) {
              return (
                <Avatar
                  src={cs?.image}
                  size="small"
                  className="mr-1"
                  key={cs?.key}
                />
              )
            }
            if (
              solutionEngineers.length > 1 &&
              solutionEngineers.length - 1 == index
            ) {
              return <Avatar src={cs?.image} size="small" className="mr-1" />
            }

            return <Avatar key={cs?.key} src={cs?.image} size="small" />
          })}
        </Avatar.Group>
      )
    }
    const customerList = () => {
      return customers?.map((tag, index) => {
        // check if more than 2 tags, then say +4 etc
        if (index === 3 && customers.length > 3) {
          return (
            <label className="mr-1 text-xs filter-chip" key={`${index}`}>
              {`+${customers.length - 3}`}
            </label>
          )
        }
        if (index > 3) {
          return <div></div>
        }

        return (
          <label className="mr-1 text-xs filter-chip" key={`${index}`}>
            {tag}
          </label>
        )
      })
    }

    const urgencyList = () => {
      return urgency.map((tag, index) => {
        let urgencyClass = '-green'
        if (tag === 'Low') {
          urgencyClass = '-green'
        } else if (tag === 'Medium') {
          urgencyClass = '-orange'
        } else if (tag === 'High') {
          urgencyClass = '-red'
        }
        return (
          <label
            className={`mr-1 text-xs filter-chip${urgencyClass}`}
            key={`${index}`}
          >
            {tag}
          </label>
        )
      })
    }

    const sentimentList = () => {
      return sentiment.map((tag, index) => {
        return (
          <label className="mr-1 text-xs filter-chip" key={`${index}`}>
            {tag}
          </label>
        )
      })
    }

    const typeList = () => {
      return cardType.map((tag, index) => {
        return (
          <label className="mr-1 text-xs filter-chip" key={`${index}`}>
            {tag}
          </label>
        )
      })
    }
    const accountTypesList = () => {
      return accountTypes.map((tag, index) => {
        return (
          <label className="mr-1 text-xs filter-chip" key={`${index}`}>
            {tag}
          </label>
        )
      })
    }
    const sortByList = () =>
      sortBy ? <label className="text-xs filter-chip">{sortBy}</label> : null

    const [searchParams] = useSearchParams()
    const crmConfig =
      useAccountsStore((state) => state.crmConfig) ?? emptyObject
    const filters = ['Sentiment', 'Urgency', 'AI Tags', 'Solutions Engineer']
    const dynamicFilters: any[] = []
    const crmDynamicFilters: any[] = []
    // using configuredFields add dynamic filters
    configuredFields?.forEach((ele: configuredFields) => {
      // if filters does not have it, then add
      if (filters.indexOf(ele.category_name) === -1) {
        if (ele.type !== 'link') {
          filters.push(ele.category_name)
        }

        const data = ele.available_values?.map((elem) => {
          return elem.value
        })

        let valuesFromQuery = searchParams.get(ele.category_name)
        try {
          valuesFromQuery = JSON.parse(valuesFromQuery || '')
        } catch (err) {
          //
        }

        const initialSelectedValues =
          valuesFromQuery ||
          StoredKanbanFilters?.configuredFieldValues?.[ele?.category_name]

        if (
          ele.type === 'multiselect' ||
          ele.type === 'select' ||
          ele.type === 'radio'
        ) {
          const view = (
            <DynamicMultiSelect
              type={ele.type}
              label={ele.category_name}
              icon={GenericFilterIcon}
              initialSelectedValues={initialSelectedValues}
              data={data}
              func={(data: any) => {
                setConfiguredFieldValues((old: any) => {
                  return {
                    ...old,
                    ...{
                      [ele.category_name]: isArray(data)
                        ? data
                        : data
                        ? [data]
                        : [],
                    },
                  }
                })
              }}
            />
          )

          dynamicFilters.push({ key: ele.category_name, view })
        }
      }

      // build dynamic filter for each cat
    })
    crmConfig?.additional_fields?.forEach((ele: AdditonalFieldType) => {
      // if filters does not have it, then add
      if (filters.indexOf(ele.field_name) === -1) {
        // filters.push(ele.field_name)

        const data = ele.available_values?.map((elem) => {
          return elem.value
        })

        let valuesFromQuery = searchParams.get(ele.field_name)
        try {
          valuesFromQuery = JSON.parse(valuesFromQuery || '')
        } catch (err) {
          //
        }

        if (
          ele.type === 'multiselect' ||
          ele.type === 'select' ||
          ele.type === 'radio'
        ) {
          const view = (
            <DynamicMultiSelect
              type={ele.type}
              label={ele.field_name}
              icon={GenericFilterIcon}
              initialSelectedValues={valuesFromQuery}
              data={data}
              func={(data: any) => {
                setCrmFieldValues((old: any) => {
                  return {
                    ...old,
                    ...{
                      [ele.field_name]: isArray(data)
                        ? data
                        : data
                        ? [data]
                        : [],
                    },
                  }
                })
              }}
            />
          )

          crmDynamicFilters.push({ key: ele.field_name, view })
        }
      }

      // build dynamic filter for each cat
    })
    const { data } = useQuery('fetchCSM', () => fetchCSM({ channelIds: [] }))

    const [selectedFilterChannels, setChannelsForMessages] =
      useState<SelectedChannelTypeV2>(
        channelId || KanbanFilterDefaultValues.selectedFilterChannels
      )

    useEffect(() => {
      if (channelId && channelId.length) {
        setChannelsForMessages(channelId)
      }
    }, [channelId])

    const TagsTagRender = (props: CustomTagProps) => {
      const { value } = props
      return <label>{value},</label>
    }

    const { data: selectedChannels, isLoading: isChannelLoading } =
      useQuery<FetchRequestsRelationships>(
        ['fetchRequestsRelationships'],
        fetchRequestsRelationships
      )
    const workspaceNames = Array.from(
      new Set(
        selectedChannels?.rIds
          .filter((item) =>
            tabEnabled
              ? isHelpdesk
                ? item.type === 'Internal'
                : item.type === 'External'
              : item
          )
          .map((item) => item.customer_name)
      )
    ).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))

    const { data: assignees } = useQuery('assignee', fetchAssignee)

    const { RangePicker } = DatePicker
    const content = (
      <div>
        <Input
          autoFocus
          placeholder="Search"
          onChange={onSearch}
          prefix={<MagnifyingGlass color="#707070" />}
          className="input"
        />
        <div style={{ height: '200px', overflowY: 'scroll' }}>
          <>
            {filters
              .filter((it) =>
                it.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((tag: string, index: number) => (
                <div
                  style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                  key={index}
                >
                  <Checkbox
                    name={tag}
                    checked={allFilter.includes(tag)}
                    onChange={debouncedFunction((e: any) => onChange(e, tag))}
                  >
                    {tag}
                  </Checkbox>
                </div>
              ))}
            {!isEmpty(crmConfig?.additional_fields) && (
              <label className="w-[180px] text-[var(--color-gray-4)] text-left text-xs font-medium ml-1">
                CRM Filters
              </label>
            )}
            {crmConfig?.additional_fields
              ?.filter((it, i) =>
                it.field_name.toLowerCase().includes(searchQuery.toLowerCase())
              )
              ?.map((tag, index) => (
                <div
                  style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                  key={index}
                >
                  <Checkbox
                    name={tag.field_name}
                    checked={allFilter.includes(tag.field_name)}
                    onChange={(e) => onChange(e, tag.field_name)}
                  >
                    {tag.field_name}
                  </Checkbox>
                </div>
              ))}
            {flag.accounts && (
              <div
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                key={'AccountTypes'}
              >
                <Checkbox
                  name={'AccountTypes'}
                  checked={allFilter.includes('AccountTypes')}
                  onChange={(e) => onChange(e, 'AccountTypes')}
                >
                  Account Types
                </Checkbox>
              </div>
            )}
          </>
        </div>
      </div>
    )
    const { user } = useAuth()
    const newOptions = kanbanFilters?.assignees?.members
      ? [
          kanbanFilters?.assignees?.members.find(
            (el: any) => el.id === user?.id
          ),
          ...kanbanFilters?.assignees?.members.filter(
            (el: any) => el.id !== user?.id
          ),
        ].filter(Boolean)
      : kanbanFilters?.assignees?.members

    const assignItems = newOptions?.map((el: any) => ({
      label: user?.id === el.id ? 'Me' : el.name,
      key: el.id,
      image: el.image,
      ...el,
    }))

    const sortedAssignItems = assignItems?.sort((a: any, b: any) =>
      a.label === 'Me'
        ? -1
        : b.label === 'Me'
        ? 1
        : a.label.localeCompare(b.label)
    )

    sortedAssignItems?.unshift({
      label: 'Unassigned',
      key: '',
      image: AddUser,
      _id: '',
      id: '',
      team_id: '',
      name: 'Unassigned',
    })

    const customerContent = (
      <div>
        <Input
          placeholder="Search"
          onChange={onCustomerSearch}
          prefix={<MagnifyingGlass color="#707070" />}
          autoFocus
          className="input"
        />
        <div style={{ height: '200px', overflow: 'auto', marginTop: '0.5rem' }}>
          {workspaceNames
            .filter((it) =>
              it.toLowerCase().includes(customerSearchQuery.toLowerCase())
            )
            .map((tag: string, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    customers.includes(tag)
                      ? 'checkbox-label-selected'
                      : 'checkbox-label'
                  }
                >
                  <Checkbox
                    className={
                      customers?.includes(tag)
                        ? 'checkbox-selected'
                        : 'checkbox-item'
                    }
                    name={tag}
                    checked={customers.includes(tag)}
                    onChange={debouncedFunction((e: any) => {
                      onCustomerChange(e, tag)
                      ;(window as any).analytics.track('Kanban Filter', {
                        userId: user?.id,
                        userEmail: user?.email,
                        teamId: user?.team_id,
                        teamName: user?.team_name,
                        name: 'customer',
                        value: tag,
                        enabled: customers.includes(tag),
                      })
                    })}
                  >
                    {tag}
                  </Checkbox>
                </div>
              )
            })}
        </div>
      </div>
    )
    const UrgencyContent = (
      <div>
        <div style={{ height: '200px', overflow: 'auto' }}>
          {urgencyFilterList.map((tag: string, index: number) => {
            return (
              <div
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                key={index}
                className={
                  urgency.includes(tag)
                    ? 'checkbox-label-selected'
                    : 'checkbox-label'
                }
              >
                <Checkbox
                  className={
                    urgency.includes(tag)
                      ? 'checkbox-selected'
                      : 'checkbox-item'
                  }
                  name={tag}
                  checked={urgency.includes(tag)}
                  onChange={debouncedFunction((e: any) => {
                    onUrgencyChange(e, tag)
                    ;(window as any).analytics.track('Kanban Filter', {
                      userId: user?.id,
                      userEmail: user?.email,
                      teamId: user?.team_id,
                      teamName: user?.team_name,
                      name: 'urgency',
                      value: tag,
                      enabled: urgency.includes(tag),
                    })
                  })}
                >
                  {tag}
                </Checkbox>
              </div>
            )
          })}
        </div>
      </div>
    )
    const sentimentContent = (
      <div>
        <div style={{ height: '200px', overflow: 'auto' }}>
          {sentimentFilterList.map((tag: string, index: number) => {
            return (
              <div
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                key={index}
                className={
                  sentiment.includes(tag)
                    ? 'checkbox-label-selected'
                    : 'checkbox-label'
                }
              >
                <Checkbox
                  name={tag}
                  className={
                    sentiment.includes(tag)
                      ? 'checkbox-selected'
                      : 'checkbox-item'
                  }
                  checked={sentiment.includes(tag)}
                  onChange={debouncedFunction((e: any) => {
                    onSentimentChange(e, tag)
                    ;(window as any).analytics.track('Kanban Filter', {
                      userId: user?.id,
                      userEmail: user?.email,
                      teamId: user?.team_id,
                      teamName: user?.team_name,
                      name: 'sentiment',
                      value: tag,
                      enabled: sentiment.includes(tag),
                    })
                  })}
                >
                  {tag}
                </Checkbox>
              </div>
            )
          })}
        </div>
      </div>
    )
    const typeContent = (
      <div style={{ height: '100px', overflow: 'auto', width: '150px' }}>
        {typeFilterList.map((tag: string, index: number) => {
          return (
            <div
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
              key={index}
              className={
                cardType?.includes(tag)
                  ? 'checkbox-label-selected'
                  : 'checkbox-label'
              }
            >
              <Checkbox
                name={tag}
                className={
                  cardType?.includes(tag)
                    ? 'checkbox-selected'
                    : 'checkbox-item'
                }
                checked={cardType.includes(tag)}
                onChange={debouncedFunction((e: any) => {
                  onTypeChange(e, tag)
                  ;(window as any).analytics.track('Kanban Filter', {
                    userId: user?.id,
                    userEmail: user?.email,
                    teamId: user?.team_id,
                    teamName: user?.team_name,
                    name: 'type',
                    value: tag,
                    enabled: cardType.includes(tag),
                  })
                })}
              >
                {startCase(toLower(tag))}
              </Checkbox>
            </div>
          )
        })}
      </div>
    )

    const sortByContent = (
      <div>
        <div
          style={{
            height: '150px',
            overflow: 'auto',
          }}
        >
          <div
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            className={
              sortBy === 'NEWEST' ? 'checkbox-label-selected' : 'checkbox-label'
            }
          >
            <Radio
              className={
                sortBy === 'NEWEST' ? 'checkbox-selected' : 'checkbox-item'
              }
              name="Newest first"
              checked={sortBy === 'NEWEST'}
              onChange={(e) => {
                setSortBy('NEWEST')
                ;(window as any).analytics.track('Sort By', {
                  userId: user?.id,
                  userEmail: user?.email,
                  teamId: user?.team_id,
                  teamName: user?.team_name,
                  sortBy: 'NEWEST',
                })
              }}
            >
              Newest First
            </Radio>
          </div>
          <div
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            className={
              sortBy === 'OLDEST' ? 'checkbox-label-selected' : 'checkbox-label'
            }
          >
            <Radio
              className={
                sortBy === 'OLDEST' ? 'checkbox-selected' : 'checkbox-item'
              }
              name="Oldest first"
              checked={sortBy === 'OLDEST'}
              onChange={(e) => {
                setSortBy('OLDEST')
                ;(window as any).analytics.track('Sort By', {
                  userId: user?.id,
                  userEmail: user?.email,
                  teamId: user?.team_id,
                  teamName: user?.team_name,
                  sortBy: 'OLDEST',
                })
              }}
            >
              Oldest First
            </Radio>
          </div>
          <div
            style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
            className={
              sortBy === 'RECENT ACTIVITY'
                ? 'checkbox-label-selected'
                : 'checkbox-label'
            }
          >
            <Radio
              className={
                sortBy === 'RECENT ACTIVITY'
                  ? 'checkbox-selected'
                  : 'checkbox-item'
              }
              name="RECENT ACTIVITY"
              checked={sortBy === 'RECENT ACTIVITY'}
              onChange={(e) => {
                setSortBy('RECENT ACTIVITY')
                ;(window as any).analytics.track('Sort By', {
                  userId: user?.id,
                  userEmail: user?.email,
                  teamId: user?.team_id,
                  teamName: user?.team_name,
                  sortBy: 'RECENT ACTIVITY',
                })
              }}
            >
              Recent Activity
            </Radio>
          </div>
        </div>
      </div>
    )
    const CsmContent = (
      <div>
        <Input
          placeholder="Search"
          onChange={onSearch}
          prefix={<MagnifyingGlass color="#707070" />}
          autoFocus
          className="input"
        />
        <div style={{ height: '200px', overflow: 'auto', marginTop: '0.5rem' }}>
          {kanbanFilters?.csm?.members
            .filter((it: any) =>
              it.name?.toLowerCase().includes(searchQuery?.toLowerCase())
            )
            .map((item: any, index: number) => {
              return (
                <div
                  className={
                    csm.find((el) => el.id === item.id)
                      ? 'checkbox-label-selected'
                      : 'checkbox-label'
                  }
                  key={item.id}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1 w-full">
                      <Checkbox
                        className={
                          csm.find((el) => el.id === item.id)
                            ? 'checkbox-selected'
                            : 'checkbox-item'
                        }
                        onChange={debouncedFunction((e: any) =>
                          onCsmChange(e, item)
                        )}
                        checked={csm.find((el) => el.id === item.id)}
                      >
                        <Avatar
                          src={item.image}
                          size="small"
                          className="avatar_dropdown"
                        />
                        <Text
                          className="ml-1 font-normal text-default"
                          ellipsis={{ tooltip: false }}
                        >
                          {' '}
                          {item.name}{' '}
                        </Text>
                      </Checkbox>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
    const accountTypesContent = (
      <div>
        <div style={{ height: '200px', overflow: 'auto' }}>
          {accountTypesFilterList.map((tag: any, index: number) => {
            return (
              <div
                style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
                key={index}
                className={
                  accountTypes.includes(tag)
                    ? 'checkbox-label-selected'
                    : 'checkbox-label'
                }
              >
                <Checkbox
                  name={tag}
                  className={
                    accountTypes.includes(tag)
                      ? 'checkbox-selected'
                      : 'checkbox-item'
                  }
                  checked={accountTypes.includes(tag)}
                  onChange={(e: any) => {
                    onAccountTypeChange(e, tag)
                    ;(window as any).analytics.track('Kanban Filter', {
                      userId: user?.id,
                      userEmail: user?.email,
                      teamId: user?.team_id,
                      teamName: user?.team_name,
                      name: 'account types',
                      value: tag,
                      enabled: accountTypes.includes(tag),
                    })
                  }}
                >
                  {tag}
                </Checkbox>
              </div>
            )
          })}
        </div>
      </div>
    )
    const SolutionEngineersContent = (
      <div>
        <Input
          placeholder="Search"
          onChange={onSearch}
          prefix={<MagnifyingGlass color="#707070" />}
          autoFocus
          className="input"
        />
        <div style={{ height: '200px', overflow: 'auto', marginTop: '0.5rem' }}>
          {kanbanFilters?.solutionEngineers?.members
            .filter((it: any) =>
              it.name?.toLowerCase().includes(searchQuery?.toLowerCase())
            )
            .map((item: any, index: number) => {
              return (
                <div
                  className={
                    solutionEngineers.find((el) => el.id === item.id)
                      ? 'checkbox-label-selected'
                      : 'checkbox-label'
                  }
                  key={item.id}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1 w-full">
                      <Checkbox
                        className={
                          solutionEngineers.find((el) => el.id === item.id)
                            ? 'checkbox-selected'
                            : 'checkbox-item'
                        }
                        onChange={debouncedFunction((e: any) =>
                          onSolutionEngineerChange(e, item)
                        )}
                        checked={solutionEngineers.find(
                          (el) => el.id === item.id
                        )}
                      >
                        <Avatar
                          src={item.image}
                          size="small"
                          className="avatar_dropdown"
                        />
                        <Text
                          className="ml-1 font-normal text-default"
                          ellipsis={{ tooltip: false }}
                        >
                          {' '}
                          {item.name}{' '}
                        </Text>
                      </Checkbox>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )

    const clearAllFilters = () => {
      setAssignedTo([])
      setAssignedToMe(false)
      setEscalated(false)
      setDateRange([moment().subtract(30, 'd'), moment()])
      setUrgency([])
      setAllTags([])
      setSentiment([])
      setConfiguredFieldValues({})
      setCrmFieldValues({})
      setCardType([])
      setAllFilter(['Assignee', 'Customer', 'Date', 'Type', 'Account Owner'])
      setSearchQuery('')
      setCustomers([])
      setSortBy('NEWEST')
      setCsm([])
      setSolutionEngineers([])
      setSearch('')
      setAccountTypes([])
      ;(window as any).analytics.track('Kanban Filter', {
        userId: user?.id,
        userEmail: user?.email,
        teamId: user?.team_id,
        teamName: user?.team_name,
        name: 'clear',
      })
    }
    const [modalDetails, setModalDetails] = useState({
      open: false,
      modalType: 'filtered_requests_in_view',
      type: 'json',
    })
    const handlePreviewClick: MenuProps['onClick'] = (e) => {
      if (e.key === 'filtered_requests_in_view') {
        setModalDetails((prev) => ({
          ...prev,
          open: true,
          modalType: 'filtered_requests_in_view',
        }))
      }
      if (e.key === 'all_requests_in_time_frame') {
        setModalDetails((prev) => ({
          ...prev,
          open: true,
          modalType: 'all_requests_in_time_frame',
        }))
      }
    }

    const queryTrackRef = useRef(false)
    const [mountQueryUpdateHandler, setMountQueryUpdateHandler] =
      useState(false)

    const exportData = () => {
      message.info('Downloading...', 2)
      const requestIdList =
        modalDetails.modalType === 'filtered_requests_in_view' ? requestIds : []
      try {
        const form = document.createElement('form')
        form.setAttribute('method', 'POST')
        form.setAttribute(
          'action',
          `${API_ROOT}/v2/kanban/download?fileType=${
            modalDetails.type
          }&startDate=${
            dateRange && dateRange[0]
              ? moment(dateRange[0]).startOf('day').valueOf().toString()
              : ''
          }&endDate=${
            dateRange && dateRange[0]
              ? moment(dateRange[1]).endOf('day').valueOf().toString()
              : ''
          }`
        )
        form.setAttribute('target', '_blank')
        const input1 = document.createElement('input')
        input1.setAttribute('type', 'hidden')
        input1.setAttribute('name', 'requestIdList')
        input1.setAttribute(
          'value',
          JSON.stringify(requestIdList.filter((i: any) => !!i))
        )
        form.appendChild(input1)
        document.body.appendChild(form)
        form.submit()
      } catch (error) {
        message.error('Error downloading data!')
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    useKanbanQueryInit(
      useMemo(() => {
        return {
          setCsm,
          setSearch,
          setSortBy,
          setUrgency,
          setAllTags,
          setCardType,
          setSentiment,
          setEscalated,
          setCustomers,
          setDateRange,
          setAssignedTo,
          setAssignedToMe,
          setInitialDateRange,
          setSolutionEngineers,
          setConfiguredFieldValues,
          setCrmFieldValues,
          setAccountTypes,
        }
      }, []),
      queryTrackRef,
      setAllFilter,
      setMountQueryUpdateHandler
    )

    useKanbanQuery(
      useMemo(() => {
        return {
          csm,
          search,
          sortBy,
          urgency,
          allTags,
          sentiment,
          customers,
          dateRange,
          assignedTo,
          isEscalated,
          assignedToMe,
          dateRangeType,
          solutionEngineers,
          isInitialDateRange,
          configuredFieldValues,
          cardType,
          tab: selectedTab,
          initDateRange: initDateRangeRef.current || emptyList,
          crmFieldValues,
          accountTypes,
        }
      }, [
        csm,
        search,
        sortBy,
        urgency,
        allTags,
        sentiment,
        customers,
        dateRange,
        assignedTo,
        isEscalated,
        assignedToMe,
        dateRangeType,
        solutionEngineers,
        isInitialDateRange,
        configuredFieldValues,
        cardType,
        selectedTab,
        crmFieldValues,
        accountTypes,
      ]),
      queryTrackRef,
      mountQueryUpdateHandler
    )

    const setDateRangeType = (rangeType: string | null) => {
      storeDispatch({
        type: 'SET_DATE_RANGE_TYPE',
        payload: { dateRangeType: rangeType },
      })
    }

    const handleDateRangeTypeClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      const innerText = (e.target as HTMLElement).innerText
      switch (innerText) {
        case 'Last 7 days':
          return setDateRangeType('7_DAYS')

        case 'Last 14 days':
          return setDateRangeType('14_DAYS')

        case 'Last 30 days':
          return setDateRangeType('30_DAYS')

        case 'All Time':
          return setDateRangeType('ALL_TIME')

        default:
          if (isNumber(innerText)) {
            setDateRangeType(null)
          }
      }
    }

    const renderFilters = () => {
      return workspaceNames
        .filter((it) =>
          it.toLowerCase().includes(customerSearchQuery.toLowerCase())
        )
        .map((tag: string, index: number) => {
          return (
            <div
              style={{
                marginLeft: '0.5rem',
                marginBottom: '0.5rem',
              }}
              key={index}
              className={
                customers.includes(tag)
                  ? 'filter-popover-item-selected flex-shrink-0 w-max'
                  : 'filter-popover-item flex-shrink-0 w-max'
              }
            >
              <Checkbox
                name={tag}
                checked={customers.includes(tag)}
                onChange={debouncedFunction((e: any) => {
                  onCustomerChange(e, tag)
                  ;(window as any).analytics.track('Kanban Filter', {
                    userId: user?.id,
                    userEmail: user?.email,
                    teamId: user?.team_id,
                    teamName: user?.team_name,
                    name: 'customer',
                    value: tag,
                    enabled: customers.includes(tag),
                  })
                })}
              >
                {tag}
              </Checkbox>
            </div>
          )
        })
    }
    return (
      <KanbanFilterContext.Provider
        value={useMemo(() => {
          const KanbanFilterValues = {
            search,
            cardType,
            csm,
            sortBy,
            selectedFilterChannels,
            setChannelsForMessages,
            drawer,
            assignedTo,
            dateRange,
            urgency,
            sentiment,
            configuredFieldValues,
            customers,
            isEscalated,
            assignedToMe,
            isInitialDateRange,
            allTags,
            solutionEngineers,
            dateRangeType,
            setDateRange,
            crmFieldValues,
            accountTypes,
          }

          localStorage.setItem(
            'kanbanFilters',
            JSON.stringify({
              search,
              cardType,
              csm,
              sortBy,
              selectedFilterChannels,
              drawer,
              configuredFieldValues,
              assignedTo,
              urgency,
              sentiment,
              customers,
              dateRangeType,
              isEscalated,
              assignedToMe,
              allTags,
              solutionEngineers,
              dateRange,
              isInitialDateRange,
              crmFieldValues,
              accountTypes,
            })
          )
          const getItems = JSON.parse(
            localStorage.getItem('kanbanFilters') || '{}'
          )
          return { ...KanbanFilterValues, ...getItems }
        }, [
          selectedFilterChannels,
          search,
          cardType,
          csm,
          sortBy,
          dateRangeType,
          setChannelsForMessages,
          drawer,
          assignedTo,
          dateRange,
          urgency,
          sentiment,
          configuredFieldValues,
          customers,
          isEscalated,
          assignedToMe,
          isInitialDateRange,
          allTags,
          solutionEngineers,
          crmFieldValues,
          accountTypes,
        ])}
      >
        <Modal
          open={modalDetails.open}
          onCancel={() =>
            setModalDetails((prev) => ({
              ...prev,
              open: false,
              type: 'json',
            }))
          }
          title={
            modalDetails.modalType === 'filtered_requests_in_view'
              ? 'Download Filtered Requests in View'
              : 'Download All Requests in Time Frame'
          }
          footer={null}
          centered
        >
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 8 }}
            autoComplete="off"
            onFinish={exportData}
          >
            <Form.Item label="Type" name="type">
              <Select
                value={modalDetails.type}
                className="w-full"
                onChange={changeType}
                options={[
                  { value: 'json', label: 'JSON' },
                  { value: 'xlsx', label: 'XLSX' },
                ]}
              />
              <span className="invisible">{modalDetails.type}</span>
            </Form.Item>
            <div className="flex justify-end">
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Download
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>

        <div className="flex items-center justify-between w-full gap-1">
          <div className="flex items-center justify-start w-full gap-1">
            <Input
              name="search"
              id="search"
              className="col-span-2 shadow-sm form-input w-60 search-input"
              placeholder="Search tasks"
              prefix={<MagnifyingGlass />}
              autoComplete={'off'}
              onChange={debouncedFunction((e: any) =>
                setSearch(e.target.value)
              )}
              style={{ fontSize: 'inherit' }}
              defaultValue={search}
              allowClear
            />
            {/* <Button className="ml-1 mr-2 splitter-btn">
            <img src={EditIcon} className="mr-3" />
            Open Requests
          </Button>
          <Button className="mr-2 splitter-btn">
            <img src={EditIcon} className="mr-3" />
            Unreplied Request
          </Button> */}
            {!isHelpdesk ? (
              <>
                <Button
                  className={`flex items-center ml-2 mr-2 splitter-btn${
                    assignedToMe ? '-dark' : ''
                  }`}
                  onClick={assignToMe}
                >
                  <img
                    src={OpenRequestIcon}
                    className="pt-1 mr-3"
                    style={
                      assignedToMe
                        ? { filter: 'brightness(0) invert(1)' }
                        : { filter: 'none' }
                    }
                  />
                  My Requests
                </Button>

                <Button
                  className={`flex items-center mr-2 splitter-btn${
                    isEscalated ? '-dark' : ''
                  }`}
                  onClick={() => {
                    setAssignedTo([])
                    setAssignedToMe(false)
                    setEscalated(!isEscalated)
                  }}
                >
                  <img
                    src={EscalatedIcon}
                    className="mr-3 pb-0.5"
                    style={
                      isEscalated
                        ? { filter: 'brightness(0) invert(1)' }
                        : { filter: 'none' }
                    }
                  />
                  Escalated Requests
                </Button>
              </>
            ) : tabEnabled ? (
              <div className="w-[83%] flex justify-between">
                <Wrapper>
                  <HorizontalList listItems={renderFilters()} />{' '}
                </Wrapper>
                <MultiSelect
                  label="Sort by:"
                  icon={SortIcon}
                  content={sortByContent}
                  clearClick={() => setSortBy('NEWEST')}
                  iconList={sortByList}
                ></MultiSelect>
              </div>
            ) : (
              <></>
            )}
          </div>
          {!isHelpdesk && (
            <div className="flex items-center justify-end w-full gap-1">
              <MultiSelect
                label="Sort by:"
                icon={SortIcon}
                content={sortByContent}
                clearClick={() => setSortBy('NEWEST')}
                iconList={sortByList}
              ></MultiSelect>
            </div>
          )}
        </div>
        <Divider className="p-0 mt-2 mb-2" />

        <div className="flex items-start justify-between w-full">
          <FilterContainer>
            {/* <Text className="font-bold text-gray-dark">Filter by</Text> */}
            {allFilter.includes('Date') && (
              <>
                <div
                  className="text-sm date"
                  onClick={handleDateRangeTypeClick}
                >
                  <RangePicker
                    ranges={{
                      'Last 7 days': [moment().subtract(7, 'd'), moment()],
                      'Last 14 days': [moment().subtract(14, 'd'), moment()],
                      'Last 30 days': [moment().subtract(30, 'd'), moment()],
                      'All Time': null as unknown as [Moment, Moment],
                    }}
                    className="clear-btn"
                    value={dateRange}
                    onChange={debouncedFunction(changeDateRange)}
                    separator={<ArrowRight className="mt-1" />}
                  />
                </div>
              </>
            )}

            {allFilter.includes('Type') && (
              <div>
                <MultiSelect
                  label={'Type'}
                  icon={TypeIcon}
                  data={cardType}
                  content={typeContent}
                  clearClick={() => setCardType([])}
                  iconList={typeList}
                  active={cardType}
                ></MultiSelect>
              </div>
            )}
            {allFilter.includes('Assignee') && (
              <Assignees
                assignees={assignees}
                assignedTo={assignedTo}
                sortedAssignItems={sortedAssignItems}
                onCheckboxClick={debouncedFunction(
                  (e: CheckboxChangeEvent, item: any) => {
                    onAssigneeChange(e, item.key)
                    ;(window as any).analytics.track('Kanban Filter', {
                      userId: user?.id,
                      userEmail: user?.email,
                      teamId: user?.team_id,
                      teamName: user?.team_name,
                      name: 'assigned to',
                      value: user?.name,
                      enabled: assignedTo.includes(item.id),
                    })
                  }
                )}
                onCheckboxClickMe={debouncedFunction(
                  (e: CheckboxChangeEvent, item: any) => {
                    onAssigneeChange(e, item.key)
                    ;(window as any).analytics.track('Kanban Filter', {
                      userId: user?.id,
                      userEmail: user?.email,
                      teamId: user?.team_id,
                      teamName: user?.team_name,
                      name: 'assignee',
                      value: item.label,
                      enabled: assignedTo.includes(item.id),
                    })
                  }
                )}
                onClear={() => {
                  setAssignedTo([])
                  setAssignedToMe(false)
                }}
              />
            )}
            {allFilter.includes('Account Owner') &&
            kanbanFilters?.csm?.members?.length &&
            kanbanFilters?.csm?.members?.length > 0 ? (
              <div
                className={`${
                  isEmpty(csm) ? 'kanban-not-selected' : 'kanban-selected date'
                }`}
              >
                <MultiSelect
                  label="Account Owner"
                  icon={AccountOwnerIcon}
                  content={CsmContent}
                  clearClick={() => setCsm([])}
                  iconList={csmList}
                  callback={(isOpen: boolean) => {
                    if (isOpen) {
                      setSearchQuery('')
                    }
                  }}
                ></MultiSelect>
              </div>
            ) : (
              <></>
            )}
            {allFilter.includes('Solutions Engineer') &&
            kanbanFilters?.solutionEngineers?.members?.length &&
            kanbanFilters?.solutionEngineers?.members?.length > 0 ? (
              <div
                className={`${
                  isEmpty(solutionEngineers)
                    ? 'kanban-not-selected'
                    : 'kanban-selected date'
                }`}
              >
                <MultiSelect
                  label="Solutions Engineer"
                  icon={SolutionEngineerIcon}
                  content={SolutionEngineersContent}
                  clearClick={() => setSolutionEngineers([])}
                  iconList={solutionEngineerList}
                  callback={(isOpen: boolean) => {
                    if (isOpen) {
                      setSearchQuery('')
                    }
                  }}
                ></MultiSelect>
              </div>
            ) : (
              <></>
            )}
            {showChannelFilter && allFilter.includes('Customer') && (
              <MultiSelect
                label={isHelpdesk ? 'Channels' : 'Customers'}
                icon={UserFilterIcon}
                content={customerContent}
                clearClick={() => setCustomers([])}
                iconList={customerList}
                callback={(isOpen: boolean) => {
                  if (isOpen) {
                    setCustomerSearchQuery('')
                  }
                }}
              ></MultiSelect>
            )}

            <>
              {allFilter.includes('Tag') && (
                <div
                  className={`${
                    isEmpty(allTags)
                      ? 'kanban-not-selected'
                      : 'kanban-selected date'
                  }`}
                >
                  <CustomSelect
                    prefixText="Tags:"
                    mode="tags"
                    suffixIcon={null}
                    showSearch
                    prefixIcon={TagFilterIcon}
                    tagRender={TagsTagRender}
                    value={allTags}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(val: string[]) => {
                      setAllTags(val)
                    }}
                    style={{
                      width: allTags.length > 0 ? '230px' : 'min-content',
                    }}
                    showArrow={false}
                    allowClear
                    bordered
                    dropdownMatchSelectWidth={false}
                    maxTagCount={'responsive'}
                    clearIcon={<X color="#000000" className="bg-transparent" />}
                  >
                    {(tags || []).map((item: any) => (
                      <Option
                        value={item.value}
                        label={item.label}
                        key={item.value}
                      >
                        <div className="flex items-center space-x-2 text-sm">
                          <Checkbox
                            checked={allTags.includes(item.value)}
                          ></Checkbox>
                          <span>
                            <Text>{item.label}</Text>
                          </span>
                        </div>
                      </Option>
                    ))}
                  </CustomSelect>
                </div>
              )}
            </>

            {allFilter.includes('Urgency') && (
              <MultiSelect
                label={'Urgency'}
                icon={UrgencyIcon}
                data={urgency}
                content={UrgencyContent}
                clearClick={() => {
                  setUrgency([])
                }}
                iconList={urgencyList}
              ></MultiSelect>
            )}
            {allFilter.includes('Sentiment') && (
              <MultiSelect
                label={'Sentiment'}
                icon={SentimentIcon}
                data={sentiment}
                content={sentimentContent}
                clearClick={() => {
                  setSentiment([])
                }}
                iconList={sentimentList}
              ></MultiSelect>
            )}
            {allFilter.includes('AI Tags') && (
              <DynamicMultiSelect
                type={'multiselect'}
                label={'AI Tags'}
                icon={TagFilterIcon}
                initialSelectedValues={allTags}
                data={StoredKanbanTags}
                func={(data: any) => {
                  setAllTags(data)
                }}
              ></DynamicMultiSelect>
            )}
            <>
              {dynamicFilters?.map((elem: any) => {
                if (allFilter.includes(elem.key)) {
                  return elem.view
                }
              })}
              {flag.accounts && allFilter.includes('AccountTypes') && (
                <MultiSelect
                  label={'Account Types'}
                  icon={SentimentIcon}
                  data={accountTypes}
                  content={accountTypesContent}
                  clearClick={() => {
                    setAccountTypes([])
                  }}
                  iconList={accountTypesList}
                ></MultiSelect>
              )}
              {crmDynamicFilters?.map((elem: any) => {
                if (allFilter.includes(elem.key)) {
                  return elem.view
                }
              })}
            </>
          </FilterContainer>

          <FilterButton className="wsa-filter-chip">
            <Popover
              content={content}
              trigger="click"
              placement="bottomLeft"
              showArrow={false}
            >
              <div
                className="add-filter-btn"
                onClick={() => {
                  ;(window as any).analytics.track('Add Filters', {
                    userId: user?.id,
                    userEmail: user?.email,
                    teamId: user?.team_id,
                    teamName: user?.team_name,
                  })
                }}
              >
                <PlusOutlined className="plus-icon" />
                Add Filter
              </div>
            </Popover>
            <div className="reset-btn" onClick={clearAllFilters}>
              <img src={CloseIcon} alt="refresh icon" className="w-3 h-3" />
              Reset
            </div>
            <Dropdown.Button
              className="filters-btn rounded border-none download-btn"
              type="default"
              size="middle"
              icon={
                <CaretDown
                  className="mt-1"
                  color="var(--color-purple-4)"
                  weight="bold"
                />
              }
              onClick={() =>
                setModalDetails((prev) => ({
                  ...prev,
                  open: true,
                  modalType: 'filtered_requests_in_view',
                }))
              }
              menu={{
                items: download,
                onClick: handlePreviewClick,
              }}
            >
              <DownloadSimple
                color="var(--color-purple-4)"
                className="mt-1"
                weight="bold"
              />
            </Dropdown.Button>
          </FilterButton>
        </div>

        {children}
      </KanbanFilterContext.Provider>
    )
  }
)
export const useKanbanFilterV2 = () => {
  return useContext(KanbanFilterContext)
}
