import { MouseEvent } from 'react'
import styled from 'styled-components'

type FilerChipProps = {
  label: string
  iconAlt: string
  iconSrc: string
  onClick: () => void
  content?: React.ReactNode
  className?: string
}

export const FilterChip = ({
  label,
  iconAlt,
  iconSrc,
  onClick,
  content,
  className,
}: FilerChipProps) => {
  return (
    <FilterChipWrapper onClick={onClick} className={className}>
      <img src={iconSrc} alt={iconAlt} />
      <span>{label}</span>
      {content}
    </FilterChipWrapper>
  )
}

const FilterChipWrapper = styled.div`
  height: 28px;
  display: flex;
  cursor: pointer;
  padding-left: 6px;
  line-height: 16px;
  border-radius: 6px;
  padding-right: 8px;
  align-items: center;
  border: 1px solid var(--color-gray-3);
  img {
    margin-right: 6px;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-gray-5);
  }
  :hover {
    .ClearIconWrapper {
      svg {
        opacity: 0.55;
      }
    }
  }
`

type ClearIconProps = {
  onClick: (e: MouseEvent<HTMLSpanElement>) => void
}

export const ClearIcon = ({ onClick }: ClearIconProps) => {
  return (
    <ClearIconWrapper className="ClearIconWrapper" onClick={onClick}>
      <span
        role="img"
        aria-label="close-circle"
        className="anticon anticon-close-circle"
      >
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="close-circle"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
        </svg>
      </span>
    </ClearIconWrapper>
  )
}

const ClearIconWrapper = styled.span`
  margin-left: 4px;
  svg {
    opacity: 0.25;
    transition: opacity 0.3s;
  }
`
