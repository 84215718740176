import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Space } from 'antd'
import styled from 'styled-components'
import { ClusterType } from '../../../../types'
import { CustomSpinner } from '../Spinner'
import { CustomIconBtn } from '../StyledComponent'

type renderOperationProps = {
  editingRecord?: ClusterType
  record: ClusterType
  isCreateLoading: boolean
  isUpdateLoading: boolean
  onEdit: (record: ClusterType) => void
  onSave: () => void
  onCancel: () => void
}

export const renderOperations = ({
  editingRecord,
  record,
  isCreateLoading,
  isUpdateLoading,
  onEdit,
  onSave,
  onCancel,
}: renderOperationProps) => {
  if (!editingRecord) {
    return (
      <OperationWrapper size="small">
        <CustomIconBtn
          type="text"
          size="small"
          className="operation-icon"
          onClick={() => onEdit(record)}
          icon={<EditOutlined />}
        />
        <CustomIconBtn
          type="text"
          size="small"
          className="operation-icon"
          icon={<DeleteOutlined style={{ color: 'var(--color-red-1)' }} />}
        />
      </OperationWrapper>
    )
  }
  if (editingRecord._id === record._id) {
    return (
      <OperationWrapper size="small">
        <CustomIconBtn
          type="text"
          size="small"
          onClick={onSave}
          icon={
            isCreateLoading || isUpdateLoading ? (
              <CustomSpinner />
            ) : (
              <CheckOutlined style={{ color: 'var(--color-green-3)' }} />
            )
          }
          disabled={
            editingRecord._id === '' ? isCreateLoading : isUpdateLoading
          }
        />
        <CustomIconBtn
          type="text"
          size="small"
          onClick={onCancel}
          icon={<CloseOutlined style={{ color: 'var(--color-gray-6)' }} />}
          disabled={
            editingRecord._id === '' ? isCreateLoading : isUpdateLoading
          }
        />
      </OperationWrapper>
    )
  }
}

const OperationWrapper = styled(Space)`
  .ant-space-item {
    .operation-icon {
      display: none;
    }
  }
`
