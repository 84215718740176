import Button from 'antd/es/button'
import { Component, ErrorInfo, ReactNode } from 'react'
import styled from 'styled-components'
import { fetchUser } from '../api/auth'
import Error from '/images/error.png'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const ImageWrapper = styled.div`
  margin-top: 32px;
`

const ErrorText = styled.h2`
  margin-top: 32px;
`

const StyledButton = styled(Button)`
  margin-top: 16px;
`

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true })
    fetchUser()
      .then((user) => {
        ;(window as any).analytics.track('Page Crash - Error Boundary', {
          userId: user?.id,
          teamId: user?.team_id,
          userEmail: user?.email,
          teamName: user?.team_name,
          error,
          errorInfo,
          origin: window?.location?.origin,
        })
      })
      .catch(() => {
        ;(window as any).analytics.track('Page Crash - Error Boundary', {
          userId: null,
          error,
          errorInfo,
          origin: window?.location?.origin,
        })
      })
    // eslint-disable-next-line no-console
    console.error('Caught in ErrorBoundary', {
      error,
      errorInfo,
    })
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      console.log('Reloading page')
      window.location.reload()
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Wrapper>
          <ImageWrapper>
            <img src={Error} width={285} height={196} alt="Error" />
          </ImageWrapper>
          <ErrorText>
            Some error happened! Please try refreshing the page.
          </ErrorText>
          <StyledButton onClick={() => location.reload()}>Refresh</StyledButton>
        </Wrapper>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
