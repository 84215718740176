/* eslint-disable max-lines */
import { DotsSixVertical } from 'phosphor-react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useMaEditorStore } from '../../../store/maEditor'
import AddNewBlock from './AddNewBlock'
import BlockActions from './Blocks/BlockActions'
import BlockContext from './Blocks/BlockContext'
import BlockHeader from './Blocks/BlockHeader'
import BlockImage from './Blocks/BlockImage'
import BlockSection from './Blocks/BlockSection'
import BlockVideo from './Blocks/BlockVideo'
import { DeleteBlock } from './Commons'

type IProps = {
  block: any
  index: number
  memBlocks: any
  toggleModal: () => void
  setModalDetails: (args: any) => void
  setVideoModalDetails: (args: any) => void
  setButtonModalDetails: (args: any) => void
  handleSelect: (type: string, index: number) => void
  debouncedHandleSectionChange: (e: any, index: number, type: string) => void
  selectedText: string | undefined
  isReadOnly?: boolean
}

const BlockWrapper = styled.div`
  position: relative;
  padding-left: 40px;
  .drag-icon {
    display: none;
  }
`

const Drag = styled.div<{ $center?: boolean }>`
  top: 0;
  left: 0;
  cursor: grab;
  position: absolute;

  ${({ $center }) => {
    if ($center) {
      return `
      top: 50%;
      transform: translateY(-38%);
      `
    }
  }}
`

const StyledDivider = styled.div`
  height: 31px;
  display: flex;
  align-items: center;
  position: relative;

  .render-plus-banner {
    display: none;
    position: absolute;
    margin: 0;
    width: 90%;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
  }

  .delete-icon {
    display: none;
    width: 24px;
    height: 24px;
    right: -10px;
    box-shadow: 0 0 4px 0 var(--color-red-3);
  }

  :hover {
    .delete-icon {
      display: flex;
    }
    .render-plus-banner {
      display: flex;
    }
  }
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: var(--color-gray-7);
`
const RenderBlocks = ({
  block,
  index,
  memBlocks,
  toggleModal,
  handleSelect,
  setModalDetails,
  setVideoModalDetails,
  setButtonModalDetails,
  debouncedHandleSectionChange,
  selectedText,
  isReadOnly,
}: IProps) => {
  const {
    type: blockType,
    text,
    title,
    fields = [],
    elements = [],
    alt_text,
    accessory,
    image_url,
    title_url,
    video_url,
    description,
    author_name,
    thumbnail_url,
    provider_name,
    provider_icon_url,
  } = block
  const { campaignId } = useParams()
  const type = String(blockType).toLowerCase()

  const onBlockFocus = () => {
    handleSelect(type, index)
  }

  const onBlockBlur = (e: any) => {
    debouncedHandleSectionChange(e, index, type)
  }
  const dispatch = useMaEditorStore((state) => state.dispatch)
  const openModal = () => {
    setModalDetails({
      open: true,
      index,
      type,
    })
    if (type === 'image' || type === 'section') {
      dispatch({
        type: 'SET_IMAGE_TITLE',
        payload: {
          imageTitle: memBlocks[index]?.title?.text || '',
        },
      })
      if (memBlocks[index].image_url) {
        dispatch({
          type: 'SET_IMAGE_URL',
          payload: {
            imageUrl: memBlocks[index].image_url,
          },
        })
        dispatch({
          type: 'SET_ALT_TEXT',
          payload: {
            altText: memBlocks[index].alt_text,
          },
        })
      } else {
        dispatch({
          type: 'SET_IMAGE_URL',
          payload: {
            imageUrl: memBlocks[index].accessory.image_url,
          },
        })
        dispatch({
          type: 'SET_ALT_TEXT',
          payload: {
            altText: memBlocks[index].accessory.alt_text,
          },
        })
      }

      window.analytics.track('Campaign Edit - Image modal open', {
        campaignId: campaignId,
      })
    }
    if (type === 'context') {
      dispatch({
        type: 'SET_BUTTON_TEXT',
        payload: {
          buttonText: memBlocks[index].elements.text.text,
        },
      })
      dispatch({
        type: 'SET_BUTTON_URL',
        payload: {
          buttonUrl: memBlocks[index].elements.url,
        },
      })
      window.analytics.track('Campaign Edit - Button modal open', {
        campaignId: campaignId,
      })
    }
  }

  const renderBlock = (isReadOnly?: boolean) => {
    switch (type) {
      case 'header':
        return (
          <BlockHeader
            block={text}
            index={index}
            onBlur={onBlockBlur}
            onFocus={onBlockFocus}
            toggleModal={toggleModal}
            isReadOnly={isReadOnly}
          />
        )

      case 'context':
        return (
          <BlockContext
            index={index}
            elements={elements}
            onBlur={onBlockBlur}
            onFocus={onBlockFocus}
            toggleModal={toggleModal}
            onPopoverClick={openModal}
            selectedText={selectedText}
            isReadOnly={isReadOnly}
          />
        )

      case 'divider':
        return (
          <StyledDivider>
            <Line />
            {!isReadOnly && <DeleteBlock index={index} />}
            {!isReadOnly && <AddNewBlock index={index} />}
          </StyledDivider>
        )

      case 'section':
        return (
          <BlockSection
            block={text}
            fields={fields}
            accessory={accessory}
            typeIndex={index}
            type={type}
            onBlur={onBlockBlur}
            onFocus={onBlockFocus}
            toggleModal={toggleModal}
            handleImageClick={openModal}
            handleButtonClick={() => {
              setButtonModalDetails({ open: true, index, type })
              dispatch({
                type: 'SET_BUTTON_TEXT',
                payload: {
                  buttonText: accessory
                    ? memBlocks[index].accessory.text.text
                    : '',
                },
              })
              dispatch({
                type: 'SET_BUTTON_URL',
                payload: {
                  buttonUrl: accessory ? memBlocks[index].accessory.url : '',
                },
              })
              window.analytics.track('Campaign Edit - Button modal open', {
                campaignId: campaignId,
              })
            }}
            selectedText={selectedText}
            isReadOnly={isReadOnly}
          />
        )

      case 'image':
        return (
          <BlockImage
            index={index}
            imageUrl={image_url}
            altText={alt_text}
            onClick={openModal}
            isReadOnly={isReadOnly}
          />
        )

      case 'video':
        return (
          <BlockVideo
            title={title}
            title_url={title_url}
            description={description}
            video_url={video_url}
            altText={alt_text}
            thumbnail_url={thumbnail_url}
            author_name={author_name}
            provider_name={provider_name}
            provider_icon_url={provider_icon_url}
            size="big"
            index={index}
            blockType={type}
            onClick={() => {
              setVideoModalDetails({
                open: true,
                index,
                type,
              })
              dispatch({
                type: 'SET_VIDEO_ALT_TEXT',
                payload: {
                  videoAltText: memBlocks[index].alt_text,
                },
              })
              dispatch({
                type: 'SET_VIDEO_TITLE',
                payload: {
                  videoTitle: memBlocks[index].title.text,
                },
              })
              dispatch({
                type: 'SET_THUMBNAIL_URL',
                payload: {
                  thumbnailUrl: memBlocks[index].thumbnail_url,
                },
              })
              dispatch({
                type: 'SET_VIDEO_URL',
                payload: {
                  videoUrl: memBlocks[index].video_url,
                },
              })
              window.analytics.track('Campaign Edit - Video modal open', {
                campaignId: campaignId,
              })
            }}
            isReadOnly={isReadOnly}
          />
        )

      case 'actions':
        return (
          <BlockActions
            elements={elements}
            onClick={() => {
              setButtonModalDetails({
                open: true,
                index,
                type,
              })
              window.analytics.track('Campaign Edit - Button modal open', {
                campaignId: campaignId,
              })
            }}
            isReadOnly={isReadOnly}
          />
        )

      default:
        return null
    }
  }

  return (
    <BlockWrapper className="render-block-wrapper">
      {!isReadOnly && (
        <Drag className="drag-icon" $center={type === 'divider'}>
          <DotsSixVertical
            size={18}
            color="var(--color-gray-6)"
            weight="bold"
          />
        </Drag>
      )}
      {renderBlock(isReadOnly)}
      {!isReadOnly && <AddNewBlock index={index} />}
    </BlockWrapper>
  )
}

export default RenderBlocks
