import React, { useContext, useState } from 'react'
import { ChannelFilterType } from './definitions'

interface ChannelContextType {
  showHiddenChannelModal: boolean
  setShowHiddenChannelModal: React.Dispatch<React.SetStateAction<boolean>>
  showSelectedChannelDrawer: boolean
  setShowSelectedChannelDrawer: React.Dispatch<React.SetStateAction<boolean>>
  filters: ChannelFilterType
  setFilters: React.Dispatch<React.SetStateAction<ChannelFilterType>>
}

const ChannelContext = React.createContext<ChannelContextType>({
  showHiddenChannelModal: false,
  setShowHiddenChannelModal: () => null,
  showSelectedChannelDrawer: false,
  setShowSelectedChannelDrawer: () => null,
  filters: {
    search: '',
    page: 0,
    sort: 'ascending',
    filter: {
      members: [],
      channelType: 'all',
    },
  },
  setFilters: () => null,
})

export const ChannelProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [showHiddenChannelModal, setShowHiddenChannelModal] = useState(false)
  const [showSelectedChannelDrawer, setShowSelectedChannelDrawer] =
    useState(false)

  const [filters, setFilters] = useState<ChannelFilterType>({
    search: '',
    page: 0,
    sort: 'ascending',
    filter: {
      members: [],
      channelType: 'all',
    },
  })

  return (
    <ChannelContext.Provider
      value={{
        showHiddenChannelModal,
        setShowHiddenChannelModal,
        showSelectedChannelDrawer,
        setShowSelectedChannelDrawer,
        filters,
        setFilters,
      }}
    >
      {children}
    </ChannelContext.Provider>
  )
}

export const useChannels = () => {
  return useContext(ChannelContext)
}
