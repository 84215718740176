import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Divider, Select, Switch, Tooltip, Typography } from 'antd'
import { useState } from 'react'
import { IntegrationModalHeader } from '../../../components'
import { API_ROOT, HUBSPOT_APP_ID } from '../../../config/constants'
import { useAuth } from '../../../context'
import HubspotSvg from '../../../images/logos/hubspot.svg'
import { useConnectedApp } from '../../ConnectedApps'
import { ExtendedConnectorConfiguration } from '../ConnectorConfiguration'
import { AccountConfiguration } from './AccountConfiguration'
import { FieldMapping } from './FieldMapping'

const { Text } = Typography
type Props = {
  requestType: boolean
}
export const HubspotIntegration = ({ requestType }: Props) => {
  const { user } = useAuth()
  const { isEdition } = useConnectedApp()
  const [lifecycleStage, setLifecycleStage] = useState<string>()

  return (
    <main className="w-full">
      {!isEdition ? (
        <div>
          <IntegrationModalHeader
            imgSrc={HubspotSvg}
            imageClassName="w-[42px] h-[42px]"
            title="HubSpot Tickets Integration"
            description="O-Auth enabled integration to support ticketing"
          />
          <Divider className="my-5 py-0" />
          {requestType ? (
            <Tooltip title="Tech Support Request Type is already in use.">
              <Button
                aria-disabled={requestType}
                className="cursor-not-allowed opacity-40 hover:text-[#ffebe6] hover:bg-[#FF5C35] rounded-full bg-[#FF5C35] !text-white font-medium"
                href={`https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_APP_ID}&redirect_uri=${API_ROOT}/v1/oauth/hubspot/redirect&state=${user?.team_id}&scope=oauth%20files%20tickets%20sales-email-read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.objects.companies.read%20crm.objects.owners.read%20conversations.read%20conversations.write`}
              >
                Connect to HubSpot
              </Button>
            </Tooltip>
          ) : (
            <Button
              type="text"
              aria-disabled={requestType}
              className="bg-[#FF5C35] !text-white font-medium hover:bg-[#ffebe6] hover:text-[#FF5C35] rounded-full transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 hover:border-[#FF5C35]"
              href={`https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_APP_ID}&redirect_uri=${API_ROOT}/v1/oauth/hubspot/redirect&state=${user?.team_id}&scope=oauth%20files%20tickets%20sales-email-read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.objects.companies.read%20crm.objects.owners.read%20conversations.read%20conversations.write`}
            >
              Connect to HubSpot
            </Button>
          )}
        </div>
      ) : (
        <div>
          <IntegrationModalHeader
            imgSrc={HubspotSvg}
            imageClassName="w-[42px] h-[42px]"
            title="Configure HubSpot Tickets Integration"
            description="Configure HubSpot behavior on Slack"
          />
          <Divider className="my-5 py-0" />
          <div className="mb-3">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>HubSpot Response Medium</Text>
              </div>
              <Tooltip
                placement="top"
                title="Choose how you'd like to send responses from HubSpot"
              >
                <InfoCircleOutlined data-intercom-target="Settings Integrations Hubspot Response Medium" />
              </Tooltip>
            </div>
          </div>
          <div className="mb-4">
            <div className="flex items-center justify-between">
              <div>
                <Text>Notes</Text>
                {/* <Tag color="success">Default</Tag> */}
              </div>
              <div>
                <Switch checked={true} className="bg-success" />
              </div>
            </div>
          </div>
          <div className="mb-9">
            <div className="grid grid-cols-6 items-center space-y-2">
              <div className="col-span-2">
                <Text strong>Filter Accounts by Lifecycle Stage</Text>
              </div>
              <div className="col-span-2" />
              <div className="col-span-2">
                {/* Filter */}
                <Select
                  allowClear
                  value={lifecycleStage}
                  onChange={(value) => setLifecycleStage(value)}
                  className="w-full mr-4"
                  showSearch={false}
                  dropdownMatchSelectWidth
                  showArrow
                  filterOption={false}
                  placeholder="Lifecycle Stage"
                >
                  {[
                    {
                      key: '1',
                      value: 'subscriber',
                      label: <span>Subscriber</span>,
                    },
                    {
                      key: '2',
                      value: 'customer',
                      label: <span>Customer</span>,
                    },
                  ].map((option) => (
                    <Select.Option value={option.value} key={option.key}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <Divider className="my-6 py-0" />
          <AccountConfiguration lifecycleStage={lifecycleStage} />
          <FieldMapping />
          <ExtendedConnectorConfiguration
            isEdition={isEdition}
            connector="HUBSPOT"
          />
        </div>
      )}
    </main>
  )
}
