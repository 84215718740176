import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons'
import '@mdxeditor/editor/style.css'
import { Switch, Tooltip, message } from 'antd'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { QNAType } from '../../../../types'
import { CustomSpinner } from '../Spinner'
import { CustomIconBtn } from '../StyledComponent'
import CustomTipTapEditor from './CustomTiptapEditor'

export const QNAExpanded = ({
  qna,
  expandAllStatus,
  isLoading,
  saveChanges,
  setEditedRowId,
  setEditedAnswer,
}: {
  qna: QNAType
  expandAllStatus: boolean
  isLoading: boolean
  saveChanges: (data: QNAType) => void
  setEditedRowId: (data: string) => void
  setEditedAnswer: (data: string) => void
}) => {
  const editorRef = useRef<HTMLDivElement>(null)

  const handleSwitch = (checked: boolean) => {
    const updatedQna = { ...qna, isActive: checked }
    saveChanges(updatedQna)
  }
  const handleInputChange = (value: string | undefined) => {
    if (value && !expandAllStatus) {
      setEditedRowId(qna._id)
      setEditedAnswer(value)
    }
  }

  const handleVerify = () => {
    const updatedQna = { ...qna, isVerified: !qna.isVerified }
    saveChanges(updatedQna)
  }

  useEffect(() => {
    if (isLoading) {
      message.loading('Saving data...')
    }
  }, [isLoading])

  useEffect(() => {
    if (editorRef.current && !expandAllStatus && !isLoading) {
      editorRef.current.focus?.()
    }
  }, [])

  return (
    <Wrapper>
      <CustomSpinner isLoading={isLoading}>
        <div className="answer-area" data-color-mode="light">
          <CustomTipTapEditor
            content={qna.answer}
            editorRef={editorRef}
            handleInputChange={handleInputChange}
          />
        </div>
        <div className="btn-group">
          <Tooltip
            title="Verify"
            placement="bottom"
            color="var(--color-purple-1)"
          >
            <CustomIconBtn
              type="text"
              size="middle"
              onClick={handleVerify}
              disabled={isLoading}
              icon={
                qna.isVerified ? (
                  <CheckCircleFilled
                    style={{ color: 'var(--color-green-3)' }}
                  />
                ) : (
                  <CheckCircleOutlined
                    style={{ color: 'var(--color-gray-6)' }}
                  />
                )
              }
            />
          </Tooltip>
          <Tooltip
            title="Activate"
            placement="bottom"
            color="var(--color-purple-1)"
          >
            <Switch
              size="small"
              style={{
                margin: '0px 4px',
                backgroundColor: 'var(--color-purple-1)',
              }}
              checked={qna.isActive}
              onChange={handleSwitch}
              disabled={isLoading}
            />
          </Tooltip>
        </div>
      </CustomSpinner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 4px 320px 4px 152px;
  .answer-area {
    padding: 0 !important;
    margin-bottom: 8px;
    resize: none;
  }
  .btn-group {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .prose {
    font-family: Lato;
    line-height: 24px;
    font-size: 15px;
  }
`
