import { getColorByLuminance } from '@utils/textUtils'
import { Divider, Layout, Modal, Tooltip } from 'antd'
import 'commandbar-launcher/assets/index.css'
import { CaretDoubleLeft, CaretDoubleRight } from 'phosphor-react'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import { useAuth } from '../../../context'
import useEvents from '../../../hooks/useEvents'
import { ReactComponent as RequestSVG } from '../../../images/icons/open_requests_icon.svg'
import { ReactComponent as SettingsSVG } from '../../../images/icons/settings.svg'
import { ReactComponent as Logo } from '../../../images/logos/thena-logomark.svg'
import { useLayoutStore } from '../../../store/layoutStore'
import { emptyObject } from '../../../utils/empty'
import CustomerPageHeader from '../pageHeader/CustomerPageHeader'
import OrgSelector from './OrgSelector'
import './index.css'
const { Sider, Content } = Layout

interface Props {
  children: ReactNode
}

const LogoWrapper = styled.div<{ $fillColor: string }>`
  path {
    fill: ${({ $fillColor }) => $fillColor};
  }
`

const ItemWrapper = styled.div<{
  $isSelected?: boolean
  $selectedColor: string
}>`
  background-color: ${({ $isSelected, $selectedColor }) =>
    $isSelected ? $selectedColor : 'transparent'};
  :hover {
    background-color: ${({ $selectedColor }) => $selectedColor};
  }
`

const ROUTES_TO_HIDE_SIDEBAR = ['/', '/logout', '/slack/callback']

const CustomerLayout = ({ children }: Props) => {
  const navigate = useNavigate()
  const trackEvent = useEvents()
  const { pathname } = useLocation()
  const dispatch = useLayoutStore((state) => state.dispatch)
  const collapsed = useLayoutStore((state) => state.collapsed)
  const [selectedKeys, setSelectedKeys] = useState(pathname.slice(1))
  const [mobileView, setMobileView] = useState(window.innerWidth < 640)

  const { user } = useAuth()
  const { branding = emptyObject } = user?.organization || (emptyObject as any)
  const { page_background: color1, primary: color2 } =
    branding?.colors || emptyObject

  // swapped for a hack for Auth0 color issue
  const page_background = color2
  const primary = color1

  const selectedColor = getColorByLuminance(page_background)

  useEffect(() => {
    switch (pathname) {
      case '/settings':
        setSelectedKeys('settings')
        break
      case '/requests':
        setSelectedKeys('requests')
        break
      default:
        break
    }
  }, [pathname])

  const toggleCollapsed = () => {
    dispatch({
      type: 'SET_NAVBAR_COLLAPSED',
      payload: {
        collapsed: !collapsed,
      },
    })
  }

  const onItemClick = (key: string) => {
    trackEvent('Page View', { oldView: pathname, newView: '/' + key })
    ;(window as any).analytics.page('/' + key)
    setSelectedKeys(key)
    navigate('/' + key)
    setMobileView(window.innerWidth < 640)
  }

  const hideSidebar = ROUTES_TO_HIDE_SIDEBAR.includes(pathname)

  return (
    <Layout
      style={{ height: '100vh', backgroundColor: 'var(--color-white-2)' }}
    >
      <Modal
        title=""
        centered
        width="370px"
        footer={null}
        closable={false}
        open={mobileView}
      >
        <p>For better experience, use Desktop version of the app.</p>
      </Modal>
      {!hideSidebar && (
        <Sider
          theme="dark"
          collapsed={collapsed}
          onCollapse={(value) =>
            dispatch({
              type: 'SET_NAVBAR_COLLAPSED',
              payload: {
                collapsed: value,
              },
            })
          }
          width={240}
          style={{
            top: 0,
            left: 0,
            zIndex: 99999,
            height: '100vh',
            position: 'sticky',
            backgroundColor: page_background,
            borderRight: '1px solid var(--color-gray-7)',
          }}
        >
          <div
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="flex flex-col items-center justify-center group">
              {!collapsed ? (
                <div className="flex justify-between items-center w-[72%] mt-11 mb-4">
                  <OrgSelector
                    expanded
                    color={primary}
                    bgColor={page_background}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-between mb-4 mt-11">
                  <OrgSelector color={primary} bgColor={page_background} />
                </div>
              )}
            </div>
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div className="p-[10px] relative">
                <Tooltip
                  placement="right"
                  title={collapsed ? 'Requests' : false}
                  getTooltipContainer={(trigger) =>
                    trigger.parentElement || document.body
                  }
                >
                  <ItemWrapper
                    $selectedColor={selectedColor}
                    onClick={() => onItemClick('requests')}
                    $isSelected={selectedKeys === 'requests'}
                    className="h-[40px] rounded-[4px] flex items-center gap-[10px] px-[24px] cursor-pointer"
                    style={{
                      color: primary,
                      justifyContent: collapsed ? 'center' : 'flex-start',
                    }}
                  >
                    <RequestSVG width={22} height={22} className="shrink-0" />
                    {collapsed ? '' : 'Requests'}
                  </ItemWrapper>
                </Tooltip>
                <Divider
                  style={{
                    borderColor: primary,
                  }}
                  className="my-[10px]"
                />
                <Tooltip
                  placement="right"
                  title={collapsed ? 'Settings' : false}
                  getTooltipContainer={(trigger) =>
                    trigger.parentElement || document.body
                  }
                >
                  <ItemWrapper
                    $selectedColor={selectedColor}
                    onClick={() => onItemClick('settings')}
                    $isSelected={selectedKeys === 'settings'}
                    className="h-[40px] rounded-[4px] flex items-center gap-[10px] px-[24px] cursor-pointer"
                    style={{
                      color: primary,
                      justifyContent: collapsed ? 'center' : 'flex-start',
                    }}
                  >
                    <SettingsSVG width={22} height={22} className="shrink-0" />
                    {collapsed ? '' : 'Settings'}
                  </ItemWrapper>
                </Tooltip>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center mb-[20px]">
              {!collapsed ? (
                <div
                  style={{ color: 'var(--color-white)' }}
                  className="font-medium rounded-[4px] w-52 pl-4"
                >
                  <div
                    className="mb-[20px] flex items-center"
                    style={{
                      color: primary,
                    }}
                  >
                    <LogoWrapper $fillColor={primary}>
                      <Logo width={22} height={22} className="mr-[10px]" />
                    </LogoWrapper>
                    Thena App
                  </div>
                  <div
                    style={{
                      color: primary,
                    }}
                    onClick={toggleCollapsed}
                    className="cursor-pointer flex items-center gap-[18px]"
                  >
                    <CaretDoubleLeft
                      size={22}
                      color={primary}
                      className="cursor-pointer"
                    />
                    Collapse
                  </div>
                </div>
              ) : (
                <div>
                  <LogoWrapper $fillColor={primary}>
                    <Logo width={22} height={22} className="block mb-[16px]" />
                  </LogoWrapper>
                  <CaretDoubleRight
                    size={22}
                    color={primary}
                    onClick={toggleCollapsed}
                    className="cursor-pointer"
                  />
                </div>
              )}
            </div>
          </div>
        </Sider>
      )}
      {hideSidebar ? (
        <Content className="w-full h-full overflow-y-auto" hasSider>
          {children}
        </Content>
      ) : (
        <Layout
          className="flex h-full site-layout"
          style={{ backgroundColor: 'var(--color-white)' }}
        >
          <CustomerPageHeader />
          <Content className="w-full h-full overflow-y-auto" hasSider>
            {children}
          </Content>
        </Layout>
      )}
    </Layout>
  )
}
export default CustomerLayout
