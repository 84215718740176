import { message } from 'antd'
import React, { useContext } from 'react'
import { UseMutationResult, useMutation, useQueryClient } from 'react-query'
import { reSyncAdditionFields } from '../../../../api/integrations'

interface AdditionalFieldContextType {
  reSyncZendeskFieldMutation?: UseMutationResult<any, unknown, void, unknown>
  loading: boolean
}

const AdditionalFieldContext = React.createContext<AdditionalFieldContextType>({
  loading: false,
})

export const AdditionalFieldProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const queryClient = useQueryClient()

  // Added same mutation as testSalesforceConnectionMutation to avoid unnecessary checks and balances
  const reSyncZendeskFieldMutation = useMutation(reSyncAdditionFields, {
    onSuccess: () => {
      message.success('Zendesk additional fields re-sync successfully')
      queryClient.invalidateQueries(['fetch-zendesk-integration'])
    },
    onError: (error) => {
      console.error(error)
      message.error('Something went wrong')
    },
  })
  return (
    <AdditionalFieldContext.Provider
      value={{
        reSyncZendeskFieldMutation,
        loading: reSyncZendeskFieldMutation.isLoading,
      }}
    >
      {children}
    </AdditionalFieldContext.Provider>
  )
}

export const useZendeskAdditionalFields = () => {
  return useContext(AdditionalFieldContext)
}
