/* eslint-disable max-lines */
import { AxiosResponse, CancelToken } from 'axios'
import { api } from '.'

import { cloneDeep } from 'lodash'
import { RequestItem } from '../components/common/commandbar/utils'
import { CLOUDINARY_CLOUDNAME } from '../config'
import {
  KanbanReducerType,
  kanbanType,
  kanbanUpdate,
  slackConversation,
} from '../reducer/kanbanReducer/kanbanReducerState'
import {
  ClosedConversationResponseType,
  ConversationDataType,
  RequestStatusType,
  SingleConversation,
  SuggestedRequestStatusType,
} from '../types'

export const fetchKanban = (filterData: any, token: CancelToken) => {
  return api
    .post<AxiosResponse<kanbanType>>(`/v2/kanban`, filterData, {
      cancelToken: token,
    })
    .then(({ data }) => data.data)
}

export const fetchKanbanRequest = async (
  requestId: string,
  teamId?: string
) => {
  const { data } = await api.get<AxiosResponse<KanbanReducerType>>(
    `/v2/kanban/${requestId}?team_id=${teamId}`
  )
  return data.data
}

export const fetchKanbanTicket = async (ticketId: string) => {
  const { data } = await api.get<AxiosResponse<KanbanReducerType>>(
    `/v2/ticket/${ticketId}`
  )
  return data.data
}

export const fetchSearchKanbanRequest = async (
  query: string,
  token: CancelToken
) => {
  const { data } = await api.get<AxiosResponse<RequestItem[]>>(
    `/v2/kanban/search?query=${query}`,
    { cancelToken: token }
  )
  return data.data
}

export const fetchArchivedConversations = () =>
  api
    .get<AxiosResponse<ConversationDataType[]>>(`/v1/archived-conversations`)
    .then(({ data }) => data.data)

export const createATicket = (
  message_key_req_type: {},
  fromSuggestedRequest = true
) =>
  api
    .post('/v2/requests/createWebAppRequest', {
      message_details: message_key_req_type,
      fromSuggestedRequest: fromSuggestedRequest,
    })
    .then(({ data }) => data.data)

export const archiveConversation = (keys: { key: string; id: string }[]) =>
  api
    .post('/v1/conversation/mark-archived', { keys })
    .then(({ data }) => data.data)

export const restoreConversations = (keys: { key: string; id: string }[]) =>
  api
    .post('/v1/conversation/restore-archived', { keys })
    .then(({ data }) => data.data)

export const fetchConversationDetails = (
  channelId: string,
  message_ts?: string
) =>
  api
    .get<AxiosResponse<SingleConversation[]>>(
      `/v1/conversation?channel_id=${channelId}&message_ts=${message_ts}`
    )
    .then(({ data }) => data.data)

export const fetchFiveConversations = (
  channelId: string,
  previous: boolean,
  message_ts: string
) =>
  api
    .get<AxiosResponse<ConversationDataType[]>>(`/v2/kanban`, {
      params: { channel_id: channelId, message_ts, previous },
    })
    .then(({ data }) => data.data)

export const requestStatusChange = (data: SuggestedRequestStatusType) =>
  api.patch('/v1/conversation/status-change', data).then((data) => data)

export const ticketStatusChange = (data: RequestStatusType) =>
  api.patch('/v1/requests/status-change', data).then((data) => data)

export const fetchClosedConversations = (id: 'all' | string[], page: number) =>
  api
    .get<AxiosResponse<ClosedConversationResponseType>>(
      `/v1/closed-conversations`,
      {
        params: { channel_ids: id, page },
      }
    )
    .then(({ data }) => data.data)

export const fetchConfigureFields = () =>
  api.post('/v2/kanban/configured-fields').then(({ data }) => data.data)

export const fetchKanbanFilters = () =>
  api.post('/v2/kanban/filters', {}).then(({ data }) => data.data)

export const updateKanban = (data: kanbanUpdate) => {
  const payload = cloneDeep(data)
  delete payload._id

  return api.patch(`/v2/kanban/${data._id}`, payload).then((data) => data)
}

export const fetchSlackConversation = (
  data: slackConversation,
  signal?: AbortSignal
) =>
  api
    .post(`/v2/kanban/slack-conversations`, data, {
      signal,
    })
    .then((data) => data.data)

export const fetchInternalSlackConversation = (
  data: any,
  signal?: AbortSignal
) =>
  api
    .get(`/v2/kanban/internal-threads?id=${data.id}`, { data, signal })
    .then((data) => data.data)

export const fetchActivity = (data: any) =>
  api.get(`/v2/kanban/activity?id=${data.id}`).then((data) => data.data)

export const fetchEnabledConnector = () =>
  api.get(`/v2/kanban/enabled-connector`).then((data) => data.data)

export const kanbanNudgeUser = (id: any) =>
  api.post(`/v2/kanban/nudge-user`, { id: id }).then((data) => data.data)

export const kanbanCreateTicket = (data: any) => {
  return api.post(`/v2/kanban/create-ticket`, data).then((data) => data.data)
}

export const fetchClosed = (page: number | undefined) =>
  api
    .post<AxiosResponse<any>>(`/v2/kanban`, {
      filters: {
        status: 'CLOSED',
        page: page,
      },
    })
    .then(({ data }) => data.data)

export const postComments = ({
  message,
  channel_id,
  ts,
  emailData,
  request_mongo_id,
}: {
  message: string
  channel_id: string
  ts: string
  emailData: any
  request_mongo_id: string
}) =>
  api
    .post('/v2/kanban/post-comment-to-thread', {
      message,
      channel_id,
      ts,
      emailData,
      request_mongo_id,
    })
    .then(({ data }) => data.data)

export const uploadFilesToS3 = (formdata: FormData) =>
  api
    .post('/v2/kanban/upload-files-to-S3', formdata)
    .then(({ data }) => data.data)

export const uploadFilesToCloudinary = (formdata: FormData) =>
  fetch(
    `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/image/upload`,
    {
      method: 'POST',
      body: formdata,
    }
  )

export const deleteFileFromS3 = (key: string) =>
  api
    .post('/v2/kanban/delete-file-from-S3', { data: { key } })
    .then(({ data }) => data.data)

export const externalLinks = ({
  requestId,
  url,
}: {
  url: string
  requestId: string
  currentCardId?: string
}) =>
  api
    .post(`/v2/external-links/add/${requestId}`, { url })
    .then(({ data }) => data)

export const fetchRequestsForMerging = (
  channelId: string,
  original_request_id: string | null,
  team_id?: string
) =>
  api
    .get(`/v2/kanban/requests-for-merging`, {
      params: { channelId, original_request_id, team_id },
    })
    .then(({ data }) => data.data)

export const postMergeRequests = ({
  parent_request,
  toBeMergedRequests,
}: {
  parent_request: string
  toBeMergedRequests: string[]
}) =>
  api
    .post('/v2/kanban/merge-requests', {
      parent_request,
      toBeMergedRequests,
    })
    .then(({ data }) => data.data)

export const fetchExternalLinksForm = (): Promise<any> =>
  api.get('/v2/external-links/getforms').then((data) => data.data)

type LinearTicketPayload = {
  ticket: {
    title: string
    description: string
  }
  requestId: string
  linearTeamId: string
}

export const postCreateLinearTicket = (
  payload: LinearTicketPayload,
  sink: string
) =>
  api
    .post(`/v2/external-links/create/${sink}`, {
      ...payload,
    })
    .then(({ data }) => data.data)

type UnlinkPayload = {
  requestId: string
  externalLinkId: string
}

export const postUnlinkExternalLink = (payload: UnlinkPayload) =>
  api
    .post('/v2/external-links/unlink', {
      ...payload,
    })
    .then(({ data }) => data.data)

export const searchLinearTickets = async (
  searchTerm: string,
  token?: CancelToken
) =>
  await api
    .post(
      `/v2/external-links/search/linear`,
      {
        searchTerm,
      },
      {
        cancelToken: token,
      }
    )
    .then(({ data }) => data)

export const searchJiraTickets = async (
  searchTerm: string,
  token: CancelToken
) =>
  await api
    .post(
      `/v2/external-links/search/jira`,
      {
        searchTerm,
      },
      {
        cancelToken: token,
      }
    )
    .then(({ data }) => data)

export const getJiraTicketInfo = (id: string) =>
  api.get(`/v2/external-links/search/jira/${id}`).then(({ data }) => data)

export const newRequestCreation = ({
  channelId,
  text,
}: {
  channelId: string
  text: string
}) =>
  api
    .post('/v2/requests', {
      channelId,
      text,
    })
    .then(({ data }) => data.data)

export const fetchCustomStatus = () =>
  api.get(`/v2/requests/config/status`).then(({ data }) => data.data)

type StatusPayload = {
  parent: string
  name: string
  description: string
  isDefaultForParent: boolean
  isAIManaged: boolean
  isEnabled: boolean
}

export const addCustomStatus = (payload: StatusPayload) =>
  api
    .post(`/v2/requests/config/status`, { ...payload })
    .then(({ data }) => data.data)

export const updateCustomStatus = (
  subStatusId: string,
  payload: StatusPayload
) =>
  api
    .post(`/v2/requests/config/status/${subStatusId}`, {
      ...payload,
    })
    .then(({ data }) => data.data)

export const fetchSavedView = () =>
  api.get(`/v2/view`).then((data) => data.data)

export const addSavedView = (view: SavedViewPayload) =>
  api.post(`/v2/view`, { view }).then((data) => data.data)

export const updatedSavedView = (viewId: string, view: SavedViewPayload) =>
  api
    .patch(`/v2/view/${viewId}`, {
      view,
    })
    .then((data) => data.data)

export const deleteSavedView = (viewId: string) =>
  api.delete(`/v2/view/${viewId}`).then((data) => data.data)

export const postInternalComments = ({
  message,
  channel_id,
  ts,
  emailData,
  request_mongo_id,
}: {
  message: string
  channel_id: string
  ts: string
  emailData: any
  request_mongo_id: string
}) =>
  api
    .post('/v2/kanban/post-internal-comment-to-thread', {
      message,
      channel_id,
      ts,
      emailData,
      request_mongo_id,
    })
    .then(({ data }) => data.data)

export const deleteCustomStatus = (
  subStatusId: string,
  moveToSubStatusId: string | null
) =>
  api
    .delete(`/v2/requests/config/status/${subStatusId}`, {
      data: { moveToSubStatusId },
    })
    .then(({ data }) => data.data)

export const addThenaDefaultCustomStatus = (
  defaultThenaCustomSubStatus: boolean
) =>
  api
    .post(`/v2/requests/config/bulk/status`, { defaultThenaCustomSubStatus })
    .then(({ data }) => data.data)

type SavedViewPayload = {
  name: string
  type: string
  scope: string
  filter: string
  additionalPrefs: {
    active_columns: string[]
    ordered_columns?: string[]
  }
}
