import { CancelToken } from 'axios'
import { api } from '.'
import {
  Customer,
  CustomerSettingType,
  OrgIdResponse,
  OrgSettingsPayload,
} from '../pages/CustomerPortal/types'
import { KanbanReducerType } from '../reducer/kanbanReducer/kanbanReducerState'

export const fetchCustomerPortalKanban = async (
  filterData: any,
  token: CancelToken
) => {
  const { data } = await api.post(`/v2/customers/requests`, filterData, {
    cancelToken: token,
  })
  return data as KanbanReducerType[]
}

export const fetchCustomerPortalKanbanFilters = () =>
  api.get('/v2/customers/requests/filters').then(({ data }) => data)

export const fetchCustomerPortalKanbanRequest = async (
  requestId: string,
  teamId: string
) => {
  const { data } = await api.get(
    `/v2/customers/requests/${teamId}-${requestId}`
  )
  return data as KanbanReducerType
}

export const fetchCustomersList = () =>
  api.get('/v2/customers/team').then(({ data }) => data as Customer[])

export const fetchDeleteCustomer = (id: string) =>
  api.delete(`/v2/customers/team/member/${id}`).then(({ data }) => data)

export const fetchInviteCustomer = (email: string) =>
  api
    .post(`/v2/customers/team/member`, {
      email,
    })
    .then(({ data }) => data)

export const fetchInviteCustomerFromSettings = (
  email: string,
  team_id: string
) =>
  api
    .post(`/v2/settings/customers/invitation`, {
      email,
      team_id,
    })
    .then(({ data }) => data)

export const fetchCustomersListFromSettings = () =>
  api
    .get('/v2/settings/customers')
    .then(({ data }) => data as CustomerSettingType[])

export const fetchPostOrgSettings = (payload: OrgSettingsPayload) =>
  api.patch(`/v2/settings/organization`, payload).then(({ data }) => data)

export const fetchOrgIdByName = (name: string) =>
  api
    .get(`/v2/public/subdomain/${name}`)
    .then(({ data }) => data as OrgIdResponse)
