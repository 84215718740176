import { Input, Popover } from 'antd'
import Checkbox from 'antd/es/checkbox'
import Avatar from 'antd/lib/avatar/avatar'
import VirtualList from 'rc-virtual-list'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import { noOp } from '../../../utils/empty'
import { truncate } from '../../../utils/textUtils'
import { ClearIcon, FilterChip } from './Common'

const Wrapper = styled.div`
  .account-type {
    min-width: 90px;
  }
`

type IProps = {
  label: string
  itemList: any[]
  iconAlt: string
  iconSrc: string
  className?: string
  selectedList: any[]
  showSearch?: boolean
  clearList: () => void
  onChange: (arg: any) => void
  renderChipsAsAvatar?: boolean
}

const ChipContentWrapper = styled.div<{ $removeSpace: boolean }>`
  display: flex;
  margin-left: 4px;
  position: relative;
  align-items: center;
  .avatar-shift {
    top: 0;
    z-index: 2;
    right: 8px;
    position: relative;
  }
  ${({ $removeSpace }) => {
    if ($removeSpace) {
      return css`
        width: 32px;
        overflow: hidden;
      `
    }
  }}
`

const ContentWrapper = styled.div`
  min-height: 200px;
`

const List = styled.div`
  margin-top: 12px;
  max-height: 200px;
  overflow-y: scroll;
  min-width: 100px;
`

const Item = styled.label<{ $isChecked: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  align-items: center;
  label {
    cursor: pointer;
  }
  .avatar {
    margin-left: 6px;
    cursor: pointer;
  }
`

const Label = styled.span`
  margin-left: 6px;
`

const SurplusIdicator = styled.div`
  margin-left: 4px;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--color-purple-1);
`

const Chip = styled.div`
  flex-shrink: 0;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 8px;
  margin-right: 4px;
  border-radius: 6px;
  color: var(--color-white);
  background-color: var(--color-purple-1);
`

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
`

const MAX_ITEMS_IN_CHIP = 2

const MultipleSelect = ({
  label,
  iconAlt,
  iconSrc,
  itemList,
  onChange,
  clearList,
  className,
  selectedList,
  showSearch = true,
  renderChipsAsAvatar = false,
}: IProps) => {
  const [searchText, setSearchText] = useState('')
  const surplusElementCount = selectedList.slice(MAX_ITEMS_IN_CHIP).length

  const ChipContent = (
    <>
      <ChipContentWrapper
        $removeSpace={renderChipsAsAvatar && selectedList.length > 1}
      >
        {selectedList.slice(0, MAX_ITEMS_IN_CHIP).map((info, index) => {
          if (renderChipsAsAvatar) {
            return (
              <StyledAvatar
                size={20}
                src={info.src}
                className={index === 1 ? 'avatar avatar-shift' : 'avatar'}
              >
                {info.name.charAt(0).toUpperCase()}
              </StyledAvatar>
            )
          }

          return <Chip>{truncate(info?.name || '', 15)}</Chip>
        })}
      </ChipContentWrapper>
      {!!surplusElementCount && (
        <SurplusIdicator>+{surplusElementCount}</SurplusIdicator>
      )}
      {!!selectedList.length && (
        <ClearIcon
          onClick={(e) => {
            clearList()
            e.stopPropagation()
          }}
        />
      )}
    </>
  )

  const filteredList = itemList.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  )

  const Content = (
    <ContentWrapper className={showSearch ? 'min-w-[220px]' : 'min-w-[90px]'}>
      {showSearch && (
        <Input
          autoFocus
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
        />
      )}
      <List>
        <VirtualList
          height={200}
          itemKey="id"
          itemHeight={40}
          data={filteredList}
        >
          {(info: any) => {
            // {filteredList.map((info) => {
            const isChecked = selectedList.some((item) => item?.id === info?.id)
            const key = `checkbox-${info.id}`
            return (
              <Item key={key} htmlFor={key} $isChecked={isChecked}>
                <Checkbox
                  id={key}
                  checked={isChecked}
                  onChange={() => onChange(info)}
                >
                  {renderChipsAsAvatar && (
                    <Avatar size="small" className="avatar mr-1" src={info.src}>
                      {info.name.charAt(0).toUpperCase()}
                    </Avatar>
                  )}
                  {truncate(info.name, 25)}
                </Checkbox>
              </Item>
            )
          }}
        </VirtualList>
      </List>
    </ContentWrapper>
  )

  return (
    <Wrapper className={className}>
      <Popover
        trigger="click"
        content={Content}
        showArrow={false}
        placement="bottomLeft"
      >
        <FilterChip
          label={label}
          onClick={noOp}
          iconAlt={iconAlt}
          iconSrc={iconSrc}
          content={ChipContent}
        />
      </Popover>
    </Wrapper>
  )
}

export default MultipleSelect

/**
 * Improvements:
 * - close dropdown on ESC click
 */
