import { Button, Row } from 'antd'
import styled from 'styled-components'
import { CustomSpinner } from '../../Spinner'
import { PrimaryBtn } from '../../StyledComponent'

type FormBtnProps = {
  okText?: string
  cancelText?: string
  isSaving: boolean
  onClose: () => void
  onOk: (data?: any) => void
}
export const FormButtonGroup = ({
  okText = 'OK',
  cancelText,
  isSaving,
  onClose,
  onOk,
}: FormBtnProps) => {
  return (
    <Wrapper justify={'end'}>
      <Button
        onClick={onClose}
        disabled={isSaving}
        className="custom-cancel-btn"
      >
        {cancelText || 'Cancel'}
      </Button>
      <PrimaryBtn
        onClick={onOk}
        disabled={isSaving}
        icon={isSaving ? <CustomSpinner /> : null}
      >
        {okText}
      </PrimaryBtn>
    </Wrapper>
  )
}

const Wrapper = styled(Row)`
  .custom-cancel-btn:hover {
    color: var(--color-purple-1);
    border-color: var(--color-purple-1);
  }
  margin-top: 32px;
  gap: 8px;
`
