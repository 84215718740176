import { Tabs } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import useSources from '../../../../../hooks/useSources'

const AssistantTab = () => {
  const { id = '' } = useParams()
  const { data: sources = [] } = useSources({
    assistantId: id,
  })
  const statusArray = sources.map((s) =>
    parseInt(s.syncStatus.replace('%', ''), 10)
  )

  const status = statusArray.length < 1 || statusArray[0] < 100

  const list = [
    {
      label: `Sources`,
      key: 'sources',
    },
    {
      label: `Clusters`,
      key: 'clusters',
      disabled: status,
    },
    {
      label: `Q&A Repository`,
      key: 'qna',
      disabled: status,
    },
    {
      label: `Playground`,
      key: 'playground',
      disabled: status,
    },
  ]

  let tab = 'sources'
  const { pathname } = useLocation()
  const navigate = useNavigate()
  if (pathname) {
    if (pathname.includes('clusters')) tab = 'clusters'
    else if (pathname.includes('qna')) tab = 'qna'
    else if (pathname.includes('playground')) tab = 'playground'
  }

  const onChange = (key: string) => {
    navigate(`${key}`)
  }

  return (
    <CustomTab
      items={list}
      onChange={onChange}
      activeKey={tab}
      tabBarStyle={{ paddingLeft: '28px', margin: 0 }}
    />
  )
}

const CustomTab = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 15px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-purple-1) !important;
  }
  .ant-tabs-ink-bar {
    background: var(--color-purple-1);
  }
  .ant-tabs-tab .ant-tabs-tab-btn:hover {
    color: var(--color-purple-1);
  }
`
export default AssistantTab
