/* eslint-disable max-lines */
import {
  CaretDown,
  CaretUp,
  CheckCircle,
  PencilLine,
  Warning,
} from 'phosphor-react'

import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Input,
  Spin,
  Typography,
} from 'antd'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { fetchZendeskIntegration } from '../../../api/integrations'
import { helphubUtil } from '../../../utils/helphub'
import { useConnectedApp } from '../../ConnectedApps'
import { ExtendedConnectorConfiguration } from '../ConnectorConfiguration'
import { ZendeskConfigurationDetailsType } from './../definitions'
import { AdditionalFieldProvider } from './AdditionalFields/AdditionFieldProvider'
import { ZendeskAdditionalFields } from './AdditionalFields/ZendeskAdditionalFields'
import { AdditionalFieldType } from './AdditionalFields/types'
import { useZendesk } from './ZendeskProvider'

const { Panel } = Collapse
const { Text } = Typography

const SHOWED_ZENDESK_SUCCESS_STATUS = 'SHOWED_ZENDESK_SUCCESS_STATUS'

interface ZendeskConfigurationType {
  username: string
  token: string
  baseurl: string
  requestTypes: string[]
  commentTriggerId?: string
  commentWebhookId?: string
  createdOn?: string
  role?: string
  statusTriggerId?: string
  statusWebhookId?: string
  triggerCategoryId?: string
  isCredentialsInvalid?: boolean
  tag?: string
  additionalFields: AdditionalFieldType[]
}

type Props = {
  requestType: {
    label: string
    value: string
    disabled: boolean
  }[]
}

export const ZendeskSetUp = ({ requestType }: Props) => {
  const { isEdition } = useConnectedApp()
  const {
    isSetUpOpen,
    connectionPassed,
    connectionFailed,
    saveRequestTypesMutation,
    testZendeskConnectionMutation,
    setIsSetUpOpen,
  } = useZendesk()

  const {
    data: zendeskConfiguration,
    isLoading,
    refetch,
  } = useQuery('fetch-zendesk-integration', fetchZendeskIntegration, {
    select: (
      data: ZendeskConfigurationDetailsType
    ): ZendeskConfigurationType => {
      return {
        username: data?.username as string,
        token: data?.token as string,
        baseurl: data?.baseurl as string,
        requestTypes: data?.requestTypes as string[],
        commentTriggerId: data?.commentTriggerId as string,
        commentWebhookId: data?.commentWebhookId as string,
        createdOn: data?.createdOn as string,
        role: data?.role as string,
        statusTriggerId: data?.statusTriggerId as string,
        statusWebhookId: data?.statusWebhookId as string,
        triggerCategoryId: data?.triggerCategoryId as string,
        isCredentialsInvalid: data?.isCredentialsInvalid as boolean,
        tag: data?.tag as string,
        additionalFields: data?.additionalFields as AdditionalFieldType[],
      }
    },
  })

  useEffect(() => {
    // On Mount Refetch configuration
    refetch()
    return () => {
      // Set local storage that showed zendesk webhooks success status
      if (
        zendeskConfiguration?.username &&
        zendeskConfiguration?.commentTriggerId &&
        zendeskConfiguration?.commentWebhookId &&
        zendeskConfiguration?.statusTriggerId &&
        zendeskConfiguration?.statusWebhookId
      ) {
        localStorage.setItem(SHOWED_ZENDESK_SUCCESS_STATUS, 'YES')
      }
    }
  }, [])

  const onSubmit = (data: any) => {
    testZendeskConnectionMutation?.mutate({
      username: data.username,
      token: data.token,
      baseurl: data.baseurl,
      requestTypes: data.requestTypes,
      tag: data.tag,
    })
  }

  const onSave = (data: any) => {
    saveRequestTypesMutation?.mutate({
      username: data.username,
      token: data.token,
      baseurl: data.baseurl,
      requestTypes: data.requestTypes,
      tag: data.tag,
    })
  }

  const { handleSubmit, reset, control, formState } = useForm()

  useEffect(() => {
    reset(zendeskConfiguration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zendeskConfiguration])

  // const handleReAuthenticate = () => {
  //   window.open(zendeskConfiguration?.authUrl, '_self')
  // }

  // const disableSave = false

  const disableSave =
    (!isEdition && !connectionPassed) || (isEdition && !connectionPassed)

  // Loader
  if (isLoading)
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Spin size="large" />
      </div>
    )

  let connectionStatus = 'REQUIRED'

  if (connectionPassed) {
    connectionStatus = 'SUCCESS'
  } else if (
    (!connectionPassed && connectionFailed) ||
    zendeskConfiguration?.isCredentialsInvalid
  ) {
    connectionStatus = 'FAILED'
  }

  let zendeskWebhookStatus = ''
  const showedZendeskWebhookStatus = localStorage.getItem(
    SHOWED_ZENDESK_SUCCESS_STATUS
  )
  if (zendeskConfiguration?.username) {
    const isWebhooksCreated =
      zendeskConfiguration?.commentTriggerId &&
      zendeskConfiguration?.commentWebhookId &&
      zendeskConfiguration?.statusTriggerId &&
      zendeskConfiguration?.statusWebhookId
    if (!isWebhooksCreated) {
      zendeskWebhookStatus = 'FAILED'
    } else if (showedZendeskWebhookStatus !== 'YES' && isWebhooksCreated) {
      zendeskWebhookStatus = 'SUCCESS'
    }
  }

  return (
    <Collapse
      ghost
      accordion
      expandIconPosition="end"
      activeKey={isSetUpOpen ? '1' : '0'}
      className="border border-solid border-brand-border"
      expandIcon={() => {
        if (isEdition) return <PencilLine size={18} color="#696F7B" />
        if (!isSetUpOpen) return <CaretDown size={18} color="#7A00FF" />
        return <CaretUp size={18} color="#7A00FF" />
      }}
      onChange={() => {
        setIsSetUpOpen((p) => !p)
      }}
    >
      <Panel
        header={
          <div className="flex items-center justify-between">
            <h4 className="m-0 ml-3 font-semibold text-sm 3xl:text-[length:var(--sm)]">
              Setup Integration
            </h4>
            <p
              className="text-primary m-0 font-medium cursor-pointer"
              onClick={() => helphubUtil(133829)}
              role="button"
              data-intercom-target="Settings Integrations Zendesk Setup"
            >
              Where do I find this?
            </p>
          </div>
        }
        key="1"
      >
        <div>
          <div className="mt-2">
            <Text className="font-medium">Username</Text>
            <Controller
              name="username"
              control={control}
              render={({ field }) => (
                <Input
                  size="middle"
                  className="mt-1"
                  placeholder="Username"
                  disabled={connectionPassed}
                  {...field}
                  required
                />
              )}
            />
            {zendeskConfiguration?.role &&
              zendeskConfiguration?.role !== 'admin' && (
                <Text className="text-xs text-muted font-medium flex items-center">
                  <Warning className="mr-1" /> You are not an admin. click{' '}
                  <a
                    className="mx-1"
                    href="https://help.thena.ai/docs/connectors-slack-zendesk"
                    target="_blank"
                  >
                    here
                  </a>{' '}
                  for limitations
                </Text>
              )}
          </div>
          <div className="mt-3">
            <Text className="font-medium">Token</Text>
            <Controller
              name="token"
              control={control}
              render={({ field }) => (
                <Input
                  size="middle"
                  className="mt-1"
                  placeholder="Token"
                  disabled={connectionPassed}
                  {...field}
                  required
                />
              )}
            />
          </div>

          <div className="space-y-4 mt-3">
            <div>
              <Text className="font-medium">Base URL</Text>
              <div className="flex space-x-2 mt-1">
                <div className="w-full">
                  <Controller
                    name="baseurl"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Base URL"
                        className="mt-1"
                        size="middle"
                        {...field}
                        status={
                          connectionStatus === 'FAILED' ? 'error' : undefined
                        }
                        disabled={connectionPassed}
                        required
                      />
                    )}
                  />
                </div>
                <Button
                  className="text-primary border-primary/40 mt-1"
                  size="middle"
                  type="default"
                  onClick={handleSubmit(onSubmit)}
                  disabled={connectionPassed}
                  loading={testZendeskConnectionMutation?.isLoading}
                >
                  Test connection
                </Button>
              </div>
              <div className="flex space-x-2 mt-1">
                {connectionStatus === 'SUCCESS' && (
                  <span className="flex items-center">
                    <CheckCircle className="mr-1" size={14} color="#2BBC58" />{' '}
                    <Text className="text-xs text-success font-medium">
                      Connection Successful!
                    </Text>
                  </span>
                )}
                {connectionStatus === 'FAILED' && (
                  <span className="flex items-center">
                    <Warning className="mr-1" size={14} color="#D91029" />{' '}
                    <Text className="text-xs text-error font-medium">
                      Test connection failed. We will reach out to you shortly
                    </Text>
                  </span>
                )}
                {connectionStatus === 'REQUIRED' && (
                  <Text className="text-xs text-muted font-medium">
                    Testing connection required
                  </Text>
                )}
              </div>
            </div>

            <div className="flex space-x-2 mt-1">
              {zendeskWebhookStatus === 'SUCCESS' && (
                <div className="text-success font-medium">
                  2 Webhooks and Triggers have been successfully created. For
                  custom requirements, please reach out to us
                </div>
              )}
              {zendeskWebhookStatus === 'FAILED' && (
                <div className="text-error font-medium flex items-center">
                  <Warning className="mr-1" color="#D91029" />
                  Unable to create Webhooks and Triggers. We will reach out to
                  you shortly.
                </div>
              )}
            </div>
            {/* {Boolean(zendeskConfiguration?.authUrl) && (
            <div className="flex flex-row-reverse">
              <Button onClick={handleReAuthenticate}>Re-Authenticate</Button>
            </div>
          )} */}
            {/* <div className="space-y-4 mt-3"> */}
            <div className="mt-3">
              <Text className="font-medium">Tag</Text>
              <Controller
                name="tag"
                control={control}
                render={({ field }) => (
                  <Input
                    size="middle"
                    className="mt-1"
                    placeholder="Default tag to send to Zendesk from Thena"
                    disabled={disableSave}
                    {...field}
                    required
                  />
                )}
              />
            </div>
            <div className="col-span-2">
              <Text className="font-medium">Request type(s) to send...</Text>
              <div className="mt-1 w-full">
                <Controller
                  name="requestTypes"
                  control={control}
                  render={({ field }) => (
                    <Checkbox.Group {...field} className="flex">
                      {requestType.map((el: any) => (
                        <div className="">
                          <Checkbox
                            key={el.value}
                            value={el.value}
                            disabled={disableSave || el.disabled}
                          >
                            <span className="mr-2 text-sm font-normal">
                              {el.label}
                            </span>
                          </Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  )}
                />
              </div>
            </div>
            <div className="w-full flex justify-end ">
              <Button
                className="text-primary border-primary/40 mt-4 mb-1 font-bold px-8"
                size="middle"
                type="default"
                disabled={disableSave}
                onClick={handleSubmit(onSave)}
                loading={saveRequestTypesMutation?.isLoading}
              >
                Save
              </Button>
            </div>
            {zendeskConfiguration?.additionalFields || [].length ? (
              <AdditionalFieldProvider>
                <Divider className="my-5 py-0" />

                <ZendeskAdditionalFields
                  additionalFields={
                    zendeskConfiguration?.additionalFields || []
                  }
                />
                <Divider className="my-5 py-0" />
              </AdditionalFieldProvider>
            ) : null}
            <ExtendedConnectorConfiguration
              isEdition={isEdition}
              className="mb-8"
            />
          </div>
        </div>
      </Panel>
    </Collapse>
  )
}
