/* eslint-disable max-lines */
import { Checkbox, Input, Popover, Radio, RadioChangeEvent } from 'antd'
import { useLiveQuery } from 'dexie-react-hooks'
import { startCase } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { accountDB } from '../../../DB/AccountDB'
import { useAccountsStore } from '../../../store/accountsStore'
import {
  AdditonalFieldType,
  CRMAdditonalFieldType,
} from '../../../types/accountsTypes'
import { truncate } from '../../../utils/textUtils'
import { RowType } from './Table'

const Wrapper = styled.div``

const ContentWrapper = styled.div`
  max-height: 180px;
  overflow-y: scroll;
`

const List = styled.div`
  margin-top: 12px;
  max-height: 200px;
  overflow-y: scroll;
  .ant-radio {
    top: 4px !important;
  }
`

const Item = styled.label<{ $isChecked: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  align-items: center;
  label {
    cursor: pointer;
  }
  ${({ $isChecked }) => {
    if ($isChecked) {
      return css`
        background-color: var(--color-purple-7);
      `
    }
  }}
  .checkbox {
    margin-right: 8px;
  }
`

const StyledInput = styled.input<{ $width: number }>`
  all: unset !important;
  padding: 0 4px !important;
  border-radius: 4px !important;
  width: ${({ $width }) => $width}px !important;
  :focus {
    outline: 2px solid var(--color-blue-1) !important;
  }
`

export const SingleEditCell = ({
  field,
  value,
  record,
  isMultiEditing,
}: {
  record: RowType
  field: AdditonalFieldType
  value: CRMAdditonalFieldType['values'] | null
  isMultiEditing: boolean
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value?.[0])

  const dispatch = useAccountsStore((state) => state.dispatch)

  const updateStore = (values: CRMAdditonalFieldType['values']) => {
    dispatch({
      type: 'UPDATE_ADDITIONAL_FIELDS',
      payload: {
        _id: record?._id,
        field_name: field.field_name,
        values,
        field_id: field._id,
      },
    })
  }

  const Content = isEditing ? (
    <ContentWrapper>
      <Radio.Group
        value={selectedValue?.value}
        onChange={(e: RadioChangeEvent) => {
          const item = field.available_values.find(
            (item) => item.value === e.target.value
          )
          if (item) {
            setSelectedValue(item)
            updateStore([item])
          }
        }}
      >
        <List>
          {field.available_values.map((info) => {
            const isChecked = selectedValue?.value === info.value
            return (
              <Item key={info.value} $isChecked={isChecked}>
                <Radio value={info.value}>
                  <span>{truncate(info.name, 15)}</span>
                </Radio>
              </Item>
            )
          })}
        </List>
      </Radio.Group>
    </ContentWrapper>
  ) : null

  return (
    <Wrapper onClick={() => setIsEditing(true)}>
      <Popover
        trigger="click"
        content={Content}
        showArrow={false}
        placement="bottomLeft"
      >
        {truncate(selectedValue?.name ?? '', 20) || 'Select a value'}
      </Popover>
    </Wrapper>
  )
}

const CellWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  span {
    flex-shrink: 0;
    margin: 2px;
  }
`

export const MultiEditCell = ({
  field,
  record,
  isMultiEditing,
}: {
  record: RowType
  field: AdditonalFieldType
  isMultiEditing: boolean
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const relationship = useLiveQuery(async () => {
    const relationshipDetail = accountDB?.relationship?.get(record?._id || '')
    return relationshipDetail
  }, [record?._id])

  const [selectedValues, setSelectedValues] = useState<any>([])
  useEffect(() => {
    if (relationship) {
      const detail = relationship?.crm_values?.additional_fields?.find(
        (item) => item.field_name === field.field_name
      )?.values
      setSelectedValues(detail || [])
    }
  }, [relationship, field])

  const dispatch = useAccountsStore((state) => state.dispatch)
  const updateStore = (values: CRMAdditonalFieldType['values']) => {
    dispatch({
      type: 'UPDATE_ADDITIONAL_FIELDS',
      payload: {
        _id: record?._id,
        field_name: field.field_name,
        values,
        field_id: field._id,
      },
    })
  }

  const Content = isEditing ? (
    <ContentWrapper>
      {field.available_values.map((item) => {
        const isChecked = selectedValues?.some(
          (selectedItem: any) => selectedItem.value === item.value
        )
        const onChange = () => {
          if (isChecked) {
            setSelectedValues((prev: any) =>
              prev.filter((i: any) => i.value !== item.value)
            )
            updateStore(
              (selectedValues || []).filter((i: any) => i.value !== item.value)
            )
          } else {
            setSelectedValues((prev: any) => [...prev, item])
            updateStore([...(selectedValues || []), item])
          }
        }

        return (
          <Item $isChecked={!!isChecked}>
            <Checkbox
              id={item.value}
              checked={isChecked}
              onChange={onChange}
              className="checkbox"
            />
            {item.name}
          </Item>
        )
      })}
    </ContentWrapper>
  ) : null
  const formattedSelectedValues = (selectedValues || [])
    ?.map((item: any) => startCase(item.name))
    .join(', ')
  return (
    <Wrapper onClick={() => setIsEditing(true)}>
      <Popover
        trigger="click"
        content={Content}
        showArrow={false}
        placement="bottomLeft"
      >
        {selectedValues?.length ? (
          <CellWrapper>{truncate(formattedSelectedValues, 12)}</CellWrapper>
        ) : (
          'Select values'
        )}
      </Popover>
    </Wrapper>
  )
}

export const TextInputEditCell = ({
  field,
  record,
  isMultiEditing,
}: {
  record: RowType
  field: AdditonalFieldType
  isMultiEditing: boolean
}) => {
  const ref = useRef<any>(null)
  const [isEditing, setIsEditing] = useState(false)
  const relationship = useLiveQuery(async () => {
    const relationshipDetail = accountDB?.relationship?.get(record?._id || '')
    return relationshipDetail
  }, [record?._id])
  const [selectedValues, setSelectedValues] = useState<any>('')
  const handleTextCellClick = () => {
    setIsEditing(true)
  }
  useEffect(() => {
    if (relationship) {
      const detail = relationship?.crm_values?.additional_fields?.find(
        (item) => item.field_name === field.field_name
      )?.values
      setSelectedValues((detail || [])[0]?.name)
    }
  }, [relationship, field])

  useEffect(() => {
    if (isEditing) {
      ref.current?.focus()
    }
  }, [isEditing])

  const dispatch = useAccountsStore((state) => state.dispatch)
  const updateStore = () => {
    dispatch({
      type: 'UPDATE_ADDITIONAL_FIELDS',
      payload: {
        _id: record?._id,
        field_name: field.field_name,
        values: [
          { name: selectedValues, value: selectedValues, _id: selectedValues },
        ],
        field_id: field._id,
      },
    })
  }

  const saveOnBlur = () => {
    updateStore()
    setIsEditing(false)
  }

  if (isEditing || isMultiEditing) {
    return isEditing ? (
      <StyledInput
        ref={ref}
        $width={120}
        value={selectedValues}
        size={selectedValues?.length}
        onBlur={saveOnBlur}
        min={undefined}
        type={'text'}
        onChange={(e) => setSelectedValues(e.target.value)}
      />
    ) : (
      <Input type="text" />
    )
  }

  const cellText = truncate(selectedValues ?? 'Not Set', 20)

  return (
    <div className="p-0 w-full" onClick={handleTextCellClick}>
      {cellText}
    </div>
  )
}
