import { CheckboxOptionType } from 'antd/lib/checkbox/Group'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { AssigneeType, fetchAssignee } from '../../../../api/assignee'
import { fetchClusters } from '../../../../api/clusters'
import ClusterIcon from '../../../../images/icons/cluster.svg'
import SMEIcon from '../../../../images/icons/group_sme.svg'
import StatusIcon from '../../../../images/icons/status.svg'
import MutipleSelect from './MutipleSelect'

export const DefaultFilters = () => {
  const { id = '' } = useParams()
  const { data: assignees } = useQuery('assignee', fetchAssignee)
  const { data: clusters } = useQuery('clusterList', () => fetchClusters(id))
  const clusterItem: CheckboxOptionType[] = clusters
    ? clusters.map((item) => {
        return { value: item._id, label: item.name }
      })
    : []
  const smeItem: CheckboxOptionType[] = assignees
    ? assignees.map((item: AssigneeType) => {
        return { value: item._id, label: item.name }
      })
    : []
  const statusItems: CheckboxOptionType[] = [
    { value: 'true', label: 'Verified' },
    { value: 'false', label: 'Unverified' },
  ]
  return (
    <>
      <MutipleSelect
        itemList={clusterItem}
        queryKey="clusterId"
        label="Cluster"
        iconAlt="cluster"
        iconSrc={ClusterIcon}
        className="qna-filter"
      />
      <MutipleSelect
        itemList={statusItems}
        queryKey="isVerified"
        label="Status"
        iconAlt="status"
        iconSrc={StatusIcon}
        className="qna-filter"
      />
      {/* <MutipleSelect
        itemList={sourceItem}
        queryKey="sourceId"
        label="Source"
        iconAlt="source"
        iconSrc={SourceIcon}
        className="qna-filter"
      /> */}
      <MutipleSelect
        itemList={smeItem}
        queryKey="sme"
        label="Subject Matter Expert"
        iconAlt="sme"
        iconSrc={SMEIcon}
        className="qna-filter"
      />
    </>
  )
}
