import { CloudUploadOutlined } from '@ant-design/icons'
import { Button, Upload, message } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { useState } from 'react'
import styled from 'styled-components'
import {
  SourceInputType,
  SourceOriginType,
  SourceType,
} from '../../../../types'
import { FormButtonGroup } from './components/FormButtonGroup'
import { FormHeader } from './components/FormHeader'
import FormInput from './components/FormInput'

type SourceFormProps = {
  onCancel: () => void
  onSubmit: (data: SourceInputType) => void
  currentData?: SourceType
  isSaving: boolean
}

export const SourceForm = ({
  onCancel,
  onSubmit,
  isSaving,
  currentData,
}: SourceFormProps) => {
  const initialFormData = {
    sourceName: currentData?.sourceName ?? '',
    type: currentData
      ? currentData.sourceType === 'web_link'
        ? SourceOriginType.WEB_LINK
        : SourceOriginType.DOC
      : null,
    link: currentData?.link ?? '',
    files: null,
    fileName: currentData?.fileName ?? null,
  }
  const [formData, setFormData] = useState<SourceInputType>(initialFormData)

  const canSubmit = () => {
    if (currentData) return true
    if (formData.type === SourceOriginType.WEB_LINK) {
      return formData.link !== '' && formData.sourceName !== ''
    }
    if (formData.type === SourceOriginType.DOC && !currentData) {
      return formData.files && formData.sourceName !== ''
    }
    return false
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSelectChange = (value: string) => {
    setFormData({
      ...formData,
      type:
        value === 'web_link' ? SourceOriginType.WEB_LINK : SourceOriginType.DOC,
    })
  }

  const allowedTypes = [
    'application/pdf',
    'text/plain',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]
  const handleUploadFile = (info: UploadChangeParam) => {
    const file = info.fileList.slice(-1)[0]?.originFileObj
    const isFileTypeAllowed = file && allowedTypes.includes(file.type)
    if (!isFileTypeAllowed) {
      return message.error('*.pdf, *.txt, *.docx files are allowed')
    }
    setFormData({
      ...formData,
      files: file,
    })
  }

  const handleSubmit = () => {
    if (!canSubmit()) {
      return message.error('Please fill in all the fields!')
    }
    onSubmit(formData)
  }

  const onClose = () => {
    setFormData(initialFormData)
    onCancel()
  }

  const renderData = () => {
    if (formData.type === 'web_link') {
      return (
        <FormInput
          type="text"
          name="link"
          title="Paste Link"
          placeholder="Paste the source link"
          value={formData.link}
          onChange={onChangeHandler}
          disabled={!!currentData || isSaving}
        />
      )
    }
    return (
      <div>
        <div className="upload-btn-text">Upload File</div>
        <Upload
          name="file"
          showUploadList={!!formData.files}
          className="custom-upload"
          beforeUpload={() => false}
          onChange={handleUploadFile}
          maxCount={1}
          accept={allowedTypes.join(',')}
        >
          <Button
            size="large"
            className="upload-btn"
            icon={<CloudUploadOutlined />}
            disabled={!!currentData || isSaving}
          >
            {currentData ? currentData.fileName : 'File Upload'}
          </Button>
        </Upload>
      </div>
    )
  }

  return (
    <Wrapper>
      <FormHeader
        title={`${currentData ? 'Edit' : 'Add'} Source`}
        desc={
          currentData
            ? 'You can only edit source name'
            : 'Get smarter, personalized customer support solutions'
        }
      />
      <FormInput
        type="text"
        title="Give a name"
        name="sourceName"
        placeholder="Enter a unique name for your source."
        value={formData.sourceName}
        onChange={onChangeHandler}
        disabled={isSaving}
      />
      <FormInput
        type="select"
        title="Type"
        placeholder="Select type of source"
        value={formData.type?.toString()}
        options={[
          { value: 'web_link', label: 'Website URL' },
          { value: 'doc', label: 'File Upload' },
        ]}
        onChange={handleSelectChange}
        disabled={!!currentData || isSaving}
      />
      {formData.type && renderData()}
      <FormButtonGroup
        okText={currentData ? 'Save Changes' : 'Add Source'}
        isSaving={isSaving}
        onOk={handleSubmit}
        onClose={onClose}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .upload-btn-text {
    margin-top: 24px;
    margin-bottom: 8px;
    color: var(--color-black-1);
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .upload-btn {
    width: 100%;
    color: var(--color-purple-4);
    background-color: var(--color-purple-3);
  }
  .upload-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .custom-upload .ant-upload {
    display: block !important;
  }
`
