import axios, { CancelToken } from 'axios'
import moment from 'moment'
import Pusher, { Channel, ChannelAuthorizationCallback } from 'pusher-js'
import { useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import {
  fetchCustomerPortalKanban,
  fetchCustomerPortalKanbanRequest,
} from '../api/customerPortal'
import { fetchKanban, fetchKanbanRequest } from '../api/kanban'
import { API_ROOT, PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from '../config'
import { useAuth } from '../context'
import { useKanbanBoardStore } from '../store/kanbanBoardStore'
import { useKanbanStore2 } from '../store/kanbanStore2'

const authorizer = (channel: Channel, userName: string, userId: string) => {
  return {
    authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
      axios({
        method: 'POST',
        url: `${API_ROOT}/v2/pusher/web-user-auth?user_name=${userName}&user_id=${userId}`,
        data: {
          channel_name: channel.name,
          socket_id: socketId,
        },
        withCredentials: true,
      })
        .then((response) => {
          callback(null, response.data)
        })
        .catch((error) => {
          callback(new Error(`Error authenticating with server: ${error}`), {
            auth: '',
          })
        })
    },
  }
}

// Pusher.logToConsole = true
export const initPusher = (userName: string, userId: string) => {
  const pusher = new Pusher(`${PUSHER_APP_KEY}`, {
    cluster: `${PUSHER_APP_CLUSTER}`,
    authorizer: (channel) => authorizer(channel, userName, userId),
  })
  return pusher
}

const usePusherInit = () => {
  const { user } = useAuth()
  const initRef = useRef(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    let channelName = ''
    let pusher: Pusher | null = null

    const isUserInfoPresent =
      !!user?.team_id && !!user?.id && !!user?.displayName

    if (isUserInfoPresent && !initRef.current) {
      pusher = initPusher(user.displayName, user.id)

      pusher.connection.bind('connected', function () {
        if (!pusher) {
          return
        }
        initRef.current = true
        console.log('Pusher init success')
        channelName = `private-${user.team_id}`
        const channel = pusher?.subscribe(channelName)
        channel.bind('request', async function async(requestPusherData: any) {
          try {
            if (requestPusherData.request_id) {
              const prevItem =
                useKanbanBoardStore.getState().records[
                  requestPusherData.request_id
                ]
              if (prevItem && prevItem.requestId) {
                const requestInfo = await fetchKanbanRequest(
                  prevItem.requestId,
                  prevItem.teamId
                )
                if (requestInfo) {
                  useKanbanBoardStore.dispatch({
                    type: 'UPDATE_RECORD_PUSHER',
                    payload: {
                      _id: requestInfo._id,
                      data: requestInfo,
                    },
                  })
                }
              } else {
                // solution for this hack -> API to get request from mongo Id
                const currentEpoch = moment().subtract(1, 'minute').valueOf()
                const payload = {
                  filters: {
                    startDate: '',
                    endDate: '',
                    fetchAfterEpoch: currentEpoch,
                  },
                }
                const response = await fetchKanban(
                  payload,
                  null as unknown as CancelToken
                )
                const allRecords = response.records || []
                if (allRecords.length) {
                  const recordsObject = allRecords.reduce(
                    (acc: any, item: any) => {
                      return {
                        ...acc,
                        [item._id]: item,
                      }
                    },
                    {}
                  )
                  const isDummyDataPresent =
                    useKanbanStore2.getState().isSampleData
                  if (isDummyDataPresent) {
                    useKanbanBoardStore.dispatch({
                      type: 'PUT_RECORDS',
                      payload: {
                        records: recordsObject,
                      },
                    })
                    useKanbanStore2.dispatch({
                      type: 'INIT',
                      payload: {
                        total: response?.total,
                        isSampleData: response?.isSampleData,
                      },
                    })
                  } else {
                    useKanbanBoardStore.dispatch({
                      type: 'BULK_UPDATE_RECORDS',
                      payload: {
                        records: recordsObject,
                      },
                    })
                  }
                }
              }
            }
          } catch (error) {
            console.log(error)
          }
        })
        channel.bind('ticket', function (ticketPusherData: any) {
          console.log({ ticketPusherData })
          queryClient.invalidateQueries(['kanban-data-on-date-change'])
        })
      })
    } else {
      console.log('Pusher init failed')
    }

    return () => {
      if (pusher && channelName) {
        pusher.unsubscribe(channelName)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.team_id, user?.id, user?.displayName])
}

export const usePusherInitForCustomerPortal = () => {
  const { user } = useAuth()
  const initRef = useRef(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    let channelName = ''
    let pusher: Pusher | null = null

    const isUserInfoPresent =
      !!user?.team_id && !!user?.id && !!user?.displayName

    if (isUserInfoPresent && !initRef.current) {
      pusher = initPusher(user.displayName, user.id)

      pusher.connection.bind('connected', function () {
        if (!pusher) {
          return
        }
        initRef.current = true
        console.log('Pusher init success')
        channelName = `private-${user.team_id}`
        const channel = pusher?.subscribe(channelName)
        channel.bind('request', async function async(requestPusherData: any) {
          try {
            if (requestPusherData.request_id) {
              const prevItem =
                useKanbanBoardStore.getState().records[
                  requestPusherData.request_id
                ]
              if (prevItem && prevItem.requestId) {
                const requestInfo = await fetchCustomerPortalKanbanRequest(
                  prevItem.requestId,
                  prevItem.teamId
                )
                if (requestInfo) {
                  useKanbanBoardStore.dispatch({
                    type: 'UPDATE_RECORD_PUSHER',
                    payload: {
                      _id: requestInfo._id,
                      data: requestInfo,
                    },
                  })
                }
              } else {
                // solution for this hack -> API to get request from mongo Id
                const currentEpoch = moment().subtract(1, 'minute').valueOf()
                const payload = {
                  filters: {
                    startDate: '',
                    endDate: '',
                    fetchAfterEpoch: currentEpoch,
                  },
                }
                const response = await fetchCustomerPortalKanban(
                  payload,
                  null as unknown as CancelToken
                )
                const allRecords = response || []
                if (allRecords.length) {
                  const recordsObject = allRecords.reduce(
                    (acc: any, item: any) => {
                      return {
                        ...acc,
                        [item._id]: item,
                      }
                    },
                    {}
                  )
                  useKanbanBoardStore.dispatch({
                    type: 'BULK_UPDATE_RECORDS',
                    payload: {
                      records: recordsObject,
                    },
                  })
                }
              }
            }
          } catch (error) {
            console.log(error)
          }
        })
        channel.bind('ticket', function (ticketPusherData: any) {
          console.log({ ticketPusherData })
          queryClient.invalidateQueries(['kanban-data-on-date-change'])
        })
      })
    } else {
      console.log('Pusher init failed')
    }

    return () => {
      if (pusher && channelName) {
        pusher.unsubscribe(channelName)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.team_id, user?.id, user?.displayName])
}

export default usePusherInit
