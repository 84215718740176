/* eslint-disable max-lines */
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { create } from 'zustand'
import { devtools, persist, redux } from 'zustand/middleware'
import { KanbanStorePersist } from '../types/kanbanTypes'

export const initialState: KanbanStorePersist = {
  csm: [],
  search: '',
  source: [],
  allTags: [],
  urgency: [],
  customers: [],
  sentiment: [],
  view: 'BOARD',
  assignedTo: [],
  sortBy: 'Newest',
  isEscalated: false,
  solutionEngineers: [],
  cardType: ['Requests'],
  dateRangeType: '30_DAYS',
  configuredFieldValues: {},
  selectedTab: 'customer-requests',
  currentViewName: 'Default View',
  dateRange: [moment().subtract(30, 'd'), moment()],
  accountTypes: [],
  crmFieldValues: {},
  openSections: ['UNASSIGNED', 'IN_PROGRESS', 'ON_HOLD', 'CLOSED'],
  showAiTitle: false,
  fetchAfterEpoch: null,
  workspaces: [],
  allViewsList: {
    External: {
      DefaultView: {
        key: 'defaultView',
        name: 'Default View',
        url: '/requests?tab=customer-requests&cardType=["Requests"]',
        hiddenCols: [],
      },
      Tickets: {
        key: 'tickets',
        name: 'Default View',
        url: '/requests?tab=customer-requests&cardType=["Tickets"]',
        hiddenCols: [],
      },
      SavedViews: [],
    },
    Internal: {
      DefaultView: {
        key: 'defaultView',
        name: 'Default View',
        url: '/requests?tab=internal-helpdesk&cardType=["Requests"]',
        hiddenCols: [],
      },
      Tickets: {
        key: 'tickets',
        name: 'Default View',
        url: '/requests?tab=internal-helpdesk&cardType=["Tickets"]',
        hiddenCols: [],
      },
      SavedViews: [],
    },
  },
  orderedColumns: [],
}

export type ActionType = {
  type:
    | 'RESET'
    | 'SET_CSM'
    | 'SET_VIEW'
    | 'SET_SEARCH'
    | 'SET_SORT_BY'
    | 'SET_URGENCY'
    | 'SET_ALL_TAGS'
    | 'SET_SENTIMENT'
    | 'SET_CARD_TYPE'
    | 'SET_CUSTOMERS'
    | 'SET_DATE_RANGE'
    | 'SET_ASSIGNED_TO'
    | 'SET_IS_ESCALATED'
    | 'SET_SELECTED_TAB'
    | 'SET_DATE_RANGE_TYPE'
    | 'SET_CONFIGURED_FIELD'
    | 'SET_SOLUTION_ENGINEERS'
    | 'SET_CONFIGURED_FIELD_BULK_UPDATE'
    | 'SET_ACCOUNT_TYPES'
    | 'SET_CRM_FIELD'
    | 'SET_OPEN_SECTIONS'
    | 'SET_SOURCE'
    | 'SET_SHOW_AI_TITLE'
    | 'SET_FETCH_AFTER_EPOCH'
    | 'SET_WORKSPACES'
    | 'SET_ALL_VIEWS_INIT'
    | 'UPDATE_CURRENT_VIEW'
    | 'SET_CURRENT_VIEW_NAME'
    | 'SET_COLUMNS_LIST'
    | 'DELETE_CURRENT_VIEW'
    | 'ADD_NEW_VIEW'

  payload?: any
}

const reducer = (
  state: KanbanStorePersist,
  action: ActionType
): KanbanStorePersist => {
  const { type, payload } = action

  switch (type) {
    case 'SET_SELECTED_TAB':
      return {
        ...state,
        selectedTab: payload.selectedTab,
      }
    case 'SET_CURRENT_VIEW_NAME':
      return {
        ...state,
        currentViewName: payload.currentViewName,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: payload.search,
      }

    case 'SET_ASSIGNED_TO':
      return {
        ...state,
        assignedTo: payload.assignedTo,
      }

    case 'SET_CUSTOMERS':
      return {
        ...state,
        customers: payload.customers,
      }

    case 'SET_IS_ESCALATED':
      return {
        ...state,
        isEscalated: payload.isEscalated,
      }

    case 'SET_SORT_BY':
      return {
        ...state,
        sortBy: payload.sortBy,
      }

    case 'SET_DATE_RANGE':
      return {
        ...state,
        dateRange: payload.dateRange,
      }

    case 'SET_DATE_RANGE_TYPE':
      return {
        ...state,
        dateRangeType: payload.dateRangeType,
      }

    case 'SET_CARD_TYPE':
      return {
        ...state,
        cardType: payload.cardType,
      }

    case 'SET_CSM':
      return {
        ...state,
        csm: payload.csm,
      }

    case 'SET_SOLUTION_ENGINEERS':
      return {
        ...state,
        solutionEngineers: payload.solutionEngineers,
      }

    case 'SET_URGENCY':
      return {
        ...state,
        urgency: payload.urgency,
      }

    case 'SET_SENTIMENT':
      return {
        ...state,
        sentiment: payload.sentiment,
      }

    case 'SET_ALL_TAGS':
      return {
        ...state,
        allTags: payload.allTags,
      }

    case 'SET_CONFIGURED_FIELD':
      return {
        ...state,
        configuredFieldValues: {
          ...state.configuredFieldValues,
          [payload.fieldName]: payload.fieldValue,
        },
      }

    case 'SET_CONFIGURED_FIELD_BULK_UPDATE':
      return {
        ...state,
        configuredFieldValues: payload.configuredFieldValues,
      }

    case 'SET_ACCOUNT_TYPES':
      return { ...state, accountTypes: payload.accountTypes }

    case 'RESET':
      return {
        ...cloneDeep(initialState),
        selectedTab: state.selectedTab,
        currentViewName: state.currentViewName,
        allViewsList: state.allViewsList,
      }

    case 'SET_VIEW':
      return {
        ...state,
        view: payload.view,
      }
    case 'SET_CRM_FIELD':
      return {
        ...state,
        crmFieldValues: {
          ...state.crmFieldValues,
          [payload.fieldName]: payload.fieldValue,
        },
      }

    case 'SET_OPEN_SECTIONS':
      return { ...state, openSections: payload.openSections }

    case 'SET_SOURCE':
      return { ...state, source: payload.source }

    case 'SET_SHOW_AI_TITLE':
      return { ...state, showAiTitle: payload.showAiTitle }

    case 'SET_FETCH_AFTER_EPOCH':
      return {
        ...state,
        fetchAfterEpoch: payload.fetchAfterEpoch,
      }
    case 'SET_ALL_VIEWS_INIT': {
      const updatedState = { ...state }
      payload.view?.forEach((item: any) => {
        let viewList: any
        if (item.type === 'REQUESTS') {
          viewList = updatedState.allViewsList.External.SavedViews
        } else if (item.type === 'INTERNAL_REQUESTS') {
          viewList = updatedState.allViewsList.Internal.SavedViews
        } else {
          viewList = []
        }
        const existingIndex = viewList.findIndex(
          (view: any) => view.key === item._id
        )
        if (existingIndex !== -1) {
          viewList[existingIndex] = {
            key: item._id,
            name: item.name,
            url: item.filter,
            activeCols: item.additionalPrefs.active_columns || [],
            scope: item.scope,
            orderedCols: item.additionalPrefs.ordered_columns || [],
          }
        } else {
          // Add new entry
          viewList.push({
            key: item._id,
            name: item.name,
            url: item.filter,
            activeCols: item.additionalPrefs.active_columns || [],
            scope: item.scope,
            orderedCols: item.additionalPrefs.ordered_columns || [],
          })
        }
      })

      return updatedState
    }

    case 'UPDATE_CURRENT_VIEW': {
      const { section, viewKey, fields } = payload as {
        section: 'External' | 'Internal'
        viewKey: any
        fields: any
      }
      const newState = {
        ...state,
        allViewsList: {
          ...state.allViewsList,
          [section]: {
            ...state.allViewsList[section],
            SavedViews: state.allViewsList[section]?.SavedViews.map(
              (view: any) => {
                if (view.key === viewKey) {
                  return { ...view, ...fields }
                }
                return view
              }
            ),
          },
        },
      }
      return newState
    }

    case 'DELETE_CURRENT_VIEW': {
      const { section, viewKey } = payload as {
        section: 'External' | 'Internal'
        viewKey: any
      }

      const newState = {
        ...state,
        allViewsList: {
          ...state.allViewsList,
          [section]: {
            ...state.allViewsList[section],
            SavedViews: state.allViewsList[section]?.SavedViews.filter(
              (view: any) => view.key !== viewKey
            ),
          },
        },
      }
      return newState
    }

    case 'SET_COLUMNS_LIST':
      return {
        ...state,
        orderedColumns: payload.orderedColumns,
      }

    case 'SET_WORKSPACES':
      return {
        ...state,
        workspaces: payload.workspaces,
      }

    case 'ADD_NEW_VIEW': {
      const { section, view } = payload as {
        section: 'External' | 'Internal'
        view: any
      }
      const newState = {
        ...state,
        allViewsList: {
          ...state.allViewsList,
          [section]: {
            ...state.allViewsList[section],
            SavedViews: [
              ...(state.allViewsList[section]?.SavedViews ?? []),
              view,
            ],
          },
        },
      }
      return newState
    }
    default:
      return state
  }
}

const useKanbanStorePersist = create(
  persist(
    devtools(redux(reducer, initialState), {
      name: 'useKanbanStorePersist',
    }),
    {
      name: 'useKanbanStorePersist',
      version: 1.3,
    }
  )
)

export { useKanbanStorePersist }
