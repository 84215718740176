import React, { createContext, useContext, useState } from 'react'
import { StepWizard } from '../../modules/stepWizard'

type Props = {}

const StepWizardContext = createContext<{
  currentStep: number
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
}>({
  currentStep: 0,
  setCurrentStep: () => null,
})

export const Configuration = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(0)

  return (
    <StepWizardContext.Provider
      value={{
        currentStep,
        setCurrentStep,
      }}
    >
      <div className="flex flex-col justify-between h-[100%]">
        <StepWizard />
      </div>
    </StepWizardContext.Provider>
  )
}

export const useStepWizard = () => {
  return useContext(StepWizardContext)
}
