import { ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const ScrollWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  position: relative;
`

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

const ObserverChip = styled.div`
  width: 1px;
  height: 1px;
  flex-shrink: 0;
`

const Shade = styled.div`
  top: 0;
  width: 32px;
  z-index: 10;
  height: 34px;
  position: absolute;
  pointer-events: none;
`

const ShadeLeft = styled(Shade)`
  left: 0;
  background: linear-gradient(to left, transparent, #dcdcdc);
`

const ShadeRight = styled(Shade)`
  right: 0;
  background: linear-gradient(to right, transparent, #dcdcdc);
`

type IProps = {
  listItems: ReactNode
}

const HorizontalList = ({ listItems }: IProps) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const leftRef = useRef<HTMLDivElement | null>(null)
  const rightRef = useRef<HTMLDivElement | null>(null)

  const [showLeftShade, setShowLeftShade] = useState(false)
  const [showRightShade, setShowRightShade] = useState(false)

  useEffect(() => {
    let leftObserver: IntersectionObserver | null = null
    let rightObserver: IntersectionObserver | null = null

    if (scrollContainerRef.current && leftRef.current) {
      leftObserver = new IntersectionObserver(
        (entries) => {
          setShowLeftShade(!entries[0].isIntersecting)
        },
        {
          root: scrollContainerRef.current,
          threshold: 0.5,
        }
      )
      leftObserver.observe(leftRef.current)
    }

    if (scrollContainerRef.current && rightRef.current) {
      rightObserver = new IntersectionObserver(
        (entries) => {
          setShowRightShade(!entries[0].isIntersecting)
        },
        {
          root: scrollContainerRef.current,
          threshold: 0.5,
        }
      )
      rightObserver.observe(rightRef.current)
    }

    const leftEle = leftRef.current
    const rightEle = rightRef.current

    return () => {
      if (leftObserver && leftEle) {
        leftObserver.unobserve(leftEle)
      }
      if (rightObserver && rightEle) {
        rightObserver.unobserve(rightEle)
      }
    }
  }, [])

  return (
    <ScrollWrapper className="scroll-wrapper">
      {showLeftShade && <ShadeLeft className="list-shade list-shade-left" />}
      <Container ref={scrollContainerRef} className="scroll-container">
        <ObserverChip ref={leftRef} />
        {listItems}
        <ObserverChip ref={rightRef} />
      </Container>
      {showRightShade && <ShadeRight className="list-shade list-shade-right" />}
    </ScrollWrapper>
  )
}

export default HorizontalList
