import { message } from 'antd'
import React, { useContext } from 'react'
import { useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { saveSlackRedirection } from '../../../api/integrations'
import { SlackRedirectionPayloadType } from '../definitions'

interface SlackContextType {
  saveRedirectionMutation?: UseMutationResult<
    any,
    unknown,
    SlackRedirectionPayloadType,
    unknown
  >
}

const SlackContext = React.createContext<SlackContextType>({})

export const SlackProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient()

  const saveRedirectionMutation = useMutation(saveSlackRedirection, {
    onSuccess: () => {
      message.success('Slack redirection saved..!')
      queryClient.invalidateQueries(['fetch-slack-redirection'])
    },
    onError: () => {
      message.error('Something went wrong!')
    },
  })

  return (
    <SlackContext.Provider
      value={{
        saveRedirectionMutation,
      }}
    >
      {children}
    </SlackContext.Provider>
  )
}

export const useSlack = () => {
  return useContext(SlackContext)
}
