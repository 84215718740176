import FastSpinner from '../components/FastSpinner'

type Props = {
  url: string
}

const RedirectToExternal = ({ url }: Props) => {
  // useEffect(() => {
  //   window.location.href = url
  // }, [url])

  return (
    <div className="w-full h-full absolute z-20 flex justify-center items-center">
      <FastSpinner />
    </div>
  )
}

export default RedirectToExternal
