import MDEditor from '@uiw/react-md-editor'
import { Avatar, Row, message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import CopyToClipboard from '../../../../images/icons/CopyToClipboard.svg'
import ThumbsDown from '../../../../images/icons/ThumbsDown.svg'
import ThumbsUp from '../../../../images/icons/ThumbsUp.svg'
import Logo from '../../../../images/logos/thena_logo.svg'

import {
  ConversationType,
  FeedbackType,
} from '../../../../types/playgroundTypes'
import { CustomIconBtn } from '../StyledComponent'

type ChatDetailProps = {
  chatHistory: ConversationType[]
  isNewResponse: boolean
  setNewResponse: (data: boolean) => void
  setContext: (value: string) => void
  addFeedback: (qnaId: string, feedback: FeedbackType) => void
  userAvatarUrl: string
}

export const ChatDetail = ({
  chatHistory,
  isNewResponse,
  setNewResponse,
  setContext,
  addFeedback,
  userAvatarUrl,
}: ChatDetailProps) => {
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null)
  const copyToClipboard = async (data: string) => {
    try {
      await navigator.clipboard.writeText(data)
      message.success('Copied to clipboard!')
    } catch (err) {
      message.error('Failed to copy text!')
    }
  }

  const ProgressiveDisplayMessage = ({ message }: { message: string }) => {
    const [displayedMessage, setDisplayedMessage] = useState('')
    const [currentWordIndex, setCurrentWordIndex] = useState(0)
    const words = message.split(' ')

    useEffect(() => {
      if (currentWordIndex < words.length) {
        const timer = setTimeout(() => {
          setDisplayedMessage((prev) => `${prev}${words[currentWordIndex]} `)
          setCurrentWordIndex(currentWordIndex + 1)
        }, 100)
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
        return () => clearTimeout(timer)
      } else {
        setNewResponse(false)
        setContext('')
      }
    }, [currentWordIndex, words])
    return (
      <MDEditor.Markdown
        source={displayedMessage}
        className="markdown-preview"
      />
    )
  }

  const addFeedbackHandler = (qnaId: string, type: FeedbackType) => {
    addFeedback(qnaId, type)
  }

  return (
    <Wrapper>
      {chatHistory.map(
        (data, index) =>
          data.answer && (
            <div key={index}>
              <div style={{ marginBottom: '24px' }} data-color-mode="light">
                <Row style={{ gap: 12 }} wrap={false}>
                  <Avatar
                    src={userAvatarUrl}
                    size={20}
                    style={{ marginTop: -1 }}
                  />
                  <MDEditor.Markdown
                    source={data.question}
                    className="question-container"
                  />
                </Row>
              </div>
              <Row style={{ gap: 12 }} wrap={false}>
                <img src={Logo} alt="assistant" style={{ height: 20 }} />
                <div data-color-mode="light" className="answer-container">
                  <div className="answer-content">
                    {index === chatHistory.length - 1 && isNewResponse ? (
                      <ProgressiveDisplayMessage message={data.answer} />
                    ) : (
                      <MDEditor.Markdown
                        source={data.answer}
                        className="markdown-preview"
                      />
                    )}
                  </div>
                  <div style={{ visibility: 'hidden' }}>
                    <CustomIconBtn
                      type="text"
                      size="small"
                      icon={<img src={CopyToClipboard} alt="CopyToClipboard" />}
                      onClick={() => copyToClipboard(data.answer)}
                    />
                    <CustomIconBtn
                      type="text"
                      size="small"
                      icon={<img src={ThumbsUp} alt="ThumbsUp" />}
                      onClick={() =>
                        addFeedbackHandler(data.qnaId, FeedbackType.THUMB_UP)
                      }
                    />
                    <CustomIconBtn
                      type="text"
                      size="small"
                      icon={<img src={ThumbsDown} alt="ThumbsDown" />}
                      onClick={() =>
                        addFeedbackHandler(data.qnaId, FeedbackType.THUMB_DOWN)
                      }
                    />
                  </div>
                </div>
              </Row>
            </div>
          )
      )}
      <span ref={endOfMessagesRef} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .question-container {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
  .answer-content {
    padding: 8px 16px;
    border-radius: 8px;
    background-color: var(--color-gray-bg);
  }
  .markdown-preview {
    font-family: Lato;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.14px;
    background-color: var(--color-gray-bg);
  }
  .answer-container {
    margin-bottom: 24px;
    &:hover ${CustomIconBtn} {
      visibility: visible;
    }
  }
`
