import { Button, Col, Row } from 'antd'
import styled from 'styled-components'
import { ReactComponent as SparkleSVG } from '../../../../images/icons/sparkle.svg'
import { CreateConversationType } from '../../../../types/playgroundTypes'

type NewContentProps = {
  currChatId: string
  createNewChat: (data: CreateConversationType) => void
  setContext: (value: string) => void
}

export const NewContent = ({
  currChatId,
  createNewChat,
  setContext,
}: NewContentProps) => {
  const startConversation = (data: string) => {
    if (currChatId === '') {
      createNewChat({ name: data, isThreaded: true })
      setContext(data)
    }
  }

  return (
    <Wrapper>
      <Row align="middle" justify="center" style={{ marginBottom: 8 }}>
        <SparkleSVG
          fill="none"
          className="w-[32px] h-[32px]"
          stroke={'var(--color-purple-1)'}
        />
        <span className="description-span">Knowledge base playground</span>
      </Row>
      <Row align="middle" justify="center" style={{ marginBottom: 24 }}>
        <span className="description-note">
          Start interacting with your assistant. Ask questions,
          <br />
          give commands, or just have a friendly chat.
        </span>
      </Row>
      <Row align="middle" justify="space-between" className="button-row">
        <Col span={8} style={{ paddingRight: 12 }}>
          <Button
            className="text-button"
            onClick={() => startConversation('Hello my assistant!')}
          >
            Hello my assistant!
          </Button>
        </Col>
        <Col span={8} style={{ paddingRight: 6, paddingLeft: 6 }}>
          <Button
            className="text-button"
            onClick={() => startConversation('What services can you provide?')}
          >
            What services can you provide?
          </Button>
        </Col>
        <Col span={8} style={{ paddingLeft: 12 }}>
          <Button
            className="text-button"
            onClick={() =>
              startConversation('Tell me more about KnowledgeBase.')
            }
          >
            Tell me more about KnowledgeBase
          </Button>
        </Col>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 20vh;
  .description-span {
    margin-left: 12px;
    font-size: 20px;
    font-family: Lato;
    font-weight: 600;
    line-height: 28px;
    color: var(--color-black-1);
  }
  .description-note {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    font-family: Lato;
    line-height: 24px;
    color: var(--color-gray-6);
  }
  .button-row {
    width: 100%;
  }
  .text-button {
    font-size: 15px;
    font-family: Lato;
    line-height: 28px;
    border-radius: 8px;
    height: 48px;
    width: 100%;
    color: var(--color-gray-6);
    background-color: var(--color-gray-bg);

    &:hover {
      border-color: var(--color-purple-1);
    }
  }
  .ant-btn {
    padding: 6px 12px;
  }
`
