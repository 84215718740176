import { Divider, Tooltip, Typography } from 'antd'
import { useLiveQuery } from 'dexie-react-hooks'
import { camelCase } from 'lodash'
import { CaretDown, CaretUp } from 'phosphor-react'
import { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { accountDB } from '../../../DB/AccountDB'
import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut'
import { useAccountsStore } from '../../../store/accountsStore'
import { emptyList, emptyObject } from '../../../utils/empty'
import useApplyFilter from '../Table/useApplyFilter'
const { Title } = Typography
const CopyButton = styled.button<{ $noBorder?: boolean }>`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-gray-3);
  border-radius: 8px;
  background-color: var(--color-white);
  cursor: pointer;
  :hover {
    border-color: var(--color-purple-6);
    path {
      stroke: var(--color-purple-6);
    }
  }
  :disabled {
    :hover {
      border-color: var(--color-gray-3);
    }
  }
  flex-shrink: 0;
  ${({ $noBorder }) => {
    if ($noBorder) {
      return css`
        border: none;
        background-color: var(--color-gray-9);
        :hover {
          background-color: var(--color-white);
        }
      `
    }
  }}
`
const Header = () => {
  const dispatch = useAccountsStore((state) => state.dispatch)
  const drawer = useAccountsStore((state) => state.drawer)
  const relationships = useLiveQuery(async () => {
    const data = await accountDB.relationship.toArray()
    return data ?? []
  }, [])
  const crmConfig = useAccountsStore((state) => state.crmConfig)
  const tableData: any = useMemo(() => {
    return (
      relationships?.map((rel: any) => {
        const { _id, users, channel, crm_values, customer_name } = rel
        const {
          name,
          type,
          contract_term,
          contract_value,
          synced_with_crm,
          additional_fields = emptyList,
          contract_end_date,
          contract_start_date,
          internal_slack_channel_name,
        } = crm_values || emptyObject

        const rowInfo = {
          _id,
          users,
          name: name || customer_name,
          accountType: type,
          externalSlackChannel: channel.name,
          contractTerm: contract_term,
          contractValue: contract_value,
          syncedWithCRM: synced_with_crm,
          contractEndDate: contract_end_date,
          contractStartDate: contract_start_date,
          internalSlackChannel: internal_slack_channel_name,
          accountOwner: users.find((user: any) => user.role === 'csm') || null,
        }

        crmConfig?.additional_fields?.forEach((field) => {
          const values = additional_fields.find(
            ({ field_name }: { field_name: any }) =>
              field_name === field.field_name
          )?.values
          // @ts-ignore

          rowInfo[camelCase(field.field_name)] = values
        })
        return rowInfo
      }) ?? []
    )
  }, [relationships, crmConfig])

  const filteredTableData = useApplyFilter(tableData)

  const conv = useLiveQuery(async () => {
    const data = await accountDB.relationship.get(
      drawer?.data?.record?._id ?? ''
    )
    return data
  }, [drawer?.data?.record?._id])

  const currentIndex = filteredTableData.findIndex(
    (item) => item._id === drawer?.data?.record?._id
  )

  const [disablePrev, setDisablePrev] = useState(false)
  const [disableNext, setDisableNext] = useState(false)

  useEffect(() => {
    currentIndex === 0 ? setDisablePrev(true) : setDisablePrev(false)
    currentIndex === (filteredTableData?.length || 0) - 1
      ? setDisableNext(true)
      : setDisableNext(false)
  }, [currentIndex, filteredTableData])

  const handlePrev = () => {
    const prevIndex = currentIndex !== -1 ? currentIndex - 1 : 0
    prevIndex === 0 || currentIndex === 0
      ? setDisablePrev(true)
      : setDisablePrev(false)
    const prevCard = filteredTableData[prevIndex]

    if (prevCard) {
      dispatch({
        type: 'SET_DRAWER',
        payload: {
          drawer: {
            type: 'ACCOUNT_DRAWER',
            data: {
              open: true,
              record: prevCard,
            },
          },
        },
      })
    }
  }

  const handleNext = () => {
    const nextIndex = currentIndex !== -1 ? currentIndex + 1 : 0
    nextIndex === filteredTableData?.length - 1 ||
    currentIndex === filteredTableData?.length - 1
      ? setDisableNext(true)
      : setDisableNext(false)
    const nextCard = filteredTableData[nextIndex]
    if (nextCard) {
      dispatch({
        type: 'SET_DRAWER',
        payload: {
          drawer: {
            type: 'ACCOUNT_DRAWER',
            data: {
              open: true,
              record: nextCard,
            },
          },
        },
      })
    }
  }

  useKeyboardShortcut(['k'], (event) => {
    event.preventDefault()
    handlePrev()
  })

  useKeyboardShortcut(['j'], (event) => {
    event.preventDefault()
    handleNext()
  })

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-1 ml-2">
        <Title style={{ marginBottom: 0, marginLeft: '0.5rem' }} level={5}>
          {conv?.customer_name ?? conv?.crm_values?.name}
        </Title>
        <Divider
          style={{
            height: '20px',
            fontWeight: 700,
            color: 'var(--color-gray-7)',
          }}
          type="vertical"
        />
        <span className="text-gray-dark text-sm 3xl:text-[length:var(--sm)] light-text">
          #{conv?.channel?.name}
        </span>
      </div>
      <div className="flex justify-end items-center gap-2">
        <Tooltip title="Previous - K">
          <CopyButton
            disabled={disablePrev}
            onClick={disablePrev ? undefined : handlePrev}
          >
            <CaretUp
              className={`${
                disablePrev
                  ? 'cursor-not-allowed text-gray-300'
                  : 'cursor-pointer'
              }`}
            />
          </CopyButton>
        </Tooltip>
        <Tooltip title="Next - J">
          <CopyButton
            disabled={disableNext}
            onClick={disableNext ? undefined : handleNext}
          >
            <CaretDown
              className={`${
                disableNext
                  ? 'cursor-not-allowed text-gray-300'
                  : 'cursor-pointer'
              }`}
            />
          </CopyButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default Header
