import { MenuProps } from 'antd'
import moment, { Moment } from 'moment'
import { ReactComponent as ClosedBoardIcon } from '../../images/icons/closedBoardIcon.svg'
import HighPriorityIcon from '../../images/icons/high-priority.svg'
import ClosedIcon from '../../images/icons/ic_status_closed.svg'
import InProgressIcon from '../../images/icons/ic_status_in_progress.svg'
import OnHoldIcon from '../../images/icons/ic_status_on_hold.svg'
import OpenIcon from '../../images/icons/ic_status_open.svg'
import { ReactComponent as InProgressBoardIcon } from '../../images/icons/inProgressBoardIcon.svg'
import LowPriorityIcon from '../../images/icons/low-priority.svg'
import MedPriorityIcon from '../../images/icons/med-priority.svg'
import NegativeSentimentIcon from '../../images/icons/negative-smiley.svg'
import NeutralSentimentIcon from '../../images/icons/neutral-smiley.svg'
import { ReactComponent as OnholdBoardIcon } from '../../images/icons/onholdBoardIcon.svg'
import { ReactComponent as OpenBoardIcon } from '../../images/icons/openBoardIcon.svg'
import PositiveSentimentIcon from '../../images/icons/positive-smiley.svg'
import { RangeValue } from '../../types/accountsTypes'

export const SORT_BY_VALUES = [
  {
    label: 'Newest',
    value: 'NEWEST',
    tooltip: 'Displays latest customer requests first',
  },
  {
    label: 'Oldest',
    value: 'OLDEST',
    tooltip: 'Displays oldest requests first',
  },
  {
    label: 'Any Reply',
    value: 'ANY_REPLY',
    tooltip: 'Displays requests with the most recent reply first',
  },
  {
    label: 'Customer Reply',
    value: 'CUSTOMER_REPLY',
    tooltip: 'Displays requests with the latest customer replies first',
  },
]

export const MODAL_TYPES = {
  DOWNLOAD_FILTERED_REQUESTS_IN_VIEW: 'DOWNLOAD_FILTERED_REQUESTS_IN_VIEW',
  DOWNLOAD_ALL_REQUESTS_IN_TIMEFRAME: 'DOWNLOAD_ALL_REQUESTS_IN_TIMEFRAME',
  SHOW_MANDATORY_TAGS: 'SHOW_MANDATORY_TAGS',
  KANBAN_DRAWER: 'KANBAN_DRAWER',
  NEW_REQUEST_CREATION: 'NEW_REQUEST_CREATION',
  NEW_VIEW: 'NEW_VIEW',
  MANAGE_STATUSES: 'MANAGE_STATUSES',
  CREATE_MANAGE_STATUS: 'CREATE_MANAGE_STATUS',
  SETTING_MANAGE_STATUS: 'SETTING_MANAGE_STATUS',
  SUCCESS_MANAGE_STATUS: 'SUCCESS_MANAGE_STATUS',
  ADD_CUSTOM_STATUS: 'ADD_CUSTOM_STATUS',
  CUSTOM_ACTION_CONFIRMATION: 'CUSTOM_ACTION_CONFIRMATION',
  DELETE_VIEW: 'DELETE_VIEW',
}

export const DOWNLOAD_MODAL_OPTIONS = [
  { value: 'json', label: 'JSON' },
  { value: 'xlsx', label: 'XLSX' },
]

export const DOWNLOAD_MENU_ITEMS: MenuProps['items'] = [
  {
    label: 'Filtered Requests in View',
    key: 'filtered_requests_in_view',
  },
  {
    label: 'All Requests in Time Frame',
    key: 'all_requests_in_time_frame',
  },
]

export const DEFAULT_FILTERS = [
  'Date',
  'Type',
  'Assignee',
  'Account Owner',
  'Customer',
  'Source',
  'Workspaces',
]

export const ADD_FILTERS = [
  'Sentiment',
  'Urgency',
  'AI Tags',
  'Solutions Engineer',
]

export const DATE_RANGES = {
  'Last 7 days': [moment().subtract(6, 'd'), moment()],
  'Last 14 days': [moment().subtract(13, 'd'), moment()],
  'Last 30 days': [moment().subtract(29, 'd'), moment()],
  'All time': null as unknown as [Moment, Moment],
} as Record<string, Exclude<RangeValue<any>, null>>

export const URGENCY_OPTIONS = ['Low', 'Medium', 'High']

export const SENTIMENT_OPTIONS = ['Positive', 'Neutral', 'Negative']

export const UNIX_TIME_DIFF = 120

export const URGENCY_TYPES = [
  {
    name: 'Low',
    icon: LowPriorityIcon,
  },
  {
    name: 'Medium',
    icon: MedPriorityIcon,
  },
  {
    name: 'High',
    icon: HighPriorityIcon,
  },
]

export const STATUS_TYPES = [
  {
    name: 'OPEN',
    icon: OpenIcon,
  },
  {
    name: 'IN_PROGRESS',
    icon: InProgressIcon,
  },
  {
    name: 'ON_HOLD',
    icon: OnHoldIcon,
  },
  {
    name: 'CLOSED',
    icon: ClosedIcon,
  },
]

export const STATUS_TYPES_BULK_UPDATE = [
  {
    name: 'IN_PROGRESS',
    label: 'In Progress',
    icon: InProgressIcon,
    subStatus: '',
  },
  {
    name: 'ON_HOLD',
    label: 'On Hold',
    icon: OnHoldIcon,
    subStatus: '',
  },
  {
    name: 'CLOSED',
    label: 'Closed',
    icon: ClosedIcon,
    subStatus: '',
  },
]

export const STATUS_TYPES_BULK_UPDATE_TICKET = [
  {
    name: 'IN_PROGRESS',
    label: 'In Progress',
    icon: InProgressIcon,
    subStatus: '',
  },
  {
    name: 'CLOSED',
    label: 'Closed',
    icon: ClosedIcon,
    subStatus: '',
  },
]

export const TICKET_STATUS_TYPES = [
  {
    name: 'RE_OPEN',
    icon: OpenIcon,
  },
  {
    name: 'IN_PROGRESS',
    icon: InProgressIcon,
  },
  {
    name: 'CLOSED',
    icon: ClosedIcon,
  },
]

export const SENTIMENT_FILTER_TYPES = [
  {
    name: 'Positive',
    icon: PositiveSentimentIcon,
  },
  {
    name: 'Neutral',
    icon: NeutralSentimentIcon,
  },
  {
    name: 'Negative',
    icon: NegativeSentimentIcon,
  },
]

export const SECTION_LIST = [
  {
    name: 'UNASSIGNED',
    sectionKey: 'UNASSIGNED',
    backgroundColor: '#EFF3FF',
    Icon: OpenBoardIcon,
  },
  {
    name: 'IN PROGRESS',
    sectionKey: 'IN_PROGRESS',
    backgroundColor: '#FFF6EA',
    Icon: InProgressBoardIcon,
  },
  {
    name: 'ON HOLD',
    sectionKey: 'ON_HOLD',
    backgroundColor: '#F4F4F8',
    Icon: OnholdBoardIcon,
  },
  {
    name: 'CLOSED',
    sectionKey: 'CLOSED',
    backgroundColor: '#EBF7F3',
    Icon: ClosedBoardIcon,
  },
]

export const SECTION_DETAIL = [
  {
    name: 'UNASSIGNED',
    sectionKey: 'UNASSIGNED',
    parent: 'OPEN',
    backgroundColor: '#EFF3FF',
    Icon: OpenBoardIcon,
  },
  {
    name: 'IN PROGRESS',
    parent: 'INPROGRESS',
    sectionKey: 'IN_PROGRESS',
    backgroundColor: '#FFF6EA',
    Icon: InProgressBoardIcon,
  },
  {
    name: 'ON HOLD',
    parent: 'ONHOLD',
    sectionKey: 'ON_HOLD',
    backgroundColor: '#F4F4F8',
    Icon: OnholdBoardIcon,
  },
  {
    name: 'CLOSED',
    sectionKey: 'CLOSED',
    parent: 'CLOSED',
    backgroundColor: '#EBF7F3',
    Icon: ClosedBoardIcon,
  },
]

export const CUSTOM_STATUS_TYPES_BULK_UPDATE = [
  {
    name: 'INPROGRESS',
    icon: InProgressIcon,
  },
  {
    name: 'ONHOLD',
    icon: OnHoldIcon,
  },
  {
    name: 'CLOSED',
    icon: ClosedIcon,
  },
]
