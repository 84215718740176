import emojione from 'emojione'
import { FocusEvent } from 'react'
import styled from 'styled-components'
import { Editable } from '../Commons'
import { formatSlackMarkdown } from '../utils'

const Wrapper = styled.div`
  p {
    font-size: 18px;
    font-weight: 900;
    margin: 1em 0 4px;
    line-height: 1.33334;
  }
`

type IBlock = {
  text?: string | null
}

type IProps = {
  index: number
  block?: IBlock
  toggleModal: () => void
  onBlur: (e: FocusEvent<HTMLDivElement, Element>) => void
  onFocus: (e: FocusEvent<HTMLDivElement, Element>) => void
  isReadOnly?: boolean
}

const BlockHeader = ({
  block,
  index,
  onBlur,
  onFocus,
  toggleModal,
  isReadOnly,
}: IProps) => {
  const htmlString = formatSlackMarkdown(
    typeof block?.text === 'string'
      ? emojione.shortnameToUnicode(block.text)
      : ''
  )

  return (
    <Wrapper>
      <Editable
        key={index}
        index={index}
        formatDisabled
        onBlur={onBlur}
        onFocus={onFocus}
        htmlString={htmlString}
        className=""
        toggleModal={toggleModal}
        type="header"
        isReadOnly={isReadOnly}
      />
    </Wrapper>
  )
}

export default BlockHeader
