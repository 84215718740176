import { create } from 'zustand'
import { devtools, persist, redux } from 'zustand/middleware'
import {
  accountsInitialState,
  accountsReducer,
} from '../reducer/accountsReducer/accountsReducer'

const useAccountsStore = create(
  persist(
    devtools(redux(accountsReducer, accountsInitialState), {
      name: 'useAccountsStore',
    }),
    {
      name: 'useAccountsStore',
      // Important: must increment if the shape of persisted data is changed.
      version: 1.2,
    }
  )
)

export { useAccountsStore }
