export enum SourceOriginType {
  WEB_LINK = 'web_link',
  DOC = 'doc',
}

export type SourceInputType = {
  sourceName: string
  type: SourceOriginType | null
  link?: string
  files?: File | null
  fileName?: string | null
}

export type ResourcesType = {
  _id: string
  urlPath: string
  title: string
  isActive: boolean
}

export type SourceType = {
  _id: string
  sourceName: string
  sourceType: string
  syncStatus: string
  link?: string
  resourceCount?: number
  resources?: ResourcesType[]
  fileName?: string
  urlOrigin?: string
}

export type ResourceType = {
  resourceIds?: string[]
  isActive: boolean
  verifiedQuestion: boolean
}
