import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Typography from '@tiptap/extension-typography'
import UnderLine from '@tiptap/extension-underline'
import { BubbleMenu, EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Markdown } from 'tiptap-markdown'
import { PrimaryBtn } from '../StyledComponent'
import { LinkModal } from './LinkModal'

const CustomTiptapEditor = ({
  content,
  editorRef,
  handleInputChange,
}: {
  content: string
  editorRef: React.Ref<HTMLDivElement>
  handleInputChange: (value: string | undefined) => void
}) => {
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('')
  const [bubbleMenuPosition, setBubbleMenuPosition] = useState({
    top: 0,
    left: 0,
  })

  const editor = useEditor({
    editorProps: {
      attributes: {
        class:
          'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
      },
    },
    extensions: [StarterKit, Highlight, Typography, UnderLine, Link, Markdown],
    content: content,
    onUpdate({ editor }) {
      handleInputChange(editor.storage.markdown.getMarkdown())
    },
  })

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href
    setCurrentUrl(previousUrl || '')
    const bubbleMenuElement = document.querySelector('.answer-area .tippy-box')
    if (bubbleMenuElement) {
      const rect = bubbleMenuElement.getBoundingClientRect()
      setBubbleMenuPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      })
    }
    setIsLinkModalOpen(true)
  }, [editor])

  const handleSetLink = (url: string) => {
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run()
    } else {
      editor
        ?.chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
    setIsLinkModalOpen(false)
  }

  useEffect(() => {
    if (!editor?.state.selection.empty || !editor) {
      setIsLinkModalOpen(false)
    }
  }, [editor])

  return (
    <Wrapper>
      {editor && (
        <>
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <PrimaryBtn
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={`bubble-menu-btn ${
                editor.isActive('bold') ? 'is-active' : ''
              }`}
              style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
            >
              B
            </PrimaryBtn>
            <PrimaryBtn
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={`bubble-menu-btn ${
                editor.isActive('italic') ? 'is-active' : ''
              }`}
            >
              I
            </PrimaryBtn>
            <PrimaryBtn
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={`bubble-menu-btn ${
                editor.isActive('underline') ? 'is-active' : ''
              }`}
            >
              U
            </PrimaryBtn>
            <PrimaryBtn
              onClick={() => {
                editor.chain().focus()
                setLink()
              }}
              className={`bubble-menu-btn ${
                editor.isActive('link') ? 'is-active' : ''
              }`}
            >
              #
            </PrimaryBtn>
            <PrimaryBtn
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={`bubble-menu-btn ${
                editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
              }`}
            >
              H2
            </PrimaryBtn>
            <PrimaryBtn
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={`bubble-menu-btn ${
                editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
              }`}
            >
              H3
            </PrimaryBtn>
            <PrimaryBtn
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={`bubble-menu-btn ${
                editor.isActive('blockquote') ? 'is-active' : ''
              }`}
              style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
            >
              “
            </PrimaryBtn>
          </BubbleMenu>
          <LinkModal
            isOpen={isLinkModalOpen}
            onClose={() => setIsLinkModalOpen(false)}
            onSetLink={handleSetLink}
            defaultValue={currentUrl}
            position={bubbleMenuPosition}
          />
        </>
      )}
      <EditorContent editor={editor} ref={editorRef} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  font-family: Lato;
  .bubble-menu-btn {
    border-radius: 0;
    margin: 0;
    border: none;
  }
`

export default CustomTiptapEditor
