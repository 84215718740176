import Dexie, { Table } from 'dexie'
import { RelationshipType } from '../types/accountsTypes'

export interface AccountType {
  data: RelationshipType[]
  _id: string
}
export class AccountDB extends Dexie {
  relationship!: Table<RelationshipType>

  constructor() {
    super('AccountDB')
    this.version(1)
      .stores({
        relationship: '_id', // Primary key and indexed props
      })
      .upgrade(() => {
        this.on('versionchange', function (event: any) {
          console.log('AccountDb VersionChange', event)
          window.location.reload()
        })
      })
  }
}

export const accountDB = new AccountDB()
