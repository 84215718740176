import { Modal, message } from 'antd'
import { useNavigate } from 'react-router'
import useAssistants from '../../../../hooks/useAssistants'
import { AssistantBodyType } from '../../../../types'
import { AssistantForm } from '../Forms/AssistantForm'

type CreateAssistantProps = {
  isOpen: boolean
  onClose: () => void
  currentData?: AssistantBodyType
  isSaving?: boolean
  firstTime?: boolean
}

export const CreateAssistantModal = ({
  onClose,
  isOpen,
  firstTime,
}: CreateAssistantProps) => {
  const navigate = useNavigate()
  const onCreateSuccess = (assistantId: string) => {
    onClose()
    navigate(`/knowledge-assistant/${assistantId}`)
    message.success('Successfully created!')
  }
  const onCreateError = () => {
    message.error('Something went wrong!')
  }
  const {
    createAssistant,
    createStatus: { isLoading },
  } = useAssistants({
    onCreateSuccess,
    onCreateError,
  })

  const handleSubmit = (newItemData: AssistantBodyType, canSubmit: boolean) => {
    if (!canSubmit) {
      return message.error('Please fill in all the fields!')
    }
    createAssistant(newItemData)
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={onClose}>
      <AssistantForm
        onClose={onClose}
        isSaving={isLoading}
        onSubmit={handleSubmit}
        isFirstTime={firstTime}
      />
    </Modal>
  )
}
