import { Button, Divider, Typography } from 'antd'
import { LockSimple } from 'phosphor-react'
import { useLocation, useNavigate } from 'react-router'
import { FormContainer } from '../../components'
import { LOGIN_URL } from '../../config'
import { useAuth } from '../../context'
import GDPR from '../../images/icons/gdpr.webp'
import SOC from '../../images/icons/soc.webp'
import Logo from '../../images/logos/thena_logo_color.svg'
import { welcomeInfo } from './definitions'
const { Paragraph, Link } = Typography

export const Welcome = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { state } = useLocation()

  const loginUrl = state?.orgId
    ? `${LOGIN_URL}?organization=${state.orgId}`
    : LOGIN_URL

  const onSignInClick = () => {
    window.open(loginUrl, '_self')
  }

  const isLoggedIn = !!user?.email

  if (isLoggedIn) {
    navigate('/requests')
    return null
  }

  return (
    <div className="flex flex-col justify-center items-center left-0 absolute top-0 m-auto w-full z-[400] h-screen">
      <FormContainer className="3xl:w-2/6 w-2/5 border border-solid border-brand-border rounded-md">
        <>
          <div className="mb-4">
            <img src={Logo} alt="thena-logo" />
          </div>
          <div className="space-y-2">
            <Typography.Title
              className="text-center"
              level={3}
              style={{ marginBottom: 0 }}
            >
              {welcomeInfo.title}
            </Typography.Title>
            <Paragraph className="text-center text-gray-500 font-[500]">
              The world’s best way to work with your customers
            </Paragraph>
          </div>
          <a href={loginUrl}>
            <Button
              onClick={onSignInClick}
              type="primary"
              size="large"
              className="mt-6 flex justify-center items-center btn py-6 px-12 font-[500]"
            >
              Sign in to continue
            </Button>
          </a>
        </>
      </FormContainer>
      <div className="flex mt-4">
        <div className="flex items-center gap-1">
          <LockSimple size={14} color="#696F7B" />
          <span className="text-muted">
            We do not store any conversation data{' '}
          </span>
        </div>
        <div>
          <Divider className="py-2 text-gray-500" type="vertical" />
        </div>
        <Link
          href={welcomeInfo.links.terms.url}
          target="_blank"
          className="text-muted visited:text-muted welcome"
        >
          <span className="underline"> {welcomeInfo.links.terms.text}</span>
        </Link>
        <div>
          <Divider className="py-2 text-gray-500" type="vertical" />
        </div>
        <Link
          href={welcomeInfo.links.privacy.url}
          target="_blank"
          className="text-muted visited:text-muted welcome"
        >
          <span className="underline"> {welcomeInfo.links.privacy.text}</span>
        </Link>
        <div>
          <Divider className="py-2 text-gray-dark" type="vertical" />
        </div>
        <div className="flex items-center gap-1">
          <img src={GDPR} alt="gdpr-compliance" className="w-5 h-5" />
          <img src={SOC} alt="soc" className="w-5 h-5" />
        </div>
      </div>
    </div>
  )
}
