import { PlusSquareFilled } from '@ant-design/icons'
import { Button, Card, Image, Typography } from 'antd'
const { Text } = Typography
export const TemplateCard = ({
  from,
  template,
  handleSetTemplate,
  createCampaignHandler,
}: {
  from: string
  template: any
  handleSetTemplate?: (block: any, name: string) => void
  createCampaignHandler?: (block: any, name: string) => void
}) => {
  if (template.name === 'Blank Canvas') {
    return (
      <Card
        className="group bg-[#fff] border-dashed border-gray-dark"
        hoverable
        style={{ width: 300, height: 360 }}
        cover={
          <div className="h-[360px] flex flex-col justify-center items-center">
            <PlusSquareFilled
              style={{
                fontSize: '200px',
                color: '#E2C7FF',
              }}
              className="group-hover:blur-[2px] object-contain p-1"
            />
          </div>
        }
        // onClick={() => {
        //   if (createCampaignHandler) {
        //     createCampaignHandler(template?.blocks, template?.name)
        //   }

        //   ;(window as any).analytics.track('Template selected', {
        //     template: template.name,
        //   })
        // }}
      />
    )
  }
  return from === 'NoCampaign' ? (
    <Card
      key={template?.name}
      className="group template"
      hoverable
      style={{ width: 300, height: 360 }}
      cover={
        <img
          alt={template?.name}
          width={300}
          height={360}
          src={template?.imageUrl}
          className="group-hover:blur-[2px] object-fill p-1"
        />
      }
      // onClick={() => {
      //   if (handleSetTemplate) {
      //     handleSetTemplate(template?.blocks, template?.name)
      //   }
      //   ;(window as any).analytics.track('Template selected', {
      //     template: template.name,
      //   })
      // }}
    >
      <Button
        type="primary"
        size="large"
        className="hidden group-hover:block group-hover:absolute group-hover:top-1/2 group-hover:left-1/2 group-hover:-translate-x-1/2 group-hover:-translate-y-1/2 group-hover:backdrop-blur-lg"
        disabled
      >
        Use template
      </Button>
      <div className="flex justify-center">
        <Text className="font-medium">{template?.name}</Text>
      </div>
    </Card>
  ) : (
    <Card
      key={template?.name}
      hoverable
      style={{ width: 300, height: 360 }}
      cover={
        <Image
          width={300}
          height={360}
          src={template?.imageUrl}
          className="group-hover:blur-[2px] object-fill p-1"
          preview={{
            src: template?.previewImageUrl,
          }}
        />
      }
    />
  )
}
