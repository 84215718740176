import { getColorByLuminance } from '@utils/textUtils'
import { Popover, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import { CaretDown, Check } from 'phosphor-react'
import { useMemo } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../../context'
import DefaultOrgIcon from '../../../images/icons/org-icon.svg'
import {
  Text5,
  Text6,
  Text9,
} from '../../../modules/ZendeskIntegration/Commons'

const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  .ant-popover-inner-content {
    padding: 0 !important;
  }
`

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  border-radius: 4px;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--color-purple-9);
  img {
    object-fit: contain;
  }
`

// const data = [
//   {
//     id: 1,
//     name: 'Hubspot - Marketing Hub',
//     url: 'hubspot.slack.com',
//     isActive: false,
//     role: 'user',
//     icon: DefaultOrgIcon,
//   },
//   {
//     id: 2,
//     name: 'Salesforce',
//     url: 'salesforce.slack.com',
//     isActive: false,
//     role: 'customer',
//     icon: 'https://i.pravatar.cc/28',
//   },
//   {
//     id: 3,
//     name: 'Atlassian - Jira',
//     url: 'jira.slack.com',
//     isActive: true,
//     role: 'customer',
//     icon: 'https://i.pravatar.cc/28',
//   },
// ]

const ListItem = styled.div`
  padding: 2px 4px;
  border-radius: 4px;
  :hover {
    background-color: var(--color-purple-9) !important;
  }
`

type Props = {
  color?: string
  bgColor?: string
  expanded?: boolean
}

const OrgSelector = ({ expanded, color = '', bgColor = '' }: Props) => {
  const { user } = useAuth()
  const isOrg = !isEmpty(user?.organization)

  const lightenBgColor = getColorByLuminance(bgColor)

  const onSelect = (item: any) => {
    // TODO: API Call
    // console.log({ item })
  }

  const data = useMemo(() => {
    return [
      {
        name: user?.organization?.display_name,
        url: user?.organization?.name,
        isActive: true,
        role: 'user',
        icon: user?.organization?.branding?.logo_url || DefaultOrgIcon,
      },
    ]
  }, [user])

  const renderContent = () => {
    const orgList = data.filter((item) => item.role === 'user')
    const portalList = data.filter((item) => item.role === 'customer')

    const renderList = (item: any) => {
      return (
        <ListItem
          onClick={() => onSelect(item)}
          className="flex items-center gap-[4px] cursor-pointer"
          style={{
            backgroundColor: 'var(--color-purple-8)',
          }}
        >
          <div>
            <img
              src={item.icon}
              alt=""
              width={28}
              height={28}
              style={{
                objectFit: 'contain',
              }}
            />
          </div>
          <div>
            <Text6 className="m-[0]" color="var(--color-white)">
              {item.name}
            </Text6>
            <Text9 className="m-[0]" color="var(--color-gray-14)">
              {item.url}
            </Text9>
          </div>
          <div
            className="flex items-center justify-center"
            style={{
              marginLeft: 'auto',
            }}
          >
            {item.isActive ? (
              <Check size={20} weight="bold" color="var(--color-purple-10)" />
            ) : null}
          </div>
        </ListItem>
      )
    }

    return (
      <div
        style={{
          width: '260px',
          padding: '8px',
          borderRadius: '4px',
          color: 'var(--color-white)',
          border: '1px solid var(--color-gray-18)',
          backgroundColor: 'var(--color-purple-8)',
        }}
      >
        {orgList.length > 0 ? (
          <div className="mb-[8px]">
            <div
              style={{
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: '14px',
                marginBottom: '8px',
                color: 'var(--color-gray-12)',
              }}
            >
              YOUR ORGANIZATIONS
            </div>
            {orgList.map((item) => {
              return renderList(item)
            })}
          </div>
        ) : null}
        {portalList.length > 0 ? (
          <div>
            <div
              style={{
                fontSize: '10px',
                fontWeight: 600,
                lineHeight: '14px',
                marginBottom: '8px',
                color: 'var(--color-gray-12)',
              }}
            >
              JOINED PORTALS
            </div>
            {portalList.map((item) => {
              return renderList(item)
            })}
          </div>
        ) : null}
      </div>
    )
  }

  const selectedOrg = data.find((item) => item.isActive)

  const getTeamInfo = () => {
    if (user?.is_customer) {
      return {
        teamName: user?.organization?.display_name,
        teamImage: user.organization?.branding?.logo_url,
      }
    }
    return {
      teamName: user?.team_name,
      teamImage: user?.team_image,
    }
  }

  if (!isOrg || user?.is_customer) {
    return (
      <Wrapper>
        <Tooltip
          title={getTeamInfo().teamName}
          getTooltipContainer={(trigger) =>
            trigger.parentElement || document.body
          }
        >
          <div className="flex items-center gap-[8px]">
            <IconWrapper
              style={{
                backgroundColor: lightenBgColor,
              }}
            >
              <img
                src={getTeamInfo().teamImage}
                alt=""
                width={28}
                height={28}
                style={{
                  objectFit: 'contain',
                }}
              />
            </IconWrapper>
            {expanded ? (
              <Text5
                className="m-[0]"
                color="var(--color-white)"
                style={{
                  color: color || '',
                  width: '100px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {getTeamInfo().teamName}
              </Text5>
            ) : null}
          </div>
        </Tooltip>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Popover
        trigger="click"
        showArrow={false}
        placement="bottomRight"
        content={renderContent}
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ width: '260px' }}
        getPopupContainer={(trigger) => trigger.parentElement || document.body}
      >
        <div className="flex items-center gap-[4px]">
          <IconWrapper>
            <img
              src={selectedOrg?.icon}
              alt=""
              width={28}
              height={28}
              style={{
                objectFit: 'contain',
              }}
            />
          </IconWrapper>
          {expanded ? (
            <Text5
              className="m-[0]"
              color="var(--color-white)"
              style={{
                width: '100px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {selectedOrg?.name}
            </Text5>
          ) : null}
          {expanded ? (
            <CaretDown size={20} color="#E2E2E2" className="ml-[8px]" />
          ) : null}
        </div>
      </Popover>
    </Wrapper>
  )
}

export default OrgSelector
