import { SendOutlined } from '@ant-design/icons'
import { Button, Input, Row, message } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { CreateConversationType } from '../../../../types/playgroundTypes'
const { TextArea } = Input

type QuestionInputProps = {
  currChatId: string
  assistantId: string
  assistantName: string
  loadingStatus: boolean
  setContext: (value: string) => void
  askQuestion: (value: string) => void
  createNewChat: (data: CreateConversationType) => void
}

export const QuestionInput = ({
  currChatId,
  assistantId,
  assistantName,
  loadingStatus,
  setContext,
  askQuestion,
  createNewChat,
}: QuestionInputProps) => {
  const [question, setQuestion] = useState('')

  const handleChange = (value: string) => {
    setQuestion(value)
  }

  const handleSubmit = () => {
    if (!assistantId) {
      return message.error('Please select an assistant!')
    }
    if (question.trim() === '') {
      return message.error('Please enter a valid question!')
    }
    if (!currChatId) {
      setContext(question)
      setQuestion('')
      return createNewChat({ name: question, isThreaded: true })
    }
    setContext(question)
    askQuestion(question)
    setQuestion('')
  }

  return (
    <Wrapper>
      <div className="input-area">
        <TextArea
          value={question}
          onChange={(e) => handleChange(e.target.value)}
          autoSize={{ minRows: 1, maxRows: 6 }}
          className="question-input"
          placeholder="Enter your prompt here..."
          onPressEnter={(e) => {
            if (e.key === 'Enter' && !e.shiftKey && !loadingStatus) {
              e.preventDefault()
              handleSubmit()
            }
          }}
        />
        <Button
          icon={<SendOutlined rotate={315} style={{ fontSize: 14 }} />}
          onClick={handleSubmit}
          className="md:bottom-2 submit-button"
          disabled={loadingStatus}
        />
      </div>
      <Row justify="space-between" style={{ width: '100%' }}>
        <span className="propmt-comment">
          You are messaging your assistant <b>{assistantName}</b>
        </span>
        <span className="propmt-comment">
          Generated content may be inaccurate or false.
        </span>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 24px;
  .input-area {
    display: flex;
    align-items: end;
    min-height: 48px;
    border: 1px solid var(--color-gray-2);
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 10px 16px;
    background-color: var(--color-gray-bg);
    width: 834px;
  }
  .question-input {
    font-size: 15px;
    padding: 0;
    background-color: var(--color-gray-bg);
    ::-webkit-scrollbar {
      display: none;
    }
    resize: none;
    border: none;
  }
  .submit-button {
    margin-left: 8px;
    height: 14px;
    width: 14px;
    border: none;
    background: transparent !important;
  }
  .propmt-comment {
    color: var(--color-gray-5);
    font-size: 12px;
  }
`
