/* eslint-disable max-lines */

import {
  KnockFeedProvider,
  NotificationFeedPopover,
  NotificationIconButton,
} from '@knocklabs/react-notification-feed'
import '@knocklabs/react-notification-feed/dist/index.css'
import {
  Avatar,
  Input,
  List,
  Popover,
  Select,
  Spin,
  Tooltip,
  Typography,
} from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isEmpty } from 'lodash'
import { track } from 'logrocket'
import {
  CaretRight,
  ChatText,
  MagnifyingGlass,
  Power,
  Question,
} from 'phosphor-react'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { kanbanBoardDB } from '../../../DB/kanbanBoardDB'
import {
  KNOCK_FEED_CHANNEL_ID,
  KNOCK_PUBLIC_API_KEY,
  LOGOUT_URL,
} from '../../../config'
import { useAuth } from '../../../context'
import { BLOCKS } from '../../../pages/Accounts/constants'
import {
  dynamicFieldMapping,
  useCurrentIntegration,
} from '../../../pages/Accounts/utils'
import '../../../pages/KanbanBoard/style.css'
import { MODAL_TYPES } from '../../../pages/Requests/constants'
import { findPrimaryUserImage } from '../../../pages/Requests/utils'
import { useAccountsStore } from '../../../store/accountsStore'
import { useKanbanBoardStore } from '../../../store/kanbanBoardStore'
import { useKanbanStore2 } from '../../../store/kanbanStore2'
import { clearBrowserStorage } from '../../../utils/domUtils'
import { emptyObject } from '../../../utils/empty'
import { DropdownContainer } from '../../Analytics/RequestOverview/Common'
import usePageInfo from '../layout/usePageInfo'
import NotificationToaster from './NotificationToaster'

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  position: relative;
  height: 32px;
  .add-filter-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-gray-4);
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .plus-icon {
      margin-right: 2px;
    }
    cursor: pointer;
    padding: 15px 6px;
  }
  .reset-btn {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-gray-4);
    height: 24px;
    padding: 15px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 4px;
    }
    cursor: pointer;
  }

  .add-filter-btn:hover,
  .reset-btn:hover {
    background-color: var(--color-gray-3);
    border-radius: 4px;
  }

  .download-btn {
    margin-left: 10px;
  }
`
const Beta = styled.div`
  z-index: 5;
  display: flex;
  padding: 0 4px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  color: var(--color-purple-6);
  background-color: var(--color-purple-7);
`

const Heading = styled(Typography.Title)`
  color: var(--color-black-1) !important;
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
`

const SubHeading = styled(Typography.Title)`
  color: var(--color-gray-6) !important;
  font-family: Lato !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 20px !important;
`

export const NewPageHeader = ({
  collapsedSidenav,
}: {
  collapsedSidenav: boolean
}) => {
  const { pathname } = useLocation()
  const { heading, helpLink, tourId, helphub_scroll, isRequestPage, search } =
    usePageInfo()

  const crmConfig = useAccountsStore((state) => state.crmConfig) ?? emptyObject

  const flags = useFlags()
  const { user } = useAuth()
  const { enabledIntegrationDetails, isParagonLoading } =
    useCurrentIntegration()
  const isAccountsPage = pathname === '/accounts'
  const aiConsentHidden = useAccountsStore((state) => state.aiConsent)

  const handleCRMImport = (CRM: string) => {
    paragon?.connect(
      CRM,
      dynamicFieldMapping(CRM, crmConfig?.additional_fields)
    )
  }

  const [firstName, lastName] = (user?.displayName || user?.name || '').split(
    ' '
  )

  const data = [
    {
      key: 'Give Feedback',
      value: (
        <div className="flex items-center justify-start gap-1">
          <ChatText color="var(--color-gray-6)" size={18} />
          Give Feedback
        </div>
      ),
    },
    {
      key: 'Logout',
      value: (
        <div className="flex items-center justify-start gap-1">
          <Power color="var(--color-gray-6)" size={18} />
          Logout
        </div>
      ),
    },
  ]
  const setSearchParams = useSearchParams()[1]
  const handleClick = (tourId: string, helphubScroll?: string) => {
    if (helphubScroll) {
      setSearchParams((param) => {
        const newParams = new URLSearchParams(param)
        newParams.set('helphub_id', tourId)
        newParams.set('helphub_scroll', helphubScroll)
        return newParams
      })
    }
    return window?.CommandBar?.openHelpHub({
      articleId: Number(tourId),
    })
  }
  const renderHelpLink = () => {
    return (
      <div>
        <Question
          className="cursor-pointer"
          color="var(--color-gray-6)"
          size={16}
          onClick={() => {
            handleClick(tourId, helphub_scroll)
            ;(window as any).analytics.track('HELP', {
              userId: user?.id,
              userEmail: user?.email,
              teamId: user?.team_id,
              teamName: user?.team_name,
            })
          }}
        />{' '}
      </div>
    )
  }
  const [isOpen, setIsOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const onItemClick = async (item: string) => {
    if (item === 'Logout') {
      navigate('/logout')
      try {
        await clearBrowserStorage()
        ;(window as any).thena?.logout()
        ;(window as any).analytics.track('User logged out')
      } catch (error) {
        console.log('Error in clearing storages: ', error)
      } finally {
        window.location.href = LOGOUT_URL
      }
    }
    if (item === 'Give Feedback') {
      window.open('https://thena.canny.io/', '_blank')
      track('Page View', {
        oldView: pathname,
        newView: 'https://thena.canny.io/',
      })
    }
  }
  const content = (
    <div style={{ height: 'max-content', overflow: 'auto', width: '150px' }}>
      <List
        size="small"
        bordered={false}
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            className="cursor-pointer [color:var(--color-gray-4)] hover:[color:var(--color-gray-6)] hover:bg-[#EBEAF8] rounded-md"
            style={{
              borderBottomWidth: 0,
              paddingLeft: '4px',
              paddingRight: 0,
            }}
            onClick={() => onItemClick(item.key)}
          >
            {item.value}
          </List.Item>
        )}
      />
    </div>
  )

  useEffect(() => {
    isOpen && setTooltipOpen(false)
  }, [isOpen])
  const [isVisible, setIsVisible] = useState(false)
  const notifButtonRef = useRef<any>(null)
  const location = useLocation()
  const navigate = useNavigate()

  const openKanbanRequestDrawer = (requestData: any) => {
    if (!isEmpty(requestData)) {
      kanbanBoardDB.conversations.toArray().then((records) => {
        const recordsObject = records.reduce((acc: any, item: any) => {
          return {
            ...acc,
            [item._id]: item,
          }
        }, {})
        const recordArray = Object.values(recordsObject) as any

        const foundData = recordArray.find(
          (item: any) => item.requestId === requestData.request_id
        )
        useKanbanBoardStore.dispatch({
          type: 'UPDATE_RECORD',
          payload: {
            _id: foundData?._id,
            data: foundData,
          },
        })
        const currentColDetails =
          foundData?.status === 'OPEN' ? 'UNASSIGNED' : foundData?.status
        useKanbanStore2.dispatch({
          type: 'SET_MODAL',
          payload: {
            modal: {
              type: MODAL_TYPES.KANBAN_DRAWER,
              data: {
                cardId: foundData?._id,
                currentCol: currentColDetails,
                query: 'requestId;teamId',
                queryValue: `${foundData.requestId};${foundData.teamId}`,
              },
            },
          },
        })
        if (!location.pathname.includes('/requests')) {
          navigate('/requests', { replace: true })
        }
      })
    }
  }

  const userImage = findPrimaryUserImage(user)

  return heading !== '' ? (
    <>
      <div
        className={`flex items-center justify-start mb-0 main-layout header-bg pt-[0.5rem] fixed right-0 top-[44px] ${
          collapsedSidenav ? 'left-[80px]' : 'left-[240px]'
        } ${
          isAccountsPage && !aiConsentHidden && 'mt-[42px]'
        } transition-all duration-300 ease-in-out`}
      >
        <div className="flex items-center justify-start w-[30%] gap-3">
          {!isRequestPage ? (
            <div
              className="flex items-start gap-1 justify-start"
              style={{ flexShrink: 0 }}
            >
              <div className="flex items-center gap-1">
                <Heading level={3} style={{ marginBottom: 0 }}>
                  {heading}
                </Heading>
                {isAccountsPage && <Beta>Beta</Beta>}
              </div>
              {helpLink !== '' && tourId !== '' && renderHelpLink()}
            </div>
          ) : (
            <div
              className="flex items-center gap-1 justify-start"
              style={{ flexShrink: 0 }}
            >
              <div className="flex items-baseline gap-1">
                <Heading level={3} style={{ marginBottom: 0 }}>
                  {search === 'customer-requests' ? 'Customer' : 'Internal'}
                </Heading>
                <CaretRight color={'var(--color-gray-6)'} weight="regular" />
                <SubHeading level={3} style={{ marginBottom: 0 }}>
                  {heading}
                </SubHeading>
              </div>
              {helpLink !== '' && tourId !== '' && renderHelpLink()}
            </div>
          )}
        </div>
        <div className="flex justify-between w-full">
          <div onClick={() => window.CommandBar.open()}>
            <Input
              name="search"
              size="middle"
              className="text-[#95949B] font-normal p-[7px] rounded-[7px] w-[500px] border border-solid border-[#E5E5EA]"
              placeholder="Search in Thena"
              prefix={<MagnifyingGlass color="#6F6F75" />}
              autoComplete={'off'}
              readOnly={true}
            />
          </div>
          <div className="flex items-center justify-start gap-4">
            <div>
              <FilterButton>
                <div
                  className="add-filter-btn"
                  onClick={() => {
                    if (isAccountsPage) {
                      window.analytics.track('Import from CRM Button Click')
                      return
                    }
                  }}
                >
                  {isAccountsPage &&
                    (enabledIntegrationDetails.icon ? (
                      <span
                        onClick={() => {
                          handleCRMImport(enabledIntegrationDetails.type)
                        }}
                      >
                        Data sync'd with{' '}
                        <img
                          src={enabledIntegrationDetails.icon}
                          width={22}
                          height={22}
                          style={{ objectFit: 'contain' }}
                          alt={enabledIntegrationDetails.name}
                        />
                      </span>
                    ) : isParagonLoading ? (
                      <div className="w-full h-20 flex justify-center items-center">
                        <Spin size="small" />
                      </div>
                    ) : (
                      <DropdownContainer
                        $width={BLOCKS.IMPORT_FROM_CRM_TEXT.length * 10}
                      >
                        <Select
                          size="small"
                          className="select-container"
                          value={BLOCKS.IMPORT_FROM_CRM_TEXT}
                          onChange={(value) => {
                            handleCRMImport(value)
                          }}
                          options={BLOCKS.CRM_OPTIONS}
                        />
                      </DropdownContainer>
                    ))}
                </div>
              </FilterButton>
            </div>
            {flags.knockIntegration && (
              <KnockFeedProvider
                apiKey={KNOCK_PUBLIC_API_KEY}
                feedId={KNOCK_FEED_CHANNEL_ID}
                userId={user?.id ?? ''}
              >
                <>
                  <NotificationIconButton
                    ref={notifButtonRef}
                    onClick={(e) => setIsVisible(!isVisible)}
                  />
                  <NotificationFeedPopover
                    isVisible={isVisible}
                    buttonRef={notifButtonRef}
                    onClose={() => setIsVisible(false)}
                    onNotificationClick={(notification) => {
                      if (notification.data?.request) {
                        openKanbanRequestDrawer(notification.data.request)
                      }
                    }}
                  />
                  <NotificationToaster
                    openKanbanRequestDrawer={openKanbanRequestDrawer}
                  />
                </>
              </KnockFeedProvider>
            )}
            <Popover
              content={content}
              className="cursor-pointer"
              trigger="click"
              placement="bottomRight"
              showArrow={false}
              style={{
                width: '150px',
              }}
              open={isOpen}
              onOpenChange={(visible) => setIsOpen(visible)}
              overlayClassName="popover-class"
            >
              <Tooltip
                placement="bottomRight"
                title={user?.displayName || user?.name || ''}
                trigger="hover"
                open={tooltipOpen}
                onOpenChange={(visible) => setTooltipOpen(visible)}
              >
                <Avatar
                  size={28}
                  src={userImage}
                  className="bg-[#1442DB] text-base"
                >
                  {firstName.charAt(0).toUpperCase()}
                  {lastName
                    ? lastName.charAt(0).toUpperCase()
                    : firstName.charAt(1)}
                </Avatar>
              </Tooltip>
            </Popover>
          </div>
        </div>
      </div>
    </>
  ) : null
}
