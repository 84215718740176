import { api } from '.'

export const askQuestion = async ({
  assistantId,
  question,
  conversationId,
}: {
  assistantId: string
  question: string
  conversationId: string
}) => {
  const { data } = await api.post(
    `v2/knowledge-base-ai/assistant/${assistantId}/ask`,
    { question, conversationId }
  )
  return data.data
}

export const pollingAnswer = ({
  assistantId,
  pollingKey,
  conversationId,
  qnaId,
}: {
  assistantId: string
  pollingKey: string
  conversationId: string
  qnaId: string
}) => {
  return api
    .post(`v2/knowledge-base-ai/assistant/${assistantId}/ask/poll`, {
      pollingKey,
      conversationId,
      qnaId,
    })
    .then(({ data }) => data.data)
}
