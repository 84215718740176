import { useEffect } from 'react'
import useParagonAuth from '../hooks/useParagonAuth'
import useParagonGlobal from '../hooks/useParagonGlobal'
import { useParagonStore } from '../store/paragonStore'

const useInitParagon = () => {
  const paragon = useParagonGlobal()
  const { user, loading } = useParagonAuth(paragon)
  const dispatchParagon = useParagonStore((state) => state?.dispatch)

  useEffect(() => {
    dispatchParagon({
      type: 'SET_PARAGON',
      payload: { paragonUser: user, loading: loading, paragon: paragon },
    })
  }, [user, loading])
}

export default useInitParagon
