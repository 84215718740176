import { api } from '.'
import type {
  BulkUpdateQNAType,
  FetchQNAType,
  QNAListType,
  UpdateQNAType,
} from '../types/qnaTypes'

export const createQNA = ({
  assistantId,
  qna,
}: {
  assistantId: string
  qna: QNAListType
}) => {
  return api
    .post(`v2/knowledge-base-ai/assistant/${assistantId}/qna`, qna)
    .then(({ data }) => data.data)
}

export const fetchQNAs = async ({
  assistantId,
  qnas,
}: {
  assistantId: string
  qnas: FetchQNAType
}) => {
  const { pagination, filters } = qnas
  const { pageNo, pageSize } = pagination

  const params: Record<string, string | string[]> = {
    pageNo: String(pageNo),
    pageSize: String(pageSize),
  }

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      params[key] = Array.isArray(value)
        ? value.map((val) => encodeURIComponent(val))
        : [encodeURIComponent(value)]
    })
  }

  const queryString = Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        return null
      }
      const encodedValues = Array.isArray(value)
        ? value.map((val) => encodeURIComponent(val))
        : [encodeURIComponent(value)]
      return `${key}=${encodedValues.join(`&${key}=`)}`
    })
    .filter((entry) => entry !== null)
    .join('&')

  const { data } = await api.get(
    `v2/knowledge-base-ai/assistant/${assistantId}/qna?${queryString}`
  )

  return data.data
}

export const updateQNA = async ({
  assistantId,
  qnaId,
  qna,
}: {
  assistantId: string
  qnaId: string
  qna: UpdateQNAType
}) => {
  return api.patch(
    `/v2/knowledge-base-ai/assistant/${assistantId}/qna/${qnaId}`,
    qna
  )
}

export const bulkUpdateQNA = async ({
  assistantId,
  qnas,
}: {
  assistantId: string
  qnas: BulkUpdateQNAType
}) => {
  return api.patch(`/v2/knowledge-base-ai/assistant/${assistantId}/qna`, qnas)
}
