import { Col, Skeleton, Tabs } from 'antd'
import moment from 'moment'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { SummaryDetail } from '../../../types/accountsTypes'
import { Actions } from './Actions'
import { Media } from './Media'
import { MetaData } from './MetaData'
import RequestList from './RequestList'
const Wrapper = styled(Col)`
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-nav {
    width: 100% !important;
  }
  .ant-tabs-tab {
    display: flex !important;
    flex: 1;
    justify-content: center;
  }
  .ant-tabs-nav-list {
    width: 100% !important;
    color: #828188 !important;
    font-weight: 600;
  }
  .ant-tabs-tab-btn:hover,
  .ant-tabs-tab:hover {
    color: var(--color-purple-4) !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #4e42c6 !important;
    font-weight: 600;
  }
  .ant-tabs-ink-bar {
    background-color: #4e42c6 !important;
  }
`

const DrawerTabs = ({
  summaryDetails,
  isLoading,
}: {
  summaryDetails: SummaryDetail[]
  isLoading: boolean
}) => {
  const [activeKey, setActiveKey] = useState('metadata')
  const sortedDetails = useMemo(() => {
    return [...summaryDetails].sort(
      (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
    )
  }, [summaryDetails])

  const items = useMemo(() => {
    return [
      {
        key: 'metadata',
        label: 'Summary Insights',
        className: 'p-6 pt-0',
        children: isLoading ? (
          <Skeleton active />
        ) : (
          <MetaData summaryDetails={sortedDetails} />
        ),
      },
      {
        key: 'actions',
        label: 'Actions & Events',
        className: 'p-6 pt-0',
        children: isLoading ? (
          <Skeleton active />
        ) : (
          <Actions summaryDetails={sortedDetails} />
        ),
      },
      {
        className: 'p-6 pt-0',
        key: 'media',
        label: 'Media',
        children: isLoading ? (
          <Skeleton active />
        ) : (
          <Media summaryDetails={sortedDetails} />
        ),
      },
      {
        className: 'px-4',
        key: 'requests',
        label: 'Requests',
        children: <RequestList />,
      },
    ]
  }, [sortedDetails, isLoading])

  return (
    <Wrapper span={15} className={`overflow-y-scroll h-full`}>
      <Tabs
        activeKey={activeKey}
        destroyInactiveTabPane
        items={items}
        onChange={(activeKey) => {
          setActiveKey(activeKey)
        }}
        style={{ color: 'var(--color-gray-4)' }}
        tabBarStyle={{
          top: 0,
          zIndex: 999,
          position: 'sticky',
          backgroundColor: '#fff',
          borderColor: 'var(--color-gray-3)',
        }}
      />
    </Wrapper>
  )
}

export default DrawerTabs
