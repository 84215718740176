import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { CLOUDINARY_CLOUDNAME } from '../../../config'
import ClosedIcon from '../../../images/icons/ic_status_closed.svg'
import InProgressIcon from '../../../images/icons/ic_status_in_progress.svg'
import OnHoldIcon from '../../../images/icons/ic_status_on_hold.svg'
import OpenIcon from '../../../images/icons/ic_status_open.svg'
import { RelationshipType } from '../../../types/accountsTypes'
import { emptyObject } from '../../../utils/empty'
import { getNameSnippet, truncate } from '../../../utils/textUtils'

export type RequestItem = {
  id: number
  label: string
  team_id: string
  tags: string[]
  status: 'OPEN' | 'ASSIGNED' | 'ONHOLD' | 'CLOSED' | 'NOT_A_REQUEST'
  assigned_to: {
    name: string
    image: string
  }
  customer_team: {
    name: string
    icon: {
      image_44: string
    }
  }
}

export type AccountItem = {
  id: number
  label: string
  tags: string[]
  accountType:
    | 'Customer'
    | 'Prospect'
    | 'Partner'
    | 'Vendor'
    | 'Lead'
    | 'Others'
  customer_team: {
    name: string
  }
}

const unAssignedIcon = `https://res.cloudinary.com/${CLOUDINARY_CLOUDNAME}/image/upload/v1690360972/ic_unassigned.svg`

const COLOR_SETS = [
  `color: #f56a00; background-color: #fde3cf;`,
  `color: #007bff; background-color: #cfe2ff;`,
  `color: #8b008b; background-color: #f8e0ff;`,
  `color: #228b22; background-color: #e0ffe0;`,
  `color: #800080; background-color: #f4e3f5;`,
]

// @ts-ignore
window.updateImageInCommandBarRequest = (id) => {
  if (document) {
    const img = document.querySelector(`.img-request-id-${id}`)
    if (img) {
      // @ts-ignore
      img.style.display = 'none'
    }
    const nameSnippet = document.querySelector(`.name-snippet-request-id-${id}`)
    if (nameSnippet) {
      // @ts-ignore
      nameSnippet.style.display = 'flex'
    }
  }
}

export class RequestResult {
  item: RequestItem

  constructor(item: RequestItem) {
    this.item = item
  }

  getRequestInfo() {
    const {
      id = 0,
      status = 'OPEN',
      tags = [],
      label = '',
      assigned_to,
      customer_team,
    } = this.item || emptyObject

    const {
      icon: { image_44: workspaceIcon = '' } = {},
      name: workspaceName = '',
    } = customer_team || emptyObject

    const { image: assignedToImage = '', name: assignedToName = '' } =
      assigned_to || emptyObject

    return {
      id,
      tags,
      label,
      status,
      workspaceIcon,
      workspaceName,
      assignedToName,
      assignedToImage,
    }
  }

  getStatusHTML() {
    const { status } = this.getRequestInfo()
    let statusText = ''
    let statusIcon = ''
    let statusColor = ''

    switch (status) {
      case 'OPEN':
        statusText = 'Open'
        statusIcon = OpenIcon
        statusColor = 'rgba(53, 93, 224)'
        break

      case 'ASSIGNED':
        statusText = 'In Progress'
        statusIcon = InProgressIcon
        statusColor = 'rgba(237, 150, 64)'
        break

      case 'ONHOLD':
        statusText = 'On Hold'
        statusIcon = OnHoldIcon
        statusColor = 'rgba(111, 111, 117)'
        break

      case 'CLOSED':
        statusText = 'Closed'
        statusIcon = ClosedIcon
        statusColor = 'rgba(38, 159, 125)'
        break

      default:
        statusText = startCase(toLower(status))
    }

    const backgroundColor = statusColor.replace(')', ', 0.2)')

    const statusHTML = `
          <div class="status-container" 
               style="color: ${statusColor}; 
                      border: 1px solid ${statusColor}; 
                      background-color:${backgroundColor}; 
          ">
            ${statusIcon ? `<img src="${statusIcon}" alt="" />` : ''}
            <div>${statusText}</div>
          </div>`

    return statusHTML
  }

  getAssignedToHTML() {
    const { assignedToName, assignedToImage, id } = this.getRequestInfo()
    const imageUrl =
      assignedToName === 'Unassigned' ? unAssignedIcon : assignedToImage
    const randomIndex = Math.floor(Math.random() * COLOR_SETS.length)
    const randomColorSet = COLOR_SETS[randomIndex]

    const assignedToHTML = `
          <div class="assigned-to-container">
            <img 
              src="${imageUrl}" 
              alt="${assignedToName}" 
              class="assigned-to-img img-request-id-${id}" 
              onerror="updateImageInCommandBarRequest(${id})" />
            <div class="assigned-to-name-snippet name-snippet-request-id-${id}" style="${randomColorSet}">
              ${getNameSnippet(assignedToName)}
            </div>
          </div>`

    return assignedToHTML
  }

  getHTML() {
    const { id, tags, label } = this.getRequestInfo()

    const requestIdHTML = `
              <div class="request-id-container">
                ID-${id}
              </div>`

    const reuqestMetaInfo = `
              <div class="request-meta-info">
                ${requestIdHTML}
                ${this.getStatusHTML()} 
              </div>`

    const tagsHTML = `
              <div class="tags-container">
                ${tags
                  .map((tag: string) => `<div class="request-tag">${tag}</div>`)
                  .join('')}
              </div>`

    const infoContainerHTML = `
              <div class="info-container">
                <div class="request-label">
                  ${truncate(label, 85)} 
                  <span class="hidden-text">${'.'.repeat(250)}</span>
                </div>
                ${tagsHTML}
                ${reuqestMetaInfo}
              </div>`

    return `<div class="request-wrapper">
              ${infoContainerHTML}
              ${this.getAssignedToHTML()}
            </div>`
  }
}

export class AccountResult {
  item: RelationshipType

  constructor(item: RelationshipType) {
    this.item = item
  }

  getRequestInfo() {
    const {
      _id = '',
      crm_values,
      channel,
      customer_name,
    } = this.item || emptyObject

    return {
      _id,
      channel,
      crm_values,
      customer_name,
    }
  }

  getStatusHTML() {
    const { crm_values } = this.getRequestInfo()
    let accountTypeText = ''
    let accountTypeColor = ''

    switch (crm_values?.type) {
      case 'customer':
        accountTypeText = 'Customer'
        accountTypeColor = 'rgba(53, 93, 224)'
        break

      case 'prospect':
        accountTypeText = 'Prospect'
        accountTypeColor = 'rgba(237, 150, 64)'
        break

      case 'lead':
        accountTypeText = 'Lead'
        accountTypeColor = 'rgba(111, 111, 117)'
        break

      case 'other':
        accountTypeText = 'Others'
        accountTypeColor = 'rgba(38, 159, 125)'
        break

      default:
        accountTypeText = 'Customer'
    }

    const backgroundColor = accountTypeColor.replace(')', ', 0.2)')

    const statusHTML = `
          <div class="accountType-container" 
               style="color: ${accountTypeColor}; 
                      border: 1px solid ${accountTypeColor}; 
                      background-color:${backgroundColor}; 
          ">
            <div>${accountTypeText}</div>
          </div>`

    return statusHTML
  }

  getHTML() {
    const { customer_name } = this.getRequestInfo()

    const reuqestMetaInfo = `
              <div class="account-meta-info">
                ${this.getStatusHTML()} 
              </div>`

    const infoContainerHTML = `
              <div class="info-container">
                <div class="request-label">
                  ${truncate(customer_name, 85)} 
                  <span class="hidden-text">${'.'.repeat(250)}</span>
                </div>
                ${reuqestMetaInfo}
              </div>`
    return `<div class="account-wrapper">
                ${infoContainerHTML}
            </div>`
  }
}
/**
 * Todo:
 * - handle truncation of tags and +more in tags
 * - emoji and markdown support in label
 */
