import { Button, Card, Divider, Spin, Tag, Typography } from 'antd'
import styled from 'styled-components'
import { dynamicFieldMapping } from '../../../pages/Accounts/utils'
import { useAccountsStore } from '../../../store/accountsStore'
import { useParagonStore } from '../../../store/paragonStore'
import { emptyObject } from '../../../utils/empty'
import { helphubUtil } from '../../../utils/helphub'

const { Text, Paragraph } = Typography
const CRMLabel = styled.span`
  border-radius: 20px;
  background: #e7f0fd;
  padding: 2px 8px;
  color: #3d6eef;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.1px;
`
export const ParagonIntegrationCard = ({
  integration,
  integrationEnabled,
  loading,
}: {
  integration: any
  integrationEnabled?: boolean
  loading: boolean
}) => {
  const { paragonUser: user, paragon } = useParagonStore((state) => ({
    paragonUser: state?.paragonUser,
    paragon: state?.paragon,
  }))
  const crmConfig = useAccountsStore((state) => state.crmConfig) ?? emptyObject

  const helpHubHandler = (integration: string) => {
    if (integration === 'Salesforce') {
      helphubUtil(133830)
    } else if (integration === 'Jira') {
      helphubUtil(0)
    } else if (integration === 'Microsoft Teams') {
      helphubUtil(0)
    } else if (integration === 'Linear') {
      helphubUtil(0)
    } else if (integration === 'Thena Webhooks') {
      helphubUtil(275113)
    } else if (integration === 'PagerDuty') {
      helphubUtil(0)
    } else if (integration === 'HubSpot') {
      helphubUtil(0)
    }
  }
  const getDescription = (integration: string) => {
    if (integration === 'HubSpot') {
      return 'O-Auth enabled integration that pulls data from CRM'
    }
    return 'Sync Thena Requests with ' + integration
  }

  const getIntegrationLabel = (integration: string) => {
    if (integration === 'HubSpot') {
      return (
        <>
          HubSpot CRM <CRMLabel>CRM</CRMLabel>
        </>
      )
    }
    if (
      integration === 'Linear' ||
      integration === 'Jira' ||
      integration === 'Thena Webhooks' ||
      integration === 'Rootly' ||
      integration === 'PagerDuty' ||
      integration === 'Incident.io' ||
      integration === 'Opsgenie'
    ) {
      return <>{integration}</>
    }
    return (
      <>
        {integration} <CRMLabel>CRM</CRMLabel>
      </>
    )
  }

  return (
    <Card
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 16,
        paddingTop: 32,
      }}
      className="hover:border-hover-accent shadow-lg w-[260px]"
    >
      <div className={`bg-white h-auto`}>
        {!user?.authenticated ? (
          <div className="w-full flex items-center justify-center flex-col">
            <Spin />
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between">
              <div className="pl-6">
                <img src={integration.icon} width={51} height={42} alt="" />
              </div>
              {integrationEnabled && (
                <Tag className="rounded-full h-fit mx-[14px]" color="green">
                  Connected
                </Tag>
              )}
            </div>
            <div className="pl-6 pr-5 justify-left items-center w-full mt-4">
              <Text className="text-sm 3xl:text-[length:var(--sm)] font-bold">
                {getIntegrationLabel(integration.name)}
              </Text>
              <Paragraph
                style={{ minHeight: '40px' }}
                className="font-normal text-xs 3xl:text-[length:var(--xs)] text-gray-dark"
                ellipsis={{
                  rows: 2,
                }}
                title={'Zendesk + Paragon Integration'}
              >
                {/* Hardcoding for now - we need to make this name dynamic*/}
                {getDescription(integration.name)}
              </Paragraph>
            </div>
            <Divider className="py-0 mb-3 mt-5" />
            <div className="flex justify-between">
              {!integrationEnabled && (
                <Button
                  key="add-integration"
                  type="link"
                  className="pl-6  text-primary hover:text-brand-accent"
                  onClick={() =>
                    paragon?.connect(
                      integration?.type,
                      dynamicFieldMapping(
                        integration?.type,
                        crmConfig?.additional_fields
                      )
                    )
                  }
                >
                  Add Integration
                </Button>
              )}
              {integrationEnabled && (
                <Button
                  key="view-integration"
                  type="link"
                  className="pl-6  text-primary hover:text-brand-accent"
                  onClick={() =>
                    paragon?.connect(
                      integration?.type,
                      dynamicFieldMapping(
                        integration?.type,
                        crmConfig?.additional_fields
                      )
                    )
                  }
                >
                  View Integration
                </Button>
              )}
              <Button
                key="add-integration"
                type="link"
                className="pr-6 text-[color:var(--color-blue-1)] hover:text-[color:var(--color-blue-1)] cursor-pointer"
                onClick={() => helpHubHandler(integration.name)}
              >
                FAQs
              </Button>
            </div>
          </>
        )}
      </div>
    </Card>
  )
}
