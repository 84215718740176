import React, { useContext, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useConnectedApp } from '../../ConnectedApps'

interface NotionContextType {
  isSetUpOpen: boolean
  isConfigureOpen: boolean
  connectionPassed: boolean
  connectionFailed: boolean
  setIsSetUpOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsConfigureOpen: React.Dispatch<React.SetStateAction<boolean>>
  setConnectionPassed: React.Dispatch<React.SetStateAction<boolean>>
  setConnectionFailed: React.Dispatch<React.SetStateAction<boolean>>
  // isTestingConnection: boolean
  // testSalesforceConnectionMutation?: UseMutationResult<
  //   any,
  //   unknown,
  //   SalesforceTestConnectionPayloadType,
  //   unknown
  // >
}

const NotionContext = React.createContext<NotionContextType>({
  isSetUpOpen: false,
  isConfigureOpen: false,
  connectionPassed: false,
  connectionFailed: false,
  // isTestingConnection: false,
  setIsSetUpOpen: () => null,
  setIsConfigureOpen: () => null,
  setConnectionPassed: () => null,
  setConnectionFailed: () => null,
})

export const NotionProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient()
  const { isEdition } = useConnectedApp()
  const [connectionPassed, setConnectionPassed] = useState<boolean>(false)
  const [connectionFailed, setConnectionFailed] = useState<boolean>(false)
  const [isSetUpOpen, setIsSetUpOpen] = useState<boolean>(!isEdition)
  const [isConfigureOpen, setIsConfigureOpen] = useState<boolean>(isEdition)

  // Test Salesforce configuration
  // const testSalesforceConnectionMutation = useMutation(
  //   testSalesforceConnection,
  //   {
  //     onSuccess: () => {
  //       setConnectionPassed(true)
  //       setConnectionFailed(false)
  //       if (!isEdition && connectionPassed) {
  //         message.success('Salesforce configuration saved')
  //         setIsConfigureOpen(true)
  //         setIsSetUpOpen(false)
  //       }
  //       queryClient.invalidateQueries(['fetch-salesforce-integration'])
  //     },
  //     onError: () => {
  //       setConnectionPassed(false)
  //       setConnectionFailed(true)
  //     },
  //   }
  // )

  return (
    <NotionContext.Provider
      value={{
        isSetUpOpen,
        isConfigureOpen,
        connectionPassed,
        connectionFailed,
        // isTestingConnection: testSalesforceConnectionMutation?.isLoading,
        setIsSetUpOpen,
        setIsConfigureOpen,
        setConnectionPassed,
        setConnectionFailed,
        // testSalesforceConnectionMutation,
      }}
    >
      {children}
    </NotionContext.Provider>
  )
}

export const useNotion = () => {
  return useContext(NotionContext)
}
