export type ConversationType = {
  _id: string
  timestamp: string
  qnaId: string
  question: string
  answer: string
  feedback: number
  resources: ConversationResourceType[]
}

export type ConversationResourceType = {
  url: string | null
  title: string
}

export type AnswerType = {
  answer: string
  qnaId: string
  pollingKey?: string
  isPolling?: boolean
  status?: PollingStatusType
}

export enum PollingStatusType {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export enum FeedbackType {
  THUMB_UP = 1,
  NEUTRAL = 0,
  THUMB_DOWN = -1,
}

export type ConversationListType = {
  _id: string
  name: string
}

export type CreateConversationType = {
  name: string
  isThreaded: boolean
}
