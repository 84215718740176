import { useLayoutStore } from 'store/layoutStore'
import NewAppIcon from '../../images/icons/new-banner-new-icon.svg'

const NewAppBanner = () => {
  const collapsed = useLayoutStore((state) => state.collapsed)

  return (
    <div
      className="h-[44px] bg-[#7A00FF] text-white flex items-center justify-center gap-2"
      style={{
        left: collapsed ? '77px' : '240px',
        right: 0,
        position: 'fixed',
      }}
    >
      <div className="flex items-center gap-1 text-[#9333EA] px-[8px] bg-white rounded">
        <img src={NewAppIcon} alt="banner" />
        New
      </div>
      Check out our new and improved app! Experience enhanced features and a
      smoother interface.
      <a href="https://beta.thena.ai/" target="_blank">
        <div className="flex items-center gap-1 px-[12px] text-white border border-solid border-white rounded">
          Start exploring
        </div>
      </a>
    </div>
  )
}

export default NewAppBanner
