import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  createSource as createSourceApi,
  fetchSources,
  updateResources as updateResourcesApi,
  updateSource as updateSourceApi,
} from '../api/sources'
import { AssistantHookParamType, ResourceType, SourceInputType } from '../types'
const useSources = ({
  onCreateSuccess,
  onCreateError,
  onUpdateResourceSuccess,
  onUpdateResourceError,
  onUpdateSourceSuccess,
  onUpdateSourceError,
  assistantId,
}: AssistantHookParamType) => {
  const queryClient = useQueryClient()
  const { isLoading, error, data } = useQuery(['sourceList', assistantId], () =>
    fetchSources(assistantId)
  )

  const createMutation = useMutation(createSourceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('sourceList')
      onCreateSuccess && onCreateSuccess()
    },
    onError: (error) => {
      onCreateError && onCreateError(error)
    },
  })

  const createSource = (newItemData: SourceInputType) => {
    createMutation.mutate({ assistantId, sourceData: newItemData })
  }

  const updateMutation = useMutation(updateResourcesApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('sourceList')
      onUpdateResourceSuccess && onUpdateResourceSuccess()
    },
    onError: (error) => {
      onUpdateResourceError && onUpdateResourceError(error)
    },
  })
  const updateResources = (sourceId: string, newItemData: ResourceType) => {
    updateMutation.mutate({ assistantId, sourceId, resources: newItemData })
  }

  const updateSourceMutation = useMutation(updateSourceApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('sourceList')
      onUpdateSourceSuccess && onUpdateSourceSuccess()
    },
    onError: (error) => {
      onUpdateSourceError && onUpdateSourceError(error)
    },
  })
  const updateSource = (sourceId: string, sourceName: string) => {
    updateSourceMutation.mutate({
      assistantId,
      sourceId,
      sourceName,
    })
  }

  return {
    isLoading,
    error,
    data,
    createSource,
    createStatus: createMutation,
    updateResources,
    updateStatus: updateMutation,
    updateSource,
    updateSourceStatus: updateSourceMutation,
  }
}

export default useSources
