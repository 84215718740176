/* eslint-disable max-lines */
import { Button, message, Spin, Tooltip, Typography } from 'antd'
import EmojiConvertor from 'emoji-js'
import * as htmlToSlackMD_ from 'html-to-slack-md'
import { isEmpty, truncate } from 'lodash'
import { Paperclip, PaperPlaneRight, XCircle } from 'phosphor-react'
import quillEmoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
import QuillMention from 'quill-mention'
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom/client'
import { renderToString } from 'react-dom/server'
import { useDropzone } from 'react-dropzone'
import { useMutation, useQueryClient } from 'react-query'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { escapeForSlackWithMarkdown } from 'slack-to-html'
import styled, { css } from 'styled-components'
import { FetchMembersForAdminsType } from '../../../api/admins'
import {
  deleteFileFromS3,
  postComments,
  postInternalComments,
  uploadFilesToS3,
} from '../../../api/kanban'
import { useAuth } from '../../../context'
import useKeyboardShortcut from '../../../hooks/useKeyboardShortcut'
import { findPrimaryUserImage } from '../../../pages/Requests/utils'
import { isMacDevice } from '../../../utils/domUtils'
import {
  createTransformedHTML,
  renderAtValues,
  renderMentionItem,
  validateFileType,
} from '../../../utils/editor'
import { noOp } from '../../../utils/empty'
import { convertHtmlBlockquotesToSlack } from '../../../utils/slackFormatting'
import { Thread } from '../kanbanDrawer/RequestV2Drawer'
import './styles.css'
const emoji = new EmojiConvertor()
const { Text } = Typography
const htmlToSlackMD = htmlToSlackMD_.default || htmlToSlackMD_
Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
    'modules/mentions': QuillMention,
  },
  true
)
const formats = ['bold', 'italic', 'strike', 'emoji', 'mention']

const Wrapper = styled.div<{ $isInternal?: boolean }>`
  ${({ $isInternal }) =>
    $isInternal &&
    css`
      .ql-toolbar.ql-snow {
        background-color: var(--color-gray-3) !important;
      }
      .ql-container.ql-snow {
        background-color: var(--color-gray-bg) !important;
      }
    `}
`

const EditorWrapper = styled.div`
  position: relative;
`

const StyledButton = styled(Button)`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  border-radius: 4px !important;
  background-color: var(--color-purple-3) !important;
  :disabled {
    opacity: 0.6 !important;
  }
`

const RichTextEditor = ({
  setThreadDetails,
  setInternalThreadDetails,
  membersData,
  ts,
  threadTs,
  isInternal,
  channelId,
  ccEmails,
  bccEmails,
  isEmail = false,
  toEmails = [],
  clearEmailEditor = noOp,
  _id,
}: {
  setThreadDetails?: Dispatch<SetStateAction<Thread[]>>
  setInternalThreadDetails?: Dispatch<any>
  membersData: FetchMembersForAdminsType
  ts: string
  isEmail?: boolean
  threadTs: string
  isInternal: boolean
  channelId: string
  toEmails?: any[]
  ccEmails?: string[]
  bccEmails?: string[]
  clearEmailEditor?: () => void
  _id: string
}) => {
  const [value, setValue] = useState('')
  const [transformed, setTransformed] = useState('')
  const [newData, setNewData] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [files, setFiles] = useState<any>([])
  const [uploadedFiles, setUploadedFiles] = useState<any>([])

  const queryClient = useQueryClient()

  const reactQuillRef = useRef<any>(null)

  useEffect(() => {
    const removeEmojiPalette = (e: any) => {
      if (
        !(
          e.target.id === 'ReactQuill-Editor' ||
          e.target.closest('#ReactQuill-Editor')
        )
      ) {
        const emojiPalette = document.querySelector('#emoji-palette')
        if (emojiPalette) {
          emojiPalette.remove()
        }
      }
    }
    document.addEventListener('click', removeEmojiPalette)
    return () => {
      document.removeEventListener('click', removeEmojiPalette)
    }
  }, [])

  const { user } = useAuth()
  const modules = useMemo(
    () => ({
      toolbar: [['bold', 'italic', 'strike', 'emoji']],
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        spaceAfterInsert: true,
        source: (searchTerm: any, renderList: any, mentionChar: any) => {
          let values: any = [{ id: '', value: '' }]
          if (
            mentionChar === '@' &&
            !isEmpty(membersData) &&
            !isEmpty(membersData?.members)
          ) {
            const atValues = renderAtValues(membersData)
            values = atValues
          } else {
            values = []
          }
          if (searchTerm.length === 0) {
            renderList(values, searchTerm)
          } else {
            const matches = []
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i])
            renderList(matches, searchTerm)
          }
        },
        renderItem: (item: any) => {
          return renderToString(renderMentionItem(item))
        },
        positioningStrategy: 'fixed',
        listItemClass: 'ql-mention-list-item',
        mentionContainerClass: 'ql-mention-list-container',
        mentionListClass: 'ql-mention-list',
      },
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true,
    }),
    []
  )
  const InternalCommentMutation = useMutation(postInternalComments, {
    onSuccess: (data) => {
      message.success('Added new comment')
    },
    onError: () => {
      message.error('Something went wrong!')

      setInternalThreadDetails &&
        setInternalThreadDetails((prevData: any) =>
          prevData.filter((data: any) => data.threadTs !== undefined)
        )
    },
  })
  const CommentMutation = useMutation(postComments, {
    onSuccess: (data) => {
      message.success('Added new comment')
      // queryClient.
    },
    onError: () => {
      message.error('Something went wrong!')
      setThreadDetails &&
        setThreadDetails((prevState: any) =>
          prevState.map((thread: any) => {
            if (thread.ts === ts) {
              return {
                ...thread,
                replies: [...thread.replies.slice(0, -1)],
              }
            }
            return thread
          })
        )
    },
  })
  const UploadMutation = useMutation(uploadFilesToS3, {
    onSuccess: (data) => {
      message.destroy()
      message.success('Files uploaded!')
      window.analytics.track('Files uploaded on S3', {
        uploadedFiles: data,
      })
      setUploadedFiles([...uploadedFiles, ...data])
    },
    onError: () => {
      message.error('Something went wrong!')
    },
  })
  const DeleteMutation = useMutation(deleteFileFromS3, {
    onSuccess: (data) => {
      message.success('File removed!')
    },
    onError: () => {
      message.error('Something went wrong!')
    },
  })

  const userImage = findPrimaryUserImage(user)

  const handleSave = () => {
    const now = Math.floor(Date.now() / 1000)

    // cancels slack conversation refetch to prevent any race conditions
    queryClient.cancelQueries({ queryKey: ['fetchSlackConversation'] })
    queryClient.cancelQueries({ queryKey: ['internal-thread'] })
    if (isInternal) {
      setInternalThreadDetails &&
        setInternalThreadDetails((prevData: any) => [
          ...prevData,
          {
            text: isEmpty(files)
              ? newData
              : newData +
                '\n' +
                uploadedFiles.map(
                  (file: any) => `<a href=${file.permalink}>${file.name}</a>`
                ) +
                '\n',
            ts: now.toString(),
            threadTs: threadTs,
            blocks: [
              {
                type: 'rich_text',
                block_id: 'new_block_id',
                elements: [
                  {
                    type: 'rich_text_section',
                    elements: [
                      {
                        type: 'text',
                        text: newData,
                      },
                    ],
                  },
                ],
              },
            ],
            user: {
              id: user?.id || '',
              image: userImage,
              real_name: user?.displayName || '',
              display_name: user?.displayName || '',
              team: user?.team_id || '',
              name: user?.name || '',
            },
            file: [],
          },
        ])
    } else {
      setThreadDetails &&
        setThreadDetails((prevState: any) =>
          prevState.map((thread: any) => {
            if (thread.ts === ts) {
              return {
                ...thread,
                replies: [
                  ...thread.replies,
                  ...[
                    {
                      text: isEmpty(files)
                        ? newData
                        : newData +
                          '\n' +
                          uploadedFiles.map(
                            (file: any) =>
                              `<a href=${file.permalink}>${file.name}</a>`
                          ) +
                          '\n',
                      ts: now.toString(),
                      threadTs: threadTs,
                      parent_user_id: thread.user.id,
                      blocks: [
                        {
                          type: 'rich_text',
                          block_id: 'new_block_id',
                          elements: [
                            {
                              type: 'rich_text_section',
                              elements: [
                                {
                                  type: 'text',
                                  text: newData,
                                },
                              ],
                            },
                          ],
                        },
                      ],
                      user: {
                        id: user?.id || '',
                        image: userImage,
                        real_name: user?.displayName || '',
                        display_name: user?.displayName || '',
                        team: user?.team_id || '',
                        name: user?.name || '',
                      },
                      file: [],
                      ...(isEmail && {
                        email: {
                          to: toEmails,
                          cc: ccEmails,
                          bcc: bccEmails,
                        },
                      }),
                    },
                  ],
                ],
              }
            }
            return thread
          })
        )
    }

    const payload: any = {
      ts: ts,
      message: isEmpty(files)
        ? htmlToSlackMD(transformed).replaceAll(/\*_(.*?)_\*/g, '_*$1*_')
        : htmlToSlackMD(transformed).replaceAll(/\*_(.*?)_\*/g, '_*$1*_') +
          '\n' +
          uploadedFiles.map(
            (file: any) => `<${file.permalink} | ${file.name}>`
          ) +
          '\n',
      channel_id: channelId,
      request_mongo_id: _id,
    }

    if (isEmail) {
      payload.emailData = {
        to: toEmails,
        cc: ccEmails || [],
        bcc: bccEmails || [],
      }
    }
    if (isInternal) {
      InternalCommentMutation.mutate(payload)
    } else {
      CommentMutation.mutate(payload)
    }
    clearEmailEditor()
    setValue('')
    setNewData('')
    setFiles([])
    setUploadedFiles([])
  }

  const isSaveButtonDisabled =
    (htmlToSlackMD(newData) === '' && isEmpty(uploadedFiles)) ||
    UploadMutation.isLoading

  const isMac = isMacDevice()

  useKeyboardShortcut(
    ['ctrlKey', '\r'],
    (e) => {
      if (isMac) {
        return
      }
      e.stopPropagation()
      if (!isSaveButtonDisabled) {
        handleSave()
      }
    },
    false
  )

  useKeyboardShortcut(
    ['metaKey', '\r'],
    (e) => {
      if (!isMac) {
        return
      }
      e.stopPropagation()
      if (!isSaveButtonDisabled) {
        handleSave()
      }
    },
    false
  )

  const handleChange = (content: any) => {
    const transformedHTML = createTransformedHTML(content)
    const newContent = transformedHTML.innerHTML
    const regex = /\*_(.*?)_\*/g // Handing both bold and italics
    const data = emoji.replace_colons(
      escapeForSlackWithMarkdown(
        convertHtmlBlockquotesToSlack(
          newContent.replaceAll(/:(.*?):/g, ': $1 :')
        )
      )
        .replaceAll('<br/>', '\n')
        .replaceAll(/:\s*(.*?)\s*:/g, ':$1:')
        .replaceAll(/<p>((?!\n)[\s\S]*?)<\/p>/g, '<p>$1\n</p>')
    )

    setValue(content)
    setNewData(htmlToSlackMD(data).replaceAll(regex, '_*$1*_'))
    setTransformed(data)
  }
  const handleEditorFocus = () => {
    setIsFocused(true)
  }

  const handleEditorBlur = () => {
    setIsFocused(false)
  }

  const onDrop = (acceptedFiles: any) => {
    const validFiles = acceptedFiles.filter((file: any) => {
      const isFileTypeValid = validateFileType(file)
      const isSizeValid = file.size <= 52428800 // 50 MB
      return isFileTypeValid && isSizeValid
    })
    if (validFiles.length !== acceptedFiles.length) {
      const invalidFiles = acceptedFiles.filter(
        (file: any) => file.size > 52428800
      )
      invalidFiles.forEach((file: any) => {
        message.error(`File "${file.name}" exceeds the maximum allowed size.`)
      })
    } else {
      setFiles([...files, ...validFiles])
      const formData = new FormData()
      validFiles.forEach((file: any) => {
        formData.append('files', file)
      })
      UploadMutation.mutate(formData)
      message.loading('Uploading files')
    }
  }

  const removeFile = (file: any, index: number) => {
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setFiles(newFiles)
    DeleteMutation.mutate(uploadedFiles[index].Key)
    const newUploadedFiles = [...uploadedFiles]
    newUploadedFiles.splice(index, 1)
    setUploadedFiles(newUploadedFiles)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const UploadFileTool = useMemo(() => {
    return (
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <Paperclip
          size={16}
          weight="bold"
          className="cursor-pointer hover:text-[#06c] hover:font-bold text-default"
        />
      </div>
    )
  }, [getInputProps, getRootProps])

  useEffect(() => {
    if (!reactQuillRef.current) return
    try {
      const quill = reactQuillRef.current.getEditor()
      const button = document.createElement('button')
      const root = ReactDOM.createRoot(button as HTMLElement)
      root.render(UploadFileTool)
      const toolbar = quill.getModule('toolbar')
      const formatter = toolbar.container.querySelector('.ql-formats')
      formatter.appendChild(button)
    } catch (error) {
      console.error(error)
    }
  }, [])

  return (
    <Wrapper $isInternal={isInternal}>
      <EditorWrapper>
        <ReactQuill
          id="ReactQuill-Editor"
          ref={reactQuillRef}
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          placeholder={
            isInternal
              ? 'Reply to the internal comments...'
              : 'Reply to the thread...'
          }
          className={`relative h-[100px] text-default ${
            isFocused ? 'focused' : ''
          }`}
          onFocus={handleEditorFocus}
          onBlur={handleEditorBlur}
        />
        <div className={`absolute bottom-[-20px] z-50 right-[8px]`}>
          <Tooltip title={isMac ? '⌘ + Enter' : 'Ctrl + Enter'}>
            <StyledButton
              onClick={handleSave}
              disabled={isSaveButtonDisabled}
              icon={
                <PaperPlaneRight weight="fill" color="var(--color-purple-4)" />
              }
            />
          </Tooltip>
        </div>
      </EditorWrapper>

      <div className="mt-[36px] ml-1 mr-3 flex items-center flex-wrap justify-start gap-1 bg-[#F4F4F8] rounded-lg">
        {files.map((file: any, index: number) => (
          <div className="flex items-center gap-[1px] justify-between rounded-[4px] p-1">
            <Text ellipsis={{ tooltip: true }} key={index}>
              {truncate(file?.name, {
                length: 15,
              })}
            </Text>
            <XCircle
              onClick={() => removeFile(file, index)}
              size={20}
              className="cursor-pointer"
              color="#4236A8"
            />
          </div>
        ))}
        {UploadMutation.isLoading && <Spin size="small" />}
      </div>
    </Wrapper>
  )
}
export default RichTextEditor
