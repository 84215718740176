interface Props {
  children?: JSX.Element
  className?: string
}

const FormContainer = ({ children, className }: Props) => {
  return (
    <div
      className={`w-1/2 flex flex-col justify-center items-center bg-white rounded-md drop-shadow-lg border border-brand-border p-10 ${className}`}
    >
      {children}
    </div>
  )
}

export { FormContainer }
