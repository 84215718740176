import { createContext, useContext, useReducer } from 'react'
import { msteamsReducer } from '../reducer/msteamsReducer/msteamsReducer'

const initialState = {}

const MSTeamsContext = createContext<any>(initialState)

export const MSTeamsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(msteamsReducer, initialState)

  return (
    <MSTeamsContext.Provider value={[state, dispatch]}>
      {children}
    </MSTeamsContext.Provider>
  )
}

export const useMSTeams = () => {
  return useContext(MSTeamsContext)
}
