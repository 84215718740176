import { Avatar, Row, Select } from 'antd'
import styled from 'styled-components'
import { AssigneeType } from '../../../../api/assignee'
import { ClusterSMEType, ClusterType } from '../../../../types'

type renderSMESelectProps = {
  sme: ClusterSMEType
  editingRecord?: ClusterType
  record: ClusterType
  isCreateLoading: boolean
  isUpdateLoading: boolean
  assignees: AssigneeType[]
  onChange: (value: any) => void
}

export const renderSMESelect = ({
  sme,
  editingRecord,
  record,
  isCreateLoading,
  isUpdateLoading,
  onChange,
  assignees,
}: renderSMESelectProps) => {
  if (!editingRecord || record._id !== editingRecord._id) {
    return (
      <SMEWrapper>
        <Row
          align="middle"
          style={{
            fontSize: '12px',
            gap: '8px',
          }}
        >
          <Avatar src={sme.image} size={24} />
          {sme.displayName}
        </Row>
      </SMEWrapper>
    )
  }
  return (
    <SMEWrapper>
      <Select
        className="sme-select"
        placeholder="Subject Matter Expert"
        defaultValue={record._id === '' ? null : sme.userId}
        onChange={(value) => value && onChange(value)}
        disabled={isCreateLoading || isUpdateLoading}
      >
        {assignees.map((assignee: AssigneeType) => (
          <Select.Option key={assignee.id} value={assignee._id}>
            <Row
              align="middle"
              style={{
                fontSize: '12px',
                gap: '8px',
              }}
            >
              <Avatar src={assignee.image} size={16} />
              {assignee.name}
            </Row>
          </Select.Option>
        ))}
      </Select>
    </SMEWrapper>
  )
}

const SMEWrapper = styled.div`
  .sme-select {
    width: 100%;
    .ant-select-arrow {
      display: none;
    }
  }
`
