import { Progress } from 'antd'

type IProps = {
  maxLength: number
  charactersLeft: number
}

const WordLimit = ({ maxLength, charactersLeft }: IProps) => {
  const percentage = ((maxLength - charactersLeft) / maxLength) * 100

  const progressBarColor =
    charactersLeft <= 15 && charactersLeft > 0 ? 'var(--color-orange-1)' : ''

  return (
    <div className="word-limit-wrapper w-[100%]">
      <Progress
        type="circle"
        percent={percentage}
        size="small"
        width={20}
        format={() => `${charactersLeft}`}
        strokeColor={progressBarColor}
        strokeWidth={charactersLeft <= 0 ? 8 : 10}
        status={charactersLeft <= 0 ? 'exception' : 'active'}
        showInfo={charactersLeft.toString().length < 3}
        className="font-bold"
      />
    </div>
  )
}

export default WordLimit
