import { create } from 'zustand'
import { devtools, redux } from 'zustand/middleware'
import { blocks as defaultBlocks } from '../modules/MarketingAutomation/block'

type MaEditorActionType = {
  type:
    | 'SET_BLOCK_JSON'
    | 'SET_SHOW_INPUT'
    | 'SET_SEND_AS_USER'
    | 'SET_YOUR_APP_NAME'
    | 'SET_CAMPAIGN_NAME'
    | 'LOAD_INITIAL_DATA'
    | 'SET_SHOW_APP_INPUT'
    | 'SET_SEND_AS_USERs_ID'
    | 'SET_SHOW_FOCUS_ON_EDITOR'
    | 'SET_FIRST_EDITOR_BLOCK_ID'
    | 'SET_ALT_TEXT'
    | 'SET_VIDEO_URL'
    | 'SET_IMAGE_URL'
    | 'SET_LINK_HREF'
    | 'SET_BUTTON_URL'
    | 'SET_VIDEO_ALT_TEXT'
    | 'SET_IMAGE_TITLE'
    | 'SET_VIDEO_TITLE'
    | 'SET_BUTTON_TEXT'
    | 'SET_THUMBNAIL_URL'
    | 'SET_EDITOR_LOADER'
    | 'INIT'
    | 'SET_SAVING_LOADER'
    | 'SET_SAVING_FAILED'
    | 'SET_USER_ACTION'
  payload?: any
}

type BlockType = {
  title?: any
  type: string
  url?: string
  elements?: any
  accessory?: any
  block_id: string
  text: {
    type: string
    text: string
  }
}

type ModalStateType = {
  altText: string
  videoUrl: string
  imageUrl: string
  linkHref: string
  buttonUrl: string
  imageTitle: string
  videoTitle: string
  buttonText: string
  thumbnailUrl: string
  videoAltText: string
}

type MaEditorStateType = {
  showInput: boolean
  showAppInput: boolean
  showFocusOnEditor: boolean
  firstEditorBlockId: null | string
  modalState: ModalStateType
  editorLoader: boolean
  initialState: any
  savingLoader: boolean
  savingFailed: boolean
  userAction: boolean
}

const maEditorInitialState: MaEditorStateType = {
  showInput: false,
  showAppInput: false,
  firstEditorBlockId: null,
  showFocusOnEditor: false,
  initialState: {
    blocksJSON: defaultBlocks,
    sendAsUser: false,
    sendUser: undefined,
    yourApp: '',
    campaignName: 'Untitled Template',
  },
  modalState: {
    altText: '',
    videoUrl: '',
    linkHref: '',
    imageUrl: '',
    buttonUrl: '',
    imageTitle: '',
    videoTitle: '',
    buttonText: '',
    thumbnailUrl: '',
    videoAltText: '',
  },
  editorLoader: true,
  savingLoader: false,
  savingFailed: false,
  userAction: false,
}

const maEditorReducer = (
  state: MaEditorStateType,
  action: MaEditorActionType
): MaEditorStateType => {
  const { type, payload } = action

  switch (type) {
    case 'INIT':
      return { ...state, initialState: action.payload }

    case 'SET_FIRST_EDITOR_BLOCK_ID':
      return { ...state, firstEditorBlockId: payload.firstEditorBlockId }

    case 'SET_SHOW_FOCUS_ON_EDITOR':
      return { ...state, showFocusOnEditor: payload.showFocusOnEditor }

    case 'SET_SEND_AS_USER':
      return {
        ...state,
        initialState: {
          ...state.initialState,
          yourApp: action.payload.yourApp,
          sendUser: action.payload.sendUser,
          sendAsUser: action.payload.sendAsUser,
        },
      }

    case 'SET_SEND_AS_USERs_ID':
      return {
        ...state,
        initialState: {
          ...state.initialState,
          sendUser: action.payload.sendUser,
        },
      }

    case 'SET_YOUR_APP_NAME':
      return {
        ...state,
        initialState: {
          ...state.initialState,
          yourApp: action.payload.yourApp,
        },
      }

    case 'SET_CAMPAIGN_NAME':
      return {
        ...state,
        initialState: {
          ...state.initialState,
          campaignName: action.payload.campaignName,
        },
      }

    case 'SET_BLOCK_JSON':
      return {
        ...state,
        initialState: {
          ...state.initialState,
          blocksJSON: action.payload.blocksJSON,
        },
      }

    case 'SET_SHOW_INPUT':
      return {
        ...state,
        showInput: action.payload.showInput,
      }

    case 'SET_SHOW_APP_INPUT':
      return {
        ...state,
        showAppInput: action.payload.showAppInput,
      }

    case 'SET_ALT_TEXT':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          altText: action.payload.altText,
        },
      }

    case 'SET_VIDEO_ALT_TEXT':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          videoAltText: action.payload.videoAltText,
        },
      }

    case 'SET_VIDEO_URL':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          videoUrl: action.payload.videoUrl,
        },
      }

    case 'SET_BUTTON_URL':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          buttonUrl: action.payload.buttonUrl,
        },
      }

    case 'SET_BUTTON_TEXT':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          buttonText: action.payload.buttonText,
        },
      }

    case 'SET_THUMBNAIL_URL':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          thumbnailUrl: action.payload.thumbnailUrl,
        },
      }
    case 'SET_VIDEO_TITLE':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          videoTitle: action.payload.videoTitle,
        },
      }
    case 'SET_IMAGE_TITLE':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          imageTitle: action.payload.imageTitle,
        },
      }

    case 'SET_LINK_HREF':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          linkHref: action.payload.linkHref,
        },
      }
    case 'SET_IMAGE_URL':
      return {
        ...state,
        modalState: {
          ...state.modalState,
          imageUrl: action.payload.imageUrl,
        },
      }

    case 'SET_EDITOR_LOADER':
      return { ...state, editorLoader: payload.editorLoader }
    case 'SET_SAVING_LOADER':
      return { ...state, savingLoader: payload.savingLoader }
    case 'SET_SAVING_FAILED':
      return { ...state, savingFailed: payload.savingFailed }
    case 'SET_USER_ACTION':
      return { ...state, userAction: payload.userAction }
    default:
      return state
  }
}

const useMaEditorStore = create(
  devtools(redux(maEditorReducer, maEditorInitialState), {
    name: 'useMaEditorStore',
  })
)

export { useMaEditorStore }
