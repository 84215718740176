import { Button, Card, Divider } from 'antd'
import { IntegrationModalHeader } from '../../../components'
import SlackSvg from '../../../images/logos/slack.svg'
import { RequestDirect } from './RequestRedirect'
import { useSlack } from './SlackProvider'

export const SlackIntegration = () => {
  const { saveRedirectionMutation } = useSlack()

  return (
    <main className="w-full">
      <IntegrationModalHeader
        imgSrc={SlackSvg}
        imageClassName="h-10 w-14"
        title="Slack Redirection"
        description="Redirect all requests based on the type to relevant internal channels."
      />
      <Divider className="my-5 py-0" />
      <RequestDirect />
      {/* Pro Tip */}
      <Card
        className="bg-[#F7F8FC] mt-3 border-[#BDC7E0]"
        bodyStyle={{
          paddingBottom: 20,
          paddingTop: 14,
          paddingLeft: 17,
          paddingRight: 8,
        }}
      >
        <div className="flex">
          <div className="text-[#2156DD] font-bold text-sm mr-2">Pro-tip:</div>
          <div className="text-sm 3xl:text-[length:var(--sm)]">
            Best used when teams have a dedicated channel for addressing
            questions. <br />
            Eg: #internal-billing-request channel could be used for all billing
            requests.
          </div>
        </div>
      </Card>
      <div className="flex flex-row-reverse mt-10 mb-6">
        <Button
          loading={saveRedirectionMutation?.isLoading}
          className="text-primary border-primary/40 font-bold px-8"
          size="large"
        >
          Done
        </Button>
      </div>
    </main>
  )
}
