import { Card, Divider, Spin, Typography } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { lazy, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { ConnectedAppProvider } from '.'
import {
  AuthenticatedConnectUser,
  IntegrationUninstallEvent,
} from '../../../paragon'
import {
  disableParagonIntegration,
  fetchIntegrations,
} from '../../api/integrations'
import { IntegrationCard, ParagonIntegrationCard } from '../../components'
import WorkspaceSelector from '../../components/WorkspaceSelector'
import { useParagonStore } from '../../store/paragonStore'
import { useZendeskStore } from '../../store/zendeskStore'
import { IntegrationModal } from './IntegrationModal'
import { IntegrationsList } from './definitions'
const { Text } = Typography

const ZendeskIntegration = lazy(() => import('../ZendeskIntegration/Landing'))

export const ConnectedApps = () => {
  const queryClient = useQueryClient()
  const [showConnectedApps, setShowConnectedApps] = useState<boolean>(false)
  const [showParagonConnectedApp, setShowParagonConnectedApp] =
    useState<boolean>(false)

  const paragonData = useParagonStore((state) => state)

  const paragonInstance = useParagonStore((state) => state?.paragon)

  const deleteParagonIntegration = useMutation(disableParagonIntegration)

  useEffect(() => {
    const handler = (
      event: IntegrationUninstallEvent,
      user: AuthenticatedConnectUser
    ) => {
      deleteParagonIntegration.mutate(
        // Disabling this because line 54 checks for existance of paragonData.user?.userId
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        { event, user, teamid: paragonData?.paragonUser?.userId! },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('integrations')
          },
        }
      )
    }

    if (paragonInstance && paragonData?.paragonUser?.userId) {
      paragonInstance.subscribe('onIntegrationUninstall', handler)
    }

    return () => {
      paragonInstance?.unsubscribe('onIntegrationUninstall', handler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragonInstance, paragonData?.paragonUser])

  const { paragon, paragonUser, loading } = paragonData || {}

  const flags = useFlags()
  const { data: integrations, isLoading } = useQuery(
    ['integrations'],
    fetchIntegrations,
    {
      select: (data) => {
        const integrationsObjectById = (data || []).reduce(
          (acc: any, intg: any) => ({ ...acc, [intg.id]: intg }),
          {}
        )
        return IntegrationsList.map((i) => {
          if (integrationsObjectById[i.id]) {
            if (
              !showConnectedApps &&
              integrationsObjectById[i.id]?.hasConnected
            )
              setShowConnectedApps(true)
            return { ...i, ...integrationsObjectById[i.id] }
          }
          return {
            ...i,
            configured: false,
            hasConnected: false,
            showSwitch: false,
            showDelete: false,
            requestTypes: [],
          }
        })
      },
    }
  )

  const dispatch = useZendeskStore((state) => state.dispatch)
  const updateZendeskStore = (key: string) => {
    dispatch({
      type: 'SET_MODAL_STATE',
      payload: {
        modalState: {
          isOpen: true,
          type: 'ALL_INTEGRATIONS',
          title: 'Zendesk Integration Setup',
        },
      },
    })
    dispatch({
      type: 'SET_ACTIVE_TAB',
      payload: {
        activeTab: key,
      },
    })
  }

  useEffect(() => {
    if (flags.externalTicketingZendesk) {
      const currentUrl = window.location.href
      const searchParams = new URLSearchParams(new URL(currentUrl).search)
      const activeKey = searchParams.get('zendesk-integration-tab')
      if (activeKey) {
        updateZendeskStore(activeKey)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags.externalTicketingZendesk])

  useEffect(() => {
    if (paragonUser) {
      const isEnabled = Object.values(paragonUser?.integrations || {}).some(
        (item: any) => item?.enabled
      )
      isEnabled
        ? setShowParagonConnectedApp(true)
        : setShowParagonConnectedApp(false)
    }
  }, [paragonUser])

  useEffect(() => {
    integrations?.some((ele) =>
      ele.hasConnected === true
        ? setShowConnectedApps(true)
        : setShowConnectedApps(false)
    )
  }, [integrations])

  // Loader
  if (isLoading)
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Spin size="large" />
      </div>
    )
  // Connected apps

  if (showConnectedApps || showParagonConnectedApp) {
    return (
      <>
        {flags.externalTicketingZendesk ? <ZendeskIntegration /> : null}
        <div className="flex items-center justify-between">
          <h4 className="font-bold text-lg 3xl:text-[length:var(--lg)]">
            Connected Apps
          </h4>
          <WorkspaceSelector />
        </div>
        <Text className="text-gray-dark font-medium text-sm 3xl:text-[length:var(--sm)]">
          Active Integrations in your workspace. Customer tickets will flow to
          an active integration basis configuration
        </Text>
        <div className="mt-3 py-2 flex items-center justify-start flex-wrap gap-5">
          {integrations
            ?.filter(({ hasConnected }) => hasConnected)
            .map((integration) => (
              <IntegrationCard
                key={integration.id}
                {...integration}
                inConnectedApps
              />
            ))}
          {paragon &&
            paragon?.getIntegrationMetadata &&
            paragon?.getIntegrationMetadata().map((integration: any) => {
              const integrationEnabled =
                paragonUser?.integrations?.[integration.type]?.enabled
              return (
                integrationEnabled && (
                  <ParagonIntegrationCard
                    integration={integration}
                    integrationEnabled={integrationEnabled}
                    loading={loading}
                  />
                )
              )
            })}
        </div>
        <div id={showConnectedApps ? '1' : '0'}></div>
        <h4 className="mt-8 font-bold text-lg 3xl:text-[length:var(--lg)]">
          Add more apps
        </h4>
        <Text className="text-gray-dark font-medium text-sm 3xl:text-[length:var(--sm)]">
          Integrations that will automate your customer interactions from Slack
          and your email ticketing & CRM tools
        </Text>
        <div className="mt-3 py-2 flex items-center justify-start flex-wrap gap-5">
          {paragon &&
            paragon.getIntegrationMetadata &&
            paragon.getIntegrationMetadata().map((integration: any) => {
              const integrationEnabled =
                paragonUser?.integrations?.[integration.type]?.enabled
              return (
                !integrationEnabled &&
                (integration.type != 'hubspot' ||
                  (integration.type == 'hubspot' && flags.hubSpotCrm)) && (
                  <ParagonIntegrationCard
                    integration={integration}
                    integrationEnabled={integrationEnabled}
                    loading={loading}
                  />
                )
              )
            })}
          {integrations
            ?.filter(({ hasConnected }) => !hasConnected)
            .map((integration) => (
              <IntegrationCard key={integration.id} {...integration} />
            ))}
        </div>
        <IntegrationModal integrations={integrations} />
      </>
    )
  }

  // All Integrations
  return (
    <ConnectedAppProvider>
      {flags.externalTicketingZendesk ? <ZendeskIntegration /> : null}
      <Card className="p-6">
        {/* Top Section */}
        <div className="flex gap-2 items-baseline justify-between">
          <h4 className="font-bold text-lg 3xl:text-[length:var(--lg)]">
            Connect Apps
          </h4>
          <WorkspaceSelector />
        </div>
        <div id={showConnectedApps ? '1' : '0'}></div>
        <p className="text-sm 3xl:text-[length:var(--sm)]">
          All replies in an open ticket thread in Slack will be sent to the
          integration. Responses from the integration will be white-labeled.
        </p>
        <p className="bg-label-default w-fit">
          <span className="font-bold text-sm 3xl:text-[length:var(--sm)]">
            Tip:
          </span>{' '}
          Integration setup works best when you are the admin of that tool.
        </p>
        <Divider className="py-0 my-0" />
        <div className="mt-5 py-2 flex items-center justify-start flex-wrap gap-5">
          {paragon &&
            paragon.getIntegrationMetadata &&
            paragon?.getIntegrationMetadata()?.map((integration: any) => {
              const integrationEnabled =
                paragonUser?.integrations?.[integration.type]?.enabled
              return (
                !integrationEnabled &&
                (integration.type != 'hubspot' ||
                  (integration.type == 'hubspot' && flags.hubSpotCrm)) && (
                  <ParagonIntegrationCard
                    integration={integration}
                    integrationEnabled={integrationEnabled}
                    loading={loading}
                  />
                )
              )
            })}
          {integrations?.map((integration) => (
            <IntegrationCard key={integration.id} {...integration} />
          ))}
        </div>
      </Card>
      <IntegrationModal integrations={integrations} />
    </ConnectedAppProvider>
  )
}
