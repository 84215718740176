import { Button } from 'antd'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import AIIconSVG from '../../../images/icons/ai-icon-white.svg'
import { useAccountsStore } from '../../../store/accountsStore'

const Wrapper = styled.div<{ $hide: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-purple-4);
  color: var(--color-white);
  padding: 0 12px;
  img {
    margin-right: 6px;
  }
  overflow: hidden;
  transition: height 0.4s ease-in-out 0.5s;
  ${({ $hide }) => {
    if ($hide) {
      return css`
        height: 0;
      `
    }
  }}
`

const Block = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ConfirmCTA = styled(Button)<{ $startTransition: boolean }>`
  border: 0;
  height: 24px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 6px;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  color: var(--color-purple-6);
  transition: opacity 0.3s ease-in-out;
  ${({ $startTransition }) => {
    if ($startTransition) {
      return css`
        opacity: 0;
      `
    }
  }}
`

const StyledButton = styled(Button)<{ $startTransition: boolean }>`
  padding: 0;
  width: 16px;
  height: 16px;
  border: none;
  position: relative;
  background-color: transparent;
  :hover {
    background-color: transparent;
  }
  :focus {
    background-color: transparent;
  }
  .cross,
  .tick {
    top: 0;
    left: 0;
    position: absolute;
    transition: opacity 0.3s ease-in-out;
  }
  .tick {
    opacity: 0;
  }
  ${({ $startTransition }) => {
    if ($startTransition) {
      return css`
        .cross {
          opacity: 0;
        }
        .tick {
          opacity: 1;
        }
      `
    }
  }}
`

const AIConsent = ({ collapsedSidenav }: { collapsedSidenav: boolean }) => {
  const [mount, setMount] = useState(true)
  const dispatch = useAccountsStore((state) => state.dispatch)
  const aiConsent = useAccountsStore((state) => state.aiConsent)

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null
    if (aiConsent) {
      timerId = setTimeout(() => setMount(false), 750)
    }
    return () => {
      if (timerId) clearTimeout(timerId)
    }
  }, [aiConsent])

  if (!mount) {
    return null
  }

  const setIsConfirmed = (bool: boolean) => {
    dispatch({ type: 'SET_AI_CONSENT', payload: { aiConsent: bool } })
  }

  const handleConfirm = () => {
    setIsConfirmed(true)
  }

  const handleClose = () => {
    if (!aiConsent) {
      setMount(false)
    }
  }

  return (
    <Wrapper
      $hide={aiConsent}
      id={'ai-banner'}
      className={`w-fit-content ${
        collapsedSidenav ? 'ml-[80px]' : 'ml-[240px]'
      } transition-all duration-500 ease-in-out`}
    >
      <Block>
        <img src={AIIconSVG} alt="ai" width={20} height={20} />
        <div>
          By using this feature, you agree that OpenAI is a subprocessor and may
          process the data that you’ve choosen to submit.
        </div>
      </Block>
      <Block style={{ flexShrink: 0 }}>
        <ConfirmCTA $startTransition={aiConsent} onClick={handleConfirm}>
          Confirm
        </ConfirmCTA>
        <StyledButton $startTransition={aiConsent} onClick={handleClose}>
          <svg
            className="cross"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 4L4 12M12 12L4 4"
              stroke="#ffffff"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            className="tick"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2,8 L6,12 L14,4"
              stroke="#ffffff"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </StyledButton>
      </Block>
    </Wrapper>
  )
}

export default AIConsent
