import { AdditionalFieldType } from './types'

export const DEFAULT_FIELDS: AdditionalFieldType[] = [
  {
    label: 'Title',
    type: 'TEXT',
    key: '1001',
    mandatory: true,
    options: [],
    defaultValue: '',
    hidden: false,
    enabled: true,
    sortOrder: 0,
    zendeskFieldId: 1,
  },
  {
    label: 'Description',
    type: 'RICHTEXT',
    key: '1002',
    mandatory: true,
    options: [],
    defaultValue: '',
    hidden: false,
    enabled: true,
    sortOrder: 1,
    zendeskFieldId: 1,
  },
  {
    label: 'Urgency',
    type: 'RADIO',
    key: '1003',
    mandatory: true,
    options: [
      {
        text: '🧨 High',
        value: 'High',
      },
      {
        text: '🧯 Medium',
        value: 'Medium',
      },
      {
        text: '🧑‍🚒 Low',
        value: 'Low',
      },
    ],
    defaultValue: '',
    hidden: false,
    enabled: true,
    sortOrder: 2,
    zendeskFieldId: 1,
  },
  {
    label: 'Tags',
    type: 'MULTI_SELECT',
    key: '1004',
    mandatory: false,
    options: [
      {
        text: 'Customer Onboarding',
        value: 'Customer Onboarding',
      },
    ],
    defaultValue: [],
    hidden: false,
    enabled: true,
    sortOrder: 3,
    zendeskFieldId: 1,
    placeholder: '(Optional) you can select multiple tags',
  },
]
