import { DatePicker } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import styled, { css } from 'styled-components'
import DateRangeSvg from '../../../images/icons/date-range-filter.svg'
import { RangeValue } from '../../../types/accountsTypes'
import { FilterChip } from './Common'
const { RangePicker } = DatePicker

const Wrapper = styled.div<{ $isOpen: boolean }>`
  .RangePicker {
    height: 22px;
    width: 236px;
    border: none;
    outline: none;
    box-shadow: none;
  }
  ${({ $isOpen }) => {
    if ($isOpen) {
      return css`
        .filter-chip-container {
          padding-right: 0;
        }
      `
    }
    return css`
      .filter-chip-container {
        padding-right: 8px;
      }
    `
  }}
`

type IProps = {
  label: string
  className?: string
  dateRange: moment.Moment[] | null
  setDateRange: (value: RangeValue<moment.Moment> | moment.Moment[]) => void
}

const dateFormat = 'MM-DD-YYYY'

const initialRange = [
  moment().subtract(1, 'M'),
  moment(),
] as RangeValue<moment.Moment>

// const currentMonth = moment().startOf('month')
// const nextMonth = moment().add(1, 'month').startOf('month')
// let initialRange: any = [currentMonth, nextMonth] as RangeValue<moment.Moment>
// initialRange = initialRange.map((date: any) => date.format(dateFormat))

const DateRange = ({ className, label, dateRange, setDateRange }: IProps) => {
  const [openPanel, setOpenPanel] = useState(false)

  let range = null
  if (dateRange) {
    range = moment.isMoment(dateRange[0])
      ? dateRange
      : [moment(dateRange[0]), moment(dateRange[1])]
  }

  const onFilterClick = () => {
    setOpenPanel(true)
    if (!dateRange) {
      setDateRange(initialRange)
    }
  }

  return (
    <Wrapper className={className} $isOpen={!!dateRange}>
      <FilterChip
        label={label}
        iconAlt="date-range"
        iconSrc={DateRangeSvg}
        onClick={onFilterClick}
        className="filter-chip-container"
        content={
          dateRange ? (
            <RangePicker
              open={openPanel}
              format={dateFormat}
              className="RangePicker"
              onChange={setDateRange}
              onOpenChange={setOpenPanel}
              value={range as RangeValue<moment.Moment>}
              onCalendarChange={(e, _, info) => {
                if (info.range === 'end') {
                  setOpenPanel(false)
                }
              }}
            />
          ) : null
        }
      />
    </Wrapper>
  )
}

export default DateRange
