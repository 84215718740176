import { Popover } from 'antd'
import styled from 'styled-components'
import { noOp } from '../../../../utils/empty'
import { FilterChip } from './Common'

type BulkEditMenuProps = {
  label: string
  iconAlt: string
  iconSrc: string
  className?: string
  content?: React.ReactNode
  setOpen: (isOpen: boolean) => void
  isOpen: boolean
}

const BulkEditMenu = ({
  label,
  iconAlt,
  iconSrc,
  className,
  content,
  isOpen,
  setOpen,
}: BulkEditMenuProps) => {
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  return (
    <Wrapper className={className}>
      <Popover
        trigger="click"
        showArrow={false}
        placement="bottomLeft"
        content={content}
        open={isOpen}
        onOpenChange={handleOpenChange}
      >
        <FilterChip
          label={label}
          onClick={noOp}
          iconAlt={iconAlt}
          iconSrc={iconSrc}
        />
      </Popover>
    </Wrapper>
  )
}

export default BulkEditMenu

const Wrapper = styled.div``
