import { Select, SelectProps } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

type CustomSelectProps = SelectProps & {
  prefixIcon?: string
  prefixText?: string
}

const SelectWrapper = styled.div<{ padding: string }>`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    min-width: 7rem;
    padding: 2px 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(${({ padding }) => padding} - 0px);
  }

  .ant-select-selection-overflow-item {
    margin-left: -6px;
  }
`
const CustomSelect = ({
  prefixIcon,
  prefixText,
  children,
  placeholder,
  ...rest
}: CustomSelectProps) => {
  const [open, setOpen] = useState(false)
  return (
    <SelectWrapper
      padding={
        prefixText === 'Tags:' || placeholder === 'Type:' ? '5rem' : '5rem'
      }
    >
      {prefixIcon && (
        <div
          className="prefix-icon-wrapper"
          onClick={() => setOpen((old: any) => !old)}
          style={{ cursor: 'Pointer' }}
        >
          {/* {prefixIcon} */}
          <img src={prefixIcon} alt="icon" />
          <label className="ml-1">{prefixText}</label>
        </div>
      )}
      <Select
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        placeholder={placeholder}
        {...rest}
      >
        {children}
      </Select>
    </SelectWrapper>
  )
}

export default CustomSelect
