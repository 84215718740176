import Dexie from 'dexie'

export const moveCursorToEnd = (element: HTMLElement) => {
  const range = document.createRange()
  range.selectNodeContents(element)
  range.collapse(false)
  const selection = window.getSelection()
  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

export const focusEditor = (element: HTMLElement) => {
  moveCursorToEnd(element)
  element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  element.focus()
}

export const isMacDevice = () => navigator.userAgent.indexOf('Mac') > -1
const keysToRetain = [
  'requestDefaultView',
  'ticketDefaultView',
  'internalRequestDefaultView',
  'internalTicketDefaultView',
]

// Function to clear localStorage except for specified keys
function clearLocalStorageExcept(keysToRetain: string[]) {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i) ?? ''
    if (!keysToRetain.includes(key)) {
      localStorage.removeItem(key)
    }
  }
}
export const clearBrowserStorage = async (isWebUser?: boolean) => {
  window.localStorage.clear()
  await Promise.all([
    Dexie.delete('AccountDB'),
    Dexie.delete('accountDrawerDB'),
    Dexie.delete('kanbanBoardDB'),
    Dexie.delete('kanbanDrawerDB'),
    Dexie.delete('AnalyticsDB'),
    Dexie.delete('CustomStatusesDB'),
  ])
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
