/* eslint-disable no-console */
import React, { useContext, useEffect, useRef, useState } from 'react'
import socketClient, { Socket } from 'socket.io-client'
import { SOCKET_URI } from '../config'

interface SocketContextType {
  socket: Socket | null
  time: number
}

const SocketContext = React.createContext<SocketContextType>({
  socket: null,
  time: 0,
})

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const socketRef = useRef<any>()
  const [time, setTime] = useState(0)

  useEffect(() => {
    socketRef.current = socketClient(SOCKET_URI, {
      transports: ['websocket'],
      upgrade: false,
      auth: {
        token: window.localStorage.getItem('token'),
      },
      reconnectionDelay: 1000 * 2,
      reconnectionAttempts: Infinity,
    })

    console.log('Attempting to connect to socket...')

    if (socketRef.current) {
      socketRef.current.on('connect', () => {
        console.log(`Socket connection established!`)
      })

      socketRef.current.on('disconnect', (reason: string) => {
        console.log('Socket disconnected! Reason:', reason)
        console.log(
          'Auto reconnecting every 2 seconds. Max attempts: Infinity. Max timeout: 20 seconds'
        )
      })

      socketRef.current.on('test', (dt: any) => {
        const start = new Date().getTime()
        setTime(start)
        console.log(dt)
      })

      socketRef.current.on('close', (e: any) => {
        console.log('Connection closed: ', e)
      })

      socketRef.current.on('connect_error', (e: any) => {
        console.log('Error connecting socket: ', e)
      })
    } else {
      console.log('Socket not connected.')
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect()
      }
    }
  }, [])

  return (
    <SocketContext.Provider value={{ socket: socketRef.current, time }}>
      {children}
    </SocketContext.Provider>
  )
}

export const useSocket = () => {
  return useContext(SocketContext)
}
