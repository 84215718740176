import { api } from '.'
import {
  AdditonalFieldType,
  CRMValuesType,
  RelationshipType,
} from '../types/accountsTypes'

export const fetchRelationships = async (): Promise<RelationshipType[]> => {
  const { data } = await api.get('/v1/relationship')
  return data
}

export const fetchAccountsFromCRM = async (
  integrationType: undefined | string,
  relationshipId: string | undefined
): Promise<any> => {
  const { data } = await api.post('/v1/relationship/fetchAccountsDataFromCRM', {
    integrationType,
    relationshipId,
  })
  return data
}

type FetchCRMResponse = {
  additional_fields: AdditonalFieldType[]
  summariser: { timezone: string }
  fetchAccountsWorkflowMap: Record<string, string>
}

export const fetchCRMConfig = async (): Promise<FetchCRMResponse> => {
  const { data } = await api.get('/v1/relationship/crm/config')
  return data
}

export const updateCRMValuesOnRelation = async (
  relationId: string,
  crmValues: Partial<CRMValuesType>
): Promise<Partial<CRMValuesType>> => {
  const data = api.post(`/v1/relationship/${relationId}/crm`, {
    ...crmValues,
  })
  return data as Promise<Partial<CRMValuesType>>
}
export const updateAllCRMValuesOnRelation = ({
  relationId,
  crmValues,
}: {
  relationId: string
  crmValues: Partial<CRMValuesType>
}) => {
  return api
    .post(`/v1/relationship/${relationId}/crm`, {
      ...crmValues,
    })
    .then((data) => data)
}

export const fetchSummariseDetails = (relationId: string) =>
  api
    .post(`/v1/relationship/summarize/${relationId}`)
    .then(({ data }) => data.data)

export const addCustomFields = (
  properties: string[],
  mappedDefaultFields: Record<string, any>
) =>
  api
    .post(`/v1/add/crmProperties/`, {
      properties,
      mappedDefaultFields,
    })
    .then(({ data }) => data.data)

export const removeCustomFields = (properties: string[]) =>
  api
    .post(`/v1/remove/crmProperties/`, {
      properties,
    })
    .then(({ data }) => data.data)

type CRMConfigType = {
  additional_fields: any
  isDelete?: boolean
}
export const addCRMConfig = (data: CRMConfigType) =>
  api.post(`/v1/relationship/crm/config`, data).then((data) => data.data)

export const updateCRMConfig = (data: CRMConfigType) =>
  api.patch(`/v1/relationship/crm/config`, data).then((data) => data.data)

export const deleteCRMConfig = (data: CRMConfigType) =>
  api.patch(`/v1/relationship/crm/config`, data).then((data) => data.data)

export const fetchTaskHistory = ({
  data,
}: {
  data: { startTime: string; endTime?: string }
}) => api.post(`/v2/automation-logs`, data).then((data) => data.data.data.tasks)
/**
 * TODO:
 * updateCRMConfig -> POST /v1/relationship/crm/config
 */
