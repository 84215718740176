import { api } from '.'
import {
  AuthenticatedConnectUser,
  IntegrationUninstallEvent,
} from '../../paragon'
import {
  ConnectorConfigurationPayloadType,
  FreshdeskTestConnectionPayloadType,
  IntercomIntegrationType,
  SalesforceTestConnectionPayloadType,
  SaveHubspotAccountChannelMappingPayloadType,
  SaveSalesforceAccountChannelMappingPayloadType,
  SlackRedirectionPayloadType,
  ZendeskRequestTypesPayloadType,
  ZendeskTestConnectionPayloadType,
} from '../modules/integrations/definitions'

// * Salesforce integration
// Test Connection
export const testSalesforceConnection = (
  data: SalesforceTestConnectionPayloadType
) =>
  api
    .post('/v1/integration/sfdc/test-connection', { ...data })
    .then(({ data: dt }) => dt)

// Salesforce configuration
export const fetchSalesforceIntegration = () =>
  api.get('/v1/integration/sfdc').then(({ data: { data: dt } }) => dt)

// Salesforce Accounts
export const fetchSalesforceAccounts = (search: string) =>
  api
    .get('/v1/integration/sfdc/accounts', { params: { search } })
    .then(({ data: { data: dt } }) => dt)

// Saving Salesforce Account Mapping with Channel
export const saveSalesforceAccounts = (
  payload: SaveSalesforceAccountChannelMappingPayloadType
) =>
  api
    .post('/v1/integration/sfdc', { ...payload })
    .then(({ data: { data: dt } }) => dt)

// Intercom Integration
export const saveIntercomIntegrationConfig = (
  payload: IntercomIntegrationType
) =>
  api
    .post('/v1/integration/intercom', { ...payload })
    .then(({ data: { data: dt } }) => dt)

export const getIntercomIntercomIntegrationConfig = () =>
  api.get('/v1/integration/intercom').then(({ data: { data: dt } }) => dt)

// * Freshdesk Integration
export const testFreshdeskConnection = (
  data: FreshdeskTestConnectionPayloadType
) =>
  api
    .post('/v1/integration/freshdesk/test-connection', { ...data })
    .then(({ data: dt }) => dt)

// Zendesk configuration
export const fetchFreshdeskIntegration = () =>
  api.get('/v1/integration/freshdesk').then(({ data: { data: dt } }) => dt)

// * Zendesk Integration
export const testZendeskConnection = (data: ZendeskTestConnectionPayloadType) =>
  api
    .post('/v1/integration/zendesk/test-connection', { ...data })
    .then(({ data: dt }) => dt)

export const saveBaseUrl = (data: { accountName: string; baseurl: string }) =>
  api
    .post('/v1/integration/zendesk/save-base-url', data)
    .then(({ data: dt }) => dt)

export const saveZendeskRequestType = (data: ZendeskRequestTypesPayloadType) =>
  // export const testZendeskConnection = (data: ZendeskRequestTypesPayloadType) =>
  api
    .post('/v1/integration/zendesk/save-request-types', { ...data })
    .then(({ data: dt }) => dt)

// Zendesk configuration
export const fetchZendeskIntegration = () =>
  api.get('/v1/integration/zendesk').then(({ data: { data: dt } }) => dt)

export const reSyncAdditionFields = () =>
  api
    .get('/v1/integration/zendesk/custom-field-sync')
    .then(({ data: { data: dt } }) => dt)

// Other Connector Configurations
export const fetchConnectorConfiguration = () =>
  api.get('/v1/configure/connector').then(({ data: { data: dt } }) => dt)

export const saveConnectorConfiguration = (
  data: ConnectorConfigurationPayloadType
) => api.post('/v1/configure/connector', { ...data }).then(({ data: dt }) => dt)

// * Internal Slack integration
export const fetchSlackRedirection = () =>
  api.get('/v1/integration/slack').then(({ data: { data: dt } }) => dt)

export const saveSlackRedirection = (data: SlackRedirectionPayloadType) => {
  return api
    .patch('/v1/integration/slack', { ...data })
    .then(({ data: { data: dt } }) => dt)
}

// * Notion Integration

// * Hubspot integration
// Hubspot Account Properties
export const fetchHubspotAccountsProperties = () =>
  api
    .get('/v1/integration/hubspot/account-properties')
    .then(({ data: { data: dt } }) => dt)

// Hubspot Account Field Mapping with Thena
export const fetchHubspotAccountsFieldMapping = () =>
  api
    .get('/v1/integration/hubspot/field-mapping')
    .then(({ data: { data: dt } }) => dt)

// Saving Hubspot field mapping
export const saveHubspotAccountsFieldMapping = (data: {
  fieldMapping: { [key: string]: string | null }
}) =>
  api
    .post('/v1/integration/hubspot/field-mapping', data)
    .then(({ data: { data: dt } }) => dt)

// Hubspot Accounts
export const fetchHubspotAccounts = (search: string, lifecycleStage?: string) =>
  api
    .get('/v1/integration/hubspot/accounts', {
      params: { search, lifecycleStage },
    })
    .then(({ data: { data: dt } }) => dt)

// Saving Salesforce Account Mapping with Channel
export const saveHubspotAccounts = (
  payload: SaveHubspotAccountChannelMappingPayloadType
) =>
  api
    .post('/v1/integration/hubspot', { ...payload })
    .then(({ data: { data: dt } }) => dt)

// * Integration List
export const fetchIntegrationChannels = ({
  page = 0,
  search = '',
  integration,
}: {
  page?: number
  search?: string
  integration:
    | 'slack'
    | 'notion'
    | 'zendesk'
    | 'salesforce'
    | 'hubspot'
    | 'freshdesk'
    | 'shortcut'
}) =>
  api
    .get(`/v1/integrations/${integration}`, {
      params: { page, search },
    })
    .then(({ data: { data } }) => data)

// * Integration Status Change
export const integrationStatusChange = ({
  type,
  isEnabled = false,
}: {
  type:
    | 'zendesk'
    | 'salesforce'
    | 'intercom'
    | 'hubspot'
    | 'freshdesk'
    | 'shortcut'
  isEnabled: boolean
}) =>
  api
    .patch(`/v1/integrations/${type}/status-change`, {
      isEnabled,
    })
    .then(({ data: { data } }) => data)

// Fetch All Integrations
export const fetchIntegrations = () =>
  api.get('/v1/integrations').then(({ data: { data } }) => data)

// * Delete Integration
export const deleteIntegration = ({
  type,
}: {
  type:
    | 'zendesk'
    | 'salesforce'
    | 'intercom'
    | 'hubspot'
    | 'freshdesk'
    | 'shortcut'
}) =>
  api
    .patch(`/v1/integrations/${type}/delete`, {})
    .then(({ data: { data } }) => data)

/**
 * If a certain integration that exists through Paragon was disconnected, this function will be called to disable the integration from our sink
 *
 * @param options Bag of options to forward
 * @param options.event Event containing data for the integration uninstallation
 * @param options.user User metadata currently authenticated
 * @returns Null if the operation succeeds
 */
export const disableParagonIntegration = ({
  event,
  user,
  teamid,
}: {
  event: IntegrationUninstallEvent
  user: AuthenticatedConnectUser
  teamid: string
}) =>
  api
    .patch(
      `/v2/paragon/integration-disconnected`,
      { event, user },
      { headers: { teamid } }
    )
    .then(({ data }) => data)

export const saveShortcutToken = (shortcutToken: string) =>
  api.post('/v2/shortcut/save', { shortcutToken }).then(({ data }) => data)

export const fetchShortcutIntegration = () =>
  api.get('/v2/shortcut').then(({ data }) => data)
