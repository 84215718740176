import { Divider } from 'antd'
import { IntegrationModalHeader } from '../../../components'
import FreshworksSvg from '../../../images/logos/freshworks.svg'
import { FreshdeskSetUp } from './FreshdeskSetup'
type Props = {
  requestType: {
    label: string
    value: string
    disabled: boolean
  }[]
}
export const FreshdeskIntegration = ({ requestType }: Props) => {
  return (
    <main className="w-full">
      <IntegrationModalHeader
        imgSrc={FreshworksSvg}
        imageClassName="w-[42px] h-[42px]"
        title="Freshdesk Integration"
        description="Customizable integration for customer support"
      />
      <Divider className="my-5 py-0" />
      <FreshdeskSetUp requestType={requestType} />
    </main>
  )
}
