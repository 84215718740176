import { Identify, identify, setUserId } from '@amplitude/analytics-browser'
import { init } from 'commandbar'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'
import LogRocket from 'logrocket'
import React, { lazy, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { api } from '../api'
import { fetchUser } from '../api/auth'
import { fetchOrgIdByName } from '../api/customerPortal'
import FastSpinner from '../components/FastSpinner'
import { COMMANDBAR_ORG_ID, LC_CLIENT_KEY, LOGIN_URL } from '../config'
import {
  broadcastReload,
  useReloadBroadcastListener,
} from '../hooks/useReloadBroadcast'
import { useGlobalStorePersist } from '../store/globalStorePersist'
import { clearBrowserStorage } from '../utils/domUtils'
import { noOp } from '../utils/empty'
import { extractSubdomain } from '../utils/textUtils'
import { UserType } from './definitions'
const LazyCommandbar = lazy(
  () => import('../components/common/commandbar/LazyCommandbar')
)
const LazyThenaWidget = lazy(
  () => import('../components/common/thenaWidget/LazyThenaWidget')
)

init(COMMANDBAR_ORG_ID)

type AuthState = 'LOGGED_IN' | 'LOGIN' | 'LOGOUT' | 'LOADING' | null

interface AuthContextType {
  authState: AuthState
  setAuthState: React.Dispatch<'LOGIN' | 'LOGOUT'>
  user: UserType | null
  setUser: React.Dispatch<React.SetStateAction<UserType | null>>
  initAuth: () => void
  logout: () => void
}

const AuthContext = React.createContext<AuthContextType>({
  authState: null,
  user: null,
  setAuthState: noOp,
  setUser: noOp,
  initAuth: noOp,
  logout: noOp,
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [authState, setAuthState] = useState<AuthState>(null)
  const [user, setUser] = useState<UserType | null>(null)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useReloadBroadcastListener()

  const activeWorkspaceId = useGlobalStorePersist(
    (state) => state.activeWorkspaceId
  )

  api.interceptors.response.use(
    (response) => {
      return response
    },
    function (error) {
      if (error.response?.status === 401) {
        console.log('Logged out because of 401 response.')
        setAuthState('LOGOUT')
        return Promise.reject(error)
      }
      return Promise.reject(error)
    }
  )

  useEffect(() => {
    const axiosRequestInterceptor = (config: any) => {
      if (config.headers) {
        if (activeWorkspaceId) {
          config.headers['x-selected-installation'] = activeWorkspaceId
        } else {
          delete config.headers['x-selected-installation']
        }
      }
      return config
    }
    const interceptorId = api.interceptors.request.use(axiosRequestInterceptor)
    return () => {
      api.interceptors.request.eject(interceptorId)
    }
  }, [activeWorkspaceId])

  useEffect(() => {
    if (!authState && !['/slack/callback'].includes(pathname)) {
      initAuth()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (authState === 'LOGIN') {
      initAuth()
    } else if (authState === 'LOGOUT') {
      logout()
    } else if (user?.blocked || user?.isWebLoginBlocked) {
      navigate('/blocked')
    } else if (user?.too_small) {
      navigate('/too_small')
    } else if (authState === 'LOGGED_IN') {
      if (!user?.isThenaInstalled) {
        navigate('/welcome')
      } else if (user?.finishedOnBoarding) {
        let redirectPath = '/requests'
        try {
          redirectPath =
            localStorage.getItem('redirect_post_login') || '/requests'
          localStorage.removeItem('redirect_post_login')
        } catch (err) {
          //
        }
        if (
          [
            '/add-to-slack',
            '/',
            '/slack/callback',
            '/onboarding',
            '/welcome',
          ].includes(pathname)
        ) {
          user.isFirstLogin
            ? navigate('/requests', { state: { from: pathname } })
            : navigate(redirectPath)
        }
      } else if (['/add-to-slack', '/', '/slack/callback'].includes(pathname)) {
        navigate('/requests', { state: { from: pathname } })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState])

  const sendUserIdentity = async (userInfo: UserType) => {
    try {
      if (userInfo && !userInfo.superUser) {
        // LogRocket
        LogRocket.identify(
          isEmpty(userInfo.thenaUser) ? userInfo.id : userInfo.thenaUser.id,
          {
            name: isEmpty(userInfo.thenaUser)
              ? userInfo.name
              : userInfo.thenaUser.displayName,
            email: isEmpty(userInfo.thenaUser)
              ? userInfo.email
              : userInfo.thenaUser.email,
            team: userInfo.team_name,
          }
        )

        // Amplitude
        setUserId(userInfo.id)
        const identifyObj = new Identify()
        identifyObj.set('id', userInfo.id)
        identifyObj.set('email', userInfo.email)
        identifyObj.set('name', userInfo.name)
        identifyObj.set('team', userInfo.team_name)
        identifyObj.set('team_id', userInfo.team_id)
        identify(identifyObj)
      }
    } catch (Error) {
      console.log(Error)
    }
  }

  const dispatch = useGlobalStorePersist((state) => state.dispatch)
  const setActiveWorkspaceId = (id: string) => {
    dispatch({
      type: 'SET_ACTIVE_WORKSPACE_ID',
      payload: {
        activeWorkspaceId: id,
      },
    })
  }

  const initAuth = async () => {
    setAuthState('LOADING')
    try {
      const userInfo = (await fetchUser()) as UserType
      if (userInfo && userInfo.id) {
        setUser(userInfo)
        if (userInfo.primaryInstallation?._id && !activeWorkspaceId) {
          console.log(
            'Setting active workspace id',
            userInfo.primaryInstallation._id
          )
          setActiveWorkspaceId(userInfo.primaryInstallation._id)
        }
        setAuthState('LOGGED_IN')
        sendUserIdentity(userInfo)
        ;(window as any).analytics.identify(userInfo.id, {
          name: userInfo.name,
          email: userInfo.email,
        })
        // if (userInfo.organization?.name) {
        //   const subdomain = extractSubdomain(window.location.hostname)
        //   if (subdomain !== userInfo.organization.name) {
        //     const protocol = window.location.protocol
        //     const hostParts = window.location.host.split('.')
        //     let rootDomain = ''
        //     if (hostParts.length === 1) {
        //       rootDomain = hostParts[0]
        //     } else if (hostParts.length > 1) {
        //       rootDomain = hostParts.slice(-2).join('.')
        //     }
        //     const orgUrl = `${protocol}//${userInfo.organization.name}.${rootDomain}`
        //     window.location.href = orgUrl
        //   }
        // }
      } else {
        setAuthState('LOGOUT')
      }
    } catch (error) {
      setAuthState('LOGOUT')
    }
  }

  const logout = async () => {
    navigate('/logout')
    setUser(null)
    try {
      await clearBrowserStorage()
      ;(window as any).thena?.logout()
      ;(window as any).analytics.track('User logged out')
    } catch (error) {
      console.log('Error in clearing storages: ', error)
    } finally {
      broadcastReload()
      const subdomain = extractSubdomain(window.location.hostname)
      if (!subdomain || subdomain === 'app') {
        window.location.href = LOGIN_URL
      } else {
        try {
          const res = await fetchOrgIdByName(subdomain)
          if (res.id) {
            navigate('/login', { state: { orgId: res.id } })
          } else {
            window.location.href = LOGIN_URL
          }
        } catch (error) {
          console.log('Error in fetching org id: ', error)
          window.location.href = LOGIN_URL
        }
      }
    }
  }

  const getLDContext = () => {
    if (user) {
      return {
        kind: 'multi', // LDMultiKindContext
        ['installation-team-id']: {
          key: user.primaryInstallation?.team?.id,
        },
        user: {
          key: user.primaryInstallation?.user?.id,
        },
      }
    }
  }

  return (
    <LDProvider
      clientSideID={LC_CLIENT_KEY}
      deferInitialization={true}
      context={getLDContext()}
    >
      <AuthContext.Provider
        value={useMemo(
          () => ({
            user,
            setUser,
            authState,
            setAuthState,
            initAuth,
            logout,
          }),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [user, authState]
        )}
      >
        {authState === 'LOADING' ? (
          <div className="w-full h-full absolute z-20 flex justify-center items-center">
            <FastSpinner />
          </div>
        ) : null}
        <div
          className={
            authState === 'LOADING'
              ? 'blur-[2px] pointer-events-none fixed top-0 w-full'
              : ''
          }
        >
          <React.Suspense fallback={<div>Loading...</div>}>
            {children}
            {authState !== 'LOADING' ? <LazyCommandbar user={user} /> : null}
          </React.Suspense>
        </div>
        <LazyThenaWidget userInfo={user} />
      </AuthContext.Provider>
    </LDProvider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
