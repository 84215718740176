import { InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

type FormHeaderProps = {
  title: string
  desc: string
  isFirstTime?: boolean
}
export const FormHeader = ({ title, desc, isFirstTime }: FormHeaderProps) => {
  return (
    <FormHeaderWrapper>
      <div className="form-header-title-wrapper">
        <span className="form-header-title">{title}</span>
        {isFirstTime ? null : <InfoCircleOutlined />}
      </div>
      <span className="header-desc">{desc}</span>
    </FormHeaderWrapper>
  )
}

const FormHeaderWrapper = styled.div`
  margin-bottom: 32;
  .form-header-title {
    color: var(--color-black-1);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-right: 8px;
  }
  .form-header-desc {
    color: var(--color-gray-6);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
  }

  .form-header-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
  }
`
