import { BoldOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import {
  LinkSimple,
  TextItalic,
  TextStrikethrough,
  Trash,
} from 'phosphor-react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import ThenaSvg from '../../../images/icons/thena.svg'

type CommandType = 'italic' | 'bold' | 'strikeThrough' | 'createLink'

interface IEditButton {
  arg?: any
  name?: string
  cmd: CommandType
  onClick?: () => void
}

const getIcon = (command: CommandType) => {
  switch (command) {
    case 'bold':
      return (
        <BoldOutlined color="#0f172a" className="font-bold text-sm mt-[2px]" />
      )

    case 'italic':
      return <TextItalic size={16} color="#0f172a" weight="bold" />

    case 'strikeThrough':
      return <TextStrikethrough size={16} color="#6F6F75" weight="bold" />

    case 'createLink':
      return <LinkSimple size={16} color="#0f172a" weight="bold" />

    default:
      return null
  }
}

const EditButton = ({ name, onClick, cmd, arg }: IEditButton) => {
  const { campaignId } = useParams()
  const handleClick = () => {
    if (onClick) {
      return onClick()
    }
    // Send the command to the browser
    cmd !== 'strikeThrough' &&
      document.execCommand(cmd, false, arg) &&
      window.analytics.track('Campaign Edited', {
        campaignId: campaignId,
      })
  }

  if (cmd === 'strikeThrough') {
    return (
      <Tooltip title="Coming soon">
        <button key={cmd} style={{ cursor: 'not-allowed' }}>
          {getIcon(cmd)} <span>{name}</span>
        </button>
      </Tooltip>
    )
  } else
    return (
      <button key={cmd} onMouseDown={handleClick}>
        {getIcon(cmd)} <span>{name}</span>
      </button>
    )
}

type IProps = {
  toggleModal: () => void
  formatDisabled: boolean
  onDeleteBlock: () => void
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  position: absolute;
  left: -32px;
  z-index: 10;
  border-radius: 4px;
  background-color: var(--color-gray-1);
  box-shadow: 0px 0px 5px var(--color-primary);
`

const ActionsWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 24px;
  border-radius: 4px;
  background-color: var(--color-gray-1);
  box-shadow: 0 0 4px 0 var(--color-red-3);
`

const DeleteIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Text = styled.div`
  font-weight: 600;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`

const EditButtons = ({
  toggleModal,
  onDeleteBlock,
  formatDisabled,
}: IProps) => {
  return (
    <div className="edit-buttons-wrapper">
      <IconWrapper>
        <img src={ThenaSvg} width={16} height={16} alt="thena" />
      </IconWrapper>
      {!formatDisabled && (
        <div className="edit-buttons-container">
          <EditButton cmd="italic" />
          <EditButton cmd="bold" />
          <EditButton cmd="strikeThrough" />
          <EditButton cmd="createLink" onClick={toggleModal} name="Link" />
        </div>
      )}
      <ActionsWrapper onClick={onDeleteBlock}>
        <DeleteIcon>
          <Trash size={16} color="var(--color-black-1)" weight="bold" />
        </DeleteIcon>
        <Text>Delete Block</Text>
      </ActionsWrapper>
    </div>
  )
}

export default EditButtons
