/* eslint-disable max-lines */
import { Popover, message } from 'antd'
import { Circle, DotsThree, StackSimple } from 'phosphor-react'
import { ReactComponent as RequestSVG } from '../../../images/icons/customer_issues.svg'
import { ReactComponent as InternalSVG } from '../../../images/icons/internal_issues.svg'
import { ReactComponent as RequestIcon } from '../../../images/icons/open_requests_icon.svg'
import { ReactComponent as TicketIcon } from '../../../images/icons/ticketIcon.svg'

import { truncate } from '@utils/textUtils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { capitalize, isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { fetchIntegrations } from '../../../api/integrations'
import { updatedSavedView } from '../../../api/kanban'
import useRequestPageNavigation from '../../../hooks/useRequestPageNavigation'
import { Text6 } from '../../../modules/ZendeskIntegration/Commons'
import { getItem } from '../../../pages/CustomStatusRequests/utils'
import { useKanbanBoardStore } from '../../../store/kanbanBoardStore'
import { useKanbanStore2 } from '../../../store/kanbanStore2'
import { useKanbanStorePersist } from '../../../store/kanbanStorePersist'
import { IconWrapper, ListItem, Wrapper } from './styledComponent'

type Props = {
  expanded?: boolean
  selectedKeys: string
  setSelectedKeys: React.Dispatch<React.SetStateAction<string>>
  setMobileView: React.Dispatch<React.SetStateAction<boolean>>
  items: any
}

const RequestSelector = ({
  expanded,
  selectedKeys,
  setSelectedKeys,
  setMobileView,
  items,
}: Props) => {
  const kanbanDispatch = useKanbanStorePersist((state) => state.dispatch)
  const colsDispatch = useKanbanBoardStore((state) => state.dispatch)
  const [searchParams, setSearchParams] = useSearchParams()
  const flag = useFlags()
  const dispatch = useKanbanStore2((state) => state.dispatch)

  const [isClicked, setIsClicked] = useState<any>('')
  const { pageState, initializeFilterState } = useRequestPageNavigation({
    item: isClicked,
    setSelectedKeys,
    setMobileView,
  })
  useEffect(() => {
    if (isClicked) {
      pageState()
      queryClient.invalidateQueries(['kanban-initial-data'])
      message.destroy('kanban-loading')
      setIsClicked('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked])

  const onSelect = (item: any) => {
    setIsClicked(item)
    colsDispatch({
      type: 'RESET_UNSAVED_COLS',
    })
  }
  const allViewsList = useKanbanStorePersist((state) => state.allViewsList)
  const { data: integrationList = [] } = useQuery(
    ['integrations'],
    fetchIntegrations
  )
  const ticketName = integrationList?.find(
    (item: any) =>
      item?.id !== 'shortcut' && item?.id !== 'msteams' && item?.isEnabled
  )
  const isConnectorEnabled = (integrationList as any)?.some(
    (ele: any) => ele.isEnabled
  )
  const getIcon = (item: any) => {
    if (item.icon === 'RequestIcon') {
      return <RequestIcon className="w-[16px] h-[16px] m-0" />
    } else if (item.icon === 'TicketIcon') {
      return (
        <TicketIcon
          className="w-[16px] h-[16px] m-0 text-[#fff]"
          color="#fff"
        />
      )
    } else if (item.icon === 'SavedViewIcon') {
      return <StackSimple size={14} />
    } else {
      return <Circle size={6} weight="bold" />
    }
  }
  const renderCustomerContent = () => {
    const savedViews = allViewsList.External.SavedViews.map((item: any) => ({
      name: item.name,
      key: `customer-requests-${item.key}`,
      url: item.url,
      isActive: false,
      id: item.key,
    }))
    const renderList = (item: any, isNested?: boolean) => {
      return (
        <ListItem
          onClick={() => onSelect(item)}
          className={`flex items-center gap-[4px] cursor-pointer ${
            isNested ? 'm-[0px]' : ''
          }`}
          style={{
            backgroundColor: 'var(--color-purple-8)',
          }}
          $isSelected={selectedKeys === item.key}
        >
          {getIcon(item)}
          <div
            className={`flex items-center justify-start gap-3 ${
              isNested ? 'ml-1 mt-0' : ''
            }`}
          >
            <Text6 className="m-[0]" color="var(--color-gray-14)">
              {item.name === 'Tickets'
                ? capitalize(ticketName?.id) + ' Tickets'
                : item.name}
            </Text6>
          </div>
          <div
            className="flex items-center justify-center"
            style={{
              marginLeft: 'auto',
            }}
          >
            {selectedKeys === item.key &&
            item.name !== 'Requests' &&
            item.name !== 'Tickets' ? (
              <Popover
                trigger={'hover'}
                autoAdjustOverflow={true}
                placement="right"
                content={() => renderConfigContent(item)}
                showArrow={false}
                overlayStyle={{
                  padding: 0,
                }}
                overlayInnerStyle={{ width: '0px' }}
              >
                <DotsThree
                  onClick={(e) => e.stopPropagation()}
                  size={14}
                  weight="bold"
                  color="#fff"
                  className="cursor-pointer"
                />
              </Popover>
            ) : null}
          </div>
        </ListItem>
      )
    }
    const defaultRequestView = allViewsList.External.DefaultView
    const defaultTicketsView = allViewsList.External.Tickets
    const customerIssueList = [
      {
        name: 'Requests',
        url: allViewsList.External.DefaultView.url,
        isActive: false,
        icon: 'RequestIcon',
        key: `customer-requests-${defaultRequestView.key}`,
      },
      isConnectorEnabled
        ? {
            name: 'Tickets',
            url: allViewsList.External.Tickets.url,
            isActive: false,
            icon: 'TicketIcon',
            key: `customer-requests-${defaultTicketsView.key}`,
          }
        : {},
      !isEmpty(savedViews)
        ? {
            name: 'Saved Views',
            url: '',
            isActive: false,
            icon: 'SavedViewIcon',
            key: '',
          }
        : {},
    ]

    return (
      <div
        style={{
          width: '260px',
          padding: '8px',
          borderRadius: '4px',
          color: 'var(--color-gray-14)',
          border: '1px solid var(--color-gray-17)',
          backgroundColor: 'var(--color-purple-8)',
        }}
      >
        {customerIssueList.length > 0 ? (
          <div className="mb-[8px]">
            <div
              style={{
                fontSize: '12px',

                fontWeight: 600,
                lineHeight: '14px',
                marginBottom: '8px',
                color: 'var(--color-gray-5)',
              }}
            >
              Customer
            </div>
            {customerIssueList
              .filter((item) => !isEmpty(item))
              .map((item) => {
                return renderList(item)
              })}
          </div>
        ) : null}
        {savedViews.length > 0 ? (
          <div className="ml-[3px]">
            {savedViews.map((item: any) => {
              return renderList(item, true)
            })}
          </div>
        ) : null}
      </div>
    )
  }
  const renderInternalContent = () => {
    const savedViews = allViewsList.Internal.SavedViews.map((item: any) => ({
      name: item.name,
      key: `internal-helpdesk-${item.key}`,
      url: item.url,
      isActive: false,
      id: item.key,
    }))
    const renderList = (item: any, isNested?: boolean) => {
      return (
        <ListItem
          onClick={() => onSelect(item)}
          className={`flex items-center gap-[4px] cursor-pointer ${
            isNested ? 'm-[0px]' : ''
          }`}
          style={{
            backgroundColor: 'var(--color-purple-8)',
          }}
          $isSelected={selectedKeys === item.key}
        >
          {getIcon(item)}
          <div
            className={`flex items-center justify-start gap-3 ${
              isNested ? 'ml-1 mt-0' : ''
            }`}
          >
            <Text6 className="m-[0]" color="var(--color-gray-14)">
              {item.name === 'Tickets'
                ? capitalize(ticketName?.id) + ' Tickets'
                : item.name}
            </Text6>
          </div>
          <div
            className="flex items-center justify-center"
            style={{
              marginLeft: 'auto',
            }}
          >
            {selectedKeys === item.key &&
            item.name !== 'Requests' &&
            item.name !== 'Tickets' ? (
              <Popover
                autoAdjustOverflow={true}
                placement="right"
                content={() => renderConfigContent(item)}
                showArrow={false}
                overlayStyle={{
                  padding: 0,
                }}
                overlayInnerStyle={{ width: '0px' }}
              >
                <DotsThree
                  size={14}
                  weight="bold"
                  color="#fff"
                  className="cursor-pointer"
                />
              </Popover>
            ) : null}
          </div>
        </ListItem>
      )
    }
    const defaultRequestView = allViewsList.Internal.DefaultView
    const defaultTicketsView = allViewsList.Internal.Tickets
    const InternalIssueList = [
      {
        name: 'Requests',
        url: allViewsList.Internal.DefaultView.url,
        isActive: false,
        icon: 'RequestIcon',
        key: `internal-helpdesk-${defaultRequestView.key}`,
      },
      isConnectorEnabled
        ? {
            name: 'Tickets',
            url: allViewsList.Internal.Tickets.url,
            isActive: false,
            icon: 'TicketIcon',
            key: `internal-helpdesk-${defaultTicketsView.key}`,
          }
        : {},
      !isEmpty(savedViews)
        ? {
            name: 'Saved Views',
            url: '',
            isActive: false,
            icon: 'SavedViewIcon',
            key: '',
          }
        : {},
    ]

    return (
      <div
        style={{
          width: '260px',
          padding: '8px',
          borderRadius: '4px',
          color: 'var(--color-gray-14)',
          border: '1px solid var(--color-gray-17)',
          backgroundColor: 'var(--color-purple-8)',
        }}
      >
        {InternalIssueList.length > 0 ? (
          <div className="mb-[8px]">
            <div
              style={{
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '14px',
                marginBottom: '8px',
                color: 'var(--color-gray-5)',
              }}
            >
              Internal
            </div>
            {InternalIssueList.filter((item) => !isEmpty(item)).map((item) => {
              return renderList(item)
            })}
          </div>
        ) : null}
        {savedViews.length > 0 ? (
          <div className="ml-[3px]">
            {savedViews.map((item: any) => {
              return renderList(item, true)
            })}
          </div>
        ) : null}
      </div>
    )
  }
  const tabValue = searchParams.get('tab')
  const currentViewName = useKanbanStorePersist(
    (state) => state.currentViewName
  )
  const orderedColumns = useKanbanStorePersist((state) => state.orderedColumns)
  const isTicketsPage = searchParams.get('cardType') ?? ''
  const hiddenColumnsList = useMemo(() => {
    const allViewsList = useKanbanStorePersist.getState().allViewsList
    const orderedColumnsList = useKanbanStorePersist.getState().orderedColumns
    if (tabValue === 'customer-requests') {
      if (currentViewName === 'Default View') {
        if (isTicketsPage.includes('Tickets')) {
          return allViewsList.External.Tickets.hiddenCols
        } else {
          return allViewsList.External.DefaultView.hiddenCols
        }
      } else {
        const currentSavedView = allViewsList.External.SavedViews.find(
          (item: any) => item.name === currentViewName
        )
        if (currentSavedView) {
          const hiddenCols = orderedColumnsList.filter(
            (item) => !currentSavedView?.activeCols?.includes(item)
          )
          return hiddenCols
        }
        return []
      }
    } else if (tabValue === 'internal-helpdesk') {
      if (currentViewName === 'Default View') {
        if (isTicketsPage.includes('Tickets')) {
          return allViewsList.Internal.Tickets.hiddenCols
        } else {
          return allViewsList.Internal.DefaultView.hiddenCols
        }
      } else {
        if (currentSavedView) {
          const currentSavedView = allViewsList.Internal.SavedViews.find(
            (item: any) => item.name === currentViewName
          )
          const hiddenCols = orderedColumnsList.filter(
            (item) => !currentSavedView?.activeCols?.includes(item)
          )
          return hiddenCols
        }
        return []
      }
    }
    return []
  }, [currentViewName, tabValue, isTicketsPage])
  const unsavedHiddenColumns = useKanbanBoardStore(
    (state) => state.unsavedHiddenColumns
  )
  const unsavedActiveColumns = useKanbanBoardStore(
    (state) => state.unsavedActiveColumns
  )
  const currentSavedCols = useKanbanStore2((state) => state.currentSavedCols)

  const finalHiddenList = useMemo(() => {
    return [
      ...hiddenColumnsList.filter(
        (item) => !unsavedActiveColumns.includes(item)
      ),
      ...unsavedHiddenColumns,
    ]
  }, [hiddenColumnsList, unsavedActiveColumns, unsavedHiddenColumns])

  const finalActiveListToUpdate = orderedColumns.filter(
    (item) => !finalHiddenList.includes(item)
  )
  const currentView = () => {
    let updatedView = []
    if (tabValue?.includes('customer-requests')) {
      updatedView = allViewsList.External.SavedViews.find(
        (item: any) => item.name === currentViewName
      )
    } else {
      updatedView = allViewsList.Internal.SavedViews.find(
        (item: any) => item.name === currentViewName
      )
    }
    return updatedView
  }
  const currentSavedView = currentView()
  const queryClient = useQueryClient()
  const updateSavedViewMutation = useMutation(
    (data: any) => updatedSavedView(currentSavedView?.key ?? '', data),
    {
      onMutate: () => {
        message.destroy()
        message.loading('Updating View', 0.5)
      },
      onSuccess: (data) => {
        message.destroy()
        message.success('View updated', 0.5)
        queryClient.invalidateQueries(['fetchSavedViews'])
      },
      onError: () => {
        message.error('Something went wrong!')
      },
    }
  )
  const configurationHandler = (config: any, currentView: any) => {
    const type = currentView.key.includes('customer-requests')
      ? 'REQUESTS'
      : 'INTERNAL_REQUESTS'

    if (config.name === 'Update') {
      const view = {
        name: currentView?.name ?? '',
        type: currentSavedView?.type ?? type,
        scope: currentSavedView?.scope ?? 'GLOBAL',
        filter: searchParams.toString(),
        additionalPrefs: {
          active_columns: finalActiveListToUpdate,
          ordered_columns: currentSavedCols.map((item) =>
            item === 'OPEN'
              ? 'UNASSIGNED'
              : item === 'ONHOLD'
              ? 'ON_HOLD'
              : item
          ),
        },
      }
      const newPayload = {
        viewKey: currentView?.id,
        fields: view,
        section:
          tabValue === 'customer-requests'
            ? 'External'
            : tabValue === 'internal-helpdesk'
            ? 'Internal'
            : '',
      }
      kanbanDispatch({
        type: 'UPDATE_CURRENT_VIEW',
        payload: newPayload,
      })
      initializeFilterState(searchParams)
      if (!isEmpty(currentSavedView) && currentSavedView?.key !== '') {
        updateSavedViewMutation.mutate(view)
      }
    } else if (config.name === 'Edit') {
      dispatch({
        type: 'SET_MODAL',
        payload: {
          modal: {
            type: 'NEW_VIEW',
            data: {
              currentView: currentSavedView,
              isEditing: true,
            },
          },
        },
      })
    } else if (config.name === 'Delete') {
      dispatch({
        type: 'SET_MODAL',
        payload: {
          modal: {
            type: 'DELETE_VIEW',
            data: {
              currentView: currentSavedView,
            },
          },
        },
      })
    }
  }
  const renderConfigContent = (currentView: any) => {
    const renderList = (item: any, isNested?: boolean) => {
      return (
        <ListItem
          key={item.name}
          onClick={() => configurationHandler(item, currentView)}
          className={`flex items-center gap-[4px] cursor-pointer ${
            isNested ? 'ml-2' : ''
          }`}
          style={{
            backgroundColor: 'var(--color-purple-8)',
          }}
          $isSelected={selectedKeys === item.key}
        >
          <Text6 className="m-[0] ml-2" color="var(--color-gray-14)">
            {item.name}
          </Text6>
        </ListItem>
      )
    }

    const configList = [
      {
        name: 'Edit',
      },
      {
        name: 'Update',
      },
      {
        name: 'Delete',
      },
    ]
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          width: '100px',
          padding: '0px',
          borderRadius: '4px',
          color: 'var(--color-gray-14)',
          border: '1px solid var(--color-gray-17)',
          backgroundColor: 'var(--color-purple-8)',
        }}
      >
        {configList.length > 0
          ? configList.map((item) => {
              return renderList(item)
            })
          : null}
      </div>
    )
  }

  const isOnlyInternalDefaultView =
    !isConnectorEnabled && isEmpty(allViewsList.Internal.SavedViews)

  const isOnlyCustomerDefaultView =
    !isConnectorEnabled && isEmpty(allViewsList.External.SavedViews)

  if (expanded) {
    if (isConnectorEnabled && !isEmpty(allViewsList.External.SavedViews)) {
      const defaultRequestView = allViewsList.External.DefaultView
      const defaultTicketsView = allViewsList.External.Tickets
      const savedViews = allViewsList.External.SavedViews.map((item: any) => ({
        label: (
          <div className="flex items-center justify-between">
            {truncate(item.name, 20)}
            {selectedKeys === `customer-requests-${item.key}` && (
              <Popover
                trigger={'hover'}
                autoAdjustOverflow={true}
                placement="right"
                content={() => renderConfigContent(item)}
                showArrow={false}
                overlayInnerStyle={{ width: '0px' }}
              >
                <DotsThree
                  onClick={(e) => e.stopPropagation()}
                  size={14}
                  weight="bold"
                  color="#fff"
                  className="cursor-pointer"
                />
              </Popover>
            )}
          </div>
        ),
        icon: <Circle size={6} weight="bold" />,
        key: `customer-requests-${item.key}`,
      }))

      const requestItem = getItem(
        'Customer',
        'sub5',
        <RequestSVG
          fill="none"
          color={
            selectedKeys.includes('customer-requests') ? '#BEC1FE' : '#fff'
          }
        />,
        [
          getItem(
            'Requests',
            `customer-requests-${defaultRequestView.key}`,
            <RequestIcon className="w-[16px] h-[16px] m-0" />
          ),
          getItem(
            capitalize(ticketName?.id) + ' Tickets',
            `customer-requests-${defaultTicketsView.key}`,
            <TicketIcon className="w-[16px] h-[16px] m-0" />
          ),
          getItem('Saved Views', 'grp1', <StackSimple size={14} />, [
            getItem(null, 'g1', null, [...savedViews], 'group'),
          ]),
        ]
      )
      items.splice(0, 0, requestItem)
    } else if (
      isConnectorEnabled &&
      isEmpty(allViewsList.External.SavedViews)
    ) {
      const defaultRequestView = allViewsList.External.DefaultView
      const defaultTicketsView = allViewsList.External.Tickets
      const requestItem = getItem(
        'Customer',
        'sub5',
        <RequestSVG
          fill="none"
          color={
            selectedKeys.includes('customer-requests') ? '#BEC1FE' : '#fff'
          }
        />,
        [
          getItem(
            'Requests',
            `customer-requests-${defaultRequestView.key}`,
            <RequestIcon className="w-[16px] h-[16px] m-0" />
          ),
          getItem(
            capitalize(ticketName?.id) + ' Tickets',
            `customer-requests-${defaultTicketsView.key}`,
            <TicketIcon className="w-[16px] h-[16px] m-0" />
          ),
        ]
      )
      items.splice(0, 0, requestItem)
    } else if (
      !isConnectorEnabled &&
      !isEmpty(allViewsList.External.SavedViews)
    ) {
      const defaultRequestView = allViewsList.External.DefaultView
      const savedViews = allViewsList.External.SavedViews.map((item: any) => ({
        label: (
          <div className="flex items-center justify-between">
            {truncate(item.name, 20)}
            {selectedKeys === `customer-requests-${item.key}` && (
              <Popover
                trigger={'hover'}
                autoAdjustOverflow={true}
                placement="right"
                content={() => renderConfigContent(item)}
                showArrow={false}
                overlayInnerStyle={{ width: '0px' }}
              >
                <DotsThree
                  onClick={(e) => e.stopPropagation()}
                  size={14}
                  weight="bold"
                  color="#fff"
                  className="cursor-pointer"
                />
              </Popover>
            )}
          </div>
        ),
        icon: <Circle size={6} weight="bold" />,
        key: `customer-requests-${item.key}`,
      }))
      const requestItem = getItem(
        'Customer',
        'sub5',
        <RequestSVG
          fill="none"
          color={
            selectedKeys.includes('customer-requests') ? '#BEC1FE' : '#fff'
          }
        />,
        [
          getItem(
            'Requests',
            `customer-requests-${defaultRequestView.key}`,
            <RequestIcon className="w-[16px] h-[16px] m-0" />
          ),
          getItem('Saved Views', 'grp1', <StackSimple size={14} />, [
            getItem(
              null,
              'g1',
              <RequestIcon className="w-[16px] h-[16px] m-0" />,
              [...savedViews],
              'group'
            ),
          ]),
        ]
      )
      items.splice(0, 0, requestItem)
    } else {
      const defaultRequestView = allViewsList.External.DefaultView

      const requestItem = getItem(
        'Customer',
        `customer-requests-${defaultRequestView.key}`,
        <RequestSVG
          fill="none"
          color={
            selectedKeys.includes('customer-requests') ? '#BEC1FE' : '#fff'
          }
        />
      )
      items.splice(0, 0, requestItem)
    }
    if (flag.internalHelpdesk) {
      if (isConnectorEnabled && !isEmpty(allViewsList.Internal.SavedViews)) {
        const defaultRequestView = allViewsList.Internal.DefaultView
        const defaultTicketsView = allViewsList.Internal.Tickets
        const savedViews = allViewsList.Internal.SavedViews.map(
          (item: any) => ({
            label: (
              <div className="flex items-center justify-between">
                {truncate(item.name, 20)}
                {selectedKeys === `internal-helpdesk-${item.name}` && (
                  <Popover
                    trigger={'hover'}
                    autoAdjustOverflow={true}
                    placement="right"
                    content={() => renderConfigContent(item)}
                    showArrow={false}
                    overlayInnerStyle={{ width: '0px' }}
                  >
                    <DotsThree
                      onClick={(e) => e.stopPropagation()}
                      size={14}
                      weight="bold"
                      color="#fff"
                      className="cursor-pointer"
                    />
                  </Popover>
                )}
              </div>
            ),
            icon: <Circle size={6} weight="bold" />,
            key: `internal-helpdesk-${item.name}`,
          })
        )
        const requestItem = getItem(
          'Internal',
          'sub6',
          <InternalSVG
            fill="none"
            color={
              selectedKeys.includes('internal-helpdesk') ? '#BEC1FE' : '#fff'
            }
          />,
          [
            getItem(
              'Requests',
              `internal-helpdesk-${defaultRequestView.key}`,
              <RequestIcon className="w-[16px] h-[16px] m-0" />
            ),
            getItem(
              capitalize(ticketName?.id) + ' Tickets',
              `internal-helpdesk-${defaultTicketsView.key}`,
              <TicketIcon className="w-[16px] h-[16px] m-0" />
            ),
            getItem('Saved Views', 'grp2', <StackSimple size={14} />, [
              getItem(null, 'g2', null, [...savedViews], 'group'),
            ]),
          ]
        )
        items.splice(1, 0, requestItem)
      } else if (
        isConnectorEnabled &&
        isEmpty(allViewsList.Internal.SavedViews)
      ) {
        const defaultRequestView = allViewsList.Internal.DefaultView
        const defaultTicketsView = allViewsList.Internal.Tickets
        const requestItem = getItem(
          'Internal',
          'sub6',
          <InternalSVG
            fill="none"
            color={
              selectedKeys.includes('internal-helpdesk') ? '#BEC1FE' : '#fff'
            }
          />,
          [
            getItem(
              'Requests',
              `internal-helpdesk-${defaultRequestView.key}`,
              <RequestIcon className="w-[16px] h-[16px] m-0" />
            ),
            getItem(
              capitalize(ticketName?.id) + ' Tickets',
              `internal-helpdesk-${defaultTicketsView.key}`,
              <TicketIcon className="w-[16px] h-[16px] m-0" />
            ),
          ]
        )
        items.splice(1, 0, requestItem)
      } else if (
        !isConnectorEnabled &&
        !isEmpty(allViewsList.Internal.SavedViews)
      ) {
        const defaultRequestView = allViewsList.Internal.DefaultView
        const savedViews = allViewsList.Internal.SavedViews.map(
          (item: any) => ({
            label: (
              <div className="flex items-center justify-between">
                {truncate(item.name, 20)}
                {selectedKeys === `internal-helpdesk-${item.name}` && (
                  <Popover
                    trigger={'hover'}
                    autoAdjustOverflow={true}
                    placement="right"
                    content={() => renderConfigContent(item)}
                    showArrow={false}
                    overlayInnerStyle={{ width: '0px' }}
                  >
                    <DotsThree
                      onClick={(e) => e.stopPropagation()}
                      size={14}
                      weight="bold"
                      color="#fff"
                      className="cursor-pointer"
                    />
                  </Popover>
                )}
              </div>
            ),
            icon: <Circle size={6} weight="bold" />,
            key: `internal-helpdesk-${item.name}`,
          })
        )
        const requestItem = getItem(
          'Internal',
          'sub6',
          <InternalSVG
            fill="none"
            color={
              selectedKeys.includes('internal-helpdesk') ? '#BEC1FE' : '#fff'
            }
          />,
          [
            getItem(
              'Requests',
              `internal-helpdesk-${defaultRequestView.key}`,
              <RequestIcon className="w-[16px] h-[16px] m-0" />
            ),
            getItem('Saved Views', 'grp2', <StackSimple size={14} />, [
              getItem(null, 'g2', null, [...savedViews], 'group'),
            ]),
          ]
        )
        items.splice(1, 0, requestItem)
      } else {
        const defaultRequestView = allViewsList.Internal.DefaultView

        const requestItem = getItem(
          'Internal',
          `internal-helpdesk-${defaultRequestView.key}`,
          <InternalSVG
            fill="none"
            color={
              selectedKeys.includes('internal-helpdesk') ? '#BEC1FE' : '#fff'
            }
          />
        )
        items.splice(1, 0, requestItem)
      }
    }

    return null
  } else {
    return (
      <Wrapper>
        {/* Customer Requests */}
        {isOnlyCustomerDefaultView ? (
          <div
            onClick={() => {
              onSelect({
                ...allViewsList.External.DefaultView,
                key: `customer-requests-${allViewsList.External.DefaultView.key}`,
              })
            }}
            className={`flex items-center gap-[4px] justify-between w-full px-1 ${
              selectedKeys.includes('customer-requests')
                ? 'bg-[#4D456A]  border rounded-md'
                : ''
            }`}
          >
            <IconWrapper $isActive={selectedKeys.includes('customer-requests')}>
              <RequestSVG
                fill="none"
                color={
                  selectedKeys.includes('customer-requests')
                    ? '#BEC1FE'
                    : '#fff'
                }
              />
            </IconWrapper>
          </div>
        ) : (
          <Popover
            trigger="hover"
            showArrow={false}
            placement="rightTop"
            content={() => renderCustomerContent()}
            overlayStyle={{
              padding: 0,
              position: 'fixed',
              zIndex: 9999,
              left: '80px',
            }}
            overlayInnerStyle={{ width: '260px' }}
            getPopupContainer={(trigger) =>
              trigger.parentElement || document.body
            }
          >
            <div
              className={`flex items-center gap-[4px] justify-between w-full px-1 ${
                selectedKeys.includes('customer-requests')
                  ? 'bg-[#4D456A]  border rounded-md'
                  : ''
              }`}
            >
              <IconWrapper
                $isActive={selectedKeys.includes('customer-requests')}
              >
                <RequestSVG
                  fill="none"
                  color={
                    selectedKeys.includes('customer-requests')
                      ? '#BEC1FE'
                      : '#fff'
                  }
                />
              </IconWrapper>
            </div>
          </Popover>
        )}
        {/* Internal Helpdesk */}
        {flag.internalHelpdesk ? (
          isOnlyInternalDefaultView ? (
            <div
              onClick={() => {
                onSelect({
                  ...allViewsList.Internal.DefaultView,
                  key: `internal-helpdesk-${allViewsList.Internal.DefaultView.key}`,
                })
              }}
              className={`flex items-center gap-[4px] justify-between w-full mt-4 px-1 ${
                selectedKeys.includes('internal-helpdesk')
                  ? 'bg-[#4D456A]  border rounded-md'
                  : ''
              }`}
            >
              <IconWrapper
                $isActive={selectedKeys.includes('internal-helpdesk')}
              >
                <InternalSVG
                  fill="none"
                  color={
                    selectedKeys.includes('internal-helpdesk')
                      ? '#BEC1FE'
                      : '#fff'
                  }
                />
              </IconWrapper>
            </div>
          ) : (
            <Popover
              trigger="hover"
              showArrow={false}
              placement="rightTop"
              content={() => renderInternalContent()}
              overlayStyle={{
                padding: 0,
                position: 'fixed',
                zIndex: 9999,
                left: '80px',
              }}
              overlayInnerStyle={{ width: '260px' }}
              getPopupContainer={(trigger) =>
                trigger.parentElement || document.body
              }
            >
              <div
                className={`flex items-center gap-[4px] justify-between w-full mt-4 px-1 ${
                  selectedKeys.includes('internal-helpdesk')
                    ? 'bg-[#4D456A]  border rounded-md'
                    : ''
                }`}
              >
                <IconWrapper
                  $isActive={selectedKeys.includes('internal-helpdesk')}
                >
                  <InternalSVG
                    fill="none"
                    color={
                      selectedKeys.includes('internal-helpdesk')
                        ? '#BEC1FE'
                        : '#fff'
                    }
                  />
                </IconWrapper>
              </div>
            </Popover>
          )
        ) : null}
      </Wrapper>
    )
  }
}
export default RequestSelector
