import { Modal } from 'antd'
import { useState } from 'react'

import RemoveResourceForm from '../Forms/RemoveResourceForm'

type UpdateResourceProps = {
  isOpen: boolean
  onCancel: () => void
  onOk: (checked: boolean) => void
  isSaving: boolean
}

export const UpdateResourceModal = ({
  onCancel,
  onOk,
  isOpen,
  isSaving,
}: UpdateResourceProps) => {
  const [checked, setChecked] = useState(false)
  const onCheck = (checkStatus: boolean) => {
    setChecked(checkStatus)
  }
  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={onCancel}
      width={520}
      bodyStyle={{ padding: '32px 32px 24px' }}
      closable={false}
      destroyOnClose
    >
      <RemoveResourceForm
        actionType="update"
        title="Are you sure you want to update sources?"
        description="You will not be able select the sources that have been deselected"
        onCancel={onCancel}
        onAction={() => onOk(checked)}
        okText="Update Source"
        checkboxLabels={['Delete verified questions & answers']}
        isSaving={isSaving}
        onChange={onCheck}
      />
    </Modal>
  )
}
