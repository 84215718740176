import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

type CustomSpinnerProps = {
  isLoading?: boolean
  children?: React.ReactNode
}

export const CustomSpinner = ({
  isLoading = true,
  children,
}: CustomSpinnerProps) => {
  const loadIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />
  return (
    <Spin
      indicator={loadIcon}
      size="small"
      style={{ marginRight: 8 }}
      spinning={isLoading}
    >
      {children}
    </Spin>
  )
}
