/* eslint-disable max-lines */
import { track } from '@amplitude/analytics-browser'
import { LogoutOutlined } from '@ant-design/icons'
import { Button, Layout, Menu, MenuProps, Modal, message } from 'antd'
import classNames from 'classnames'
import 'commandbar-launcher/assets/index.css'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isEmpty } from 'lodash'
import { CaretDoubleLeft, CaretDoubleRight } from 'phosphor-react'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useAccountsStore } from 'store/accountsStore'
import styled from 'styled-components'
import { useAuth } from '../../../context'
import useRequestPageNavigation from '../../../hooks/useRequestPageNavigation'
import { ReactComponent as WorkflowSVG } from '../../../images/icons/TreeStructure.svg'
import { ReactComponent as AccountsSVG } from '../../../images/icons/accountsBeta.svg'
import { ReactComponent as ChartSVG } from '../../../images/icons/chart.svg'
import { ReactComponent as MarketingAutomationSVG } from '../../../images/icons/marketing.svg'
import { ReactComponent as SettingsSVG } from '../../../images/icons/settings.svg'
import { ReactComponent as SparkleSVG } from '../../../images/icons/sparkle.svg'
import LogoColor from '../../../images/logos/full-logo.webp'
import Logo from '../../../images/logos/thena-logomark.webp'
import { MenuItem, getItem } from '../../../pages/CustomStatusRequests/utils'
import { useKanbanBoardStore } from '../../../store/kanbanBoardStore'
import { useKanbanStorePersist } from '../../../store/kanbanStorePersist'
import { useLayoutStore } from '../../../store/layoutStore'
import NewAppBanner from '../NewAppBanner'
import { NewPageHeader } from '../pageHeader/NewPageHeader'
import AIConsent from './AIConsent'
import OrgSelector from './OrgSelector'
import RequestSelector from './RequestSelector'
import './index.css'
const { Sider, Content } = Layout

interface Props {
  children: ReactNode
}

const accountSubRoute = new RegExp('^/account/')
const productTourSubRoute = new RegExp('^/product-tour/')
const knowlwedgeAssistantSubRoute = new RegExp('^/knowledge-assistant/')
const marketingAutomationSubRoute = new RegExp('^/marketing-automation/')
const workflowsSubRoute = new RegExp('^/workflows/')

const ScrollableDiv = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 6px;
    border: 3px solid transparent;
  }
  scrollbar-width: thin;
  scrollbar-color: #ffffff transparent;
  margin-right: 2px;
`

const NewAppLayout = ({ children }: Props) => {
  const flag = useFlags()
  const navigate = useNavigate()
  const { user, setAuthState } = useAuth()
  const ref = useRef<HTMLDivElement>(null)
  const searchParams = useSearchParams()[0]
  const { pathname, search } = useLocation()
  const [showSlider, setShowSlider] = useState<boolean>(false)
  const [isOnboarding, setIsOnboarding] = React.useState(true)
  const dispatch = useLayoutStore((state) => state.dispatch)
  const collapsed = useLayoutStore((state) => state.collapsed)
  const currentViewName = useKanbanStorePersist(
    (state) => state.currentViewName
  )
  const allViewsList = useKanbanStorePersist((state) => state.allViewsList)
  const [mobileView, setMobileView] = useState(window.innerWidth < 640)
  const isListView =
    useKanbanStorePersist((state) => state.view === 'LIST') &&
    flag.kanbanListView

  const getInitialSelectedKeys = () => {
    let selectedKey: string
    const url = new URLSearchParams(search)
    const isTicketsPage = url.get('cardType')?.includes('Tickets')
    if (isTicketsPage) {
      selectedKey = 'customer-requests-tickets'
    } else if (
      pathname?.slice(1)?.includes('requests') &&
      search?.includes('currentViewName=Default+View')
    ) {
      selectedKey = 'customer-requests-defaultView'
    } else if (
      pathname?.slice(1)?.includes('requests') &&
      search?.includes(currentViewName)
    ) {
      const savedViews = allViewsList.External.SavedViews
      const currentSavedView = savedViews.find(
        (item: any) => item.name === currentViewName
      )
      selectedKey = `customer-requests-${currentSavedView?.key}`
    } else {
      accountSubRoute.test(pathname)
        ? (selectedKey = 'account')
        : (selectedKey = pathname.slice(1))
    }
    return selectedKey
  }

  const [selectedKeys, setSelectedKeys] = useState<string>(() =>
    getInitialSelectedKeys()
  )

  useEffect(() => {
    const data = window.localStorage.getItem('navbarCollapsed')
    if (data)
      dispatch({
        type: 'SET_NAVBAR_COLLAPSED',
        payload: {
          collapsed: JSON.parse(data),
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      ['/', '/add-to-slack', '/welcome', '/product-tour'].includes(pathname)
    ) {
      setIsOnboarding(true)
    } else if (productTourSubRoute.test(pathname)) {
      setIsOnboarding(true)
    } else {
      setIsOnboarding(false)
    }
  }, [pathname])

  useEffect(() => {
    if (
      [
        '/requests',
        '/settings',
        '/requestsv2',
        '/alerts',
        '/analytics',
        '/onboarding-guide',
        '/marketing-automation',
        '/requests-revamp',
        '/knowledge-assistant',
        '/workflows',
      ].includes(pathname)
    ) {
      setShowSlider(true)
    } else if (productTourSubRoute.test(pathname)) {
      setShowSlider(false)
    } else if (
      pathname.includes('/account') ||
      accountSubRoute.test(pathname) ||
      pathname.includes('/marketing-automation') ||
      marketingAutomationSubRoute.test(pathname) ||
      pathname.includes('/knowledge-assistant') ||
      knowlwedgeAssistantSubRoute.test(pathname) ||
      workflowsSubRoute.test(pathname)
    ) {
      setShowSlider(true)
    } else {
      setShowSlider(false)
    }
  }, [pathname])

  const isOrg = !isEmpty(user?.organization)

  const settingsItems = useMemo(() => {
    const list = [
      getItem('Channels', 'settings'),
      getItem('Integrations', 'settings?tab=integrations'),
      getItem('Configure', 'settings?tab=config'),
    ]
    if (flag.emailToSlackSettings) {
      list.push(getItem('Email', 'settings?tab=email-settings'))
    }
    if (flag.webToSlack) {
      list.push(getItem('Web Chat', 'settings?tab=web-chat'))
    }
    if (flag.microsoftTeams) {
      list.push(getItem('Microsoft Teams', 'settings?tab=msteams'))
    }
    if (flag.globalSettingsApiKey) {
      list.push(getItem('API Key', 'settings?tab=api-key'))
    }
    list.push(getItem('Team', 'settings?tab=team'))
    if (isOrg) {
      list.push(getItem('Organization', 'settings?tab=organization'))
    }
    return list
  }, [
    flag.emailToSlackSettings,
    flag.webToSlack,
    flag.microsoftTeams,
    flag.globalSettingsApiKey,
    isOrg,
  ])

  useEffect(() => {
    const tabValue = searchParams.get('tab')
    const currentViewName = searchParams.get('currentViewName') ?? ''
    const cardType = searchParams.get('cardType')
    if (
      tabValue?.includes('customer-requests') &&
      currentViewName?.includes('Default View')
    ) {
      if (currentViewName?.includes('Default View')) {
        if (cardType?.includes('Tickets')) {
          setSelectedKeys('customer-requests-tickets')
        } else setSelectedKeys('customer-requests-defaultView')
      } else {
        const savedViews = allViewsList.External.SavedViews
        const currentSavedView = savedViews.find(
          (item: any) => item.name === currentViewName
        )
        setSelectedKeys(`customer-requests-${currentSavedView?.key}`)
      }
    } else if (
      tabValue?.includes('internal-helpdesk') &&
      currentViewName?.includes('Default View')
    ) {
      if (currentViewName?.includes('Default View')) {
        if (cardType?.includes('Tickets')) {
          setSelectedKeys('internal-helpdesk-tickets')
        } else setSelectedKeys('internal-helpdesk-defaultView')
      } else {
        const savedViews = allViewsList.Internal.SavedViews
        const currentSavedView = savedViews.find(
          (item: any) => item.name === currentViewName
        )
        setSelectedKeys(`internal-helpdesk-${currentSavedView?.key}`)
      }
    }
  }, [allViewsList, searchParams])

  const [isClicked, setIsClicked] = useState<string>('')
  const colsDispatch = useKanbanBoardStore((state) => state.dispatch)

  const item = { key: isClicked }
  const { pageState } = useRequestPageNavigation({
    item,
    setSelectedKeys,
    setMobileView,
  })
  const queryClient = useQueryClient()
  useEffect(() => {
    if (isClicked) {
      pageState()
      queryClient.invalidateQueries(['kanban-initial-data'])
      message.destroy('kanban-loading')
      setIsClicked('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked])

  useEffect(() => {
    const tabValue = searchParams.get('tab')
    const cardType = searchParams.get('cardType')
    if (currentViewName !== 'Default View' && !cardType?.includes('Tickets')) {
      if (tabValue?.includes('internal-helpdesk-')) {
        const savedViews = allViewsList.Internal.SavedViews
        const currentSavedView = savedViews.find(
          (item: any) => item.name === currentViewName
        )
        currentSavedView &&
          setSelectedKeys(`${tabValue}-${currentSavedView.key}`)
      } else if (tabValue?.includes('customer-requests-')) {
        const savedViews = allViewsList.External.SavedViews
        const currentSavedView = savedViews.find(
          (item: any) => item.name === currentViewName
        )
        currentSavedView &&
          setSelectedKeys(`${tabValue}-${currentSavedView.key}`)
      }
    }
  }, [allViewsList, currentViewName, searchParams])

  const items: MenuItem[] = [
    getItem(
      'Marketing Automation',
      'marketing-automation',
      <MarketingAutomationSVG
        fill="none"
        style={{
          marginTop: !collapsed ? '' : '8px',
          marginLeft: !collapsed ? '' : '-2px',
        }}
        className="w-[22px] h-[22px]"
        color={selectedKeys === 'marketing-automation' ? '#BEC1FE' : '#fff'}
      />
    ),
    getItem(
      'Analytics',
      'analytics',
      <ChartSVG
        fill="none"
        style={{
          marginTop: !collapsed ? '' : '8px',
          marginLeft: !collapsed ? '' : '-2px',
        }}
        className="w-[22px] h-[22px]"
        color={selectedKeys === 'analytics' ? '#BEC1FE' : '#fff'}
      />,
      undefined
    ),
    getItem('', '2', undefined, undefined, 'divider'),
    getItem(
      'Global Settings',
      'sub4',
      <SettingsSVG
        fill="none"
        style={{
          marginTop: !collapsed ? '' : '8px',
          marginLeft: !collapsed ? '' : '-2px',
        }}
        className="w-[22px] h-[22px]"
        color={selectedKeys.includes('settings') ? '#BEC1FE' : '#fff'}
      />,
      settingsItems
    ),
  ]

  if (flag.knowledgebaseUi) {
    const knowledgebaseItem = getItem(
      'KnowledgeAssistant',
      'knowledge-assistant',
      <SparkleSVG
        fill="none"
        style={{
          marginTop: !collapsed ? '' : '8px',
          marginLeft: !collapsed ? '' : '-2px',
        }}
        className="w-[22px] h-[22px]"
        stroke={selectedKeys === 'knowledge-assistant' ? '#BEC1FE' : '#fff'}
      />,
      undefined
    )
    items.splice(4, 0, knowledgebaseItem)
  }

  if (flag.workflows) {
    const workflowsItem = getItem(
      'Workflows',
      'workflows',
      <WorkflowSVG
        fill="none"
        style={{
          marginTop: !collapsed ? '' : '8px',
          marginLeft: !collapsed ? '' : '-2px',
        }}
        className="w-[22px] h-[22px]"
        color={selectedKeys === 'workflows' ? '#BEC1FE' : '#fff'}
      />,
      undefined
    )
    items.splice(4, 0, workflowsItem)
  }

  if (flag.accounts) {
    items.splice(
      4,
      0,
      getItem(
        'Accounts',
        'accounts',
        <AccountsSVG
          fill="none"
          style={{
            marginTop: !collapsed ? '' : '8px',
            marginLeft: !collapsed ? '' : '-2px',
          }}
          className="w-[22px] h-[22px]"
          stroke={selectedKeys === 'accounts' ? '#BEC1FE' : '#fff'}
        />,
        undefined
      )
    )
  }

  const toggleCollapsed = () => {
    window.localStorage.setItem('navbarCollapsed', JSON.stringify(!collapsed))
    window.dispatchEvent(new Event('storage'))
    dispatch({
      type: 'SET_NAVBAR_COLLAPSED',
      payload: {
        collapsed: !collapsed,
      },
    })
  }

  const onItemClick: MenuProps['onClick'] = (e) => {
    const { key } = e
    if (key === '') return
    if (key === 'help') {
      window.open('https://help.thena.ai/', '_blank')
      track('Page View', {
        oldView: pathname,
        newView: 'https://help.thena.ai/',
      })
      return
    }
    if (key === 'logout') {
      setAuthState('LOGOUT')
    }
    if (key === 'feedback') {
      window.open('https://thena.canny.io/', '_blank')
      track('Page View', {
        oldView: pathname,
        newView: 'https://thena.canny.io/',
      })
    }
    if (
      key.includes('customer-requests-') ||
      key.includes('internal-helpdesk-')
    ) {
      colsDispatch({
        type: 'RESET_UNSAVED_COLS',
      })
      setIsClicked(key)
    } else {
      track('Page View', { oldView: pathname, newView: '/' + key })
      ;(window as any).analytics.page(pathname)
      setSelectedKeys(key)
      navigate('/' + key)
      setMobileView(window.innerWidth < 640)
      return
    }
  }

  const isAccountsPage = pathname === '/accounts'
  const aiConsentHidden = useAccountsStore((state) => state.aiConsent)

  return (
    <>
      <Layout style={{ height: '100vh', backgroundColor: '#fafbff' }} hasSider>
        <Modal
          centered
          title=""
          open={mobileView}
          width="370px"
          closable={false}
          footer={null}
        >
          <p>For better experience, use Desktop version of the app.</p>
        </Modal>
        {showSlider && (
          <Sider
            theme="dark"
            collapsed={collapsed}
            onCollapse={(value) =>
              dispatch({
                type: 'SET_NAVBAR_COLLAPSED',
                payload: {
                  collapsed: value,
                },
              })
            }
            width={240}
            style={{
              top: 0,
              left: 0,
              zIndex: 99999,
              height: '100vh',
              position: 'fixed',
              backgroundColor: '#0f052b',
              borderRight: '1px solid #DCE2F0',
            }}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                className={`flex flex-col items-center justify-center group ${
                  collapsed ? 'ml-3' : ''
                }`}
              >
                {!collapsed ? (
                  <div className="flex justify-between items-center w-[72%] mt-11 mb-4">
                    <OrgSelector expanded />
                  </div>
                ) : (
                  <div className="flex items-center justify-between w-4/5 mb-4 ml-3 mt-11">
                    <OrgSelector />
                  </div>
                )}
              </div>
              <ScrollableDiv>
                <div
                  className={`flex flex-col items-center justify-center group`}
                >
                  <div className="flex items-center justify-between w-4/5 mt-2">
                    <RequestSelector
                      expanded={!collapsed}
                      selectedKeys={selectedKeys}
                      setSelectedKeys={setSelectedKeys}
                      setMobileView={setMobileView}
                      items={items}
                    />
                  </div>
                </div>
                <div
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                  }}
                >
                  <div>
                    <Menu
                      theme="dark"
                      inlineIndent={10}
                      onClick={onItemClick}
                      defaultOpenKeys={[
                        'g1',
                        'sub5',
                        'grp1',
                        'g2',
                        'sub6',
                        'grp2',
                      ]}
                      defaultSelectedKeys={[
                        accountSubRoute.test(pathname)
                          ? 'account'
                          : pathname.slice(1),
                      ]}
                      selectedKeys={[selectedKeys]}
                      mode="inline"
                      inlineCollapsed={collapsed}
                      style={{
                        padding: '10px',
                      }}
                      className={`${
                        selectedKeys === 'onboarding-guide' ? 'customColor' : ''
                      } bg-[#0F052B]`}
                      items={items}
                    />
                  </div>
                </div>
              </ScrollableDiv>
              <div className="flex flex-col items-center justify-center pt-[20px] pb-[20px]">
                {!collapsed ? (
                  <div
                    style={{ color: 'var(--color-white)' }}
                    className="font-medium rounded-[4px] w-52 pl-4"
                  >
                    <div className="mb-[20px]">
                      <img
                        src={Logo}
                        width={22}
                        height={22}
                        className="mr-[10px]"
                      />
                      Thena App
                    </div>
                    <div
                      onClick={toggleCollapsed}
                      className="cursor-pointer flex items-center gap-[18px]"
                    >
                      <CaretDoubleLeft
                        size={22}
                        color="#E2E2E2"
                        className="cursor-pointer"
                      />
                      Collapse
                    </div>
                  </div>
                ) : (
                  <div>
                    <img
                      src={Logo}
                      alt="thena-logo"
                      width={22}
                      height={22}
                      className="block mb-[16px]"
                    />
                    <CaretDoubleRight
                      size={22}
                      color="#E2E2E2"
                      onClick={toggleCollapsed}
                      className="cursor-pointer"
                    />
                  </div>
                )}
              </div>
            </div>
          </Sider>
        )}
        {isOnboarding && pathname !== '/' && (
          <>
            <img src={LogoColor} alt="thena-logo" className="h-8 mt-4 ml-4" />
            <Button
              icon={<LogoutOutlined color="#4A299C" />}
              className="absolute mt-4 mr-4 text-brand-accent border-brand-accent right-5"
              type="ghost"
              size="middle"
              onClick={() => {
                setAuthState('LOGOUT')
              }}
            >
              Logout
            </Button>
          </>
        )}
        {showSlider ? (
          <Layout
            className={classNames('flex h-full site-layout w-full')}
            style={{ backgroundColor: '#fff' }}
          >
            <NewAppBanner />
            {isAccountsPage && <AIConsent collapsedSidenav={collapsed} />}
            {showSlider && <NewPageHeader collapsedSidenav={collapsed} />}
            <Content
              className={classNames(
                `pt-[44px] 
                ${
                  pathname.includes('/requests') && !isListView && 'w-full'
                } h-full mt-[65px] ${collapsed ? 'ml-[80px]' : 'ml-[240px]'} ${
                  isAccountsPage && !aiConsentHidden && 'mt-[48px]'
                } transition-all duration-300 ease-in-out`,
                !isOnboarding &&
                  !pathname.includes('/requests') &&
                  'overflow-y-auto',
                isOnboarding && 'overflow-y-hidden'
              )}
              ref={ref}
              hasSider
            >
              {children}
            </Content>
          </Layout>
        ) : (
          <Content
            className={classNames(
              'w-full h-full',
              !isOnboarding &&
                !pathname.includes('/requests') &&
                'overflow-y-auto',
              isOnboarding && 'overflow-y-hidden'
            )}
            ref={ref}
            hasSider
          >
            {children}
          </Content>
        )}
      </Layout>
    </>
  )
}
export { NewAppLayout }
