import { Button, Popover } from 'antd'
import styled from 'styled-components'
import AddNewBlock from '../AddNewBlock'
import { DeleteBlock } from '../Commons'

type IProps = {
  index: number
  size?: string
  altText: string
  imageUrl: string
  onClick: () => void
  showPlusIcon?: boolean
  showDeleteIcon?: boolean
  isReadOnly?: boolean
}

const Wrapper = styled.div<{ $isSmall: boolean }>`
  position: relative;
  padding-bottom: 20px;
  margin-top: 8px;
  .delete-icon {
    display: none;
    position: absolute;
    top: 16px;
    right: 8px;
    width: 24px;
    height: 24px;
    box-shadow: 0 0 4px 0 var(--color-red-3);
  }
  .render-plus-banner {
    display: none;
    position: absolute;
    bottom: -2px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
  }
  :hover {
    .delete-icon {
      display: flex;
    }
    .render-plus-banner {
      display: flex;
    }
  }
  ${({ $isSmall }) => {
    if ($isSmall) {
      return `padding-bottom: 0px;`
    }
  }}
`

const BlockImage = ({
  size = 'big',
  imageUrl,
  altText,
  onClick,
  index,
  showPlusIcon = true,
  showDeleteIcon = true,
  isReadOnly,
}: IProps) => {
  const addDefaultSrc = (event: any) => {
    event.target.src = '/images/broken-image.svg'
  }

  return !isReadOnly ? (
    <Popover
      placement="right"
      content={
        <div className="flex justify-end w-full">
          <Button type="link" className="font-medium" onClick={onClick}>
            Edit
          </Button>
        </div>
      }
      title={null}
    >
      <Wrapper $isSmall={size === 'small'}>
        <img
          className={`slack_img_${size} object-fill`}
          src={imageUrl}
          alt={altText}
          onError={addDefaultSrc}
        />
        {showDeleteIcon && <DeleteBlock index={index} />}
        {showPlusIcon && <AddNewBlock index={index} />}
      </Wrapper>
    </Popover>
  ) : (
    <Wrapper $isSmall={size === 'small'}>
      <img
        className={`slack_img_${size} object-fill`}
        src={imageUrl}
        alt={altText}
        onError={addDefaultSrc}
      />
    </Wrapper>
  )
}

export default BlockImage
