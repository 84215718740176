import { fromUnixTime } from 'date-fns'
import { toInteger } from 'lodash'
import moment from 'moment'
moment.updateLocale('en', {
  relativeTime: {
    s: 'a few seconds',
    ss: '%ds',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    w: '1w',
    ww: '%dw',
    M: '1mon',
    MM: '%dmon',
    y: '1y',
    yy: '%dy',
  },
})
export const getTime = (ts: string, type: string) => {
  if (type === 'request') {
    const timeSpent = moment(fromUnixTime(toInteger(ts))).fromNow()
    if (timeSpent.includes('a few seconds ago')) {
      return 'just now'
    } else {
      return timeSpent
    }
  } else if (type === 'ticket') {
    const timeSpent = moment(ts).fromNow()
    if (timeSpent.includes('a few seconds ago')) {
      return 'just now'
    } else {
      return timeSpent
    }
  }
}
