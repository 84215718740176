/* eslint-disable max-lines */
import { track } from '@amplitude/analytics-browser'
import { customStatusesDB } from 'DB/customStatusesDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { isEmpty, snakeCase, toUpper } from 'lodash'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useKanbanBoardStore } from 'store/kanbanBoardStore'
import { useKanbanStore2 } from 'store/kanbanStore2'
import { fetchConfigureFields } from '../api/kanban'
import { getParsedJsonArray } from '../pages/CustomStatusRequests/utils'
import { configuredFields } from '../reducer/kanbanReducer/kanbanReducerState'
import { useAccountsStore } from '../store/accountsStore'
import {
  ActionType,
  initialState,
  useKanbanStorePersist,
} from '../store/kanbanStorePersist'
import { KanbanStorePersist } from '../types/kanbanTypes'

const useRequestPageNavigation = ({
  item,
  setSelectedKeys,
  setMobileView,
}: {
  item: any
  setSelectedKeys?: React.Dispatch<React.SetStateAction<string>>
  setMobileView?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const kanbanDispatch = useKanbanStorePersist((state) => state.dispatch)
  const dispatch = useKanbanStore2((state) => state.dispatch)
  const allViewsList = useKanbanStorePersist((state) => state.allViewsList)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const tabValue = searchParams.get('tab')
  const currentViewName = useKanbanStorePersist(
    (state) => state.currentViewName
  )
  const orderedColumnsList: any = useKanbanStorePersist(
    (state) => state.orderedColumns
  )
  const statusList = useLiveQuery(async () => {
    const data = await customStatusesDB.list.orderBy('order').toArray()
    return data || []
  }, [])
  const isDataFetched = useLiveQuery(
    async () => {
      const data = await customStatusesDB.list.toArray()
      return data.length > 0
    },
    [],
    false
  )
  const statusArray = useMemo(() => {
    return isDataFetched
      ? statusList
          ?.map((item) => {
            if (item.sub_status.length > 0) {
              return item.sub_status.map((sub: any) =>
                toUpper(snakeCase(sub.name))
              )
            } else {
              return item.status === 'ONHOLD'
                ? 'ON_HOLD'
                : item.status === 'OPEN'
                ? 'UNASSIGNED'
                : item.status
            }
          })
          .flat()
      : []
  }, [isDataFetched, statusList])

  const { data, isSuccess } = useQuery(
    ['configured-fields'],
    fetchConfigureFields
  )
  const crmConfigList = useAccountsStore((state) => state.crmConfig)
  const updateState = (
    dispatchType: ActionType['type'],
    field: string,
    value: any,
    isArray = false
  ) => {
    if (isArray) {
      const parsedValue = getParsedJsonArray(value)
      if (parsedValue) {
        kanbanDispatch({
          type: dispatchType,
          payload: {
            [field]: parsedValue,
          },
        })
      }
      return
    }
    kanbanDispatch({
      type: dispatchType,
      payload: {
        [field]: value,
      },
    })
  }
  const initializeFilterState = (newParams: URLSearchParams) => {
    ;[...Object.keys(initialState), 'tab'].forEach((key) => {
      const typedKey = key as keyof KanbanStorePersist | 'tab'
      const value = newParams.get(typedKey)

      const isValueValid = value !== null && value !== undefined && value !== ''
      if (isValueValid) {
        switch (typedKey) {
          case 'search':
            updateState('SET_SEARCH', 'search', value)
            break

          case 'sortBy':
            updateState('SET_SORT_BY', 'sortBy', value)
            break

          case 'isEscalated':
            updateState('SET_IS_ESCALATED', 'isEscalated', Boolean(value))
            break

          case 'assignedTo':
            updateState('SET_ASSIGNED_TO', 'assignedTo', value, true)
            break

          case 'customers':
            updateState('SET_CUSTOMERS', 'customers', value, true)
            break

          case 'cardType':
            updateState('SET_CARD_TYPE', 'cardType', value, true)
            break

          case 'source':
            updateState('SET_SOURCE', 'source', value, true)
            break

          case 'csm':
            updateState('SET_CSM', 'csm', value, true)
            break

          case 'solutionEngineers':
            updateState(
              'SET_SOLUTION_ENGINEERS',
              'solutionEngineers',
              value,
              true
            )
            break

          case 'urgency':
            updateState('SET_URGENCY', 'urgency', value, true)
            break

          case 'sentiment':
            updateState('SET_SENTIMENT', 'sentiment', value, true)
            break

          case 'allTags':
            updateState('SET_ALL_TAGS', 'allTags', value, true)
            break

          case 'accountTypes':
            updateState('SET_ACCOUNT_TYPES', 'accountTypes', value, true)
            break

          default:
            break
        }
      } else {
        switch (typedKey) {
          case 'search':
            updateState('SET_SEARCH', 'search', '')
            break

          case 'sortBy':
            updateState('SET_SORT_BY', 'sortBy', 'Newest')
            break

          case 'isEscalated':
            updateState('SET_IS_ESCALATED', 'isEscalated', false)
            break

          case 'assignedTo':
            updateState(
              'SET_ASSIGNED_TO',
              'assignedTo',
              JSON.stringify([]),
              true
            )
            break

          case 'customers':
            updateState('SET_CUSTOMERS', 'customers', JSON.stringify([]), true)
            break

          case 'cardType':
            updateState('SET_CARD_TYPE', 'cardType', JSON.stringify([]), true)
            break

          case 'source':
            updateState('SET_SOURCE', 'source', JSON.stringify([]), true)
            break

          case 'csm':
            updateState('SET_CSM', 'csm', JSON.stringify([]), true)
            break

          case 'solutionEngineers':
            updateState(
              'SET_SOLUTION_ENGINEERS',
              'solutionEngineers',
              JSON.stringify([]),
              true
            )
            break

          case 'urgency':
            updateState('SET_URGENCY', 'urgency', JSON.stringify([]), true)
            break

          case 'sentiment':
            updateState('SET_SENTIMENT', 'sentiment', JSON.stringify([]), true)
            break

          case 'allTags':
            updateState('SET_ALL_TAGS', 'allTags', JSON.stringify([]), true)
            break

          case 'accountTypes':
            updateState(
              'SET_ACCOUNT_TYPES',
              'accountTypes',
              JSON.stringify([]),
              true
            )
            break

          default:
            break
        }
      }
    })
    if (isSuccess) {
      const configuredFieldKeys = (data as configuredFields[])?.map(
        (item) => item.category_name
      )
      configuredFieldKeys?.forEach((key) => {
        const paramValue = newParams.get(key)
        if (paramValue) {
          try {
            const parsedValue = JSON.parse(paramValue)

            if (!Array.isArray(parsedValue)) {
              throw new Error('Invalid value! Query: configuredFields')
            }
            kanbanDispatch({
              type: 'SET_CONFIGURED_FIELD',
              payload: {
                fieldName: key,
                fieldValue: parsedValue,
              },
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          kanbanDispatch({
            type: 'SET_CONFIGURED_FIELD',
            payload: {
              fieldName: key,
              fieldValue: [],
            },
          })
        }
      })
    }
    if (!isEmpty(crmConfigList?.additional_fields)) {
      const crmFieldKeys = crmConfigList?.additional_fields?.map(
        (item) => item.field_name
      )
      crmFieldKeys.forEach((key) => {
        const paramValue = newParams.get(key)
        if (paramValue) {
          try {
            const parsedValue = JSON.parse(paramValue)
            if (!Array.isArray(parsedValue)) {
              throw new Error('Invalid value! Query: crmFields')
            }
            kanbanDispatch({
              type: 'SET_CRM_FIELD',
              payload: {
                fieldName: key,
                fieldValue: parsedValue,
              },
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          kanbanDispatch({
            type: 'SET_CRM_FIELD',
            payload: {
              fieldName: key,
              fieldValue: [],
            },
          })
        }
      })
    }
  }
  const unsavedHiddenColumns = useKanbanBoardStore(
    (state) => state.unsavedHiddenColumns
  )
  const unsavedActiveColumns = useKanbanBoardStore(
    (state) => state.unsavedActiveColumns
  )
  const isTicketsPage = searchParams.get('cardType') ?? ''
  const hiddenColumnsList = useMemo(() => {
    const orderedColumnsList = useKanbanStorePersist.getState().orderedColumns
    if (tabValue === 'customer-requests') {
      if (currentViewName === 'Default View') {
        if (isTicketsPage.includes('Tickets')) {
          return allViewsList.External.Tickets.hiddenCols
        } else {
          return allViewsList.External.DefaultView.hiddenCols
        }
      } else {
        const currentSavedView = allViewsList.External.SavedViews.find(
          (item: any) => item.name === currentViewName
        )
        if (currentSavedView) {
          const hiddenCols = orderedColumnsList.filter(
            (item) => !currentSavedView?.activeCols?.includes(item)
          )
          return hiddenCols
        }
        return []
      }
    } else if (tabValue === 'internal-helpdesk') {
      if (currentViewName === 'Default View') {
        if (isTicketsPage.includes('Tickets')) {
          return allViewsList.Internal.Tickets.hiddenCols
        } else {
          return allViewsList.Internal.DefaultView.hiddenCols
        }
      } else {
        const currentSavedView = allViewsList.Internal.SavedViews.find(
          (item: any) => item.name === currentViewName
        )
        if (currentSavedView) {
          const hiddenCols = orderedColumnsList.filter(
            (item) => !currentSavedView?.activeCols?.includes(item)
          )
          return hiddenCols
        }
        return []
      }
    }
    return []
  }, [currentViewName, tabValue, isTicketsPage])

  const finalHiddenList = useMemo(() => {
    return [
      ...hiddenColumnsList.filter(
        (item) => !unsavedActiveColumns.includes(item)
      ),
      ...unsavedHiddenColumns,
    ]
  }, [hiddenColumnsList, unsavedActiveColumns, unsavedHiddenColumns])
  const pageState = () => {
    if (item?.key.includes('customer-requests-')) {
      kanbanDispatch({
        type: 'SET_SELECTED_TAB',
        payload: {
          selectedTab: 'customer-requests',
        },
      })
      if (item?.key === 'customer-requests-defaultView') {
        const externalDefaultView = allViewsList.External.DefaultView
        const url = new URLSearchParams(externalDefaultView.url.split('?')[1])
        kanbanDispatch({
          type: 'SET_CURRENT_VIEW_NAME',
          payload: {
            currentViewName: 'Default View',
          },
        })
        //resetting hidden states for default view
        const existingData = localStorage.getItem('requestDefaultView')
        let dataArray: any[] = []
        if (existingData) {
          // Parse the existing data
          const filteredExistingData = JSON.parse(existingData).filter(
            (item: any) => statusArray?.includes(item)
          )
          dataArray = filteredExistingData
        }
        statusArray?.forEach((element: string) => {
          if (!dataArray.includes(element)) {
            dataArray.push(element)
          }
        })
        localStorage.setItem('requestDefaultView', JSON.stringify(dataArray))
        track('Page View', { oldView: pathname, newView: '/requests' })
        ;(window as any).analytics.page(pathname)
        setSelectedKeys && setSelectedKeys(item?.key)
        setMobileView && setMobileView(window.innerWidth < 640)
        setSearchParams(() => {
          url.set('currentViewName', 'Default View')
          return url
        })
        navigate({
          pathname: '/requests',
          search: '?' + url.toString(),
        })
        initializeFilterState(url)
        return
      } else if (item?.key === 'customer-requests-tickets') {
        const externalDefaultView = allViewsList.External.Tickets
        const url = new URLSearchParams(externalDefaultView.url.split('?')[1])
        kanbanDispatch({
          type: 'SET_CURRENT_VIEW_NAME',
          payload: {
            currentViewName: 'Default View',
          },
        })
        //resetting hidden states for default view
        const existingData = localStorage.getItem('ticketDefaultView')
        let dataArray: any[] = []
        if (existingData) {
          // Parse the existing data
          const filteredExistingData = JSON.parse(existingData).filter(
            (item: any) => statusArray?.includes(item)
          )
          dataArray = filteredExistingData
        }
        ;['UNASSIGNED', 'INPROGRESS', 'ON_HOLD', 'CLOSED'].forEach(
          (element: string) => {
            if (!dataArray.includes(element)) {
              dataArray.push(element)
            }
          }
        )
        localStorage.setItem('ticketDefaultView', JSON.stringify(dataArray))
        track('Page View', { oldView: pathname, newView: '/requests' })
        ;(window as any).analytics.page(pathname)
        setSelectedKeys && setSelectedKeys(item?.key)
        setMobileView && setMobileView(window.innerWidth < 640)
        setSearchParams(() => {
          url.set('currentViewName', 'Default View')
          return url
        })
        navigate({
          pathname: '/requests',
          search: '?' + url.toString(),
        })
        initializeFilterState(url)
        return
      } else {
        const savedViews = allViewsList.External.SavedViews
        const [, viewKey] = item?.key?.split('customer-requests-') ?? ''
        const currentSavedView = savedViews.find(
          (item: any) => item.key === viewKey
        )
        if (!isEmpty(currentSavedView)) {
          const url = new URLSearchParams(currentSavedView?.url)
          kanbanDispatch({
            type: 'SET_CURRENT_VIEW_NAME',
            payload: {
              currentViewName: currentSavedView.name,
            },
          })
          const result = !isEmpty(currentSavedView?.orderedCols)
            ? currentSavedView?.orderedCols
                ?.filter((item: any) => statusArray?.includes(item))
                .map((item: any) =>
                  item === 'OPEN'
                    ? 'UNASSIGNED'
                    : item === 'ONHOLD'
                    ? 'ON_HOLD'
                    : item
                )
            : statusArray

          const visibleColumns = result?.filter(
            (column: any) => !finalHiddenList.includes(column)
          )

          dispatch({
            type: 'SET_CURRENT_SAVED_REORDER_COLS',
            payload: {
              currentSavedCols: visibleColumns,
            },
          })
          track('Page View', { oldView: pathname, newView: '/requests' })
          ;(window as any).analytics.page(pathname)
          setSelectedKeys && setSelectedKeys(item?.key)
          setMobileView && setMobileView(window.innerWidth < 640)
          setSearchParams(() => {
            url.set('currentViewName', currentSavedView.name ?? 'Default View')
            return url
          })
          navigate({
            pathname: '/requests',
            search: '?' + url.toString(),
          })
          initializeFilterState(url)
        }
      }
    } else if (item?.key.includes('internal-helpdesk-')) {
      kanbanDispatch({
        type: 'SET_SELECTED_TAB',
        payload: {
          selectedTab: 'internal-helpdesk',
        },
      })
      if (item?.key === 'internal-helpdesk-defaultView') {
        const internalDefaultView = allViewsList.Internal.DefaultView
        const url = new URLSearchParams(internalDefaultView.url.split('?')[1])
        kanbanDispatch({
          type: 'SET_CURRENT_VIEW_NAME',
          payload: {
            currentViewName: 'Default View',
          },
        })
        track('Page View', { oldView: pathname, newView: '/requests' })
        ;(window as any).analytics.page(pathname)
        setSelectedKeys && setSelectedKeys(item?.key)
        setMobileView && setMobileView(window.innerWidth < 640)
        setSearchParams(() => {
          url.set('currentViewName', 'Default View')
          return url
        })
        //resetting hidden states for default view
        const existingData = localStorage.getItem('internalRequestDefaultView')
        let dataArray: any[] = []
        if (existingData) {
          // Parse the existing data
          const filteredExistingData = JSON.parse(existingData).filter(
            (item: any) => statusArray?.includes(item)
          )
          dataArray = filteredExistingData
        }
        statusArray?.forEach((element: string) => {
          if (!dataArray.includes(element)) {
            dataArray.push(element)
          }
        })
        localStorage.setItem(
          'internalRequestDefaultView',
          JSON.stringify(dataArray)
        )
        navigate({
          pathname: '/requests',
          search: '?' + url.toString(),
        })
        initializeFilterState(url)
        return
      } else if (item?.key === 'internal-helpdesk-tickets') {
        const externalDefaultView = allViewsList.Internal.Tickets
        const url = new URLSearchParams(externalDefaultView.url.split('?')[1])
        kanbanDispatch({
          type: 'SET_CURRENT_VIEW_NAME',
          payload: {
            currentViewName: 'Default View',
          },
        })
        track('Page View', { oldView: pathname, newView: '/requests' })
        ;(window as any).analytics.page(pathname)
        setSelectedKeys && setSelectedKeys(item?.key)
        setMobileView && setMobileView(window.innerWidth < 640)
        setSearchParams(() => {
          url.set('currentViewName', 'Default View')
          return url
        })
        //resetting hidden states for default view
        const existingData = localStorage.getItem('internalTicketDefaultView')
        let dataArray: any[] = []
        if (existingData) {
          // Parse the existing data
          const filteredExistingData = JSON.parse(existingData).filter(
            (item: any) => statusArray?.includes(item)
          )
          dataArray = filteredExistingData
        }
        ;['UNASSIGNED', 'INPROGRESS', 'ON_HOLD', 'CLOSED'].forEach(
          (element: string) => {
            if (!dataArray.includes(element)) {
              dataArray.push(element)
            }
          }
        )
        localStorage.setItem(
          'internalTicketDefaultView',
          JSON.stringify(dataArray)
        )
        navigate({
          pathname: '/requests',
          search: '?' + url.toString(),
        })
        initializeFilterState(url)
        return
      } else {
        const savedViews = allViewsList.Internal.SavedViews
        const [, viewKey] = item?.key?.split('internal-helpdesk-') ?? ''
        const currentSavedView = savedViews.find(
          (item: any) => item.key === viewKey
        )
        if (!isEmpty(currentSavedView)) {
          const url = new URLSearchParams(currentSavedView.url)
          kanbanDispatch({
            type: 'SET_CURRENT_VIEW_NAME',
            payload: {
              currentViewName: currentSavedView.name,
            },
          })
          const result = !isEmpty(currentSavedView?.orderedCols)
            ? currentSavedView?.orderedCols
                ?.filter((item: any) => statusArray?.includes(item))
                .map((item: any) =>
                  item === 'OPEN'
                    ? 'UNASSIGNED'
                    : item === 'ONHOLD'
                    ? 'ON_HOLD'
                    : item
                )
            : statusArray
          const visibleColumns = result?.filter(
            (column: any) => !finalHiddenList.includes(column)
          )
          dispatch({
            type: 'SET_CURRENT_SAVED_REORDER_COLS',
            payload: {
              currentSavedCols: visibleColumns,
            },
          })
          track('Page View', { oldView: pathname, newView: '/requests' })
          ;(window as any).analytics.page(pathname)
          setSelectedKeys && setSelectedKeys(item?.key)
          setMobileView && setMobileView(window.innerWidth < 640)
          setSearchParams(() => {
            url.set('currentViewName', currentSavedView.name ?? 'Default View')
            return url
          })
          navigate({
            pathname: '/requests',
            search: '?' + url.toString(),
          })
          initializeFilterState(url)
        }
      }
    }
  }
  return { pageState, initializeFilterState }
}

export default useRequestPageNavigation
