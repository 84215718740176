import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons'
import { Button, Input, Modal, Table, message } from 'antd'
import { MagnifyingGlass } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { api } from '../../api'
import { useAuth } from '../../context'
const { Column } = Table
const { confirm } = Modal

const fetchDomains = async () => {
  try {
    return api.get('/v2/domains').then(({ data }) => data)
  } catch (error: any) {
    throw new Error('Failed to fetch users', error)
  }
}

const postDomain = async (domainData: { domain: string }) => {
  return api.post('/v2/domains', domainData)
}

const deleteDomain = async (domainname: any) => {
  return api.delete(`/v2/domains/name/${domainname}`)
}

export const ManageDomain = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [domainArray, setDomainArray] = useState<any[]>([])
  const [domainValue, setDomainValue] = useState('')
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (user && !user.isSPA) {
      navigate('/requests')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const { data, isLoading, isFetching, refetch } = useQuery(
    'domains',
    fetchDomains
  )

  useEffect(() => {
    if (Array.isArray(data)) {
      const arrayWithIndex = data.map((domain: any, index: number) => ({
        ...domain,
        idx: index + 1,
      }))
      setDomainArray(arrayWithIndex)
    }
  }, [data])

  const { mutate, isLoading: mutationLoading } = useMutation(postDomain, {
    onSuccess: () => {
      setDomainValue('')
      message.success('Domain added successfully')
      refetch()
    },
    onError: (err: any) => {
      message.error(err?.response?.data?.error)
    },
  })

  const { mutate: deleteMutation, isLoading: deletMutationLoading } =
    useMutation(deleteDomain, {
      onSuccess: () => {
        message.success('Domain deleted successfully')
        refetch()
      },
      onError: (err: any) => {
        message.error(err?.response?.data?.error)
      },
    })

  const showConfirm = (name: any) => {
    confirm({
      title: 'Do you really want to delete this domain?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteMutation(name)
      },
    })
  }

  return (
    <div className="m-4">
      <h1 className="text-center mb-4">Domains</h1>
      <div
        style={{ maxWidth: '700px', margin: 'auto' }}
        className="flex items-center justify-end gap-2"
      >
        <Button
          loading={isLoading || isFetching}
          onClick={() => refetch()}
          icon={<ReloadOutlined />}
        >
          {isLoading || isFetching ? 'Loading...' : 'Refresh'}
        </Button>
      </div>
      <div className="my-[14px]" />
      <div
        style={{ maxWidth: '700px', margin: 'auto' }}
        className="flex justify-between items-center gap-2"
      >
        <Input
          name="search"
          size="middle"
          id="search"
          className="shadow-sm !w-[220px]"
          placeholder="Search for domain name"
          prefix={<MagnifyingGlass color="#211E24" weight="bold" />}
          autoComplete="off"
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          allowClear
        />
        <div className="flex items-center gap-2">
          <label htmlFor="domainInput" className="cursor-pointer">
            Add a domain
          </label>
          <Input
            id="domainInput"
            value={domainValue}
            onChange={(e) => setDomainValue(e.target.value)}
            style={{ width: 200 }}
            placeholder="example.com"
            disabled={mutationLoading}
          />
          <Button
            onClick={() => {
              const domain = domainValue
              mutate({ domain })
            }}
            loading={mutationLoading}
            type="primary"
          >
            Add
          </Button>
        </div>
      </div>
      <div
        style={{ maxWidth: '700px', maxHeight: '70vh' }}
        className="ml-auto mr-auto mt-4"
      >
        <Table
          dataSource={domainArray.filter((it) =>
            it.domain.includes(searchValue)
          )}
          rowKey="id"
          showHeader
          loading={isFetching || isLoading}
        >
          <Column title="#" dataIndex="idx" key="idx" />
          <Column title="Domain Name" dataIndex="domain" key="domain" />
          <Column
            title="Delete"
            key="action"
            render={(_, record: any) => (
              <Button
                icon={<DeleteOutlined />}
                loading={deletMutationLoading}
                onClick={() => showConfirm(record.domain)}
              />
            )}
          />
        </Table>
      </div>
    </div>
  )
}
