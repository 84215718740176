export const BLOCKS: Record<string, any> = {
  'Markdown Text': {
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: 'This is a mrkdwn section block :ghost: *this is bold*, and ~this is crossed out~, and <https://google.com|this is a link>',
    },
  },
  'Plain Text': {
    type: 'section',
    text: {
      type: 'plain_text',
      text: 'This is a plain text section block.',
      emoji: true,
    },
  },
  Header: {
    type: 'header',
    text: {
      type: 'plain_text',
      text: 'This is a header block',
      emoji: true,
    },
  },
  Image: {
    type: 'image',
    title: {
      type: 'plain_text',
      text: 'Image Title',
      emoji: true,
    },
    image_url:
      'https://assets3.thrillist.com/v1/image/1682388/size/tl-horizontal_main.jpg',
    alt_text: '',
  },
  'Section with Image': {
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: 'This is a section block with an accessory image.',
    },
    accessory: {
      type: 'image',
      image_url:
        'https://pbs.twimg.com/profile_images/625633822235693056/lNGUneLX_400x400.jpg',
      alt_text: 'cute cat',
    },
  },
  'Section with Button': {
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: 'This is a section block with a button.',
    },
    accessory: {
      type: 'button',
      text: {
        type: 'plain_text',
        text: 'Click Me',
        emoji: true,
      },
      value: 'click_me_123',
      action_id: 'button-action',
      url: 'https://thena.ai',
    },
  },
  'Context with Image': {
    type: 'context',
    elements: [
      {
        type: 'image',
        image_url:
          'https://pbs.twimg.com/profile_images/625633822235693056/lNGUneLX_400x400.jpg',
        alt_text: 'cute cat',
      },
      {
        type: 'mrkdwn',
        text: '* Context with Markdown.*',
      },
    ],
  },
  Video: {
    type: 'video',
    title: {
      type: 'plain_text',
      text: 'How to use Slack.',
      emoji: true,
    },
    title_url: 'https://www.youtube.com/watch?v=RRxQQxiM7AA',
    description: {
      type: 'plain_text',
      text: "Slack is a new way to communicate with your team. It's faster, better organized and more secure than email.",
      emoji: true,
    },
    video_url:
      'https://www.youtube.com/embed/RRxQQxiM7AA?feature=oembed&autoplay=1',
    alt_text: 'How to use Slack?',
    thumbnail_url: 'https://i.ytimg.com/vi/RRxQQxiM7AA/hqdefault.jpg',
    author_name: 'Arcado Buendia',
    provider_name: 'YouTube',
    provider_icon_url:
      'https://a.slack-edge.com/80588/img/unfurl_icons/youtube.png',
  },
  Divider: {
    type: 'divider',
  },
}

export const BLOCK_TYPES = Object.keys(BLOCKS)
