import { Divider } from 'antd'
import { IntegrationModalHeader } from '../../../components'
import SalesforceSvg from '../../../images/logos/salesforce.svg'
import { AccountConfiguration } from './AccountConfiguration'
import { SalesforceSetup } from './SalesforceSetup'
type Props = {
  requestType: {
    label: string
    value: string
    disabled: boolean
  }[]
}
export const SalesforceIntegration = ({ requestType }: Props) => {
  return (
    <main className="w-full">
      <IntegrationModalHeader
        imgSrc={SalesforceSvg}
        imageClassName="h-10 w-14"
        title="Salesforce Integration"
        description="Customizable integration for CRM and customer support"
      />
      <Divider className="my-5 py-0" />
      <SalesforceSetup requestType={requestType} />
      <Divider className="my-6 py-0" />
      <AccountConfiguration />
    </main>
  )
}
