import { Avatar, Col, Row } from 'antd'
import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { fetchPublicChannels } from '../../../api/channels'
import { ChannelType } from '../../../modules/channels/definitions/types'
import { useAccountsStore } from '../../../store/accountsStore'
import {
  ACCOUNT_TYPE_LIST,
  RelationshipType,
} from '../../../types/accountsTypes'
import { emptyObject } from '../../../utils/empty'
import { pluralize } from '../../../utils/textUtils'
import {
  MultiEditCell,
  SingleEditCell,
  TextInputEditCell,
} from '../Table/ConfigEditCell'
import {
  DateSelectCell,
  SingleSelectChannelEditCell,
  SingleSelectEditCell,
  SingleSelectUserEditCell,
  TextEditCell,
} from '../Table/EditableCell'
const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
`

const SurplusIdicator = styled.div`
  right: 10px;
  font-weight: 600;
  font-size: 0.75rem;
  position: relative;
  color: var(--color-purple-1);
`

const UsersGroup = styled.div`
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 6px;
  }
`
const UserWithProfile = styled.div`
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 8px;
  }
  position: relative;
  .avatar-shift {
    top: 0;
    right: 12px;
    position: relative;
  }
`
export const DrawerFilters = ({
  drawer,
  conv,
}: {
  drawer: {
    data: any
    type: 'ACCOUNT_DRAWER'
  } | null
  conv: RelationshipType | undefined
}) => {
  const accountTypeList = ACCOUNT_TYPE_LIST.map((item) => ({
    id: item,
    name: capitalize(item),
  }))
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })
  const renderUsers = () => {
    if (conv?.users?.length) {
      const surplusElementCount = conv?.users.slice(2).length
      return (
        <UsersGroup key={`${drawer?.data?.record?._id}-users`}>
          {conv?.users.slice(0, 2).map(({ user }, index) => {
            return (
              <UserWithProfile>
                <StyledAvatar
                  size={20}
                  src={user?.slack?.profile?.image_original || ''}
                  className={index === 1 ? 'avatar avatar-shift' : 'avatar'}
                >
                  {user?.name}
                </StyledAvatar>
              </UserWithProfile>
            )
          })}
          {!!surplusElementCount && (
            <SurplusIdicator>+{surplusElementCount}</SurplusIdicator>
          )}
        </UsersGroup>
      )
    }
    return 'NA'
  }
  const crmConfig = useAccountsStore((state) => state.crmConfig) ?? emptyObject
  const { data: publicChannels } = useQuery(['fetchPublicChannels'], () =>
    fetchPublicChannels({})
  )

  const channelList = useMemo(() => {
    return (publicChannels?.channels || []).map((channel: ChannelType) => ({
      name: channel.name,
      id: channel.id,
    }))
  }, [publicChannels?.channels])

  return (
    <div className="p-6">
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            Account Type
          </div>
        </Col>
        <Col span={12}>
          <SingleSelectEditCell
            key={`${drawer?.data?.record?._id}-type`}
            value={conv?.crm_values?.type ?? ''}
            crmKey="type"
            record={drawer?.data?.record}
            index={0}
            dropdownList={accountTypeList}
            form={form}
            isMultiEditing={false}
          />
        </Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            ARR (USD)
          </div>
        </Col>
        <Col span={12}>
          <TextEditCell
            key={`${drawer?.data?.record?._id}-contractValue`}
            isNumber
            record={drawer?.data?.record}
            value={conv?.crm_values?.contract_value?.toString() || '0'}
            crmKey="contract_value"
            width={100}
            index={0}
            form={form}
            isMultiEditing={false}
          />
        </Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            Account Owner
          </div>
        </Col>
        <Col span={12}>
          <SingleSelectUserEditCell
            key={`${drawer?.data?.record?._id}-accountOwner`}
            value={
              conv?.users?.find((user: any) => user.role === 'csm') || null
            }
            record={drawer?.data?.record}
            crmKey="accountOwner"
            index={0}
            form={form}
            isMultiEditing={false}
          />
        </Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            Internal Slack Channel
          </div>
        </Col>
        <Col span={12}>
          <SingleSelectChannelEditCell
            key={`${drawer?.data?.record?._id}-internalSlackChannel`}
            value={conv?.crm_values?.internal_slack_channel_name || 'NA'}
            crmKey="internal_slack_channel_name"
            record={drawer?.data?.record}
            index={0}
            dropdownList={channelList}
            form={form}
            isMultiEditing={false}
          />
          {/* <TextEditCell
            key={`${drawer?.data?.record?._id}-internalSlackChannel`}
            value={conv?.crm_values?.internal_slack_channel_name || 'NA'}
            record={drawer?.data?.record}
            crmKey="internal_slack_channel_name"
            index={0}
            dropdownList={channelList}
            form={form}
            isMultiEditing={false}
          /> */}
        </Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            External Slack Channel
          </div>
        </Col>
        <Col span={12}>
          <div>{conv?.channel?.name}</div>
        </Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            Synced with CRM
          </div>
        </Col>
        <Col span={12}>
          <div>{conv?.crm_values?.synced_with_crm ? 'Yes' : 'No'}</div>
        </Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            Users
          </div>
        </Col>
        <Col span={12}>{renderUsers()}</Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            Start Date
          </div>
        </Col>
        <Col span={12}>
          <DateSelectCell
            key={`${drawer?.data?.record?._id}-contractStartDate`}
            value={conv?.crm_values?.contract_start_date ?? undefined}
            record={drawer?.data?.record}
            index={0}
            form={form}
            crmKey="contract_start_date"
            isMultiEditing={false}
          />
        </Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            Renewal date
          </div>
        </Col>
        <Col span={12}>
          <DateSelectCell
            key={`${drawer?.data?.record?._id}-contractEndDate`}
            value={conv?.crm_values?.contract_end_date ?? undefined}
            record={drawer?.data?.record}
            form={form}
            index={0}
            crmKey="contract_end_date"
            isMultiEditing={false}
          />
        </Col>
        <Col span={12}>
          <div className="font-semibold font-xs text-[var(--color-black-1)]">
            Contract Duration
          </div>
        </Col>
        <Col span={12}>
          <TextEditCell
            key={`${drawer?.data?.record?._id}-contractTerm`}
            isNumber
            value={conv?.crm_values?.contract_term || '0'}
            record={drawer?.data?.record}
            postFix={pluralize(
              ' month',
              Number(conv?.crm_values?.contract_term || 0)
            )}
            crmKey="contract_term"
            width={100}
            form={form}
            index={0}
            isMultiEditing={false}
          />
        </Col>
        {crmConfig?.additional_fields?.map((filter) => {
          const values = conv?.crm_values?.additional_fields?.find(
            (item) => item.field_name === filter.field_name
          )
          return (
            <>
              <Col span={12}>
                <div className="font-semibold font-xs text-[var(--color-black-1)]">
                  {filter.field_name}
                </div>
              </Col>
              <Col span={12}>
                {filter.type === 'radio' || filter.type === 'boolean' ? (
                  <SingleEditCell
                    field={filter}
                    value={values ? values.values : null}
                    record={drawer?.data?.record}
                    isMultiEditing={false}
                  />
                ) : filter.type === 'select' ||
                  filter.type === 'multiselect' ||
                  filter.type === 'checkbox' ? (
                  <MultiEditCell
                    field={filter}
                    record={drawer?.data?.record}
                    isMultiEditing={false}
                  />
                ) : (
                  <TextInputEditCell
                    field={filter}
                    record={drawer?.data?.record}
                    isMultiEditing={false}
                  />
                )}
              </Col>
            </>
          )
        })}
      </Row>
    </div>
  )
}
