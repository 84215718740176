import { useEffect, useRef } from 'react'

/**
 * @param func memoized function wrapped in useCallback or useMemo
 * @param deps dependencies passed to useEffect and the above func
 * @param timeout defaults to 500 (in milliseconds)
 */

const useDebounceEffect = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  func: (args: any) => void,
  deps: unknown,
  timeout = 500
) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(func, timeout, deps)

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [func, deps, timeout])
}

export default useDebounceEffect
