import { CancelToken } from 'axios'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment'
import Pusher from 'pusher-js'
import { lazy, Suspense, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { Route, Routes, useLocation } from 'react-router-dom'
import { fetchCRMConfig, fetchRelationships } from '../api/accounts'
import { fetchKanban, fetchKanbanRequest } from '../api/kanban'
import { ProductTourCard } from '../components'
import { NewAppLayout } from '../components/common/layout/NewLayout'
import ErrorBoundary from '../components/ErrorBoundary'
import { LOGIN_URL } from '../config'
import { useAuth } from '../context'
import { AddToSlackProvider } from '../context/AddToSlackProvider'
import { accountDB } from '../DB/AccountDB'
import { ConnectedAppProvider } from '../modules/ConnectedApps'
import {
  AddToSlack,
  Blocked,
  FourZeroFour,
  ProductTour,
  TooSmall,
  Welcome,
  WelcomeQuestion,
} from '../pages'
import { AuthCallback } from '../pages/AuthCallback'
import { ManageDomain } from '../pages/ManageDomains/ManageDomains'
import { Templates } from '../pages/MarketingAutomation/Templates'
import { SettingUp } from '../pages/SettingUp'
import { useAccountsStore } from '../store/accountsStore'
import { useKanbanBoardStore } from '../store/kanbanBoardStore'
import { useKanbanStore2 } from '../store/kanbanStore2'
import { AccountType, RelationshipType } from '../types/accountsTypes'
import RedirectToExternal from './RedirectToExternal'
import useInitParagon from './useInitParagon'
import { initPusher } from './usePusherInit'
import useRedirects from './useRedirects'
import Organizations from 'pages/Organizations/Organizations'
import OrganizationLogs from 'pages/Organizations/OrganizationLogs'
const Accounts = lazy(() => import('../pages/Accounts/LandingTabs'))
const Analytics = lazy(() => import('../pages/WSAnalytics/Landing'))
const RequestsRevamped = lazy(
  () => import('../pages/CustomStatusRequests/KanbanLanding')
)
const MarketingAnalytics = lazy(
  () => import('../pages/MarketingAutomation/MarketingAnalytics')
)
const MarketingAutomation = lazy(
  () => import('../pages/MarketingAutomation/MarketingAutomation')
)
const MAEditor = lazy(
  () => import('../pages/MarketingAutomation/NewTemplateEditor/MAEditor')
)
const KnowledgeAssistant = lazy(() => import('../pages/KnowledgeAssistant'))
const Workflows = lazy(() => import('../pages/Workflows'))
const Settings = lazy(() => import('../pages/Settings/Settings'))
const SentTemplateEditor = lazy(
  () => import('../modules/MarketingAutomation/SentTemplateEditor')
)
const WebUsers = lazy(() => import('../pages/WebUsers/WebUsers'))

const crmRoutes = [
  '/requests',
  '/accounts',
  '/marketing-automation',
  '/analytics',
  '/settings',
]

const NewRoute = () => {
  useRedirects()
  useInitParagon()
  const flag = useFlags()
  const dispatch = useAccountsStore((state) => state.dispatch)
  const { pathname } = useLocation()
  const { user } = useAuth()
  const isGodUser = user?.godMode || false
  const queryClient = useQueryClient()
  const pusherInitRef = useRef(false)

  useEffect(() => {
    let channelName = ''
    let pusher: Pusher | null = null

    const isUserInfoPresent =
      !!user?.team_id && !!user?.id && !!user?.displayName

    if (isUserInfoPresent && !pusherInitRef.current) {
      pusher = initPusher(user.displayName, user.id)

      pusher.connection.bind('connected', function () {
        if (!pusher) {
          return
        }
        pusherInitRef.current = true
        console.log('Pusher init success')
        channelName = `private-${user?.team_id}`
        const channel = pusher?.subscribe(channelName)
        channel.bind('request', async function async(requestPusherData: any) {
          try {
            if (requestPusherData.request_id) {
              const prevItem =
                useKanbanBoardStore.getState().records[
                  requestPusherData.request_id
                ]
              if (prevItem && prevItem.requestId) {
                const requestInfo = await fetchKanbanRequest(
                  prevItem.requestId,
                  prevItem.teamId
                )
                if (requestInfo) {
                  useKanbanBoardStore.dispatch({
                    type: 'UPDATE_RECORD_PUSHER',
                    payload: {
                      _id: requestInfo._id,
                      data: requestInfo,
                    },
                  })
                }
              } else {
                // solution for this hack -> API to get request from mongo Id
                const currentEpoch = moment().subtract(1, 'minute').valueOf()
                const payload = {
                  filters: {
                    startDate: '',
                    endDate: '',
                    fetchAfterEpoch: currentEpoch,
                  },
                }
                const response = await fetchKanban(
                  payload,
                  null as unknown as CancelToken
                )
                const allRecords = response.records || []
                if (allRecords.length) {
                  const recordsObject = allRecords.reduce(
                    (acc: any, item: any) => {
                      return {
                        ...acc,
                        [item._id]: item,
                      }
                    },
                    {}
                  )
                  const isDummyDataPresent =
                    useKanbanStore2.getState().isSampleData
                  if (isDummyDataPresent) {
                    useKanbanBoardStore.dispatch({
                      type: 'PUT_RECORDS',
                      payload: {
                        records: recordsObject,
                      },
                    })
                    useKanbanStore2.dispatch({
                      type: 'INIT',
                      payload: {
                        total: response?.total,
                        isSampleData: response?.isSampleData,
                      },
                    })
                  } else {
                    useKanbanBoardStore.dispatch({
                      type: 'BULK_UPDATE_RECORDS',
                      payload: {
                        records: recordsObject,
                      },
                    })
                  }
                }
              }
            }
          } catch (error) {
            console.log(error)
          }
        })
        channel.bind('ticket', function (ticketPusherData: any) {
          console.log({ ticketPusherData })
          queryClient.invalidateQueries(['kanban-data-on-date-change'])
        })
      })
    } else {
      console.log('Pusher init failed')
    }

    return () => {
      if (pusher && channelName) {
        pusher.unsubscribe(channelName)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    // if (isOlder) {
    if (crmRoutes.some((route) => pathname.includes(route))) {
      fetchRelationships()
        .then((data: RelationshipType[]) => {
          const normalized_data = data.map((element: RelationshipType) => ({
            ...element,
            crm_values: {
              ...element.crm_values,
              type: element.crm_values?.type?.toLowerCase() as AccountType,
            },
          }))
          accountDB.relationship.bulkPut(normalized_data)
        })
        .catch((err) => console.log(err))
      // }
      fetchCRMConfig()
        .then((data) =>
          dispatch({
            type: 'INIT_CRM_CONFIG_DATA',
            payload: { crmConfig: data },
          })
        )
        .catch((err) => console.log(err))
    }
  }, [pathname])

  return (
    <ErrorBoundary>
      <NewAppLayout>
        <ConnectedAppProvider>
          <AddToSlackProvider>
            <Suspense>
              <Routes>
                <Route
                  path="/"
                  element={<RedirectToExternal url={LOGIN_URL} />}
                />
                <Route
                  path="/logout"
                  element={<RedirectToExternal url={LOGIN_URL} />}
                />
                <Route path="/login" element={<Welcome />} />
                <Route path="/setting-up" element={<SettingUp />} />
                <Route path="/welcome" element={<WelcomeQuestion />} />
                <Route path="/product-tour" element={<ProductTour />} />
                <Route
                  path="/product-tour/:productTourKey"
                  element={<ProductTourCard />}
                />
                <Route path="/add-to-slack" element={<AddToSlack />} />
                <Route path="/blocked" element={<Blocked />} />
                <Route path="/too_small" element={<TooSmall />} />
                <Route path="/web-users" element={<WebUsers />} />
                {flag.accounts && (
                  <Route path="/accounts" element={<Accounts />} />
                )}
                <Route path="/slack/callback" element={<AuthCallback />} />
                <Route path="/requests" element={<RequestsRevamped />} />
                {/* <Route path="/requests" element={<RequestsV2 />} /> */}
                <Route path="/settings" element={<Settings />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route
                  path="/marketing-automation"
                  element={<MarketingAutomation />}
                />
                <Route
                  path="/marketing-automation/:campaignId"
                  element={<MarketingAnalytics />}
                />
                <Route
                  path="/marketing-automation/editor/:campaignId"
                  element={<MAEditor />}
                />
                <Route
                  path="/marketing-automation/sent/:campaignId"
                  element={<SentTemplateEditor />}
                />
                <Route
                  path="/marketing-automation/templateList"
                  element={<Templates />}
                />
                <Route
                  path="/knowledge-assistant/*"
                  element={<KnowledgeAssistant />}
                />
                <Route path="/workflows/*" element={<Workflows />} />
                <Route path="/domains" element={<ManageDomain />} />
                <Route
                  path="/internal/organizations"
                  element={isGodUser ? <Organizations /> : <FourZeroFour />}
                />
                <Route
                  path="/impersonation/logs"
                  element={isGodUser ? <OrganizationLogs /> : <FourZeroFour />}
                />
                <Route path="*" element={<FourZeroFour />} />
              </Routes>
            </Suspense>
          </AddToSlackProvider>
        </ConnectedAppProvider>
      </NewAppLayout>
    </ErrorBoundary>
  )
}

export default NewRoute
