import { Button, Popover } from 'antd'
import { formatSlackMarkdown } from '../utils'

const BlockActions = ({
  elements,
  onClick,
  isReadOnly,
}: {
  elements: any[]
  onClick: () => void
  isReadOnly?: boolean
}) => (
  <div className="flex items-center gap-1 flex-wrap mb-3">
    {Array.isArray(elements) &&
      (elements || []).map((ele, index) => {
        if (ele?.type === 'button') {
          return !isReadOnly ? (
            <Popover
              placement="right"
              content={
                <div className="flex justify-end w-full">
                  <Button type="link" className="font-medium" onClick={onClick}>
                    Edit
                  </Button>
                </div>
              }
              title={null}
            >
              <Button
                key={index}
                type="default"
                className={`mr-2 font-semibold ${
                  ele.style === 'primary'
                    ? 'text-[#027b5b] border-[#027b5b] font-bold'
                    : ''
                } ${
                  ele.style === 'danger'
                    ? 'text-[#e2376b] border-[#e2376b] font-bold'
                    : ''
                }`}
                href={ele?.url}
                target="_blank"
              >
                <div
                  className="slack_section_text"
                  dangerouslySetInnerHTML={{
                    __html: formatSlackMarkdown(
                      typeof ele?.text?.text === 'string' ? ele?.text?.text : ''
                    ),
                  }}
                />
              </Button>
            </Popover>
          ) : (
            <Button
              key={index}
              type="default"
              className={`mr-2 font-semibold ${
                ele.style === 'primary'
                  ? 'text-[#027b5b] border-[#027b5b] font-bold'
                  : ''
              } ${
                ele.style === 'danger'
                  ? 'text-[#e2376b] border-[#e2376b] font-bold'
                  : ''
              }`}
              href={ele?.url}
              target="_blank"
            >
              <div
                className="slack_section_text"
                dangerouslySetInnerHTML={{
                  __html: formatSlackMarkdown(
                    typeof ele?.text?.text === 'string' ? ele?.text?.text : ''
                  ),
                }}
              />
            </Button>
          )
        }
        return null
      })}
  </div>
)

export default BlockActions
