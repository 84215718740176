/* eslint-disable max-lines */
import { Button, DatePicker, Input, message } from 'antd'
import { useLiveQuery } from 'dexie-react-hooks'
import { camelCase } from 'lodash'
import capitalize from 'lodash/capitalize'
import flattenDeep from 'lodash/flattenDeep'
import { useMemo, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { fetchRelationships } from '../../../api/accounts'
import HorizontalList from '../../../components/common/HorizontalList/HorizontalList'
import { accountDB } from '../../../DB/AccountDB'
import AccountCategorySvg from '../../../images/icons/account-category-filter.svg'
import AccountSvg from '../../../images/icons/account-filter-icon.svg'
import AccountOwnerSvg from '../../../images/icons/account-owner-filter.svg'
import ArrSvg from '../../../images/icons/arr-icon.svg'
import { useAccountsStore } from '../../../store/accountsStore'
import {
  AccountFilterType,
  ACCOUNT_TYPE_LIST,
  AdditonalFieldType,
  RelationshipType,
  UserFilterType,
  UserType,
} from '../../../types/accountsTypes'
import { getUniqueArrayByObjectKey } from '../utils'
import MutipleSelect from './MutipleSelect'
import SingleSelect from './SingleSelect'

const Wrapper = styled.div`
  height: 40px;
  padding: 24px;
  display: flex;
  align-items: center;

  .accounts-filter-chip {
    flex-shrink: 0;
    margin-right: 8px;
  }
  .accounts-filter-chip:last-child {
    margin-right: 0;
  }
  position: relative;
  .scroll-wrapper {
  }
`

const ClearFilter = styled.div`
  height: 28px;
  right: 160px;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  border-radius: 6px;
  position: absolute;
  align-items: center;
  padding: 1px 8px 0 6px;
  color: var(--color-gray-6);
  border: 1px solid var(--color-gray-3);
  .cross-icon {
    margin-right: 4px;
  }
`

const Refresh = styled(Button)<{ $isFetching: boolean }>`
  right: 24px;
  height: 28px;
  width: 125px;
  display: flex;
  padding: 0 4px;
  position: absolute;
  align-items: center;
  color: var(--color-blue-1);
  justify-content: space-evenly;
  border-color: var(--color-blue-1);
  :hover {
    color: var(--color-blue-1);
    border-color: var(--color-blue-1);
  }
  :disabled {
    background-color: unset;
    color: var(--color-blue-1);
    border-color: var(--color-blue-1);
    :hover {
      background-color: unset;
      color: var(--color-blue-1);
      border-color: var(--color-blue-1);
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  ${({ $isFetching }) => {
    if ($isFetching) {
      return css`
        .refresh-icon {
          animation: rotate 1.5s linear infinite;
        }
      `
    }
  }}
`

const accountTypeFilterItems = ACCOUNT_TYPE_LIST.map((item) => ({
  id: item,
  name: capitalize(item),
}))
export interface TableFilter {
  onFormSubmit: () => void
  onFormCancel: () => void
  selectedRowKeys: Array<string>
  form: UseFormReturn
  dynamicFilters: AdditonalFieldType[]
}
const TableFilters = (props: TableFilter) => {
  const { onFormSubmit, onFormCancel, selectedRowKeys, form } = props
  const [isFetching, setIsFetching] = useState(false)
  const [accountTypesList, setAccountTypeList] = useState<any>([])
  const [accountOwnerList, setAccountOwnerList] = useState<any>([])
  const [dynamicList, setDynamicList] = useState<any>([])
  const [dateRangeStart, setDateRangeStart] = useState<any>(undefined)
  const [dateRangeEnd, setDateRangeEnd] = useState<any>(undefined)
  const dispatch = useAccountsStore((state) => state.dispatch)
  const accountTypes = useAccountsStore((state) => state.filters.accountTypes)
  const accountOwners = useAccountsStore((state) => state.filters.accountOwners)
  const renewalDateRange = useAccountsStore(
    (state) => state.filters.renewalDateRange
  )
  const contractStartDateRange = useAccountsStore(
    (state) => state.filters.contractStartDateRange
  )
  const allUsers = useLiveQuery(
    async () => {
      const data = await accountDB.relationship.toArray()
      return flattenDeep(data.map((rel) => rel.users)) as UserType[]
    },
    [],
    [] as UserType[]
  )

  const userList = useMemo(() => {
    const list = allUsers.map((user) => {
      return {
        name: user?.user?.name || '',
        id: user?.user?.slack?.id || '',
        src: user?.user?.slack?.profile?.image_original || '',
      }
    })
    return getUniqueArrayByObjectKey(list, 'id')
  }, [allUsers])

  const selectedList = useMemo(() => {
    const list = accountOwners.map((user) => {
      return {
        name: user?.user?.name || '',
        id: user?.user?.slack?.id || '',
        src: user?.user?.slack?.profile?.image_original || '',
      }
    })
    return getUniqueArrayByObjectKey(list, 'id')
  }, [accountOwners])

  const setAccountTypes = (type: AccountFilterType) => {
    selectedRowKeys.forEach((formKey) => {
      form.setValue(`${formKey}.type`, type.id, { shouldDirty: true })
    })
    setAccountTypeList([type])
  }

  const setAccountOwners = (user: UserFilterType) => {
    selectedRowKeys.forEach((formKey) => {
      form.setValue(`${formKey}.accountOwner`, user.id, { shouldDirty: true })
    })
    setAccountOwnerList([user])
  }

  const handleClearAccountOwners = () => {
    setAccountOwnerList([])
  }

  const handleClearAccountTypes = () => {
    setAccountTypeList([])
  }
  const handleClearDynamicList = () => {
    setAccountOwnerList([])
  }

  const handeClearFilter = () => {
    dispatch({ type: 'CLEAR_FILTERS' })
  }

  const setRenewalDateRange = (_value: unknown, dateString: string) => {
    selectedRowKeys.forEach((formKey) => {
      form.setValue(`${formKey}.contract_end_date`, _value, {
        shouldDirty: true,
      })
    })
    setDateRangeEnd(_value)
  }

  const setStartDateRange = (_value: unknown, dateString: string) => {
    selectedRowKeys.forEach((formKey) => {
      form.setValue(`${formKey}.contract_start_date`, _value, {
        shouldDirty: true,
      })
    })
    setDateRangeStart(_value)
  }

  const onRefreshClick = () => {
    setIsFetching(true)
    fetchRelationships()
      .then((data: RelationshipType[]) => {
        accountDB.relationship.bulkPut(data)
        // dispatch({
        //   type: 'INIT_RELATIONSHIPS_DATA',
        //   payload: {
        //     relationships: data,
        //     updatedAt: getCurrentTimeStamp(),
        //   },
        // })
        message.success('Refreshed Successfully!')
      })
      .catch((err) => {
        console.log(err)
        message.error('Something went wrong!')
      })
      .finally(() => setIsFetching(false))
  }
  const handleArrChange = (e: any) => {
    selectedRowKeys.forEach((formKey) => {
      form.setValue(`${formKey}.contract_value`, e.target.value, {
        shouldDirty: true,
      })
    })
  }
  const handleContactDurationChange = (e: any) => {
    selectedRowKeys.forEach((formKey) => {
      form.setValue(`${formKey}.contract_term`, e.target.value, {
        shouldDirty: true,
      })
    })
  }
  const handleAccountNameChange = (e: any) => {
    selectedRowKeys.forEach((formKey) => {
      form.setValue(`${formKey}.name`, e.target.value, { shouldDirty: true })
    })
  }
  const [dynamicSingleFilter, setDynamicSingleFilter] = useState<any>(null)
  const setDynamicFilter = (e: any, filter: AdditonalFieldType) => {
    selectedRowKeys.forEach((formKey) => {
      const field = camelCase(filter.field_name)
      form.setValue(`${formKey}.${field}`, filter._id, {
        shouldDirty: true,
      })
    })
    setDynamicList([e])
  }
  const renderDynamicFilters = () => {
    return props.dynamicFilters.map((filter) => {
      if (
        filter.type === 'select' ||
        filter.type === 'multiselect' ||
        filter.type === 'checkbox'
      ) {
        return (
          <MutipleSelect
            itemList={filter.available_values}
            label={filter.field_name}
            iconAlt={filter.field_name}
            iconSrc={AccountOwnerSvg}
            onChange={(e) => setDynamicFilter(e, filter)} //TOdo: fix this
            selectedList={dynamicList}
            className="accounts-filter-chip"
            clearList={handleClearDynamicList}
          />
        )
      }
      if (filter.type === 'radio') {
        return (
          <SingleSelect
            itemList={filter.available_values}
            label={filter.field_name}
            iconAlt={filter.field_name}
            iconSrc={AccountOwnerSvg}
            onChange={setAccountOwners} //TOdo: fix this
            selectedItem={dynamicSingleFilter}
            className="accounts-filter-chip"
            setSelectedItem={setDynamicSingleFilter}
          />
        )
      }
      if (filter.type === 'text') {
        return (
          <Input
            placeholder={filter.field_name}
            prefix={<img src={AccountSvg} />}
            className="accounts-filter-chip"
            style={{
              width: '100px',
              height: '28px',
              border: '1px solid #e5e5ea',
            }}
            size="small"
            onChange={handleAccountNameChange}
          />
        )
      }
    })
  }
  return (
    <Wrapper>
      <HorizontalList
        listItems={
          <>
            <label className="accounts-filter-chip">
              {selectedRowKeys.length
                ? `${selectedRowKeys.length} selected`
                : ''}
            </label>
            <MutipleSelect
              showSearch={false}
              label="Account type"
              iconAlt="account-type"
              onChange={setAccountTypes}
              selectedList={accountTypesList}
              iconSrc={AccountCategorySvg}
              className="accounts-filter-chip"
              itemList={accountTypeFilterItems}
              clearList={handleClearAccountTypes}
            />
            <MutipleSelect
              renderChipsAsAvatar
              itemList={userList}
              label="Account owner"
              iconAlt="account-owner"
              iconSrc={AccountOwnerSvg}
              onChange={setAccountOwners}
              selectedList={accountOwnerList}
              className="accounts-filter-chip"
              clearList={handleClearAccountOwners}
            />
            <DatePicker
              placeholder="Start Date"
              className="accounts-filter-chip"
              onChange={setStartDateRange}
              //value={moment(dateRangeStart ?? undefined)}
              format="MM.DD.YYYY"
              size="small"
              style={{ height: '28px', border: '1px solid #e5e5ea' }}
            />
            <DatePicker
              placeholder="Renewal Date"
              //value={moment(dateRangeEnd ?? undefined)}
              className="accounts-filter-chip"
              onChange={setRenewalDateRange}
              format="MM.DD.YYYY"
              size="small"
              style={{ height: '28px', border: '1px solid #e5e5ea' }}
            />
            <Input
              className="accounts-filter-chip"
              placeholder="Arr"
              prefix={<img src={ArrSvg} />}
              type="number"
              style={{
                width: '100px',
                height: '28px',
                border: '1px solid #e5e5ea',
              }}
              size="small"
              onChange={handleArrChange}
            />
            <Input
              type="number"
              placeholder="Contract Duration"
              className="accounts-filter-chip"
              style={{
                width: '100px',
                height: '28px',
                border: '1px solid #e5e5ea',
              }}
              size="small"
              onChange={handleContactDurationChange}
            />
            <Input
              placeholder="AccountName"
              prefix={<img src={AccountSvg} />}
              className="accounts-filter-chip"
              style={{
                width: '100px',
                height: '28px',
                border: '1px solid #e5e5ea',
              }}
              size="small"
              onChange={handleAccountNameChange}
            />
            {renderDynamicFilters()}
            <Button
              type="primary"
              onClick={onFormCancel}
              className="accounts-filter-chip"
            >
              Cancel
            </Button>
            <div className="flex justify-end accounts-filter-chip">
              <Button type="primary" onClick={onFormSubmit}>
                Save
              </Button>
            </div>
          </>
        }
      />
    </Wrapper>
  )
}

export default TableFilters
