import { Button, message, Tooltip } from 'antd'
import { ArrowsCounterClockwise, X } from 'phosphor-react'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSocket } from '../../../../src/context/SocketProvider'
import { fetchAccountsFromCRM, fetchRelationships } from '../../../api/accounts'
import HorizontalList from '../../../components/common/HorizontalList/HorizontalList'
import { accountDB } from '../../../DB/AccountDB'
import { useAccountsStore } from '../../../store/accountsStore'
import { RelationshipType } from '../../../types/accountsTypes'
import { emptyObject } from '../../../utils/empty'
import { AddFilter } from './AddFilter'
import { DefaultFilters } from './DefaultFilters'
import { FilterList } from './FilterList'

const Wrapper = styled.div`
  height: 40px;
  padding: 0px 24px 15px 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-3);
  .accounts-filter-chip {
    flex-shrink: 0;
    margin-right: 8px;
  }
  .accounts-filter-chip:last-child {
    margin-right: 0;
  }
  position: relative;
  .scroll-wrapper {
    margin-right: 230px;
  }
`

const ClearFilter = styled.div`
  height: 28px;
  right: 55px;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  border-radius: 6px;
  position: absolute;
  align-items: center;
  padding: 1px 8px 0 6px;
  color: var(--color-gray-6);
  font-weight: 500;
  box-shadow: none;
  :hover,
  :active {
    border-radius: 4px;
    color: var(--color-gray-6);
    background-color: var(--color-gray-3);
  }
  :focus {
    color: var(--color-gray-6);
  }
  ::after {
    display: none;
  }
  .cross-icon {
    margin-right: 4px;
  }
`

export const Refresh = styled(Button)<{
  $isFetching: boolean
  $fromTable: boolean
  $positionFree?: boolean
}>`
  right: 40px;
  height: 12px;
  width: 12px;
  display: flex;
  border: none;
  padding: 0 4px;
  position: absolute;
  align-items: center;
  color: var(--color-blue-1);
  :hover {
    color: var(--color-blue-1);
    border-color: var(--color-blue-1);
  }
  :disabled {
    background-color: unset;
    color: var(--color-blue-1);
    border-color: var(--color-blue-1);
    :hover {
      background-color: unset;
      color: var(--color-blue-1);
      border-color: var(--color-blue-1);
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  ${({ $isFetching }) => {
    if ($isFetching) {
      return css`
        .refresh-icon {
          animation: rotate 1.5s linear infinite;
        }
      `
    }
  }}
  ${({ $fromTable }) => {
    if ($fromTable) {
      return css`
        height: 12px;
        width: 12px;
        border: none;
        right: 30px;
      `
    }
  }}
  ${({ $positionFree }) => {
    if ($positionFree) {
      return css`
        position: static;
        width: 16px;
        border: none;
      `
    }
  }}
`

const Filters = () => {
  const [isFetching, setIsFetching] = useState(false)
  const dispatch = useAccountsStore((state) => state.dispatch)
  const crmConfig = useAccountsStore((state) => state.crmConfig) ?? emptyObject
  const crmDetails = useAccountsStore((state) => state.crmConfig)
  const { socket } = useSocket()
  const filteredCustomField = crmConfig?.additional_fields?.filter(
    (field) => field.type !== 'text'
  )
  const handeClearFilter = () => {
    dispatch({ type: 'CLEAR_FILTERS' })
  }

  useEffect(() => {
    socket?.on('relationships-updated-from-crm', (data) => {
      accountDB.relationship
        .bulkPut(data)
        .then(() => message.success('Refreshed Successfully!'))
        .catch((err) => {
          console.log(err)
          message.error('Something went wrong!')
        })
        .finally(() => setIsFetching(false))
    })
    return () => {
      socket?.off('relationships-updated-from-crm', () => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRefreshClick = () => {
    setIsFetching(true)
    if (crmDetails?.type) {
      fetchAccountsFromCRM(crmDetails?.type, undefined)
        .then((data: RelationshipType[]) => {
          accountDB.relationship
            .bulkPut(data)
            .then(() => message.success('Refreshed Successfully!'))
        })
        .catch(() => setIsFetching(false))
    } else {
      fetchRelationships()
        .then((data: RelationshipType[]) => {
          accountDB.relationship
            .bulkPut(data)
            .then(() => message.success('Refreshed Successfully!'))
        })
        .catch((err) => {
          console.log(err)
          message.error('Something went wrong!')
        })
        .finally(() => setIsFetching(false))
    }
  }
  const renderFilters = () => {
    return (
      <>
        <DefaultFilters />
        <FilterList />
      </>
    )
  }
  return (
    <Wrapper>
      <HorizontalList listItems={renderFilters()} />
      {filteredCustomField?.length > 4 && <AddFilter />}
      <ClearFilter onClick={handeClearFilter}>
        <X
          size={12}
          weight="bold"
          className="cross-icon"
          color="var(--color-gray-6)"
        />
        Clear filters
      </ClearFilter>
      <Tooltip title="Refresh data" placement="left">
        <Refresh
          disabled={isFetching}
          $isFetching={isFetching}
          $fromTable={false}
          onClick={onRefreshClick}
        >
          <ArrowsCounterClockwise
            size={16}
            weight="bold"
            className="refresh-icon cursor-pointer"
            style={{ flexShrink: 0 }}
            color="var(--color-blue-1)"
          />
        </Refresh>
      </Tooltip>
    </Wrapper>
  )
}

export default Filters
