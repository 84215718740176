import AccountCategorySvg from '../../../images/icons/account-category-filter.svg'
import { useAccountsStore } from '../../../store/accountsStore'
import { emptyObject } from '../../../utils/empty'
import MutipleSelect from './MutipleSelect'

export const FilterList = () => {
  const dynamicFilters = useAccountsStore((state) => state.dynamicFilters)
  const crmConfig = useAccountsStore((state) => state.crmConfig) ?? emptyObject
  const dispatch = useAccountsStore((state) => state.dispatch)
  const filters = useAccountsStore((state) => state.filters)
  const listOfFields = crmConfig?.additional_fields?.map((field) => field._id)
  const handleClearDynamicFilter = (filter: string) => {
    const updatedFilters = filters.crmFilter.filter(
      (item) => item.field_name !== filter
    )
    dispatch({
      type: 'UPDATE_FILTER_KEY',
      payload: {
        data: updatedFilters,
        key: 'crmFilter',
      },
    })
  }
  const setDynamicFilter = (value: any, filter: string, _id: string) => {
    const existingFilterIndex = filters.crmFilter.findIndex(
      (item) => item._id === _id
    )
    const selectedValues = getSelectedValuesForField(filter)
    if (!selectedValues.includes(value.value)) {
      if (existingFilterIndex !== -1) {
        // If the filter already exists, update its values
        const updatedFilters = [...filters.crmFilter]
        const selectedValues = [...updatedFilters[existingFilterIndex].values]

        // Check if the selected value already exists, if not, add it
        if (!selectedValues.includes(value.value)) {
          selectedValues.push(value.value)
          updatedFilters[existingFilterIndex].values = selectedValues
          dispatch({
            type: 'UPDATE_FILTER_KEY',
            payload: {
              data: updatedFilters,
              key: 'crmFilter',
            },
          })
        }
      } else {
        // If the filter doesn't exist, add it to the list
        const newFilter = {
          field_name: filter,
          values: [value.value],
          _id: _id,
        }
        dispatch({
          type: 'UPDATE_FILTER_KEY',
          payload: {
            data: [...filters.crmFilter, newFilter],
            key: 'crmFilter',
          },
        })
      }
    } else {
      // Remove the value from the selected list
      const updatedFilters = [...filters.crmFilter]
      const selectedValues = [...updatedFilters[existingFilterIndex].values]
      const valueIndex = selectedValues.indexOf(value.value)

      if (valueIndex !== -1) {
        selectedValues.splice(valueIndex, 1)
        updatedFilters[existingFilterIndex].values = selectedValues
        dispatch({
          type: 'UPDATE_FILTER_KEY',
          payload: {
            data: updatedFilters,
            key: 'crmFilter',
          },
        })
      }
    }
  }

  const getSelectedValuesForField = (fieldName: string) => {
    const filter = filters.crmFilter.find(
      (item) => item.field_name === fieldName
    )
    return filter ? filter.values : []
  }
  const renderDynamicFilter = () => {
    if (dynamicFilters) {
      if (dynamicFilters.length > 4) {
        return dynamicFilters.map((filter) => {
          if (listOfFields.includes(filter)) {
            const fieldValue = crmConfig?.additional_fields?.find(
              (field) => field._id === filter
            )
            if (fieldValue) {
              const { type, available_values, field_name, _id } = fieldValue
              const selectedValues = getSelectedValuesForField(field_name)
              if (type !== 'text' && type !== 'multiline') {
                const checkboxes = available_values.map(
                  ({ name, value, _id }) => ({
                    name: name,
                    value: value,
                    id: value,
                    _id: _id,
                    checked: selectedValues.includes(value),
                  })
                )
                return (
                  <>
                    <MutipleSelect
                      key={_id}
                      label={field_name}
                      iconAlt={field_name}
                      onChange={(e) => {
                        setDynamicFilter(e, field_name, _id)
                      }}
                      selectedList={selectedValues.map((value: string) => ({
                        name: value,
                        value,
                        id: value,
                      }))}
                      iconSrc={AccountCategorySvg}
                      className="accounts-filter-chip"
                      itemList={checkboxes}
                      clearList={() => handleClearDynamicFilter(field_name)}
                    />
                  </>
                )
              }
            }
          }
        })
      } else {
        return crmConfig?.additional_fields?.map((filter) => {
          if (filter) {
            const { type, available_values, field_name, _id } = filter
            const selectedValues = getSelectedValuesForField(field_name)
            if (type !== 'text' && type !== 'multiline') {
              const checkboxes = available_values.map(
                ({ name, value, _id }) => ({
                  name: name,
                  value: value,
                  id: value,
                  _id: _id,
                  checked: selectedValues.includes(value),
                })
              )
              return (
                <>
                  <MutipleSelect
                    key={_id}
                    label={field_name}
                    iconAlt={field_name}
                    onChange={(e) => {
                      setDynamicFilter(e, field_name, _id)
                    }}
                    selectedList={selectedValues.map((value: string) => ({
                      name: value,
                      value,
                      id: value,
                    }))}
                    iconSrc={AccountCategorySvg}
                    className="accounts-filter-chip"
                    itemList={checkboxes}
                    clearList={() => handleClearDynamicFilter(field_name)}
                  />
                </>
              )
            }
          }
        })
      }
    }
  }

  return <>{renderDynamicFilter()}</>
}
