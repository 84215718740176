import { Button, Card, Col, Row, Typography } from 'antd'
import Meta from 'antd/lib/card/Meta'
import {
  BellRinging,
  CaretLeft,
  ChartBar,
  ChatCircleDots,
  Hash,
  MapTrifold,
  Megaphone,
  PlugsConnected,
  ShieldCheck,
  Smiley,
} from 'phosphor-react'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { CALENDLY_LINK } from '../../config'
import { useAuth } from '../../context'
import SlackIcon from '../../images/logos/slack.svg'
import { addToSlackInfo } from '../AddToSlack/definitions'
import './index.css'
const { Text, Link: TextLink, Paragraph } = Typography

const cardDetails = [
  {
    title: 'Request Management',
    backgroundColor: '#F6F1FF',
    cover: 'request-management.svg',
    icon: ChatCircleDots,
    mirrored: false,
    svg: true,
    route: 'request-management',
  },
  {
    title: 'SLAs & Alerts',
    backgroundColor: '#E1E4FE',
    cover: 'manage-alerts.svg',
    icon: BellRinging,
    mirrored: false,
    svg: true,
    route: 'manage-alerts',
  },
  {
    title: 'Engagement Analytics & Insights',
    backgroundColor: '#F6F1FF',
    cover: 'analytics.svg',
    icon: ChartBar,
    mirrored: false,
    svg: true,
    route: 'analytics',
  },
  {
    title: 'Connectors',
    backgroundColor: '#FEFCE8',
    cover: 'connectors.svg',
    icon: PlugsConnected,
    mirrored: false,
    svg: false,
    route: 'connectors',
  },
  {
    title: 'Marketing Automation',
    backgroundColor: '#F6F1FF',
    cover: 'marketing.svg',
    icon: Megaphone,
    mirrored: true,
    svg: true,
    route: 'marketing-automation',
  },
  {
    title: 'Channel Operations',
    backgroundColor: '#E1E4FE',
    cover: 'channel-operations.svg',
    icon: Hash,
    mirrored: false,
    svg: true,
    route: 'channel-operations',
  },
  {
    title: 'Data Security & Compliance',
    backgroundColor: '#E1E4FE',
    cover: 'security.svg',
    icon: ShieldCheck,
    mirrored: false,
    svg: true,
    route: 'security-data',
  },
  {
    title: 'Customer Story',
    backgroundColor: '#FEFCE8',
    cover: 'customer.svg',
    icon: Smiley,
    mirrored: false,
    svg: true,
    route: 'customer-story',
  },
]

const ProductTour = () => {
  const { user } = useAuth()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [back, setBack] = useState('/requests')
  useEffect(() => {
    if (state !== null) {
      setBack(state?.from)
    }
  }, [state?.from])
  return (
    <div
      className={
        !user?.isThenaInstalled
          ? 'flex flex-col justify-center items-center max-w-6xl 3xl:mx-[265px] overflow-y-hidden productTour ml-[58px] mt-[20px] 3xl:mt-16 relative'
          : 'flex flex-col justify-center items-center max-w-5xl 3xl:mx-[325px] overflow-y-hidden productTour 3xl:mt-16 ml-32 mt-[40px] relative'
      }
    >
      <div
        className={
          !user?.isThenaInstalled
            ? 'bg-white w-full h-[750px] m-10 flex justify-center items-center flex-col p-16 gap-10 productTourCard border border-brand-border border-solid rounded-md'
            : 'bg-white w-full h-[750px] flex justify-center items-center flex-col p-4 gap-10 productTourCard border border-brand-border border-solid rounded-md'
        }
      >
        <div
          className={
            !user?.isThenaInstalled
              ? 'xl:mt-[500px] 2xl:mt-96 w-full mb-20'
              : 'w-full mb-20 xl:mt-[400px]'
          }
        >
          {!user?.isThenaInstalled ? (
            <div className="flex justify-between items-center w-full mb-10">
              <Text className="font-bold text-xl flex items-center gap-1">
                <MapTrifold size={30} color="#9096A4" /> Product Tour
              </Text>
              <Link to="/add-to-slack">
                <Button
                  type="link"
                  className="font-medium text-brand-accent"
                  size="large"
                >
                  Skip
                </Button>
              </Link>
            </div>
          ) : (
            <div className="flex justify-between items-center w-full mb-10">
              <Button
                type="link"
                className="font-medium text-brand-accent flex items-center"
                size="large"
                onClick={() => navigate(back)}
                icon={<CaretLeft />}
              >
                Go Back
              </Button>
            </div>
          )}
          <Row gutter={[32, 32]}>
            {cardDetails.map((card) => (
              <Col span={8} key={card.title}>
                <Link to={`/product-tour/${card.route}`}>
                  <Card
                    hoverable
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: card.backgroundColor,
                    }}
                    cover={
                      <img
                        src={`https://thena-medias.s3.us-west-1.amazonaws.com/product-tour/${card.cover}`}
                        alt=""
                        height={220}
                        style={{ padding: '14px' }}
                      />
                    }
                  >
                    <Meta
                      title={
                        <div className="flex items-center justify-start gap-1">
                          <card.icon
                            size={30}
                            color="#696F7B"
                            mirrored={card.mirrored}
                          />
                          <Paragraph
                            className="font-bold text-sm"
                            ellipsis={{ rows: 1 }}
                            style={{ margin: 0 }}
                          >
                            {card.title}
                          </Paragraph>
                        </div>
                      }
                    />
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      {!user?.isThenaInstalled ? (
        <div className="bg-white w-[72rem] absolute bottom-0 z-40 py-5 flex justify-center items-center buttonDiv mb-[40px]">
          <Button
            href={addToSlackInfo.card.buttonLink}
            type="default"
            icon={
              <img
                className="w-6 h-6 mr-3 mt-6"
                src={SlackIcon}
                alt="slack icon"
              />
            }
            size="large"
            className="w-fit flex justify-center items-center btn py-6 px-6 font-bold border border-solid border-gray-dark rounded-[4px]"
          >
            <span className="mt-6">{addToSlackInfo.card.buttonText}</span>
          </Button>
        </div>
      ) : (
        <div className="bg-white w-[64rem] absolute bottom-0 z-40 py-5 flex justify-center items-center buttonDiv">
          <Button
            type="default"
            size="large"
            target="_blank"
            href={`${CALENDLY_LINK}`}
            className="font-bold border border-solid border-gray-dark rounded-[4px]"
          >
            Schedule Demo
          </Button>
        </div>
      )}
    </div>
  )
}

export { ProductTour }
