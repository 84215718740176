type IProps = {
  stroke?: string
}

const CopyIcon = ({ stroke = '#6F6F75' }: IProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70001 10.124C3.24672 10.6996 3.00091 11.4023 3 12.125C3 13.989 4.56702 15.5001 6.50004 15.5001H10.7779C12.7109 15.5001 14.2779 13.989 14.2779 12.125C14.2779 10.2611 12.7109 8.75 10.7779 8.75H8.60006M16.2999 10.3761C16.7532 9.80046 16.999 9.09779 16.9999 8.37504C16.9999 6.51106 15.4329 5 13.4999 5H9.22207C7.28905 5 5.72203 6.51106 5.72203 8.37504C5.72203 10.239 7.28905 11.7501 9.22207 11.7501H11.3999"
        stroke={stroke}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default CopyIcon
