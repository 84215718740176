import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
interface PageInfo {
  heading: string
  helpLink: string
  tourId: string
  search: string
  helphub_scroll?: string
  isRequestPage?: boolean
}
// Define your route mappings here
const routeMappings = [
  {
    path: '/onboarding-guide',
    heading: 'Onboarding Guide',
    helpLink: 'https://help.thena.ai/docs/getting-started-with-thena',
    tourId: '116729',
    search: '',
  },
  {
    path: '/marketing-automation/editor/',
    heading: 'Marketing Automation',
    helpLink: 'https://help.thena.ai/docs/marketing-automation#campaign-editor',
    tourId: '201994',
    search: '',
    helphub_scroll: '1546',
  },
  {
    path: '/marketing-automation/templateList',
    heading: 'Marketing Automation',
    helpLink: 'https://help.thena.ai/docs/marketing-automation#use-cases',
    tourId: '201994',
    search: '',
    helphub_scroll: '729',
  },
  {
    path: '/marketing-automation/',
    heading: 'Marketing Automation',
    helpLink: 'https://help.thena.ai/docs/campaign-analytics',
    tourId: '116747',
    search: '',
  },
  {
    path: '/marketing-automation',
    heading: 'Marketing Automation',
    helpLink: 'https://help.thena.ai/docs/marketing-automation',
    tourId: '201994',
    search: '',
  },
  {
    path: '/requests',
    search: 'customer-requests',
    heading: 'Requests',
    helpLink: 'https://help.thena.ai/docs/request-management-thena-triage',
    tourId: '138665',
    isRequestPage: true,
  },
  {
    path: '/requests',
    search: 'internal-helpdesk',
    heading: 'Requests',
    helpLink: 'https://help.thena.ai/docs/internal-helpdesk',
    tourId: '116743',
    isRequestPage: true,
  },
  {
    path: '/requestsv2',
    heading: '',
    helpLink: '',
    tourId: '',
    search: '',
  },
  {
    path: '/analytics',
    heading: 'Workspace Analytics',
    helpLink: 'https://help.thena.ai/docs/request-overview',
    tourId: '275114',
    search: 'overview',
  },
  {
    path: '/analytics',
    heading: 'Workspace Analytics',
    helpLink: 'https://help.thena.ai/docs/tag-performance-analytics',
    tourId: '364479',
    search: 'performance',
  },
  {
    path: '/analytics',
    heading: 'Workspace Analytics',
    helpLink: 'https://help.thena.ai/docs/custom-analytics',
    tourId: '364478',
    search: '',
  },
  {
    path: '/settings',
    heading: 'Global Settings',
    helpLink: 'https://help.thena.ai/docs/connectors',
    tourId: '117750',
    search: 'integrations',
  },
  {
    path: '/settings',
    heading: 'Global Settings',
    helpLink: 'https://help.thena.ai/docs/configure-installation',
    tourId: '138671',
    search: 'config',
  },
  {
    path: '/settings',
    heading: 'Global Settings',
    helpLink: 'https://help.thena.ai/docs/admin-page',
    tourId: '138670',
    search: 'team',
  },
  {
    path: '/settings',
    heading: 'Global Settings',
    helpLink: '',
    tourId: '',
    search: '',
  },
  {
    path: '/accounts',
    heading: 'Accounts',
    helpLink: 'https://help.thena.ai/docs/accounts', // not yet created
    tourId: '395607', // to confirm
    search: '',
  },
  {
    path: '/knowledge-assistant',
    heading: 'Knowledge Assistant',
    helpLink: 'https://help.thena.ai/docs/knowledge-assistant', // to confirm
    tourId: '451235', //to confirm
    search: '',
  },
  {
    path: '/workflows',
    heading: 'Workflows',
    helpLink: 'https://help.thena.ai/docs/workflows', // to confirm
    tourId: '451333', //to confirm
    search: '',
  },
]

const usePageInfo = () => {
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    heading: '',
    helpLink: '',
    tourId: '',
    search: '',
    helphub_scroll: '',
    isRequestPage: false,
  })
  const flags = useFlags()
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname
    const search = location.search
    const url = new URLSearchParams(search)
    const isTicketsPage = url.get('cardType') ?? ''
    const matchingRoute = routeMappings.find((route) => {
      return route.search !== ''
        ? pathname.includes(route.path) &&
            search.toLowerCase().includes(route.search)
        : pathname.includes(route.path)
    })
    if (matchingRoute) {
      if (isTicketsPage.includes('Tickets') && flags.requestCustomStatus) {
        setPageInfo({
          heading: 'Tickets',
          helpLink: matchingRoute?.helpLink,
          tourId: matchingRoute?.tourId,
          search: matchingRoute?.search,
          helphub_scroll: matchingRoute?.helphub_scroll,
          isRequestPage: matchingRoute?.isRequestPage,
        })
      } else
        setPageInfo({
          heading: matchingRoute?.heading,
          helpLink: matchingRoute?.helpLink,
          tourId: matchingRoute?.tourId,
          search: matchingRoute?.search,
          helphub_scroll: matchingRoute?.helphub_scroll,
          isRequestPage: matchingRoute?.isRequestPage,
        })
    }
  }, [flags.requestCustomStatus, location.pathname, location.search])

  return pageInfo
}

export default usePageInfo
