import axios from 'axios'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect, useRef } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './context'
import AppRoutes from './routes/AppRoutes'
import CustomerPortalRoutes from './routes/CustomerPortalRoutes'
import NewRoutes from './routes/NewRoutes'

declare global {
  interface Window {
    analytics: any
  }
}

const App = () => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const { user, setAuthState } = useAuth()

  useEffect(() => {
    if (user && !user.superUser) {
      ;(async () => {
        await ldClient?.identify({
          kind: 'multi', // LDMultiKindContext
          ['installation-team-id']: {
            key: user.primaryInstallation?.team?.id,
            name: user.primaryInstallation?.team?.name,
          },
          user: {
            key: user?.id,
          },
        })
      })()
    }
  }, [ldClient, user])

  const navigate = useNavigate()
  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          refetchOnReconnect: false,
        },
      },
      queryCache: new QueryCache({
        onSuccess: (data: any) => {
          if (data?.sendToSettingUpPage) {
            navigate('/setting-up')
          }
        },
        onError: (error) => {
          if (axios.isAxiosError(error)) {
            if (error && error?.response?.status === 401) {
              setAuthState('LOGOUT')
            }
          }
        },
      }),
    })
  )

  const getAppRoutes = () => {
    if (user?.is_customer) {
      return <CustomerPortalRoutes />
    }
    if (flags.requestCustomStatus !== undefined) {
      if (!flags.requestCustomStatus) {
        return <AppRoutes />
      }
      return <NewRoutes />
    }
    return <AppRoutes />
  }

  return (
    <QueryClientProvider client={queryClient.current}>
      {getAppRoutes()}
    </QueryClientProvider>
  )
}

export default App
