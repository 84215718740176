import EmojiConvertor from 'emoji-js'
import { DraggableStateSnapshot, DropAnimation } from 'react-beautiful-dnd'
import { escapeForSlackWithMarkdown } from 'slack-to-html'
import { convertHtmlBlockquotesToSlack } from './slackFormatting'
const emoji = new EmojiConvertor()
export const getBorderColor = (type: string) => {
  if (type === 'UNASSIGNED') {
    return '#A6D7F9'
  } else if (type === 'CLOSED') {
    return '#90CFA7'
  } else if (type === 'IN_PROGRESS') {
    return '#F1D5BC'
  } else if (type === 'ON_HOLD') {
    return '#B1A8E7'
  }
}

export const getData = (type: string) => {
  if (type === 'Unassigned') {
    return { bgColor: 'bg-[#EFF3FF]' }
  } else if (type === 'Closed') {
    return { bgColor: 'bg-[#EBF7F3]' }
  } else if (type === 'Escalated') {
    return { bgColor: 'bg-[#FF472E]' }
  } else if (type === 'In-Progress') {
    return { bgColor: 'bg-[#FFF6EA]' }
  } else if (type === 'On Hold') {
    return { bgColor: 'bg-[#F4F4F8]' }
  }
}

export const getStyle = (style: any, snapshot: DraggableStateSnapshot) => {
  if (!snapshot.isDropAnimating) {
    return { ...style, paddingBottom: '8px', opacity: 1 }
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation as DropAnimation
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`
  return {
    ...style,
    transform: `${translate}`,
    transition: `all ${curve} ${duration}s`,
  }
}

export const handleCustomEmoji = (message: string, escapeNewline = true) => {
  if (escapeNewline) {
    return emoji.replace_colons(
      escapeForSlackWithMarkdown(
        convertHtmlBlockquotesToSlack(message.replaceAll(/:(.*?):/g, ': $1 :'))
      )
        .replaceAll('\n', '<br/>')
        .replaceAll(/:\s*(.*?)\s*:/g, ':$1:')
    )
  }
  return emoji.replace_colons(
    escapeForSlackWithMarkdown(
      convertHtmlBlockquotesToSlack(message.replaceAll(/:(.*?):/g, ': $1 :'))
    ).replaceAll(/:\s*(.*?)\s*:/g, ':$1:')
  )
}
