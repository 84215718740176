import { Empty } from 'antd'
import { ArrowUpRight } from 'phosphor-react'
import styled from 'styled-components'

export const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
`
export const EmptyTitle = styled.div`
  color: #0c0c0d;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`
export const EmptyDescription = styled.div`
  color: #45444b;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`
export const GuideWrapper = styled.span`
  color: #355de0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
  cursor: pointer;
`

export const EmptyState = ({
  title,
  description,
  footer,
  helpText,
  image,
}: {
  title: string
  description: string
  footer: string
  helpText: string
  image: React.ReactNode
}) => {
  return (
    <EmptyWrapper>
      <Empty
        image={image}
        description={
          <div className="flex flex-col gap-[4px] w-full justify-center items-center">
            <EmptyTitle>{title}</EmptyTitle>
            <EmptyDescription>{description}</EmptyDescription>
            <EmptyDescription className="mt-2 flex items-center justify-start gap-1">
              {footer}{' '}
              <GuideWrapper className="flex items-center justify-start gap-1">
                {helpText} <ArrowUpRight />
              </GuideWrapper>
            </EmptyDescription>
          </div>
        }
        className="w-[496px]"
      />
    </EmptyWrapper>
  )
}
