import { api } from '.'
import { SavePersonaType } from '../modules/personas/definitions'

export const fetchVendors = ({
  channelIds,
  page = 0,
}: {
  channelIds: string[]
  page: number
}) =>
  api
    .get('/v1/persona/vendors', { params: { channel_ids: channelIds } })
    .then(({ data: { data } }) => data)

export const fetchCustomers = ({
  channelIds,
  page = 0,
}: {
  channelIds: string[]
  page: number
}) =>
  api
    .get('/v1/persona/customers', { params: { channel_ids: channelIds } })
    .then(({ data: { data } }) => data)

export const fetchPersonaList = ({
  isThenaSetUp = false,
  page = 0,
  search = '',
}: {
  isThenaSetUp?: boolean
  page?: number
  search?: string
}) =>
  api
    .get('/v1/persona', { params: { isThenaSetUp, page, search } })
    .then(({ data: { data } }) => data)

export const savePersona = (data: SavePersonaType) =>
  api.post('/v1/persona', data).then(({ data: { data } }) => data)

export const fetchPersonaDetails = (channelId: string) =>
  api.get(`/v1/persona/${channelId}`).then(({ data: { data } }) => data)

export const fetchCSM = ({ channelIds }: { channelIds: string[] }) =>
  api
    .get('/v1/persona/csms', { params: { channel_ids: channelIds } })
    .then(({ data: { data } }) => data)
