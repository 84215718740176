import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  createAssistant as createAssistantApi,
  fetchAssistants,
} from '../api/assistant'
import { AssistantBodyType } from '../types'
const useAssistants = ({
  onCreateSuccess,
  onCreateError,
}: {
  onCreateSuccess?: (assistantId: string) => void
  onCreateError?: (error: Error | unknown) => void
} = {}) => {
  const queryClient = useQueryClient()
  const { isLoading, error, data } = useQuery('assistantsList', fetchAssistants)

  const createMutation = useMutation(createAssistantApi, {
    onSuccess: (data) => {
      if (data && data.length > 0) {
        queryClient.invalidateQueries('assistantsList')
        onCreateSuccess && onCreateSuccess(data[data.length - 1]._id)
      } else {
        onCreateError && onCreateError(new Error('Something went wrong'))
      }
    },
    onError: (error) => {
      onCreateError && onCreateError(error)
    },
  })

  const createAssistant = (newItemData: AssistantBodyType) => {
    createMutation.mutate(newItemData)
  }

  return {
    isLoading,
    error,
    data,
    createAssistant,
    createStatus: createMutation,
  }
}

export default useAssistants
