import { Badge, Collapse } from 'antd'
import styled from 'styled-components'
import { SVG_Element } from '../../../types/kanbanTypes'
const { Panel } = Collapse

const Wrapper = styled.div`
  .collapsible-row {
    border: none;
    border-radius: 0;
    .ant-collapse-header {
      padding: 5px !important;
      padding-left: 8px !important;
      border: none;
      display: flex !important;
      align-items: center !important;
    }
    .ant-collapse-content {
      border: none !important;
    }
  }
  .collapsible-panel {
    border: none;
    border-radius: 0;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  border-top: 1px solid var(--color-gray-7);
`

type Props = {
  name: string
  isOpen: boolean
  itemsList: any[]
  Icon: SVG_Element
  sectionKey: string
  backgroundColor: string
  onPanelToggle: (key: string) => void
}

const Header = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
  color: var(--color-brown-1);
  svg {
    margin-right: 8px;
  }
`

const Section = ({
  name,
  Icon,
  isOpen,
  itemsList,
  sectionKey,
  onPanelToggle,
  backgroundColor,
}: Props) => {
  return (
    <Wrapper>
      <Collapse
        style={{ backgroundColor }}
        className="collapsible-row"
        onChange={() => onPanelToggle(sectionKey)}
        activeKey={isOpen ? [sectionKey] : undefined}
      >
        <Panel
          key={sectionKey}
          header={
            <Header>
              <Icon />
              {name}
              <Badge
                showZero
                overflowCount={10000}
                count={itemsList.length}
                color="var(--color-white)"
                style={{
                  marginLeft: '6px',
                  borderColor: 'var(--color-brown-2)',
                }}
              />
            </Header>
          }
          className="collapsible-panel"
        />
      </Collapse>
    </Wrapper>
  )
}

export default Section
