import { message } from 'antd'
import { accountDB } from '../../DB/AccountDB'
import { updateCRMValuesOnRelation } from '../../api/accounts'
import { AccountsStateType, FiltersType } from '../../types/accountsTypes'

export const defaultFilters: FiltersType = {
  accountTypes: [],
  accountOwners: [],
  renewalDateRange: null,
  contractStartDateRange: null,
  crmFilter: [],
}

const accountsInitialState: AccountsStateType = {
  aiConsent: false,
  relationships: [],
  isApiLoading: true,
  filters: defaultFilters,
  crmConfig: {
    additional_fields: [],
    type: '',
    fetchAccountsWorkflowMap: {},
    fetchSummariesWorkflows: [],
  },
  updatedAt: 0,
  dynamicFilters: [],
  drawer: null,
  crmData: { icon: '', name: '', type: '' },
  selectedTab: 'home',
}

export type AccountsActionType = {
  type:
    | 'CLEAR_FILTERS'
    | 'SET_AI_CONSENT'
    | 'UPDATE_CRM_VALUE'
    | 'UPDATE_FILTER_KEY'
    | 'INIT_CRM_CONFIG_DATA'
    | 'UPDATE_ADDITIONAL_FIELDS'
    | 'UPDATE_CRM_RELATIONSHIP'
    | 'SET_ALL_FILTER'
    | 'SET_DRAWER'
    | 'INIT_CRM_DATA'
    | 'SET_SELECTED_TAB'
    | 'ADD_CRM_CONFIG_ADDITIONAL_FIELDS'
    | 'UPDATE_CRM_CONFIG_ADDITIONAL_FIELDS'
    | 'DELETE_CRM_CONFIG_ADDITIONAL_FIELDS'
  payload?: any
}

const accountsReducer = (
  state: AccountsStateType,
  action: AccountsActionType
): AccountsStateType => {
  const { type, payload } = action

  switch (type) {
    case 'SET_SELECTED_TAB':
      return {
        ...state,
        selectedTab: payload.selectedTab,
      }

    case 'INIT_CRM_CONFIG_DATA': {
      const updatedAdditionalFields = [
        ...payload.crmConfig.additional_fields.filter(
          (item: any) => !item.isDeleted
        ),
      ]
      return {
        ...state,
        crmConfig: {
          ...state.crmConfig,
          fetchAccountsWorkflowMap:
            payload.crmConfig.fetchAccountsWorkflowMap ?? {},
          fetchSummariesWorkflows:
            payload?.crmConfig?.fetchSummariesWorkflows ?? [],
          type: payload.crmConfig.type ?? '',
          additional_fields: [...updatedAdditionalFields],
        },
      }
    }

    case 'ADD_CRM_CONFIG_ADDITIONAL_FIELDS':
      return {
        ...state,
        crmConfig: {
          ...state.crmConfig,
          additional_fields: [
            ...state.crmConfig.additional_fields,
            payload.additional_fields,
          ],
        },
      }

    case 'UPDATE_CRM_CONFIG_ADDITIONAL_FIELDS':
      return {
        ...state,
        crmConfig: {
          ...state.crmConfig,
          additional_fields: [...state.crmConfig.additional_fields].map(
            (field) =>
              field._id === payload.additional_fields?._id
                ? { ...field, ...payload.additional_fields }
                : field
          ),
        },
      }

    case 'DELETE_CRM_CONFIG_ADDITIONAL_FIELDS':
      return {
        ...state,
        crmConfig: {
          ...state.crmConfig,
          additional_fields: [...state.crmConfig.additional_fields].filter(
            (field) => field._id !== payload.additional_fields?._id
          ),
        },
      }
    case 'SET_AI_CONSENT':
      return {
        ...state,
        aiConsent: payload.aiConsent,
      }

    case 'CLEAR_FILTERS':
      return {
        ...state,
        filters: defaultFilters,
      }

    case 'UPDATE_FILTER_KEY':
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.key]: payload.data,
        },
      }

    case 'UPDATE_CRM_VALUE': {
      accountDB.relationship
        .toCollection()
        .primaryKeys()
        .then((getKeys) =>
          accountDB.relationship.bulkGet(getKeys).then((resp) => {
            const relationships = resp?.map((rel: any) => {
              if (rel._id === payload._id) {
                return {
                  ...rel,
                  crm_values: {
                    ...rel.crm_values,
                    [payload.crmKey]: payload.value,
                  },
                }
              }
              return rel
            })

            message.destroy()
            // message.loading('Updating', 0)
            updateCRMValuesOnRelation(payload._id, {
              [payload.crmKey]: payload.value,
            })
              .then(() => {
                message.destroy()
                // message.success('Updated Successfully!')
              })
              .catch(() => {
                message.destroy()
                message.error('Something went wrong!')
              })

            accountDB.relationship.bulkPut(relationships)
          })
        )

      return {
        ...state,
        // relationships: relationships,
        updatedAt: payload.updatedAt,
      }
    }

    case 'UPDATE_CRM_RELATIONSHIP': {
      return {
        ...state,
        // relationships: relationships,
        updatedAt: payload.updatedAt,
      }
    }

    case 'UPDATE_ADDITIONAL_FIELDS': {
      accountDB.relationship
        .toCollection()
        .primaryKeys()
        .then((getKeys) =>
          accountDB.relationship.bulkGet(getKeys).then((resp) => {
            const relationships = resp?.map((rel: any) => {
              if (rel._id === payload._id) {
                const isFieldNameExist = rel.crm_values.additional_fields.some(
                  (item: any) => item.field_name === payload.field_name
                )

                let additionalFields = []

                if (isFieldNameExist) {
                  additionalFields = rel.crm_values.additional_fields.map(
                    (field: any) => {
                      if (field.field_name === payload.field_name) {
                        return {
                          ...field,
                          values: payload.values,
                        }
                      }
                      return field
                    }
                  )
                } else {
                  additionalFields = [
                    ...rel.crm_values.additional_fields,
                    {
                      field_name: payload.field_name,
                      values: payload.values,
                    },
                  ]
                }

                return {
                  ...rel,
                  crm_values: {
                    ...rel.crm_values,
                    additional_fields: additionalFields,
                  },
                }
              }
              return rel
            })
            updateCRMValuesOnRelation(payload._id, {
              [payload.field_id]: payload.values.map((item: any) => item._id),
            })
              .then(() => {
                message.destroy()
                message.success('Updated Successfully!')
              })
              .catch(() => {
                message.destroy()
                message.error('Something went wrong!')
              })

            accountDB.relationship.bulkPut(relationships)
          })
        )

      return {
        ...state,
      }
    }
    case 'SET_ALL_FILTER':
      return {
        ...state,
        dynamicFilters: payload.dynamicFilters,
      }
    case 'SET_DRAWER':
      return {
        ...state,
        drawer: payload.drawer,
      }
    case 'INIT_CRM_DATA':
      return {
        ...state,
        crmData: payload.crmData,
      }
    default:
      return state
  }
}

export { accountsInitialState, accountsReducer }
