import { Button, Spin } from 'antd'
import styled from 'styled-components'

export const SMEImg = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 40px;
`

export const PrimaryBtn = styled(Button)`
  background: var(--color-purple-1);
  border-radius: 7px;
  color: var(--color-white) !important;
  &:hover {
    background: var(--color-purple-6);
    border-color: var(--color-purple-6);
  }
  &:active,
  &:focus {
    background: var(--color-purple-1);
    border-color: var(--color-purple-1);
    outline: none;
  }
`

export const CustomIconBtn = styled(Button)`
  background-color: transparent !important;
  .operation-icon {
    display: none;
  }
`

export const Spinner = styled(Spin)`
  width: 100%;
  height: 100%;
  margin-top: 160px;
`

export const CustomRow = styled.tr`
  .ant-table-cell-row-hover .ant-space-item {
    .operation-icon {
      display: inline-block;
    }
  }
`
