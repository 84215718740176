import { Button, Collapse, message, Select } from 'antd'
import cls from 'classnames'
import { CaretDown, CaretUp } from 'phosphor-react'
import { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  fetchHubspotAccountsFieldMapping,
  fetchHubspotAccountsProperties,
  saveHubspotAccountsFieldMapping,
} from '../../../api/integrations'
import { useConnectedApp } from '../../ConnectedApps'

const { Panel } = Collapse

type HubspotAccountFieldMappingType = {
  name: string
  label: string
}

const THENA_FIELDS = [
  {
    key: 'arr_tier',
    name: 'Customer Tier',
  },
  {
    key: 'average_nps_score',
    name: 'Customer NPS Score',
  },
  {
    key: 'csm',
    name: 'Customer Success Manager',
  },
  {
    key: 'customer_health',
    name: 'Customer Health Indicator',
  },
  {
    key: 'domain',
    name: 'Customer Domain Name',
  },
  {
    key: 'lifecycle_stage',
    name: 'Customer Lifecycle Stage',
  },
  {
    key: 'name',
    name: 'Customer Name',
  },
  {
    key: 'renewal_date',
    name: 'Contract Renewal Date',
  },
]
interface mappingType {
  [key: string]: string | null
}

export const FieldMapping = () => {
  const { isEdition } = useConnectedApp()
  const queryClient = useQueryClient()

  const [mapping, setMapping] = useState<mappingType>(
    THENA_FIELDS.reduce((acc, obj) => ({ ...acc, [obj.key]: null }), {})
  )
  const [isConfigureOpen, setIsConfigureOpen] = useState<boolean>(false)

  // Fetching Hubspot Accounts
  const {
    data: properties,
    isLoading,
    isFetching,
  } = useQuery('fetchHubspotAccountsProperties', fetchHubspotAccountsProperties)

  // Fetching Hubspot Accounts
  const {
    data: fieldMapping,
    isLoading: loadingFieldMapping,
    isFetching: fetchingFieldMapping,
  } = useQuery(
    'fetchHubspotAccountsFieldMapping',
    fetchHubspotAccountsFieldMapping
  )

  useEffect(() => {
    setMapping({
      ...THENA_FIELDS.reduce((acc, obj) => ({ ...acc, [obj.key]: null }), {}),
      ...fieldMapping,
    })
  }, [fieldMapping])

  // Creating Hubspot Property Options
  const getAccountProperties = (fields: HubspotAccountFieldMappingType[]) =>
    fields.map((field) => ({
      label: field.label,
      value: field.name,
    }))

  // Memorizing Hubspot Options
  const hubspotAccountProperties = useMemo(
    () => getAccountProperties(properties || []),
    [properties]
  )

  // Save Hubspot account mapping
  const mapHubspotAccountMutation = useMutation(
    saveHubspotAccountsFieldMapping,
    {
      onSuccess: () => {
        // message.success('Hubspot Account configured')
        queryClient.invalidateQueries(['fetchHubspotAccountsFieldMapping'])
      },
      onError: (error) => {
        console.error(error)
        message.error('Something went wrong')
      },
    }
  )

  return (
    <Collapse
      ghost
      accordion
      expandIconPosition="end"
      activeKey={isConfigureOpen ? '1' : '0'}
      className="border border-solid border-brand-border mt-6"
      expandIcon={() => {
        if (!isConfigureOpen)
          return (
            <CaretDown size={18} color={!isEdition ? '#9096A4' : '#7A00FF'} />
          )
        return <CaretUp size={18} color="#7A00FF" />
      }}
      onChange={() => {
        setIsConfigureOpen((p) => !p)
      }}
    >
      <Panel
        header={
          <div className="flex justify-between flex-col ml-4">
            <h4 className={cls('m-0 font-bold')}>
              Customer Account Configuration
            </h4>
            {isConfigureOpen && (
              <p className="text-grey font-medium mb-0">
                Match each property to the relevant HubSpot attribute
              </p>
            )}
          </div>
        }
        key="1"
      >
        <>
          {/* Hubspot Account Mapping */}
          <div className="overflow-y-auto space-y-2 h-64 scroll-mr-4">
            {THENA_FIELDS.map((field) => (
              <div
                className="grid grid-cols-6 items-center space-y-2"
                key={field.key}
              >
                <p className="col-span-2 text-sm font-medium mb-0">
                  {field.name}
                </p>
                <div className="col-span-2" />

                <div className="col-span-2 mr-4">
                  <Select
                    allowClear
                    showSearch
                    className="w-full"
                    dropdownMatchSelectWidth
                    placeholder="HubSpot Field"
                    value={mapping[field.key]}
                    loading={
                      isLoading ||
                      isFetching ||
                      loadingFieldMapping ||
                      fetchingFieldMapping
                    }
                    onChange={(value) => {
                      setMapping((p) => ({ ...p, [field.key]: value }))
                    }}
                    filterOption={(input, option) => {
                      const label = option?.label as string
                      return label.toLowerCase().includes(input.toLowerCase())
                    }}
                    options={hubspotAccountProperties.map(
                      ({ value, label }) => ({
                        value,
                        label,
                      })
                    )}
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Done Overlay */}
          <div className="bg-white relative bottom-0 border-0 border-t border-brand-border rounded-b-lg border-solid p-3 -mx-4 -mb-3 mt-3 z-10">
            <div className="flex flex-row-reverse">
              <Button
                className="text-primary border-primary/40 mr-5 font-bold px-8"
                size="middle"
                type="default"
                onClick={() => {
                  mapHubspotAccountMutation.mutate({ fieldMapping: mapping })
                  setIsConfigureOpen(false)
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </>
      </Panel>
    </Collapse>
  )
}
