/* eslint-disable max-lines */
import { PlusOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Popover,
  Radio,
  RadioChangeEvent,
  Select,
  Typography,
} from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'

import Search from 'antd/lib/input/Search'
import { useLiveQuery } from 'dexie-react-hooks'
import { flattenDeep } from 'lodash'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { accountDB } from '../../../DB/AccountDB'
import { useAccountsStore } from '../../../store/accountsStore'
import { UserType } from '../../../types/accountsTypes'
import { getCurrentTimeStamp } from '../../../utils/dateUtils'
import { truncate } from '../../../utils/textUtils'
import {
  formatCurrency,
  getUniqueArrayByObjectKey,
  useGetUsersList,
} from '../utils'
import { NewChannelModal } from './NewChannelModal'
import { RowType } from './Table'
const { Option } = Select
const { Text } = Typography

const StyledInput = styled.input<{ $width: number }>`
  all: unset !important;
  padding: 0 4px !important;
  border-radius: 4px !important;
  width: ${({ $width }) => $width}px !important;
  :focus {
    outline: 2px solid var(--color-blue-1) !important;
  }
`

// export const TextEditCell = ({
//   value,
//   record,
//   crmKey,
//   truncateBy = 14,
//   isNumber = false,
//   postFix = '',
//   width = 150,
// }: {
//   value: string
//   crmKey: string
//   record: RowType
//   isNumber?: boolean
//   truncateBy?: number
//   postFix?: string
//   width?: number
// }) => {
//   const ref = useRef<HTMLInputElement>(null)
//   const [text, setText] = useState(`${value}`)
//   const [isEditing, setIsEditing] = useState(false)
//   const dispatch = useAccountsStore((state) => state.dispatch)

//   useEffect(() => {
//     if (isEditing) {
//       ref.current?.focus()
//     }
//   }, [isEditing])

//   const saveOnBlur = () => {
//     dispatch({
//       type: 'UPDATE_CRM_VALUE',
//       payload: {
//         crmKey,
//         _id: record._id,
//         value: isNumber ? Number(text) : text,
//         updatedAt: getCurrentTimeStamp(),
//       },
//     })
//     setIsEditing(false)
//   }

//   if (isEditing) {
//     return (
//       <StyledInput
//         ref={ref}
//         $width={width}
//         value={text}
//         size={text.length}
//         onBlur={saveOnBlur}
//         min={isNumber ? 0 : undefined}
//         type={isNumber ? 'number' : 'text'}
//         onChange={(e) => setText(e.target.value)}
//       />
//     )
//   }

//   const cellText = isNumber
//     ? formatCurrency(text, undefined)
//     : truncate(text, truncateBy)

//   return (
//     <span onClick={() => setIsEditing(true)}>
//       {cellText}
//       {postFix}
//     </span>
//   )
// }
export const TextEditCell = ({
  value,
  record,
  crmKey,
  truncateBy = 14,
  isNumber = false,
  postFix = '',
  width = 150,
  isMultiEditing,
  index,
  form,
}: {
  value: string
  crmKey: string
  record: RowType
  isNumber?: boolean
  truncateBy?: number
  postFix?: string
  width?: number
  isMultiEditing: boolean
  index: number
  form: UseFormReturn
}) => {
  const ref = useRef<any>(null)
  const [text, setText] = useState(`${value}`)
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useAccountsStore((state) => state.dispatch)

  const handleTextCellClick = () => {
    if (crmKey !== 'name') {
      setIsEditing(true)
    }
  }
  useEffect(() => {
    if (isEditing) {
      ref.current?.focus()
    }
  }, [isEditing])

  useEffect(() => {
    setText(`${value}`)
  }, [value])

  const saveOnBlur = () => {
    dispatch({
      type: 'UPDATE_CRM_VALUE',
      payload: {
        crmKey,
        _id: record._id,
        value: isNumber ? Number(text) : text,
        updatedAt: getCurrentTimeStamp(),
      },
    })
    setIsEditing(false)
  }

  if (isEditing || isMultiEditing) {
    return isEditing ? (
      <StyledInput
        ref={ref}
        $width={width}
        value={text}
        size={text.length}
        onBlur={saveOnBlur}
        min={isNumber ? 0 : undefined}
        type={isNumber ? 'number' : 'text'}
        onChange={(e) => setText(e.target.value)}
      />
    ) : (
      <Controller
        name={`${record._id}.${crmKey}`}
        control={form.control}
        defaultValue={text ?? ''}
        render={({ field }) => (
          <Input
            // ref={ref}
            // $width={width}
            //value={text}
            {...field}
            // size={text.length}
            // onBlur={saveOnBlur}
            min={isNumber ? 0 : undefined}
            type={isNumber ? 'number' : 'text'}
            // onChange={(e) => setText(e.target.value)}
          />
        )}
      />
    )
  }

  const cellText = isNumber
    ? formatCurrency(text, undefined)
    : truncate(text, truncateBy)

  return (
    <div onClick={handleTextCellClick} className="p-0 w-full">
      {cellText}
      {postFix}
    </div>
  )
}

type ListItem = {
  id: string
  name: string
}

const ContentWrapper = styled.div`
  min-width: 90px;
`

const List = styled.div`
  margin-top: 12px;
  max-height: 200px;
  overflow-y: scroll;
  .ant-radio {
    top: 4px !important;
  }
`

const Item = styled.label<{ $isChecked: boolean }>`
  display: flex;
  width: 205px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  align-items: center;
  label {
    cursor: pointer;
  }
  ${({ $isChecked }) => {
    if ($isChecked) {
      return css`
        background-color: var(--color-purple-7);
      `
    }
  }}
`

const getSelectedValue = (value: any) => {
  if (typeof value === 'string') {
    return value || 'Select a value'
  }
  if (Array.isArray(value)) {
    const typedValue = value as any[]
    return typedValue[0]?.value || 'Select a value'
  }
  return 'Select a value'
}

export const SingleSelectEditCell = ({
  value,
  record,
  crmKey,
  dropdownList,
  isBoolean = false,
  isMultiEditing,
  index,
  form,
}: {
  value: string
  crmKey: string
  record: RowType
  isBoolean?: boolean
  dropdownList: ListItem[]
  isMultiEditing: boolean
  index: number
  form: UseFormReturn
}) => {
  const [selected, setSelected] = useState(getSelectedValue(value))
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useAccountsStore((state) => state.dispatch)

  const handleOnClick = (value: string) => {
    setTimeout(() => {
      setIsEditing(false)
    }, 250)
    setSelected(capitalize(value))
    let text: string | boolean = value
    if (isBoolean) {
      text = value === 'yes' ? true : false
    }
    dispatch({
      type: 'UPDATE_CRM_VALUE',
      payload: {
        value: text,
        crmKey,
        _id: record._id,
        updatedAt: getCurrentTimeStamp(),
      },
    })
  }

  const Content = (
    <ContentWrapper>
      <Radio.Group
        value={selected?.toLowerCase()}
        onChange={(e: RadioChangeEvent) => {
          const item = dropdownList.find(
            (item: any) => item.id === e.target.value
          )
          if (item) {
            handleOnClick(item.id)
          }
        }}
      >
        <List>
          {dropdownList.map((info) => {
            const isChecked = selected?.toLowerCase() === info.id
            return (
              <Item key={info.id} $isChecked={isChecked}>
                <Radio value={info.id}>{truncate(info.name, 15)}</Radio>
              </Item>
            )
          })}
        </List>
      </Radio.Group>
    </ContentWrapper>
  )

  useEffect(() => {
    setSelected(getSelectedValue(value))
  }, [value])

  if (isEditing || isMultiEditing) {
    return isEditing ? (
      <Popover
        trigger="hover"
        content={Content}
        showArrow={false}
        placement="bottomLeft"
      >
        <span>{capitalize(selected)}</span>
      </Popover>
    ) : (
      <Controller
        name={`${record._id}.${crmKey}`}
        control={form.control}
        defaultValue={value ?? ''}
        render={({ field }) => (
          <Select {...field} style={{ width: '100px' }}>
            {dropdownList.map((item) => (
              <Option key={item.id} value={item.id}>
                <Text>{truncate(item.name, 15)}</Text>
              </Option>
            ))}
          </Select>
        )}
      />
    )
  }

  return <span onClick={() => setIsEditing(true)}>{capitalize(selected)}</span>
}

export const DateSelectCell = ({
  value,
  record,
  crmKey,
  isMultiEditing,
  index,
  form,
}: {
  value: string | undefined
  crmKey: string
  record: RowType
  isMultiEditing: boolean
  index: number
  form: UseFormReturn
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useAccountsStore((state) => state.dispatch)

  const saveOnBlur = () => {
    setIsEditing(false)
  }

  const onChange = (_: unknown, dateString: string) => {
    dispatch({
      type: 'UPDATE_CRM_VALUE',
      payload: {
        crmKey,
        value: moment(dateString).toISOString(),
        _id: record._id,
        updatedAt: getCurrentTimeStamp(),
      },
    })
    setIsEditing(false)
  }

  if (isEditing) {
    return (
      <DatePicker
        size="small"
        defaultOpen
        onChange={onChange}
        onBlur={saveOnBlur}
        format="MM.DD.YYYY"
        value={moment(value ?? undefined)}
        onOpenChange={(open) => {
          if (!open) {
            saveOnBlur()
          }
        }}
      />
    )
  } else if (isMultiEditing) {
    return (
      <Controller
        name={`${record._id}.${crmKey}`}
        control={form.control}
        defaultValue={moment(value ?? undefined) ?? ''}
        render={({ field }) => (
          <DatePicker
            {...field}
            defaultOpen={false}
            // value={moment(value ?? undefined)}
            format="MM.DD.YYYY"
          />
        )}
      />
    )
  }

  const dateString = value ? moment(value).format('MM.DD.YYYY') : 'Select'

  return <span onClick={() => setIsEditing(true)}>{dateString}</span>
}

const Label = styled.span`
  margin-left: 6px;
`

const UserWithProfile = styled.div`
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 8px;
  }
  position: relative;
  .avatar-shift {
    top: 0;
    z-index: 2;
    right: 12px;
    position: relative;
  }
`

// TODO: dispatch to store (confirm with BE about the key)
export const SingleSelectUserEditCell = ({
  value,
  record,
  crmKey,
  isMultiEditing,
  index,
  form,
}: {
  crmKey: string
  value: UserType | null
  record: RowType
  isMultiEditing: boolean
  index: number
  form: UseFormReturn
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedUser, setSelectedUser] = useState(value)
  const dispatch = useAccountsStore((state) => state.dispatch)
  const allUsers = useLiveQuery(
    async () => {
      const data = await accountDB.relationship.toArray()
      return flattenDeep(data.map((rel) => rel.users)) as UserType[]
    },
    [],
    [] as UserType[]
  )
  const { accountOwners: accountOwnersList } = useGetUsersList(
    record?.channel?.id ?? ''
  )

  const userList = useMemo(() => {
    const list = accountOwnersList.map((user: any) => {
      return {
        name: user?.real_name || '',
        id: user?.id || '',
        src: user?.image || '',
      }
    })
    return getUniqueArrayByObjectKey(list, 'id')
  }, [accountOwnersList])

  const filteredList = userList.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  )

  const handleChange = (e: RadioChangeEvent) => {
    const selectedUser = allUsers.find(
      (item) => item.user.slack.id === e.target.value
    )
    if (selectedUser) {
      setSelectedUser(selectedUser)
    }
    setIsEditing(false)
    dispatch({
      type: 'UPDATE_CRM_VALUE',
      payload: {
        value: selectedUser?.user?._id,
        crmKey,
        _id: record._id,
        updatedAt: getCurrentTimeStamp(),
      },
    })
  }

  const Content = (
    <ContentWrapper>
      <Search
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
      />
      <List>
        <Radio.Group
          onChange={handleChange}
          value={selectedUser?.user?.slack?.id}
        >
          {filteredList.map((info) => {
            const isChecked = selectedUser?.user?.slack?.id === info.id
            const key = `radio-${info.id}`
            return (
              <Item key={key} htmlFor={key} $isChecked={isChecked}>
                <Radio value={info.id}>
                  <Avatar size="small" className="avatar" src={info.src}>
                    {info.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <Label>{truncate(info.name, 15)}</Label>
                </Radio>
              </Item>
            )
          })}
        </Radio.Group>
      </List>
    </ContentWrapper>
  )

  if (isEditing) {
    return (
      <Popover
        trigger="hover"
        content={Content}
        showArrow={false}
        placement="bottomLeft"
      >
        {selectedUser ? (
          <UserWithProfile>
            <Avatar
              size="small"
              className="avatar"
              src={selectedUser?.user?.slack?.profile?.image_original}
            >
              {selectedUser?.user?.name}
            </Avatar>
            {truncate(selectedUser?.user?.name || '', 15)}
          </UserWithProfile>
        ) : (
          'NA'
        )}
      </Popover>
    )
  } else if (isMultiEditing) {
    return (
      <Controller
        name={`${record._id}.${crmKey}`}
        control={form.control}
        defaultValue={value ?? ''}
        render={({ field }) => (
          <Select
            {...field}
            className="w-full"
            style={{ width: '150px' }}
            value={field.value}
          >
            {filteredList.map((info) => (
              <Option ksey={info.id} value={info.id}>
                {info.id && (
                  <Avatar
                    size="small"
                    className="mr-1 avatar_dropdown"
                    src={info.src ?? ''}
                  >
                    {info.name.charAt(0).toUpperCase()}
                  </Avatar>
                )}
                <Text>{truncate(info.name, 15)}</Text>
              </Option>
            ))}
          </Select>
        )}
      />
    )
  }

  return (
    <UserWithProfile onClick={() => setIsEditing(true)}>
      {selectedUser ? (
        <>
          <Avatar
            size="small"
            className="mr-1 avatar"
            src={selectedUser?.user?.slack?.profile?.image_original}
          >
            {selectedUser?.user?.name}
          </Avatar>
          {truncate(selectedUser?.user?.name || '', 15)}
        </>
      ) : (
        'NA'
      )}
    </UserWithProfile>
  )
}

export const MultiSelectEditCell = ({
  value,
  record,
  crmKey,
  dropdownList,
  isBoolean = false,
  isMultiEditing,
  index,
  form,
}: {
  value: string
  crmKey: string
  record: RowType
  isBoolean?: boolean
  dropdownList: ListItem[]
  isMultiEditing: boolean
  index: number
  form: UseFormReturn
}) => {
  const [selected, setSelected] = useState(value || 'Select a value')
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useAccountsStore((state) => state.dispatch)

  const handleOnClick = (value: string) => {
    setTimeout(() => {
      setIsEditing(false)
    }, 250)
    setSelected(capitalize(value))
    let text: string | boolean = value
    if (isBoolean) {
      text = value === 'yes' ? true : false
    }
    dispatch({
      type: 'UPDATE_CRM_VALUE',
      payload: {
        value: text,
        crmKey,
        _id: record._id,
        updatedAt: getCurrentTimeStamp(),
      },
    })
  }

  const Content = (
    <ContentWrapper>
      <Checkbox.Group
        onChange={(e: CheckboxValueType[]) => {
          const item = dropdownList.find((item: any) => e.includes(item.id))
          if (item) {
            // Todo: need to update
            handleOnClick(item.id)
          }
        }}
      >
        <List>
          {dropdownList.map((info) => {
            const isChecked = selected.toLowerCase() === info.id
            return (
              <Item key={info.id} $isChecked={isChecked}>
                <Checkbox value={info.id}>
                  <span>{truncate(info.name, 15)}</span>
                </Checkbox>
              </Item>
            )
          })}
        </List>
      </Checkbox.Group>
    </ContentWrapper>
  )

  useEffect(() => {
    setSelected(value || 'Select values')
  }, [value])
  if (isEditing || isMultiEditing) {
    return isEditing ? (
      <Popover
        trigger="hover"
        content={Content}
        showArrow={false}
        placement="bottomLeft"
      >
        <span>{capitalize(selected)}</span>
      </Popover>
    ) : (
      <Controller
        name={`${record._id}.${crmKey}`}
        control={form.control}
        defaultValue={value ?? []}
        render={({ field }) => (
          <Select
            {...field}
            style={{ width: '100px' }}
            mode="multiple"
            showArrow
          >
            {dropdownList.map((item) => (
              <Option key={item.id} value={item.id}>
                <Text>{truncate(item.name, 15)}</Text>
              </Option>
            ))}
          </Select>
        )}
      />
    )
  }

  return <span onClick={() => setIsEditing(true)}>{capitalize(selected)}</span>
}

export const SingleSelectChannelEditCell = ({
  value,
  record,
  crmKey,
  dropdownList,
  isBoolean = false,
  isMultiEditing,
  index,
  form,
}: {
  value: string
  crmKey: string
  record: RowType
  isBoolean?: boolean
  dropdownList: ListItem[]
  isMultiEditing: boolean
  index: number
  form: UseFormReturn
}) => {
  const [selected, setSelected] = useState(value || 'Select a value')
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useAccountsStore((state) => state.dispatch)
  const [isNewChannel, setIsNewChannel] = useState(false)
  const handleOnClick = (value: string) => {
    setTimeout(() => {
      setIsEditing(false)
    }, 250)
    setSelected(value)
    let text: string | boolean = value
    if (isBoolean) {
      text = value === 'yes' ? true : false
    }
    dispatch({
      type: 'UPDATE_CRM_VALUE',
      payload: {
        value: text,
        crmKey,
        _id: record._id,
        updatedAt: getCurrentTimeStamp(),
      },
    })
  }
  const [searchText, setSearchText] = useState('')
  const filteredList = dropdownList.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  )
  const Content = (
    <ContentWrapper>
      <Search
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
      />
      <List>
        <Radio.Group
          value={selected}
          onChange={(e: RadioChangeEvent) => {
            const item = dropdownList.find(
              (item: any) => item.name === e.target.value
            )
            if (item) {
              handleOnClick(item.name)
            }
          }}
        >
          <List>
            {filteredList.map((info) => {
              const isChecked = selected === info.name
              return (
                <Item key={info.id} $isChecked={isChecked}>
                  <Radio value={info.name}>
                    <span>{truncate(info.name, 25)}</span>
                  </Radio>
                </Item>
              )
            })}
          </List>
        </Radio.Group>
      </List>
    </ContentWrapper>
  )

  useEffect(() => {
    setSelected(value || 'Select a value')
  }, [value])

  if (isEditing || isMultiEditing) {
    return isEditing ? (
      <Popover
        trigger="hover"
        content={Content}
        showArrow={false}
        placement="bottomLeft"
      >
        <span>{truncate(selected, 20)}</span>
      </Popover>
    ) : (
      <>
        <NewChannelModal
          isNewChannel={isNewChannel}
          setIsNewChannel={setIsNewChannel}
        />
        <Controller
          name={`${record._id}.${crmKey}`}
          control={form.control}
          defaultValue={value ?? ''}
          render={({ field }) => (
            <Select
              {...field}
              showSearch
              listHeight={250}
              size="middle"
              className="w-[10rem]"
              allowClear
              dropdownMatchSelectWidth={false}
              placeholder={'Select internal channel'}
              value={field.value}
              optionFilterProp="children"
              filterOption={(input, option) => {
                const label = option?.value as string
                return label?.toLowerCase().includes(input?.toLowerCase())
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <div
                    className="flex justify-between items-center"
                    style={{ padding: '0 8px 4px' }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      icon={<PlusOutlined color="#4A299C" />}
                      onClick={() => setIsNewChannel(true)}
                    >
                      <span className="text-sm 3xl:text-[length:var(--sm)]">
                        Create new channel
                      </span>
                    </Button>
                  </div>
                </>
              )}
              dropdownStyle={{
                width: '250px',
              }}
            >
              {dropdownList.map((item) => (
                <Option key={item.id} value={item.name}>
                  <Text>{truncate(item.name, 25)}</Text>
                </Option>
              ))}
            </Select>
          )}
        />
      </>
    )
  }

  return (
    <span onClick={() => setIsEditing(true)}>{truncate(selected, 20)}</span>
  )
}
