import { Modal, Row } from 'antd'
import styled from 'styled-components'
import { FormButtonGroup } from '../Forms/components/FormButtonGroup'

type RemoveChatProps = {
  isOpen: boolean
  onClose: () => void
  isSaving?: boolean
  chatTitle: string
  onSubmit: (removeChatId: string) => void
}

export const RemoveChatModal = ({
  onClose,
  isOpen,
  chatTitle,
  onSubmit,
}: RemoveChatProps) => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={onClose}
      title={<span style={{ fontWeight: 'bold' }}>Delete conversation?</span>}
      width={480}
    >
      <RemoveDescription justify="center">
        <span>This will delete</span>
        <span className="chat-title">{`< ${chatTitle} >`}</span>
      </RemoveDescription>
      <FormButtonGroup
        okText="Delete"
        isSaving={false}
        onOk={onSubmit}
        onClose={onClose}
      />
    </Modal>
  )
}

const RemoveDescription = styled(Row)`
  font-size: 16px;
  .chat-title {
    font-weight: bold;
    margin: 0 4px;
  }
`
