import { Avatar, Typography } from 'antd'
import * as htmlToSlackMD_ from 'html-to-slack-md'
import { FetchMembersForAdminsType } from '../api/admins'
import { ChannelType } from '../modules/MarketingAutomation/SendCampaignModal'
// hack to use non-es modules with vite
const htmlToSlackMD = htmlToSlackMD_.default || htmlToSlackMD_
const { Text } = Typography
export const renderMentionItem = (item: any) => {
  return (
    <div className="text-sm px-[6px] py-[2px] flex items-center gap-2">
      <Avatar src={item.avatar} alt={item.value} size="small" />
      <Text ellipsis={{ tooltip: true }} className="w-20">
        {item.value}
      </Text>
    </div>
  )
}

export const renderAtValues = (membersData: FetchMembersForAdminsType) => {
  const atValues = membersData?.members?.map((member: any) => ({
    id: member.id,
    value: member.real_name || member.display_name,
    avatar: member.image,
  }))
  return atValues
}

export const transformToSlackMarkdown = (text: string) => {
  const paragraphs = text.split('<p>').slice(1)
  const transformedLines = paragraphs.map((paragraph) => {
    let line = paragraph
    line = line.replace(/<br>/g, '\n')
    line = line.replace(
      /<strong><em><s>([^<]*)<\/s><\/em><\/strong>/g,
      ' *_~$1~_* '
    )
    line = line.replace(/<strong><em>([^<]*)<\/em><\/strong>/g, ' _*$1*_ ')
    line = line.replace(/<strong><s>([^<]*)<\/s><\/strong>/g, ' *~$1~* ')
    line = line.replace(/<em><s>([^<]*)<\/s><\/em>/g, ' _~$1~_ ')
    line = line.replace(/<s>(.*?)<\/s>/g, ' ~$1~ ')
    line = line.replace(/<strong>(.*?)<\/strong>/g, ' *$1* ')
    line = line.replace(/<em>(.*?)<\/em>/g, ' _$1_ ')

    return line
  })
  return transformedLines.join('\n')
}

export const createTransformedHTML = (content: any) => {
  const transformedHTML = document.createElement('div')
  transformedHTML.innerHTML = content
  const mentions = transformedHTML.querySelectorAll('span.mention')
  mentions.forEach((mention) => {
    const mentionElement = mention as HTMLElement
    mentionElement.outerHTML = `<@${mentionElement.dataset.id}>`
  })
  return transformedHTML
}

export const createNewLineContent = (content: any) => {
  const newLineHTML = document.createElement('div')
  newLineHTML.innerHTML = content
  const paragraphs = newLineHTML.querySelectorAll('p')
  paragraphs.forEach((paragraph) => {
    if (!paragraph.innerHTML.includes('\n')) {
      paragraph.outerHTML = paragraph.innerHTML + '<br>'
    }
  })
  return newLineHTML
}

export const validateFileType = (file: any) => {
  const fileType = file.type.split('/')[0]
  const isExecutable = [
    'application/x-msdownload',
    'application/x-dosexec',
  ].includes(fileType)
  return !isExecutable
}

export const getCombinedChannelLength = (
  addedChannels: ChannelType[],
  nonAddedChannels: ChannelType[]
) => {
  return addedChannels.length + nonAddedChannels.length
}

export const getSelectedChannelLength = (
  addedChannels: ChannelType[],
  nonAddedChannels: ChannelType[],
  selectedChannels: string[]
) => {
  const selectedIds = new Set()

  for (const idToCheck of selectedChannels) {
    if (
      addedChannels.some((i) => i.id === idToCheck) ||
      nonAddedChannels.some((i) => i.id === idToCheck)
    ) {
      selectedIds.add(idToCheck)
    }
  }

  const selectedIdsLength = selectedIds.size
  return selectedIdsLength
}
