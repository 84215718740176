import { api } from '.'

export type AssigneeType = {
  id: string
  name: string
  _id: string
  image: string
  team_id?: string
}
export const fetchAssignee = () =>
  api.get(`/v1/assignee`, {}).then(({ data }) => data.data)
