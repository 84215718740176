import { cloneDeep } from 'lodash'
import { create } from 'zustand'
import { devtools, redux } from 'zustand/middleware'

type KanbanBoardType = {
  records: Record<string, any>
  currentColumns: Record<string, any>
  unsavedHiddenColumns: string[]
  unsavedActiveColumns: string[]
}

const initalState: KanbanBoardType = {
  records: {},
  currentColumns: {},
  unsavedHiddenColumns: [],
  unsavedActiveColumns: [],
}

type ActionType = {
  type:
    | 'PUT_RECORDS'
    | 'UPDATE_RECORD'
    | 'UPDATE_RECORD_SOCKET'
    | 'BULK_UPDATE_RECORDS'
    | 'DELETE_RECORD'
    | 'BULK_UPDATE_RECORD'
    | 'UPDATE_RECORD_PUSHER'
    | 'SET_CURRENT_COLUMNS'
    | 'UNSAVED_HIDDEN_COLS'
    | 'UNSAVED_ACTIVE_COLS'
    | 'RESET_UNSAVED_COLS'
  payload?: any
}

const reducer = (
  state: KanbanBoardType,
  action: ActionType
): KanbanBoardType => {
  const { type, payload } = action

  switch (type) {
    case 'PUT_RECORDS':
      return {
        ...state,
        records: payload.records,
      }

    case 'UPDATE_RECORD':
      return {
        ...state,
        records: {
          ...state.records,
          [payload._id]: {
            ...(state.records[payload._id] || {}),
            ...payload.data,
          },
        },
      }

    case 'UPDATE_RECORD_SOCKET':
      return {
        ...state,
        records: {
          ...state.records,
          [payload._id]: {
            ...(state.records[payload._id] || {}),
            ...payload.data,
          },
        },
      }

    case 'UPDATE_RECORD_PUSHER':
      return {
        ...state,
        records: {
          ...state.records,
          [payload._id]: {
            ...(state.records[payload._id] || {}),
            ...payload.data,
          },
        },
      }

    case 'BULK_UPDATE_RECORD': {
      const updatedRecords = payload.reduce(
        (updatedState: any, item: any) => {
          const { _id, data } = item

          if (updatedState.records[_id]) {
            updatedState.records[_id] = {
              ...(updatedState.records[_id] || {}),
              ...data,
            }
          }
          return updatedState
        },
        { ...state }
      )

      return {
        ...state,
        records: {
          ...state.records,
          ...updatedRecords.records,
        },
      }
    }

    case 'BULK_UPDATE_RECORDS':
      return {
        ...state,
        records: {
          ...state.records,
          ...payload.records, // { [_id]: { ...data }, [_id]: { ...data } }
        },
      }

    case 'DELETE_RECORD': {
      const records = cloneDeep(state.records)
      delete records[payload._id]

      return {
        ...state,
        records,
      }
    }

    case 'SET_CURRENT_COLUMNS':
      return { ...state, currentColumns: payload.currentColumns }

    case 'UNSAVED_HIDDEN_COLS': {
      if (!state.unsavedHiddenColumns.includes(payload.columnId)) {
        return {
          ...state,
          unsavedHiddenColumns: [
            ...state.unsavedHiddenColumns,
            payload.columnId,
          ],
          unsavedActiveColumns: [
            ...state.unsavedActiveColumns.filter(
              (item) => item !== payload.columnId
            ),
          ],
        }
      }
      return state
    }
    case 'UNSAVED_ACTIVE_COLS': {
      if (!state.unsavedActiveColumns.includes(payload.columnId)) {
        return {
          ...state,
          unsavedActiveColumns: [
            ...state.unsavedActiveColumns,
            payload.columnId,
          ],
          unsavedHiddenColumns: [
            ...state.unsavedHiddenColumns.filter(
              (item) => item !== payload.columnId
            ),
          ],
        }
      }
      return state
    }
    case 'RESET_UNSAVED_COLS': {
      return { ...state, unsavedActiveColumns: [], unsavedHiddenColumns: [] }
    }
    default:
      return state
  }
}

const useKanbanBoardStore = create(
  devtools(redux(reducer, initalState), {
    name: 'useKanbanBoardStore',
  })
)

export { useKanbanBoardStore }
