import emojione from 'emojione'
import { debounce, isEmpty } from 'lodash'
import { Trash } from 'phosphor-react'
import Quill, { RangeStatic } from 'quill'
import { FocusEvent, useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import styled from 'styled-components'
import useMAEditorActions from '../../../hooks/useMAEditorActions'
import { useMaEditorStore } from '../../../store/maEditor'
import EditButtons from './EditButtons'
import WordLimit from './WordLimit'
import './editable.css'
import { countTrimmedSpaces, jsonEditorParsing } from './utils'

const Wrapper = styled.div`
  .ma-quill-editor {
    .ql-toolbar {
      display: none !important;
    }
    .ql-container {
      border: none;
      height: min-content;
      padding: 0;
      .ql-editor {
        font-size: 15px;
        padding: 0;
      }
    }
    .ql-tooltip {
      display: none !important;
    }
  }

  img[src="https://slackmojis.com/emojis/5206-slack/download"]
  {
    width: 18px;
  }

  a {
    text-decoration: none !important;
  }
`

type IProps = {
  type?: string
  index: number
  className: string
  htmlString: string
  disabled?: boolean
  toggleModal: () => void
  formatDisabled?: boolean
  onBlur: (e: FocusEvent<HTMLDivElement, Element>) => void
  onFocus: (e: FocusEvent<HTMLDivElement, Element>) => void
  selectedText?: string
  isReadOnly?: boolean
}

const DeleteIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -8px;
  background-color: var(--color-gray-1);
  width: 20px;
  height: 20px;
  border-radius: 4px;
`

const DeleteBlock = ({ index }: { index: number }) => {
  const { handleDeleteBlock } = useMAEditorActions()
  return (
    <DeleteIcon
      className="delete-icon"
      onClick={() => handleDeleteBlock(index)}
    >
      <Trash size={16} color="var(--color-black-1)" weight="bold" />
    </DeleteIcon>
  )
}

const modules = {
  toolbar: [['bold', 'italic', 'strike', 'link', 'emoji']],
}
const formats = ['bold', 'italic', 'strike', 'link', 'image', 'emoji']

const Editable = ({
  type,
  index,
  onBlur,
  onFocus,
  className,
  htmlString,
  toggleModal,
  disabled = false,
  formatDisabled = false,
  selectedText,
  isReadOnly,
}: IProps) => {
  const { handleUpdateBlock, handleDeleteBlock } = useMAEditorActions()

  const dispatch = useMaEditorStore((state) => state.dispatch)
  const state = useMaEditorStore((state) => state.initialState)
  const firstEditorBlockId = useMaEditorStore(
    (state) => state.firstEditorBlockId
  )
  const isFirstTextBlock = type === 'context' || type === 'section'
  if (isFirstTextBlock && !firstEditorBlockId) {
    dispatch({
      type: 'SET_FIRST_EDITOR_BLOCK_ID',
      payload: {
        firstEditorBlockId: 'first-editor-block',
      },
    })
  }

  const quillRef = useRef<ReactQuill>(null)
  const [value, setValue] = useState(htmlString)
  const [text, setText] = useState<any>('')
  useEffect(() => {
    setValue(htmlString)
  }, [htmlString])

  const showFocusOnEditor = useMaEditorStore((state) => state.showFocusOnEditor)

  useEffect(() => {
    let timerId: null | NodeJS.Timeout = null
    if (index === 0 && showFocusOnEditor) {
      quillRef.current?.focus()
      timerId = setTimeout(() => {
        quillRef.current?.blur()
      }, 2000)
    }
    return () => {
      if (timerId) clearTimeout(timerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFocusOnEditor])

  useEffect(() => {
    let quillInstance: Quill | null = null

    const selectionChanger = (
      range: RangeStatic,
      _: unknown,
      source: string
    ) => {
      if (source === 'user' && quillInstance && range) {
        const text = quillInstance.getText(range.index, range.length)
        const { trimmedText, leadingSpaces } = countTrimmedSpaces(text)
        if (trimmedText.length) {
          const newSelection: RangeStatic = {
            index: range.index + leadingSpaces,
            length: trimmedText.length,
          }
          quillInstance.setSelection(newSelection, 'silent')
          quillInstance.update()
        }
      }
    }

    const debouncedSelectionChanger = debounce(selectionChanger, 300)

    if (quillRef.current) {
      quillInstance = quillRef.current.getEditor()
      quillInstance.on('selection-change', debouncedSelectionChanger)
    }

    return () => {
      if (quillInstance) {
        quillInstance.off('selection-change', debouncedSelectionChanger)
      }
    }
  }, [])

  useEffect(() => {
    let quill: Quill | null = null

    if (quillRef.current) {
      quill = quillRef.current.getEditor()

      const getUpdatedRange = () => {
        let updatedRange: RangeStatic = {
          index: 0,
          length: 0,
        }
        if (quill) {
          const range = quill.getSelection()
          if (range && range.length > 0) {
            const text = quill.getText(range.index, range.length)
            const { trimmedText, leadingSpaces } = countTrimmedSpaces(text)
            updatedRange = {
              index: range.index + leadingSpaces,
              length: trimmedText.length,
            }
          }
        }
        return updatedRange
      }

      const boldHandler = () => {
        if (quill) {
          if (type === 'header') {
            return
          }
          const updatedRange = getUpdatedRange()
          const oldFormat = quill.getFormat(updatedRange)
          quill.formatText(
            updatedRange,
            {
              bold: !oldFormat?.bold,
            },
            'user'
          )
        }
      }

      const italicHandler = () => {
        if (quill) {
          if (type === 'header') {
            return
          }
          const updatedRange = getUpdatedRange()
          const oldFormat = quill.getFormat(updatedRange)
          quill.formatText(
            updatedRange,
            {
              italic: !oldFormat?.italic,
            },
            'user'
          )
        }
      }

      const keyboardModule = quill.getModule('keyboard')
      delete keyboardModule.bindings[66] // 66 is key code for 'B'
      delete keyboardModule.bindings[73] // 73 is key code for 'I'
      quill.keyboard.addBinding({ key: 'B', shortKey: true }, boldHandler)
      quill.keyboard.addBinding({ key: 'I', shortKey: true }, italicHandler)
    }
  }, [])

  if (disabled) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
    )
  }
  const maxLength = type === 'header' ? 150 : 3000
  const charactersLeft =
    maxLength -
    jsonEditorParsing({ target: { innerHTML: emojione.toShort(value) } }, '')
      .length
  const handleChange = (content: string) => {
    setValue(content)
    quillRef.current?.editor?.getText()
      ? setText(quillRef.current?.editor?.getText())
      : setText('')
  }

  const handleEditorBlur = (...arr: any) => {
    const quill = arr[2]
    if (arr[1] === 'silent') {
      return
    }
    onBlur({
      target: {
        innerHTML: quill.getHTML(),
      },
    } as any)
    const isFormatting =
      quill.getHTML().includes('<strong>') ||
      quill.getHTML().includes('<em>') ||
      !isEmpty(selectedText)
    if ((type === 'section' || type === 'context') && isFormatting) {
      const html = quill.getHTML()

      const json = jsonEditorParsing({ target: { innerHTML: html } }, '')

      handleUpdateBlock(index, json, type)
    }
  }
  const checkCharacterCount = (event: any) => {
    const unprivilegedEditor = quillRef?.current?.unprivilegedEditor
    if (event.key === 'x' && event.metaKey) {
      return
    }
    if (
      Number(unprivilegedEditor?.getLength()) >= maxLength &&
      event.key !== 'Backspace'
    )
      event.preventDefault()
  }

  return (
    <Wrapper
      className={`editable-wrapper ${charactersLeft <= 0 ? 'disabled' : ''}`}
    >
      {!isReadOnly && (
        <EditButtons
          toggleModal={toggleModal}
          formatDisabled={formatDisabled}
          onDeleteBlock={() => handleDeleteBlock(index)}
        />
      )}
      <ReactQuill
        readOnly={isReadOnly}
        theme="snow"
        value={value}
        ref={quillRef}
        // @ts-ignore
        onFocus={onFocus}
        formats={formats}
        modules={modules}
        onChange={handleChange}
        onBlur={handleEditorBlur}
        id={isFirstTextBlock ? 'first-editor-block' : ''}
        className={`${className} ma-quill-editor content-editable relative`}
        onKeyDown={checkCharacterCount}
      />
      {!isReadOnly && (
        <div className={`absolute bottom-[40px] z-50 right-[35px]`}>
          <WordLimit maxLength={maxLength} charactersLeft={charactersLeft} />
        </div>
      )}
    </Wrapper>
  )
}

export { DeleteBlock, Editable }
