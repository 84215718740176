import { Button, Checkbox, Typography } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { surveyAnswers } from '../../api/misc'
import { FormContainer, Stepper } from '../../components'
import './index.css'
const { Paragraph, Title } = Typography
export const WelcomeQuestion = () => {
  const options = [
    {
      label:
        'We use Slack and an Email Ticketing Tool for servicing customers.',
      value:
        'We use Slack and an Email Ticketing Tool for servicing customers.',
    },
    {
      label: 'I’m unable to manage the volume of customer messages on Slack.',
      value: 'I’m unable to manage the volume of customer messages on Slack.',
    },
    {
      label: 'I want analytics on my customer Slack channels.',
      value: 'I want analytics on my customer Slack channels.',
    },
    {
      label: 'I want to send marketing newsletters in Slack.',
      value: 'I want to send marketing newsletters in Slack.',
    },
  ]

  const [userOptions, setUserOptions] = useState([
    {
      question:
        'We use Slack and an Email Ticketing Tool for servicing customers.',
      answer: false,
    },
    {
      question:
        'I’m unable to manage the volume of customer messages on Slack.',
      answer: false,
    },
    {
      question: 'I want analytics on my customer Slack channels.',
      answer: false,
    },
    {
      question: 'I want to send marketing newsletters in Slack.',
      answer: false,
    },
  ])

  const onChange = (checkedValues: CheckboxValueType[]) => {
    const result = userOptions.map((x) => ({
      ...x,
      answer: checkedValues.includes(x.question),
    }))
    setUserOptions(result)
  }

  const addSurveryAnswers = useMutation(surveyAnswers)

  const clickHandler = (surveystatus: string) => {
    addSurveryAnswers.mutate({
      survey: userOptions,
      survey_status: surveystatus,
    })
  }

  return (
    <div className="welcomeQuestion flex flex-col justify-center items-center left-0 absolute top-0 m-auto w-full z-[400] mt-[180px]">
      <div className="w-2/6">
        <Stepper current={1} />
      </div>
      <FormContainer className="w-2/5 border border-solid border-brand-border rounded-md mt-14">
        <div className="flex flex-col gap-8">
          <div className="space-y-2">
            <Title
              className="text-center"
              level={3}
              style={{ marginBottom: 0 }}
            >
              What brings you to Thena?
            </Title>
            <Paragraph className="text-center text-gray-500">
              Choose the options most relevant to you
            </Paragraph>
          </div>
          <Checkbox.Group
            options={options}
            onChange={onChange}
            className="font-medium"
          />
          <div className="flex justify-center items-center gap-2">
            <Link to="/add-to-slack">
              <Button
                type="link"
                className="font-medium text-brand-accent"
                size="large"
                onClick={() => clickHandler('Skipped')}
              >
                Skip
              </Button>
            </Link>
            <Link to="/add-to-slack">
              <Button
                type="primary"
                size="large"
                className="flex justify-center items-center btn font-bold disabled:bg-disabled disabled:cursor-not-allowed disabled:hover:bg-disabled disabled:hover:text-white disabled:text-white"
                disabled={!userOptions.some((option) => option.answer === true)}
                onClick={() => clickHandler('Completed')}
              >
                Continue
              </Button>
            </Link>
          </div>
        </div>
      </FormContainer>
    </div>
  )
}
