import { Popover, Radio, RadioChangeEvent } from 'antd'
import styled, { css } from 'styled-components'
import { noOp } from '../../../utils/empty'
import { truncate } from '../../../utils/textUtils'
import { ClearIcon, FilterChip } from './Common'

const Wrapper = styled.div``

type IProps = {
  label: string
  itemList: any[]
  iconAlt: string
  iconSrc: string
  className?: string
  selectedItem: any
  onChange: (arg: any) => void
  setSelectedItem: React.Dispatch<React.SetStateAction<any>>
}

const ChipContentWrapper = styled.div`
  display: flex;
  margin-left: 4px;
  position: relative;
  align-items: center;
`

const ContentWrapper = styled.div`
  min-width: 90px;
`

const List = styled.div`
  margin-top: 12px;
  max-height: 200px;
  overflow-y: scroll;
  .ant-radio {
    top: 4px !important;
  }
`

const Item = styled.label<{ $isChecked: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 8px;
  margin-bottom: 4px;
  align-items: center;
  label {
    cursor: pointer;
  }
  ${({ $isChecked }) => {
    if ($isChecked) {
      return css`
        background-color: var(--color-purple-7);
      `
    }
  }}
`

const Label = styled.span``

const Chip = styled.div`
  flex-shrink: 0;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 8px;
  margin-right: 4px;
  border-radius: 6px;
  color: var(--color-white);
  background-color: var(--color-purple-1);
`

const SingleSelect = ({
  label,
  iconAlt,
  iconSrc,
  itemList,
  onChange,
  className,
  selectedItem,
  setSelectedItem,
}: IProps) => {
  const ChipContent = (
    <>
      <ChipContentWrapper>
        {!!selectedItem?.name && <Chip>{truncate(selectedItem.name, 15)}</Chip>}
      </ChipContentWrapper>
      {!!selectedItem?.name && (
        <ClearIcon
          onClick={(e) => {
            setSelectedItem({})
            e.stopPropagation()
          }}
        />
      )}
    </>
  )

  const Content = (
    <ContentWrapper>
      <Radio.Group
        value={selectedItem?.id}
        onChange={(e: RadioChangeEvent) => {
          const item = itemList.find((item: any) => item.id === e.target.value)
          if (item) {
            onChange(item)
          }
        }}
      >
        <List>
          {itemList.map((info) => {
            const isChecked = selectedItem?.id === info.id
            return (
              <Item key={info.id} $isChecked={isChecked}>
                <Radio value={info.id}>
                  <Label>{truncate(info.name, 15)}</Label>
                </Radio>
              </Item>
            )
          })}
        </List>
      </Radio.Group>
    </ContentWrapper>
  )

  return (
    <Wrapper className={className}>
      <Popover
        trigger="click"
        content={Content}
        showArrow={false}
        placement="bottomLeft"
      >
        <FilterChip
          label={label}
          onClick={noOp}
          iconAlt={iconAlt}
          iconSrc={iconSrc}
          content={ChipContent}
        />
      </Popover>
    </Wrapper>
  )
}

export default SingleSelect
