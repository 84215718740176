import { useEffect, useRef } from 'react'

const useFirstRenderSkip = (callback: () => void, dependencies: unknown[]) => {
  const initialRender = useRef(true)

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}

export default useFirstRenderSkip
