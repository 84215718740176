import Dexie, { Table } from 'dexie'

export class CustomStatusesDB extends Dexie {
  list!: Table<any>

  constructor() {
    super('CustomStatusesDB')
    this.version(2)
      .stores({
        list: 'status,order',
      })
      .upgrade(() => {
        this.on('versionchange', function (event: any) {
          console.log('CustomStatuses VersionChange', event)
          window.location.reload()
        })
      })
  }
}

export const customStatusesDB = new CustomStatusesDB()
