import { Col, Divider, message, Row } from 'antd'
import { useLiveQuery } from 'dexie-react-hooks'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { fetchSummariseDetails } from '../../../api/accounts'
import { accountDB } from '../../../DB/AccountDB'
import { accountDrawerDB } from '../../../DB/accountDrawerDB'
import { Conversation } from '../../../DB/kanbanDrawerDB'
import { useAccountsStore } from '../../../store/accountsStore'
import { RelationshipType } from '../../../types/accountsTypes'

import { ContentHeader } from './ContentHeader'
import { DrawerFilters } from './DrawerFilters'
import Tabs from './Tabs'

const Wrapper = styled(Row)`
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 8px;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
`

const Content = () => {
  const drawer = useAccountsStore((state) => state.drawer)

  const conv = useLiveQuery(
    async () => {
      if (drawer?.data?.record?._id) {
        return await accountDB.relationship.get(drawer.data.record._id)
      }
      return {} as RelationshipType
    },
    [drawer?.data?.record?._id],
    {} as RelationshipType
  )
  const useSummariseDetails = () => {
    const { isFetching, refetch, isLoading } = useQuery<any>(
      ['summarise', drawer?.data?.record?._id],
      () => fetchSummariseDetails(drawer?.data?.record?._id),
      {
        enabled: !!drawer?.data?.record?._id,
        onSuccess: async (data) => {
          try {
            await accountDrawerDB.summary.put(
              {
                _id: drawer?.data?.record?._id,
                data: data,
              },
              drawer?.data?.record?._id
            )
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
        },
        onError: () => {
          message.error('Error fetching summary')
        },
      }
    )

    const conversation = useLiveQuery(
      async () => {
        if (drawer?.data?.record?._id) {
          const data = await accountDrawerDB.summary.get(
            drawer?.data?.record?._id
          )
          return data
        } else {
          return {} as Conversation
        }
      },
      [drawer?.data?.record?._id],
      {} as Conversation
    )

    return useMemo(() => {
      return {
        refetch,
        isFetching,
        isLoading,
        summaryDetails: conversation?.data || [],
      }
    }, [conversation?.data, refetch, isFetching, isLoading])
  }
  const { summaryDetails, isLoading } = useSummariseDetails()

  return (
    <Wrapper className="h-full">
      <Col
        span={9}
        className="overflow-y-auto h-full border border-solid border-l-0 border-t-0 border-b-0 border-r-[#E5E5EA] bg-[#F4F4F8]"
      >
        <ContentHeader conv={conv} summaryDetails={summaryDetails} />
        <Divider className="m-0"></Divider>
        <DrawerFilters drawer={drawer} conv={conv} />
      </Col>
      <Tabs summaryDetails={summaryDetails} isLoading={isLoading} />
    </Wrapper>
  )
}

export default Content
