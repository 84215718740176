import { ADD_TO_SLACK_URL } from '../../../config'

export const addToSlackInfo = {
  card: {
    description: 'Average configuration time: 2 mins',
    buttonText: 'Add Thena to Slack',
    buttonLink: ADD_TO_SLACK_URL,
    links: {
      terms: {
        text: 'Terms of Service',
        url: 'https://www.thena.ai/terms',
      },
      privacy: {
        text: 'Privacy Policy',
        url: 'https://www.thena.ai/privacy-policy',
      },
    },
  },
  hero: {
    title: 'Let’s Get Started!',
    points: [
      {
        title: 'Conversational Ticketing',
        onClickId: 6579178,
      },
      {
        title: 'Contact Intelligence',
        onClickId: 6579183,
      },
      {
        title: 'Configurable Alerts',
        onClickId: 6378035,
      },
      {
        title: 'Smart Inbox',
        onClickId: 6537982,
      },
      {
        title: 'Channel Analytics',
        onClickId: 6538958,
      },
    ],
  },
}
