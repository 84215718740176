export const oldBlock = [
  {
    type: 'header',
    text: {
      type: 'plain_text',
      text: ':newspaper: Weekly Slack Customer Management Report',
      emoji: true,
    },
    block_id: 'lolol',
  },
  {
    type: 'context',
    elements: [
      {
        text: "*November 14, 2022*  |  Spotdraft's week in numbers",
        type: 'mrkdwn',
      },
    ],
  },
  {
    type: 'header',
    text: {
      type: 'plain_text',
      text: ':slack: Spotdraft Workspace ',
      emoji: true,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: '\n *Channels with Thena*: 59\n*Busiest Channel*: <#C0337SZHQV8|>\n*Quietest Channel*: <#C046ER3GCKU|>\n*Most Active User*: <@U03JDE4Q5N0>\n\n',
    },
    accessory: {
      type: 'image',
      image_url:
        'https://pbs.twimg.com/profile_images/1410331053600829440/ZpImWk30_400x400.jpg',
      alt_text: 'computer thumbnail',
    },
  },
  {
    type: 'header',
    text: {
      type: 'plain_text',
      text: ':ticket: Requests on Thena',
      emoji: true,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: '*New Requests*: 31 (_similar to last week_)\n*Total Requests*: 111\n*Open Requests*: 26 (*_50% increase_*)\n*Escalated Requests*: 0',
    },
    accessory: {
      type: 'image',
      image_url:
        'https://thena-medias.s3.us-west-1.amazonaws.com/slack-tutorial/requests.png',
      alt_text: 'cute cat',
    },
  },
  {
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: '*Average Time to Close (This Week v/s All Time)*: 17 v/s 16 hours\n  ',
    },
  },
  {
    type: 'header',
    text: {
      type: 'plain_text',
      text: ':email: Messages',
      emoji: true,
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'section',
    text: {
      type: 'mrkdwn',
      text: '*Total Messages*: 583 (_similar to last week_)\n*Total Customer Messages*: 124 (*_40% decrease_*)\n*Average Time to first response*: 29 mins (*_80% decrease_*) \n*Total Suggested Requests*: 68 (_similar to last week_)',
    },
    accessory: {
      type: 'image',
      image_url:
        'https://thena-medias.s3.us-west-1.amazonaws.com/slack-tutorial/messages.png',
      alt_text: 'cute cat',
    },
  },
]

export const blocks = {
  blocks: [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: 'Create your own template',
      },
      block_id: 'template_heading',
    },
  ],
}
