import { camelCase } from 'lodash'
import moment, { Moment } from 'moment'
import { useAccountsStore } from '../../../store/accountsStore'

const isDateInRange = (
  dateToCheck: string,
  startDate: Moment,
  endDate: Moment
): boolean => {
  const momentDateToCheck = moment(dateToCheck)
  const momentStartDate = moment(startDate)
  const momentEndDate = moment(endDate)
  return momentDateToCheck.isBetween(momentStartDate, momentEndDate, null, '[]')
}

const useApplyFilter = (tableData: any[]) => {
  const filters = useAccountsStore((state) => state.filters)
  const filteredData = tableData.filter((row) => {
    let condition1 = false
    let condition2 = false
    let condition3 = false
    let condition4 = false

    if (filters.accountTypes.length) {
      const listOfTypes = filters.accountTypes.map((type) => type.id)
      condition1 = row.accountType
        ? listOfTypes.includes(row.accountType)
        : false
    } else {
      condition1 = true
    }

    if (filters.accountOwners.length) {
      const listOfIds = filters.accountOwners.map(
        (user) => user?.user?.slack?.id
      )
      condition2 = row.accountOwner
        ? listOfIds.includes(row.accountOwner?.user?.slack?.id)
        : false
    } else {
      condition2 = true
    }

    condition3 = filters.contractStartDateRange?.length
      ? isDateInRange(
          row.contractStartDate,
          filters.contractStartDateRange[0],
          filters.contractStartDateRange[1]
        )
      : true

    condition4 = filters.renewalDateRange?.length
      ? isDateInRange(
          row.contractEndDate,
          filters.renewalDateRange[0],
          filters.renewalDateRange[1]
        )
      : true

    const dynamicConditions = filters.crmFilter.map((filter) => {
      if (Array.isArray(filter.values) && filter.values.length > 0) {
        return filter.values?.some((filterItem) => {
          // Custom logic for each dynamic filter
          // if (filter.field_name === 'accountTier') {
          if (row[camelCase(filter.field_name)]) {
            return row[camelCase(filter.field_name)]?.some(
              (tier: any) => tier.value === filterItem
            )
          }
        })
      }
      // No filter applied if the dynamic filter is empty
      return true
    })

    return (
      dynamicConditions.every((condition) => condition) &&
      condition1 &&
      condition2 &&
      condition3 &&
      condition4
    )
  })

  return filteredData
}

export default useApplyFilter
