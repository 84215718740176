import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons'
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  Input,
  Select,
  Tooltip,
  Typography,
  message,
} from 'antd'
import _, { debounce } from 'lodash'
import { MagnifyingGlass, X } from 'phosphor-react'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CSVLink } from 'react-csv'
import { useQuery, useQueryClient } from 'react-query'
import { fetchAssignee } from '../api/assignee'
import { fetchIntegrations } from '../api/integrations'
import { fetchCSM } from '../api/personas'
import {
  FetchRequestsRelationships,
  fetchRequestsRelationships,
} from '../api/requests'
import { ChannelList } from '../components'

const { Text } = Typography
const { Option } = Select

export type SelectedChannelType = null | 'all' | string[]

export type CardType = 'TICKETS' | 'REQUESTS' | undefined
export type SortType = 'NEWEST' | 'OLDEST' | 'RECENT ACTIVITY'

interface KanbanFilterContextType {
  search: string
  cardType?: CardType
  sortBy: SortType
  period: number
  selectedFilterChannels: SelectedChannelType
  csm: string[]
  drawer: boolean
  setChannelsForMessages: React.Dispatch<
    React.SetStateAction<SelectedChannelType>
  >
  assignedTo: string[]
  setCsvData: React.Dispatch<any>
  csvLink: React.MutableRefObject<any> | null
}

const KanbanFilterDefaultValues: KanbanFilterContextType = {
  search: '',
  cardType: undefined,
  csm: [],
  period: 7,
  selectedFilterChannels: 'all',
  sortBy: 'NEWEST',
  drawer: false,
  setChannelsForMessages: () => {},
  assignedTo: [],
  setCsvData: () => {},
  csvLink: null,
}

const KanbanFilterContext = createContext<KanbanFilterContextType>(
  KanbanFilterDefaultValues
)

export const KanbanFilterProvider = ({
  children,
  showChannelFilter = true,
  channelId,
}: {
  children: React.ReactNode
  showChannelFilter?: boolean
  key?: string
  channelId?: string[]
}) => {
  const { data: integrations } = useQuery(['integrations'], fetchIntegrations)
  const [search, setSearch] = useState<string>(KanbanFilterDefaultValues.search)
  // const [period, setPeriod] = useState(KanbanFilterDefaultValues.period)
  const [drawer, setDrawer] = useState(false)
  const [period, setPeriod] = useState(KanbanFilterDefaultValues.period)
  const [cardType, setCardType] = useState<CardType>()
  const [sortBy, setSortBy] = useState(KanbanFilterDefaultValues.sortBy)
  const [csm, setCsm] = useState<string[]>([])
  const [assignedTo, setAssignedTo] = useState<string[]>([])
  const [csvData, setCsvData] = useState<any>([])
  const csvLink = useRef<any>(null)
  const queryClient = useQueryClient()
  const headers = [
    { label: 'Workspace Name', key: 'customer_team.name' },
    {
      label: 'Channel Name',
      key: 'channelName',
    },
    { label: 'Request', key: 'conversationText' },
    { label: 'Request Status', key: 'status' },
    { label: 'Sender Name', key: 'senderName' },
    { label: 'Looking Into', key: 'assignedTo.name' },
    { label: 'Closed By', key: 'closed_by.name' },
    { label: 'Reply Count', key: 'replyCount' },
    { label: 'Slack Link', key: 'permalink' },
    { label: 'Created at', key: 'key' },
  ]

  const showDrawer = () => {
    setDrawer(true)
  }
  const onClose = () => {
    setDrawer(false)
  }
  const downloadReport = () => {
    csvLink?.current?.link.click()
  }

  const { data } = useQuery('fetchCSM', () => fetchCSM({ channelIds: [] }))
  const { data: selectedCustomers, isLoading } =
    useQuery<FetchRequestsRelationships>(
      'fetch-added-channels',
      fetchRequestsRelationships
    )

  const [selectedFilterChannels, setChannelsForMessages] =
    useState<SelectedChannelType>(
      channelId || KanbanFilterDefaultValues.selectedFilterChannels
    )

  useEffect(() => {
    if (channelId && channelId.length) {
      setChannelsForMessages(channelId)
    }
  }, [channelId])

  // useEffect(() => {
  //   integrations?.map((ele: any) => ele.isEnabled === false) &&
  //     setCardType('REQUESTS')
  //   integrations?.some((ele: any) => ele.isEnabled === true) &&
  //     setCardType(undefined)
  // }, [integrations])

  const tagRender = (props: CustomTagProps) => {
    const { value } = props

    return data.members.map(
      (item: any) =>
        item.id === value && <Avatar size="small" src={item.image} />
    )
  }
  const { data: assignees } = useQuery('assignee', fetchAssignee)

  const assigneeTagRender = (props: CustomTagProps) => {
    const { value } = props

    return (assignees || []).map(
      (item: any) =>
        item.id === value && <Avatar size="small" src={item.image} />
    )
  }

  return (
    <KanbanFilterContext.Provider
      value={useMemo(
        () => ({
          search,
          cardType,
          csm,
          sortBy,
          period,
          selectedFilterChannels,
          setChannelsForMessages,
          drawer,
          assignedTo,
          setCsvData,
          csvLink,
        }),
        [
          selectedFilterChannels,
          search,
          cardType,
          csm,
          period,
          sortBy,
          setChannelsForMessages,
          drawer,
          assignedTo,
          setCsvData,
          csvLink,
        ]
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center w-full gap-1">
          <Input
            name="search"
            size="middle"
            id="search"
            className="h-10 col-span-2 shadow-sm form-input w-60"
            placeholder="Search"
            prefix={<MagnifyingGlass />}
            autoComplete={'off'}
            onChange={debounce((e) => setSearch(e.target.value), 300)}
          />
          <Divider type="vertical" className="py-4" />
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-2">
              <Text className="font-bold text-gray-dark">Filter by</Text>
              <div
                className={`${
                  cardType === undefined
                    ? 'kanban-not-selected'
                    : 'kanban-selected date'
                }`}
              >
                <Select
                  placeholder="Type"
                  value={cardType}
                  onChange={(val) => {
                    setCardType(val)
                  }}
                  size="middle"
                  style={{
                    width: 'auto',
                    fontSize: '30px',
                  }}
                  showArrow={false}
                  allowClear
                  clearIcon={<X color="#000000" className="bg-transparent" />}
                  bordered
                  dropdownMatchSelectWidth={false}
                  className={
                    cardType === undefined
                      ? 'kanban-not-selected'
                      : 'kanban-selected date'
                  }
                >
                  {integrations?.some((ele: any) => ele.isEnabled === true) ? (
                    <>
                      <Option value="TICKETS">
                        <Text className="text-sm">Tickets</Text>
                      </Option>
                      <Option value="REQUESTS">
                        <Text className="text-sm">Requests</Text>
                      </Option>
                    </>
                  ) : (
                    <>
                      <Option value="REQUESTS">
                        <Text className="text-sm">Requests</Text>
                      </Option>
                    </>
                  )}
                </Select>
              </div>
              <div
                className={`${
                  _.isEmpty(assignedTo)
                    ? 'kanban-not-selected'
                    : 'kanban-selected date'
                }`}
              >
                <Select
                  placeholder="Assigned"
                  mode="multiple"
                  showSearch
                  tagRender={assigneeTagRender}
                  disabled={_.isEmpty(csm) ? false : true}
                  value={assignedTo}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={(val: string[]) => {
                    setAssignedTo(val)
                  }}
                  size="middle"
                  style={{
                    width: assignedTo.length > 0 ? '100px' : '90px',
                  }}
                  showArrow={false}
                  allowClear
                  bordered
                  dropdownMatchSelectWidth={false}
                  maxTagCount={'responsive'}
                  clearIcon={<X color="#000000" className="bg-transparent" />}
                >
                  {(assignees || []).map((item: any) => (
                    <Option value={item.id} label={item.name} key={item.id}>
                      <div className="flex items-center space-x-2 text-sm">
                        <span>
                          <Avatar src={item.image} size="small" />
                        </span>
                        <span>
                          <Text>{item.name}</Text>
                        </span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </div>
              <div
                className={`${
                  _.isEmpty(csm)
                    ? 'kanban-not-selected'
                    : 'kanban-selected date'
                }`}
              >
                <Select
                  placeholder="CSM"
                  mode="multiple"
                  tagRender={tagRender}
                  disabled={_.isEmpty(assignedTo) ? false : true}
                  value={csm}
                  onChange={(val: string[]) => {
                    setCsm(val)
                  }}
                  size="middle"
                  style={{
                    width: csm.length > 0 ? '100px' : '60px',
                  }}
                  showArrow={false}
                  allowClear
                  clearIcon={<X color="#000000" className="bg-transparent" />}
                  bordered
                  dropdownMatchSelectWidth={false}
                  maxTagCount={'responsive'}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toString()
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  showSearch
                >
                  {data?.members.map((item: any) => (
                    <Option value={item.id} label={item.name} key={item.id}>
                      <div className="flex items-center space-x-2 text-sm">
                        <span>
                          <Avatar src={item.image} size="small" />
                        </span>
                        <span>
                          <Text>{item.name}</Text>
                        </span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </div>

              {showChannelFilter && (
                <Button
                  className="flex items-center justify-center text-sm font-medium bg-transparent border-solid border-disabled text-gray-dark hover:bg-transparent hover:text-gray-dark"
                  onClick={showDrawer}
                >
                  Select Customers
                  {!isLoading && (
                    <>
                      {' '}
                      (
                      {selectedFilterChannels === 'all'
                        ? Array.from(
                            new Set(
                              selectedCustomers?.rIds.map(
                                (item) => item.customer_name
                              )
                            )
                          ).length
                        : Array.from(
                            new Set(
                              selectedCustomers?.rIds
                                .filter((item) =>
                                  selectedFilterChannels?.includes(
                                    item.channelId
                                  )
                                )
                                .map((item) => item.customer_name)
                            )
                          ).length}
                      )
                    </>
                  )}
                </Button>
              )}
              <div className="text-sm date kanban-selected">
                <Select
                  size="middle"
                  style={{
                    width: 'auto',
                    backgroundColor: 'none',
                  }}
                  showArrow={false}
                  bordered
                  dropdownMatchSelectWidth={false}
                  value={period}
                  className="kanban-selected"
                  onChange={(val: number) => {
                    setPeriod(val)
                  }}
                >
                  <Option value={7}>
                    <Text className="text-sm">Last 7 days</Text>
                  </Option>
                  <Option value={14}>
                    <Text className="text-sm">Last 14 days</Text>
                  </Option>
                  <Option value={30}>
                    <Text className="text-sm">Last 30 days</Text>
                  </Option>
                </Select>
              </div>
              {showChannelFilter && (
                <Drawer
                  width="480px"
                  placement="right"
                  onClose={onClose}
                  open={drawer}
                  closable={false}
                  maskClosable={false}
                  bodyStyle={{
                    border:
                      selectedFilterChannels === null ||
                      selectedFilterChannels?.length === 0
                        ? '1px solid #D91029'
                        : '',
                  }}
                >
                  <ChannelList onClose={onClose} />
                </Drawer>
              )}
              <Divider type="vertical" className="py-4" />
              <div className="date kanban-selected">
                <Select
                  placeholder="Sort By"
                  value={sortBy}
                  onChange={(val) => {
                    setSortBy(val)
                  }}
                  size="middle"
                  style={{
                    width: 'auto',
                  }}
                  showArrow={false}
                  bordered
                  dropdownMatchSelectWidth={false}
                >
                  <Option value="NEWEST" label="Newest First">
                    <Text className="text-sm">Newest First</Text>
                  </Option>
                  <Option value="OLDEST" label="Oldest First">
                    <Text className="text-sm">Oldest First</Text>
                  </Option>
                </Select>
              </div>
            </div>
            <div className="flex items-center justify-end gap-1">
              {/* Refresh Channels */}
              <Button
                size="middle"
                type="ghost"
                icon={<ReloadOutlined />}
                className="text-sm border-none shadow-none text-gray-dark hover:bg-hover-accent"
                onClick={() => {
                  queryClient.invalidateQueries(['conversations'])
                  queryClient.invalidateQueries(['requests'])
                  // queryClient.invalidateQueries(['closed-conversations'])
                  // queryClient.invalidateQueries(['closed-requests'])
                  message.success('Refreshing… this might take a few mins')
                }}
              >
                Refresh
              </Button>
              <Tooltip title="Download Requests">
                <Button
                  shape="circle"
                  type="default"
                  size="middle"
                  icon={
                    <DownloadOutlined
                      style={{
                        fontWeight: 'bold',
                        color: '#696F7B',
                      }}
                    />
                  }
                  className="border-none bg-transparent text-[#696F7B]"
                  onClick={downloadReport}
                />
                <CSVLink
                  data={csvData}
                  headers={headers}
                  filename="Requests Report.csv"
                  ref={csvLink}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {children}
    </KanbanFilterContext.Provider>
  )
}

export const useKanbanFilter = () => {
  return useContext(KanbanFilterContext)
}
