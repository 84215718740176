import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Tooltip,
  Typography,
} from 'antd'
import { PencilSimple } from 'phosphor-react'
import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import {
  fetchShortcutIntegration,
  saveShortcutToken,
} from '../../../api/integrations'
import { IntegrationModalHeader } from '../../../components'
import CopyIcon from '../../../components/common/kanbanDrawer/CopyIcon'
import { API_ROOT } from '../../../config'
import ShortcutPNG from '../../../images/logos/shortcut.png'
import { useShortcutProvider } from './ShortcutProvider'
const { Text, Paragraph } = Typography
const CopyButton = styled.button`
  margin-left: 20px;
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-gray-4);
  border-radius: 4px;
  background-color: var(--color-white);
  cursor: pointer;
  :hover {
    border-color: var(--color-purple-6);
    path {
      stroke: var(--color-purple-6);
    }
  }
`
export const ShortcutIntegration = () => {
  const { tokenUrl, setTokenUrl, isEditing, setIsEditing } =
    useShortcutProvider()
  const onSave = () => {
    saveShortcutIntegration.mutate(tokenUrl)
  }
  const queryClient = useQueryClient()
  const saveShortcutIntegration = useMutation(saveShortcutToken, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['fetch-shortcut-integration'])
      message.success(data.message)
      isEditing && setIsEditing(false)
    },
    onError: (error) => {
      message.error('Something went wrong!')
    },
  })
  const { data, refetch } = useQuery(
    'fetch-shortcut-integration',
    fetchShortcutIntegration
  )
  const [form] = Form.useForm()
  useEffect(() => {
    // API call on Mount
    refetch()
  }, [])

  useEffect(() => {
    // Setting state
    setTokenUrl(data?.shortcutSink?.apiKey)
    form.setFieldsValue({ token: data?.shortcutSink?.apiKey })
  }, [data])

  const maskNumber = (inputString: string) => {
    const parts = inputString.split('-')

    if (parts.length !== 5) {
      return inputString
    }

    const maskedParts = parts
      .slice(0, 1) // Keep the first part as is
      .concat(['****', '****', '****']) // Mask the 2nd, 3rd, and 4th parts
      .concat(parts.slice(4)) // Keep the last part as is

    return maskedParts.join('-')
  }

  return (
    <main className="w-full">
      <IntegrationModalHeader
        imgSrc={ShortcutPNG}
        imageClassName="w-[42px] h-[42px]"
        title="Shortcut Integration"
        description="Link shortcut stories with Thena Requests"
      />
      <Divider className="my-5 py-0" />
      {data?.shortcutSink?.webhookPath !== undefined && (
        <>
          <div className="flex flex-col space-y-4 mt-3">
            <Text className="text-justify">
              We have successfully stored your Shortcut API Token and you can
              start linking Shortcut stories with Thena Requests now.
            </Text>
            <Text className="text-justify">
              In order to sync the status of your Shortcut Stories real time,
              you need to add the below URL as a webhook on Shortcut as a
              Webhook Integration.
            </Text>

            <div className="flex items-baseline gap-[0.5px]">
              <div className="font-medium">URL: </div>
              <Paragraph
                className="w-full text-[color:var(--color-purple-4)] cursor-pointer"
                copyable={{
                  text: `${API_ROOT}${data?.shortcutSink?.webhookPath}`,
                  icon: (
                    <CopyButton>
                      <CopyIcon />
                    </CopyButton>
                  ),
                }}
                onClick={() =>
                  window.open(
                    `${API_ROOT}${data?.shortcutSink?.webhookPath}`,
                    '_blank'
                  )
                }
                ellipsis={{ rows: 2 }}
              >
                {`${API_ROOT}${data?.shortcutSink?.webhookPath}`}
              </Paragraph>
            </div>

            <Divider />
          </div>
        </>
      )}
      <div className="space-y-4 mt-3">
        <Text className="font-medium">Shortcut API Token </Text>
        <div className="flex space-x-2 mt-1">
          <Form
            form={form}
            name="shortcutIntegration"
            onFinish={onSave}
            autoComplete="off"
            className="w-full"
            initialValues={{ token: data?.shortcutSink?.apiKey }}
          >
            <Form.Item
              name="token"
              rules={[{ required: true, message: 'Please input token' }]}
            >
              {data?.shortcutSink?.webhookPath !== undefined ? (
                isEditing ? (
                  <Input
                    placeholder={
                      data?.shortcutSink?.isEnabled
                        ? 'Update Shortcut API Token'
                        : 'Enter Shortcut API Token'
                    }
                    className="mt-1 w-full"
                    size="middle"
                    onChange={(e) => setTokenUrl(e.target.value)}
                  />
                ) : (
                  <div className="flex items-center gap-1">
                    <Text>{maskNumber(data?.shortcutSink?.apiKey)}</Text>
                    <Tooltip title="Edit">
                      <CopyButton>
                        <PencilSimple
                          color="var(--color-gray-4)"
                          className="cursor-pointer"
                          onClick={() => setIsEditing(true)}
                        />
                      </CopyButton>
                    </Tooltip>
                  </div>
                )
              ) : (
                <Input
                  placeholder={
                    data?.shortcutSink?.isEnabled
                      ? 'Update Shortcut API Token'
                      : 'Enter Shortcut API Token'
                  }
                  className="mt-1 w-full"
                  size="middle"
                  onChange={(e) => setTokenUrl(e.target.value)}
                />
              )}
            </Form.Item>

            <Form.Item className="flex justify-end">
              {data?.shortcutSink?.webhookPath !== undefined ? (
                isEditing ? (
                  <Button
                    className="text-primary border-primary/40 mt-10 mb-8 font-bold px-8"
                    size="middle"
                    type="default"
                    htmlType="submit"
                    loading={saveShortcutIntegration.isLoading}
                  >
                    Update
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <Button
                  className="text-primary border-primary/40 mt-10 mb-8 font-bold px-8"
                  size="middle"
                  type="default"
                  htmlType="submit"
                  loading={saveShortcutIntegration.isLoading}
                >
                  Save
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </main>
  )
}
