import { Card, Divider, Spin } from 'antd'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { fetchPublicChannels } from '../../../api/channels'
import { fetchSlackRedirection } from '../../../api/integrations'
import { SelectUI } from '../../../components'
import { ChannelType } from '../../channels/definitions'
import { useSlack } from './SlackProvider'

const REQUEST_TYPES = [
  {
    label: 'Product',
    key: 'Product',
  },
  {
    label: 'Billing',
    key: 'Billing',
  },
  {
    label: 'Account',
    key: 'Account Manager',
  },
  {
    label: 'Tech Support',
    key: 'Tech Support',
  },
]

export const RequestDirect = () => {
  const { saveRedirectionMutation } = useSlack()

  // Fetch Internal channels Integrations
  const { data: slackRedirection, isLoading } = useQuery(
    'fetch-slack-redirection',
    fetchSlackRedirection
  )

  useEffect(() => {
    reset(slackRedirection)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slackRedirection])

  // Fetching all Public Internal Channels
  const { data: publicChannels, isLoading: fetchingPublicChannels } = useQuery(
    ['fetchAllChannels'],
    () => fetchPublicChannels({})
  )

  const { handleSubmit, reset, control, setValue } = useForm()

  const channels = useMemo(
    () =>
      (publicChannels?.channels || []).map((channel: ChannelType) => ({
        value: `# ${channel.name}`,
        key: channel.id,
      })),
    [publicChannels]
  )

  const onSubmit = (data: any) => {
    saveRedirectionMutation?.mutate(data)
  }

  const handleChange = (key: string, value?: string) => {
    setValue(key, value)
    handleSubmit(onSubmit)()
  }

  if (isLoading) return <Spin size="large" />

  return (
    <Card
      bodyStyle={{
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 20,
        paddingBottom: 38,
      }}
    >
      <h3 className="text-sm font-bold mb-0">Request Redirection</h3>
      <Divider className="my-4 p-0 " />
      {REQUEST_TYPES.map(({ label, key }) => (
        <div className="grid grid-cols-6 items-center space-y-5" key={key}>
          <p className="col-span-2 m-0 font-medium">{label}</p>
          <div className="col-span-2" />
          <SelectUI
            name={key}
            allowClear
            className="col-span-2"
            onChange={(value) => {
              handleChange(key, value)
            }}
            control={control}
            loading={fetchingPublicChannels}
            options={channels}
            placeholder="Select channel"
          />
        </div>
      ))}
    </Card>
  )
}
