import { Modal } from 'antd'
import { useQueryClient } from 'react-query'
import { TestingConnectionLoader } from '../../components'
import { useConnectedApp } from '../ConnectedApps'
import {
  FreshdeskIntegration,
  FreshdeskProvider,
} from '../integrations/freshdesk'
import { HubspotIntegration } from '../integrations/hubspot'

import { IntercomIntegration } from '../integrations/intercom'
import { NotionIntegration, NotionProvider } from '../integrations/notion'
import {
  SalesforceIntegration,
  SalesforceProvider,
  useSalesforce,
} from '../integrations/salesforce'
import { ShortcutIntegration, ShortcutProvider } from '../integrations/shortcut'
import { SlackIntegration, SlackProvider } from '../integrations/slack'
import { ZendeskIntegration, ZendeskProvider } from '../integrations/zendesk'

type Props = {
  integrations: any[] | undefined
}
export const IntegrationModal = ({ integrations }: Props) => {
  const queryClient = useQueryClient()

  const {
    setIsEdition,
    selectedIntegration: integration,
    setSelectedIntegration,
  } = useConnectedApp()
  const {
    isTestingConnection: isSalesforceTestingConnection,
    setConnectionFailed,
    setConnectionPassed,
  } = useSalesforce()

  const handleClose = () => {
    queryClient.invalidateQueries(['integrations'])
    setConnectionFailed(false)
    setConnectionPassed(false)
    setSelectedIntegration(null)
    setIsEdition(false)
  }

  const zendeskRequestType = integrations
    ?.filter(
      (e) =>
        e.id !== 'zendesk' &&
        e.id !== 'slack' &&
        e.id !== 'intercom' &&
        e.isEnabled
    )
    .flatMap((e) => e.requestTypes)

  const availableZendeskRequestTypes = [
    { label: 'Product', value: 'Product', disabled: false },
    { label: 'Billing', value: 'Billing', disabled: false },
    {
      label: 'Account',
      value: 'Account Manager',
      disabled: false,
    },
    {
      label: 'Tech Support',
      value: 'Tech Support',
      disabled: false,
    },
  ].map((item) => {
    const item2 = zendeskRequestType?.find((e) => e === item.label)
    if (item2) {
      item.disabled = true
    }
    return item
  })

  const salesforceRequestType = integrations
    ?.filter(
      (e) =>
        e.id !== 'salesforce' &&
        e.id !== 'slack' &&
        e.id !== 'intercom' &&
        e.isEnabled
    )
    .flatMap((e) => e.requestTypes)

  const availableSalesforceRequestTypes = [
    { label: 'Product', value: 'Product', disabled: false },
    { label: 'Billing', value: 'Billing', disabled: false },
    {
      label: 'Account',
      value: 'Account Manager',
      disabled: false,
    },
    {
      label: 'Tech Support',
      value: 'Tech Support',
      disabled: false,
    },
  ].map((item) => {
    const item2 = salesforceRequestType?.find((e) => e === item.label)
    if (item2) {
      item.disabled = true
    }
    return item
  })

  const freshdeskRequestType = integrations
    ?.filter(
      (e) =>
        e.id !== 'freshdesk' &&
        e.id !== 'slack' &&
        e.id !== 'intercom' &&
        e.isEnabled
    )
    .flatMap((e) => e.requestTypes)

  const availableFreshdeskRequestTypes = [
    { label: 'Product', value: 'Product', disabled: false },
    { label: 'Billing', value: 'Billing', disabled: false },
    {
      label: 'Account',
      value: 'Account Manager',
      disabled: false,
    },
    {
      label: 'Tech Support',
      value: 'Tech Support',
      disabled: false,
    },
  ].map((item) => {
    const item2 = freshdeskRequestType?.find((e) => e === item.label)
    if (item2) {
      item.disabled = true
    }
    return item
  })

  const hubspotRequestType = integrations
    ?.filter(
      (e) =>
        e.id !== 'hubspot' &&
        e.id !== 'slack' &&
        e.id !== 'intercom' &&
        e.isEnabled
    )
    .flatMap((e) => e.requestTypes)

  const availableHubspotRequestTypes = [
    { label: 'Product', value: 'Product', disabled: false },
    { label: 'Billing', value: 'Billing', disabled: false },
    {
      label: 'Account',
      value: 'Account Manager',
      disabled: false,
    },
    {
      label: 'Tech Support',
      value: 'Tech Support',
      disabled: false,
    },
  ].some((item) => hubspotRequestType?.find((e) => e === 'Tech Support'))

  return (
    <Modal
      destroyOnClose
      open={!!integration}
      centered
      width={680}
      footer={null}
      onCancel={handleClose}
      onOk={handleClose}
    >
      {integration === 'salesforce' && !isSalesforceTestingConnection && (
        <SalesforceProvider>
          {isSalesforceTestingConnection ? (
            <TestingConnectionLoader />
          ) : (
            <SalesforceIntegration
              requestType={availableSalesforceRequestTypes}
            />
          )}
        </SalesforceProvider>
      )}
      {integration === 'notion' && (
        <NotionProvider>
          {/* <TestingConnectionLoader /> */}
          <NotionIntegration />
        </NotionProvider>
      )}
      {integration === 'zendesk' && (
        <ZendeskProvider>
          {/* <TestingConnectionLoader /> */}
          <ZendeskIntegration requestType={availableZendeskRequestTypes} />
        </ZendeskProvider>
      )}
      {integration === 'slack' && (
        <SlackProvider>
          <SlackIntegration />
        </SlackProvider>
      )}
      {integration === 'intercom' && <IntercomIntegration />}
      {integration === 'hubspot' && (
        <HubspotIntegration requestType={availableHubspotRequestTypes} />
      )}
      {integration === 'freshdesk' && (
        <FreshdeskProvider>
          {/* <TestingConnectionLoader /> */}
          <FreshdeskIntegration requestType={availableFreshdeskRequestTypes} />
        </FreshdeskProvider>
      )}
      {integration === 'shortcut' && (
        <ShortcutProvider>
          <ShortcutIntegration />
        </ShortcutProvider>
      )}
    </Modal>
  )
}
