/* eslint-disable max-lines */
import { Select, Tooltip } from 'antd'
import Highcharts from 'highcharts'
import exportingData from 'highcharts/modules/export-data'
import enableExporting from 'highcharts/modules/exporting'
import WordCloud from 'highcharts/modules/wordcloud'
import { isArray, isObject, isString, mapValues } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { Info } from 'phosphor-react'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import styled from 'styled-components'
import FastSpinner from '../../FastSpinner'
enableExporting(Highcharts)
exportingData(Highcharts)
WordCloud(Highcharts)

Highcharts.setOptions({
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
})

export const HighchartsWithExports = Highcharts

export const STACKED_COLUMN_COLORS_OLD = [
  '#4E42C5',
  '#4CCCB7',
  '#B3D9FA',
  '#85AA32',
  '#64E058',
  '#EF66F0',
  '#FBACF6',
  '#CA9260',
  '#FBD127',
  '#FB7810',
]

export const STACKED_COLUMN_COLORS = [
  '#FEBD31',
  '#FC6F41',
  '#B84360',
  '#E557DC',
  '#B65DD8',
  '#49DFD3',
  '#36A75F',
  '#4DAFF4',
  '#4674CD',
  '#6250CF',
]

export const TWO_COLUMNS_CHART_COLORS = ['#6250CF', '#49DFD3']

export const THREE_COLUMNS_CHART_COLORS = ['#E557DB', '#FEBD31', '#4DAFF4']

export const PIE_COLORS = [
  '#6250CF',
  '#FEBD31',
  '#BBBBC0',
  '#49DFD3',
  '#AF5AD0',
  '#36A75F',
]

export const RAG_COLORS = ['#4CCCB7', '#FEAF38', '#F86464']

export const PIE_COLORS_V2 = [
  '#4E42C5',
  '#4CCCB7',
  '#B3D9FA',
  '#85AA32',
  '#64E058',
  '#EF66F0',
  '#FBACF6',
  '#CA9260',
  '#FBD127',
  '#FB7810',
  '#50BDFB',
  '#FF5A9F',
  '#25CDDD',
  '#27CB5F',
  '#FEAF38',
]

export const BAR_COLORS = ['#6250CF']
export const BAR_COLORS_2 = ['#4674CD']

export const STACKED_BAR_COLORS = [
  '#36A75F',
  '#49DFD3',
  '#FEBD31',
  '#FC6F41',
  '#B84360',
]

export const LINE_COLUMN_COLORS = ['#4674CD', '#49DFD3']
export const LINE_COLUMN_COLORS_2 = ['#CBBDFF', '#40AB8C']
export const LINE_COLUMN_COLORS_3 = ['#FC6F41', '#36A75F']

export const getProcessedData = (apiData: any[]) => {
  return apiData.map((series: any) => {
    const tempSeries = cloneDeep(series)
    tempSeries.data = tempSeries.data.map(
      ([x, y]: [x: any, y: any]): [any, any] => {
        return [moment(x).valueOf(), y]
      }
    )
    return tempSeries
  })
}

export const getProcessedDataV2 = (apiData: any[]) => {
  return apiData.map((series: any) => {
    const tempSeries = cloneDeep(series)
    tempSeries.data = tempSeries.data.map(
      ({ x, ...rest }: { x: any; rest: any }) => {
        return { x: moment(x).valueOf(), ...rest }
      }
    )
    return tempSeries
  })
}

export const GraphTitle = styled.div`
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
  color: var(--color-black-1);
  position: relative;
`

export const GraphWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 400px;
  border-radius: 16px;
  padding-bottom: 20px;
  box-sizing: content-box;
  background-color: var(--color-white);
  box-shadow: 0 4px 20px -3px var(--color-gray-shadow-1);
  .highcharts-container {
    margin: auto !important;
  }
  .highcharts-data-table {
    caption {
      height: 32px;
      padding: 5px 0;
      font-size: 12px;
      font-weight: 500;
      caption-side: top;
      text-align: center;
      font-style: normal;
      color: var(--color-black-1);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background-color: var(--color-gray-bg);
      border: 1px solid var(--color-gray-3);
      border-bottom: none;
    }
    table {
      width: 70%;
      margin: auto;
      tr td {
        text-align: center;
        border: 1px solid var(--color-gray-3);
      }
      tr th {
        border: 1px solid var(--color-gray-3);
      }
      thead {
        background-color: var(--color-gray-9);
        tr {
          height: 32px;
        }
      }
      tbody {
        tr th:first-child {
          background-color: var(--color-gray-9);
        }
      }
    }
  }
  .highcharts-menu {
    border: none !important;
    padding: 4px !important;
    border-radius: 6px !important;
    background: var(--color-white) !important;
    box-shadow: 0 20px 35px -5px rgba(12, 12, 13, 0.1),
      0 4px 10px 0 rgba(12, 12, 13, 0.05), 0 0 0 1px rgba(12, 12, 13, 0.03) !important;
    .highcharts-menu-item {
      height: 28px !important;
      display: flex !important;
      font-size: 12px !important;
      align-items: center !important;
      color: var(--color-black-1) !important;
      :hover {
        border-radius: 4px !important;
        background-color: var(--color-purple-3) !important;
      }
    }
    hr {
      display: none !important;
    }
  }
`

export const useGetUpdatedWidth = (
  graphRef: MutableRefObject<HTMLDivElement | null>
) => {
  const [width, setWidth] = useState(0)
  useEffect(() => {
    let observer: ResizeObserver
    const element = graphRef.current
    if (element) {
      observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const element = entry.target
          setWidth(element.clientWidth)
        }
      })
      observer.observe(element)
    }

    return () => {
      if (element) {
        observer.unobserve(element)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return width
}

const ChartLoaderWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChartBlockerText = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10;

  span {
    padding: 4px 8px;
    border-radius: 6px;
    background-color: var(--color-gray-3);
  }
`

export const ChartLoader = () => {
  return (
    <ChartLoaderWrapper>
      <FastSpinner size="custom" w={32} bw={2} />
    </ChartLoaderWrapper>
  )
}

export const ChartBlocker = ({
  text = 'No data available. Check your filters and calculations.',
}: {
  text?: string
}) => {
  return (
    <ChartLoaderWrapper>
      <ChartBlockerText className="fixed flex justify-center items-center z-20">
        <span>{text}</span>
      </ChartBlockerText>
    </ChartLoaderWrapper>
  )
}

const StyledTooltip = styled(Tooltip)`
  position: relative;
  bottom: 8px;
  left: 4px;
`

export const ChartTooltip = ({ title }: { title: string }) => {
  return (
    <StyledTooltip title={title}>
      <Info size={16} color="var(--color-gray-8)" />
    </StyledTooltip>
  )
}

export const BUSINESS_HOURS_OPTIONS = [
  {
    label: 'Business Hours',
    value: 'Business Hours',
  },
  {
    label: 'Actual Time',
    value: 'Actual Time',
  },
]

export const REQUEST_STATUS_OPTIONS = [
  {
    label: 'Open vs Closed',
    value: 'Open vs Closed',
  },
  {
    label: 'Open Statuses',
    value: 'Open Statuses',
  },
]

export const DropdownContainer = styled.div<{ $width: number }>`
  top: 2px;
  right: -8px;
  margin-right: 8px;
  position: absolute;
  .select-container {
    position: relative;
    width: ${(props) => props.$width}px;
  }
  .ant-select-item.ant-select-item-option {
    margin: 2px 4px;
    border-radius: 6px;
  }
  .ant-select-selector {
    height: 28px !important;
    display: flex !important;
    align-items: center !important;
  }
`

export type ChartProps = {
  type?: any
  setType?: any
  options?: any
  title: string
  tooltip?: string
  seriesData: any[]
  colors?: string[]
  rowWidth?: number
  className?: string
  yAxisTitle?: string
  tableCaption: string
  categories?: string[]
  showDropdown?: boolean
  dropdownWidth?: number
  tooltipSubtext?: string
  chartBlockerText?: string
  showChartBlocker?: boolean
  dropdownDisabled?: boolean
  primaryYAxisTitle?: string
  shouldFormatYAxis?: boolean
  secondaryYAxisTitle?: string
}

export const TIMED_LABELS = ['First response time']

export const OPEN_CLOSE_OPTIONS = [
  {
    label: 'Open',
    value: 'Open',
  },
  {
    label: 'Closed',
    value: 'Closed',
  },
]

export const ChartDropdown = ({
  type,
  options,
  setType,
  dropdownWidth,
  disabled = false,
}: {
  type: any
  setType: any
  options: any[]
  disabled?: boolean
  dropdownWidth: number
}) => {
  return (
    <DropdownContainer $width={dropdownWidth}>
      <Select
        size="small"
        value={type}
        options={options}
        onChange={setType}
        disabled={disabled}
        className="select-container"
        getPopupContainer={(trigger) => trigger.parentElement || document.body}
      />
    </DropdownContainer>
  )
}

export const HOURS_CATEGORIES = [
  '0-12 hrs',
  '12-24 hrs',
  '1-4 days',
  '5-15 days',
  '15-30 days',
  '> 30 days',
]

export const useUpdateChartDataTable = (seriesData: any) => {
  const chartRef = useRef<any>(null)
  const timerRef = useRef<null | NodeJS.Timeout>(null)

  useEffect(() => {
    try {
      if (chartRef.current) {
        const chart = chartRef.current.chart
        if (chart.isDataTableVisible) {
          chart.hideData()
          timerRef.current = setTimeout(() => {
            chart.viewData()
          }, 50)
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }, [seriesData])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return chartRef
}

export const sanitizeDeep = (data: any): any => {
  if (isString(data)) {
    return sanitizeHtml(data, {
      disallowedTagsMode: 'escape',
    })
  }
  if (isArray(data)) {
    return data.map((item) => sanitizeDeep(item))
  }
  if (isObject(data)) {
    return mapValues(data, (value) => sanitizeDeep(value))
  }
  return data
}

export const getRAGColorByType = (type: string, index: number) => {
  const lowerCaseType = (type || '').toLowerCase()
  if (lowerCaseType === 'low' || lowerCaseType === 'positive') {
    return '#4CCCB7'
  }
  if (lowerCaseType === 'medium' || lowerCaseType === 'neutral') {
    return '#FEAF38'
  }
  if (lowerCaseType === 'high' || lowerCaseType === 'negative') {
    return '#F86464'
  }
  return PIE_COLORS_V2[index]
}
