import { useEffect } from 'react'

type Props = {
  keyCombination: (string | 'ctrlKey' | 'altKey' | 'shiftKey' | 'metaKey')[]
  callback: (e: KeyboardEvent) => void
}

const useKeyboardShortcut = (
  keyCombination: Props['keyCombination'],
  callback: Props['callback'],
  disableInInputs = true
) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      const target = event.target as any

      if (disableInInputs) {
        const isQuillEditor = target.className.includes('ql-editor')
        const isTextInput =
          (target.tagName === 'INPUT' && target.type !== 'checkbox') ||
          target.tagName === 'TEXTAREA'

        if (isQuillEditor || isTextInput) {
          return
        }
      }

      const keyPressed = String.fromCharCode(event.which).toLowerCase()
      if (keyCombination.length === 1 && keyPressed === keyCombination[0]) {
        callback(event)
        return
      }
      const keyComboPressed = keyCombination.every((key) => {
        if (
          key === 'ctrlKey' ||
          key === 'altKey' ||
          key === 'shiftKey' ||
          key === 'metaKey'
        ) {
          return event[key as keyof KeyboardEvent]
        }
        return keyPressed === key
      })
      if (keyComboPressed) {
        callback(event)
      }
    }
    window.addEventListener('keydown', handler)

    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [keyCombination, callback, disableInInputs])
}

export default useKeyboardShortcut
