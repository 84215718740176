import { Button, Divider, Typography } from 'antd'
import { CaretLeft, CaretRight, LockSimple } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { sharedSlackChannel } from '../../../api/admins'
import { CALENDLY_LINK } from '../../../config'
import { useAuth } from '../../../context'
import { useAddToSlack } from '../../../context/AddToSlackProvider'
import GDPR from '../../../images/icons/gdpr.webp'
import SOC from '../../../images/icons/soc.webp'
import SlackIcon from '../../../images/logos/slack.svg'
import { addToSlackInfo } from '../../../pages/AddToSlack/definitions'
import '../../../pages/ProductTour/index.css'
import { welcomeInfo } from '../../../pages/Welcome/definitions'
import { AddToSlackButton } from '../addThenaToSlack'

const { Paragraph, Title, Link: TextLink } = Typography

const cardDetails = [
  {
    title: 'Request Management',
    desc: `Thena’s advanced Machine Learning technology detects all customer requests in real-time, allowing for efficient triaging, redirection, and collaboration.`,
    cover: 'request-management-illustration.svg',
    addToSlackBtn: false,
    route: 'request-management',
    key: 0,
  },
  {
    title: 'SLAs & Alerts',
    desc: 'Ensure that customer requests are being addressed promptly and effectively through Thena’s alerts for Customer Messages, Breach of SLAs on First Responses and Resolutions. Alerting is available for Internal Teams as well as organizational leadership.',
    cover: 'manage-alerts-illustration.svg',
    addToSlackBtn: false,
    route: 'manage-alerts',
    key: 1,
  },
  {
    title: 'Engagement Analytics & Insights',
    desc: 'Unified Dashboard to analyse Customer Interactions, Escalations, Turn-around Times with intuitive actionable insights. Deep drill down reporting for Customer Success Managers, Support Teams and Executive Leadership.',
    cover: 'analytics-illustration.svg',
    addToSlackBtn: false,
    route: 'analytics',
    key: 2,
  },
  {
    title: 'Connectors',
    desc: 'Focus only on  customer interactions in Slack by eliminating the hassle of creating Support tickets through our powerful bi-directional integrations with all ERP ticketing systems. With these connectors, responses are fed back to customer conversations, and also supports attachments, rich texts, images, and more.',
    cover: 'connectors-illustration.svg',
    addToSlackBtn: false,
    route: 'connectors',
    key: 3,
  },
  {
    title: 'Marketing Automation',
    desc: 'Unlock Slack Inbound Marketing and enhance customer retention! Tune into Thena’s Marketing Automation to create intuitive messages with Message Builder. Broadcast newsletters, event invites, surveys through advanced segment targeting and measure their reach and engagement of slack marketing.',
    cover: 'marketing-illustration.svg',
    addToSlackBtn: false,
    route: 'marketing-automation',
    key: 4,
  },
  {
    title: 'Channel Operations',
    desc: 'Automate the management of customer Slack channels from creation to archival using our Channel Management toolkit. Also, get relevant channel analytics to drive better engagement and value.',
    cover: 'channel-illustration.svg',
    addToSlackBtn: false,
    route: 'channel-operations',
    key: 5,
  },
  {
    title: 'Data Security & Compliance',
    desc: 'Thena is compliant with SOC2 and GDPR data security standards. Majority data is kept within the Slack environment and no message data or sensitive information is stored at our end.',
    cover: 'security-illustration.svg',
    addToSlackBtn: false,
    route: 'security-data',
    key: 6,
  },
  {
    title: 'Customer Story',
    desc: 'Workramp uses Thena to efficiently manage over 400 Customer channels with just 3 support agents through our Intercom integration. The Case Study highlights over 25% increase in Customer Success Productivity helping teams to focus on Customer Growth.',
    cover: 'customer-illustration.svg',
    addToSlackBtn: true,
    route: 'customer-story',
    key: 7,
  },
]
const ProductTourCard = ({ cardName }: { cardName?: string | undefined }) => {
  const { productTourKey } = useParams()
  const [currentIndex, setCurrentIndex] = useState(
    cardDetails.findIndex((card) => card.route === productTourKey)
  )
  const productCardDetails = cardDetails.find(
    (card, index) => card.route === productTourKey
  )
  const navigate = useNavigate()
  useEffect(() => {
    navigate(`/product-tour/${cardDetails[currentIndex].route}`)
  }, [currentIndex, navigate])

  const { user } = useAuth()
  const onNext = () => {
    setCurrentIndex(currentIndex + 1)
  }
  const onPrev = () => {
    setCurrentIndex(currentIndex - 1)
  }
  const { checked } = useAddToSlack()
  const sharedSlackChannelMutation = useMutation(sharedSlackChannel)
  const onClickHandler = () => {
    sharedSlackChannelMutation.mutate(checked)
  }
  return (
    <>
      <div
        className={`flex flex-col justify-center items-center overflow-y-hidden productTour ${
          !user?.isThenaInstalled
            ? 'max-w-5xl ml-32 3xl:mx-[325px] mt-14 3xl:mt-20'
            : 'max-w-5xl ml-32 3xl:mx-[325px]'
        }`}
      >
        <div
          className={`bg-white w-full ${
            !user?.isThenaInstalled
              ? 'h-5/6 3xl:mt-10 border border-brand-border border-solid rounded-md'
              : 'h-5/6 3xl:mt-20 mt-10 border border-brand-border border-solid rounded-md'
          } flex justify-between items-center flex-col px-16 pt-10 gap-10 tourCard`}
        >
          <div className="w-full">
            <div className="w-full flex flex-col items-start justify-center mb-2">
              <Link to="/product-tour">
                <Button
                  type="link"
                  className="font-medium text-brand-accent mb-5 p-0 flex items-center"
                  size="large"
                  icon={<CaretLeft />}
                >
                  Go to main screen
                </Button>
              </Link>
              <Title level={4}>{productCardDetails?.title}</Title>
              <Paragraph
                className={`${!user?.isThenaInstalled ? 'w-full' : 'w-full'}`}
              >
                {productCardDetails?.title === 'Customer Story' ? (
                  <p>
                    Workramp uses Thena to efficiently manage over{' '}
                    <strong>400 Customer channels</strong> with just 3 support
                    agents through our Intercom integration. The Case Study
                    highlights over{' '}
                    <strong>
                      25% increase in Customer Success Productivity
                    </strong>{' '}
                    helping teams to focus on Customer Growth.
                  </p>
                ) : (
                  productCardDetails?.desc
                )}
                {productCardDetails?.title === 'Data Security & Compliance' ? (
                  <a
                    href={'https://www.thena.ai/security-and-compliance/'}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    {' '}
                    Read more
                  </a>
                ) : (
                  ''
                )}
              </Paragraph>
            </div>
            <div className="w-full">
              <LazyLoadImage
                alt={productCardDetails?.title}
                effect="blur"
                src={`https://thena-medias.s3.us-west-1.amazonaws.com/product-tour/${productCardDetails?.cover}`}
                width={'100%'}
                className={`2xl:h-auto ${
                  productCardDetails?.addToSlackBtn ? 'xl:h-[22rem]' : 'xl:h-96'
                }`}
              />
            </div>
            {productCardDetails?.addToSlackBtn && !user?.isThenaInstalled && (
              <div className="flex flex-col justify-center items-center mt-2 mb-4">
                <AddToSlackButton className="mt-4" />

                <Button
                  onClick={onClickHandler}
                  href={addToSlackInfo.card.buttonLink}
                  type="default"
                  icon={
                    <img
                      className="w-6 h-6 mr-3 mt-6"
                      src={SlackIcon}
                      alt="slack icon"
                    />
                  }
                  size="large"
                  className="w-fit mt-2 mb-2 flex justify-center items-center btn py-6 px-6 font-bold border border-solid border-gray-dark rounded-[4px]"
                >
                  <span className="mt-6">{addToSlackInfo.card.buttonText}</span>
                </Button>
              </div>
            )}
            {!productCardDetails?.addToSlackBtn && (
              <div
                className={`flex justify-center items-center gap-1 mt-12 ${
                  !user?.isThenaInstalled ? 'mb-10' : 'mb-10'
                }`}
              >
                <Button
                  type="default"
                  icon={<CaretLeft color="#4A299C" size={20} />}
                  size="middle"
                  shape="circle"
                  className="flex justify-center items-center font-bold border border-solid border-gray-dark bg-hover-accent hover:bg-hover-accent"
                  onClick={onPrev}
                  disabled={currentIndex === 0}
                />
                <Divider
                  style={{ height: '30px', color: '#DCE2F0', fontWeight: 700 }}
                  type="vertical"
                />
                <Button
                  type="default"
                  shape="circle"
                  icon={<CaretRight color="#4A299C" size={20} />}
                  size="middle"
                  className="flex justify-center items-center font-bold border border-solid border-gray-dark bg-hover-accent hover:bg-hover-accent"
                  onClick={onNext}
                  disabled={currentIndex === 7}
                />
              </div>
            )}
            {productCardDetails?.addToSlackBtn && user?.isThenaInstalled && (
              <div
                className={`flex flex-col justify-center items-center mt-12 ${
                  !user?.isThenaInstalled ? '' : 'mb-10'
                }`}
              >
                <Button
                  type="default"
                  size="large"
                  target="_blank"
                  href={`${CALENDLY_LINK}`}
                  className="font-bold border border-solid border-gray-dark rounded-[4px]"
                >
                  Schedule Demo
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center ml-[-100px] mt-2">
        <div className="flex items-center gap-1">
          <LockSimple size={14} color="#696F7B" />
          <span className="text-muted">
            We do not store any conversation data{' '}
          </span>
        </div>
        <div>
          <Divider className="py-2 text-brand-border" type="vertical" />
        </div>
        <TextLink
          href={welcomeInfo.links.terms.url}
          target="_blank"
          className="text-muted visited:text-muted welcome"
        >
          <span className="underline"> {welcomeInfo.links.terms.text}</span>
        </TextLink>
        <div>
          <Divider className="py-2 text-gray-500" type="vertical" />
        </div>
        <TextLink
          href={welcomeInfo.links.privacy.url}
          target="_blank"
          className="text-muted visited:text-muted welcome"
        >
          <span className="underline"> {welcomeInfo.links.privacy.text}</span>
        </TextLink>
        <div>
          <Divider className="py-2 text-gray-500" type="vertical" />
        </div>
        <div className="flex items-center gap-1">
          <img src={GDPR} alt="gdpr-compliance" className="w-5 h-5" />
          <img src={SOC} alt="soc" className="w-5 h-5" />
        </div>
      </div>
    </>
  )
}

export { ProductTourCard }
