import { Input, Row } from 'antd'
import { MagnifyingGlass } from 'phosphor-react'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import useClusters from '../../../../hooks/useClusters'
import ClusterIcon from '../../../../images/icons/cluster.svg'
import { ClusterType } from '../../../../types'
import BulkEditMenu from './BulkEditMenu'

export const BulkCluster = ({
  onSelectCluster,
  setOpen,
  isOpen,
}: {
  onSelectCluster: (value: string) => void
  setOpen: (isOpen: boolean) => void
  isOpen: boolean
}) => {
  const { id = '' } = useParams()
  const { data = [] } = useClusters({
    assistantId: id,
  })
  const [searchQuery, setSearchQuery] = useState('')

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }
  const filteredList: ClusterType[] = useMemo(() => {
    return data.filter((it: any) =>
      it.name?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
  }, [data, searchQuery])

  const content = (
    <Wrapper>
      <div className="cluster-list">
        {filteredList &&
          filteredList.map((item, index) => {
            return (
              <Row
                key={index}
                onClick={() => onSelectCluster(item._id)}
                className="content-item"
                align="middle"
                justify="start"
              >
                {item.name}
              </Row>
            )
          })}
      </div>
      <Input
        autoFocus
        onChange={onSearch}
        placeholder="Search..."
        prefix={<MagnifyingGlass color="#707070" />}
      />
    </Wrapper>
  )

  return (
    <BulkEditMenu
      label="Cluster"
      iconAlt="cluster"
      iconSrc={ClusterIcon}
      className="qna-filter"
      content={content}
      isOpen={isOpen}
      setOpen={setOpen}
    />
  )
}

const Wrapper = styled.div`
  .cluster-list {
    margin: 8px;
    max-height: 300px;
    overflow-y: auto;
    .content-item {
      padding: 6px 8px;
      margin-bottom: 4px;
      cursor: pointer;
    }
  }
`
