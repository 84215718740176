import { AxiosResponse } from 'axios'
import { api } from '.'
import { AnalyticsCustomer } from '../types/wsAnalyticsTypes'

export const fetchRequests = (id: string) =>
  api
    .get<AxiosResponse<FetchRequestsType>>(`/v1/requests`, {
      params: { channel_ids: [id] },
    })
    .then(({ data }) => data.data)

export const fetchRequestsForSmartInbox = (ids: null | 'all' | string[]) => {
  if (!ids) {
    return {
      tickets: [],
      totalTickets: 0,
      customer_name: '',
      stats: {
        openEscalations: 0,
        openEscalationsToolTip: 'Currently open requests that are escalated',
        openRequests: 0,
        openRequestsToolTip: 'Requests that are currently open',
        avgRequestOpenTime: 0,
        avgFeedbackScore: 0,
        avgRequestOpenTimeToolTip:
          'Average time spent on a request before it gets closed',
        avgFeedbackScoreToolTip:
          'Average feedback score for all closed requests',
        totalFeedbackMax: 0,
      },
      dummyData: false,
    }
  } else {
    return api
      .get<AxiosResponse<FetchRequestsType>>(`/v1/requests`, {
        params: { channel_ids: ids },
      })
      .then(({ data }) => data.data)
  }
}

export const fetchRequestsRelationships = () =>
  api
    .get<AxiosResponse<FetchRequestsRelationships>>(
      '/v1/requests/relationships'
    )
    .then(({ data }) => data.data)

export const fetchAnalyticsCustomers = () =>
  api
    .get<AxiosResponse<AnalyticsCustomer[]>>(
      '/v1/requests/analytics-relationships'
    )
    .then(({ data }) => data.data)

export const requestAssignee = (data: RequestAssigneeType) =>
  api.post('v1/requests/assignee', data).then(({ data }) => data.data)

export const fetchClosedRequestsForSmartInbox = (
  ids: null | 'all' | string[],
  page: number
) => {
  if (!ids) {
    return {
      tickets: [],
      totalTickets: 0,
      customer_name: '',
      stats: {
        openEscalations: 0,
        openEscalationsToolTip: 'Currently open requests that are escalated',
        openRequests: 0,
        openRequestsToolTip: 'Requests that are currently open',
        avgRequestOpenTime: 0,
        avgFeedbackScore: 0,
        avgRequestOpenTimeToolTip:
          'Average time spent on a request before it gets closed',
        avgFeedbackScoreToolTip:
          'Average feedback score for all closed requests',
        totalFeedbackMax: 0,
      },
      dummyData: false,
    }
  } else {
    return api
      .get<AxiosResponse<FetchRequestsType>>(`/v1/closed-requests`, {
        params: { channel_ids: ids, page: page },
      })
      .then(({ data }) => data.data)
  }
}

export interface FetchRequestsType {
  tickets: Ticket[]
  totalTickets: number
  customer_name: string
  stats: Stats
  dummyData: boolean
  hasMore?: boolean
}

export interface Stats {
  openEscalations: number
  openEscalationsToolTip: string
  openRequests: number
  openRequestsToolTip: string
  avgRequestOpenTime: number
  avgFeedbackScore: number
  avgRequestOpenTimeToolTip: string
  avgFeedbackScoreToolTip: string
  totalFeedbackMax: number
}

export interface Ticket {
  _id: string
  title: string
  description: string
  type: string
  priority: string
  ts: string
  key: string
  status: string
  activity: Activity[]
  relationshipChannelId: string
  relationshipChannelName: string
  isSilent: boolean
  createdAt: string
  updatedAt: string
  permalink: string
  user_first_name: string
  user_last_name: string
  user_real_name?: string
  user_profile: string
  replies: number
  redirectForRequest: string
  daysCount: number
  assignedTo?: {
    id: string
    name: string
    _id: string
  }
  customer_team?: {
    id: string
    name: string
    icon: {
      image_230: string
    }
  }
  feedback: {
    feedback: string
    feedback_on: string
    user_name: string
    user_image: string
  }[]
}

export interface Activity {
  id: number
  text: string
  date: number
  profileImg: string
}

export interface FetchRequestsRelationships {
  rIds: RID[]
}

export interface RID {
  _id: string
  customer_name: string
  channelName: string
  channelId: string
  type: 'External' | 'Internal'
  lastUpdated: string
  connected_teams: string[]
  vendor_name: string
  sinks?: string
}

type RequestAssigneeType = {
  assignee: string
  ts: any
  channelId: string
}
