import { api } from '.'

type SurveryAnswerType = {
  survey: {
    question: string
    answer: boolean
  }[]
  survey_status?: string
}
type ChannelType = {
  channelName: string
  participants: string[]
  sla?: number | null
  save?: string
}
type ConfigurationType = {
  suggestedRequestChannel?: string
  sla?: string
  channel?: string
}

type SettingUp = {
  onboarded: boolean
  members: {
    count: number
    status: 'not_started' | 'syncing' | 'rate_limited' | 'synced'
  }
  channels: {
    count: number
    status: 'not_started' | 'syncing' | 'rate_limited' | 'synced'
  }
}

export const syncSlack = () =>
  api.get('/v1/slack/sync').then(({ data }) => data.data)

export const syncOnboarding = () =>
  api.get('/v1/onboarding/sync').then(({ data }) => data.data)

export const makeOnboardingFinished = () =>
  api.patch('/v1/mark-finished').then(({ data }) => data.data)

export const fetchOnboardingStatus = () =>
  api.get('/v1/onboarding-finished').then(({ data }) => data.data)

export const getInterest = (type: string) =>
  api.post('/v1/getInterest', { interestType: type }).then(({ data }) => data)

export const saveInterest = (type: string) =>
  api
    .post('/v1/saveInterest', { interestType: type })
    .then(({ data }) => data.data)

export const fetchChecklist = () =>
  api.get('/v1/checklist').then(({ data }) => data.data)

export const startSlackTutorial = () =>
  api.get('/v1/start-tutorial').then((data) => {
    return data.data.data
  })
export const gettingStarted = () =>
  api
    .patch('/v1/user-checklist', { gettingStarted: true })
    .then(({ data }) => data.data)

export const surveyAnswers = (data: SurveryAnswerType) =>
  api.post('/v1/surveyanswers/onboarding', data).then((data) => data)

export const testChannel = (data: ChannelType) =>
  api.post('/v1/create-shared-channel', data).then((data) => data)

export const internalChannel = ({ save, ...data }: ChannelType) =>
  api
    .post(`/v1/create-internal-channel?save=${save}`, data)
    .then((data) => data)

export const setInstallationConfiguration = (data: ConfigurationType) =>
  api.patch('/v1/set-configuration', data).then((data) => data)

export const logoutUser = (data: { id?: string }) =>
  api.post('/v1/logout', data).then((data) => data)

export const settingUpStatus = () =>
  api.get('/v1/setting-up-status').then((data) => data.data)

export const fetchSettingUpStatus = (): Promise<SettingUp> =>
  api.get('/v2/onboarding/status').then((data) => data.data)

export const updateChecklist = () =>
  api.patch('/v1/update-checklist').then(({ data }) => data.data)
