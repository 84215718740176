import { Popover, Tooltip } from 'antd'
import { Question } from 'phosphor-react'
import { useState } from 'react'
import styled from 'styled-components'
import useMAEditorActions from '../../../hooks/useMAEditorActions'
import PlusIcon from '../../../images/icons/plus-icon-white.svg'
import { helphubUtil } from '../../../utils/helphub'
import { BLOCKS, BLOCK_TYPES } from './constants'

const PlusBanner = styled.div`
  height: 16px;
  margin: 12px 20px;
  border-radius: 4px;
  background-color: rgba(123, 0, 255, 0.25);
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`

const Title = styled.div`
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
`

const Content = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-rows: minmax(36px, auto);
  grid-template-columns: repeat(2, 1fr);
`

const BlockTypeChip = styled.div`
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 3px var(--color-primary);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  :hover {
    box-shadow: 0px 0px 6px var(--color-primary);
  }
`

type IProps = {
  index: number
}

const ToolInfo = styled.span`
  font-weight: 600;
  font-size: 12px;
`

const AddNewBlock = ({ index }: IProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { handleAddBlock } = useMAEditorActions()

  const handleClick = (item: string) => {
    const block = BLOCKS[item]
    handleAddBlock(index + 1, block)
    setIsOpen(false)
  }

  const renderContent = () => {
    return (
      <div>
        <Title>
          <div className="flex items-center gap-1">
            Select a block type{' '}
            <Question
              className="cursor-pointer"
              color="var(--color-gray-6)"
              size={16}
              onClick={() => helphubUtil(116746)}
            />
          </div>
        </Title>
        <Content>
          {BLOCK_TYPES.map((item) => {
            return (
              <BlockTypeChip key={item} onClick={() => handleClick(item)}>
                {item}
              </BlockTypeChip>
            )
          })}
        </Content>
      </div>
    )
  }

  return (
    <Popover
      open={isOpen}
      trigger="click"
      placement="bottom"
      content={renderContent}
      onOpenChange={(state) => setIsOpen(state)}
    >
      <Tooltip
        title={<ToolInfo>Add New Block below</ToolInfo>}
        placement="bottom"
      >
        <PlusBanner className="render-plus-banner">
          <img src={PlusIcon} alt="add" width={8} height={8} />
        </PlusBanner>
      </Tooltip>
    </Popover>
  )
}

export default AddNewBlock
