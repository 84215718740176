import MDEditor from '@uiw/react-md-editor'
import { Avatar, Row } from 'antd'
import styled from 'styled-components'
import Logo from '../../../../images/logos/thena_logo.svg'

type LoadingAnswerProps = {
  context: string
  userAvatarUrl: string
}

export const LoadingAnswer = ({
  context,
  userAvatarUrl,
}: LoadingAnswerProps) => {
  return (
    <Wrapper data-color-mode="light">
      <Row className="question-row" wrap={false}>
        <Avatar src={userAvatarUrl} size={20} style={{ marginTop: -1 }} />
        <MDEditor.Markdown source={context} className="question-container" />
      </Row>
      <Row style={{ gap: 12 }}>
        <img src={Logo} alt="assistant" style={{ height: '20px' }} />
        <div className="dot-wrapper">
          <span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </div>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .question-row {
    gap: 12px;
    margin-bottom: 24px;
  }
  .question-container {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.14px;
  }
  .dot-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px 8px;
    border-radius: 8px;
    background: var(--color-gray-bg);
  }
  .dot {
    display: inline-block;
    margin: 0 2px;
    font-size: 24px;
    animation: jump 1.2s infinite;
  }
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  @keyframes jump {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
      opacity: 0.5;
    }
    40% {
      transform: translateY(-4px);
      opacity: 1;
    }
    60% {
      transform: translateY(-2px);
      opacity: 1;
    }
  }
`
