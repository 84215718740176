import { Avatar, Timeline, Tooltip } from 'antd'
import { fromUnixTime } from 'date-fns'
import { isEmpty, toInteger } from 'lodash'
import moment from 'moment'
import { MagicWand } from 'phosphor-react'
import styled from 'styled-components'
import MetaDataEmpty from '../../../images/icons/empty-summary.svg'
import { EventMetadataType, SummaryDetail } from '../../../types/accountsTypes'
import { Beacon } from './Beacon'
import { EmptyState, GuideWrapper } from './EmptyState'

const Wrapper = styled.div`
  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    text-align: left;
    margin-left: 12px;
    width: auto;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
    left: 15%;
  }
  .ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content {
    left: 15%;
    width: calc(80% - 36px);
  }
`

const ChipWrapper = styled.div`
  width: fit-content;
  height: 25px;
  padding: 1px 8px 4px 8px;
  border-radius: 20px;
  border: 1px solid var(--color-gray-3);
  background-color: var(--color-gray-bg);
`

const SummaryWrapper = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  margin-top: 12px;
  text-align: justify;
`

const TagsWrapper = styled.div<{ $background: string; $color: string }>`
  width: fit-content;
  height: 20px;
  padding: 1px 8px 4px 8px;
  border-radius: 20px;
  border: 1px solid transparent;
  font-size: 10px;
  font-weight: 500;
  ${({ $background }) =>
    $background
      ? `background: ${$background};`
      : `background-color: var(--color-gray-bg);`}
  ${({ $color }) =>
    $color ? `color: ${$color};` : `color: var(--color-black-1);`}
`

const ParticipantsWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 18px;
`
const SummaryInfo = styled.div`
  border-radius: 5px;
  background: #e7f0fd;
  padding: 6px 8px;
  margin-bottom: 16px;
  font-size: 12px;
  width: 652px;
  margin-left: 32px;
  mragin-right: 16px;
`

const SummaryInfoTitle = styled.div`
  color: #0c0c0d;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`
const Chip = ({
  chipName,
  isExternal,
}: {
  chipName: string
  isExternal: boolean
}) => {
  return (
    <ChipWrapper>
      <div className="flex items-center gap-1 justify-start">
        <span className="font-semibold text-xs">{chipName}</span>
        <p className="mb-0 text-[var(--color-gray-4)]">•</p>
        <span className="text-xs text-[var(--color-gray-4)]">
          {isExternal ? 'External Summary' : 'Internal Summary'}
        </span>
      </div>
    </ChipWrapper>
  )
}

const Summary = ({ summary }: { summary: EventMetadataType }) => {
  return (
    <>
      <SummaryWrapper>{summary?.title}</SummaryWrapper>
      <div className="text-justify mt-3">{summary?.summary}</div>
    </>
  )
}

const colorsList = [
  { color: '#E05D86', background: '#FEE1EB' },
  { color: '#4781B2', background: '#E3ECF3' },
  { color: '#7E63DB', background: '#EBE7FF' },
  { color: '#E0A022', background: '#FFF9DA' },
  { color: '#4BAC90', background: '#E6F5F0' },
  { color: '#8E5D39', background: '#F6E4D1' },
]

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colorsList.length)
  return colorsList[randomIndex]
}

const Tags = ({ summary }: { summary: EventMetadataType }) => {
  return (
    <div className="flex items-center gap-1 mt-3">
      {summary?.tags?.map((item) => {
        const { color, background } = getRandomColor()
        return (
          <div className="flex items-center gap-1 mt-3">
            <TagsWrapper $color={color} $background={background}>
              <div>{item}</div>
            </TagsWrapper>
          </div>
        )
      })}
    </div>
  )
}

const Participants = ({ summary }: { summary: EventMetadataType }) => {
  return (
    <>
      <ParticipantsWrapper>Participants</ParticipantsWrapper>
      <Avatar.Group size={'small'} className="mt-2">
        {summary?.participants?.map((user) => (
          <Avatar src={user?.user?.profile?.image_24} />
        ))}
      </Avatar.Group>
    </>
  )
}

export const MetaData = ({
  summaryDetails,
}: {
  summaryDetails: SummaryDetail[]
}) => {
  const isDataEmpty = (detail: any) => {
    return (
      isEmpty(detail?.event_metadata?.external) &&
      isEmpty(detail?.event_metadata?.internal)
    )
  }
  const allDataEmpty = summaryDetails.every(isDataEmpty)
  if (allDataEmpty) {
    return (
      <EmptyState
        title="Looks like we're waiting to start a conversation!"
        description="Once you've exchanged messages on your external or internal Slack
      channel, you'll see AI-generated summaries of those conversations
      here. This feature helps you quickly grasp the essence of your
      interactions."
        footer="Learn more about conversation summaries in our"
        helpText="Help Guide"
        image={MetaDataEmpty}
      />
    )
  } else {
    return (
      <Wrapper>
        <SummaryInfo className="flex items-center justify-center gap-2">
          <MagicWand size={20} color="#0a4a75" weight="light" />
          <SummaryInfoTitle>
            Our AI Summary Tool is in learning mode. While we fine-tune its
            skills, you might notice a few quirks
          </SummaryInfoTitle>
          <GuideWrapper role={'button'}>Learn More</GuideWrapper>
        </SummaryInfo>
        <Timeline
          mode="left"
          pendingDot={<Beacon sentiment="gray" staticMode={true} />}
          pending
        >
          {summaryDetails.map((summary) => {
            const external = summary?.event_metadata?.external && (
              <Timeline.Item
                key={`external-${
                  summary.createdAt + summary?.event_metadata?.external?.title
                }`}
                label={
                  <Tooltip
                    title={
                      <div className="flex flex-col">
                        Summary generated
                        <span>
                          {moment(
                            fromUnixTime(
                              toInteger(summary?.event_metadata?.oldest)
                            )
                          ).format('L')}{' '}
                          -{' '}
                          {moment(
                            fromUnixTime(
                              toInteger(summary?.event_metadata?.latest)
                            )
                          ).format('L')}
                        </span>
                      </div>
                    }
                  >
                    <span className="text-[#6F6F75] text-xs">
                      {moment(summary.createdAt).format('L')}
                    </span>
                  </Tooltip>
                }
                dot={
                  <Beacon
                    sentiment={
                      summary?.event_metadata?.external?.sentiment?.indicator
                    }
                    tooltip={
                      summary?.event_metadata?.external?.sentiment?.reason
                    }
                  />
                }
              >
                <Chip chipName={summary?.customerName} isExternal={true} />
                <Summary summary={summary?.event_metadata?.external} />
                <Tags summary={summary?.event_metadata?.external} />
                <Participants summary={summary?.event_metadata?.external} />
                {/* <RequestList summary={summary} /> */}
              </Timeline.Item>
            )

            const internal = summary?.event_metadata?.internal && (
              <Timeline.Item
                key={`internal-${
                  summary.createdAt + summary?.event_metadata?.internal?.title
                }`}
                label={
                  <Tooltip title="Summary generated">
                    <span className="text-[#6F6F75] text-xs">
                      {moment(summary.createdAt).format('L')}
                    </span>
                  </Tooltip>
                }
                dot={
                  <Beacon
                    sentiment={
                      summary?.event_metadata?.external?.sentiment?.indicator
                    }
                    tooltip={
                      summary?.event_metadata?.external?.sentiment?.reason
                    }
                  />
                }
              >
                <Chip chipName={summary?.customerName} isExternal={false} />
                <Summary summary={summary?.event_metadata?.internal} />
                <Tags summary={summary?.event_metadata?.internal} />
                <Participants summary={summary?.event_metadata?.internal} />
              </Timeline.Item>
            )
            if (
              summary?.event_metadata?.external &&
              summary?.event_metadata?.internal
            ) {
              return [external, internal]
            }

            if (summary?.event_metadata?.external) {
              return external
            }

            if (summary?.event_metadata?.internal) {
              return internal
            }

            return null
          })}
        </Timeline>
      </Wrapper>
    )
  }
}
