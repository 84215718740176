import { Popover } from 'antd'
import { isEmpty, truncate } from 'lodash'
import { CaretDown } from 'phosphor-react'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../context'
import WorkspaceIcon from '../images/icons/workspace-icon.svg'
import { useGlobalStorePersist } from '../store/globalStorePersist'
import { emptyList } from '../utils/empty'

const Wrapper = styled.div<{ $isOpen: boolean }>`
  gap: 4px;
  display: flex;
  cursor: pointer;
  border-radius: 6px;
  padding: 2px 5px;
  align-items: center;
  border: 1px solid var(--color-gray-3);
  .caret-down-icon {
    rotate: ${({ $isOpen }) => ($isOpen ? '180deg' : '0deg')};
    transition: all 0.3s ease-in-out;
  }
`

const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 4px;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-purple-1);
  background: var(--color-blue-6);
`

type Props = {
  isFixed?: boolean
}

const WorkspaceSelector = ({ isFixed }: Props) => {
  const { user } = useAuth()
  const installations = user?.installations || emptyList
  const isOrg = !isEmpty(user?.organization)

  const list = useMemo(() => {
    return installations.map((installation) => {
      return {
        name: installation.team.name,
        value: installation._id,
      }
    })
  }, [installations])

  const [open, setOpen] = useState(false)
  const dispatch = useGlobalStorePersist((state) => state.dispatch)
  const activeWorkspaceId = useGlobalStorePersist(
    (state) => state.activeWorkspaceId
  )

  if (!isOrg) {
    return null
  }

  const selectedWorkspace = list.find(
    (workspace) => workspace.value === activeWorkspaceId
  )

  const handleWorkspaceClick = (value: string) => {
    if (activeWorkspaceId === value) {
      setOpen(false)
      return
    }
    dispatch({
      type: 'SET_ACTIVE_WORKSPACE_ID',
      payload: {
        activeWorkspaceId: value,
      },
    })
    setTimeout(() => {
      window.location.reload()
    }, 0)
  }

  const renderList = () => {
    return list.map((workspace) => {
      return (
        <div
          key={workspace.value}
          onClick={(e) => {
            e.stopPropagation()
            handleWorkspaceClick(workspace.value)
          }}
          className="cursor-pointer hover:bg-gray-100 rounded-md px-[8px] py-[2px]"
          style={{
            fontSize: '12px',
          }}
        >
          {truncate(workspace.name, {
            length: 20,
          })}
        </div>
      )
    })
  }

  return (
    <Popover
      trigger="click"
      showArrow={false}
      content={renderList}
      placement="bottomRight"
      open={isFixed ? false : open}
      overlayInnerStyle={{ padding: 0 }}
      overlayStyle={{ paddingTop: '4px' }}
      onOpenChange={isFixed ? undefined : setOpen}
      overlayClassName="workspace-selector-overlayClassName"
    >
      <Wrapper $isOpen={open} onClick={(e) => e.stopPropagation()}>
        <img src={WorkspaceIcon} alt="" />
        <span
          style={{
            fontSize: '12px',
          }}
        >
          Workspace:
        </span>
        {selectedWorkspace?.name ? (
          <Tag>
            {truncate(selectedWorkspace.name, {
              length: 20,
            })}
          </Tag>
        ) : (
          <span
            style={{
              fontSize: '12px',
              color: 'var(--color-gray-5)',
            }}
          >
            Select a workspace
          </span>
        )}
        <CaretDown
          size={14}
          color="var(--color-gray-5)"
          className="caret-down-icon"
        />
      </Wrapper>
    </Popover>
  )
}

export default WorkspaceSelector
