import { Button, Popover } from 'antd'
import emojione from 'emojione'
import { FocusEvent } from 'react'
import { Editable } from '../Commons'
import { formatSlackMarkdown } from '../utils'

type IProps = {
  index: number
  elements?: any[]
  toggleModal: () => void
  onPopoverClick: () => void
  onBlur: (e: FocusEvent<HTMLDivElement, Element>) => void
  onFocus: (e: FocusEvent<HTMLDivElement, Element>) => void
  selectedText?: string
  isReadOnly?: boolean
}

const THENA_COPY = 'Powered by <http://Thena.ai|Thena.ai>'

const BlockContext = ({
  index,
  onBlur,
  onFocus,
  elements,
  toggleModal,
  onPopoverClick,
  selectedText,
  isReadOnly,
}: IProps) => {
  const renderByType = (e: any, innerIndex: number) => {
    const { type: blockType } = e || {}

    switch (blockType) {
      case 'image': {
        return isReadOnly ? (
          <img
            className="h-5 w-5 mr-1"
            src={e.image_url}
            alt={e.alt_text}
            key={innerIndex}
          />
        ) : (
          <Popover
            placement="right"
            content={
              <div className="flex justify-end w-full">
                <Button
                  type="link"
                  className="font-medium"
                  onClick={onPopoverClick}
                >
                  Edit
                </Button>
              </div>
            }
            title={null}
          >
            <img
              className="h-5 w-5 mr-1"
              src={e.image_url}
              alt={e.alt_text}
              key={innerIndex}
            />
          </Popover>
        )
      }

      case 'plain_text':
        return (
          <Editable
            key={index}
            index={index}
            className="slack_context"
            htmlString={formatSlackMarkdown(
              typeof e?.text === 'string'
                ? emojione.shortnameToUnicode(e?.text)
                : ''
            )}
            onBlur={onBlur}
            onFocus={onFocus}
            toggleModal={toggleModal}
            type="context"
            selectedText={selectedText}
            isReadOnly={isReadOnly}
          />
        )

      case 'mrkdwn':
        return (
          <Editable
            key={index}
            index={index}
            disabled={e.text === THENA_COPY}
            className="slack_context_mrkdwn"
            htmlString={formatSlackMarkdown(
              typeof e?.text === 'string'
                ? emojione.shortnameToUnicode(e?.text)
                : ''
            )}
            onBlur={onBlur}
            onFocus={onFocus}
            toggleModal={toggleModal}
            type="context"
            isReadOnly={isReadOnly}
          />
        )

      default:
        return null
    }
  }

  return (
    <div className="flex">
      {Array.isArray(elements) &&
        (elements || []).map((e: any, index: number) => renderByType(e, index))}
    </div>
  )
}

export default BlockContext
