import { cloneDeep } from 'lodash'
import { useCallback } from 'react'
import { useAuth } from '../context'
import { useMaEditorStore } from '../store/maEditor'

const useMAEditorActions = () => {
  const { user } = useAuth()
  const state = useMaEditorStore((state) => state.initialState)
  const dispatch = useMaEditorStore((state) => state.dispatch)

  const setShowInput = (bool: boolean) => {
    dispatch({
      type: 'SET_SHOW_INPUT',
      payload: {
        showInput: bool,
      },
    })
  }

  const setShowAppInput = (bool: boolean) => {
    dispatch({
      type: 'SET_SHOW_APP_INPUT',
      payload: {
        showAppInput: bool,
      },
    })
  }

  const handleAddBlock = useCallback(
    (index: number, block: any) => {
      const updatedBlocks = cloneDeep(state.blocksJSON.blocks)
      updatedBlocks.splice(index, 0, block)

      try {
        dispatch({
          type: 'SET_BLOCK_JSON',
          payload: {
            blocksJSON: {
              ...state.blocksJSON,
              blocks: updatedBlocks,
            },
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, 'Invalid JSON syntax')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.blocksJSON]
  )

  const handleDeleteBlock = useCallback(
    (index: number) => {
      const updatedBlocks = cloneDeep(state.blocksJSON.blocks)
      updatedBlocks.splice(index, 1)

      try {
        dispatch({
          type: 'SET_BLOCK_JSON',
          payload: {
            blocksJSON: {
              ...state.blocksJSON,
              blocks: updatedBlocks,
            },
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, 'Invalid JSON syntax')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.blocksJSON]
  )

  const handleJsonChangeOnDrag = useCallback(
    (updatedBlocks: any[]) => {
      try {
        dispatch({
          type: 'SET_BLOCK_JSON',
          payload: {
            blocksJSON: {
              ...state.blocksJSON,
              blocks: updatedBlocks,
            },
          },
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, 'Invalid JSON syntax')
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.blocksJSON]
  )

  const handleUpdateBlock = useCallback(
    (index: number, text: any, type: string) => {
      const updatedBlocks = cloneDeep(state.blocksJSON.blocks)
      if (
        index >= 0 &&
        index < state.blocksJSON.blocks.length &&
        state.blocksJSON.blocks[index].type === type
      ) {
        const blockToUpdate = updatedBlocks[index]
        if (type === 'section') {
          updatedBlocks[index] = {
            ...blockToUpdate,
            text: {
              type: 'mrkdwn',
              text: text,
            },
          }
        }
        if (type === 'context') {
          updatedBlocks[index] = {
            ...blockToUpdate,
            elements: [
              {
                type: 'mrkdwn',
                text: text,
              },
            ],
          }
        }
        try {
          dispatch({
            type: 'SET_BLOCK_JSON',
            payload: {
              blocksJSON: {
                ...state.blocksJSON,
                blocks: updatedBlocks,
              },
            },
          })
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error, 'Invalid JSON syntax')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.blocksJSON]
  )
  return {
    state,
    setShowInput,
    handleAddBlock,
    setShowAppInput,
    handleDeleteBlock,
    handleJsonChangeOnDrag,
    handleUpdateBlock,
  }
}

export default useMAEditorActions
