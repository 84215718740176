import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  RightOutlined,
} from '@ant-design/icons'
import type { TableColumnsType } from 'antd'
import { Col, Dropdown, Progress, Row, Table } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as LoadingSVG } from '../../../../images/icons/loading_status.svg'
import VThreeDots from '../../../../images/icons/three-dots-vertical.svg'
import { SourceType } from '../../../../types'
import { EditSourceModal } from '../Modals/EditSourceModal'
import { RemoveSourceModal } from '../Modals/RemoveSourceModal'
import { SourceExpanded } from './SourceExpanded'
import './SourceTable.css'

export const SourceList = ({ sourceData }: { sourceData: SourceType[] }) => {
  const [removeSourceModal, setRemoveSourceModal] = useState(false)
  const [currentData, setCurrentData] = useState<SourceType | undefined>(
    undefined
  )
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [isMenuOpen, setMenuOpen] = useState<Record<string, boolean>>({})

  const editSource = (source: SourceType) => {
    setCurrentData(source)
    setMenuOpen((prevState) => ({ ...prevState, [source._id]: false }))
  }

  const incompleteData = sourceData.find((data) => data.syncStatus !== '100%')
  const analysisStatus = incompleteData
    ? parseInt(incompleteData.syncStatus.replace('%', ''), 10)
    : undefined

  const removeSource = (sourceId: string) => {
    setMenuOpen((prevState) => ({ ...prevState, [sourceId]: false }))
    setRemoveSourceModal(true)
  }
  const getMenuItems = (source: SourceType) => [
    {
      key: 'edit',
      label: (
        <Row
          className="operation-icon"
          justify="space-between"
          align="middle"
          onClick={(event) => {
            event.stopPropagation()
            editSource(source)
          }}
        >
          <span>Edit Source</span>
          <EditOutlined />
        </Row>
      ),
    },
    {
      key: 'delete',
      label: (
        <Row
          className="operation-icon"
          justify="space-between"
          align="middle"
          onClick={(event) => {
            event.stopPropagation()
            removeSource(source._id)
          }}
        >
          <span>Delete Source</span>
          <DeleteOutlined />
        </Row>
      ),
    },
  ]

  const renderSource = (sourceType: string, record: SourceType) => {
    const content = sourceType === 'web_link' ? record.link : record.fileName
    return (
      <>
        <span style={{ fontWeight: 'bold' }}>Source: </span>
        <span>{content}</span>
      </>
    )
  }

  const columns: TableColumnsType<SourceType> = [
    {
      title: 'Name',
      dataIndex: 'sourceName',
      render: (title: string) => (
        <>
          <span style={{ fontWeight: 'bold' }}>Name: </span>
          <span>{title}</span>
        </>
      ),
      width: '15%',
    },
    {
      title: 'Type',
      dataIndex: 'sourceType',
      render: (title: string) => (
        <>
          <span style={{ fontWeight: 'bold' }}>Type: </span>
          <span>{title === 'web_link' ? 'Link' : 'Document'}</span>
        </>
      ),
      width: '15%',
    },
    {
      title: 'Source',
      dataIndex: 'sourceType',
      render: (sourceType: string, record: object) =>
        renderSource(sourceType, record as SourceType),
      width: '60%',
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Action',
      dataIndex: 'operation',
      render: (text, record) => (
        <Dropdown
          menu={{ items: getMenuItems(record) }}
          trigger={['click']}
          open={isMenuOpen[record._id]}
          onOpenChange={(open: boolean) => toggleDropdown(record._id, open)}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <img
              src={VThreeDots}
              alt="operation"
              style={{ cursor: 'pointer' }}
            />
          </div>
        </Dropdown>
      ),
      width: '24px',
    },
  ]

  const renderExpandedRow = (record: SourceType) => {
    return (
      record.sourceType === 'web_link' && <SourceExpanded source={record} />
    )
  }

  const isRowExpandable = (record: SourceType) => {
    return (
      record.sourceType === 'web_link' &&
      !!record.resources &&
      record.resources.length > 0
    )
  }

  const renderExpandIcon = ({
    expanded,
    onExpand,
    record,
  }: {
    expanded: boolean
    onExpand: (record: SourceType, e: React.MouseEvent<HTMLElement>) => void
    record: SourceType
  }) => {
    if (!record.resources || record.resources.length === 0) {
      return null
    }

    return (
      <span className="expand-icon" onClick={(e) => onExpand(record, e)}>
        {record.resources.length} Sub-Pages{' '}
        {expanded ? (
          <DownOutlined
            style={{ fontSize: '12px', color: 'var(--color-gray-4)' }}
          />
        ) : (
          <RightOutlined
            style={{ fontSize: '12px', color: 'var(--color-gray-4)' }}
          />
        )}
      </span>
    )
  }

  const handleRowClick = (record: SourceType) => {
    const keys = []
    if (!expandedRowKeys.includes(record._id)) {
      keys.push(record._id)
    }
    setExpandedRowKeys(keys)
  }

  const toggleDropdown = (sourceId: string, isOpen: boolean) => {
    setMenuOpen((prevState) => ({
      ...prevState,
      [sourceId]: isOpen,
    }))
  }

  return (
    <Wrapper>
      <Table
        dataSource={sourceData}
        className="custom-table"
        rowKey="_id"
        columns={columns}
        pagination={false}
        showHeader={false}
        onRow={(record) => ({
          onClick: () => {
            handleRowClick(record)
          },
        })}
        expandable={{
          expandedRowRender: renderExpandedRow,
          rowExpandable: isRowExpandable,
          expandIcon: renderExpandIcon,
          expandedRowKeys: expandedRowKeys,
        }}
      />
      {analysisStatus && (
        <Row className="progress-bar" justify="space-between">
          <Row justify="start">
            <LoadingSVG />
            <div className="progress-desc">
              We are analysing your sources, this may take up to 5 minutes
            </div>
          </Row>
          <Row gutter={12}>
            <Col>Processing</Col>
            <Col>
              <Progress
                percent={analysisStatus}
                style={{ width: '306px' }}
                strokeColor={'var(--color-purple-1)'}
              />
            </Col>
          </Row>
        </Row>
      )}
      <EditSourceModal
        isOpen={!!currentData}
        onCancel={() => setCurrentData(undefined)}
        currentSource={currentData}
      />
      <RemoveSourceModal
        isOpen={removeSourceModal}
        onCancel={() => setRemoveSourceModal(false)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-table-row:hover {
    cursor: pointer;
  }
  .operation-icon {
    padding: 4px;
    gap: 8px;
  }
  .expand-icon {
    display: inline-block;
    cursor: pointer;
    font-size: 12px;
    color: var(--color-gray-4);
    white-space: nowrap;
  }
  .progress-bar {
    padding: 16px 20px;
    margin-top: 24px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-3);
    background-color: var(--color-purple-3);

    .progress-desc {
      margin-left: 8px;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black-1);
    }
  }
`
