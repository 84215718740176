import { Divider, Input, Typography } from 'antd'
import { IntegrationModalHeader } from '../../../components'
import NotionSvg from '../../../images/logos/notion.svg'

const { Text } = Typography
export const NotionIntegration = () => {
  return (
    <main className="w-full">
      <IntegrationModalHeader
        imgSrc={NotionSvg}
        imageClassName="w-[42px] h-[42px]"
        title="Notion Integration"
        description="Connect your Notion Workspace to Thena"
      />
      <Divider className="my-5 py-0" />
      <Text>API Key</Text>
      <Input placeholder="Enter API Key here ..." />
    </main>
  )
}
