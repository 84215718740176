import styled from 'styled-components'

const Span = styled.span`
  border-radius: 50%;
  display: inline-block;
  border-top: 0 solid var(--color-purple-1);
  border-right: 0 solid transparent;
  animation: rotation 0.54s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
type Props = {
  size?: 'small' | 'medium' | 'large' | 'custom'
  className?: string
  w?: number
  bw?: number
}

const FastSpinner = ({ size = 'medium', className, w, bw }: Props) => {
  let width = 48
  let borderWidth = 3

  switch (size) {
    case 'small':
      width = 20
      borderWidth = 2
      break

    case 'medium':
      width = 48
      borderWidth = 3
      break

    case 'large':
      width = 56
      borderWidth = 4
      break

    case 'custom':
      width = w || 48
      borderWidth = bw || 3
      break
  }

  return (
    <Span
      style={{
        width,
        borderWidth,
        height: width,
      }}
      className={className}
    />
  )
}

export default FastSpinner
