import { Checkbox, Input, Popover } from 'antd'
import { CheckboxOptionType, CheckboxValueType } from 'antd/lib/checkbox/Group'
import { MagnifyingGlass } from 'phosphor-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { noOp } from '../../../../utils/empty'
import { truncate } from '../../../../utils/textUtils'
import { ClearIcon, FilterChip } from './Common'

type IProps = {
  label: string
  queryKey: string
  itemList: CheckboxOptionType[]
  iconAlt: string
  iconSrc: string
  className?: string
}

const MultipleSelect = ({
  label,
  queryKey,
  iconAlt,
  iconSrc,
  itemList,
  className,
}: IProps) => {
  const navigate = useNavigate()
  const [checkedValue, setCheckedValue] = useState<CheckboxValueType[]>([])
  const [isOpen, setOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const handleChange = (checkedValues: CheckboxValueType[]) => {
    let newValue: CheckboxValueType[] = []

    if (checkedValues.length > 0) {
      const latestValue = checkedValues.find(
        (value) => !checkedValue.includes(value)
      )
      if (latestValue !== undefined) {
        newValue = [latestValue]
      } else if (checkedValues.length === 1) {
        newValue = checkedValues
      }
    }
    setOpen(false)
    setCheckedValue(newValue)

    const baseUrl = window.location.pathname
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete(queryKey)
    if (newValue.length > 0) {
      searchParams.append(queryKey, encodeURIComponent(newValue[0] as string))
    }

    const newUrl = `${baseUrl}?${searchParams.toString()}`
    navigate(newUrl, { replace: true })
  }

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }

  const filteredList: CheckboxOptionType[] = useMemo(() => {
    return itemList.filter((it: any) =>
      it.label?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
  }, [itemList, searchQuery])

  const content = (
    <div>
      {label !== 'Status' && (
        <Input
          autoFocus
          onChange={onSearch}
          placeholder="Search..."
          prefix={<MagnifyingGlass color="#707070" />}
        />
      )}
      <Checkbox.Group
        options={filteredList}
        value={checkedValue}
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          maxHeight: '300px',
          margin: 8,
          gap: 8,
        }}
        onChange={handleChange}
      />
    </div>
  )

  const selectedItem = filteredList
    .find((item) => item.value === checkedValue[0])
    ?.label?.toString()

  const ChipContent = !!selectedItem && (
    <>
      <ChipContentWrapper>
        <Chip>{truncate(selectedItem, 15)}</Chip>
      </ChipContentWrapper>
      <ClearIcon
        onClick={(e) => {
          e.stopPropagation()
          setCheckedValue([])
          setOpen(false)
          handleChange([])
        }}
      />
    </>
  )

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }
  return (
    <Wrapper className={className}>
      <Popover
        trigger="click"
        showArrow={false}
        placement="bottomLeft"
        content={content}
        open={isOpen}
        onOpenChange={handleOpenChange}
      >
        <FilterChip
          label={label}
          onClick={noOp}
          iconAlt={iconAlt}
          iconSrc={iconSrc}
          content={ChipContent}
        />
      </Popover>
    </Wrapper>
  )
}

export default MultipleSelect

const Wrapper = styled.div``

const ChipContentWrapper = styled.div`
  display: flex;
  margin-left: 4px;
  position: relative;
  align-items: center;
`

const Chip = styled.div`
  flex-shrink: 0;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 8px;
  margin-right: 4px;
  border-radius: 6px;
  color: var(--color-white);
  background-color: var(--color-purple-1);
`
