import { Tooltip } from 'antd'
import styled from 'styled-components'

const Wrapper = styled.span<{
  $sentiment: string
  $static: boolean | undefined
}>`
  .beacon {
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #db2828;
    height: 0.6em;
    width: 0.6em;
    border-radius: 50%;
    -webkit-animation: grow 0.4s 1 linear;
    animation: grow 0.4s 1 linear;
    -webkit-transform: translateX(-50%) translateY(-50%);
    ${({ $sentiment }) => {
      if ($sentiment === 'red') {
        return `
        background-color: #db2828;
        `
      }
      if ($sentiment === 'yellow') {
        return `
        background-color: #fbd127;
        `
      }
      if ($sentiment === 'gray') {
        return `
        background-color: #828188;
        `
      }
      return `
      background-color: #269f7c;
      `
    }}
  }
  .beacon:before {
    position: absolute;
    content: '';
    height: 0.6em;
    width: 0.6em;
    left: 0;
    top: 0;
    background-color: transparent;
    border-radius: 50%;
    ${({ $static }) => {
      if (!$static) {
        return `
        -webkit-animation: active 2s infinite linear;
        animation: active 2s infinite linear;
        `
      }
    }}
    ${({ $sentiment }) => {
      if ($sentiment === 'red') {
        return `
        box-shadow: 0px 0px 2px 2px #db2828;
        `
      }
      if ($sentiment === 'yellow') {
        return `
        box-shadow: 0px 0px 2px 2px #fbd127;
        `
      }
      if ($sentiment === 'gray') {
        return `
        background-color: #828188;
        `
      }
      return `
      box-shadow: 0px 0px 2px 2px #269f7c;
      `
    }}
  }

  @-webkit-keyframes grow {
    0% {
      -webkit-transform: scale(0.1);
    }

    100% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes grow {
    0% {
      transform: scale(0.1);
    }

    100% {
      transform: scale(1);
    }
  }

  @-webkit-keyframes active {
    0% {
      -webkit-transform: scale(0.1);
      opacity: 1;
    }
    70% {
      -webkit-transform: scale(2.5);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes active {
    0% {
      transform: scale(0.1);
      opacity: 1;
    }
    70% {
      transform: scale(2.5);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`

export const Beacon = ({
  sentiment,
  tooltip,
  staticMode = false,
}: {
  sentiment: string
  tooltip?: string
  staticMode?: boolean
}) => {
  return (
    <Wrapper $sentiment={sentiment?.toLowerCase()} $static={staticMode}>
      <Tooltip title={tooltip}>
        <span className="beacon"></span>
      </Tooltip>
    </Wrapper>
  )
}
