import { Checkbox, Input, Popover } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { MagnifyingGlass, Plus } from 'phosphor-react'
import { useState } from 'react'
import styled from 'styled-components'
import { useAccountsStore } from '../../../store/accountsStore'
const Wrapper = styled.div`
  height: 28px;
  right: 155px;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  border-radius: 6px;
  position: absolute;
  align-items: center;
  padding: 1px 8px 0 6px;
  color: var(--color-gray-6);
  border: none;
  :hover,
  :active {
    border-radius: 4px;
    color: var(--color-gray-6);
    background-color: var(--color-gray-3);
  }
  :focus {
    color: var(--color-gray-6);
  }
  ::after {
    display: none;
  }
  .cross-icon {
    margin-right: 4px;
  }
`
export const AddFilter = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const dynamicFilters = useAccountsStore((state) => state.dynamicFilters)
  const crmConfig = useAccountsStore((state) => state.crmConfig)
  const dispatch = useAccountsStore((state) => state.dispatch)

  const setAllFilter = (list: string[]) => {
    dispatch({
      type: 'SET_ALL_FILTER',
      payload: {
        dynamicFilters: list,
      },
    })
  }

  const onSearch = (e: any) => {
    setSearchQuery(e.target.value)
  }
  const onChange = (e: CheckboxChangeEvent, value: string) => {
    const {
      target: { checked },
    } = e
    if (checked) {
      return setAllFilter([...dynamicFilters, value])
    }
    setAllFilter(dynamicFilters.filter((it) => it !== value))
  }

  const content = (
    <div>
      <Input
        autoFocus
        placeholder="Search"
        onChange={onSearch}
        prefix={<MagnifyingGlass color="#707070" />}
        className="input"
      />
      <div style={{ height: '200px', overflowY: 'scroll' }}>
        {crmConfig?.additional_fields
          ?.filter(
            (it, i) =>
              i > 5 &&
              it?.field_name?.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((tag, index) => (
            <div
              style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
              key={index}
            >
              <Checkbox
                name={tag.field_name}
                checked={dynamicFilters.includes(tag._id)}
                onChange={(e) => onChange(e, tag._id)}
              >
                {tag.field_name}
              </Checkbox>
            </div>
          ))}
      </div>
    </div>
  )
  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottomLeft"
      showArrow={false}
    >
      <Wrapper>
        <Plus
          size={12}
          weight="bold"
          className="cross-icon"
          color="var(--color-gray-6)"
        />
        Add filters
      </Wrapper>
    </Popover>
  )
}
