import { ExclamationOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Timeline, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import ActionsEmpty from '../../../images/icons/empty-actions.svg'
import { SummaryDetail } from '../../../types/accountsTypes'
import { Beacon } from './Beacon'
import { EmptyState } from './EmptyState'

const Wrapper = styled.div`
  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    text-align: left;
    margin-left: 12px;
    width: auto;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
    left: 15%;
  }
  .ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content {
    left: 15%;
    width: calc(80% - 36px);
  }
`

const ChipWrapper = styled.div`
  width: fit-content;
  height: 25px;
  padding: 1px 8px 4px 8px;
  border-radius: 20px;
  border: 1px solid var(--color-gray-3);
  background-color: var(--color-gray-bg);
`

const TitleWrapper = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 18px;
`

const Chip = ({
  chipName,
  isExternal,
}: {
  chipName: string
  isExternal: boolean
}) => {
  return (
    <ChipWrapper>
      <div className="flex items-center gap-1 justify-start">
        <span className="font-semibold text-xs">{chipName}</span>
        <p className="mb-0 text-[var(--color-gray-4)]">•</p>
        <span className="text-xs text-[var(--color-gray-4)]">
          {isExternal ? 'External Summary' : 'Internal Summary'}
        </span>
      </div>
    </ChipWrapper>
  )
}

const ActionItems = ({
  actionItems,
}: {
  actionItems: {
    date: string
    item: string
  }[]
}) => {
  return (
    <>
      <TitleWrapper>Action items</TitleWrapper>
      {actionItems?.map((item) => (
        <div className="flex items-center mt-2">
          <Avatar
            icon={<UserOutlined />}
            className="absolute left-[-36px] bg-[#828188]"
            size={20}
          />
          <div className="flex items-start justify-between text-xs w-full border border-solid border-[#E5E5EA] pt-[5px] pr-[8px] pb-[5px] pl-[8px] rounded-md">
            <div className="w-[85%]">{item?.item}</div>
            <div>{item?.date}</div>
          </div>
        </div>
      ))}
    </>
  )
}

const ImportantDates = ({
  importantDates,
}: {
  importantDates: {
    date: string
    topic: string
  }[]
}) => {
  return (
    <>
      <TitleWrapper>Important Dates</TitleWrapper>
      {importantDates?.map((item) => (
        <div className="flex items-center mt-2">
          <Avatar
            icon={<ExclamationOutlined />}
            className="absolute left-[-36px] bg-[#828188]"
            size={20}
          />
          <div className="flex items-start justify-between text-xs w-full border border-solid border-[#E5E5EA] pt-[5px] pr-[8px] pb-[5px] pl-[8px] rounded-md">
            <div className="w-[85%]">{item?.topic}</div>
            <div>{item?.date}</div>
          </div>
        </div>
      ))}
    </>
  )
}

export const Actions = ({
  summaryDetails,
}: {
  summaryDetails: SummaryDetail[]
}) => {
  const areLinksEmpty = (detail: any) => {
    return (
      isEmpty(detail?.event_metadata?.external?.action_items) &&
      isEmpty(detail?.event_metadata?.internal?.action_items) &&
      isEmpty(detail?.event_metadata?.external?.important_dates) &&
      isEmpty(detail?.event_metadata?.internal?.important_dates)
    )
  }
  const allLinksEmpty = summaryDetails.every(areLinksEmpty)

  if (allLinksEmpty) {
    return (
      <EmptyState
        title="No action items or important dates yet!"
        description="Our AI will highlight these for you from your conversations. Once you've interacted with this customer, any identified action items or key dates will be flagged here."
        footer="Learn how Action Items & Dates work in our"
        helpText="Help Document"
        image={ActionsEmpty}
      />
    )
  } else {
    return (
      <Wrapper className="mt-2">
        <Timeline
          mode="left"
          pendingDot={<Beacon sentiment="gray" staticMode={true} />}
          pending={!allLinksEmpty}
        >
          {summaryDetails.map((summary) => {
            const external = summary?.event_metadata?.external && (
              <Timeline.Item
                key={`external-${
                  summary.createdAt + summary?.event_metadata?.external?.title
                }`}
                label={
                  <Tooltip title="Summary generated">
                    <span className="text-[#6F6F75] text-xs">
                      {moment(summary.createdAt).format('L')}
                    </span>
                  </Tooltip>
                }
                dot={<Beacon sentiment="gray" staticMode={true} />}
              >
                <Chip chipName={summary?.customerName} isExternal={true} />
                {!isEmpty(summary?.event_metadata?.external?.action_items) && (
                  <ActionItems
                    actionItems={
                      summary?.event_metadata?.external?.action_items
                    }
                  />
                )}
                {!isEmpty(
                  summary?.event_metadata?.external?.important_dates
                ) && (
                  <ImportantDates
                    importantDates={
                      summary?.event_metadata?.external?.important_dates
                    }
                  />
                )}
              </Timeline.Item>
            )

            const internal = summary?.event_metadata?.internal && (
              <Timeline.Item
                key={`internal-${
                  summary.createdAt + summary?.event_metadata?.internal?.title
                }`}
                label={
                  <Tooltip title="Summary generated">
                    <span className="text-[#6F6F75] text-xs">
                      {moment(summary.createdAt).format('L')}
                    </span>
                  </Tooltip>
                }
                dot={<Beacon sentiment="gray" staticMode={true} />}
              >
                <Chip chipName={summary?.customerName} isExternal={false} />
                {!isEmpty(summary?.event_metadata?.internal?.action_items) && (
                  <ActionItems
                    actionItems={
                      summary?.event_metadata?.internal?.action_items
                    }
                  />
                )}
                {!isEmpty(
                  summary?.event_metadata?.internal?.important_dates
                ) && (
                  <ImportantDates
                    importantDates={
                      summary?.event_metadata?.internal?.important_dates
                    }
                  />
                )}
              </Timeline.Item>
            )

            if (
              summary?.event_metadata?.external &&
              summary?.event_metadata?.internal
            ) {
              return [external, internal]
            }

            if (summary?.event_metadata?.external) {
              return external
            }

            if (summary?.event_metadata?.internal) {
              return internal
            }

            return null
          })}
        </Timeline>
      </Wrapper>
    )
  }
}
