import { PlusOutlined } from '@ant-design/icons'
import { Button, Table, message } from 'antd'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { fetchAssignee } from '../../../../api/assignee'
import useClusters from '../../../../hooks/useClusters'
import StatusIcon from '../../../../images/icons/cluster_status.svg'
import { ClusterSMEType, ClusterType } from '../../../../types'
import { NoList } from '../NoList'
import { CustomRow, Spinner } from '../StyledComponent'
import { renderClusterName } from './renderClusterName'
import { renderOperations } from './renderOperation'
import { renderSMESelect } from './renderSMESelect'

export const ClusterList = () => {
  const [editingRecord, setEditingRecord] = useState<ClusterType | null>(null)
  const { data: assignees } = useQuery('assignee', fetchAssignee)
  const { id = '' } = useParams()
  const onCreateSuccess = () => {
    setEditingRecord(null)
    message.success('Successfully created!')
  }
  const onUpdateSuccess = () => {
    setEditingRecord(null)
    message.success('Successfully updated!')
  }
  const onCreateError = () => {
    message.error('Something went wrong!')
  }
  const {
    createCluster,
    updateCluster,
    createStatus,
    updateStatus,
    data = [],
    isLoading,
  } = useClusters({
    onCreateSuccess,
    onCreateError,
    onUpdateSuccess,
    assistantId: id,
  })
  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editingRecord) {
      setEditingRecord({
        ...editingRecord,
        name: event.target.value,
      })
    }
  }
  const saveChanges = () => {
    if (!editingRecord || !editingRecord.sme) {
      return
    }
    if (editingRecord.name === '') {
      message.error('Please input a cluster name!')
      return
    }
    if (!editingRecord.userId && editingRecord.sme.userId === '') {
      message.error('You should select a Subject Matter Expert!')
      return
    }
    const newItemData = {
      name: editingRecord.name,
      sme: editingRecord.userId
        ? editingRecord.userId
        : editingRecord.sme.userId,
    }
    if (editingRecord._id === '') {
      createCluster(newItemData)
    } else {
      updateCluster(editingRecord._id, newItemData)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (value: any) => {
    if (editingRecord) {
      setEditingRecord({
        ...editingRecord,
        userId: value,
      })
    }
    return value
  }

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      render: () => <img src={StatusIcon} alt="status" />,
      width: '22px',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (value: string, record: object) =>
        renderClusterName({
          value: value,
          record: record as ClusterType,
          editingRecord: editingRecord as ClusterType,
          isCreateLoading: createStatus.isLoading,
          isUpdateLoading: updateStatus.isLoading,
          onChange: handleInputValue,
        }),
    },
    {
      title: 'Questions',
      dataIndex: 'questionCount',
      render: (value: number) => (
        <div className="question-count">{value} Questions</div>
      ),
    },
    {
      title: 'Display Name',
      dataIndex: 'sme',
      render: (sme: ClusterSMEType, record: object) =>
        renderSMESelect({
          sme: sme,
          record: record as ClusterType,
          editingRecord: editingRecord as ClusterType,
          isCreateLoading: createStatus.isLoading,
          isUpdateLoading: updateStatus.isLoading,
          onChange: handleSelectChange,
          assignees: assignees,
        }),
      width: '224px',
    },
    {
      title: 'Operations',
      dataIndex: 'operations',
      render: (value: string, record: object) =>
        renderOperations({
          editingRecord: editingRecord as ClusterType,
          record: record as ClusterType,
          isCreateLoading: createStatus.isLoading,
          isUpdateLoading: updateStatus.isLoading,
          onEdit: () => setEditingRecord(record as ClusterType),
          onSave: saveChanges,
          onCancel: () => setEditingRecord(null),
        }),
      width: '72px',
    },
  ]
  const addCluster = () => {
    setEditingRecord({
      _id: '',
      name: '',
      questionCount: 0,
      status: false,
      sme: {
        displayName: '',
        image: '',
        slackId: '',
        userId: '',
      },
      userId: '',
    })
  }
  if (isLoading) {
    return <Spinner />
  }
  if (data.length < 1) {
    return <NoList data={'clusters'} />
  }
  return (
    <Wrapper>
      <Table
        dataSource={[
          ...data,
          ...(editingRecord && editingRecord._id === '' ? [editingRecord] : []),
        ]}
        rowKey="_id"
        columns={columns}
        pagination={false}
        showHeader={false}
        className="border-solid rounded-lg border-[1px] border-[#EEEFF2]"
        size="middle"
        style={{ width: '720px' }}
        components={{ body: { row: CustomRow } }}
      />
      <Button
        type="text"
        className="create-btn"
        icon={<PlusOutlined />}
        onClick={addCluster}
        disabled={!!editingRecord}
      >
        Add new cluster
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .question-count {
    font-size: 10px;
    font-weight: 400;
    color: var(--color-gray-6);
  }
  .create-btn {
    padding: 4px 12px 4px 8px;
    margin: 4px 0;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-gray-4);
  }
`
