import { api } from '@api/index'
import type { ColumnsType } from 'antd/es/table'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import { Table } from 'antd'
import { useEffect, useState } from 'react'

const fetchLogs = async (id: string): Promise<any> => {
  const { data } = await api.get(
    `v2/god-mode/organizations/${id}/impersonation/logs`
  )
  return data
}

interface DataType {
  key: string
  host: string
  ipAddress: string
  userAgent: string
  createdAt: string
  user: string
  user_email: string
  actor_name: string
  actor_email: string
  organization_name: string
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Host',
    dataIndex: 'host',
    key: 'host',
  },
  {
    title: 'IP Address',
    dataIndex: 'ipAddress',
    key: 'ipAddress',
  },
  {
    title: 'User Agent',
    dataIndex: 'userAgent',
    key: 'userAgent',
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    // render: (text: any) => new Date(text).toLocaleString(), // Format date
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: 'User Email',
    dataIndex: 'user_email',
    key: 'user_email',
  },
  {
    title: 'Actor Name',
    dataIndex: 'actor_name',
    key: 'actor_name',
  },
  {
    title: 'Actor Email',
    dataIndex: 'actor_email',
    key: 'actor_email',
  },
  {
    title: 'Organization Name',
    dataIndex: 'organization_name',
    key: 'organization_name',
  },
]

const OrganizationLogs = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id') || ''
  const [data, setData] = useState<DataType[]>([])

  const { isLoading, data: apiData } = useQuery(
    ['fetchLogs', id],
    () => fetchLogs(id),
    {
      enabled: !!id, // Only run the query if id is not null
    }
  )

  useEffect(() => {
    if (apiData) {
      setData(apiData.results)
    }
  }, [apiData])

  return (
    <div>
      <h1 className="text-center p-4">Logs</h1>
      <div className="p-8">
        <Table
          columns={columns}
          pagination={{ position: ['bottomRight'] }}
          dataSource={data}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

export default OrganizationLogs
