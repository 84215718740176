import { Modal, message } from 'antd'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { fetchClusters } from '../../../../api/clusters'
import useQNAs from '../../../../hooks/useQNAs'
import { CustomTag } from '../CustomTag'
import { FormButtonGroup } from '../Forms/components/FormButtonGroup'
import { FormHeader } from '../Forms/components/FormHeader'
import FormInput from '../Forms/components/FormInput'

type CreateAssistantProps = {
  isOpen: boolean
  onClose: () => void
  isSaving?: boolean
}

export const CreateQuestionModal = ({
  onClose,
  isOpen,
}: CreateAssistantProps) => {
  const onCreateSuccess = () => {
    message.success('Successfully created!')
    onClose()
  }
  const onCreateError = () => {
    message.error('Something went wrong!')
  }
  const { id = '' } = useParams()
  const { data: clusters } = useQuery('clusterList', () => fetchClusters(id))

  const [newClusterData, setNewClusterData] = useState({
    question: '',
    answer: '',
    clusterId: '',
  })

  const { createQNA, createStatus } = useQNAs({
    onCreateSuccess,
    onCreateError,
    assistantId: id,
  })
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewClusterData({
      ...newClusterData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSelectChange = (value: string, key: string) => {
    setNewClusterData({
      ...newClusterData,
      [key]: value,
    })
  }

  const canSubmit =
    newClusterData.answer !== '' &&
    newClusterData.question !== '' &&
    newClusterData.clusterId !== ''

  const handleSubmit = () => {
    if (!canSubmit) {
      message.error('Please fill in all the fields!')
      return
    }
    createQNA(newClusterData)
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={onClose} destroyOnClose>
      <FormHeader
        title="Add New Question"
        desc="Add your own question to the Q&A repository"
      />
      <FormInput
        type="text"
        title="Question"
        name="question"
        placeholder="Frame the question"
        onChange={handleInputChange}
      />
      <FormInput
        type="textarea"
        name="answer"
        title="Answer"
        placeholder="Type your answer"
        rows={4}
        onChange={handleInputChange}
      />
      <FormInput
        type="select"
        title="Cluster"
        placeholder="Select cluster"
        options={
          clusters &&
          clusters.map((cluster) => ({
            value: cluster._id,
            label: cluster.name,
          }))
        }
        onChange={(value) => handleSelectChange(value, 'clusterId')}
      />
      <CustomTag
        description="Newly added question will be automatically marked as verified and be
      will be activated"
        color={'var(--color-brown-3)'}
        bgColor={'var(--color-brown-4)'}
      />
      <FormButtonGroup
        okText="Add Question"
        isSaving={createStatus.isLoading}
        onOk={handleSubmit}
        onClose={onClose}
      />
    </Modal>
  )
}
