import axios from 'axios'
import { create } from 'zustand'
import { devtools, redux } from 'zustand/middleware'
import { DEFAULT_FILTERS } from '../pages/Requests/constants'
import { KanbanStore2 } from '../types/kanbanTypes'

const initalState: KanbanStore2 = {
  modal: null,
  allFilters: DEFAULT_FILTERS,
  droppedId: '',
  total: undefined,
  isSampleData: false,
  updateCard: null,
  closeRequest: false,
  saveMandatoryTag: false,
  toggleBoard: Boolean(window.localStorage.getItem('toggleBoard')) || false,
  selectedConversation: null,
  currentScrollingCol: '',
  isScrolling: false,
  messagesLoading: false,
  checkedList: [],
  addFiltersInit: false,
  addCustomFiltersInit: false,
  isBulkUpdating: false,
  cancelToken: axios.CancelToken.source(),
  currentSubStatus: null,
  currentSavedCols: [],
}

type ActionType = {
  type:
    | 'SET_MODAL'
    | 'ADD_FILTER'
    | 'REMOVE_FILTER'
    | 'SET_ALL_FILTERS'
    | 'SET_DROPPED_ID'
    | 'INIT'
    | 'UPDATE_CARD'
    | 'TRY_CLOSE_REQUEST'
    | 'SAVE_MANDATORY_TAG'
    | 'SET_TOGGLE_BOARD'
    | 'ADD_CARD'
    | 'SET_CURRENT_SCROLLING_COL'
    | 'SET_SCROLLING'
    | 'MESSAGE_LOADING'
    | 'SET_CHECKED_LIST'
    | 'SET_ADD_FILTERS_INIT'
    | 'SET_ADD_CUSTOM_FILTERS_INIT'
    | 'SET_BULK_UPDATING'
    | 'SET_CANCEL_TOKEN'
    | 'SET_CURRENT_SUBSTATUS'
    | 'SET_CURRENT_SAVED_REORDER_COLS'
  payload?: any
}

const reducer = (state: KanbanStore2, action: ActionType): KanbanStore2 => {
  const { type, payload } = action

  switch (type) {
    case 'SET_MODAL':
      return {
        ...state,
        modal: payload.modal,
      }

    case 'ADD_FILTER':
      return {
        ...state,
        allFilters: [...state.allFilters, payload.filter],
      }

    case 'REMOVE_FILTER':
      return {
        ...state,
        allFilters: state.allFilters.filter(
          (filter) => filter !== payload.filter
        ),
      }

    case 'SET_ALL_FILTERS':
      return {
        ...state,
        allFilters: payload.allFilters,
      }

    case 'SET_DROPPED_ID':
      return {
        ...state,
        droppedId: payload.droppedId,
      }
    case 'INIT':
      return {
        ...state,
        total: payload.total,
        isSampleData: payload.isSampleData,
      }
    case 'UPDATE_CARD':
      return {
        ...state,
        updateCard: payload.updateCard,
      }
    case 'TRY_CLOSE_REQUEST':
      return {
        ...state,
        closeRequest: payload.closeRequest,
      }
    case 'SAVE_MANDATORY_TAG':
      return {
        ...state,
        saveMandatoryTag: payload.saveMandatoryTag,
      }

    case 'SET_TOGGLE_BOARD':
      return { ...state, toggleBoard: payload.toggleBoard }

    case 'ADD_CARD':
      return { ...state, selectedConversation: payload.selectedConversation }

    case 'SET_CURRENT_SCROLLING_COL':
      return { ...state, currentScrollingCol: payload.currentScrollingCol }

    case 'SET_SCROLLING':
      return { ...state, isScrolling: payload.isScrolling }

    case 'MESSAGE_LOADING':
      return { ...state, messagesLoading: payload?.messageLoading }

    case 'SET_CHECKED_LIST':
      return { ...state, checkedList: payload.checkedList }

    case 'SET_ADD_FILTERS_INIT':
      return { ...state, addFiltersInit: payload.addFiltersInit }

    case 'SET_ADD_CUSTOM_FILTERS_INIT':
      return { ...state, addCustomFiltersInit: payload.addCustomFiltersInit }

    case 'SET_BULK_UPDATING':
      return { ...state, isBulkUpdating: payload.isBulkUpdating }

    case 'SET_CANCEL_TOKEN':
      return { ...state, cancelToken: payload.cancelToken }
    case 'SET_CURRENT_SUBSTATUS': {
      const newState = {
        ...state,
        currentSubStatus: payload.currentSubStatus,
      }
      return newState
    }
    case 'SET_CURRENT_SAVED_REORDER_COLS': {
      return {
        ...state,
        currentSavedCols: payload.currentSavedCols,
      }
    }
    default:
      return state
  }
}

const useKanbanStore2 = create(
  devtools(redux(reducer, initalState), {
    name: 'useKanbanStore2',
  })
)

export { useKanbanStore2 }
