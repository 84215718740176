import { useLiveQuery } from 'dexie-react-hooks'
import { camelCase } from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components'
import { accountDB } from '../../../DB/AccountDB'
import HorizontalList from '../../../components/common/HorizontalList/HorizontalList'
import { useAccountsStore } from '../../../store/accountsStore'
import { RelationshipType } from '../../../types/accountsTypes'
import { emptyList, emptyObject } from '../../../utils/empty'
import useApplyFilter from '../Table/useApplyFilter'
import Card from './Card'

const Wrapper = styled.div`
  height: 94px;
  width: 100%;
  padding: 0px 24px;
  border-bottom: 1px solid var(--color-gray-3);
  display: flex;
  align-items: center;
  .card-wrapper {
    margin-right: 20px;
    justify-content: space-between;
  }
  .card-wrapper:last-child {
    margin-right: 0;
  }
  .scroll-wrapper {
    height: 96px;
    border-radius: 10px;
    .list-shade {
      height: 96px;
    }
  }
`

const Cards = () => {
  const relationships = useLiveQuery(
    async () => {
      const getKeys = await accountDB.relationship.toCollection().primaryKeys()
      const data = await accountDB.relationship.bulkGet(getKeys)
      return (data || []) as RelationshipType[]
    },
    [],
    [] as RelationshipType[]
  )
  const crmConfig = useAccountsStore((state) => state.crmConfig)

  const tableData: any = useMemo(() => {
    return (
      relationships?.map((rel: any) => {
        const { _id, users, channel, crm_values, customer_name } = rel
        const {
          name,
          type,
          contract_term,
          contract_value,
          synced_with_crm,
          additional_fields = emptyList,
          contract_end_date,
          contract_start_date,
          internal_slack_channel_name,
        } = crm_values || emptyObject

        const rowInfo = {
          _id,
          users,
          name: name || customer_name,
          accountType: type,
          externalSlackChannel: channel.name,
          contractTerm: contract_term,
          contractValue: contract_value,
          syncedWithCRM: synced_with_crm,
          contractEndDate: contract_end_date,
          contractStartDate: contract_start_date,
          internalSlackChannel: internal_slack_channel_name,
          accountOwner: users.find((user: any) => user.role === 'csm') || null,
        }

        crmConfig?.additional_fields?.forEach((field) => {
          const values = additional_fields.find(
            ({ field_name }: { field_name: any }) =>
              field_name === field.field_name
          )?.values
          // @ts-ignore

          rowInfo[camelCase(field.field_name)] = values
        })
        return rowInfo
      }) ?? []
    )
  }, [relationships, crmConfig])

  const filteredTableData = useApplyFilter(tableData)

  const cards = useMemo(() => {
    const { customersLen, prospectsLen, acv, opportunity } =
      filteredTableData.reduce(
        (acc, rel) => {
          const { accountType, contractValue = 0 } = rel || {}
          if (accountType === 'customer') {
            return {
              ...acc,
              customersLen: acc.customersLen + 1,
              acv: acc.acv + contractValue,
            }
          }
          if (accountType === 'prospect') {
            return {
              ...acc,
              prospectsLen: acc.prospectsLen + 1,
              opportunity: acc.opportunity + contractValue,
            }
          }
          return acc
        },
        {
          acv: 0,
          opportunity: 0,
          prospectsLen: 0,
          customersLen: 0,
        }
      )

    return [
      {
        isCurrency: false,
        name: 'Total Accounts',
        figure: filteredTableData.length,
      },
      {
        name: 'Customers',
        isCurrency: false,
        figure: customersLen,
      },
      {
        name: 'ACV',
        figure: acv || 0,
        isCurrency: true,
      },
      {
        name: 'Prospects',
        isCurrency: false,
        figure: prospectsLen,
      },
      {
        isCurrency: true,
        name: 'Opportunity',
        figure: opportunity || 0,
      },
    ]
  }, [filteredTableData])

  return (
    <Wrapper>
      <HorizontalList
        listItems={cards.map((card, index) => {
          return <Card key={index} card={card} />
        })}
      />
    </Wrapper>
  )
}

export default Cards
