import {
  MsteamsChannel,
  MsteamsTeam,
  MsteamsTeamChannel,
} from '../../modules/integrations/definitions'
import {
  FETCH_DATA,
  SET_IS_ACTIVE,
  SET_SLACK_CHANNEL,
} from '../types/msteamsReducerAction'

interface Action {
  type: typeof FETCH_DATA | typeof SET_IS_ACTIVE | typeof SET_SLACK_CHANNEL
  payload: any
}

type StateModificationWithActive = {
  slackChannelId?: never
  dirty?: boolean
  active: MsteamsTeamChannel['active'] | null
}

type StateModificationWithSlackChannelId = {
  active?: never
  dirty?: boolean
  slackChannelId: MsteamsChannel['id'] | null
}

export type StateModification = {
  msteamsTeamId: MsteamsTeam['id']
  msteamsChannelId: MsteamsChannel['id'] | null
} & (StateModificationWithActive | StateModificationWithSlackChannelId)

const modifyState = ({
  state,
  msteamsTeamId,
  msteamsChannelId,
  dirty = false,
  slackChannelId = null,
  active = null,
}: StateModification & { state: any }) => {
  const teamChannels = state[msteamsTeamId]
  return teamChannels.map((item: MsteamsTeamChannel) => {
    if (
      item.msteams?.team.id === msteamsTeamId &&
      item.msteams?.channel.id === msteamsChannelId
    ) {
      return {
        ...item,
        slack: {
          ...item.slack,
          channel: {
            ...item.slack?.channel,
            id:
              slackChannelId === null
                ? item.slack?.channel?.id
                : slackChannelId,
          },
        },
        active: active === null ? item.active : active,
        dirty,
      }
    }
    return item
  })
}

export const msteamsReducer = (state: {}, action: Action) => {
  const { type, payload } = action
  const { active, slackChannelId, msteamsTeamId, msteamsChannelId } = payload

  switch (type) {
    case FETCH_DATA:
      return {
        ...state,
        ...payload,
      }
    case SET_IS_ACTIVE:
      return {
        ...state,
        [payload.msteamsTeamId]: [
          ...modifyState({
            state: { ...state },
            dirty: true,
            active,
            msteamsTeamId,
            msteamsChannelId,
          }),
        ],
      }
    case SET_SLACK_CHANNEL:
      return {
        ...state,
        [payload.msteamsTeamId]: [
          ...modifyState({
            state: { ...state },
            dirty: true,
            slackChannelId,
            msteamsTeamId,
            msteamsChannelId,
          }),
        ],
      }
    default:
      return state
  }
}
