import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import type { TableRowSelection } from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import SealWarning from '../../../../images/icons/SealWarning.svg'
import CheckMark from '../../../../images/icons/checkMark.svg'
import {
  BulkUpdateQNAType,
  QNADataType,
  QNAType,
  UpdateQNAType,
} from '../../../../types'
import { SMEImg } from '../StyledComponent'
import { BulkEdit } from './BulkEdit'
import { QNAExpanded } from './QNAExpanded'

export const QNAList = ({
  qnaData,
  expandAllStatus,
  expandAll,
  bulkUpdateQNA,
  updateQNA,
  updateStatus,
}: {
  qnaData: QNADataType
  expandAllStatus: boolean
  expandAll: () => void
  bulkUpdateQNA: (data: BulkUpdateQNAType) => void
  updateQNA: (qnaId: string, newItemData: UpdateQNAType) => void
  updateStatus: boolean
}) => {
  const [bulkUpdateData, setBulkUpdateData] = useState<BulkUpdateQNAType>({
    qnaIds: [],
    isActive: false,
    isVerified: false,
  })
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [bulkEditStatus, setBulkEditStatus] = useState(false)
  const [expandStatus, setExpandStatus] = useState(false)
  const [editedRowId, setEditedRowId] = useState('')
  const [editedAnswer, setEditedAnswer] = useState('')
  const allRowKeys = qnaData.qnas.map((data) => data._id)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'displayId',
      key: 'displayId',
      render: (value: string) => (
        <span
          style={{
            color: 'var(--color-gray-6)',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '14px',
          }}
        >
          {'Q-' + value}
        </span>
      ),
      width: '60px',
    },
    {
      title: 'Status',
      dataIndex: 'isVerified',
      key: 'isVerified',
      render: (value: boolean) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={value === true ? CheckMark : SealWarning} alt="verify" />
        </div>
      ),
      cellPaddingInlineSM: true,
      width: '60px',
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
      render: (value: string) => (
        <span
          style={{
            fontSize: '15px',
            fontFamily: 'Lato',
            fontWeight: 600,
            lineHeight: '20px',
            color: 'var(--color-black-1)',
          }}
        >
          {value}
        </span>
      ),
      cellPaddingInlineSM: true,
    },
    {
      title: 'Cluster',
      dataIndex: ['cluster', 'clusterName'],
      key: 'clusterName',
      render: (value: string) => <Tag className="cluster-tag">{value}</Tag>,
      width: '240px',
    },
    {
      title: 'SME',
      dataIndex: ['sme', 'image'],
      key: 'image',
      render: (image: string) => <SMEImg src={image} alt="Avatar" />,
      width: '48px',
    },
    Table.EXPAND_COLUMN,
  ]

  const rowSelection: TableRowSelection<QNAType> = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length > 0) {
        setBulkEditStatus(true)
      } else {
        setBulkEditStatus(false)
      }
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const handleRowClick = (record: QNAType) => {
    if (editedRowId && editedAnswer !== '') {
      const editedRecord = qnaData.qnas.find((qna) => qna._id === editedRowId)
      if (editedRecord && !expandAllStatus && !updateStatus) {
        const updatedRecord = { ...editedRecord, answer: editedAnswer }
        saveChanges(updatedRecord)
      }
    }
    const isCurrentlyExpanded = expandedRowKeys.includes(record._id)
    let newExpandedRowKeys = [...expandedRowKeys]
    if (!isCurrentlyExpanded && editedRowId !== record._id) {
      newExpandedRowKeys = editedRowId !== '' ? [editedRowId] : [record._id]
    } else if (isCurrentlyExpanded && editedRowId === record._id) {
      newExpandedRowKeys = [editedRowId]
    } else {
      newExpandedRowKeys = isCurrentlyExpanded
        ? newExpandedRowKeys.filter((id) => id !== record._id)
        : [record._id]
    }
    setExpandedRowKeys(newExpandedRowKeys)
    setEditedRowId('')
    setEditedAnswer('')
  }

  const onSelectAll = () => {
    const allKeys = qnaData.qnas.map((item) => item._id)
    setSelectedRowKeys(allKeys)
    setBulkEditStatus(true)
  }

  const onDeselectAll = () => {
    setSelectedRowKeys([])
    setBulkEditStatus(false)
  }

  const handleSelectedExpand = () => {
    if (selectedRowKeys.length === 50) {
      setExpandStatus(!expandAllStatus)
      expandAll()
    } else {
      const newExpandStatus = !expandStatus
      setExpandStatus(newExpandStatus)
      setExpandedRowKeys(
        newExpandStatus
          ? expandedRowKeys.concat(
              selectedRowKeys.map((item) => item.toString())
            )
          : expandedRowKeys.filter(
              (expanded) => !selectedRowKeys.includes(expanded)
            )
      )
    }
  }

  const saveChanges = (updatedQna: QNAType) => {
    const newQNA = {
      clusterId: updatedQna.cluster.clusterId,
      question: updatedQna.question,
      answer: updatedQna.answer || '',
      isVerified: updatedQna.isVerified,
      isActive: updatedQna.isActive,
    }
    updateQNA(updatedQna._id, newQNA)
  }

  useEffect(() => {
    if (expandAllStatus && !updateStatus) {
      setExpandedRowKeys(allRowKeys)
      setExpandStatus(true)
    } else {
      if (expandedRowKeys.length > 1) {
        setExpandedRowKeys([])
      }
      setExpandStatus(false)
    }
    if (selectedRowKeys.length === 50) {
      setExpandStatus(expandAllStatus)
    }
  }, [expandAllStatus])

  useEffect(() => {
    if (bulkUpdateData.qnaIds.length > 0) {
      bulkUpdateQNA(bulkUpdateData)
    }
  }, [bulkUpdateData])

  return (
    <Wrapper>
      <Table
        dataSource={qnaData.qnas}
        className="border-solid rounded-md border-[1px] border-[#EEEFF2] qna-table"
        columns={columns}
        pagination={false}
        rowKey="_id"
        size="middle"
        rowSelection={rowSelection}
        scroll={{ y: window.innerHeight - 332 }}
        onRow={(record) => ({
          onClick: () => {
            !updateStatus && handleRowClick(record)
          },
        })}
        expandable={{
          expandedRowRender: (record, _index, _indent, expanded) =>
            expanded && (
              <QNAExpanded
                qna={record}
                expandAllStatus={expandAllStatus || expandStatus}
                isLoading={updateStatus}
                setEditedRowId={setEditedRowId}
                setEditedAnswer={setEditedAnswer}
                saveChanges={saveChanges}
              />
            ),
          expandIcon: ({ expanded, onExpand, record }) => (
            <div
              onClick={(e) => onExpand(record, e)}
              style={{ width: '24px', cursor: 'pointer' }}
            >
              {expanded ? <ShrinkOutlined /> : <ArrowsAltOutlined />}
            </div>
          ),
          expandedRowKeys: expandedRowKeys,
        }}
      />
      {bulkEditStatus && (
        <BulkEdit
          handleSelectedExpand={handleSelectedExpand}
          expandStatus={expandStatus}
          onSelectAll={onSelectAll}
          onDeselectAll={onDeselectAll}
          selectedRowKeys={selectedRowKeys}
          setBulkUpdateData={setBulkUpdateData}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .cluster-tag {
    font-size: 10px;
    line-height: 14px;
    color: var(--color-purple-4);
    background-color: var(--color-purple-3);
    font-weight: bold;
    padding: 2px 4px;
  }
  .ant-table-row:hover {
    cursor: pointer;
  }
  .qna-table {
    .ant-table-row-selected .ant-checkbox-inner,
    .ant-table-selection-column
      .ant-checkbox-wrapper-checked
      .ant-checkbox-inner {
      background-color: var(--color-purple-1);
      border-color: var(--color-purple-1);
    }
  }
  .qna-table
    .ant-table-selection-column
    .ant-checkbox-indeterminate
    .ant-checkbox-inner::after {
    background-color: var(--color-purple-1);
  }
  .qna-table .ant-table-cell-row-hover .ant-checkbox-inner {
    border-color: var(--color-purple-1);
  }
  .qna-table .ant-table-tbody > tr.ant-table-row-selected > td {
    background: var(--color-purple-3);
  }
`
