import { api } from '.'
import { CreateConversationType, FeedbackType } from '../types/playgroundTypes'

export const fetchConversationList = async (assistantId: string) => {
  const { data } = await api.get(
    `v2/knowledge-base-ai/assistant/${assistantId}/conversation`
  )
  return data.data
}

export const createConversation = async ({
  assistantId,
  newConversationData,
}: {
  assistantId: string
  newConversationData: CreateConversationType
}) => {
  const { data } = await api.post(
    `v2/knowledge-base-ai/assistant/${assistantId}/conversation`,
    newConversationData
  )
  return data.data
}

export const fetchConversationDetails = async ({
  assistantId,
  conversationId,
}: {
  assistantId: string
  conversationId: string
}) => {
  const { data } = await api.get(
    `v2/knowledge-base-ai/assistant/${assistantId}/conversation/${conversationId}`
  )
  return data.data
}

export const conversationFeedback = async ({
  assistantId,
  conversationId,
  qnaId,
  feedback,
}: {
  assistantId: string
  conversationId: string
  qnaId: string
  feedback: FeedbackType
}) => {
  const { data } = await api.patch(
    `v2/knowledge-base-ai/assistant/${assistantId}/conversation/${conversationId}/feedback`,
    { qnaId, feedback }
  )
  return data.data
}
