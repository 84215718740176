import { ArrowsAltOutlined, ShrinkOutlined } from '@ant-design/icons'
import { Row, Tag } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { BulkUpdateQNAType } from '../../../../types'
import { BulkActivate } from './BulkActivate'
import { BulkCluster } from './BulkCluster'
import { BulkSelect } from './BulkSelect'
import { BulkVerify } from './BulkVerify'

export const BulkEdit = ({
  handleSelectedExpand,
  onSelectAll,
  onDeselectAll,
  expandStatus,
  selectedRowKeys,
  setBulkUpdateData,
}: {
  handleSelectedExpand: () => void
  onSelectAll: () => void
  onDeselectAll: () => void
  expandStatus: boolean
  selectedRowKeys: React.Key[]
  setBulkUpdateData: (data: BulkUpdateQNAType) => void
}) => {
  const [isSelectOpen, setSelectOpen] = useState(false)
  const [isClusterOpen, setClusterOpen] = useState(false)
  const [isStatusOpen, setStatusOpen] = useState(false)
  const [isActivationOpen, setActivationOpen] = useState(false)
  const onVerify = () => {
    setStatusOpen(false)
    onDeselectAll()
    setBulkUpdateData({
      qnaIds: selectedRowKeys,
      isVerified: true,
    })
  }
  const onInvalidate = () => {
    setStatusOpen(false)
    onDeselectAll()
    setBulkUpdateData({
      qnaIds: selectedRowKeys,
      isVerified: false,
    })
  }

  const onActive = () => {
    setActivationOpen(false)
    onDeselectAll()
    setBulkUpdateData({
      qnaIds: selectedRowKeys,
      isActive: true,
    })
  }
  const onDeactive = () => {
    setActivationOpen(false)
    onDeselectAll()
    setBulkUpdateData({
      qnaIds: selectedRowKeys,
      isActive: false,
    })
  }
  const onSelectCluster = (value: string) => {
    setClusterOpen(false)
    onDeselectAll()
    setBulkUpdateData({
      qnaIds: selectedRowKeys,
      clusterId: value,
    })
  }

  return (
    <Wrapper className="scroll-wrapper">
      <BulkSelect
        onSelectAll={onSelectAll}
        onDeselectAll={onDeselectAll}
        selectedRowsCount={selectedRowKeys.length}
        isOpen={isSelectOpen}
        setOpen={setSelectOpen}
      />
      <BulkCluster
        onSelectCluster={(value: string) => onSelectCluster(value)}
        isOpen={isClusterOpen}
        setOpen={setClusterOpen}
      />
      <BulkVerify
        onVerify={onVerify}
        onInvalidate={onInvalidate}
        isOpen={isStatusOpen}
        setOpen={setStatusOpen}
      />
      <BulkActivate
        onActive={onActive}
        onDeactive={onDeactive}
        isOpen={isActivationOpen}
        setOpen={setActivationOpen}
      />
      <Tag className="expand-all" onClick={handleSelectedExpand}>
        {expandStatus ? (
          <>
            <ShrinkOutlined />
            Collapse All
          </>
        ) : (
          <>
            <ArrowsAltOutlined />
            Expand All
          </>
        )}
      </Tag>
    </Wrapper>
  )
}

const Wrapper = styled(Row)`
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 12px;
  background-color: var(--color-white);
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 12px 0px rgba(12, 12, 13, 0.12);
  gap: 12px;
  overflow-x: scroll;
  align-items: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  .expand-all {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 12px 4px 8px;
    color: var(--color-purple-4);
    background-color: var(--color-purple-3);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
  }
`
