import { Checkbox, Input, Popover, RadioChangeEvent } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { MagnifyingGlass } from 'phosphor-react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAuth } from '../../context'
import useEvents from '../../hooks/useEvents'
import CloseIcon from '../../images/icons/CloseIcon.svg'
import { useKanbanStorePersist } from '../../store/kanbanStorePersist'
import './DynamicMultiSelect.css'

const ButtonWrapper = styled.div`
  display: inline-block;
  // border: 1px solid transparent;
  // cursor: pointer;
  // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  // -webkit-user-select: none;
  // user-select: none;
  // touch-action: manipulation;
  // padding: 4px 8px;
  // border-radius: 6px;
  // border-color: #dce2f0;
  // :hover {
  //   color: #9729ff;
  //   background: #fff;
  //   border-color: #9729ff;
  // }
`

export const DynamicMultiSelect = (props: any) => {
  const { disabled, initialSelectedValues, isControlled, listToShow } = props
  const [isOpen, setOpen] = useState(false)
  const configuredFieldKeys = useKanbanStorePersist(
    (state) => state.configuredFieldValues
  )
  const crmFieldKeys = useKanbanStorePersist((state) => state.crmFieldValues)
  const [allData, setAllData] = useState<any>(
    !props.isCRM ? initialSelectedValues : configuredFieldKeys[props.label]
  )
  const { user } = useAuth()
  const [searchQuery, setSearchQuery] = useState('')
  const trackEvent = useEvents()
  useEffect(() => {
    props.func(allData)
  }, [allData])

  useEffect(() => {
    if (!props.isCRM) {
      setAllData(configuredFieldKeys[props.label])
    } else if (props.isCRM) {
      setAllData(crmFieldKeys[props.label])
    }
  }, [configuredFieldKeys, crmFieldKeys])

  useEffect(() => {
    // if (props.type === 'multiselect') {
    //   setAllData([])
    // }
    //setAllData([])
  }, [props.type])

  const onChange = (e: CheckboxChangeEvent, value: string) => {
    const {
      target: { checked },
    } = e
    if (checked) {
      setAllData((current: any) => {
        if (current) {
          return [...current, value]
        } else {
          return [value]
        }
      })
    } else {
      const array = [...allData] // make a separate copy of the array
      const index = array.indexOf(value)
      if (index !== -1) {
        array.splice(index, 1)
        setAllData(array)
      }
    }
  }
  const onRadioChange = (e: RadioChangeEvent) => {
    const {
      target: { checked, value },
    } = e
    setAllData(value)
  }
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }
  const sortedContent = (data: any) =>
    (data || []).sort((a: any, b: any) =>
      a.trim().toLowerCase().localeCompare(b.trim().toLowerCase())
    )

  const content = (
    <div>
      <Input
        placeholder="Search"
        onChange={onSearch}
        prefix={<MagnifyingGlass color="#707070" />}
        autoFocus
      />
      <div style={{ height: '200px', overflow: 'auto' }}>
        {sortedContent(props.data)
          ?.filter((it: any) =>
            it.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((tag: string, index: number) => {
            return (
              <div
                key={index}
                className={` mt-[0.5rem] mb-[0.5rem] ${
                  allData === tag ? 'checkbox-label-selected' : 'checkbox-label'
                }`}
              >
                {/* {props.type === 'multiselect' && ( */}
                <Checkbox
                  className={
                    allData?.includes(tag)
                      ? 'checkbox-selected'
                      : 'checkbox-item'
                  }
                  name={tag}
                  checked={allData?.includes(tag)}
                  onChange={(e) => {
                    onChange(e, tag)
                    trackEvent('Kanban Filter', {
                      name: props.label,
                      value: tag,
                      enabled: allData?.includes(tag),
                      isCRM: props.isCRM ?? false,
                    })
                  }}
                >
                  {tag.trim()}
                </Checkbox>
                {/* )} */}
                {/* {(props.type === 'select' || props.type === 'radio') && (
                <Radio
                  name={tag}
                  checked={allData === tag}
                  value={tag}
                  onChange={(e) => {
                    onRadioChange(e)
                    ;(window as any).analytics.track('Kanban Filter', {
                      userId: user?.id,
                      userEmail: user?.email,
                      teamId: user?.team_id,
                      teamName: user?.team_name,
                      name: props.label,
                      value: tag,
                    })
                  }}
                >
                  <label>{tag}</label>
                </Radio>
              )} */}
              </div>
            )
          })}
      </div>
    </div>
  )
  const List = () => {
    if (isControlled) {
      const sortedList = sortedContent(listToShow)
      return sortedList.map((tag: any, index: any) => {
        if (index === 3 && sortedList.length > 3) {
          return (
            <label className="mr-1 text-xs filter-chip" key={`${index}`}>
              {`+${sortedList.length - 3}`}
            </label>
          )
        }
        if (index > 3) {
          return <div></div>
        }

        return (
          <label className="mr-1 text-xs filter-chip" key={`${index}`}>
            {tag}
          </label>
        )
      })
    }

    const sortedAllData = sortedContent(allData)
    return sortedAllData?.map((tag: any, index: any) => {
      if (index === 3 && sortedAllData.length > 3) {
        return (
          <label className="mr-1 text-xs filter-chip" key={`${index}`}>
            {`+${sortedAllData.length - 3}`}
          </label>
        )
      }
      if (index > 3) {
        return <div></div>
      }

      return (
        <label className="mr-1 text-xs filter-chip" key={`${index}`}>
          {tag}
        </label>
      )
    })
  }

  return (
    <div className="flex items-center filter-btn">
      <Popover
        content={content}
        trigger="click"
        placement="bottomLeft"
        showArrow={false}
        open={isOpen}
        onOpenChange={(visible) => setOpen(visible)}
        destroyTooltipOnHide
      >
        <ButtonWrapper>
          <div
            role="button"
            tabIndex={disabled ? -1 : 0}
            className={`button-multi ${disabled ? 'button--disabled' : ''}`}
            aria-disabled={disabled}
            id={props.name}
          >
            <img src={props.icon} alt="icon" />
            <span className="ml-1 mr-1">{props.label}</span>
            {/* {props.type === 'multiselect' && <List></List>} */}
            {<List></List>}

            {/* {(props.type === 'select' || props.type === 'radio') &&
              allData?.length ? (
              <label className="text-xs filter-chip">{allData}</label>
            ) : null} */}
            {/* {props.clearIcon} */}
          </div>
        </ButtonWrapper>
      </Popover>
      <img
        src={CloseIcon}
        onClick={() => {
          setOpen(false)
          setAllData([])
          props.func([])
        }}
        alt="Close"
        className="ml-1"
      />
    </div>
  )
}
