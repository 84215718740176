import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import {
  askQuestion as askQuestionApi,
  pollingAnswer as pollingAnswerApi,
} from '../api/ask'
import { AnswerType } from '../types/playgroundTypes'

const useQuestion = ({
  onSuccess,
  onError,
  assistantId,
  conversationId,
}: {
  onSuccess?: (data: string) => void
  onError?: (error: Error | unknown) => void
  assistantId: string
  conversationId: string
}) => {
  const [pollingKey, setPollingKey] = useState<string | undefined>('')
  const [qnaId, setQNAId] = useState('')
  const [requestPollingAnswer, setRequestPollingAnswer] = useState(0)

  const askMutation = useMutation(askQuestionApi, {
    onSuccess: (data: AnswerType) => {
      if (!data) {
        return onSuccess && onSuccess('Something went wrong. Please try again!')
      }
      const isPolling = data['isPolling']
      if (isPolling) {
        setPollingKey(data['pollingKey'])
        setQNAId(data['qnaId'])
        setRequestPollingAnswer(
          (requestPollingAnswer) => requestPollingAnswer + 1
        )
      } else {
        setRequestPollingAnswer(0)
        onSuccess && onSuccess(data['answer'])
      }
    },
    onError: (error) => {
      setRequestPollingAnswer(0)
      onError && onError(error)
    },
  })

  const pollingMutation = useMutation(pollingAnswerApi, {
    onSuccess: (data: AnswerType) => {
      const pollingStatus = data['status']

      if (pollingStatus === 'in_progress') {
        setRequestPollingAnswer(
          (requestPollingAnswer) => requestPollingAnswer + 1
        )
      }
      if (pollingStatus === 'completed') {
        setRequestPollingAnswer(0)
        onSuccess && onSuccess(data['answer'])
      }
    },
    onError: (error) => {
      setRequestPollingAnswer(0)
      onError && onError(error)
    },
  })

  const askQuestion = (question: string) => {
    askMutation.mutate({ assistantId, question, conversationId })
  }

  const loadingStatus =
    askMutation.isLoading ||
    pollingMutation.isLoading ||
    requestPollingAnswer > 0

  useEffect(() => {
    if (pollingKey && requestPollingAnswer < 50 && requestPollingAnswer > 0)
      pollingMutation.mutate({ assistantId, pollingKey, conversationId, qnaId })
  }, [requestPollingAnswer, pollingKey])

  return {
    askQuestion,
    loadingStatus,
  }
}

export default useQuestion
