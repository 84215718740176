import { Modal, message } from 'antd'
import { useParams } from 'react-router'
import useSources from '../../../../hooks/useSources'
import { SourceInputType, SourceType } from '../../../../types'
import { SourceForm } from '../Forms/SourceForm'

type EditSourceProps = {
  isOpen: boolean
  onCancel: () => void
  isSaving?: boolean
  currentSource?: SourceType
}

export const EditSourceModal = ({
  onCancel,
  isOpen,
  currentSource,
}: EditSourceProps) => {
  const { id = '' } = useParams()

  const onUpdateSourceSuccess = () => {
    onCancel()
    message.success('Successfully updated!')
  }
  const onUpdateSourceError = () => {
    message.error('Something went wrong!')
  }

  const {
    updateSource,
    updateSourceStatus: { isLoading },
  } = useSources({
    onUpdateSourceSuccess,
    onUpdateSourceError,
    assistantId: id,
  })

  const handleSubmit = (formData: SourceInputType) => {
    currentSource && updateSource(currentSource._id, formData.sourceName)
  }

  return (
    <Modal open={isOpen} footer={null} onCancel={onCancel} destroyOnClose>
      {currentSource && (
        <SourceForm
          onCancel={onCancel}
          isSaving={isLoading}
          onSubmit={handleSubmit}
          currentData={currentSource}
        />
      )}
    </Modal>
  )
}
