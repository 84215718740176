/* eslint-disable max-lines */
import { Button, Drawer, Input, Select, SelectProps } from 'antd'
import { BaseButtonProps } from 'antd/lib/button/button'
import Link from 'antd/lib/typography/Link'
import { CaretDown, Info, Question } from 'phosphor-react'
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { fetchPatchHelpdesk } from '../../api/zendesk'
import AlertIcon from '../../images/icons/alert-triangle.svg'
import CheckFilledIcon from '../../images/icons/check-filled-icon.svg'
import CloseFilledIcon from '../../images/icons/close-filled-icon.svg'
import { OptionType, useZendeskStore } from '../../store/zendeskStore'

export const StyledP = styled.p<{
  color?: string
}>`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.color || 'var(--color-black-1)'};
`

export const Text1 = styled(StyledP)`
  font-weight: 600;
`

export const Text2 = styled(StyledP)`
  font-weight: 500;
`

export const Text3 = styled(StyledP)`
  font-size: 14px;
  font-weight: 600;
`

export const Text4 = styled(StyledP)`
  font-size: 14px;
  font-weight: 500;
`

export const Text5 = styled(StyledP)`
  font-size: 14px;
  font-weight: 400;
`

export const Text6 = styled(StyledP)`
  font-size: 12px;
  font-weight: 600;
`

export const Text7 = styled(StyledP)`
  font-size: 12px;
  font-weight: 500;
`

export const Text8 = styled(StyledP)`
  font-size: 12px;
  font-weight: 400;
`

export const Text9 = styled(StyledP)`
  font-size: 10px;
  font-weight: 500;
`

export const Text10 = styled(StyledP)`
  font-size: 20px;
  font-weight: 600;
`

export const Text11 = styled(StyledP)`
  font-size: 10px;
  font-weight: 400;
`

export const StyledButton = styled(Button)``

type ButtonProps = {
  onClick?: () => void
  children?: React.ReactNode
  buttonProps?: BaseButtonProps
}

export const PrimaryButton = ({
  onClick,
  children,
  buttonProps,
}: ButtonProps) => {
  return (
    <StyledButton type="primary" {...buttonProps} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

export const SecondaryButton = ({
  onClick,
  children,
  buttonProps,
}: ButtonProps) => {
  return (
    <StyledButton type="ghost" {...buttonProps} onClick={onClick}>
      {children}
    </StyledButton>
  )
}

type ZendeskModalProps = {
  open: boolean
  title: string
  footer: React.ReactNode
  handleClose: () => void
  children: React.ReactNode
  closeIcon: React.ReactNode
}

export const ZendeskModal = (props: ZendeskModalProps) => {
  const { handleClose, open, closeIcon, title, footer, children } = props

  const onHelpIconClick = () => {
    // TODO: Open help doc
  }

  return (
    <Drawer
      width="75vw"
      placement="right"
      onClose={handleClose}
      open={open}
      closeIcon={closeIcon}
      style={{
        fontFamily: 'var(--global-font)',
      }}
      headerStyle={{
        padding: '12px 16px',
        borderColor: 'var(--color-gray-3)',
        backgroundColor: 'var(--color-gray-9)',
      }}
      bodyStyle={{
        margin: 0,
        padding: 0,
      }}
      footerStyle={{
        borderColor: 'var(--color-gray-3)',
      }}
      title={
        <div className="flex justify-between items-center">
          <div>{title}</div>
          <div
            onClick={onHelpIconClick}
            className="flex items-center gap-[4px] cursor-pointer"
          >
            <Text4
              as={Link}
              target="_blank"
              href="https://help.thena.ai/docs/external-tickets-zendesk"
              className="m-[0] !text-[var(--color-gray-4)]"
            >
              How it works
              <Question color="var(--color-gray-4)" className="ml-[4px]" />
            </Text4>
          </div>
        </div>
      }
      footer={footer}
    >
      {children}
    </Drawer>
  )
}

export const Card = styled.div`
  gap: 24px;
  display: flex;
  padding: 16px 20px;
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid var(--color-gray-3);
  .ant-input-group-wrapper {
    padding: 0;
    height: 42px;
    .ant-input-group,
    .ant-input {
      height: 42px;
    }
  }
`

type FieldProps = {
  classNames?: string
  title?: React.ReactNode
  subtitle?: React.ReactNode
  inputElement?: React.ReactNode
}

const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--color-gray-3);
`

export const Field = ({
  title,
  subtitle,
  classNames,
  inputElement,
}: FieldProps) => {
  return (
    <FieldWrapper className={classNames}>
      <div className="field-label-container">
        {title ? title : null}
        {subtitle ? subtitle : null}
      </div>
      <div className="relative">{inputElement ? inputElement : null}</div>
    </FieldWrapper>
  )
}

export const StyledInput = styled(Input)<{
  $showError?: boolean
  $showInnerError?: boolean
}>`
  width: 320px;
  padding: 9px 11px;
  ${(props) => {
    if (props.$showInnerError) {
      return css`
        .ant-input {
          border: 1px solid var(--color-red-1) !important;
        }
      `
    }
    if (props.$showError) {
      return css`
        border: 1px solid var(--color-red-1) !important;
      `
    }
  }}
`

export const StyledInputPassword = styled(StyledInput.Password)<{
  $showError?: boolean
}>`
  width: 320px;
  padding: 9px 11px;
  ${(props) =>
    props.$showError &&
    css`
      border: 1px solid var(--color-red-1) !important;
    `}
`

export const Tag = styled.div`
  display: flex;
  width: min-content;
  font-size: 10px;
  padding: 2px 8px;
  font-weight: 500;
  line-height: 14px;
  align-items: center;
  justify-content: center;
  color: var(--color-brown-3);
  border-radius: 20px;
  background: var(--color-brown-4);
`

type StatusIconProps = {
  success: boolean
}

export const StatusIcon = ({ success }: StatusIconProps) => {
  if (success) {
    return <img src={CheckFilledIcon} alt="success" width={16} height={16} />
  }
  return <img src={CloseFilledIcon} alt="failure" width={16} height={16} />
}

type SelectDropdownProps = {
  options: OptionType
  placeholder: string
  extraItem?: React.ReactNode
  value: string[] | string | null
  setValue: (items: any) => void
  mode?: 'multiple' | undefined
  width?: string
}

export const SelectDropdown = ({
  options,
  extraItem,
  placeholder,
  value,
  setValue,
  mode = undefined,
  width = '320px',
}: SelectDropdownProps) => {
  const selectProps: SelectProps = {
    mode,
    style: { width },
    value,
    options,
    onChange: (_, options) => {
      setValue(options as OptionType)
    },
    placeholder,
    maxTagCount: 'responsive',
    showArrow: true,
    showSearch: true,
    allowClear: true,
    listHeight: 300,
    filterOption: (input, option) => {
      let label = ''
      if (React.isValidElement(option?.label)) {
        label = option?.label?.props?.children?.[1]?.toString()
      } else {
        label = option?.label as string
      }
      return (label ?? '').toLowerCase().includes(input.toLowerCase())
    },
    getPopupContainer: (trigger) => trigger.parentElement || document.body,
    dropdownRender: (menu) => {
      return (
        <>
          {menu}
          {extraItem ? extraItem : null}
        </>
      )
    },
  }

  return <Select {...selectProps} />
}

const ErrorBlockWrapper = styled.div`
  padding: 24px;
  border-radius: 10px;
  background-color: var(--color-red-2);
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  max-width: 932px;
`

type ErrorBlockProps = {
  className?: string
  showExtra?: boolean
}

export const ErrorBlock = ({ className, showExtra }: ErrorBlockProps) => {
  return (
    <ErrorBlockWrapper className={className}>
      <img src={AlertIcon} alt="alert" width={32} height={32} />
      <StyledP className="m-[0]">
        <Text2 className="m-[0]" as="span">
          Error:
        </Text2>{' '}
        Forms import error: Failed to import forms from Zendesk. Please ensure
        the forms are properly configured in Zendesk and accessible
        {showExtra ? (
          <StyledP className="m-[0] mt-[12px]">
            Still unable to import fields from Zendesk? Read more on our{' '}
            <Text2
              as={Link}
              color="var(--color-blue-7)"
              className="m-[0] !underline"
            >
              Help Document
            </Text2>
          </StyledP>
        ) : null}
      </StyledP>
      <PrimaryButton
        buttonProps={{
          className: showExtra ? 'self-start' : '',
        }}
      >
        Retry
      </PrimaryButton>
    </ErrorBlockWrapper>
  )
}

export const Tab = styled.div<{ $isActive: boolean }>`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $isActive }) =>
    $isActive ? 'var(--color-purple-1)' : 'var(--color-gray-4)'};
  position: relative;
`

export const Border = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1.2px;
  border-bottom: 1.2px solid var(--color-gray-7);
`

export const ExpandableCard = styled(Card)<{ $expanded?: boolean }>`
  display: block;
  position: relative;
  overflow: hidden;
  max-height: ${(props) => (props.$expanded ? '1200px' : '84px')};
  transition: max-height 0.2s ease-in-out;
  .expand-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: ${(props) => (props.$expanded ? 'rotate(180deg)' : 'rotate(0)')};
  }
`

type ExpandableProp = {
  className?: string
  header: React.ReactNode
  content: React.ReactNode
}

export const Expandable = ({ header, content, className }: ExpandableProp) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <ExpandableCard
      $expanded={expanded}
      className={className}
      onClick={() => setExpanded(true)}
      style={{
        cursor: expanded ? 'auto' : 'pointer',
      }}
    >
      <CaretDown
        size={20}
        className="expand-icon"
        color="var(--color-gray-5)"
        onClick={(e) => {
          e.stopPropagation()
          setExpanded((prev) => !prev)
        }}
      />
      {header}
      {expanded ? content : null}
    </ExpandableCard>
  )
}

type AuthFormProps = {
  className?: string
  isEditFlow?: boolean
}

export const AuthForm = ({ className, isEditFlow }: AuthFormProps) => {
  const dispatch = useZendeskStore((state) => state.dispatch)
  const authForm = useZendeskStore((state) => state.authForm)
  const { username, baseURL, token } = authForm
  const helpdeskId = useZendeskStore((state) => state.activeHelpdesk?._id || '')

  const [testError, setTestError] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const setUsername = (username: string) => {
    setDisabled(false)
    dispatch({
      type: 'SET_AUTH_FORM',
      payload: {
        authForm: {
          ...authForm,
          username: {
            value: username,
            error: '',
          },
        },
      },
    })
  }

  const setToken = (token: string) => {
    setDisabled(false)
    dispatch({
      type: 'SET_AUTH_FORM',
      payload: {
        authForm: {
          ...authForm,
          token: {
            value: token,
            error: '',
          },
        },
      },
    })
  }

  const setBaseURL = (baseURL: string) => {
    dispatch({
      type: 'SET_AUTH_FORM',
      payload: {
        authForm: {
          ...authForm,
          baseURL: {
            value: baseURL,
            error: '',
          },
        },
      },
    })
  }

  const renderForm = () => {
    return (
      <Card className={className}>
        <Field
          title={
            <Text3 className="m-[0]">
              Username
              <Text3 as="span" color="var(--color-red-1)" className="mx-[4px]">
                *
              </Text3>
            </Text3>
          }
          subtitle={
            <Text4 color="var(--color-gray-6)" className="m-[0] mt-[2px]">
              Enter your Zendesk username, the email associated with your
              Zendesk account
            </Text4>
          }
          inputElement={
            <div className="relative">
              <StyledInput
                placeholder="Enter Zendesk username"
                value={username.value}
                $showError={username.error.length > 0}
                onChange={(e) => setUsername(e.target.value)}
              />
              {username.error ? (
                <Text8
                  className="m-[0] absolute bottom-[-20px]"
                  color="var(--color-red-1)"
                >
                  {username.error}
                </Text8>
              ) : null}
            </div>
          }
        />
        <Field
          title={
            <Text3 className="m-[0]">
              Token
              <Text3 as="span" color="var(--color-red-1)" className="mx-[4px]">
                *
              </Text3>
            </Text3>
          }
          subtitle={
            <Text4 color="var(--color-gray-6)" className="m-[0] mt-[2px]">
              Input your generated Zendesk API token found in account settings
              under API tokens
            </Text4>
          }
          inputElement={
            <StyledInputPassword
              placeholder="Enter API token"
              value={token.value}
              $showError={token.error.length > 0}
              onChange={(e) => setToken(e.target.value)}
            />
          }
        />
        <Field
          title={
            <Text3 className="m-[0]">
              Base URL
              <Text3 as="span" color="var(--color-red-1)" className="mx-[4px]">
                *
              </Text3>
            </Text3>
          }
          subtitle={
            <Text4 color="var(--color-gray-6)" className="m-[0] mt-[2px]">
              Add your Zendesk dashboard URL (e.g., yourcompany.zendesk.com)
            </Text4>
          }
          inputElement={
            <div className="relative">
              <StyledInput
                width={340}
                addonAfter="/api/v2"
                addonBefore="https://"
                disabled={isEditFlow}
                value={baseURL.value}
                placeholder="yourcompany.zendesk.com"
                $showInnerError={baseURL.error.length > 0}
                onChange={(e) => setBaseURL(e.target.value)}
              />
              {baseURL.error ? (
                <Text8
                  className="m-[0] absolute bottom-[-20px]"
                  color="var(--color-red-1)"
                >
                  {baseURL.error}
                </Text8>
              ) : null}
            </div>
          }
          classNames="!border-none !pb-[4px]"
        />
      </Card>
    )
  }

  if (isEditFlow) {
    const onTestConnection = async () => {
      setIsLoading(true)
      try {
        await fetchPatchHelpdesk(helpdeskId, {
          token: authForm.token.value.trim(),
          username: authForm.username.value.trim(),
        })
        setDisabled(true)
        setTestError('')
      } catch (error: any) {
        console.log(error)
        const msg = error?.response?.data?.message
        if (msg) {
          setTestError(msg)
        }
        setDisabled(false)
      } finally {
        setIsLoading(false)
      }
    }

    return (
      <div>
        {renderForm()}
        <div className="flex items-center justify-between mt-[16px]">
          <div>
            {testError ? (
              <div className="flex items-center gap-[4px]">
                <Info color="var(--color-red-1)" size={20} />
                <Text4
                  color="var(--color-red-1)"
                  className="m-[0] leading-[20px]"
                >
                  {testError}
                </Text4>
              </div>
            ) : null}
          </div>
          <PrimaryButton
            buttonProps={{
              disabled,
              loading: isLoading,
            }}
            onClick={onTestConnection}
          >
            Test Connection
          </PrimaryButton>
        </div>
      </div>
    )
  }

  return renderForm()
}

/**
 * TODO:
 * - Add validation for email and URL
 * - border in table
 */
