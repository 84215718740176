import { init } from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/react'
import LogRocket from 'logrocket'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { registerSW } from 'virtual:pwa-register'
import App from './App'
import './utils/customWebComponents' //importing shadow-dom web component

if (location.hostname !== 'localhost') {
  // only in production
  registerSW({ immediate: true })
}

import {
  AMPLITUDE_API_KEY,
  LOG_ROCKET_APP_ID,
  NODE_ENV,
  SENTRY_DSN,
} from './config'
import { AuthProvider } from './context'
import './globals.less'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.5,
  })

  init(AMPLITUDE_API_KEY, undefined, {
    attribution: {
      trackPageViews: true,
    },
  })
}

if (NODE_ENV === 'production') {
  LogRocket.init(LOG_ROCKET_APP_ID, {
    mergeIframes: true,
    childDomains: ['*'], // OR: ['https://example.child_frame_domain.com', 'https://example.parent_window_domain.com'],
    release: process.env.REACT_APP_LATEST_COMMIT_SHA ?? '1.0.0',
  })
}

root.render(
  <Router>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>
)
