import { Skeleton } from 'antd'
import { useLiveQuery } from 'dexie-react-hooks'
import styled from 'styled-components'
import { accountDB } from '../../../DB/AccountDB'
import { formatCurrency } from '../utils'

const Wrapper = styled.div`
  height: 70px;
  min-width: 180px;
  padding: 10px 12px;
  border-radius: 10px;
  background-color: var(--color-white);
  box-shadow: 0 2px 10px rgba(12, 12, 13, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
    overflow: hidden !important;
    margin-top: 10px !important;
    border-radius: 4px !important;
  }
`

const Title = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
`

const BottomContainer = styled.div`
  position: relative;
`

const Figure = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
`

type IProps = {
  card: any
}

const Card = ({ card }: IProps) => {
  const { name, figure, isCurrency } = card
  const cardFigure = isCurrency ? formatCurrency(figure, 'currency') : figure

  const isDataFetched = useLiveQuery(
    async () => {
      const data = await accountDB.relationship.toArray()
      return data.length > 0
    },
    [],
    false
  )

  return (
    <Wrapper className="card-wrapper">
      <Title>{name}</Title>
      <BottomContainer>
        {isDataFetched ? (
          <Figure>{cardFigure}</Figure>
        ) : (
          <Skeleton.Input active size="small" />
        )}
      </BottomContainer>
    </Wrapper>
  )
}

export default Card
