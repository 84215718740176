import Dexie, { Table } from 'dexie'

export class KanbanBoardDB extends Dexie {
  conversations!: Table<any>

  constructor() {
    super('kanbanBoardDB')
    this.version(1)
      .stores({
        conversations: '_id,channelId,ts', // Primary key and indexed props
      })
      .upgrade(() => {
        this.on('versionchange', function (event: any) {
          console.log('KanbanBoardDb VersionChange', event)
          window.location.reload()
        })
      })
  }
}
export const kanbanBoardDB = new KanbanBoardDB()
