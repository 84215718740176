import { Button, Collapse, Divider, message, Switch, Typography } from 'antd'
import cls from 'classnames'
import { CaretDown, CaretUp } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  fetchConnectorConfiguration,
  saveConnectorConfiguration,
} from '../../../api/integrations'
import { ConnectorConfigurationDetailsType } from '../definitions'

const { Text } = Typography
const { Panel } = Collapse

interface ConnectorConfigurationType {
  send_threads_to_connectors: boolean
  send_ai_summary_to_connectors: boolean
  do_not_show_ticket_modal: boolean
  auto_create_ticket_from_request: boolean
  hide_priority: boolean
  invisible_only: boolean
}

interface Props {
  className?: string
  isEdition: boolean
  connector?: string
}

export const ExtendedConnectorConfiguration = ({
  className,
  isEdition = false,
  connector = '',
}: Props) => {
  const queryClient = useQueryClient()

  const [isConfigureOpen, setIsConfigureOpen] = useState<boolean>(!isEdition)

  const {
    data: configurations,
    isLoading,
    refetch,
  } = useQuery('fetch-connector-configurations', fetchConnectorConfiguration, {
    select: (
      data: ConnectorConfigurationDetailsType
    ): ConnectorConfigurationType => {
      return {
        send_threads_to_connectors: data?.send_threads_to_connectors as boolean,
        send_ai_summary_to_connectors:
          data?.send_ai_summary_to_connectors as boolean,
        do_not_show_ticket_modal: data?.do_not_show_ticket_modal as boolean,
        auto_create_ticket_from_request:
          data?.auto_create_ticket_from_request as boolean,
        hide_priority: data?.hide_priority as boolean,
        invisible_only: data?.invisible_only as boolean,
      }
    },
  })

  useEffect(() => {
    // On Mount Refetch configuration
    refetch()
  }, [])

  const { handleSubmit, reset, control } = useForm()

  useEffect(() => {
    reset(configurations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations])

  const saveConfigurationMutation = useMutation(saveConnectorConfiguration, {
    onSuccess: () => {
      message.success('Connector configuration saved')
      queryClient.invalidateQueries(['fetch-freshdesk-integration'])
    },
    onError: (error: any) => {
      console.log(error)
      message.error('Something went wrong while saving connector configuration')
    },
  })
  const onSave = (data: any) => {
    saveConfigurationMutation?.mutate({
      send_threads_to_connectors: data.send_threads_to_connectors,
      send_ai_summary_to_connectors: data.send_ai_summary_to_connectors,
      auto_create_ticket_from_request: data.auto_create_ticket_from_request,
      do_not_show_ticket_modal: data.do_not_show_ticket_modal,
      hide_priority: data.hide_priority,
      invisible_only: data.invisible_only,
    })
  }

  return (
    <Collapse
      ghost
      accordion
      expandIconPosition="end"
      className={cls(className, 'mt-5 border border-solid border-brand-border')}
      expandIcon={() => {
        if (!isConfigureOpen)
          return (
            <CaretDown size={18} color={!isEdition ? '#9096A4' : '#7A00FF'} />
          )
        return <CaretUp size={18} color="#7A00FF" />
      }}
      onChange={() => {
        setIsConfigureOpen((p) => !p)
      }}
    >
      <Panel
        header={
          <div className="flex justify-between flex-col ml-4">
            <h4
              className={cls(
                'm-0 font-bold'
                // !connectionPassed && !isEdition && 'text-muted'
              )}
            >
              Connector Configuration
            </h4>
            {isConfigureOpen && (
              <p className="text-grey font-medium mb-0">
                Miscellaneous configurations for connector
              </p>
            )}
          </div>
        }
        key="1"
      >
        <Divider className="m-0" />

        {connector !== 'INTERCOM' && connector !== 'HUBSPOT' && (
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Hide Urgency in Ticket preview of Slack</Text>
              </div>
              <div>
                <Controller
                  name="hide_priority"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      defaultChecked={false}
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
        {connector !== 'INTERCOM' && connector !== 'HUBSPOT' && (
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Hide Ticket preview</Text>
              </div>
              <div>
                <Controller
                  name="invisible_only"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      defaultChecked={false}
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
        {connector !== 'HUBSPOT' && connector !== 'SALESFORCE' && (
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Send message thread to connector</Text>
              </div>
              <div>
                <Controller
                  name="send_threads_to_connectors"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      defaultChecked={false}
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
        {connector !== 'HUBSPOT' && connector !== 'SALESFORCE' && (
          <div className="mt-3">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Send AI Summary to connector</Text>
              </div>
              <div>
                <Controller
                  name="send_ai_summary_to_connectors"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      defaultChecked={false}
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        )}
        {connector !== 'INTERCOM' && (
          <>
            <div className="mt-3">
              <div className="flex items-center justify-between">
                <div>
                  <Text strong>Show Ticket Creation Modal{'  '}</Text>
                  <Text> (using 🎫 emoji)</Text>
                </div>
                <div>
                  <Controller
                    name="do_not_show_ticket_modal"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        defaultChecked={false}
                        {...field}
                        onChange={(e) => {
                          field.onChange(!e)
                        }}
                        checked={!field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="flex items-center justify-between">
                <div>
                  <Text strong>Auto Send Request</Text>
                </div>
                <div>
                  <Controller
                    name="auto_create_ticket_from_request"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        defaultChecked={false}
                        {...field}
                        checked={field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex justify-end w-full gap-3 my-3">
          <Button
            className="text-primary border-primary/40 mt-10 mb-2 font-bold px-8"
            size="middle"
            type="default"
            disabled={saveConfigurationMutation?.isLoading}
            onClick={handleSubmit(onSave)}
            loading={saveConfigurationMutation?.isLoading}
          >
            Save Configuration
          </Button>
        </div>
      </Panel>
    </Collapse>
  )
}
