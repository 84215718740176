import * as htmlToSlackMD_ from 'html-to-slack-md'
// hack to use non-es modules with vite
const htmlToSlackMD = htmlToSlackMD_.default || htmlToSlackMD_

export const formatSlackMarkdown = (markdownText: string) => {
  const htmlText = convertSlackMDToHTML(markdownText)
  return htmlText
}

const replaceTagsWithSpace = (text: string) => {
  return text
    .replace(/<strong>\s*<\/strong>/g, ' ')
    .replace(/<em>\s*<\/em>/g, ' ')
}

const addSpaceBetweenTags = (htmlString: string) => {
  return htmlString.replace(/<\/(strong|em)><(em|strong)>/g, '</$1> <$2>')
}

export const jsonEditorParsing = (event: any, linkHref: string) => {
  const htmlString = event.target.innerHTML
  /**
   * PRE PROCESSING
   */
  // New Line Hack: adds \n after every p closing tag
  let formattedString = htmlString.replace(/<\/p>/g, '</p>\n')
  // hack for slack emoji
  formattedString = formattedString.replace(
    '<img src="https://slackmojis.com/emojis/5206-slack/download">',
    ':slack:'
  )
  // hack for reversing link and formatting tag nesting
  formattedString = reverseTagNesting(formattedString)

  // combaine adjacent tags so that MD formatting is clearer
  formattedString = combineAdjacentTags(formattedString)

  // remove strong and em tags enclosing only spaces
  formattedString = replaceTagsWithSpace(formattedString)

  // adds space between closed and open formatting tags
  formattedString = addSpaceBetweenTags(formattedString)

  let jsonString = htmlToSlackMD(formattedString)

  /**
   * POST PROCESSING
   */
  // fix incorrect bold + italics nesting
  jsonString = jsonString.replace(/\*_(.*?)_\*/g, '_*$1*_')

  // console.log({ htmlString, formattedString, jsonString })

  return jsonString
}

export const getPosition = (
  json: any,
  keyword: string
): { start: number; end: number } => {
  const jsonString = JSON.stringify(json, null, 2)
  const start = jsonString.indexOf(keyword)
  return { start, end: start + keyword.length }
}

const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/
export const validateUrl = (_: unknown, value: string, nullCheck = true) => {
  if (nullCheck) {
    if (!value) {
      return Promise.reject('Please input a valid URL!')
    }
  } else if (!value) {
    return Promise.resolve()
  }

  if (urlPattern.test(value)) {
    return Promise.resolve()
  }
  return Promise.reject(
    'Please enter a valid URL starting with http:// or https://'
  )
}

function convertSlackMDToHTML(text: string) {
  let i = 0
  let output = ''
  const stack = []

  while (i < text.length) {
    // Check for link
    if (
      text[i] === '<' &&
      text.indexOf('|', i) !== -1 &&
      text.indexOf('>', i) !== -1
    ) {
      const endLinkMarkup = text.indexOf('>', i)
      const splitLinkMarkup = text.substring(i + 1, endLinkMarkup).split('|')
      if (splitLinkMarkup.length === 2) {
        output += `<a href="${splitLinkMarkup[0]}" target="_blank" rel="noopener noreferrer">${splitLinkMarkup[1]}</a>`
        i = endLinkMarkup + 1
        continue
      }
    } else if (text[i] === '*') {
      if (stack.length > 0 && stack[stack.length - 1] === '*') {
        stack.pop()
        output += '</b>'
      } else {
        stack.push('*')
        output += '<b>'
      }
    } else if (text[i] === '_') {
      if (stack.length > 0 && stack[stack.length - 1] === '_') {
        stack.pop()
        output += '</i>'
      } else {
        stack.push('_')
        output += '<i>'
      }
    } else if (text[i] === '~') {
      if (stack.length > 0 && stack[stack.length - 1] === '~') {
        stack.pop()
        output += '</s>'
      } else {
        stack.push('~')
        output += '<s>'
      }
    } else if (text[i] === '\n') {
      output += '<br/>'
    } else {
      output += text[i]
    }
    i++
  }
  // Assuming well-formed input, the stack should be empty here.
  // If not, can add error handling or recovery
  // console.log(stack.length)

  output = output.replaceAll(
    ':slack:',
    '<img src="https://slackmojis.com/emojis/5206-slack/download" class="slack_emoji" />'
  )
  output = output.replaceAll(':email:', '✉️')

  return output
}

export function reverseTagNesting(input: string) {
  return input.replace(
    /<a(.*?)>(<\w+.*?>.*?<\/\w+>)<\/a>/g,
    function (match, attributes, innerContent) {
      const innerTagPattern = /<(\w+)(.*?)>(.*?)<\/\1>/
      const innerMatch = innerContent.match(innerTagPattern)
      if (!innerMatch) return match
      const innerTag = innerMatch[1]
      const innerAttributes = innerMatch[2]
      const content = innerMatch[3]
      return `<${innerTag}${innerAttributes}><a${attributes}>${content}</a></${innerTag}>`
    }
  )
}

// supports strong, em, and s tags
export function combineAdjacentTags(html: string) {
  return html.replace(/<\/(strong|em|s)>\s*<\1>/gi, '')
}

export const extractLinkFromText = (
  inputString: string,
  targetText: string
) => {
  const regex = new RegExp(`<([^>]+)\\|${targetText}>`)
  const match = inputString.match(regex)
  return match ? match[1] : null
}

export const countTrimmedSpaces = (text: string) => {
  const trimmedText = text.trim()
  const leadingSpaces = text.indexOf(trimmedText[0])
  const trailingSpaces = text.length - leadingSpaces - trimmedText.length

  return {
    trimmedText,
    leadingSpaces,
    trailingSpaces,
  }
}
