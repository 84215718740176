import { CancelToken } from 'axios'
import { api } from '.'

type MessagePreviewType = {
  blocks: string
  channel?: string
  username?: string
  icon_url?: string
  id?: string
  sendAsUser: boolean
  sendingUserId: string
}

type SendCampaignType = {
  _id?: string
  blocks: any
  channels?: string[]
  campaign_name?: string
  scheduled_date?: any
  icon_url?: string
  username?: string
  text?: string
  groups?: string[]
  templateName?: string
  sendAsUser: boolean
  sendingUserId: string
}

type ChannelGroup = {
  groupName: string
  channelIds: string[]
  internalChannelIds: string[]
}

type UnsendCampaignType = {
  campaignId: string
  channels: string[]
}
export const sendPreviewMessage = (data: MessagePreviewType) =>
  api
    .post('/v1/marketing-automation/send-slack-preview', data)
    .then((data) => data?.data?.data)

export const campaignDuplicate = (data: { id: string }) =>
  api
    .post('/v1/marketing-automation/campaign-duplicate', data)
    .then((data) => data?.data?.data)

export const getCampaigns = () =>
  api.get('/v1/marketing-automation/campaigns').then((data) => data.data?.data)

export const getDeletedCampaigns = () =>
  api
    .get('/v1/marketing-automation/deleted-campaigns')
    .then((data) => data.data?.data)

export const deleteCampaign = (data: { id: string }) =>
  api
    .delete('/v1/marketing-automation/campaign', { data })
    .then((data) => data?.data?.data)

export const cancelCampaign = (data: { id: string }) =>
  api
    .patch('/v1/marketing-automation/campaign-cancel', data)
    .then((data) => data?.data?.data)

export const sendCampaign = (data: SendCampaignType) =>
  api
    .post('/v1/marketing-automation/campaign', data)
    .then((data) => data.data?.data)

export const updateCampaign = (data: SendCampaignType) =>
  api
    .patch('/v1/marketing-automation/campaign', data)
    .then((data) => data.data?.data)

export const updateCampaignWithoutSending = (
  data: SendCampaignType,
  token: CancelToken
) =>
  api
    .patch('/v1/marketing-automation/campaign-without-sending', data, {
      cancelToken: token,
    })
    .then((data) => data.data?.data)

export const getCampaignDetails = (campaignId: string) =>
  api
    .get(`/v1/marketing-automation/campaign/${campaignId}`)
    .then((data) => data.data?.data)

export const getTemplates = () =>
  api.get('/v1/marketing-automation/templates').then((data) => data.data?.data)

export const getAnalytics = (campaignId: string) =>
  api
    .post('/v1/marketing-automation/analytics', { campaignId })
    .then((data) => data.data?.data)

export const getChannelGroup = () =>
  api.get('/v1/channel-group').then((data) => data.data?.data)

export const createChannelGroup = (data: ChannelGroup) =>
  api.post('/v1/channel-group', data).then((data) => data?.data?.data)

export const updateChannelGroup = (data: {
  groupName: string
  channelIds: string[]
  internalChannelIds: string[]
  _id: string
}) => api.patch('/v1/channel-group', data).then((data) => data)

export const deleteChannelGroup = (data: ChannelGroup) =>
  api.delete('/v1/channel-group', { data }).then((data) => data?.data?.data)

export const getSentChannelDetails = (campaignId: string) =>
  api
    .get(`/v1/marketing-automation/sent-channels-groups/${campaignId}`)
    .then(({ data }) => data?.data)

export const unsendCampaign = (data: UnsendCampaignType) =>
  api
    .post('/v1/marketing-automation/campaign-unsend', data)
    .then((data) => data.data?.data)
