import { useFlags } from 'launchdarkly-react-client-sdk'
import { lazy, Suspense, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { fetchCRMConfig, fetchRelationships } from '../api/accounts'
import { AppLayout, ProductTourCard } from '../components'
import ErrorBoundary from '../components/ErrorBoundary'
import { LOGIN_URL } from '../config'
import { useAuth } from '../context'
import { AddToSlackProvider } from '../context/AddToSlackProvider'
import { accountDB } from '../DB/AccountDB'
import { ConnectedAppProvider } from '../modules/ConnectedApps'
import {
  AddToSlack,
  Blocked,
  FourZeroFour,
  ProductTour,
  TooSmall,
  Welcome,
  WelcomeQuestion,
} from '../pages'
import { AuthCallback } from '../pages/AuthCallback'
import { ManageDomain } from '../pages/ManageDomains/ManageDomains'
import { Templates } from '../pages/MarketingAutomation/Templates'
import { SettingUp } from '../pages/SettingUp'
import { useAccountsStore } from '../store/accountsStore'
import { AccountType, RelationshipType } from '../types/accountsTypes'
import RedirectToExternal from './RedirectToExternal'
import useInitParagon from './useInitParagon'
import usePusherInit from './usePusherInit'
import useRedirects from './useRedirects'
import Organizations from 'pages/Organizations/Organizations'
import OrganizationLogs from 'pages/Organizations/OrganizationLogs'

const WebUsers = lazy(() => import('../pages/WebUsers/WebUsers'))
const Settings = lazy(() => import('../pages/Settings/Settings'))
const KnowledgeAssistant = lazy(() => import('../pages/KnowledgeAssistant'))
const Workflows = lazy(() => import('../pages/Workflows'))
const Accounts = lazy(() => import('../pages/Accounts/LandingTabs'))
const Analytics = lazy(() => import('../pages/WSAnalytics/Landing'))
const RequestsRevamped = lazy(() => import('../pages/Requests/Landing'))
const MarketingAnalytics = lazy(
  () => import('../pages/MarketingAutomation/MarketingAnalytics')
)
const MarketingAutomation = lazy(
  () => import('../pages/MarketingAutomation/MarketingAutomation')
)
const MAEditor = lazy(
  () => import('../pages/MarketingAutomation/NewTemplateEditor/MAEditor')
)
const SentTemplateEditor = lazy(
  () => import('../modules/MarketingAutomation/SentTemplateEditor')
)

const crmRoutes = [
  '/requests',
  '/accounts',
  '/marketing-automation',
  '/analytics',
  '/settings',
]
const Init = () => {
  usePusherInit()
  return null
}

const AppRoutes = () => {
  useRedirects()
  useInitParagon()
  const flag = useFlags()
  const { pathname } = useLocation()
  const dispatch = useAccountsStore((state) => state.dispatch)
  const isNotCustomer = !useAuth().user?.is_customer
  const { user } = useAuth()
  const isGodUser = user?.godMode || false

  useEffect(() => {
    if (isNotCustomer && crmRoutes.some((route) => pathname.includes(route))) {
      fetchRelationships()
        .then((data: RelationshipType[]) => {
          const normalized_data = data.map((element: RelationshipType) => ({
            ...element,
            crm_values: {
              ...element.crm_values,
              type: element.crm_values?.type?.toLowerCase() as AccountType,
            },
          }))
          accountDB.relationship.bulkPut(normalized_data)
        })
        .catch((err) => console.log(err))

      fetchCRMConfig()
        .then((data) =>
          dispatch({
            type: 'INIT_CRM_CONFIG_DATA',
            payload: { crmConfig: data },
          })
        )
        .catch((err) => console.log(err))
    }
  }, [pathname, isNotCustomer])

  return (
    <ErrorBoundary>
      {flag.requestCustomStatus === false && isNotCustomer ? <Init /> : null}
      <AppLayout>
        <ConnectedAppProvider>
          <AddToSlackProvider>
            <Suspense>
              <Routes>
                {/* Public routes */}
                <Route
                  path="/"
                  element={<RedirectToExternal url={LOGIN_URL} />}
                />
                <Route path="/login" element={<Welcome />} />
                <Route
                  path="/logout"
                  element={<RedirectToExternal url={LOGIN_URL} />}
                />
                <Route path="/blocked" element={<Blocked />} />
                <Route path="/too_small" element={<TooSmall />} />
                <Route path="/slack/callback" element={<AuthCallback />} />
                {/* Private routes */}
                <Route path="/setting-up" element={<SettingUp />} />
                <Route path="/welcome" element={<WelcomeQuestion />} />
                <Route path="/product-tour" element={<ProductTour />} />
                <Route
                  path="/product-tour/:productTourKey"
                  element={<ProductTourCard />}
                />
                <Route path="/add-to-slack" element={<AddToSlack />} />
                <Route path="/web-users" element={<WebUsers />} />
                {flag.accounts && (
                  <Route path="/accounts" element={<Accounts />} />
                )}
                <Route path="/requests" element={<RequestsRevamped />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route
                  path="/marketing-automation"
                  element={<MarketingAutomation />}
                />
                <Route
                  path="/marketing-automation/:campaignId"
                  element={<MarketingAnalytics />}
                />
                <Route
                  path="/marketing-automation/editor/:campaignId"
                  element={<MAEditor />}
                />
                <Route
                  path="/marketing-automation/sent/:campaignId"
                  element={<SentTemplateEditor />}
                />
                <Route
                  path="/marketing-automation/templateList"
                  element={<Templates />}
                />
                <Route
                  path="/knowledge-assistant/*"
                  element={<KnowledgeAssistant />}
                />
                <Route path="/workflows/*" element={<Workflows />} />
                {/* Catch all */}
                <Route path="/domains" element={<ManageDomain />} />
                <Route
                  path="/internal/organizations"
                  element={isGodUser ? <Organizations /> : <FourZeroFour />}
                />
                <Route
                  path="/impersonation/logs"
                  element={isGodUser ? <OrganizationLogs /> : <FourZeroFour />}
                />
                <Route path="*" element={<FourZeroFour />} />
              </Routes>
            </Suspense>
          </AddToSlackProvider>
        </ConnectedAppProvider>
      </AppLayout>
    </ErrorBoundary>
  )
}

export default AppRoutes
