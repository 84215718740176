import { useCallback } from 'react'
import { useAuth } from '../context'
import { emptyObject } from '../utils/empty'

const useEvents = () => {
  const { user } = useAuth()

  const track = useCallback(
    (event: string, properties?: Record<string, any>) => {
      ;(window as any).analytics.track(
        event,
        {
          userId: user?.id,
          teamId: user?.team_id,
          userEmail: user?.email,
          teamName: user?.team_name,
          ...(properties || emptyObject),
        },
        {
          context: {
            page: {
              referrer: document.referrer.substring(0, 200),
              search: window.location.search.substring(0, 200),
              url: window.location.href.substring(0, 200),
            },
          },
        }
      )
    },
    [user?.email, user?.id, user?.team_id, user?.team_name]
  )

  return track
}

export default useEvents
