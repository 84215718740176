import { Button, Collapse, Input, message, Select } from 'antd'
import cls from 'classnames'
import { debounce } from 'lodash'
import { CaretDown, CaretUp, MagnifyingGlass } from 'phosphor-react'
import { useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import {
  fetchIntegrationChannels,
  fetchSalesforceAccounts,
  saveSalesforceAccounts,
} from '../../../api/integrations'
import { useConnectedApp } from '../../ConnectedApps'
import {
  IntegrationEligibleChannelsType,
  SalesforceAccountMappingType,
} from '../definitions'
import { useSalesforce } from './SalesforceProvider'

const { Panel } = Collapse

export const AccountConfiguration = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isEdition, setSelectedIntegration } = useConnectedApp()
  const { connectionPassed, isConfigureOpen, setIsConfigureOpen } =
    useSalesforce()

  const [search, setSearch] = useState<string>('')
  const [filter, setFilter] = useState<string>('unmapped')
  const [searchChannels, setSearchChannels] = useState<string>('')

  // Fetching Salesforce Accounts
  const {
    data: accounts,
    isLoading,
    isFetching,
  } = useQuery(
    ['fetchSalesforceAccounts', search],
    () => fetchSalesforceAccounts(search),
    {
      enabled: isConfigureOpen,
    }
  )

  // Fetching Integration channels
  const { data: integrationChannels } = useQuery(
    ['integration-channels', { integration: 'salesforce' }],
    () =>
      fetchIntegrationChannels({
        page: 0,
        search: '',
        integration: 'salesforce',
      })
  )

  // Filtering channels function
  const memorizeFilteredChannels = (
    channels: IntegrationEligibleChannelsType[]
  ) => {
    let filteredChannels = channels.filter(
      (ch: IntegrationEligibleChannelsType) => {
        if (filter === 'mapped') return ch.isMapped
        return !ch.isMapped
      }
    )

    if (searchChannels) {
      filteredChannels = filteredChannels.filter((ch) =>
        ch.name.includes(searchChannels)
      )
    }

    return filteredChannels
  }

  // Memorizing Filtering channels
  const memorizedFilteredChannels = useMemo(
    () => memorizeFilteredChannels(integrationChannels?.channels || []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [integrationChannels, filter, searchChannels]
  )

  // Creating Salesforce Options
  const getAccounts = (accounts: SalesforceAccountMappingType[]) =>
    accounts.map((account) => ({
      label: account.Name,
      value: account.Id,
    }))

  // Memorizing Salesforce Options
  const salesforceAccounts = useMemo(
    () => getAccounts(accounts || []),
    [accounts]
  )

  // Save Salesforce account mapping
  const mapSalesforceAccountMutation = useMutation(saveSalesforceAccounts, {
    onSuccess: () => {
      message.success('Salesforce Account configured')
      queryClient.invalidateQueries([
        'integration-channels',
        { integration: 'salesforce' },
      ])
    },
    onError: (error) => {
      console.error(error)
      message.error('Something went wrong')
    },
  })

  return (
    <Collapse
      ghost
      accordion
      expandIconPosition="end"
      activeKey={isConfigureOpen ? '1' : '0'}
      className="border border-solid border-brand-border"
      collapsible={!isEdition && !connectionPassed ? 'disabled' : 'header'}
      expandIcon={() => {
        // if (isEdition)
        //   return (
        //     <InfoCircleOutlined data-intercom-target="Settings Integrations Salesforce Account Mapping" />
        //   )
        if (!isConfigureOpen)
          return (
            <CaretDown
              size={18}
              color={!isEdition && !connectionPassed ? '#9096A4' : '#7A00FF'}
            />
          )
        return <CaretUp size={18} color="#7A00FF" />
      }}
      onChange={() => {
        setIsConfigureOpen((p) => !p)
      }}
    >
      <Panel
        header={
          <div className="flex justify-between flex-col">
            <h4
              className={cls(
                'm-0 ml-3 font-semibold text-sm 3xl:text-[length:var(--sm)]',
                !connectionPassed && !isEdition && 'text-muted'
              )}
            >
              Account Configuration
            </h4>
            {isConfigureOpen && (
              <p className="text-grey font-medium mb-0 ml-3">
                Match each channel to its relevant salesforce account
              </p>
            )}
          </div>
        }
        key="1"
      >
        <div>
          {/* Search and filter */}
          <div className="flex items-center justify-between mb-6">
            {/* Search */}
            <Input
              name="search"
              id="search"
              className="w-fit"
              placeholder="Search channel..."
              prefix={<MagnifyingGlass />}
              onChange={({ target: { value } }) => {
                setSearchChannels(value)
              }}
            />
            {/* Filter */}
            <Select
              value={filter}
              onChange={(value) => setFilter(value)}
              className="w-fit mr-4"
              showSearch={false}
              dropdownMatchSelectWidth
              showArrow
            >
              {[
                {
                  key: '1',
                  value: 'mapped',
                  label: <span>Mapped Channels</span>,
                },
                {
                  key: '2',
                  value: 'unmapped',
                  label: <span>Unmapped Channels</span>,
                },
              ].map((option) => (
                <Select.Option value={option.value} key={option.key}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </div>
          {/* No Data component */}
          {integrationChannels?.channels.length === 0 && (
            <div className="flex flex-col justify-center items-center h-64 scroll-mr-4">
              <p className="text-brand-border font-medium mb-0">
                No channels added to Thena
              </p>
              <Button
                type="link"
                onClick={() => {
                  setSelectedIntegration(null)
                  navigate('/settings?tab=channels')
                }}
              >
                Add here
              </Button>
            </div>
          )}
          {memorizedFilteredChannels.length === 0 &&
            integrationChannels?.channels.length !== 0 && (
              <div className="flex justify-center items-center h-64 scroll-mr-4">
                {Boolean(search) && (
                  <p className="text-brand-border font-medium mb-0">
                    No channels found
                  </p>
                )}
                {filter === 'mapped' && (
                  <p className="text-brand-border font-medium mb-0">
                    No channels are mapped yet
                  </p>
                )}
                {filter === 'unmapped' && (
                  <p className="text-brand-border font-medium mb-0">
                    All channels are mapped
                  </p>
                )}
              </div>
            )}
          {/* Channel-Salesforce Account Mapping */}
          {memorizedFilteredChannels.length > 0 && (
            <div className="overflow-y-auto space-y-2 h-64 scroll-mr-4">
              {memorizedFilteredChannels.map(
                (channel: IntegrationEligibleChannelsType) => (
                  <div
                    className="grid grid-cols-6 items-center space-y-2"
                    key={channel._id}
                  >
                    <p className="col-span-2 text-sm font-medium mb-0">
                      # {channel.name_normalized}
                    </p>
                    <div className="col-span-2" />

                    <div className="col-span-2 mr-4">
                      <Select
                        allowClear
                        showSearch
                        className="w-full"
                        filterOption={false}
                        dropdownMatchSelectWidth
                        placeholder="Account Name"
                        onBlur={() => {
                          setSearch('')
                        }}
                        value={
                          channel.salesforce || channel.accountName
                            ? {
                                value: channel.salesforce,
                                label: channel.accountName,
                              }
                            : null
                        }
                        loading={isLoading || isFetching}
                        //suffixIcon={<CaretDown size={18} color="#7A00FF" />}
                        onChange={(value, valueObj) => {
                          const valueObjWithType = valueObj as {
                            value: string
                            label: string
                          }
                          mapSalesforceAccountMutation.mutate({
                            channelIds: [channel.id],
                            accountId: valueObjWithType?.value || null,
                            accountName: valueObjWithType?.label || null,
                          })
                        }}
                        onSearch={debounce((value) => setSearch(value), 300)}
                        options={salesforceAccounts.map(({ value, label }) => ({
                          value,
                          label,
                        }))}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          )}
          {/* Done Overlay */}
          <div className="bg-white relative bottom-0 border-0 border-t border-brand-border rounded-b-lg border-solid p-3 -mx-4 -mb-3 z-10">
            <div className="flex flex-row-reverse">
              <Button
                className="text-primary border-primary/40 mr-5 font-bold px-8"
                size="middle"
                type="default"
                onClick={() => {
                  setIsConfigureOpen(false)
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </Panel>
    </Collapse>
  )
}
