import { Button, Popover } from 'antd'
import styled from 'styled-components'
import AddNewBlock from '../AddNewBlock'
import { DeleteBlock } from '../Commons'
import { formatSlackMarkdown } from '../utils'

const Wrapper = styled.div`
  position: relative;
  padding-bottom: 16px;
  .delete-icon {
    top: 8px;
    right: 0;
    width: 24px;
    height: 24px;
    display: none;
    box-shadow: 0 0 4px 0 var(--color-red-3);
  }
  .render-plus-banner {
    display: none;
    margin: 0;
    width: 90%;
    bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  :hover {
    .delete-icon {
      display: flex;
    }
    .render-plus-banner {
      display: flex;
    }
  }
`

const BlockVideo = ({
  type,
  title,
  title_url,
  description,
  video_url,
  alt_text,
  thumbnail_url,
  author_name,
  provider_name,
  provider_icon_url,
  size = 'big',
  index,
  blockType,
  onClick,
  isReadOnly,
}: any) => {
  const Provider = () => {
    if (provider_name) {
      return (
        <span>
          {provider_icon_url && (
            <img
              style={{
                width: '20px',
                height: '20px',
                background: 'transparent',
                boxShadow: 'none',
                marginBottom: '2px',
              }}
              className="slack_img_small"
              src={provider_icon_url}
              alt="provider icon url"
            />
          )}{' '}
          <strong className="font-black">{provider_name}</strong>
        </span>
      )
    }
    return <span />
  }

  return (
    <Wrapper>
      {!isReadOnly && <DeleteBlock index={index} />}
      {!isReadOnly && <AddNewBlock index={index} />}
      {author_name && (
        <div className="slack_video_body">
          <Provider />| {author_name}
        </div>
      )}
      {Boolean(description?.text) && (
        <div
          className="slack_section_text"
          dangerouslySetInnerHTML={{
            __html: formatSlackMarkdown(
              typeof description.text === 'string' ? description.text : ''
            ),
          }}
        />
      )}
      <div className="w-11/11">
        {!isReadOnly ? (
          <Popover
            placement="right"
            content={
              <div className="flex justify-end w-full">
                <Button type="link" className="font-medium" onClick={onClick}>
                  Edit
                </Button>
              </div>
            }
            title={null}
          >
            <iframe
              className={`slack_img_${size}`}
              style={{ width: '100%', border: 'none', borderRadius: 0 }}
              src={video_url}
              title={alt_text}
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Popover>
        ) : (
          <iframe
            className={`slack_img_${size}`}
            style={{ width: '100%', border: 'none', borderRadius: 0 }}
            src={video_url}
            title={alt_text}
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    </Wrapper>
  )
}

export default BlockVideo
