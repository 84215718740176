import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  createCluster as createClusterApi,
  fetchClusters,
  updateClusters as updateClustersApi,
} from '../api/clusters'
import { ListClusterType } from '../types'

const useClusters = ({
  onCreateSuccess,
  onCreateError,
  onUpdateSuccess,
  onUpdateError,
  assistantId,
}: {
  onCreateSuccess?: () => void
  onCreateError?: (error: Error | unknown) => void
  onUpdateSuccess?: () => void
  onUpdateError?: (error: Error | unknown) => void
  assistantId: string
}) => {
  const queryClient = useQueryClient()
  const { isLoading, error, data } = useQuery(
    ['clusterList', assistantId],
    () => fetchClusters(assistantId)
  )

  const createMutation = useMutation(createClusterApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('clusterList')
      onCreateSuccess && onCreateSuccess()
    },
    onError: (error) => {
      onCreateError && onCreateError(error)
    },
  })

  const createCluster = (newItemData: ListClusterType) => {
    createMutation.mutate({ assistantId, cluster: newItemData })
  }

  const updateMutation = useMutation(updateClustersApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('clusterList')
      onUpdateSuccess && onUpdateSuccess()
    },
    onError: (error) => {
      onUpdateError && onUpdateError(error)
    },
  })
  const updateCluster = (clusterId: string, newItemData: ListClusterType) => {
    updateMutation.mutate({ assistantId, clusterId, cluster: newItemData })
  }

  return {
    isLoading,
    error,
    data,
    createCluster,
    updateCluster,
    createStatus: createMutation,
    updateStatus: updateMutation,
  }
}

export default useClusters
