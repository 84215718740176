import { Drawer } from 'antd'
import { useLiveQuery } from 'dexie-react-hooks'
import { useSearchParams } from 'react-router-dom'
import { accountDB } from '../../../DB/AccountDB'
import { useAccountsStore } from '../../../store/accountsStore'
import Content from './Content'
import Header from './Header'

const AccountDrawer = () => {
  const drawer = useAccountsStore((state) => state.drawer)
  const dispatch = useAccountsStore((state) => state.dispatch)
  const [searchParams, setSearchParams] = useSearchParams()

  const resetModal = () => {
    dispatch({
      type: 'SET_DRAWER',
      payload: {
        drawer: null,
      },
    })
  }

  const relId = searchParams.get('relationshipId')

  useLiveQuery(async () => {
    if (relId) {
      const data = await accountDB.relationship.get(relId)

      if (data) {
        dispatch({
          type: 'SET_DRAWER',
          payload: {
            drawer: {
              type: 'ACCOUNT_DRAWER',
              data: {
                open: true,
                record: data,
              },
            },
          },
        })
      }
    }
  }, [relId])

  return (
    <Drawer
      width="80vw"
      open={drawer?.data?.open}
      placement="right"
      onClose={resetModal}
      className="v2-drawer"
      bodyStyle={{ padding: 0, overflowY: 'hidden' }}
      headerStyle={{ backgroundColor: 'var(--color-gray-9)' }}
      title={<Header />}
    >
      <Content />
    </Drawer>
  )
}

export default AccountDrawer
