import Dexie, { Table } from 'dexie'

export interface Conversation {
  data: any
  _id: string
}

export class AccountDrawerDB extends Dexie {
  summary!: Table<Conversation>

  constructor() {
    super('accountDrawerDB')
    this.version(1)
      .stores({
        summary: '_id',
      })
      .upgrade(() => {
        this.on('versionchange', function (event: any) {
          console.log('AccountDrawerDb VersionChange', event)
          window.location.reload()
        })
      })
  }
}

export const accountDrawerDB = new AccountDrawerDB()
