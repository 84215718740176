export const welcomeInfo = {
  title: 'Welcome to Thena',
  description: 'To let us know who you are, sign in with Slack',
  buttonText: 'Sign in with Slack',
  links: {
    terms: {
      text: 'Terms of Service',
      url: 'https://www.thena.ai/terms',
    },
    privacy: {
      text: 'Privacy Policy',
      url: 'https://www.thena.ai/privacy-policy',
    },
  },
}
