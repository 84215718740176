import { Col, Row, message } from 'antd'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import { fetchAssistants } from '../../../../api/assistant'
import { fetchUser } from '../../../../api/auth'
import FastSpinner from '../../../../components/FastSpinner'
import useChat from '../../../../hooks/useChat'
import useQuestion from '../../../../hooks/useQuestion'
import {
  ChatContent,
  ChatList,
  QuestionInput,
} from '../../components/Playground'

export const Playground = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const currChatId = searchParams.get('chatId') || ''
  const { id = '' } = useParams()
  const [context, setContext] = useState('')
  const [isNewResponse, setNewResponse] = useState(false)
  const [newChatId, setNewChatId] = useState('')
  const [firstQuestion, setFirstQuestion] = useState('')
  const { isLoading, data: assistantData = [] } = useQuery(
    'assistantsList',
    fetchAssistants
  )
  let assistantName = ''
  if (!isLoading && assistantData) {
    const assistant = assistantData.find((assistant) => assistant._id === id)
    assistantName = assistant ? assistant.name : ''
  }
  const onCreateSuccess = (newConversationId: string, question: string) => {
    navigate(`?chatId=${newConversationId}`)
    setNewChatId(newConversationId)
    setFirstQuestion(question)
  }
  const onCreateError = () => {
    message.error('Something went wrong')
  }
  const onFeedbackSuccess = () => {
    message.success('Thank you for your feedback')
  }
  const onFeedbackError = () => {
    message.error('Something went wrong. Please try again')
  }
  const {
    conversationList = [],
    listLoading,
    conversationDetails = [],
    detailLoading,
    refetchDetails,
    refetchingState,
    createConversation,
    createStatus,
    addFeedback,
  } = useChat({
    onCreateSuccess,
    onCreateError,
    onFeedbackSuccess,
    onFeedbackError,
    assistantId: id,
    conversationId: currChatId,
  })

  const { askQuestion, loadingStatus } = useQuestion({
    onSuccess: async () => {
      if (firstQuestion !== '') {
        setFirstQuestion('')
      }
      await refetchDetails()
      setNewResponse(true)
    },
    onError: () => {
      message.error('Something went wrong')
      setContext('Network Error')
    },
    assistantId: id,
    conversationId: currChatId !== '' ? currChatId : newChatId,
  })

  const { data: userInfo, isLoading: userInfoLoadingStatus } = useQuery(
    ['userInfo'],
    fetchUser
  )

  const listLoadingStatus =
    createStatus.isLoading ||
    listLoading ||
    loadingStatus ||
    refetchingState ||
    isNewResponse

  const contentLoadingStatus =
    loadingStatus || refetchingState || createStatus.isLoading

  const inputLoadingStatus =
    loadingStatus || isNewResponse || createStatus.isLoading

  useEffect(() => {
    if (newChatId !== '') {
      askQuestion(firstQuestion)
    }
  }, [newChatId])

  return (
    <Wrapper>
      <Row className="content-row">
        <Col span={4} className="conversation-list">
          <ChatList
            currChatId={currChatId}
            chatList={conversationList}
            setContext={setContext}
            loadingStatus={listLoadingStatus}
          />
        </Col>
        <Col span={20} style={{ display: 'flex', justifyContent: 'center' }}>
          <div className="conversation-detail">
            {(detailLoading && firstQuestion === '') ||
            userInfoLoadingStatus ? (
              <div className="flex justify-center items-center h-96">
                <FastSpinner />
              </div>
            ) : (
              <div>
                <ChatContent
                  currChatId={currChatId}
                  chatHistory={conversationDetails}
                  context={context}
                  setContext={setContext}
                  createNewChat={createConversation}
                  loadingStatus={contentLoadingStatus}
                  isNewResponse={isNewResponse}
                  setNewResponse={setNewResponse}
                  addFeedback={addFeedback}
                  userAvatarUrl={userInfo.user_image}
                />
                <QuestionInput
                  assistantId={id}
                  currChatId={currChatId}
                  assistantName={assistantName}
                  loadingStatus={inputLoadingStatus}
                  setContext={setContext}
                  askQuestion={askQuestion}
                  createNewChat={createConversation}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .content-row {
    font-size: 16px;
  }
  .conversation-list {
    background-color: var(--color-gray-bg);
    height: calc(100vh - 146px);
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .conversation-detail {
    width: 834px;
  }
`
