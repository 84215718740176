import { lazy, Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import CustomerLayout from '../components/common/layout/CustomerLayout'
import ErrorBoundary from '../components/ErrorBoundary'
import { LOGIN_URL } from '../config'
import { Blocked, FourZeroFour, TooSmall } from '../pages'
import { AuthCallback } from '../pages/AuthCallback'
import RedirectToExternal from './RedirectToExternal'
import { usePusherInitForCustomerPortal } from './usePusherInit'

const CustomerPortalSettings = lazy(
  () => import('../pages/Settings/CustomerPortalSettings')
)
const CustomerPortalRequests = lazy(
  () => import('../pages/CustomerPortal/Requests/Requests')
)

const Init = () => {
  usePusherInitForCustomerPortal()
  return null
}

const CustomerPortalRoutes = () => {
  // to remove CommandBar
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.keyCode === 75) {
        window.CommandBar.shutdown()
        event.preventDefault()
        event.stopPropagation()
      }
    }
    document.addEventListener('keydown', handleKeyDown, true)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <ErrorBoundary>
      <Init />
      <CustomerLayout>
        <Suspense>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<RedirectToExternal url={LOGIN_URL} />} />
            <Route
              path="/logout"
              element={<RedirectToExternal url={LOGIN_URL} />}
            />
            <Route path="/blocked" element={<Blocked />} />
            <Route path="/too_small" element={<TooSmall />} />
            <Route path="/slack/callback" element={<AuthCallback />} />
            {/* Private routes */}
            <Route path="/requests" element={<CustomerPortalRequests />} />
            <Route path="/settings" element={<CustomerPortalSettings />} />
            {/* Catch all */}
            <Route path="*" element={<FourZeroFour />} />
          </Routes>
        </Suspense>
      </CustomerLayout>
    </ErrorBoundary>
  )
}

export default CustomerPortalRoutes
