export const convertHtmlBlockquotesToSlack = (html: string) => {
  const blockquoteRegex = /<blockquote>([\s\S]*?)<\/blockquote>/g
  const lines = html.split('<br>')

  let slackMessage = ''

  lines.forEach((line) => {
    const match = blockquoteRegex.exec(line)

    if (match) {
      const blockquoteContent = match[1].trim().replace(/&gt;/g, '>')
      slackMessage += `>${blockquoteContent}\n`
    } else {
      slackMessage += line.trim().replace(/&gt;/g, '>') + '\n'
    }
  })

  return slackMessage.trim()
}
