import { create } from 'zustand'
import { devtools, persist, redux } from 'zustand/middleware'

const layoutReducer = (state: any, action: any): any => {
  const { type, payload } = action

  switch (type) {
    case 'SET_NAVBAR_COLLAPSED':
      return {
        ...state,
        collapsed: payload.collapsed,
      }

    default:
      return state
  }
}

const layoutInitalState = {
  collapsed: Boolean(window.localStorage.getItem('navbarCollapsed')) || false,
}

const useLayoutStore = create(
  persist(
    devtools(redux(layoutReducer, layoutInitalState), {
      name: 'useLayoutStore',
    }),
    {
      name: 'useLayoutStore',
      version: 0.1,
    }
  )
)

export { useLayoutStore }
