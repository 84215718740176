import { Result } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useAuth } from '../../context'

export const Blocked = () => {
  const { user } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.isWebLoginBlocked === false) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isWebLoginBlocked])

  return (
    <div>
      <Result
        icon={<img src="/images/broken-owl.png" alt="sad waiting owl icon" />}
        title="There seems to be an issue with your account"
        subTitle="Please reach out to support@thena.ai to unblock your account"
      />
    </div>
  )
}
