import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Button, Input, Row } from 'antd'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import EditSVG from '../../../../images/icons/editPen.svg'
import { ConversationListType } from '../../../../types/playgroundTypes'
import { RemoveChatModal } from '../Modals/RemoveChatModal'

type ChatListProps = {
  currChatId: string
  loadingStatus?: boolean
  chatList: ConversationListType[]
  setContext: (data: string) => void
}

export const ChatList = ({
  currChatId,
  chatList,
  loadingStatus,
  setContext,
}: ChatListProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [editMode, setEditMode] = useState(false)
  const [selectedChatId, setSelectedChatId] = useState(currChatId)
  const [isOpen, setOpen] = useState(false)
  const showList = [...chatList]
  showList.reverse()

  const [chatTitle, setChatTitle] = useState(
    chatList.find((chat) => chat._id === currChatId)?.name || ''
  )

  const openChatHistory = (chat: ConversationListType) => {
    navigate(`?chatId=${chat._id}`)
    setContext('')
  }

  const newChat = () => {
    const { pathname } = location
    navigate(pathname)
    setContext('')
  }

  const onEdit = (editChatId: string) => {
    setEditMode(true)
    setSelectedChatId(editChatId)
    setChatTitle(chatList.find((chat) => chat._id === editChatId)?.name || '')
  }

  return (
    <Wrapper className={loadingStatus ? 'disabled-div' : ''}>
      <Button onClick={newChat} className="custom-button" size="large">
        <Row align="middle">
          <span className="new-chat-btn">New Chat</span>
          <img src={EditSVG} alt="edit" />
        </Row>
      </Button>
      {showList.length > 0 && <div className="chatlist-header">RECENT</div>}
      {showList.map((chat, index) => (
        <div
          className="chat-title-and-icons"
          style={
            chat._id === currChatId
              ? { backgroundColor: 'var(--color-gray-9)' }
              : {}
          }
          key={index}
        >
          {editMode && chat._id === selectedChatId ? (
            <Input
              value={chat.name}
              onChange={() => {}}
              autoFocus={true}
              size="small"
            />
          ) : (
            <div
              className="chat-title-item"
              onClick={() => openChatHistory(chat)}
            >
              {chat.name}
            </div>
          )}
          {editMode && chat._id === selectedChatId ? (
            <div className="edit-action-icons">
              <CheckOutlined
                className="save-btn"
                onClick={() => setEditMode(false)}
              />
              <CloseOutlined
                style={{ fontSize: 16 }}
                onClick={() => setEditMode(false)}
              />
            </div>
          ) : (
            <div className="action-icons">
              <EditOutlined
                style={{ marginRight: 8, fontSize: 16 }}
                onClick={() => onEdit(chat._id)}
              />
              <DeleteOutlined
                style={{ fontSize: 16 }}
                onClick={() => {
                  setOpen(true)
                  setChatTitle(chat.name)
                  setSelectedChatId(chat._id)
                }}
              />
            </div>
          )}
        </div>
      ))}
      <RemoveChatModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        chatTitle={chatTitle}
        onSubmit={() => setOpen(false)}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 20px 24px 0px 20px;
  &.disabled-div {
    pointer-events: none;
    opacity: 0.4;
  }
  .custom-button {
    background: var(--color-purple-1);
    border-color: var(--color-purple-1);
    color: var(--color-white);
    margin-left: 8px;
    &:hover {
      background-color: var(--color-purple-6);
      border-color: var(--color-purple-6);
    }
  }
  .new-chat-btn {
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    margin-right: 8px;
    padding-top: 2px;
  }
  .chatlist-header {
    margin: 16px 8px;
    font-family: Lato;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: var(--color-gray-6);
  }
  .chat-title-and-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: var(--color-gray-9);
    }
    &:hover .action-icons {
      display: flex;
      margin-left: 8px;
    }
    .chat-title-item {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Lato;
      font-size: 14px;
      line-height: 20px;
      color: var(--color-black-1);
    }
    .action-icons {
      display: none;
    }
    .edit-action-icons {
      display: flex;
      margin-left: 8px;
    }
    .edit-input {
      flex-grow: 1;
    }
  }

  .save-btn {
    margin-right: 8px;
    font-size: 16px;
    color: var(--color-green-4);
  }
`
