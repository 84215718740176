import { create } from 'zustand'
import { devtools, persist, redux } from 'zustand/middleware'

const kanbanReducer = (state: any, action: any): any => {
  const { type, payload } = action

  switch (type) {
    case 'SET_REQUEST_IDS':
      return {
        ...state,
        requestIds: payload.requestIdsArray,
      }

    case 'SET_DATE_RANGE_TYPE':
      return {
        ...state,
        dateRangeType: payload.dateRangeType,
      }

    default:
      return state
  }
}

const kanbanInitalState = {
  requestIds: null,
  dateRangeType: '30_DAYS',
}

const useKanbanStore = create(
  persist(
    devtools(redux(kanbanReducer, kanbanInitalState), {
      name: 'useKanbanStore',
    }),
    {
      name: 'useKanbanStore',
      version: 0.1,
    }
  )
)

export { useKanbanStore }
