import { Result, Spin } from 'antd'

export const TestingConnectionLoader = () => {
  return (
    <Result
      icon={<Spin size="large" />}
      title="Testing connection..."
      subTitle="Please don’t leave the site or refresh till it’s done."
    />
  )
}
