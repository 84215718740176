import { LinkOutlined } from '@ant-design/icons'
import { Button, Input, Row } from 'antd'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { PrimaryBtn } from '../StyledComponent'

export const LinkModal = ({
  isOpen,
  onClose,
  onSetLink,
  defaultValue,
  position,
}: {
  isOpen: boolean
  onClose: () => void
  onSetLink: (value: string) => void
  defaultValue: string
  position: { top: number; left: number }
}) => {
  const [url, setUrl] = useState(defaultValue)
  const handleSubmit = () => {
    onSetLink(url)
  }
  const linkModalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!linkModalRef.current?.contains(e.target as Node)) {
        onClose()
      }
    }
    document.body.addEventListener('mousedown', handler)
    return () => {
      document.body.removeEventListener('mousedown', handler)
    }
  }, [])

  if (!isOpen) {
    return null
  }

  return (
    <Wrapper
      style={{
        top: `${position.top + 40}px`,
        left: `${position.left - 52}px`,
      }}
      ref={linkModalRef}
    >
      <Row className="url-input" align="middle" justify="space-between">
        <Input
          value={url}
          placeholder="Enter URL"
          prefix={<LinkOutlined />}
          onChange={(e) => setUrl(e.target.value)}
        />
      </Row>
      <Row justify="center">
        <PrimaryBtn onClick={handleSubmit}>Set Link</PrimaryBtn>
        <Button onClick={onClose} className="cancel-btn">
          Cancel
        </Button>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  border: 1px solid var(--color-gray-3);
  padding: 8px;
  border-radius: 8px;
  background: var(--color-white);
  display: flex;
  width: fit-content;
  z-index: 999;
  .url-input {
    margin-right: 8px;
  }
  .cancel-btn {
    margin-left: 8px;
  }
`
