import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { fetchChecklist } from '../api/misc'
import { OnboardingChannelType } from '../modules/channels/definitions'
import { OnboardingChecklistType } from '../modules/onboarding/definitions'

type ChannelTypeType = 'internal' | 'external'

interface OnboardingContextType {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  channelType: ChannelTypeType[]
  setChannelType: React.Dispatch<React.SetStateAction<ChannelTypeType[]>>
  allChannels: OnboardingChannelType[]
  setAllChannels: React.Dispatch<React.SetStateAction<OnboardingChannelType[]>>
  addedChannels: OnboardingChannelType[]
  setAddedChannels: React.Dispatch<
    React.SetStateAction<OnboardingChannelType[]>
  >
  percent: number
  setPercent: React.Dispatch<React.SetStateAction<number>>
  checklist: OnboardingChecklistType | undefined
}

const OnboardingContext = React.createContext<OnboardingContextType>({
  search: '',
  setSearch: () => null,
  channelType: [],
  setChannelType: () => null,
  allChannels: [],
  setAllChannels: () => null,
  addedChannels: [],
  setAddedChannels: () => null,
  percent: 0,
  setPercent: () => null,
  checklist: undefined,
})

export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // State
  const [search, setSearch] = useState('')
  const [channelType, setChannelType] = useState<ChannelTypeType[]>([])
  const [allChannels, setAllChannels] = useState<OnboardingChannelType[]>([])
  const [addedChannels, setAddedChannels] = useState<OnboardingChannelType[]>(
    []
  )
  const [percent, setPercent] = useState(0)
  const { data: checklist } = useQuery<OnboardingChecklistType>(
    ['onboardingChecklist'],
    fetchChecklist
  )

  useEffect(() => {
    let completedTask = 0
    if (checklist?.addedCustomerChannel === true) {
      completedTask += 1
    }
    if (checklist?.firstIntegration === true) {
      completedTask += 1
    }
    if (checklist?.sla === true) {
      completedTask += 1
    }
    if (
      typeof checklist?.suggestedRequestNotifications !== 'undefined' &&
      checklist?.suggestedRequestNotifications !== null
    ) {
      completedTask += 1
    }
    const completedPercent = (100 * completedTask) / 4
    setPercent(Math.ceil(completedPercent))
  }, [checklist])
  return (
    <OnboardingContext.Provider
      value={{
        search,
        setSearch,
        channelType,
        setChannelType,
        addedChannels,
        setAddedChannels,
        allChannels,
        setAllChannels,
        percent,
        setPercent,
        checklist,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  )
}

export const useOnboarding = () => {
  return useContext(OnboardingContext)
}
