import styled from 'styled-components'

export const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  .ant-popover-inner-content {
    padding: 0 !important;
  }
  .ant-popover.ant-popover-placement-rightTop {
    left: 90px !important;
  }
  margin-left: 6px;
`

export const IconWrapper = styled.div<{
  $isActive: boolean
}>`
  width: 50px;
  height: 38px;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  img {
    object-fit: contain;
  }

  ${({ $isActive }) => {
    if ($isActive) {
      return `
      background-color: #4D456A;
      `
    }
  }}
`

export const ListItem = styled.div<{ $isSelected: boolean }>`
  padding: 8px 4px 8px 4px;
  margin-bottom: 3px;
  border-radius: 4px;
  color: #bbbbc0 !important;
  :hover {
    background-color: #4d456a !important;
    color: white !important;
  }
  ${({ $isSelected }) => {
    if ($isSelected) {
      return `
      background-color:#4D456A !important; 
      `
    }
  }}
`
