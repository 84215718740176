import { Avatar, Checkbox, Input, Typography } from 'antd'
import { MagnifyingGlass } from 'phosphor-react'
import VirtualList from 'rc-virtual-list'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { MultiSelect } from '../../components/MultiSelect/MultiSelect'
import AssignFilterIcon from '../../images/icons/assignFilterIcon.svg'
import { truncate } from '../../utils/textUtils'
const { Text } = Typography

const ItemWrapper = styled.div`
  margin: 0 !important;
  background-color: unset !important;
  :hover {
    background-color: var(--color-purple-3) !important;
  }
`

type Props = {
  assignees: any[]
  onClear: () => void
  assignedTo: string[]
  sortedAssignItems: any[] | undefined
  onCheckboxClick: (e: any, item: any) => void
  onCheckboxClickMe: (e: any, item: any) => void
}

const Assignees = ({
  onClear,
  assignees,
  assignedTo,
  onCheckboxClick,
  onCheckboxClickMe,
  sortedAssignItems,
}: Props) => {
  const [assigneeSearchQuery, setAssigneeSearchQuery] = useState('')
  const onAssigneeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAssigneeSearchQuery(e.target.value)
  }

  const avatarList = () => {
    return (
      <Avatar.Group size="small" maxCount={3}>
        {assignedTo.map((key, index) => {
          const assignee = sortedAssignItems?.find(
            (item: any) => item.key == key
          )
          if (assignedTo.length == 1 && index == 0) {
            return (
              <Avatar
                src={assignee?.image}
                size="small"
                className="mr-1"
                key={key}
              />
            )
          }
          if (assignedTo.length > 1 && assignedTo.length - 1 == index) {
            return (
              <Avatar src={assignee?.image} size="small" className="mr-1" />
            )
          }
          return (
            <Avatar key={assignee?.key} src={assignee?.image} size="small" />
          )
        })}
      </Avatar.Group>
    )
  }

  const sortedFilteredList = useMemo(
    () =>
      sortedAssignItems?.filter((it: any) =>
        it.label.toLowerCase().includes(assigneeSearchQuery.toLowerCase())
      ) || [],
    [assigneeSearchQuery, sortedAssignItems]
  )

  const assigneeContent = (
    <div>
      <Input
        autoFocus
        className="input"
        placeholder="Search"
        onChange={onAssigneeSearch}
        prefix={<MagnifyingGlass color="#707070" />}
      />
      <div
        style={{
          height: '200px',
          overflow: 'auto',
          minWidth: '250px',
          marginTop: '0.5rem',
        }}
      >
        <VirtualList
          height={200}
          itemKey="id"
          itemHeight={40}
          data={sortedFilteredList}
        >
          {(item: any) => {
            return (
              <ItemWrapper
                key={item.id}
                className={
                  assignedTo.includes(item.key)
                    ? 'checkbox-label-selected'
                    : 'checkbox-label'
                }
              >
                {item.label.includes('Me') ? (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1 w-full">
                      <Checkbox
                        className={
                          assignedTo?.includes(item.key)
                            ? 'checkbox-selected'
                            : 'checkbox-item'
                        }
                        onChange={(e) => {
                          onCheckboxClickMe(e, item)
                        }}
                        checked={assignedTo.includes(item.id)}
                      >
                        <Avatar
                          size="small"
                          src={item.image}
                          className="avatar_dropdown"
                        />
                        <Text
                          className="ml-1 font-medium text-info"
                          ellipsis={{ tooltip: false }}
                        >
                          {truncate(item.label, 20)}
                        </Text>
                      </Checkbox>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1 w-full">
                      <Checkbox
                        className={
                          assignedTo?.includes(item.key)
                            ? 'checkbox-selected'
                            : 'checkbox-item'
                        }
                        onChange={(e) => {
                          onCheckboxClick(e, item)
                        }}
                        checked={assignedTo.includes(item.id)}
                      >
                        <Avatar
                          size="small"
                          src={item.image}
                          className="avatar_dropdown"
                        />
                        <Text
                          className="ml-1 font-normal text-default"
                          ellipsis={{ tooltip: false }}
                        >
                          {truncate(item.label, 20)}
                        </Text>
                      </Checkbox>
                    </div>
                  </div>
                )}
              </ItemWrapper>
            )
          }}
        </VirtualList>
      </div>
    </div>
  )

  return (
    <div>
      <MultiSelect
        data={assignees}
        label="Assignees"
        active={assignedTo}
        clearClick={onClear}
        iconList={avatarList}
        icon={AssignFilterIcon}
        content={assigneeContent}
        callback={(isOpen: boolean) => {
          if (isOpen) {
            setAssigneeSearchQuery('')
          }
        }}
      />
    </div>
  )
}

export default Assignees
