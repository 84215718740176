import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'
import styled from 'styled-components'
import useAssistants from '../../../../hooks/useAssistants'
import AssistantSelector from '../AssistantSelector'

const KnowledgeAssistantHeader = () => {
  const { isLoading } = useAssistants()
  return (
    <Wrapper align="middle" justify="space-between" aria-disabled={isLoading}>
      <Col>
        <AssistantSelector />
      </Col>
      <Button className="about-btn" type="text">
        Learn about assistants
        <InfoCircleOutlined />
      </Button>
    </Wrapper>
  )
}

export default KnowledgeAssistantHeader

const Wrapper = styled(Row)`
  padding: 4px 24px;
  height: 44px;
  border-bottom: 1px solid var(--color-gray-3);
  .about-btn {
    padding-right: 0;
    font-size: 12px;
    color: var(--color-gray-6);
  }
`
