import { Button, Result } from 'antd'
import { useNavigate } from 'react-router'

export const FourZeroFour = () => {
  const navigate = useNavigate()
  return (
    <div>
      <Result
        icon={<img src="/images/404owl.png" alt="sad waiting owl icon" />}
        title="Page not found."
        subTitle="Please reach out to support@thena.ai or start a chat through our web chat widget if you have any questions."
        extra={
          <Button
            type="primary"
            onClick={() => {
              navigate('/requests')
            }}
          >
            Back Home
          </Button>
        }
      />
    </div>
  )
}
