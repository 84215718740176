import { Button, Card, message, Spin, Typography } from 'antd'
import { CaretLeft } from 'phosphor-react'
import { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { getTemplates, sendCampaign } from '../../api/marketingAutomation'
import { TemplateCard } from '../../components'
import WorkspaceSelector from '../../components/WorkspaceSelector'
import { useAuth } from '../../context'
import { useMaEditorStore } from '../../store/maEditor'
const { Title } = Typography

export const Templates = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { data, isLoading } = useQuery('all-marketing-templates', getTemplates)
  const { user } = useAuth()
  const storeDispatch = useMaEditorStore((state) => state.dispatch)
  const sendCampaignMutation = useMutation(sendCampaign, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['all-marketing-campaigns'])
      storeDispatch({
        type: 'SET_SHOW_FOCUS_ON_EDITOR',
        payload: {
          showFocusOnEditor: true,
        },
      })
      navigate(`/marketing-automation/editor/${data?._id}`)
    },
    onError: (error: any) => {
      message.error(error?.response?.data || 'Something went wrong')
    },
    onSettled: () => {
      queryClient.invalidateQueries(['all-marketing-campaigns'])
    },
  })
  const createCampaignHandler = (newBlocks: any, templateName: string) => {
    sendCampaignMutation.mutate({
      blocks: newBlocks,
      templateName: templateName,
      username: user?.team_name,
      icon_url: user?.team_image,
      sendAsUser: false,
      sendingUserId: '',
    })
  }

  useEffect(() => {
    queryClient.invalidateQueries(['all-marketing-templates'])
  }, [])

  return (
    <>
      <div
        id="marketing-automation"
        className="space-y-6 max-w-[90%] mx-auto mt-10 pb-10"
      >
        <Card
          bodyStyle={{
            padding: 0,
          }}
        >
          <div className="flex px-6 py-3 justify-between items-center">
            <Button
              icon={<CaretLeft />}
              type="text"
              className="p-0 inline-flex items-center"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <WorkspaceSelector />
          </div>
          <div
            className={`flex flex-col items-center justify-center p-5 ${
              isLoading ? 'h-screen' : ''
            }`}
          >
            {isLoading ? (
              <div>
                <Spin size="large" />
              </div>
            ) : (
              <div className="flex flex-wrap">
                {(data || []).map((template: any) => (
                  <div key={template._id} className="p-6">
                    <div className="mb-3">
                      <Title level={3} style={{ margin: 0 }}>
                        {template.name}
                      </Title>
                    </div>
                    <TemplateCard
                      from="TemplateList"
                      template={template}
                      createCampaignHandler={createCampaignHandler}
                    />
                    <div className="mt-3 mb-4 flex justify-center">
                      <Button
                        type="default"
                        size="large"
                        className="font-semibold bg-[#F8F5FF] text-primary"
                        onClick={() => {
                          createCampaignHandler(
                            template?.blocks,
                            template?.name
                          )
                          ;(window as any).analytics.track(
                            'Template selected',
                            {
                              template: template.name,
                            }
                          )
                        }}
                      >
                        Use this template
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  )
}
