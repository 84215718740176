import { Button, Checkbox, Collapse, Input, Spin, Typography } from 'antd'
import {
  CaretDown,
  CaretUp,
  CheckCircle,
  PencilLine,
  Warning,
} from 'phosphor-react'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { fetchSalesforceIntegration } from '../../../api/integrations'
import { helphubUtil } from '../../../utils/helphub'
import { useConnectedApp } from '../../ConnectedApps'
import { SalesforceConnectionDetailsType } from './../definitions'
import { useSalesforce } from './SalesforceProvider'

const { Panel } = Collapse
const { Text } = Typography

interface SalesforceIntegrationType {
  userID: string
  password: string
  consumerKey: string
  consumerSecret: string
  secretToken: string
  salesforceURL: string
  requestTypes: string[]
}
type Props = {
  requestType: {
    label: string
    value: string
    disabled: boolean
  }[]
}
export const SalesforceSetup = ({ requestType }: Props) => {
  const { isEdition } = useConnectedApp()
  const {
    isSetUpOpen,
    setIsSetUpOpen,
    connectionPassed,
    connectionFailed,
    saveRequestTypesMutation,
    testSalesforceConnectionMutation,
  } = useSalesforce()

  const { data: salesforceIntegration, isLoading } = useQuery(
    'fetch-salesforce-integration',
    fetchSalesforceIntegration,
    {
      select: (
        data: SalesforceConnectionDetailsType
      ): SalesforceIntegrationType => {
        return {
          userID: data?.username as string,
          password: data?.password as string,
          consumerKey: data?.client_id as string,
          consumerSecret: data?.client_secret as string,
          secretToken: data?.secret_token as string,
          salesforceURL: data?.url as string,
          requestTypes: data?.requestTypes as string[],
        }
      },
    }
  )

  const onSubmit = (data: any) => {
    testSalesforceConnectionMutation?.mutate({
      sfdc_username: data.userID,
      sfdc_password: data.password,
      sfdc_url: data.salesforceURL,
      sfdc_secrettoken: data.secretToken,
      sfdc_consumerkey: data.consumerKey,
      sfdc_consumersecret: data.consumerSecret,
      requestTypes: data.requestTypes,
    })
  }

  const onSave = (data: any) => {
    saveRequestTypesMutation?.mutate({
      sfdc_username: data.userID,
      sfdc_password: data.password,
      sfdc_url: data.salesforceURL,
      sfdc_secrettoken: data.secretToken,
      sfdc_consumerkey: data.consumerKey,
      sfdc_consumersecret: data.consumerSecret,
      requestTypes: data.requestTypes,
    })
  }
  const { handleSubmit, reset, control, formState } = useForm()

  useEffect(() => {
    reset(salesforceIntegration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceIntegration])

  const disableSave =
    (!isEdition && !connectionPassed) ||
    (isEdition && formState.isDirty && !connectionPassed)

  // Loader
  if (isLoading)
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Spin size="large" />
      </div>
    )

  return (
    <Collapse
      ghost
      accordion
      expandIconPosition="end"
      activeKey={isSetUpOpen ? '1' : '0'}
      className="border border-solid border-brand-border"
      expandIcon={() => {
        if (isEdition) return <PencilLine size={18} color="#696F7B" />
        if (!isSetUpOpen) return <CaretDown size={18} color="#7A00FF" />
        return <CaretUp size={18} color="#7A00FF" />
      }}
      onChange={() => {
        setIsSetUpOpen((p) => !p)
      }}
    >
      <Panel
        header={
          <div className="flex items-center justify-between">
            <h4 className="m-0 ml-3 font-semibold text-sm 3xl:text-[length:var(--sm)]">
              Setup Integration
            </h4>
            <p
              className="text-primary m-0 font-medium cursor-pointer"
              onClick={() => helphubUtil(133830)}
              role="button"
              data-intercom-target="Settings Integrations Salesforce Setup"
            >
              Where do I find this?
            </p>
          </div>
        }
        key="1"
      >
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Text className="font-medium">User ID</Text>
            <Controller
              name="userID"
              control={control}
              render={({ field }) => (
                <Input
                  size="middle"
                  className="mt-1"
                  placeholder="User ID"
                  disabled={connectionPassed}
                  {...field}
                />
              )}
            />
          </div>
          <div>
            <Text className="font-medium">Password</Text>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input.Password
                  size="middle"
                  className="mt-1"
                  placeholder="Password"
                  disabled={connectionPassed}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div>
          <div className="mt-1">
            <Text className="font-medium">Secret Token</Text>
            <Controller
              name="secretToken"
              control={control}
              render={({ field }) => (
                <Input
                  size="middle"
                  className="mt-1"
                  placeholder="Secret Token"
                  disabled={connectionPassed}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mt-1">
            <Text className="font-medium">Consumer Key</Text>
            <Controller
              name="consumerKey"
              control={control}
              render={({ field }) => (
                <Input
                  size="middle"
                  className="mt-1"
                  placeholder="Consumer Key"
                  disabled={connectionPassed}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mt-1">
            <Text className="font-medium">Consumer Secret</Text>
            <Controller
              name="consumerSecret"
              control={control}
              render={({ field }) => (
                <Input
                  size="middle"
                  className="mt-1"
                  placeholder="Consumer Secret"
                  disabled={connectionPassed}
                  {...field}
                />
              )}
            />
          </div>
          <div className="space-y-4">
            <div>
              <Text className="font-medium">Salesforce URL</Text>
              <div className="flex space-x-2 mt-1">
                <div className="w-full">
                  <Controller
                    name="salesforceURL"
                    control={control}
                    render={({ field }) => (
                      <Input
                        placeholder="Salesforce URL"
                        className="mt-1"
                        size="middle"
                        {...field}
                        status={connectionFailed ? 'error' : undefined}
                        disabled={connectionPassed}
                      />
                    )}
                  />
                </div>
                <Button
                  className="text-primary border-primary/40 mt-1"
                  size="middle"
                  type="default"
                  onClick={handleSubmit(onSubmit)}
                  disabled={connectionPassed}
                  loading={testSalesforceConnectionMutation?.isLoading}
                >
                  Test connection
                </Button>
              </div>
              <div className="flex space-x-2 mt-1">
                {connectionPassed && (
                  <span className="flex items-center">
                    <CheckCircle className="mr-1" size={14} color="#2BBC58" />{' '}
                    <Text className="text-xs text-success font-medium">
                      Connection Successful!
                    </Text>
                  </span>
                )}
                {connectionFailed && !connectionPassed && (
                  <span className="flex items-center">
                    <Warning className="mr-1" size={14} color="#D91029" />{' '}
                    <Text className="text-xs text-error font-medium">
                      Test connection failed!
                    </Text>
                  </span>
                )}
                {!connectionFailed && !connectionPassed && (
                  <Text className="text-xs text-muted font-medium">
                    Testing connection required
                  </Text>
                )}
              </div>
            </div>
            <div className="col-span-2">
              <Text className="font-medium">Request type(s) to send...</Text>
              <div className="mt-1 w-full">
                <Controller
                  name="requestTypes"
                  control={control}
                  render={({ field }) => (
                    <Checkbox.Group {...field} className="flex">
                      {requestType.map((el) => (
                        <div className="">
                          <Checkbox
                            key={el.value}
                            value={el.value}
                            disabled={disableSave || el.disabled}
                          >
                            <span className="mr-2 text-sm font-normal">
                              {el.label}
                            </span>
                          </Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  )}
                />
              </div>
            </div>
            <div className="w-full flex justify-end">
              <Button
                className="text-primary border-primary/40 mt-4 font-bold px-8"
                size="middle"
                type="default"
                disabled={disableSave}
                onClick={handleSubmit(onSave)}
                loading={saveRequestTypesMutation?.isLoading}
              >
                Save
              </Button>
            </div>
            {/* <ExtendedConnectorConfiguration
              isEdition={isEdition}
              className="mb-8"
              connector="SALESFORCE"
            /> */}
          </div>
        </div>
      </Panel>
    </Collapse>
  )
}
