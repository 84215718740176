import { useState } from 'react'
import { AssistantBodyType } from '../../../../types'
import { FormButtonGroup } from './components/FormButtonGroup'
import { FormHeader } from './components/FormHeader'
import FormInput from './components/FormInput'

type AssistantFormProps = {
  onClose: () => void
  onSubmit: (data: AssistantBodyType, canSubmit: boolean) => void
  currentData?: AssistantBodyType
  isSaving: boolean
  isFirstTime?: boolean
}

export const AssistantForm = ({
  onClose,
  onSubmit,
  isSaving,
  isFirstTime,
  currentData = { name: '', instructions: '' },
}: AssistantFormProps) => {
  const [formData, setFormData] = useState<AssistantBodyType>({
    ...currentData,
  })

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const canSubmit = formData.name !== '' && formData.instructions !== ''

  return (
    <div>
      <FormHeader
        title={
          isFirstTime
            ? 'Let’s first create an assistant'
            : 'Create New Assistant'
        }
        desc="Get smarter, personalized customer support solutions"
      />
      <FormInput
        type="text"
        title="Give a name"
        name="name"
        placeholder="Enter a unique name for your AI assistant."
        value={formData.name}
        onChange={onChangeHandler}
      />
      <FormInput
        type="textarea"
        title="Provide instructions"
        name="instructions"
        rows={4}
        placeholder="Provide specific guidelines or rules for your AI assistant's responses."
        value={formData.instructions}
        onChange={onChangeHandler}
      />
      <FormButtonGroup
        okText={`${currentData.name === '' ? 'Create' : 'Update'} Assistant`}
        isSaving={isSaving}
        onOk={() => onSubmit(formData, canSubmit)}
        onClose={onClose}
      />
    </div>
  )
}
