import { useEffect } from 'react'

const tabId = Math.random().toString(36).substring(2, 15)

const BROADCAST_CHANNEL = 'auth-channel'

export const useReloadBroadcastListener = () => {
  useEffect(() => {
    const channel = new BroadcastChannel(BROADCAST_CHANNEL)
    const handleBroadcast = (message: any) => {
      if (message.data.type === 'reload' && message.data.tabId !== tabId) {
        console.log('Triggered reload from another tab/window. Reloading.')
        if (window.location.pathname !== '/login') {
          window.location.reload()
        }
      }
    }
    channel.addEventListener('message', handleBroadcast)
    return () => {
      channel.removeEventListener('message', handleBroadcast)
      channel.close()
    }
  }, [])
}

export const broadcastReload = () => {
  const channel = new BroadcastChannel(BROADCAST_CHANNEL)
  channel.postMessage({
    type: 'reload',
    tabId,
  })
  channel.close()
}
