import { create } from 'zustand'
import { devtools, persist, redux } from 'zustand/middleware'

type ActionType = {
  type:
    | 'SET_ACTIVE_WORKSPACE_ID'
    | 'SET_HIDE_WELCOME_BANNER'
    | 'SET_IS_ADD_NEW_WORKSPACE_FLOW'
  payload?: any
}

const reducer = (state: StateType, action: ActionType): StateType => {
  const { type, payload } = action

  switch (type) {
    case 'SET_ACTIVE_WORKSPACE_ID':
      return {
        ...state,
        activeWorkspaceId: payload.activeWorkspaceId,
      }

    case 'SET_HIDE_WELCOME_BANNER':
      return {
        ...state,
        hideWelcomeBanner: payload.hideWelcomeBanner,
      }

    case 'SET_IS_ADD_NEW_WORKSPACE_FLOW':
      return {
        ...state,
        isAddNewWorkspaceFlow: payload.isAddNewWorkspaceFlow,
      }

    default:
      return state
  }
}

type StateType = {
  activeWorkspaceId: null | string
  hideWelcomeBanner: boolean
  isAddNewWorkspaceFlow: boolean
}

const initalState: StateType = {
  activeWorkspaceId: null,
  hideWelcomeBanner: false,
  isAddNewWorkspaceFlow: false,
}

const useGlobalStorePersist = create(
  persist(
    devtools(redux(reducer, initalState), {
      name: 'useGlobalStorePersist',
    }),
    {
      name: 'useGlobalStorePersist',
      version: 0.1,
    }
  )
)

export { useGlobalStorePersist }
