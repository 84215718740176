/* eslint-disable max-lines */
import { Avatar, Checkbox, Tooltip, Typography } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { isEmpty, snakeCase, toUpper, truncate } from 'lodash'
import moment from 'moment'
import { useSubStatusList } from 'pages/CustomStatusRequests/utils'
import styled, { css } from 'styled-components'
import { useAuth } from '../../../../context'
import useEvents from '../../../../hooks/useEvents'
import SLAAlertIcon from '../../../../images/icons/SLA.svg'
import ReplyIcon from '../../../../images/icons/ic_reply.svg'
import LastUpdatedIcon from '../../../../images/icons/last-updated.svg'
import { useKanbanStore2 } from '../../../../store/kanbanStore2'
import { useKanbanStorePersist } from '../../../../store/kanbanStorePersist'
import { handleCustomEmoji } from '../../../../utils/kanbanUtils'
import { MODAL_TYPES } from '../../constants'
import {
  getPriorityIconByValue,
  getStatusIndicatorColor,
  getValueBasedOnFlag,
  getWorkspaceInfo,
} from '../../utils'
import Assignee from './Assignee'
const { Text } = Typography

type Props = {
  data: any
  index: number
  scrollRef: any
  isKeyFocused: boolean
  setKeyFocusedRowId: (id: string) => void
  isReadonly?: boolean
  disableActions?: boolean
}

const Wrapper = styled.div<{ $isChecked?: boolean }>`
  height: 36px;
  display: flex;
  cursor: pointer;
  padding-right: 12px;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-3);
  .row-checkbox {
    .ant-checkbox::after {
      border: none !important;
      outline: none !important;
    }
    .ant-checkbox-checked > .ant-checkbox-inner {
      border-color: var(--color-purple-1) !important;
      background-color: var(--color-purple-1) !important;
    }
    .ant-checkbox-input:focus {
      border-color: var(--color-purple-1) !important;
    }
    .ant-checkbox-inner {
      border-radius: 3px !important;
    }
  }

  :hover,
  :focus {
    background-color: var(--color-gray-9);
    .row-checkbox {
      .ant-checkbox-inner {
        border-color: var(--color-purple-1) !important;
      }
    }
  }
  :focus {
    outline: none;
    background-color: var(--color-gray-9);
  }
  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background-color: var(--color-purple-3);
      :hover {
        background-color: var(--color-purple-3);
      }
    `}
`

const StatusIndicator = styled.div`
  width: 22px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  div {
    content: ' ';
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
`

const PriorityWrapper = styled.div<{ $isReadonly?: boolean }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  ${({ $isReadonly }) =>
    $isReadonly &&
    css`
      img {
        width: 0px;
      }
    `}
`

const WorkspaceName = styled.div<{ $isOrg?: boolean }>`
  content: '';
  flex-shrink: 0;
  font-size: 12px;
  margin-left: 8px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${({ $isOrg }) => ($isOrg ? '100px' : '0')};
`

const RequestIdWrapper = styled.div`
  flex-shrink: 0;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  color: var(--color-gray-6);
  margin: 0 8px;
`

const WorkspaceIconWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 8px;
`

const TitleWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ReplyCountWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-right: 4px;
  }

  color: var(--color-gray-6);
  font-size: 10px;
`

const AITagsWrapper = styled.div`
  display: flex;
  font-size: 11px;
  font-weight: 400;
  align-items: center;
  color: var(--color-purple-4);
  flex-shrink: 0;
  div {
    flex-shrink: 0;
    padding: 0 6px;
    margin-right: 4px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-purple-3);
  }
  .extra-tags-count {
    color: var(--color-gray-4);
    background-color: var(--color-gray-bg);
  }
`

const LastUpdatedWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  font-size: 11px;
  align-items: center;
  color: var(--color-gray-6);
  img {
    width: 12px;
    height: 12px;
    object-fit: contain;
    margin-right: 4px;
  }
`

const SLAAlertWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 24px;
  align-items: center;
`

const CheckEventWrapper = styled.div`
  height: 32px;
  display: flex;
  flex-shrink: 0;
  padding-left: 12px;
  align-items: center;
  justify-content: center;
`

const Row = ({
  data,
  index,
  scrollRef,
  isKeyFocused,
  setKeyFocusedRowId,
  isReadonly,
  disableActions = false,
}: Props) => {
  const track = useEvents()
  const showAiTitle = useKanbanStorePersist((state) => state.showAiTitle)
  // confirm about ticketId herer
  const requestId = data.requestId

  const flags = useFlags()
  const showTicketInfoWithAI =
    data.cardType === 'TICKET' && flags.aiMetadataForTickets

  const priorityInfo = getValueBasedOnFlag(
    data.AI_requestUrgency,
    data.AI_ticketUrgency,
    showTicketInfoWithAI
  )
  const priorityIcon = getPriorityIconByValue(priorityInfo)
  const priorityTitle = `${priorityInfo} priority`

  const aiTags =
    getValueBasedOnFlag(
      data.AI_generatedTags,
      data.AI_ticketGeneratedTags,
      showTicketInfoWithAI
    ) || []

  const renderAiTags = () => {
    const firstTwoTags = aiTags.slice(0, 2)
    const extraTags = aiTags.slice(2)
    const list = [
      ...firstTwoTags.map((tag: string) => {
        if (tag.length > 12) {
          return (
            <Tooltip title={tag} showArrow={false} destroyTooltipOnHide>
              <div>
                {truncate(tag, {
                  length: 12,
                })}
              </div>
            </Tooltip>
          )
        }
        return <div>{tag}</div>
      }),
    ]
    if (extraTags.length) {
      list.push(
        <Tooltip
          showArrow={false}
          destroyTooltipOnHide
          title={extraTags.join(', ')}
        >
          <div className="extra-tags-count">+{extraTags.length}</div>
        </Tooltip>
      )
    }
    return list
  }

  const [workspaceName, iconUrl] = getWorkspaceInfo(data)

  const dispatch = useKanbanStore2((state) => state.dispatch)
  const checkedList = useKanbanStore2((state) => state.checkedList)
  const isChecked = checkedList.includes(data._id)
  const subStatus = useSubStatusList()
  const handleCheckboxChange = (e: any) => {
    e.stopPropagation()
    scrollRef.current.scrollIntoView({
      index,
    })

    setKeyFocusedRowId(data._id)

    if (!isChecked) {
      return dispatch({
        type: 'SET_CHECKED_LIST',
        payload: {
          checkedList: [...checkedList, data._id],
        },
      })
    }
    dispatch({
      type: 'SET_CHECKED_LIST',
      payload: {
        checkedList: checkedList.filter((id) => id !== data._id),
      },
    })
  }

  const onRowClick = () => {
    dispatch({
      type: 'SET_DROPPED_ID',
      payload: {
        droppedId: '',
      },
    })
    let currentColDetails = ''
    if (data?.subStatus) {
      const subStatusList = subStatus?.data?.filter(
        (item: any) => item?.sub_status?.length > 0
      )

      const foundSubStatus = subStatusList
        ?.flatMap(({ sub_status }: { sub_status: any }) => sub_status)
        ?.find(({ _id }: { _id: any }) => _id === data?.subStatus)
      currentColDetails = foundSubStatus
        ? toUpper(snakeCase(foundSubStatus?.name))
        : ''
    } else {
      currentColDetails = data.status === 'OPEN' ? 'UNASSIGNED' : data.status
    }

    if (!isReadonly) {
      track('List Click')
      dispatch({
        type: 'SET_MODAL',
        payload: {
          modal: {
            type: MODAL_TYPES.KANBAN_DRAWER,
            data: {
              cardId: data._id,
              currentCol: currentColDetails,
              query:
                data.cardType === 'REQUEST'
                  ? 'requestId;teamId'
                  : 'ticketId;teamId',
              queryValue:
                data.cardType === 'REQUEST'
                  ? `${data.requestId};${data.teamId}`
                  : `${data._id};${data.teamId}`,
            },
          },
        },
      })
    } else {
      if (disableActions) {
        return window.open(
          `/requests?&requestId=${requestId}&teamId=${data.teamId}`
        )
      }
      window.open(
        `/requests?&requestId=${requestId}&teamId=${data.teamId}`,
        '_blank',
        'rel=noopener noreferrer'
      )
    }
  }

  const title = getValueBasedOnFlag(
    data.AI_requestTitle,
    data.AI_ticketTitle,
    showTicketInfoWithAI
  )

  const isAiTitle = !!title && showAiTitle

  const renderCheckboxAndPriority = () => {
    if (disableActions) {
      return (
        <CheckEventWrapper
          style={{
            paddingLeft: '0px',
          }}
        >
          <StatusIndicator>
            <div
              style={{ backgroundColor: getStatusIndicatorColor(data.status) }}
            />
          </StatusIndicator>
          <PriorityWrapper>
            <Tooltip
              title={priorityTitle}
              destroyTooltipOnHide
              showArrow={false}
            >
              <img src={priorityIcon} alt={priorityTitle} />
            </Tooltip>
          </PriorityWrapper>
        </CheckEventWrapper>
      )
    }

    if (isReadonly) {
      return null
    }

    return (
      <CheckEventWrapper onClick={handleCheckboxChange}>
        <Checkbox
          checked={isChecked}
          className="row-checkbox"
          onChange={handleCheckboxChange}
        />
        <StatusIndicator>
          <div
            style={{ backgroundColor: getStatusIndicatorColor(data.status) }}
          />
        </StatusIndicator>
        <PriorityWrapper $isReadonly={isReadonly}>
          <Tooltip title={priorityTitle} destroyTooltipOnHide showArrow={false}>
            <img src={priorityIcon} alt={priorityTitle} />
          </Tooltip>
        </PriorityWrapper>
      </CheckEventWrapper>
    )
  }

  const { user } = useAuth()
  const isOrg = !isEmpty(user?.organization)
  const installation = user?.installations?.find(
    (installation) => installation.team?.id === data.teamId
  )
  const slackWorkspaceName = (installation?.team?.name || '') as string

  return (
    <Wrapper $isChecked={isKeyFocused} onClick={onRowClick}>
      {renderCheckboxAndPriority()}
      <WorkspaceName $isOrg={isOrg}>
        {isOrg ? (
          <Tooltip title={slackWorkspaceName}>{slackWorkspaceName}</Tooltip>
        ) : null}
      </WorkspaceName>
      <RequestIdWrapper>ID-{requestId}</RequestIdWrapper>
      <WorkspaceIconWrapper>
        <Tooltip title={workspaceName} destroyTooltipOnHide showArrow={false}>
          <Avatar shape="circle" src={iconUrl} className="w-[16px] h-[16px]" />
        </Tooltip>
      </WorkspaceIconWrapper>
      <TitleWrapper>
        {isAiTitle ? (
          <Title>{title}</Title>
        ) : (
          <Text
            style={{
              fontWeight: 600,
              fontSize: '14px',
              maxHeight: '18px',
            }}
            ellipsis
          >
            <div
              className="h-[18px]"
              dangerouslySetInnerHTML={{
                __html: handleCustomEmoji(data.description, false),
              }}
            />
          </Text>
        )}
        {!!data.replyCount && (
          <ReplyCountWrapper>
            <img src={ReplyIcon} alt="replies" /> {data.replyCount}
          </ReplyCountWrapper>
        )}
      </TitleWrapper>
      <RightWrapper>
        <AITagsWrapper>{renderAiTags()}</AITagsWrapper>
        <LastUpdatedWrapper>
          <Tooltip
            showArrow={false}
            destroyTooltipOnHide
            title={
              'Created at: ' +
              moment(data.createdAt).format('MMM DD hh:mm A [GMT]')
            }
          >
            <img src={LastUpdatedIcon} alt="" />{' '}
            {moment(data.createdAt).format('MMM DD')}
          </Tooltip>
        </LastUpdatedWrapper>
        <Assignee data={data} isReadonly={disableActions} />
        <SLAAlertWrapper>
          {!!data.isEscalated && (
            <Tooltip
              placement="topRight"
              title="This request breached the First Response Timelines"
            >
              <img src={SLAAlertIcon} alt="sla alert" />
            </Tooltip>
          )}
        </SLAAlertWrapper>
        {/* <RowMenu data={data} /> */}
      </RightWrapper>
    </Wrapper>
  )
}

export default Row
