import { Checkbox } from 'antd'
import { useAddToSlack } from '../../../context/AddToSlackProvider'

const AddToSlackButton = ({ className }: { className: string }) => {
  const { checked, setChecked } = useAddToSlack()

  const onChangeHandler = () => {
    setChecked((prev) => !prev)
  }
  return (
    <Checkbox
      className={className}
      checked={checked}
      onChange={onChangeHandler}
    >
      Create a shared Slack channel to chat with us
    </Checkbox>
  )
}

export { AddToSlackButton }
