import { Modal } from 'antd'
import { AssistantBodyType } from '../../../../types'

type EditAssistantProps = {
  isOpen: boolean
  onClose: () => void
  currentData?: AssistantBodyType
  isSaving?: boolean
}

export const EditAssistantModal = ({ onClose, isOpen }: EditAssistantProps) => {
  return <Modal open={isOpen} footer={null} onCancel={onClose}></Modal>
}
