import { Avatar, Divider } from 'antd'
import { startCase } from 'lodash'
import moment from 'moment'
import { RelationshipType, SummaryDetail } from '../../../types/accountsTypes'

export const ContentHeader = ({
  conv,
  summaryDetails,
}: {
  conv: RelationshipType | undefined
  summaryDetails: SummaryDetail[]
}) => {
  return (
    <div className="p-6">
      <div className="flex items-center justify-end mb-2 text-[var(--color-gray-4)] text-xs">
        Last Updated :{' '}
        {moment(conv?.crm_values?.last_updated_time).format('MM.DD.YYYY')}
      </div>
      <div className="wrapper">
        <Avatar
          shape="circle"
          size="default"
          src={summaryDetails[0]?.customerLogo}
        />
        <div className="title">
          {conv?.customer_name ?? conv?.crm_values?.name}
        </div>
        <p className="text-[var(--color-gray-4)] m-0 p-0">•</p>
        <div className="text-sm text-[var(--color-gray-4)] font-medium">
          {startCase(conv?.crm_values?.type)}
        </div>
      </div>
      {conv?.users?.some((item) => item.role !== 'Unassigned') && (
        <Divider className="text-[#E5E5EA] max-w-[450px] min-w-0" />
      )}
      <div className="flex flex-col justify-center items-start gap-3">
        {conv?.users?.map(
          (item) =>
            item?.role?.toLowerCase() !== 'unassigned' && (
              <div className="flex items-center justify-start gap-1">
                <Avatar
                  size="small"
                  className="avatar font-medium"
                  src={item.user.slack.profile.image_original}
                >
                  {item.user.name.charAt(0).toUpperCase()}
                </Avatar>
                <span className="font-medium">{item.user.name}</span>
                <p className="text-[var(--color-gray-4)] m-0 p-0">•</p>
                <span className="font-normal text-[var(--color-gray-6)]">
                  {startCase(item.role)}
                </span>
              </div>
            )
        )}
      </div>
    </div>
  )
}
