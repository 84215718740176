import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  ConversationType,
  CreateConversationType,
  FeedbackType,
} from '../../../../types/playgroundTypes'
import { ChatDetail } from './ChatDetail'
import { LoadingAnswer } from './LoadingAnswer'
import { NewContent } from './NewContent'

type ChatContentProps = {
  currChatId: string
  chatHistory: ConversationType[]
  context: string
  loadingStatus: boolean
  isNewResponse: boolean
  setNewResponse: (data: boolean) => void
  createNewChat: (data: CreateConversationType) => void
  setContext: (value: string) => void
  addFeedback: (qnaId: string, feedback: FeedbackType) => void
  userAvatarUrl: string
}

export const ChatContent = ({
  currChatId,
  chatHistory,
  context,
  loadingStatus,
  isNewResponse,
  setNewResponse,
  createNewChat,
  setContext,
  addFeedback,
  userAvatarUrl,
}: ChatContentProps) => {
  const endOfMessagesRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView(true)
  }, [chatHistory, context])

  if (chatHistory.length === 0 && context === '') {
    return (
      <NewContent
        currChatId={currChatId}
        createNewChat={createNewChat}
        setContext={setContext}
      />
    )
  }
  return (
    <Wrapper>
      {chatHistory.length > 0 && (
        <ChatDetail
          chatHistory={chatHistory}
          isNewResponse={isNewResponse}
          setNewResponse={setNewResponse}
          setContext={setContext}
          addFeedback={addFeedback}
          userAvatarUrl={userAvatarUrl}
        />
      )}
      {loadingStatus && context !== '' && (
        <LoadingAnswer context={context} userAvatarUrl={userAvatarUrl} />
      )}
      <span ref={endOfMessagesRef} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-height: calc(100vh - 332px);
  overflow-y: auto;
  margin-top: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
`
