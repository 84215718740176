import { createContext, useContext, useState } from 'react'
interface AddToSlackContextType {
  checked: boolean
  setChecked: React.Dispatch<React.SetStateAction<boolean>>
}
const AddToSlackDefaults: AddToSlackContextType = {
  checked: true,
  setChecked: () => {},
}
const AddToSlackContext =
  createContext<AddToSlackContextType>(AddToSlackDefaults)

const AddToSlackProvider = ({ children }: { children: React.ReactNode }) => {
  const [checked, setChecked] = useState(AddToSlackDefaults.checked)
  return (
    <AddToSlackContext.Provider value={{ checked, setChecked }}>
      {children}
    </AddToSlackContext.Provider>
  )
}

const useAddToSlack = () => useContext(AddToSlackContext)

export { useAddToSlack, AddToSlackProvider }
