import {
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Typography,
} from 'antd'
import { AdditionalFieldType } from './types'

const { Text } = Typography
const { TextArea } = Input

export const InputField = ({ field }: { field: AdditionalFieldType }) => {
  let RenderInput = <></>
  switch (field.type) {
    case 'TEXT':
      RenderInput = (
        <Input
          key={`${field.label}-${field.key}`}
          placeholder={field?.placeholder}
        />
      )
      break

    case 'RICHTEXT':
      RenderInput = (
        <TextArea
          key={`${field.label}-${field.key}`}
          placeholder={field?.placeholder}
        />
      )
      break
    case 'MULTI_SELECT':
      RenderInput = (
        <Select
          key={`${field.label}-${field.key}`}
          mode="multiple"
          allowClear
          defaultValue={
            Array.isArray(field.defaultValue) ? field.defaultValue : []
          }
          style={{ width: '100%' }}
          options={field.options?.map((o) => ({
            value: o.value,
            label: o.text,
          }))}
        />
      )
      break
    case 'DATE':
      RenderInput = (
        <DatePicker
          key={`${field.label}-${field.key}`}
          style={{ width: '100%' }}
        />
      )

      break
    case 'NUMBER':
      RenderInput = (
        <InputNumber
          style={{ width: '100%' }}
          key={`${field.label}-${field.key}`}
          placeholder={field?.placeholder}
        />
      )

      break
    case 'SELECT':
      RenderInput = (
        <Select
          key={`${field.label}-${field.key}`}
          allowClear
          defaultValue={field.defaultValue}
          style={{ width: '100%' }}
          options={field.options?.map((o) => ({
            value: o.value,
            label: o.text,
          }))}
        />
      )
      break
    case 'RADIO':
      RenderInput = (
        <Radio.Group key={`${field.label}-${field.key}`}>
          <Space direction="vertical">
            {field.options?.map((o) => (
              <Radio value={o.value}>{o.text}</Radio>
            ))}
          </Space>
        </Radio.Group>
      )
      break
    default:
      break
  }
  // console.log('RenderInput', RenderInput)
  return (
    <div className="mb-2">
      <span>
        <Text strong>{field.label}</Text>{' '}
        {!field.mandatory ? <Text type="secondary">(Optional)</Text> : null}
      </span>
      <div>{RenderInput}</div>
    </div>
  )
}
