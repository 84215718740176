import { Modal } from 'antd'
import RemoveResourceForm from '../Forms/RemoveResourceForm'

type RemoveSourceProps = {
  isOpen: boolean
  onCancel: () => void
}

export const RemoveSourceModal = ({ onCancel, isOpen }: RemoveSourceProps) => {
  return (
    <Modal
      open={isOpen}
      footer={null}
      onCancel={onCancel}
      width={428}
      bodyStyle={{ padding: '32px 32px 24px' }}
      closable={false}
      destroyOnClose
    >
      <RemoveResourceForm
        actionType="delete"
        title="Are you sure you want to delete source?"
        description="You can select the data you want to delete with the source"
        checkboxLabels={[
          'Delete unverified questions & answers',
          'Delete verified questions & answers',
        ]}
        okText="Delete Source"
        onCancel={onCancel}
        onAction={onCancel}
        onChange={() => {}}
      />
    </Modal>
  )
}
