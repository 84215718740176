/* eslint-disable max-lines */
import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  Table as AntTable,
  Avatar,
  Button,
  Checkbox,
  ConfigProvider,
  Empty,
  Input,
  Popover,
  Tooltip,
  message,
} from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useLiveQuery } from 'dexie-react-hooks'
import { camelCase, isEmpty } from 'lodash'
import capitalize from 'lodash/capitalize'
import { ArrowsCounterClockwise, MagnifyingGlass } from 'phosphor-react'
import type { FixedType } from 'rc-table/lib/interface'
import { useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import ReactDragListView from 'react-drag-listview'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'
import { accountDB } from '../../../DB/AccountDB'
import {
  fetchAccountsFromCRM,
  fetchRelationships,
  updateAllCRMValuesOnRelation,
} from '../../../api/accounts'
import { fetchPublicChannels } from '../../../api/channels'
import { ChartLoader } from '../../../components/Analytics/RequestOverview/Common'
import { ChannelType } from '../../../modules/channels/definitions'
import { useAccountsStore } from '../../../store/accountsStore'
import {
  ACCOUNT_TYPE_LIST,
  AccountType,
  CRMAdditonalFieldType,
  RelationshipType,
  UserType,
} from '../../../types/accountsTypes'
import { getCurrentTimeStamp } from '../../../utils/dateUtils'
import { emptyList, emptyObject } from '../../../utils/empty'
import { pluralize, truncate } from '../../../utils/textUtils'
import { Refresh } from '../Filters/Filters'
import TableFilters from '../Filters/TableFilters'
import { useCurrentIntegration } from '../utils'
import {
  MultiEditCell,
  SingleEditCell,
  TextInputEditCell,
} from './ConfigEditCell'
import {
  DateSelectCell,
  SingleSelectChannelEditCell,
  SingleSelectEditCell,
  SingleSelectUserEditCell,
  TextEditCell,
} from './EditableCell'
import useApplyFilter from './useApplyFilter'

const Wrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
  padding: 0 24px 32px 24px;
  .ant-table-thead {
    padding: 0;
    height: 40px;
    line-height: 1px;
  }
  .ant-table-row {
    line-height: 35px;
  }
  .ant-pagination-total-text {
    flex-grow: 1 !important;
  }
  .reorder-column {
    border-color: green !important;
  }
`
const StatsWrapper = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SectionTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  align-self: flex-start;
  color: var(--color-black-1);
`

const HiddenCheckbox = styled(Checkbox)`
  margin-right: 4px;
`
const CheckboxLabel = styled.label`
  cursor: pointer;
`

const CheckboxWrapper = styled.div`
  display: inline-block;
`
const CheckboxGroupWrapper = styled(Checkbox.Group)`
  overflow-y: scroll;
  height: 220px;
  width: 150px;
  padding: 4px;
  border-radius: 8px;
`
export type RowType = {
  _id: string
  name: string
  users: UserType[]
  contractTerm: string
  contractValue: number
  syncedWithCRM: boolean
  contractEndDate: string
  type: AccountType | null
  contractStartDate: string
  internalSlackChannel: string
  externalSlackChannel: string
  accountOwner: UserType | null
  channel: {
    id: string
  }
}

const headers = [
  { label: 'Account Name', key: 'name' },
  { label: 'Account Type', key: 'type' },
  {
    label: 'ARR (USD)',
    key: 'contractValue',
  },
  { label: 'Account Owner', key: 'accountOwner' },
  { label: 'Start Date', key: 'contractStartDate' },
  { label: 'Renewal date', key: 'contractEndDate' },
  {
    label: 'Contract Duration',
    key: 'contractTerm',
  },
  {
    label: 'Synced with CRM',
    key: 'syncedWithCRM',
  },
  {
    label: 'Users',
    key: 'users',
  },
  {
    label: 'Internal Slack Channel',
    key: 'internalSlackChannel',
  },
  {
    label: 'External Slack Channel',
    key: 'externalSlackChannel',
  },
]

const UserWithProfile = styled.div`
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 8px;
  }
  position: relative;
  .avatar-shift {
    top: 0;
    right: 12px;
    position: relative;
  }
`

const StyledAvatar = styled(Avatar)`
  flex-shrink: 0;
`

const SurplusIdicator = styled.div`
  right: 10px;
  font-weight: 600;
  font-size: 0.75rem;
  position: relative;
  color: var(--color-purple-1);
`

const UsersGroup = styled.div`
  display: flex;
  align-items: center;
  .avatar {
    margin-right: 6px;
  }
`

const accountTypeList = ACCOUNT_TYPE_LIST.map((item) => ({
  id: item,
  name: capitalize(item),
}))

const booleanList = [
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
]

const Table = () => {
  const crmConfig = useAccountsStore((state) => state.crmConfig)
  const [allUpdated, setAllUpdated] = useState(0)

  const relationships = useLiveQuery(async () => {
    const data = await accountDB.relationship.toArray()
    return data ?? []
  }, [allUpdated])

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })

  const [selectedFilters, setSelectedFilters] = useState<string[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const crmDetails = useAccountsStore((state) => state.crmConfig)
  const [searchText, setSearchText] = useState<string>('')
  const tableData: any = useMemo(() => {
    const data =
      relationships?.map((rel: any) => {
        const { _id, users, channel, crm_values, customer_name } = rel
        const {
          name,
          type,
          contract_term,
          contract_value,
          synced_with_crm,
          additional_fields = emptyList,
          contract_end_date,
          contract_start_date,
          internal_slack_channel_name,
        } = crm_values || emptyObject

        const rowInfo = {
          _id,
          users,
          name: name || customer_name,
          accountType: type,
          externalSlackChannel: channel.name,
          contractTerm: contract_term,
          contractValue: contract_value,
          syncedWithCRM: synced_with_crm,
          contractEndDate: contract_end_date,
          contractStartDate: contract_start_date,
          internalSlackChannel: internal_slack_channel_name,
          accountOwner: users.find((user: any) => user.role === 'csm') || null,
          channel: {
            id: channel.id,
          },
        }

        crmConfig?.additional_fields?.forEach((field) => {
          const values = additional_fields?.find(
            ({ field_name }: { field_name: any }) =>
              field_name === field.field_name
          )?.values
          // @ts-ignore

          rowInfo[camelCase(field.field_name)] = values
        })
        return rowInfo
      }) ?? []

    const filteredData = data.filter((item: any) => {
      // Perform a case-insensitive search for each field
      if (searchText === '') {
        return item
      }
      return (
        crmConfig?.additional_fields?.some((field) => {
          const fieldName = camelCase(field?.field_name)
          const itemValue = item[fieldName]
          return (
            itemValue &&
            itemValue?.some((item: any) =>
              item?.name?.toLowerCase().includes(searchText.toLowerCase())
            )
          )
        }) ||
        item?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.accountType?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.externalSlackChannel
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item?.internalSlackChannel
          ?.toLowerCase()
          .includes(searchText.toLowerCase()) ||
        (item?.accountOwner &&
          item?.accountOwner?.user?.name
            ?.toLowerCase()
            .includes(searchText.toLowerCase()))
      )
    })

    return filteredData
  }, [relationships, crmConfig, searchText])

  const onCheckboxChange = (e: any) => {
    setSelectedFilters(e)
  }

  const filteredTableData = useApplyFilter(tableData)
  const handleDrawerAction = (record: RowType) => {
    dispatch({
      type: 'SET_DRAWER',
      payload: {
        drawer: {
          type: 'ACCOUNT_DRAWER',
          data: {
            open: true,
            record: record,
          },
        },
      },
    })
  }
  const content = useMemo(() => {
    return (
      <>
        {isEmpty(crmConfig?.additional_fields) ? (
          <Empty className="flex justify-center items-center flex-col w-104 h-48 overflow-hidden" />
        ) : (
          <div
            className="flex flex-col gap-3"
            style={{
              width: '150px',
              maxHeight: '500px',
            }}
          >
            <CheckboxGroupWrapper
              className="w-full"
              onChange={(e) => onCheckboxChange(e)}
            >
              {crmConfig?.additional_fields?.map((tag, index) => (
                <label
                  style={{
                    cursor: 'pointer',
                    display: 'block',
                    padding: '4px 8px',
                  }}
                  key={index}
                  className="popover-item w-full"
                  htmlFor={tag._id}
                >
                  <CheckboxLabel>
                    <CheckboxWrapper>
                      <HiddenCheckbox
                        key={tag.field_name}
                        value={tag.field_name}
                        className="w-full"
                      />
                    </CheckboxWrapper>
                    {tag.field_name}
                  </CheckboxLabel>
                </label>
              ))}
            </CheckboxGroupWrapper>
            {/* <Button onClick={updateColumnhandler}>Update columns</Button> */}
          </div>
        )}
      </>
    )
  }, [crmConfig?.additional_fields])

  const { data: publicChannels } = useQuery(['fetchPublicChannels'], () =>
    fetchPublicChannels({})
  )

  const channelList = useMemo(() => {
    return (publicChannels?.channels || []).map((channel: ChannelType) => ({
      name: channel.name,
      id: channel.id,
    }))
  }, [publicChannels?.channels])

  const { enabledIntegrationDetails } = useCurrentIntegration()

  const getCRMIcon = (show: boolean) => {
    return (
      enabledIntegrationDetails.name !== '' &&
      show && (
        <img
          src={enabledIntegrationDetails.icon}
          width={10}
          height={10}
          alt={enabledIntegrationDetails.name}
          className="ml-[-4px]"
        />
      )
    )
  }
  const columns: ColumnsType<any> = useMemo(() => {
    return [
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            {getCRMIcon(true)}
            Account Name
          </div>
        ),
        key: 'name',
        fixed: 'left',
        width: '180px',
        dataIndex: 'name',
        sorter: (a, b) => {
          if (a?.name) {
            return a.name.localeCompare(b?.name)
          }
        },
        onCell: (record) => {
          return {
            onClick: () => {
              !selectedRowKeys.includes(record._id) &&
                handleDrawerAction(record)
            },
          }
        },
        render(value: string, record: RowType, index: number) {
          return {
            props: {
              style: { cursor: 'pointer' },
            },
            children: (
              <TextEditCell
                key={`${record._id}-name`}
                value={value || 'Not set'}
                record={record}
                crmKey="name"
                index={index}
                width={120}
                form={form}
                isMultiEditing={selectedRowKeys.includes(record._id)}
              />
            ),
          }
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            {getCRMIcon(true)}
            Account Type
          </div>
        ),
        key: 'accountType',
        width: '150px',
        dataIndex: 'accountType',
        sorter: (a, b) => {
          return a?.accountType?.localeCompare(b?.accountType)
        },
        render: (value: string, record: RowType, index: number) => {
          return (
            <SingleSelectEditCell
              key={`${record._id}-accountType`}
              value={value}
              crmKey="accountType"
              record={record}
              index={index}
              dropdownList={accountTypeList}
              form={form}
              isMultiEditing={selectedRowKeys.includes(record._id)}
            />
          )
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            {getCRMIcon(true)}
            ARR (USD)
          </div>
        ),
        width: '150px',
        key: 'contractValue',
        dataIndex: 'contractValue',
        sorter: (a, b) => {
          if (!isEmpty(selectedRowKeys)) {
            return
          }
          if (a.contractValue === undefined || b.contractValue === undefined) {
            return a.contractValue === undefined ? -1 : 1
          }
          return a.contractValue - b.contractValue
        },
        render(value: string, record: RowType, index: number) {
          return (
            <TextEditCell
              key={`${record._id}-contractValue`}
              isNumber
              record={record}
              value={value || '0'}
              crmKey="contract_value"
              width={100}
              index={index}
              form={form}
              isMultiEditing={selectedRowKeys.includes(record._id)}
            />
          )
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            {getCRMIcon(true)}
            Account Owner
          </div>
        ),
        width: '180px',
        key: 'accountOwner',
        dataIndex: 'accountOwner',
        sorter: (a, b) => {
          const personaA = a?.accountOwner?.user?.name || ''
          const personaB = b?.accountOwner?.user?.name || ''
          if (personaA && personaB) {
            return personaA.localeCompare(personaB)
          }
        },
        render(value: UserType | null, record: RowType, index: number) {
          return (
            <SingleSelectUserEditCell
              key={`${record._id}-accountOwner`}
              value={value}
              record={record}
              crmKey="accountOwner"
              index={index}
              form={form}
              isMultiEditing={selectedRowKeys.includes(record._id)}
            />
          )
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            Internal Slack Channel
          </div>
        ),
        width: '200px',
        key: 'internalSlackChannel',
        dataIndex: 'internalSlackChannel',
        sorter: (a, b) => {
          if (a.internalSlackChannel) {
            return a.internalSlackChannel.localeCompare(b.internalSlackChannel)
          }
        },
        render(value: string, record: RowType, index: number) {
          return (
            <SingleSelectChannelEditCell
              key={`${record._id}-internalSlackChannel`}
              value={value || 'NA'}
              crmKey="internal_slack_channel_name"
              record={record}
              index={index}
              dropdownList={channelList}
              form={form}
              isMultiEditing={selectedRowKeys.includes(record._id)}
            />
          )
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            External Slack Channel
          </div>
        ),
        sorter: (a, b) => {
          if (a.externalSlackChannel) {
            return a.externalSlackChannel.localeCompare(b.externalSlackChannel)
          }
        },
        width: '200px',
        key: 'externalSlackChannel',
        dataIndex: 'externalSlackChannel',
        render(value: string) {
          return truncate(value, 20)
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            Synced with CRM
          </div>
        ),
        width: '160px',
        key: 'syncedWithCRM',
        dataIndex: 'syncedWithCRM',

        render: (value: string, record: RowType, index: number) => {
          return (
            <SingleSelectEditCell
              key={`${record._id}-syncedWithCRM`}
              isBoolean
              record={record}
              crmKey="synced_with_crm"
              dropdownList={booleanList}
              form={form}
              index={index}
              value={value ? 'Yes' : 'No'}
              isMultiEditing={selectedRowKeys.includes(record._id)}
            />
          )
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">Users</div>
        ),
        key: 'users',
        width: '150px',
        dataIndex: 'users',
        render(value: UserType[], record: RowType, index: number) {
          if (value?.length) {
            const surplusElementCount = value.slice(2).length
            return (
              <UsersGroup key={`${record._id}-users`}>
                {value.slice(0, 2).map(({ user }, index) => {
                  return (
                    <UserWithProfile>
                      <StyledAvatar
                        size={20}
                        src={user?.slack?.profile?.image_original || ''}
                        className={
                          index === 1 ? 'avatar avatar-shift' : 'avatar'
                        }
                      >
                        {user?.name}
                      </StyledAvatar>
                    </UserWithProfile>
                  )
                })}
                {!!surplusElementCount && (
                  <SurplusIdicator>+{surplusElementCount}</SurplusIdicator>
                )}
              </UsersGroup>
            )
          }
          return 'NA'
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            Start Date
          </div>
        ),
        width: '150px',
        key: 'contractStartDate',
        dataIndex: 'contractStartDate',
        sorter: (a, b) => {
          a = new Date(a.contractStartDate)
          b = new Date(b.contractStartDate)
          return a > b ? -1 : a < b ? 1 : 0
        },
        render(value: string, record: RowType, index: number) {
          return (
            <DateSelectCell
              key={`${record._id}-contractStartDate`}
              value={value}
              record={record}
              index={index}
              form={form}
              crmKey="contract_start_date"
              isMultiEditing={selectedRowKeys.includes(record._id)}
            />
          )
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            Renewal date
          </div>
        ),
        width: '150px',
        key: 'contractEndDate',
        dataIndex: 'contractEndDate',
        sorter: (a, b) => {
          a = new Date(a.contractEndDate)
          b = new Date(b.contractEndDate)
          return a > b ? -1 : a < b ? 1 : 0
        },
        render(value: string, record: RowType, index: number) {
          return (
            <DateSelectCell
              key={`${record._id}-contractEndDate`}
              value={value}
              record={record}
              form={form}
              index={index}
              crmKey="contract_end_date"
              isMultiEditing={selectedRowKeys.includes(record._id)}
            />
          )
        },
      },
      {
        title: (
          <div className="flex items-center justify-start gap-2">
            Contract Duration
          </div>
        ),
        width: '180px',
        key: 'contractTerm',
        dataIndex: 'contractTerm',
        sorter: (a, b) => {
          if (a.contractTerm === undefined || b.contractTerm === undefined) {
            return a.contractTerm === undefined ? -1 : 1
          }
          return a.contractTerm - b.contractTerm
        },
        render(value: string, record: RowType, index: number) {
          return (
            <TextEditCell
              key={`${record._id}-contractTerm`}
              isNumber
              value={value || '0'}
              record={record}
              postFix={pluralize(' month', Number(value || 0))}
              crmKey="contract_term"
              width={100}
              form={form}
              index={index}
              isMultiEditing={selectedRowKeys.includes(record._id)}
            />
          )
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, selectedRowKeys, channelList, enabledIntegrationDetails])

  const [isFetching, setIsFetching] = useState(false)
  const [currentRow, setCurrentRow] = useState<string | null>(null)
  const onRefreshClick = (record: RowType) => {
    setCurrentRow(record?._id)
    setIsFetching(true)
    if (crmDetails?.type) {
      fetchAccountsFromCRM(crmDetails?.type, record?._id)
        .then((data: RelationshipType[]) => {
          accountDB.relationship
            .put(data[0], record?._id)
            .then(() => message.success('Refreshed Successfully!'))
        })
        .catch(() => setIsFetching(false))
        .finally(() => setIsFetching(false))
    } else {
      fetchRelationships()
        .then((data: RelationshipType[]) => {
          accountDB.relationship
            .bulkPut(data)
            .then(() => message.success('Refreshed Successfully!'))
        })
        .catch((err) => {
          console.log(err)
          setIsFetching(false)
          message.error('Something went wrong!')
        })
        .finally(() => setIsFetching(false))
    }
  }

  const dynamicColumns: ColumnsType<any> = useMemo(() => {
    const dynamicList = [
      ...crmConfig.additional_fields.map((item) => {
        return {
          title: (
            <div className="flex items-center justify-start gap-2">
              {getCRMIcon(item.isFromCRM ?? false)}
              {item.field_name}
            </div>
          ),
          key: camelCase(item.field_name),
          width: item.field_name.length > 12 ? '180px' : '150px',
          dataIndex: camelCase(item.field_name),
          //Todo: add sorter for different types
          render: (
            value: CRMAdditonalFieldType['values'] | null,
            record: RowType
          ) => {
            if (item.type === 'radio' || item.type === 'boolean') {
              return (
                <SingleEditCell
                  field={item}
                  value={value}
                  record={record}
                  isMultiEditing={selectedRowKeys.includes(record._id)}
                />
              )
            }
            if (item.type === 'text') {
              return (
                <TextInputEditCell
                  field={item}
                  record={record}
                  isMultiEditing={selectedRowKeys.includes(record._id)}
                />
              )
            }
            if (
              item.type === 'select' ||
              item.type === 'multiselect' ||
              item.type === 'checkbox'
            ) {
              return (
                <MultiEditCell
                  field={item}
                  record={record}
                  isMultiEditing={selectedRowKeys.includes(record._id)}
                />
              )
            }
          },
        }
      }),
    ]
    return dynamicList
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    selectedRowKeys,
    content,
    crmConfig.additional_fields,
    currentRow,
    isFetching,
  ])

  const updatedDynamicColumns = useMemo(() => {
    return [
      ...dynamicColumns.filter((item1) =>
        selectedFilters.some((item2) => item1.key === camelCase(item2))
      ),
      {
        title: (
          <ConfigProvider
            renderEmpty={() => {
              return 'No Data'
            }}
          >
            <Popover
              content={content}
              trigger="click"
              placement="bottomRight"
              showArrow={false}
              style={{
                width: '150px',
              }}
            >
              <PlusCircleOutlined
                className="text-[#372B93] cursor-pointer font-bold"
                style={{
                  fontSize: '20px',
                  right: '20px',
                  bottom: '16px',
                  position: 'absolute',
                }}
              />
            </Popover>
          </ConfigProvider>
        ),
        key: 'action',
        width: '70px',
        fixed: 'right' as FixedType,
        render: (value: string, record: RowType, index: number) => {
          return (
            <Tooltip title="Refresh data">
              <Refresh
                disabled={isFetching && currentRow === record?._id}
                $isFetching={isFetching && currentRow === record?._id}
                $fromTable={true}
                onClick={() => onRefreshClick(record)}
              >
                <ArrowsCounterClockwise
                  size={16}
                  weight="bold"
                  className="refresh-icon cursor-pointer"
                  style={{ flexShrink: 0 }}
                  color="var(--color-blue-1)"
                />
              </Refresh>
            </Tooltip>
          )
        },
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicColumns, content, selectedFilters, isFetching, currentRow])

  const updatedColumns = useMemo(() => {
    return [...columns, ...updatedDynamicColumns]
  }, [columns, updatedDynamicColumns])

  const [col, setCol] = useState(updatedColumns)

  useEffect(() => {
    setCol(updatedColumns)
  }, [updatedColumns])

  const selectRow = (record: any) => {
    const updatedSelectedRowKeys = [...selectedRowKeys]
    if (updatedSelectedRowKeys.includes(record._id)) {
      const index = updatedSelectedRowKeys.indexOf(record._id)
      updatedSelectedRowKeys.splice(index, 1)
    } else {
      updatedSelectedRowKeys.push(record._id)
    }
    setSelectedRowKeys(updatedSelectedRowKeys)
  }

  const onRowClick = (record: any) => {
    return {
      onClick: (event: any) => {
        const cellIndex = event.target.cellIndex
        if (
          cellIndex !== undefined &&
          cellIndex !== 1 &&
          !isEmpty(selectedRowKeys)
        ) {
          selectRow(record)
        }
      },
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRowKeys(selectedRowKeys)
    },

    getCheckboxProps: (record: any) => {
      return {
        name: record._id,
      }
    },
    selectedRowKeys: selectedRowKeys,
  }

  const dirtyValues = (dirtyFields: any, allValues: any): object => {
    // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
    // "placeholders" for unchanged elements. `dirtyFields` is true for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues
    // Here, we have an object
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key]),
      ])
    )
  }

  const onFormSubmit = () => {
    const dirtyFields: any = dirtyValues(
      form.formState.dirtyFields,
      form.getValues()
    )

    // const allFormValues = form.getValues()

    Object.keys(dirtyFields).map((key) => {
      const current = { ...dirtyFields[key] }
      current.synced_with_crm = current.synced_with_crm == 'yes' ? true : false
      updateCrm.mutateAsync({ relationId: key, crmValues: current })
    })
  }

  useEffect(() => {
    if (
      allUpdated === Object.keys(form.formState.dirtyFields).length &&
      allUpdated > 0 &&
      Object.keys(form.formState.dirtyFields).length > 0
    ) {
      setAllUpdated(0)
      setSelectedRowKeys([])
      setLoading(true)
      fetchRelationships()
        .then((data: RelationshipType[]) => {
          setLoading(false)
          accountDB.relationship.bulkPut(data).then(() => form.reset())
        })
        .catch((err) => console.log(err))
    }
  }, [allUpdated])

  const dispatch = useAccountsStore((state) => state.dispatch)

  const updateCrm = useMutation(updateAllCRMValuesOnRelation, {
    onSuccess: () => {
      setAllUpdated((prev) => prev + 1)
      dispatch({
        type: 'UPDATE_CRM_RELATIONSHIP',
        payload: {
          updatedAt: getCurrentTimeStamp(),
        },
      })
      // message.success({ content: 'Successfully updated', key: 'updateCRM' })
    },
    onError: () => {
      message.error('Something went wrong!')
    },
  })
  // const [TableForm] = Form.useForm()

  const setSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const dragProps = {
    onDragEnd(fromIndex: number, toIndex: number) {
      fromIndex--
      toIndex--
      const newColumns = [...col]
      if (
        fromIndex <= 0 ||
        fromIndex >= newColumns.length - 1 ||
        toIndex <= 0 ||
        toIndex >= newColumns.length - 1
      ) {
        return
      }
      const item = newColumns.splice(fromIndex, 1)[0]

      newColumns.splice(toIndex, 0, item)
      setCol(newColumns)
    },
    nodeSelector: 'th',
  }

  return (
    <Wrapper>
      {(updateCrm.isLoading || loading) && <ChartLoader />}
      <form name="basic">
        {selectedRowKeys.length > 0 && (
          <TableFilters
            form={form}
            onFormSubmit={onFormSubmit}
            onFormCancel={() => form.reset()}
            selectedRowKeys={selectedRowKeys}
            dynamicFilters={crmConfig.additional_fields}
          />
        )}
        <ReactDragListView.DragColumn
          {...dragProps}
          lineClassName="reorder-column"
        >
          <AntTable
            size="large"
            columns={col}
            rowSelection={{
              ...rowSelection,
            }}
            className="account-table"
            rowKey={(record) => record._id}
            dataSource={[...filteredTableData]}
            showSorterTooltip={false}
            pagination={{
              size: 'small',
              showTitle: true,
              defaultCurrent: 1,
              defaultPageSize: 20,
              showLessItems: true,
              showSizeChanger: true,
              position: ['topRight'],
              total: tableData.length === 0 ? 1 : filteredTableData.length,
              showTotal: (total) => {
                return (
                  <StatsWrapper>
                    {total >= 0 && (
                      <SectionTitle>
                        <Input
                          name="search"
                          size="middle"
                          id="search"
                          className={`col-span-2 form-input w-60 shadow-sm`}
                          placeholder="Search"
                          prefix={
                            <MagnifyingGlass color="#211E24" weight="bold" />
                          }
                          onChange={setSearch}
                          allowClear
                        />
                      </SectionTitle>
                    )}
                    <CSVLink
                      data={[...filteredTableData]}
                      headers={headers}
                      filename="accounts.csv"
                    >
                      <Tooltip title="Download Report">
                        <Button
                          className="w-[24px] h-[24px] border border-[var(--color-gray-3)] border-solid rounded-md"
                          icon={
                            <DownloadOutlined
                              style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                color: 'var(--color-gray-6)',
                              }}
                            />
                          }
                        />
                      </Tooltip>
                    </CSVLink>
                  </StatsWrapper>
                )
              },
            }}
            scroll={{ x: 1000 }}
            sticky
            onRow={(record) => onRowClick(record)}
          />
        </ReactDragListView.DragColumn>
      </form>
    </Wrapper>
  )
}

export default Table
