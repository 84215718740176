import { api } from '.'
import type { SourceInputType, SourceType } from '../types'
import { ResourceType, SourceOriginType } from '../types'

export const createSource = ({
  assistantId,
  sourceData,
}: {
  assistantId: string
  sourceData: SourceInputType
}) => {
  let data: SourceInputType | FormData = {
    sourceName: sourceData.sourceName,
    type: sourceData.type,
  }
  if (sourceData.type === SourceOriginType.DOC && sourceData.files) {
    data = new FormData()
    data.append('sourceName', sourceData.sourceName)
    data.append('type', sourceData.type)
    data.append('files', sourceData.files)
  } else {
    data.link = sourceData.link
  }
  return api
    .post(`v2/knowledge-base-ai/assistant/${assistantId}/source`, data)
    .then(({ data }) => data.data)
}

export const fetchSources = async (
  assistantId: string
): Promise<SourceType[]> => {
  const { data } = await api.get(
    `v2/knowledge-base-ai/assistant/${assistantId}/source`
  )
  return data.data
}

export const updateResources = async ({
  assistantId,
  sourceId,
  resources,
}: {
  assistantId: string
  sourceId: string
  resources: ResourceType
}) => {
  return api.patch(
    `v2/knowledge-base-ai/assistant/${assistantId}/source/${sourceId}/resource`,
    resources
  )
}

export const updateSource = async ({
  assistantId,
  sourceId,
  sourceName,
}: {
  assistantId: string
  sourceId: string
  sourceName: string
}) => {
  return api.patch(
    `v2/knowledge-base-ai/assistant/${assistantId}/source/${sourceId}`,
    { sourceName }
  )
}
