import { Steps } from 'antd'
const { Step } = Steps
const Stepper = ({ current }: { current: number }) => {
  return (
    <Steps current={current}>
      <Step title="Sign In" />
      <Step title="Welcome" />
      <Step title="Add to Slack" />
    </Steps>
  )
}

export { Stepper }
