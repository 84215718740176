import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

const useQueryFilters = () => {
  const setSearchParams = useSearchParams()[1]

  const queryActions = useMemo(() => {
    const setParam = <T>(key: string, value: T) => {
      const encodedString = JSON.stringify(value)
      setSearchParams((param) => {
        param.delete(key)
        param.append(key, encodedString)
        return param
      })
    }

    const getParam = (key: string) => {
      let value
      setSearchParams((param) => {
        value = param.get(key)
        return param
      })

      try {
        return JSON.parse(value || '')
      } catch (err) {
        return value
      }
    }

    const removeParam = (key: string) => {
      setSearchParams((param) => {
        param.delete(key)
        return param
      })
    }

    return { setParam, getParam, removeParam }
  }, [setSearchParams])

  return queryActions
}

export default useQueryFilters
