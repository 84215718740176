import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { DateRangeType } from '../utils/dateUtils'
import useDebounceEffect from './useDebounceEffect'
import useQueryFilters from './useQueryFilters'

type UserInfoType = {
  id: string
  _id: string
  name: string
  image: string
  channelIds: string[]
  relationshipIds: string[]
}

export type KanbanFiltersType = {
  search: string
  cardType: string[]
  urgency: string[]
  allTags: string[]
  csm: UserInfoType[]
  sentiment: string[]
  customers: string[]
  assignedTo: string[]
  isEscalated: boolean
  assignedToMe: boolean
  dateRange: moment.Moment[]
  isInitialDateRange: boolean
  initDateRange: moment.Moment[]
  solutionEngineers: UserInfoType[]
  dateRangeType: DateRangeType | null
  sortBy: 'NEWEST' | 'OLDEST' | 'RECENT ACTIVITY'
  configuredFieldValues: Record<string, string[]>
  tab: string
  crmFieldValues: Record<string, string[]>
  accountTypes: string[]
}

const useKanbanQuery = (
  filters: KanbanFiltersType,
  queryTrackRef: React.MutableRefObject<boolean>,
  mountQueryUpdateHandler: boolean
) => {
  const queryActions = useQueryFilters()

  const updateQuery = useCallback((filters: KanbanFiltersType) => {
    if (!queryTrackRef.current) {
      return
    }

    const { setParam, removeParam } = queryActions
    Object.keys(filters).forEach((k) => {
      const key = k as keyof KanbanFiltersType
      const value = filters[key]

      if (Array.isArray(value)) {
        if (value.length) {
          switch (key) {
            case 'csm':
            case 'solutionEngineers':
              setParam(
                key,
                value.map((item) => (item as UserInfoType).id)
              )
              break

            case 'dateRange': {
              let startDate
              let endDate
              let initStartDate
              let initEndDate

              try {
                startDate = (value as moment.Moment[])[0].toISOString()
                endDate = (value as moment.Moment[])[1].toISOString()
                initStartDate = filters.initDateRange[0].toISOString()
                initEndDate = filters.initDateRange[1].toISOString()
              } catch (err) {
                //
              }

              if (startDate === initStartDate && endDate === initEndDate) {
                removeParam('dateRange')
              } else {
                setParam(key, value)
              }
              break
            }

            case 'initDateRange':
              break

            default:
              setParam(key, value)
          }
        } else {
          removeParam(key)
        }
      } else {
        switch (key) {
          case 'search':
          case 'cardType':
          case 'isEscalated':
          case 'assignedToMe':
          case 'dateRangeType':
          case 'accountTypes':
            if (value) {
              setParam(key, value)
            } else {
              removeParam(key)
            }
            break

          case 'isInitialDateRange':
            if (value) {
              removeParam(key)
            } else {
              setParam(key, value)
            }
            break

          case 'sortBy':
            if (value === 'NEWEST') {
              removeParam(key)
            } else {
              setParam(key, value)
            }
            break

          case 'configuredFieldValues': {
            if (typeof value === 'object' && value) {
              Object.keys(value).forEach((configureKey) => {
                const configureList = value[configureKey]
                if (configureList.length) {
                  setParam(configureKey, configureList)
                } else {
                  removeParam(configureKey)
                }
              })
            }
            break
          }
          case 'crmFieldValues': {
            if (typeof value === 'object' && value) {
              Object.keys(value).forEach((configureKey) => {
                const configureList = value[configureKey]
                if (configureList.length) {
                  setParam(configureKey, configureList)
                } else {
                  removeParam(configureKey)
                }
              })
            }
            break
          }
          default:
            setParam(key, value)
            break
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deps = useMemo(() => {
    return {
      ...filters,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, mountQueryUpdateHandler])

  useDebounceEffect(updateQuery, deps, 300)
}

export default useKanbanQuery
