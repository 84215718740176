import moment from 'moment'
import { RangeValue } from '../../types/accountsTypes'

export const BLOCKS: any = {
  IMPORT_FROM_CRM_TEXT: 'Import from CRM',
  CRM_OPTIONS: [
    { label: 'HubSpot', value: 'hubspot' },
    { label: 'SalesForce', value: 'salesforce' },
  ],
}

export const DATE_RANGES = {
  'Last 7 days': [moment().subtract(7, 'd'), moment()],
  'Last 14 days': [moment().subtract(14, 'd'), moment()],
  'Last 30 days': [moment().subtract(30, 'd'), moment()],
} as Record<string, Exclude<RangeValue<any>, null>>
