/* eslint-disable max-lines */
import { ConfigProvider, Empty, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  fetchConfigureFields,
  fetchKanban,
  fetchKanbanFilters,
} from '../api/kanban'
import { kanbanReducer } from '../reducer/kanbanReducer/kanbanReducer'
import {
  kanbanReducerState,
  kanbanType,
} from '../reducer/kanbanReducer/kanbanReducerState'
import {
  CONFIGURED_FIELDS,
  FETCH_FILTERS,
  MESSAGE_LOADING,
} from '../reducer/types/kanbanReducerAction'
import { DateRangeType, getDateRangeByType } from '../utils/dateUtils'
import {
  requestInsert,
  requestStatusChange,
} from '../utils/requestSocketHandlers'
import { ticketInsert, ticketStatusChange } from '../utils/ticketSocketHandlers'
import { useKanbanFilterV2 } from './KanbanFilterProviderV2'
import { useSocket } from './SocketProvider'

interface KanbanContextType {
  kanbanState: kanbanType
  kanbanDispatch: React.Dispatch<any>
}

const ConversationContextDefaults: KanbanContextType = {
  kanbanState: {} as kanbanType,
  kanbanDispatch: () => {},
}

export const Kanbanv2Provider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { socket } = useSocket()
  const queryClient = useQueryClient()
  const {
    search,
    dateRange,
    dateRangeType,
    isInitialDateRange,
    selectedFilterChannels,
    setDateRange,
  } = useKanbanFilterV2()
  const [kanbanState, kanbanDispatch] = useReducer(
    kanbanReducer,
    kanbanReducerState
  )

  const cancelTokenRef = useRef(axios.CancelToken.source())

  const isDateRangeAvailable = !!(dateRange && dateRange?.[0])

  const startDateTimeStamp = useMemo(
    () =>
      moment(dateRange?.[0] || null)
        .startOf('day')
        .valueOf()
        .toString(),
    [dateRange]
  )

  const endDateTimeStamp = useMemo(
    () =>
      moment(dateRange?.[1] || null)
        .endOf('day')
        .valueOf()
        .toString(),
    [dateRange]
  )

  const getFiltersInfo = useCallback(
    (isAPICall?: boolean) => {
      if (isDateRangeAvailable) {
        if (isAPICall && dateRangeType && dateRangeType !== 'ALL_TIME') {
          const todayTimeStamp = moment().endOf('day').valueOf().toString()
          if (endDateTimeStamp !== todayTimeStamp) {
            const range = getDateRangeByType(dateRangeType as DateRangeType)
            setDateRange(range)
          }
        }

        return {
          filters: {
            startDate: startDateTimeStamp,
            endDate: endDateTimeStamp,
          },
        }
      }
      return {
        filters: {
          startDate: '',
          endDate: '',
        },
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateRangeType, endDateTimeStamp, isDateRangeAvailable, startDateTimeStamp]
  )

  const {
    data: kanbanData,
    isLoading: kanbanMessageLoading,
    isSuccess: kanabanSuccess,
    isFetching: kanbanMessageFetching,
    refetch,
  } = useQuery<kanbanType>(
    ['allData', getFiltersInfo()],
    () => fetchKanban(getFiltersInfo(true), cancelTokenRef.current.token),
    {
      refetchInterval: 15 * 1000,
      onError: () => {
        message.error('Error fetching conversations')
      },
    }
  )

  // const { data: closedData, isSuccess: isClosedMessageSuccess } = useQuery<any>(
  //   ['closed', kanbanState?.page],
  //   () => fetchClosed(kanbanState?.page),
  //   {
  //     refetchInterval: 15 * 1000,
  //     onError: () => {
  //       message.error('Error fetching closed conversations')
  //     },
  //   }
  // )

  // useEffect(() => {
  //   if (isClosedMessageSuccess && closedData) {
  //     kanbanDispatch({
  //       type: CLOSED_INIT,
  //       payload: {
  //         data: closedData?.records,
  //       },
  //     })
  //     kanbanDispatch({
  //       type: SET_HAS_MORE,
  //       payload: {
  //         hasMore: closedData?.nextPage || false,
  //       },
  //     })
  //     kanbanDispatch({
  //       type: SET_CLOSED_COUNT,
  //       payload: {
  //         totalClosedMessages: closedData?.total || 0,
  //       },
  //     })

  //     const storedTags = JSON.parse(localStorage.getItem('kanbanTags') ?? '[]')
  //     const allTags = new Set(storedTags)
  //     if (closedData?.records) {
  //       const allRecords = closedData?.records
  //       allRecords?.forEach((item: any) => {
  //         item?.AI_generatedTags?.forEach((tags: string) => {
  //           allTags?.add(tags)
  //         })
  //       })
  //     }
  //     localStorage.setItem('kanbanTags', JSON.stringify([...allTags]))
  //   }
  // }, [isClosedMessageSuccess, closedData])

  useEffect(() => {
    if ((dateRange && dateRange[0]) || dateRange == null) {
      if (!isInitialDateRange) {
        cancelTokenRef.current.cancel()
        message.destroy()
        cancelTokenRef.current = axios.CancelToken.source()
        refetch()
        message.success('Fetching Data...')
      }
    }
  }, [dateRange])

  const { data: kanbanFilterData } = useQuery<kanbanType>(
    [],
    () => fetchKanbanFilters(),
    {
      onError: () => {
        message.error('Error fetching filters')
      },
    }
  )

  const { data: kanbanConfiguredData } = useQuery<kanbanType>(
    ['data'],
    () => fetchConfigureFields(),
    {
      onError: () => {
        message.error('Error fetching configure fields')
      },
    }
  )

  useEffect(() => {
    kanbanDispatch({
      type: MESSAGE_LOADING,
      payload: { messageLoading: kanbanMessageLoading },
    })
  }, [kanbanMessageLoading])

  useEffect(() => {
    kanbanDispatch({
      type: 'Kanban/INIT',
      payload: kanbanData,
    })
    const storedTags = JSON.parse(localStorage.getItem('kanbanTags') ?? '[]')
    const allTags = new Set(storedTags)
    if (kanbanData?.records) {
      const allRecords = kanbanData?.records
      allRecords?.forEach((item: any) => {
        item?.AI_generatedTags?.forEach((tags: string) => {
          allTags?.add(tags)
        })
      })
    }
    localStorage.setItem('kanbanTags', JSON.stringify([...allTags]))
  }, [kanabanSuccess, kanbanData])

  useEffect(() => {
    kanbanDispatch({
      type: FETCH_FILTERS,
      payload: { filters: kanbanFilterData },
    })
  }, [kanbanFilterData])

  useEffect(() => {
    kanbanDispatch({
      type: CONFIGURED_FIELDS,
      payload: { fields: kanbanConfiguredData },
    })
  }, [kanbanConfiguredData])

  socket?.on('suggested-request', (data) => {
    requestInsert({ data, queryClient, selectedFilterChannels })
  })

  socket?.on('suggested-request-status-change', (data) => {
    requestStatusChange({ data, queryClient, selectedFilterChannels })
  })

  socket?.on('request-created', (data) => {
    ticketInsert({ data, queryClient, selectedFilterChannels })
  })

  socket?.on('request-status-change', (data) => {
    ticketStatusChange({ data, queryClient, selectedFilterChannels })
  })

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
      <Empty />
    </div>
  )

  return (
    <KanbanContext.Provider
      value={useMemo(
        () => ({ kanbanState, kanbanDispatch, cancelTokenRef }),
        [kanbanState]
      )}
    >
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        {children}
      </ConfigProvider>
    </KanbanContext.Provider>
  )
}

const KanbanContext = createContext<any>(ConversationContextDefaults)

export const useKanbanV2 = () => {
  return useContext(KanbanContext)
}
