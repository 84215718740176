import {
  ArrowsAltOutlined,
  PlusOutlined,
  ShrinkOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

import { useState } from 'react'
import HorizontalList from '../../../../components/common/HorizontalList/HorizontalList'
import { CreateQuestionModal } from '../Modals/CreateQuestionModal'
import { DefaultFilters } from './DefaultFilters'

type FiltersProps = {
  expandAll: () => void
  expandAllStatus: boolean
  updateStatus: boolean
}

const Filters = ({
  expandAll,
  expandAllStatus,
  updateStatus,
}: FiltersProps) => {
  const [questionModal, setQuestionModal] = useState(false)

  const addQuestion = () => {
    setQuestionModal(true)
  }

  return (
    <Wrapper>
      <HorizontalList listItems={<DefaultFilters />} />
      <div
        className={`expand-all ${updateStatus ? 'disabled' : ''}`}
        onClick={() => !updateStatus && expandAll()}
        aria-disabled={updateStatus ? 'true' : 'false'}
      >
        {expandAllStatus ? (
          <>
            <ShrinkOutlined className="expand-icon" />
            Collapse All
          </>
        ) : (
          <>
            <ArrowsAltOutlined className="expand-icon" />
            Expand All
          </>
        )}
      </div>
      <Button
        className="add-button"
        onClick={addQuestion}
        icon={<PlusOutlined className="plus-icon" />}
      >
        New Question
      </Button>
      <CreateQuestionModal
        isOpen={questionModal}
        onClose={() => setQuestionModal(false)}
      />
    </Wrapper>
  )
}

export default Filters

const Wrapper = styled.div`
  height: 40px;
  padding: 0px 24px 15px 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-3);
  position: relative;
  .qna-filter {
    flex-shrink: 0;
    margin-right: 8px;
  }
  .scroll-wrapper {
    margin-right: 240px;
  }
  .expand-all {
    height: 28px;
    right: 160px;
    display: flex;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    align-items: center;
    padding: 4px 12px 4px 8px;
    color: var(--color-gray-4);
    .expand-icon {
      padding-right: 6px;
    }
  }
  .expand-all.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .add-button {
    right: 24px;
    height: 28px;
    width: 126px;
    display: flex;
    padding: 4px 12px 4px 8px;
    position: absolute;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: var(--color-white);
    justify-content: space-evenly;
    background-color: var(--color-purple-1);
    border-color: var(--color-purple-1);
  }
  .add-button:hover {
    background-color: var(--color-purple-6);
  }
  .add-button .plus-icon {
    margin-right: -4px;
    display: flex;
  }
`
