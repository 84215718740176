import { useFlags } from 'launchdarkly-react-client-sdk'
import { difference } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { AuthenticatedConnectUser, ConnectSDK } from '../../paragon'
import {
  addCustomFields,
  fetchAccountsFromCRM,
  removeCustomFields,
} from '../api/accounts'
import { PARAGON_PROJECT_ID } from '../config'
import { useAuth } from '../context'
import { defaultFieldsForMapping } from '../pages/Accounts/utils'
import { useAccountsStore } from '../store/accountsStore'
import { emptyObject } from '../utils/empty'

export default function useParagonAuth(paragon?: ConnectSDK): {
  user?: AuthenticatedConnectUser
  error?: Error
  loading: boolean
} {
  const { user: currentUser } = useAuth()
  const [user, setUser] = useState<AuthenticatedConnectUser | undefined>()
  const [error, setError] = useState<Error | undefined>()
  const [loading, setLoading] = useState(true)
  const crmConfig = useAccountsStore((state) => state.crmConfig) ?? emptyObject
  const accountDispatch = useAccountsStore((state) => state.dispatch)
  const apiStateTrackerRef = useRef(false)
  const workflowStateChangeTrackerRef = useRef(false)
  const flags = useFlags()

  useEffect(() => {
    let integration = ''

    const listener = () => {
      if (paragon) {
        const authenticatedUser = paragon.getUser()
        if (authenticatedUser.authenticated) {
          setUser({ ...authenticatedUser })
        }
      }
    }
    paragon?.subscribe('onIntegrationInstall', listener)
    paragon?.subscribe('onIntegrationUninstall', listener)
    paragon?.subscribe('onPortalOpen', function (event, _) {
      const { integrationType } = event
      integration = integrationType
      listener()
    })
    paragon?.subscribe('onWorkflowChange', function (event, _) {
      const { workflowId, workflowStateChange } = event

      const configuredFetchAccountsWorkflowMap =
        crmConfig.fetchAccountsWorkflowMap || {}
      const configuredFetchAccountsWorkflow =
        configuredFetchAccountsWorkflowMap?.[integration] || ''

      if (crmConfig?.fetchSummariesWorkflows?.includes(workflowId)) {
        if (workflowStateChange?.enabled) {
          paragon?.workflow(workflowId, {
            body: { enabled: true },
          })
        }
      }

      if (workflowId !== configuredFetchAccountsWorkflow) {
        return
      }

      if (
        workflowStateChange.enabled &&
        !workflowStateChangeTrackerRef.current
      ) {
        fetchAccountsFromCRM(integration, undefined)
        workflowStateChangeTrackerRef.current = true
      }
    })
    paragon?.subscribe('onPortalClose', function (event, user) {
      // detect user properties when portal is closed
      const { integrationType } = event
      const integrationDetails = user?.integrations?.[integrationType] || {}
      const mappingsOnClose =
        Object.values(integrationDetails?.sharedSettings || {})?.[0]
          ?.dynamicMappings || {}
      const onCloseMappedFields = Object.values(mappingsOnClose).map((field) =>
        field.integrationField.toLocaleLowerCase()
      )

      // get properties present already on installation
      const oldMappedFields = defaultFieldsForMapping.map(
        (field) => field.value
      )

      const mappedDefaultFields: any = {}

      Object.values(mappingsOnClose).forEach((field) => {
        if (oldMappedFields.includes(field.applicationField)) {
          mappedDefaultFields[field.integrationField] = field.applicationField
        }
      })

      const existingAdditionalFields = crmConfig?.additional_fields
        ?.filter((field) => field.isFromCRM && !field.isDeleted)
        .map((field) => field.field_name_normalized)
      oldMappedFields.push(...existingAdditionalFields)

      if (flags.consoleLoggingEnabled) {
        console.info({
          onCloseMappedFields,
          oldMappedFields,
          ref: apiStateTrackerRef.current,
        })
      }

      if (
        (difference(onCloseMappedFields, oldMappedFields)?.length ||
          difference(existingAdditionalFields, onCloseMappedFields)?.length) &&
        !apiStateTrackerRef.current
      ) {
        const addedFields = difference(onCloseMappedFields, oldMappedFields)
        const removedFields = difference(
          existingAdditionalFields,
          onCloseMappedFields
        )
        if (removedFields.length) {
          removeCustomFields(removedFields)

          const removedFieldsWithDetails = crmConfig?.additional_fields.filter(
            (field) => removedFields.includes(field.field_name_normalized)
          )

          removedFieldsWithDetails.forEach((removedField) => {
            accountDispatch({
              type: 'UPDATE_CRM_CONFIG_ADDITIONAL_FIELDS',
              payload: {
                additional_fields: {
                  isFromCRM: false,
                  _id: removedField._id,
                },
              },
            })
          })
        }

        if (addedFields.length) {
          // call backend API with this data
          addCustomFields(addedFields, mappedDefaultFields)
        }
        apiStateTrackerRef.current = true
      }
    })

    return () => {
      paragon?.unsubscribe('onIntegrationInstall', listener)
      paragon?.unsubscribe('onIntegrationUninstall', listener)
      paragon?.unsubscribe('onPortalOpen', listener)
      paragon?.unsubscribe('onPortalClose', listener)
      paragon?.unsubscribe('onWorkflowChange', listener)
    }
  }, [
    paragon?.getUser(),
    crmConfig.fetchAccountsWorkflowMap,
    crmConfig?.additional_fields,
    flags.consoleLoggingEnabled,
    accountDispatch,
  ])

  useEffect(() => {
    if (paragon && !error) {
      paragon
        ?.authenticate(PARAGON_PROJECT_ID, currentUser?.paragon_token || '', {
          metadata: {
            name: currentUser?.team_name,
          },
        })
        .then(() => {
          const authenticatedUser = paragon?.getUser()
          setLoading(false)
          if (authenticatedUser.authenticated) {
            setUser(authenticatedUser)
          }
        })
        .catch(setError)
    }
  }, [error, currentUser?.paragon_token, currentUser?.team_name, paragon])

  useEffect(() => {
    if (apiStateTrackerRef.current) {
      apiStateTrackerRef.current = false
    }
    if (workflowStateChangeTrackerRef.current) {
      workflowStateChangeTrackerRef.current = false
    }
  }, [paragon?.getUser()])

  return { user, error, loading }
}
