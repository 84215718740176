import { Tag } from 'antd'
import styled from 'styled-components'

type CustomTagProps = {
  description: string
  color: string
  bgColor: string
}

export const CustomTag = ({ description, color, bgColor }: CustomTagProps) => {
  return (
    <Wrapper>
      <Tag
        className="custom-tag"
        style={{ backgroundColor: bgColor, color: color }}
      >
        {description}
      </Tag>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  .custom-tag {
    padding: 4px 8px;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 500;
    margin-top: 32px;
  }
`
