import { DownOutlined, PlusOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps, Row, Space } from 'antd'
import { useState } from 'react'
import { Link, LinkProps, useParams } from 'react-router-dom'
import styled from 'styled-components'
import useAssistants from '../../../../hooks/useAssistants'
import CheckIcon from '../../../../images/icons/assistant-check.svg'
import { CreateAssistantModal } from '../Modals/CreateAssistantModal'
import { EditAssistantModal } from '../Modals/EditAssistantModal'

interface SelectLinkProps extends LinkProps {
  selected: boolean
}

const AssistantSelector = () => {
  const [showAssistantModal, setShowAssistantModal] = useState({
    show: false,
    item: null,
  })

  const { data: assistants = [] } = useAssistants()
  const params = useParams()
  let assistantId = ''
  if (params['id'] && params['id'] !== '') assistantId = params['id']
  else if (params['*'] && params['*'] !== '') assistantId = params['*']
  const assistant = assistants.find((a) => a._id === assistantId)
  const toggleModal = (item = null) => {
    setShowAssistantModal({ show: !showAssistantModal.show, item })
  }

  const items: MenuProps['items'] = [
    ...assistants.map((data) => ({
      label: (
        <AssistantMenuItem
          to={`/knowledge-assistant/${data._id}`}
          selected={assistantId === data._id}
        >
          {data.name}
        </AssistantMenuItem>
      ),
      key: data._id,
    })),
    { type: 'divider' },
    {
      label: (
        <Row
          align="middle"
          style={{
            padding: '4px',
            gap: '8px',
            fontSize: '16px',
            color: 'var(--color-black-1)',
          }}
          onClick={() => toggleModal()}
        >
          <PlusOutlined />
          Create New Assistant
        </Row>
      ),
      key: 'add',
    },
  ]

  return (
    <Wrapper>
      <Dropdown
        overlayStyle={{
          fontSize: '16px',
          maxHeight: '300px',
          overflowY: 'auto',
          borderRadius: '6px',
          boxShadow:
            '0px 20px 35px -5px rgba(12, 12, 13, 0.10), 0px 4px 10px 0px rgba(12, 12, 13, 0.05), 0px 0px 0px 1px rgba(12, 12, 13, 0.03)',
        }}
        menu={{ items }}
        trigger={['click']}
      >
        <Space className="select-space">
          {assistant ? assistant.name : 'Assistant Selector'}
          <DownOutlined />
        </Space>
      </Dropdown>
      {showAssistantModal.show &&
        (showAssistantModal.item ? (
          <EditAssistantModal
            isOpen={showAssistantModal.show}
            onClose={() => toggleModal()}
          />
        ) : (
          <CreateAssistantModal
            isOpen={showAssistantModal.show}
            onClose={() => toggleModal()}
          />
        ))}
    </Wrapper>
  )
}

export default AssistantSelector

const Wrapper = styled.div`
  padding: 4px;
  .select-space {
    font-size: 16px;
    color: var(--color-black-1);
    cursor: pointer;
  }
`

const AssistantMenuItem = styled(Link).attrs<SelectLinkProps>(() => ({
  tabIndex: -1,
}))<SelectLinkProps>`
  color: var(--color-black-1) !important;
  padding: 4px 40px 4px 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    font-weight: bold;
  }

  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  background-image: ${(props) =>
    props.selected ? `url(${CheckIcon})` : 'none'};
  background-repeat: no-repeat;
  background-position: right center;
`
