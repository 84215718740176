/* eslint-disable max-lines */
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Empty,
  Input,
  Menu,
  MenuProps,
  message,
  Modal,
  Spin,
  Tooltip,
  Typography,
} from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { isEmpty } from 'lodash'
import { Hash, Lock, MagnifyingGlass } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import {
  getSentChannelDetails,
  unsendCampaign,
} from '../../api/marketingAutomation'
import CloseIcon from '../../images/icons/close_icon.svg'
import { ChannelType } from './SendCampaignModal'

const { Text } = Typography
const { Panel } = Collapse
const { confirm } = Modal
const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  position: relative;
  height: 32px;
  .add-filter-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-gray-4);
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .plus-icon {
      margin-right: 2px;
    }
    cursor: pointer;
    padding: 15px 6px;
  }
  .reset-btn {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: var(--color-gray-4);
    height: 24px;
    padding: 15px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 4px;
    }
    cursor: pointer;
  }
  .add-filter-btn:hover,
  .reset-btn:hover {
    background-color: var(--color-gray-3);
    border-radius: 4px;
  }
`
export const UnsendCampaign = ({
  showUnsendModal,
  closeSaveCampaignModal,
  campaign,
  setSearch,
  search,
}: {
  showUnsendModal?: boolean
  closeSaveCampaignModal: () => void
  campaign: any
  setSearch: React.Dispatch<React.SetStateAction<string>>
  search: string
}) => {
  const [selectedChannels, setSelectedChannels] = useState<string[]>([])
  const [selectedChannelGroup, setSelectedChannelGroup] = useState<string[]>([])
  const {
    data = {
      channels: [],
      groups: [],
    },
    isLoading,
    refetch,
  } = useQuery(
    ['marketing-campaign-sent-channel-details', campaign?._id],
    () => getSentChannelDetails(campaign?._id),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data) => {
        setSelectedChannels([
          ...new Set<string>(data?.channels?.map((item: any) => item.id) || []),
        ])
        const groupNames = data?.groups?.map((item: any) => item.groupName)
        setSelectedChannelGroup(groupNames)
      },
    }
  )
  useEffect(() => {
    showUnsendModal && refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUnsendModal])

  const handleCheckBox = ({
    checked,
    channelId,
  }: {
    checked: boolean
    channelId: string
  }) => {
    setSelectedChannels((channelIdArray) => {
      const index = channelIdArray.indexOf(channelId)
      if (checked && index >= -1) {
        return [...channelIdArray, channelId]
      }

      if (!checked && index >= -1) {
        return [...channelIdArray].filter((pCh) => pCh !== channelId)
      }

      return channelIdArray
    })
  }
  const handleChannelsFromGroup = (selectedKeys: string[]) => {
    const channelSet = new Set<string>()
    const groups = data?.groups?.filter((ele: any) =>
      selectedKeys.includes(ele.groupName)
    )
    groups.forEach((grp: any) => {
      ;(grp?.channelIds || []).forEach((c: string) => {
        channelSet.add(c)
      })
    })
    const grpChannelIds: string[] = data?.groups
      ?.map((c: any) => c.channelIds)
      .reduce((acc: string[], channels: string[]) => [...acc, ...channels], [])
    const channelsNotInGrp = selectedChannels.filter(
      (c) => !grpChannelIds.includes(c)
    )
    setSelectedChannels([
      ...new Set<string>([...channelSet, ...channelsNotInGrp]),
    ])
  }
  const handleGroupFromChannelsSelected = (channels: string[]) => {
    if (isEmpty(selectedChannelGroup) && isEmpty(channels)) {
      return
    }
    ;(data?.groups || []).forEach((grp: any) => {
      if (grp.channelIds.length <= channels.length) {
        const hasGrpChannels = grp.channelIds.every((c: string) =>
          channels.includes(c)
        )
        if (hasGrpChannels) {
          setSelectedChannelGroup((gps) => [
            ...new Set([...gps, grp.groupName]),
          ])
        } else {
          setSelectedChannelGroup((gps) =>
            gps.filter((gp) => gp !== grp.groupName)
          )
        }
      } else {
        setSelectedChannelGroup((gps) =>
          gps.filter((gp) => gp !== grp.groupName)
        )
      }
    })
  }
  useEffect(() => {
    handleGroupFromChannelsSelected(selectedChannels)
  }, [selectedChannels])

  const items: MenuProps['items'] = data?.groups?.map((record: any) => {
    return {
      key: record?.groupName,
      label: (
        <div className="flex items-center justify-between">
          {/* <Text ellipsis={{ tooltip: true }}></Text> */}
          {record?.groupName}
        </div>
      ),
    }
  })
  const handleMenuSelect = ({ selectedKeys }: { selectedKeys: string[] }) => {
    setSelectedChannelGroup(selectedKeys)
    handleChannelsFromGroup(selectedKeys)
  }
  const handleMenuDeselect = ({ selectedKeys }: { selectedKeys: string[] }) => {
    setSelectedChannelGroup(selectedKeys)
    handleChannelsFromGroup(selectedKeys)
  }
  const queryClient = useQueryClient()
  const unsendCampaignMutation = useMutation(unsendCampaign, {
    onSuccess: (data: any) => {
      closeSaveCampaignModal()
      queryClient.invalidateQueries(['all-marketing-campaigns'])
    },
    onError: () => {
      message.error('Cannot unsend')
    },
  })
  const handleAddAllChannels = (e: any) => {
    e.stopPropagation()
    setSelectedChannels((prevSelectedChannels) => [
      ...prevSelectedChannels,
      ...(data?.channels || []).map(({ id }: { id: any }) => id),
    ])
  }

  const handleClearAllChannels = (e: any) => {
    e.stopPropagation()
    const filteredSelectedState = selectedChannels.filter(
      (id) => ![...(data?.channels || [])].map(({ id }) => id).includes(id)
    )
    setSelectedChannels(filteredSelectedState)
  }
  const handleSubmit = () => {
    let hasError = false
    if (selectedChannels.length <= 0) {
      hasError = true
      message.error('Select at least one channel')
    }
    if (!hasError) {
      unsendCampaignMutation.mutate({
        campaignId: campaign?._id,
        channels: [...new Set<string>(selectedChannels)],
      })
    }
  }
  const getExtra = () => (
    <FilterButton className="wsa-filter-chip">
      <div className="add-filter-btn" onClick={(e) => handleAddAllChannels(e)}>
        <PlusOutlined className="plus-icon" />
        Add All
      </div>
      <div className="reset-btn" onClick={(e) => handleClearAllChannels(e)}>
        <img src={CloseIcon} alt="refresh icon" className="w-3 h-3" />
        Clear All
      </div>
    </FilterButton>
  )
  const selectedChannelsList = (data?.channels || []).filter(
    (channel: ChannelType) => selectedChannels.includes(channel.id)
  )
  const showConfirm = () => {
    confirm({
      title: 'Confirm Unsend Action?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          This action will unsend the message from{' '}
          <Tooltip
            title={
              <div className="flex flex-col">
                {selectedChannelsList.map((item: any) => (
                  <span>{item.name}</span>
                ))}
              </div>
            }
          >
            <span className="text-[var(--color-blue-1)] cursor-pointer">
              {selectedChannels?.length}
              {selectedChannels?.length === 1 ? ' channel' : ' channels'}
            </span>
          </Tooltip>
          . Please confirm this action as it cannot be undone.
        </div>
      ),
      onOk() {
        handleSubmit()
      },
      onCancel() {},
      okText: 'Confirm',
      centered: true,
      width: 450,
    })
  }
  return (
    <Modal
      centered
      style={{ top: 20 }}
      open={showUnsendModal}
      footer={null}
      title="Unsend Campaign"
      width={'60%'}
      onCancel={(e) => {
        e.stopPropagation()
        closeSaveCampaignModal()
      }}
    >
      {
        <>
          <div className="flex items-center w-full justify-start mb-3">
            <Text className="mr-3" strong>
              Campaign Name:
            </Text>
            <div>{campaign?.campaign_name}</div>
          </div>
          {/** Channel Container */}
          <div className="mt-6">
            <div className="text-sm font-medium">
              Sent channels ({data?.channels?.length})
            </div>
            <div
              className="mt-3 border border-solid border-brand-border p-2 rounded h-96"
              style={{ position: 'relative' }}
            >
              <div className="flex justify-between items-center">
                <div className="flex items-center justify-start gap-3 relative">
                  <Input
                    name="search"
                    size="large"
                    allowClear
                    id="search"
                    className="form-input w-60 h-8 shadow-sm col-span-2"
                    placeholder="Search"
                    prefix={<MagnifyingGlass />}
                    autoComplete={'off'}
                    onChange={(e) => setSearch(e.target.value)}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <Divider className="m-0 mt-2" />
              {isLoading ? (
                <div className=" my-4 flex justify-center items-center">
                  <Spin />
                </div>
              ) : (
                <div className="relative h-5/6 w-full">
                  <div
                    className={`mb-3 px-2 pr-2 mt-2 border-solid border border-brand-border rounded bg-white h-full w-[70%]`}
                  >
                    <Collapse
                      collapsible="icon"
                      bordered={false}
                      defaultActiveKey={['1']}
                      expandIconPosition="end"
                      className="ml-5 border-none mb-3 mt-3 h-[95%] overflow-y-auto overflow-x-hidden bg-white"
                    >
                      <Panel
                        header={
                          <div className="text-base font-semibold text-[#4a299c]">
                            Campaigns Sent on Channels ({data?.channels?.length}
                            )
                          </div>
                        }
                        key="1"
                        extra={getExtra()}
                      >
                        <>
                          {isEmpty(data?.channels) ? (
                            <div className="flex justify-center items-center  w-full">
                              <Empty description="No Channels to display!" />{' '}
                            </div>
                          ) : (
                            <div className="grid grid-cols-2 gap-4 my-4">
                              {data?.channels
                                ?.sort((a: any, b: any) =>
                                  a.name.localeCompare(b.name)
                                )
                                ?.filter((c: any) =>
                                  c.name
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                )
                                .map((channel: ChannelType) => (
                                  <div
                                    key={channel.id}
                                    className="inline-flex items-center h-full"
                                  >
                                    <Checkbox
                                      className="border-black"
                                      checked={selectedChannels.includes(
                                        channel.id
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                      }}
                                      onChange={({
                                        target: { checked },
                                      }: CheckboxChangeEvent): void => {
                                        handleCheckBox({
                                          checked,
                                          channelId: channel.id,
                                        })
                                      }}
                                    >
                                      <div
                                        className="flex justify-between w-full items-center font-medium text-ellipsis"
                                        title={channel.name}
                                        onClick={(e) => {
                                          e.stopPropagation()
                                        }}
                                      >
                                        <div
                                          className={`flex items-center gap-1 text-default`}
                                        >
                                          {channel.is_private ? (
                                            <Lock size={16} />
                                          ) : (
                                            <Hash size={16} />
                                          )}
                                          <Text
                                            ellipsis={{ tooltip: true }}
                                            className={`w-36 text-default`}
                                          >
                                            {channel.name}
                                          </Text>
                                        </div>
                                      </div>
                                    </Checkbox>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      </Panel>
                    </Collapse>
                  </div>
                  <div className="grid gap-1 mb-4 right-0 top-0 absolute h-full border border-solid border-brand-border rounded">
                    <div
                      style={{
                        height: '100%',
                        backgroundColor: '#fff',
                        width: '250px',
                        transition: 'width 0.3s',
                        overflow: 'scroll',
                      }}
                    >
                      <>
                        <div
                          className={`bg-white w-full flex items-center filter  justify-start gap-1`}
                        >
                          <div className="mt-1 mb-2 p-5 text-base font-semibold text-[#4a299c]">
                            Audience
                          </div>
                        </div>
                        {items?.length === 0 && (
                          <div className="w-full bg-white h-full">
                            {
                              <span className="bg-[var(--color-gray-1)] p-1 rounded text-[var(--color-gray-4)] flex flex-col items-start justify-center w-11/12 mx-2 mt-2 text-xs 3xl:text-[length:var(--xs)] font-medium">
                                <span>No Groups to show</span>
                              </span>
                            }
                          </div>
                        )}
                        <Menu
                          multiple={true}
                          mode="inline"
                          theme="light"
                          items={items}
                          style={{
                            width: '100%',
                            backgroundColor: '#fff',
                          }}
                          selectedKeys={[
                            ...(selectedChannelGroup || []).map((item) => item),
                          ]}
                          onSelect={handleMenuSelect}
                          onDeselect={handleMenuDeselect}
                        />
                      </>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-end gap-3 mt-3 w-full">
            <div className="flex gap-2 items-center">
              <Button
                onClick={showConfirm}
                type="primary"
                loading={unsendCampaignMutation.isLoading}
                disabled={isEmpty(selectedChannels)}
              >
                Unsend Campaign
              </Button>
            </div>
          </div>
        </>
      }
    </Modal>
  )
}
