import { useEffect } from 'react'
import { fetchTokenUrl } from '../../api/auth'
import FastSpinner from '../../components/FastSpinner'
import { useAuth } from '../../context'

export const AuthCallback = () => {
  const { setAuthState } = useAuth()

  useEffect(() => {
    const getToken = async () => {
      try {
        const {
          data: { token },
        } = await fetchTokenUrl()
        if (token) {
          window.localStorage.setItem('token', token)
          setAuthState('LOGIN')
        } else {
          setAuthState('LOGOUT')
        }
      } catch (error) {
        setAuthState('LOGOUT')
      }
    }
    getToken()
  }, [])

  return (
    <div className="w-full h-full absolute z-20 flex justify-center items-center">
      <FastSpinner />
    </div>
  )
}
