import { Result } from 'antd'

export const TooSmall = () => {
  return (
    <div>
      <Result
        icon={<img src="/images/broken-owl.png" alt="sad waiting owl icon" />}
        title="You do not have sufficient shared Slack channels to use Thena at the moment."
        subTitle="Please reach out to support@thena.ai or begin a conversation using Intercom if you still want to use Thena."
      />
    </div>
  )
}
