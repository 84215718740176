import { DownOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import CloseIcon from '../../images/icons/CloseIcon.svg'
import { noOp } from '../../utils/empty'
import './MultiSelect.css'

const ButtonWrapper = styled.div`
  display: inline-block;
  width: 100%;
  // border: 1px solid transparent;
  // cursor: pointer;
  // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  // -webkit-user-select: none;
  // user-select: none;
  // touch-action: manipulation;
  // padding: 4px 8px;
  // border-radius: 6px;
  // border-color: #dce2f0;
  // :hover {
  //   color: #9729ff;
  //   background: #fff;
  //   border-color: #9729ff;
  // }
`

export const MultiSelect = (props: any) => {
  const { disabled, callback = noOp } = props
  const [isOpen, setOpen] = useState(false)

  return (
    <Popover
      content={props.content}
      trigger="click"
      placement="bottomLeft"
      showArrow={false}
      open={isOpen}
      onOpenChange={(visible) => {
        setOpen(visible)
        callback(visible)
      }}
      destroyTooltipOnHide
      getPopupContainer={(trigger) => trigger.parentElement || document.body}
    >
      <div
        className={`flex items-center h-7 !rounded-md ${props.className} ${
          props.label === 'Select' && props.mandatory
            ? 'error-btn'
            : 'filter-btn clear-btn'
        }`}
      >
        <ButtonWrapper>
          <div
            role="button"
            tabIndex={disabled ? -1 : 0}
            className={`button-multi ${disabled ? 'button--disabled' : ''} h-5`}
            aria-disabled={disabled}
            id={props.name}
            onClick={() => setOpen((prev) => !prev)}
          >
            <img src={props.icon} alt="icon" />
            <span className="ml-1 mr-1">{props.label}</span>
            <props.iconList></props.iconList>
            {/* {props.clearIcon} */}
          </div>
        </ButtonWrapper>
        {!props.mandatory && props.label !== 'Sort by:' && (
          <img
            src={CloseIcon}
            onClick={(e) => {
              e.stopPropagation()
              setOpen(false)
              props.clearClick()
            }}
            alt="Close"
            className="ml-1"
          />
        )}
        {props.label === 'Sort by:' && (
          <DownOutlined
            onClick={() => setOpen((prev) => !prev)}
            className="ml-1"
          />
        )}
      </div>
    </Popover>
  )
}
