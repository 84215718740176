import { IntegrationType } from '.'
import FreshDeskSvg from '../../../images/logos/freshworks.svg'
import HubspotSvg from '../../../images/logos/hubspot.svg'
import IntercomSvg from '../../../images/logos/intercom.svg'
import SalesforceSvg from '../../../images/logos/salesforce.svg'
import ShortcutPNG from '../../../images/logos/shortcut.png'
import ZendeskSvg from '../../../images/logos/zendesk.svg'
export const IntegrationsList: IntegrationType[] = [
  // {
  //   title: 'Slack Redirection',
  //   id: 'slack',
  //   description:
  //     'Re-direct your conversations from your external channels to your internal channels',
  //   icon: SlackSvg,
  //   className: 'w-[42px] h-[42px]',
  // },
  {
    title: 'Salesforce Cases',
    id: 'salesforce',
    description: 'O-Auth enabled integration to support ticketing',
    icon: SalesforceSvg,
    className: 'w-[57px] h-[42px]',
    faq: 133830,
  },
  {
    title: 'Zendesk',
    id: 'zendesk',
    description: 'O-Auth integration for supporting your customers',
    icon: ZendeskSvg,
    className: 'w-[51px] h-[42px]',
    faq: 133829,
  },
  {
    title: 'Intercom',
    id: 'intercom',
    description: 'Intercom approved O-Auth integration for customer support',
    icon: IntercomSvg,
    className: 'w-[43px] h-[42px]',
    faq: 133828,
    // comingSoon: true,
  },
  {
    title: 'HubSpot',
    id: 'hubspot',
    description: 'O-Auth enabled integration to support ticketing',
    icon: HubspotSvg,
    className: 'w-[42px] h-[42px]',
    faq: 133831,
  },
  {
    title: 'Freshdesk',
    id: 'freshdesk',
    description: 'Customizable integration for customer support',
    icon: FreshDeskSvg,
    className: 'w-[42px] h-[42px]',
    faq: 133832,
  },
  {
    title: 'Shortcut',
    id: 'shortcut',
    description: 'Link Shortcut stories with Thena Requests',
    icon: ShortcutPNG,
    className: 'w-[42px] h-[42px]',
    beta: false,
    faq: 238862, // Todo: add faq id
  },
  // {
  //   title: 'Notion',
  //   id: 'notion',
  //   description: 'Customizable integration for customer support (Beta)',
  //   icon: NotionSvg,
  //   className: 'w-[42px] h-[42px]',
  //   comingSoon: true,
  //   faq: 0,
  // },
]
