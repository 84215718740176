import { useLiveQuery } from 'dexie-react-hooks'
import { capitalize } from 'lodash'
import flattenDeep from 'lodash/flattenDeep'
import { useMemo } from 'react'
import { accountDB } from '../../../DB/AccountDB'
import AccountCategorySvg from '../../../images/icons/account-category-filter.svg'
import AccountOwnerSvg from '../../../images/icons/account-owner-filter.svg'
import { useAccountsStore } from '../../../store/accountsStore'
import {
  AccountFilterType,
  ACCOUNT_TYPE_LIST,
  RangeValue,
  UserFilterType,
  UserType,
} from '../../../types/accountsTypes'
import { useGetUsersList } from '../../Requests/utils'
import { getUniqueArrayByObjectKey } from '../utils'
import DateRange from './DateRange'
import MutipleSelect from './MutipleSelect'

const accountTypeFilterItems = ACCOUNT_TYPE_LIST.map((item) => ({
  id: item,
  name: capitalize(item),
}))
export const DefaultFilters = () => {
  const dispatch = useAccountsStore((state) => state.dispatch)
  const accountTypes = useAccountsStore((state) => state.filters.accountTypes)
  const accountOwners = useAccountsStore((state) => state.filters.accountOwners)
  const renewalDateRange = useAccountsStore(
    (state) => state.filters.renewalDateRange
  )
  const contractStartDateRange = useAccountsStore(
    (state) => state.filters.contractStartDateRange
  )

  const allUsers = useLiveQuery(
    async () => {
      const data = await accountDB.relationship.toArray()
      return flattenDeep(data.map((rel) => rel.users)) as UserType[]
    },
    [],
    [] as UserType[]
  )
  const { accountOwners: accountOwnersList } = useGetUsersList()

  const userList = useMemo(() => {
    const list = accountOwnersList.map((user) => {
      return {
        name: user?.name || '',
        id: user?.id || '',
        src: user?.image || '',
      }
    })
    return getUniqueArrayByObjectKey(list, 'id')
  }, [accountOwnersList])

  const selectedList = useMemo(() => {
    const list = accountOwners.map((user) => {
      return {
        name: user?.user?.name || '',
        id: user?.user?.slack?.id || '',
        src: user?.user?.slack?.profile?.image_original || '',
      }
    })
    return getUniqueArrayByObjectKey(list, 'id')
  }, [accountOwners])

  const setAccountTypes = (type: AccountFilterType) => {
    const isSelected = accountTypes.some((item) => item.name === type.name)

    const list = isSelected
      ? accountTypes.filter((item) => item.name !== type.name)
      : [...accountTypes, type]

    dispatch({
      type: 'UPDATE_FILTER_KEY',
      payload: {
        data: list,
        key: 'accountTypes',
      },
    })
  }

  const setAccountOwners = (user: UserFilterType) => {
    const isSelected = accountOwners.some(
      (item) => item?.user?.slack?.id === user?.id
    )
    const users = isSelected
      ? accountOwners.filter((item) => item?.user?.slack?.id !== user?.id)
      : [
          ...accountOwners,
          allUsers.find((item) => item?.user?.slack?.id === user?.id),
        ]

    dispatch({
      type: 'UPDATE_FILTER_KEY',
      payload: {
        data: users,
        key: 'accountOwners',
      },
    })
  }

  const handleClearAccountOwners = () => {
    dispatch({
      type: 'UPDATE_FILTER_KEY',
      payload: {
        data: [],
        key: 'accountOwners',
      },
    })
  }

  const handleClearAccountTypes = () => {
    dispatch({
      type: 'UPDATE_FILTER_KEY',
      payload: {
        data: [],
        key: 'accountTypes',
      },
    })
  }

  const setRenewalDateRange = (
    dateRange: RangeValue<moment.Moment> | moment.Moment[]
  ) => {
    dispatch({
      type: 'UPDATE_FILTER_KEY',
      payload: {
        data: dateRange,
        key: 'renewalDateRange',
      },
    })
  }

  const setStartDateRange = (
    dateRange: RangeValue<moment.Moment> | moment.Moment[]
  ) => {
    dispatch({
      type: 'UPDATE_FILTER_KEY',
      payload: {
        data: dateRange,
        key: 'contractStartDateRange',
      },
    })
  }
  return (
    <>
      <MutipleSelect
        label="Account type"
        iconAlt="account-type"
        onChange={setAccountTypes}
        selectedList={accountTypes}
        iconSrc={AccountCategorySvg}
        className="accounts-filter-chip account-type"
        itemList={accountTypeFilterItems}
        clearList={handleClearAccountTypes}
      />
      <MutipleSelect
        renderChipsAsAvatar
        itemList={userList}
        label="Account owner"
        iconAlt="account-owner"
        iconSrc={AccountOwnerSvg}
        onChange={setAccountOwners}
        selectedList={selectedList}
        className="accounts-filter-chip"
        clearList={handleClearAccountOwners}
      />
      <DateRange
        label="Start Date"
        className="accounts-filter-chip"
        setDateRange={setStartDateRange}
        dateRange={contractStartDateRange}
      />
      <DateRange
        label="Renewal Date"
        dateRange={renewalDateRange}
        className="accounts-filter-chip"
        setDateRange={setRenewalDateRange}
      />
    </>
  )
}
