import { message } from 'antd'
import React, { useContext, useState } from 'react'
import { UseMutationResult, useMutation, useQueryClient } from 'react-query'
import { testZendeskConnection } from '../../../api/integrations'
import { ZendeskTestConnectionPayloadType } from '../definitions'

// interface ZendeskConfigurationType {
//   requestTypes: string[]
//   authUrl?: string
//   accountName: string
// }

interface ZendeskContextType {
  isSetUpOpen: boolean
  connectionPassed: boolean
  connectionFailed: boolean
  setIsSetUpOpen: React.Dispatch<React.SetStateAction<boolean>>
  setConnectionPassed: React.Dispatch<React.SetStateAction<boolean>>
  setConnectionFailed: React.Dispatch<React.SetStateAction<boolean>>
  isTestingConnection: boolean
  testZendeskConnectionMutation?: UseMutationResult<
    any,
    unknown,
    ZendeskTestConnectionPayloadType,
    unknown
  >

  saveRequestTypesMutation?: UseMutationResult<
    any,
    unknown,
    ZendeskTestConnectionPayloadType,
    unknown
  >
}

const ZendeskContext = React.createContext<ZendeskContextType>({
  isSetUpOpen: false,
  connectionPassed: false,
  connectionFailed: false,
  isTestingConnection: false,
  setIsSetUpOpen: () => null,
  setConnectionPassed: () => null,
  setConnectionFailed: () => null,
})

export const ZendeskProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const queryClient = useQueryClient()
  const [isSetUpOpen, setIsSetUpOpen] = useState<boolean>(true)
  const [connectionPassed, setConnectionPassed] = useState<boolean>(false)
  const [connectionFailed, setConnectionFailed] = useState<boolean>(false)

  // Test Zendesk configuration
  const testZendeskConnectionMutation = useMutation(testZendeskConnection, {
    onSuccess: () => {
      setConnectionPassed(true)
      setConnectionFailed(false)
      queryClient.invalidateQueries(['fetch-zendesk-integration'])
    },
    onError: () => {
      setConnectionPassed(false)
      setConnectionFailed(true)
    },
  })

  // const { data: zendeskConfiguration, isLoading } = useQuery(
  //   'fetch-zendesk-integration',
  //   fetchZendeskIntegration,
  //   {
  //     select: (
  //       data: ZendeskConfigurationDetailsType
  //     ): ZendeskConfigurationType => {
  //       return {
  //         requestTypes: data?.requestTypes as string[],
  //         authUrl: (data?.authUrl || '') as string,
  //         accountName: data?.accountName as string,
  //       }
  //     },
  //   }
  // )

  // Added same mutation as testZendeskConnectionMutation to avoid unnecessary checks and balances
  const saveRequestTypesMutation = useMutation(testZendeskConnection, {
    onSuccess: () => {
      setIsSetUpOpen(false)
      setConnectionPassed(true)
      setConnectionFailed(false)
      message.success('Zendesk configuration saved')
      queryClient.invalidateQueries(['fetch-zendesk-integration'])
    },
    onError: () => {
      setConnectionPassed(false)
      setConnectionFailed(true)
    },
  })

  return (
    <ZendeskContext.Provider
      value={{
        isSetUpOpen,
        connectionPassed,
        connectionFailed,
        isTestingConnection: testZendeskConnectionMutation?.isLoading,
        setIsSetUpOpen,
        setConnectionPassed,
        setConnectionFailed,
        testZendeskConnectionMutation,
        saveRequestTypesMutation,
      }}
    >
      {children}
    </ZendeskContext.Provider>
  )
}

export const useZendesk = () => {
  return useContext(ZendeskContext)
}
