/* eslint-disable max-lines */
import { DeleteOutlined } from '@ant-design/icons'
import {
  Badge,
  Button,
  Card,
  Divider,
  message,
  Switch,
  Tag,
  Typography,
} from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMutation, useQueryClient } from 'react-query'
import {
  deleteIntegration,
  integrationStatusChange,
} from '../../../api/integrations'
import { useConnectedApp } from '../../../modules/ConnectedApps'
import { IntegrationType as CardProps } from '../../../modules/ConnectedApps/definitions'
import { useZendeskStore } from '../../../store/zendeskStore'
import { helphubUtil } from '../../../utils/helphub'

const { Text, Paragraph } = Typography

type Props = CardProps & {
  hasConnected: boolean
  configured: boolean
  inConnectedApps?: boolean
  isEnabled: boolean
  showSwitch: boolean
  showDelete: boolean
  faq: number
}

export const IntegrationCard = ({
  id,
  icon,
  title,
  description,
  comingSoon,
  className,
  isEnabled,
  hasConnected,
  showSwitch,
  showDelete,
  configured,
  inConnectedApps = false,
  beta,
  faq,
}: Props) => {
  const queryClient = useQueryClient()
  const { setIsEdition, setSelectedIntegration } = useConnectedApp()

  // Save Salesforce account mapping
  const statusChangeMutation = useMutation(integrationStatusChange, {
    onSuccess: ({ isEnabled }) => {
      let successMessage = `${isEnabled ? 'enabled' : 'disabled'}`
      switch (id) {
        case 'salesforce':
          successMessage = `Salesforce ${isEnabled ? 'enabled' : 'disabled'}`
          break
        case 'zendesk':
          successMessage = `Zendesk ${isEnabled ? 'enabled' : 'disabled'}`
          break
        case 'intercom':
          successMessage = `Intercom ${isEnabled ? 'enabled' : 'disabled'}`
          break
        case 'hubspot':
          successMessage = `HubSpot Tickets ${
            isEnabled ? 'enabled' : 'disabled'
          }`
          break
        case 'freshdesk':
          successMessage = `Freshdesk ${isEnabled ? 'enabled' : 'disabled'}`
          break
        case 'shortcut':
          successMessage = `Shortcut ${isEnabled ? 'enabled' : 'disabled'}`
          break
        default:
          break
      }
      message.success(successMessage)
      queryClient.setQueryData(['integrations'], (old: any) => {
        return old.map((el: Props) => {
          if (el.id === id) {
            el.isEnabled = isEnabled
          }
          return el
        })
      })
    },
    onError: (error) => {
      console.error(error)
      message.error('Something went wrong')
    },
  })

  // Delete Integration
  const deleteIntegrationMutation = useMutation(deleteIntegration, {
    onSuccess: ({ isEnabled }) => {
      let successMessage
      switch (id) {
        case 'salesforce':
          successMessage = `Salesforce Integration Deleted`
          break
        case 'zendesk':
          successMessage = `Zendesk Integration Deleted`
          break
        case 'intercom':
          successMessage = `Intercom Integration Deleted`
          break
        case 'hubspot':
          successMessage = `HubSpot Tickets Integration Deleted`
          break
        case 'freshdesk':
          successMessage = `Freshdesk Integration Deleted`
          break
        case 'shortcut':
          successMessage = `Shortcut Integration Deleted`
          break
        default:
          break
      }
      message.success(successMessage)
      queryClient.setQueryData(['integrations'], (old: any) => {
        return old.map((el: Props) => {
          if (el.id === id) {
            el.inConnectedApps = false
            el.hasConnected = false
            el.configured = false
          }
          return el
        })
      })
      queryClient.invalidateQueries(['integrations'])
    },
    onError: (error) => {
      console.error(error)
      message.error('Something went wrong')
    },
  })

  const flags = useFlags()
  const isNewZendeskFlow = !!flags.externalTicketingZendesk && id === 'zendesk'
  const dispatch = useZendeskStore((state) => state.dispatch)

  const showConnectForZendesk = isNewZendeskFlow && configured

  const card = (
    <Card
      key={id}
      bodyStyle={{
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 16,
        paddingTop: 32,
      }}
      className="hover:border-hover-accent shadow-lg w-[260px]"
    >
      <div className={`bg-white h-auto`}>
        <div className="flex flex-row justify-between">
          <div className="pl-6">
            <img
              src={icon}
              className={className || ''}
              alt={title + ' logo icon'}
            />
          </div>
          {((configured && !inConnectedApps) || showConnectForZendesk) && (
            <Tag className="rounded-full h-fit mx-[14px]" color="green">
              Connected
            </Tag>
          )}
          <div
            style={{
              display:
                inConnectedApps && (showSwitch || showDelete)
                  ? 'block'
                  : 'none',
            }}
          >
            {inConnectedApps && showSwitch && (
              <Switch
                loading={statusChangeMutation.isLoading}
                checked={isEnabled}
                onChange={(value) => {
                  if (
                    [
                      'salesforce',
                      'zendesk',
                      'intercom',
                      'hubspot',
                      'freshdesk',
                      'shortcut',
                    ].includes(id)
                  ) {
                    statusChangeMutation.mutateAsync({
                      type: id as
                        | 'salesforce'
                        | 'zendesk'
                        | 'intercom'
                        | 'hubspot'
                        | 'freshdesk',
                      isEnabled: value,
                    })
                  }
                }}
                className="mx-5"
              />
            )}
            {inConnectedApps && showDelete && (
              <Button
                icon={<DeleteOutlined />}
                disabled={comingSoon}
                className="mr-4"
                onClick={() => {
                  deleteIntegrationMutation.mutateAsync({
                    type: id as
                      | 'salesforce'
                      | 'zendesk'
                      | 'intercom'
                      | 'hubspot'
                      | 'freshdesk'
                      | 'shortcut',
                  })
                }}
              />
            )}
          </div>
        </div>
        <div className="pl-6 pr-5 justify-left items-center w-full mt-4">
          <Text className="text-sm 3xl:text-[length:var(--sm)] font-bold">
            {title === 'HubSpot' ? 'HubSpot Tickets' : title}
          </Text>
          <Paragraph
            style={{ minHeight: '40px' }}
            className="font-normal text-xs 3xl:text-[length:var(--xs)] text-gray-dark"
            ellipsis={{
              rows: 2,
            }}
            title={description}
          >
            {description}
          </Paragraph>
        </div>
        <Divider className="py-0 mb-3 mt-5" />
        <div className="flex justify-between items-center">
          {!hasConnected && !configured && (
            <Button
              key="add-integration"
              type="link"
              className="pl-6 text-primary hover:text-brand-accent"
              disabled={comingSoon}
              onClick={() => {
                if (isNewZendeskFlow) {
                  return dispatch({
                    type: 'SET_MODAL_STATE',
                    payload: {
                      modalState: {
                        isOpen: true,
                        type: 'CREATE_INTEGRATION',
                        title: 'New Zendesk workspace',
                      },
                    },
                  })
                }
                setSelectedIntegration(id)
              }}
            >
              Add Integration
            </Button>
          )}
          {hasConnected && (
            <Button
              key="view-integration"
              type="link"
              className="pl-6 text-primary hover:text-brand-accent"
              disabled={comingSoon}
              onClick={() => {
                if (isNewZendeskFlow) {
                  return dispatch({
                    type: 'SET_MODAL_STATE',
                    payload: {
                      modalState: {
                        isOpen: true,
                        type: 'ALL_INTEGRATIONS',
                        title: 'Zendesk Integration Setup',
                      },
                    },
                  })
                }
                setIsEdition(true)
                setSelectedIntegration(id)
              }}
            >
              View Integration
            </Button>
          )}
          {!hasConnected && configured && !inConnectedApps && (
            <Button
              key="configure"
              type="link"
              className="pl-6 text-primary hover:text-brand-accent"
              disabled={comingSoon}
              onClick={() => {
                setSelectedIntegration(id)
              }}
            >
              Configure
            </Button>
          )}
          <Button
            key="add-integration"
            type="link"
            className="pr-6 text-[color:var(--color-blue-1)] hover:text-[color:var(--color-blue-1)]"
            disabled={comingSoon}
            onClick={() => helphubUtil(faq)}
          >
            FAQs
          </Button>
        </div>
      </div>
    </Card>
  )

  if (comingSoon) {
    return (
      <Badge.Ribbon
        text={<span className="text-info">Coming soon</span>}
        color="#E5EDFF"
      >
        {card}
      </Badge.Ribbon>
    )
  }
  if (beta) {
    return (
      <Badge.Ribbon
        text={<span className="text-[color:var(--color-purple-6)]">Beta</span>}
        color="var(--color-purple-7)"
      >
        {card}
      </Badge.Ribbon>
    )
  }

  return card
}
