import { api } from '@api/index'
import { message, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router'
import { Switch } from 'antd'

interface DataType {
  key: string
  can_impersonate: boolean
  name: string
  _id: string
}

const fetchOrgs = async (): Promise<any> => {
  const { data } = await api.get('/v2/god-mode/organizations')
  return data
}

export const toggleImpersonation = (data: any) =>
  api
    .post(`/v2/god-mode/organizations/${data.id}/impersonation`, {
      enable: data.can_impersonate,
    })
    .then(({ data: { data } }) => data)

const Organizations: React.FC = () => {
  const [data, setData] = useState<DataType[]>([])
  const { isLoading, data: apiData } = useQuery('fetchOrgs', fetchOrgs)
  const navigate = useNavigate()

  const saveChannelConfigMutation = useMutation(toggleImpersonation, {
    onSuccess: () => {
      message.success('Updated successfully')
    },
    onError: () => {
      message.error('Failed to update')
    },
  })

  const onChange = (checked: boolean, id: string) => {
    saveChannelConfigMutation.mutate({ id, can_impersonate: checked })
  }
  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <a onClick={() => navigate(`/impersonation/logs?id=${record._id}`)}>
          {text}
        </a>
      ),
    },
    {
      title: 'Can Impersonate',
      dataIndex: 'can_impersonate',
      key: 'can_impersonate',
      render: (value, record) => (
        <Switch
          defaultChecked={value}
          onChange={(checked) => onChange(checked, record._id)}
        />
      ),
    },
  ]

  useEffect(() => {
    if (apiData) {
      setData(apiData)
    }
  }, [apiData])

  return (
    <div>
      <h1 className="text-center p-4">Organizations</h1>
      <div className="p-8">
        <Table
          columns={columns}
          pagination={{ position: ['bottomRight'] }}
          dataSource={data}
          loading={isLoading || saveChannelConfigMutation.isLoading}
        />
      </div>
    </div>
  )
}

export default Organizations
