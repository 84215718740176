import { Button, Popover } from 'antd'
import emojione from 'emojione'
import { FocusEvent } from 'react'
import { Editable } from '../Commons'
import { formatSlackMarkdown } from '../utils'
import BlockImage from './BlockImage'

const SlackButton = ({ text, url, onClick, isReadOnly }: any) =>
  isReadOnly ? (
    <Button
      type="default"
      size="middle"
      className="border-[#B6B5B6] font-semibold text-default w-fit"
      href={url}
      target="_blank"
    >
      <div
        className="slack_section_text"
        dangerouslySetInnerHTML={{
          __html: formatSlackMarkdown(
            typeof text?.text === 'string' ? text?.text : ''
          ),
        }}
      />
    </Button>
  ) : (
    <Button
      type="default"
      size="middle"
      className="border-[#B6B5B6] font-semibold text-default w-fit"
      href={url}
      target="_blank"
    >
      <Popover
        placement="right"
        content={
          <div className="flex justify-end w-full">
            <Button type="link" className="font-medium" onClick={onClick}>
              Edit
            </Button>
          </div>
        }
        title={null}
      >
        <div
          className="slack_section_text"
          dangerouslySetInnerHTML={{
            __html: formatSlackMarkdown(
              typeof text?.text === 'string' ? text?.text : ''
            ),
          }}
        />
      </Popover>
    </Button>
  )

type IProps = {
  fields: any
  type: string
  accessory: any
  typeIndex: number
  toggleModal: () => void
  handleImageClick: () => void
  handleButtonClick: () => void
  block: { text?: string | null }
  onBlur: (e: FocusEvent<HTMLDivElement, Element>) => void
  onFocus: (e: FocusEvent<HTMLDivElement, Element>) => void
  selectedText: string | undefined
  isReadOnly?: boolean
}

const BlockSection = ({
  type,
  block,
  fields,
  onBlur,
  onFocus,
  accessory,
  typeIndex,
  toggleModal,
  handleImageClick,
  handleButtonClick,
  selectedText,
  isReadOnly,
}: IProps) => {
  function generateAccessory(accessory: any, typeIndex: number, type: string) {
    if (accessory['type'] === 'button') {
      return (
        <SlackButton
          url={accessory.url}
          text={accessory.text}
          onClick={handleButtonClick}
          isReadOnly={isReadOnly}
        />
      )
    } else if (accessory['type'] === 'image') {
      return (
        <BlockImage
          index={typeIndex}
          imageUrl={accessory['image_url']}
          altText={accessory['alt_text']}
          size="small"
          onClick={handleImageClick}
          showPlusIcon={false}
          showDeleteIcon={false}
          isReadOnly={isReadOnly}
        />
      )
    }
  }

  const renderContent = () => {
    if (block.text) {
      return (
        <Editable
          type="section"
          onBlur={onBlur}
          onFocus={onFocus}
          index={typeIndex}
          toggleModal={toggleModal}
          className="slack_section_text"
          htmlString={formatSlackMarkdown(
            typeof block?.text === 'string'
              ? emojione.shortnameToUnicode(block?.text)
              : ''
          )}
          selectedText={selectedText}
          isReadOnly={isReadOnly}
        />
      )
    }

    if (fields?.length) {
      return (
        <div className="grid grid-cols-2 gap-x-10">
          {Array.isArray(fields) &&
            (fields || []).map((f: any, index: number) => (
              <Editable
                key={index}
                index={typeIndex}
                className="slack_section_text mb-2"
                htmlString={formatSlackMarkdown(
                  typeof f?.text === 'string'
                    ? emojione.shortnameToUnicode(f?.text)
                    : ''
                )}
                onBlur={onBlur}
                onFocus={onFocus}
                toggleModal={toggleModal}
                type="section"
                selectedText={selectedText}
                isReadOnly={isReadOnly}
              />
            ))}
        </div>
      )
    }

    return null
  }

  return (
    <div className="slack_section">
      <div className="slack_acc">
        {accessory ? generateAccessory(accessory, typeIndex, type) : ''}
      </div>
      {renderContent()}
    </div>
  )
}

export default BlockSection
