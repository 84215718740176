export const getPercentDiff = (oldValue = 0, newValue = 0) => {
  const diff = newValue - oldValue
  const percentageDiff = (diff / Math.abs(oldValue)) * 100

  if (isFinite(percentageDiff)) {
    if (percentageDiff > 0) {
      return {
        percent: `${percentageDiff.toFixed(0)}%`,
        change: 1,
      }
    }
    if (percentageDiff < 0) {
      return {
        percent: `${Math.abs(percentageDiff).toFixed(0)}%`,
        change: -1,
      }
    }
  }
  return {
    percent: 'NA',
    change: 0,
  }
}

export const isNumber = (value: unknown) => !!value && !isNaN(Number(value))
