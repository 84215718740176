import { api } from '.'
import type { AssistantBodyType, AssistantType } from '../types'

export const createAssistant = async (assistantInfo: AssistantBodyType) => {
  await api.post(`/v2/knowledge-base-ai/assistant`, assistantInfo)
  const data = await fetchAssistants()
  return data
}

export const fetchAssistants = async (): Promise<AssistantType[]> => {
  const { data } = await api.get('/v2/knowledge-base-ai/assistant')
  return data.data
}
