import { Timeline, Tooltip } from 'antd'
import { isEmpty } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import MediaEmpty from '../../../images/icons/empty-media.svg'
import { SummaryDetail } from '../../../types/accountsTypes'
import { Beacon } from './Beacon'
import { EmptyState } from './EmptyState'

const Wrapper = styled.div`
  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    text-align: left;
    margin-left: 12px;
    width: auto;
  }
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
    left: 15%;
  }
  .ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content {
    left: 15%;
    width: calc(80% - 36px);
  }
`

const ChipWrapper = styled.div`
  width: fit-content;
  height: 25px;
  padding: 1px 8px 4px 8px;
  border-radius: 20px;
  border: 1px solid var(--color-gray-3);
  background-color: var(--color-gray-bg);
`

const ListWrapper = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-top: 12px;
  text-align: left;
  a {
    color: #355de0 !important;
    text-decoration: underline;
  }
`

const Chip = ({
  chipName,
  isExternal,
}: {
  chipName: string
  isExternal: boolean
}) => {
  return (
    <ChipWrapper>
      <div className="flex items-center gap-1 justify-start">
        <span className="font-semibold text-xs">{chipName}</span>
        <p className="mb-0 text-[var(--color-gray-4)]">•</p>
        <span className="text-xs text-[var(--color-gray-4)]">
          {isExternal ? 'External Summary' : 'Internal Summary'}
        </span>
      </div>
    </ChipWrapper>
  )
}

const MediaDetails = ({
  media,
}: {
  media: {
    file_name: string
    file_link: string
  }[]
}) => {
  return (
    <ListWrapper>
      <ul className="list-disc pl-4 flex flex-col gap-2">
        {media?.map((item) => (
          <li>
            <a href={item?.file_link} target="_blank">
              {item?.file_name}
            </a>
          </li>
        ))}
      </ul>
    </ListWrapper>
  )
}

export const Media = ({
  summaryDetails,
}: {
  summaryDetails: SummaryDetail[]
}) => {
  const areLinksEmpty = (detail: any) => {
    return (
      isEmpty(detail?.event_metadata?.external?.links) &&
      isEmpty(detail?.event_metadata?.internal?.links)
    )
  }

  const allLinksEmpty = summaryDetails.every(areLinksEmpty)

  if (allLinksEmpty) {
    return (
      <EmptyState
        title="It's a clean slate here!"
        description="All links, attachments, and other media shared in your conversations with this customer will appear in this tab. This makes it easy to find and reference shared materials."
        footer="Learn how media works in our"
        helpText="Help Document"
        image={MediaEmpty}
      />
    )
  } else {
    return (
      <Wrapper className="mt-2">
        <Timeline
          mode="left"
          pendingDot={<Beacon sentiment="gray" staticMode={true} />}
          pending={!allLinksEmpty}
        >
          {summaryDetails.map((summary) => {
            const external =
              summary?.event_metadata?.external &&
              !isEmpty(summary?.event_metadata?.external?.links) ? (
                <Timeline.Item
                  key={`external-${
                    summary.createdAt + summary?.event_metadata?.external?.title
                  }`}
                  label={
                    <Tooltip title="Summary generated">
                      <span className="text-[#6F6F75] text-xs">
                        {moment(summary.createdAt).format('L')}
                      </span>
                    </Tooltip>
                  }
                  dot={<Beacon sentiment="gray" staticMode={true} />}
                >
                  <Chip chipName={summary?.customerName} isExternal={true} />
                  {!isEmpty(summary?.event_metadata?.external?.links) && (
                    <MediaDetails
                      media={summary?.event_metadata?.external?.links}
                    />
                  )}
                </Timeline.Item>
              ) : (
                <></>
              )

            const internal =
              summary?.event_metadata?.internal &&
              !isEmpty(summary?.event_metadata?.internal?.links) ? (
                <Timeline.Item
                  key={`internal-${
                    summary.createdAt + summary?.event_metadata?.internal?.title
                  }`}
                  label={
                    <Tooltip title="Summary generated">
                      <span className="text-[#6F6F75] text-xs">
                        {moment(summary.createdAt).format('L')}
                      </span>
                    </Tooltip>
                  }
                  dot={<Beacon sentiment="gray" staticMode={true} />}
                >
                  <Chip chipName={summary?.customerName} isExternal={false} />
                  {!isEmpty(summary?.event_metadata?.internal?.links) && (
                    <MediaDetails
                      media={summary?.event_metadata?.internal?.links}
                    />
                  )}
                </Timeline.Item>
              ) : (
                <></>
              )
            if (
              summary?.event_metadata?.external &&
              summary?.event_metadata?.internal
            ) {
              return [external, internal]
            }

            if (summary?.event_metadata?.external) {
              return external
            }

            if (summary?.event_metadata?.internal) {
              return internal
            }

            return null
          })}
        </Timeline>
      </Wrapper>
    )
  }
}
