import { Divider } from 'antd'
import { IntegrationModalHeader } from '../../../components'
import ZendeskSvg from '../../../images/logos/zendesk.svg'
import { ZendeskSetUp } from './ZendeskSetup'

// const { Text } = Typography

type Props = {
  requestType: {
    label: string
    value: string
    disabled: boolean
  }[]
}

export const ZendeskIntegration = ({ requestType }: Props) => {
  return (
    <main className="w-full">
      <IntegrationModalHeader
        imgSrc={ZendeskSvg}
        imageClassName="w-[51px] h-[40px]"
        title="Zendesk Integration"
        description="O-Auth integration for supporting your customers"
      />
      <Divider className="my-5 py-0" />

      <ZendeskSetUp requestType={requestType} />
    </main>
  )
}
