import { Spin } from 'antd'
import { useLiveQuery } from 'dexie-react-hooks'
import styled from 'styled-components'
import { accountDB } from '../../DB/AccountDB'
import Cards from './Cards/Cards'
import AccountDrawer from './Drawer/AccountDrawer'
import Filters from './Filters/Filters'
import Table from './Table/Table'

const Wrapper = styled.div`
  .spinner {
    width: 100%;
    height: 100%;
    margin-top: 160px;
  }
`

const Landing = () => {
  // const updatedAt = useAccountsStore((state) => state.updatedAt)
  // const isOlder = updatedAt ? isDateOlderThan24Hours(updatedAt) : true

  const isDataFetched = useLiveQuery(
    async () => {
      const data = await accountDB.relationship.toArray()
      return data.length > 0
    },
    [],
    false
  )

  return (
    <Wrapper>
      <Filters />
      <Cards />
      {isDataFetched ? <Table /> : <Spin size="large" className="spinner" />}
      <AccountDrawer />
    </Wrapper>
  )
}

export default Landing

/**
 * CRM config
 * - add filters
 * - wire up this new filter with existing ones
 */

/**
 * TODO:
 * - in filter range, need to send the epoch from start to end of day?
 * - in dateRange, add 3,6,12 months option
 * UI fixes:
 *  - filter chip font color
 */

/**
 * TODO:
 * - Clarify logic for Account owner change (as it is not in CRM values)
 */
