import { InfoCircleOutlined } from '@ant-design/icons'
import {
  Button,
  Divider,
  Input,
  message,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getIntercomIntercomIntegrationConfig,
  saveIntercomIntegrationConfig,
} from '../../../api/integrations'
import { IntegrationModalHeader } from '../../../components'
import { INTERCOM_APP_ID } from '../../../config/constants'
import { useAuth } from '../../../context'
import IntercomSvg from '../../../images/logos/intercom.svg'
import { useConnectedApp } from '../../ConnectedApps'
import { ExtendedConnectorConfiguration } from '../ConnectorConfiguration'
import { IntercomIntegrationType } from '../definitions'
const { Text, Title } = Typography

export const IntercomIntegration = () => {
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const { isEdition } = useConnectedApp()
  const { handleSubmit, control, reset } = useForm()
  const { data: intercomIntegrationConfigData, isLoading } =
    useQuery<IntercomIntegrationType>(
      'get-intercom-integration-config',
      () => getIntercomIntercomIntegrationConfig(),
      {
        onError: () => {
          message.error('Error fetching Intercom integration details')
        },
      }
    )

  useEffect(() => {
    reset(intercomIntegrationConfigData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intercomIntegrationConfigData])

  const saveIntercomConfigMutation = useMutation(
    saveIntercomIntegrationConfig,
    {
      onSuccess: () => {
        message.success('Intercom configuration saved')
        queryClient.invalidateQueries('get-intercom-integration-config')
      },
      onError: () => {
        message.error('Error saving intercom config')
      },
    }
  )

  const handleSave = async (data: any) => {
    saveIntercomConfigMutation?.mutate(data)
  }

  return (
    <main className="w-full">
      {!isEdition ? (
        <div>
          <IntegrationModalHeader
            imgSrc={IntercomSvg}
            imageClassName="w-[42px] h-[42px]"
            title="Intercom Integration"
            description="Intercom approved O-Auth integration for customer support"
          />
          <Divider className="my-5 py-0" />
          <img
            className="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            src="https://static.intercomassets.com/assets/oauth/primary-7edb2ebce84c088063f4b86049747c3a.png"
            srcSet="https://static.intercomassets.com/assets/oauth/primary-7edb2ebce84c088063f4b86049747c3a.png 1x, https://static.intercomassets.com/assets/oauth/primary@2x-0d69ca2141dfdfa0535634610be80994.png 2x, https://static.intercomassets.com/assets/oauth/primary@3x-788ed3c44d63a6aec3927285e920f542.png 3x"
            alt="intercom icon"
            onClick={() =>
              window.open(
                `https://app.intercom.io/oauth?client_id=${INTERCOM_APP_ID}&state=${user?.team_id}`,
                '_blank'
              )
            }
          />
        </div>
      ) : isLoading ? (
        <div className="w-full h-96 flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <IntegrationModalHeader
            imgSrc={IntercomSvg}
            imageClassName="w-[42px] h-[42px]"
            title="Configure Intercom Integration"
            description="Configure Intercom behavior on Slack"
          />
          <Divider className="my-5 py-0" />
          <div className="mb-9">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Auto-send to Intercom </Text>
                <Tooltip
                  placement="top"
                  title="Send all conversations on shared Slack channels with Thena to Intercom directly?"
                >
                  <InfoCircleOutlined data-intercom-target="Settings Integrations Intercom Autosend" />
                </Tooltip>
              </div>
              <div>
                <Controller
                  name="autosend"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      defaultChecked
                      // className="bg-green-500"
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-9">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Use admin/agent Slack profile </Text>
                <Tooltip
                  placement="top"
                  title="Use the Intercom agent's name and profile image when responding - select this only if all your agents/admins have a Slack account."
                >
                  <InfoCircleOutlined data-intercom-target="Settings Integrations Intercom Use Agent Profile" />
                </Tooltip>
              </div>
              <div>
                <Controller
                  name="useprofile"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      defaultChecked
                      // className="bg-green-500"
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-9">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>App Alias </Text>
                <Tooltip
                  placement="top"
                  title="An alias for the app when it responds. Something like 'My Awesome Company's Support Team'"
                >
                  <InfoCircleOutlined data-intercom-target="Settings Integrations Intercom App Alias" />
                </Tooltip>
              </div>
              <div>
                <Controller
                  name="aliasname"
                  control={control}
                  render={({ field }) => (
                    <Input placeholder="Super John" {...field} />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-9">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>App Alias Icon </Text>
                <Tooltip
                  placement="top"
                  title="The image that you'd like the responding alias to have. This is not mandatory but recommended if you configure the alias."
                >
                  <InfoCircleOutlined data-intercom-target="Settings Integrations Intercom App Alias Icon" />
                </Tooltip>
              </div>
              <div>
                <Controller
                  name="aliasicon"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="https://i.imgur.com/PF1x1Gu.jpeg"
                      className="w-72"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-9">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Default initial response </Text>
                <Tooltip
                  placement="top"
                  title="The default initial response to send to the user"
                >
                  <InfoCircleOutlined data-intercom-target="Settings Integrations Intercom Default Initial Response" />
                </Tooltip>
              </div>
              <div>
                <Controller
                  name="initialmessage"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="We'll be with you shortly..."
                      className="w-72"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-9">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Default Tag for Conversation </Text>
                <Tooltip placement="top" title="Tag for Intercom conversation">
                  <InfoCircleOutlined data-intercom-target="Settings Integrations Intercom Default Initial Response" />
                </Tooltip>
              </div>
              <div>
                <Controller
                  name="conversationtag"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="thenaSlackCreation..."
                      className="w-72"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="mb-9">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>Email Domain Restriction </Text>
                <Tooltip
                  placement="top"
                  title="Restrict who can create an Intercom conversation creation with their email domain"
                >
                  <InfoCircleOutlined data-intercom-target="Settings Integrations Intercom Email Restriction" />
                </Tooltip>
              </div>
              <div>
                <Controller
                  name="emailrestrict"
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="acme.corp"
                      className="w-72"
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="m4-3">
            <div className="flex items-center justify-between">
              <div>
                <Text strong>
                  Thena can upload files from private channels{' '}
                </Text>
                <Tooltip
                  placement="top"
                  title="Checking this enables Thena to read files from private channels and upload them to a secure S3 bucket. The files are then shared via a URL to the Intercom conversation."
                >
                  <InfoCircleOutlined data-intercom-target="Settings Integrations Intercom Private Channel File Upload" />
                </Tooltip>
              </div>
              <div>
                <Controller
                  name="privatechannelfileupload"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      defaultChecked={false}
                      // className="bg-green-500"
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex items-end justify-end">
            <Button
              className="text-primary border-primary/40 mt-5 px-8"
              size="middle"
              type="default"
              onClick={handleSubmit(handleSave)}
            >
              Save
            </Button>
          </div>
          <ExtendedConnectorConfiguration
            isEdition={isEdition}
            connector="INTERCOM"
          />
        </div>
      )}
    </main>
  )
}
