import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  bulkUpdateQNA as bulkUpdateQNAApi,
  createQNA as createQNAApi,
  fetchQNAs,
  updateQNA as updateQNAApi,
} from '../api/qnas'
import {
  BulkUpdateQNAType,
  FetchQNAType,
  QNAListType,
  UpdateQNAType,
} from '../types/qnaTypes'

const useQNAs = ({
  onCreateSuccess,
  onCreateError,
  onUpdateSuccess,
  onUpdateError,
  onBulkUpdateSuccess,
  onBulkUpdateError,
  assistantId,
  qnas = { pagination: { pageNo: 1, pageSize: 50 } },
}: {
  onCreateSuccess?: () => void
  onCreateError?: (error: Error | unknown) => void
  onUpdateSuccess?: () => void
  onUpdateError?: (error: Error | unknown) => void
  onBulkUpdateSuccess?: () => void
  onBulkUpdateError?: (error: Error | unknown) => void
  assistantId: string
  qnas?: FetchQNAType
}) => {
  const queryClient = useQueryClient()
  const { isLoading, error, data } = useQuery(
    ['qnaList', assistantId, qnas],
    () => fetchQNAs({ assistantId, qnas })
  )

  const createMutation = useMutation(createQNAApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('qnaList')
      onCreateSuccess && onCreateSuccess()
    },
    onError: (error) => {
      onCreateError && onCreateError(error)
    },
  })

  const createQNA = (newItemData: QNAListType) => {
    createMutation.mutate({ assistantId, qna: newItemData })
  }

  const updateMutation = useMutation(updateQNAApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('qnaList')
      onUpdateSuccess && onUpdateSuccess()
    },
    onError: (error) => {
      onUpdateError && onUpdateError(error)
    },
  })

  const updateQNA = (qnaId: string, newItemData: UpdateQNAType) => {
    updateMutation.mutate({ assistantId, qnaId, qna: newItemData })
  }

  const bulkUpdateMutation = useMutation(bulkUpdateQNAApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('qnaList')
      onBulkUpdateSuccess && onBulkUpdateSuccess()
    },
    onError: (error) => {
      onBulkUpdateError && onBulkUpdateError(error)
    },
  })

  const bulkUpdateQNA = (newItemData: BulkUpdateQNAType) => {
    bulkUpdateMutation.mutate({ assistantId, qnas: newItemData })
  }

  return {
    isLoading,
    error,
    data,
    createQNA,
    createStatus: createMutation,
    updateQNA,
    updateStatus: updateMutation,
    bulkUpdateQNA,
    bulkUpdateStatus: bulkUpdateMutation,
  }
}

export default useQNAs
