import { useKnockFeed } from '@knocklabs/react-notification-feed'
import { Avatar, notification } from 'antd'
import { useEffect } from 'react'
import { handleCustomEmoji } from '../../../utils/customStatusKanbanUtils'

const NotificationToaster = ({
  openKanbanRequestDrawer,
}: {
  openKanbanRequestDrawer: (data: any) => void
}) => {
  const { feedClient } = useKnockFeed()

  useEffect(() => {
    feedClient.on('items.received.realtime', onNotificationsReceived)
    return () =>
      feedClient.off('items.received.realtime', onNotificationsReceived)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedClient])

  const [api, contextHolder] = notification.useNotification()

  const onNotificationsReceived = ({ items }: any) => {
    items.forEach((notif: any) => {
      api.open({
        type: 'info',
        key: notif.id,
        message: null,
        icon: <Avatar src={notif.actors[0].avatar} size="small" />,
        description: (
          <div
            dangerouslySetInnerHTML={{
              __html: handleCustomEmoji(notif.blocks[0].rendered),
            }}
          />
        ),
        onClick: () => {
          openKanbanRequestDrawer(notif.data.request)
          notification.close(notif.id)
        },
      })
    })
    feedClient.markAsSeen(items)
  }

  return <>{contextHolder}</>
}

export default NotificationToaster
