import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  conversationFeedback,
  createConversation as createConversationApi,
  fetchConversationDetails,
  fetchConversationList,
} from '../api/chat'
import { CreateConversationType, FeedbackType } from '../types/playgroundTypes'

const useChat = ({
  onCreateSuccess,
  onCreateError,
  onFeedbackSuccess,
  onFeedbackError,
  assistantId,
  conversationId,
}: {
  onCreateSuccess?: (newConversationId: string, question: string) => void
  onCreateError?: (error: Error | unknown) => void
  onFeedbackSuccess?: () => void
  onFeedbackError?: (error: Error | unknown) => void
  assistantId: string
  conversationId: string
}) => {
  const queryClient = useQueryClient()

  const {
    isLoading: listLoading,
    error: listError,
    data: conversationList,
  } = useQuery(['conversationList', assistantId], () =>
    fetchConversationList(assistantId)
  )

  const {
    isLoading: detailLoading,
    error: detailError,
    data: conversationDetails,
    refetch: refetchDetails,
    isFetching: refetchingState,
  } = useQuery(
    ['conversationDetails', conversationId],
    () =>
      conversationId !== '' &&
      fetchConversationDetails({ assistantId, conversationId }),
    {
      enabled: conversationId !== '',
    }
  )

  const createMutation = useMutation(createConversationApi, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries('conversationList')
      onCreateSuccess &&
        onCreateSuccess(data.conversationId, variables.newConversationData.name)
    },
    onError: (error) => {
      onCreateError && onCreateError(error)
    },
  })

  const createConversation = (newItemData: CreateConversationType) => {
    createMutation.mutate({ assistantId, newConversationData: newItemData })
  }

  const feedbackMutation = useMutation(conversationFeedback, {
    onSuccess: () => {
      onFeedbackSuccess && onFeedbackSuccess()
    },
    onError: (error) => {
      onFeedbackError && onFeedbackError(error)
    },
  })

  const addFeedback = (qnaId: string, feedback: FeedbackType) => {
    feedbackMutation.mutate({ assistantId, conversationId, qnaId, feedback })
  }

  return {
    listLoading,
    listError,
    conversationList,
    detailLoading,
    detailError,
    conversationDetails,
    refetchDetails,
    refetchingState,
    createConversation,
    createStatus: createMutation,
    addFeedback,
    feedbackStatus: feedbackMutation,
  }
}

export default useChat
