import { Avatar, List, Popover, Tooltip, Typography } from 'antd'
import { ChatText, Power } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { LOGOUT_URL } from '../../../config'
import { useAuth } from '../../../context'
import useEvents from '../../../hooks/useEvents'
import { findPrimaryUserImage } from '../../../pages/Requests/utils'
import { clearBrowserStorage } from '../../../utils/domUtils'
const { Title } = Typography

const menuItems = [
  {
    key: 'Give Feedback',
    value: (
      <div className="flex items-center justify-start gap-1">
        <ChatText color="var(--color-gray-6)" size={18} />
        Give Feedback
      </div>
    ),
  },
  {
    key: 'Logout',
    value: (
      <div className="flex items-center justify-start gap-1">
        <Power color="var(--color-gray-6)" size={18} />
        Logout
      </div>
    ),
  },
]

const getRouteInfo = (pathname: string) => {
  if (pathname.includes('/requests')) {
    return {
      heading: 'Requests',
    }
  }
  if (pathname.includes('/settings')) {
    return {
      heading: 'Settings',
    }
  }
  return {
    heading: '',
  }
}

const CustomerPageHeader = () => {
  const { user } = useAuth()
  const trackEvent = useEvents()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { heading } = getRouteInfo(pathname)
  const [isOpen, setIsOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  useEffect(() => {
    isOpen && setTooltipOpen(false)
  }, [isOpen])

  const onItemClick = async (item: string) => {
    if (item === 'Logout') {
      navigate('/logout')
      try {
        await clearBrowserStorage()
        ;(window as any).thena?.logout()
        ;(window as any).analytics.track('User logged out')
      } catch (error) {
        console.log('Error in clearing storages: ', error)
      } finally {
        window.location.href = LOGOUT_URL
      }
    }
    if (item === 'Give Feedback') {
      window.open('https://thena.canny.io/', '_blank')
      trackEvent('Page View', {
        oldView: pathname,
        newView: 'https://thena.canny.io/',
      })
    }
  }

  const content = (
    <div style={{ height: 'max-content', overflow: 'auto', width: '150px' }}>
      <List
        size="small"
        bordered={false}
        dataSource={menuItems}
        renderItem={(item) => (
          <List.Item
            className="cursor-pointer [color:var(--color-gray-4)] hover:[color:var(--color-gray-6)] hover:bg-[#EBEAF8] rounded-md"
            style={{
              borderBottomWidth: 0,
              paddingLeft: '4px',
              paddingRight: 0,
            }}
            onClick={() => onItemClick(item.key)}
          >
            {item.value}
          </List.Item>
        )}
      />
    </div>
  )

  const [firstName, lastName] = (user?.displayName || user?.name || '').split(
    ' '
  )

  const userImage = findPrimaryUserImage(user)

  return (
    <div className="flex items-center justify-start mb-0 main-layout header-bg pt-[0.5rem] h-[54px]">
      <div className="flex items-center justify-start w-[30%] gap-3">
        <div className="flex items-start gap-1 justify-start shrink-0">
          <div className="flex items-center gap-1">
            <Title level={3} style={{ marginBottom: 0 }}>
              {heading}
            </Title>
          </div>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <div className="flex items-center justify-start gap-4">
          <Popover
            open={isOpen}
            trigger="click"
            content={content}
            showArrow={false}
            placement="bottomRight"
            className="cursor-pointer"
            overlayClassName="popover-class"
            onOpenChange={(visible) => setIsOpen(visible)}
            style={{
              width: '150px',
            }}
          >
            <Tooltip
              trigger="hover"
              open={tooltipOpen}
              placement="bottomRight"
              title={user?.displayName || user?.name || ''}
              onOpenChange={(visible) => setTooltipOpen(visible)}
            >
              <Avatar
                size={28}
                src={userImage}
                className="bg-[#1442DB] text-base"
              >
                {firstName.charAt(0).toUpperCase()}
                {lastName
                  ? lastName.charAt(0).toUpperCase()
                  : firstName.charAt(1)}
              </Avatar>
            </Tooltip>
          </Popover>
        </div>
      </div>
    </div>
  )
}

export default CustomerPageHeader
