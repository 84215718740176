import { createContext, useContext, useState } from 'react'
interface ShortcutContextType {
  tokenUrl: string
  setTokenUrl: React.Dispatch<React.SetStateAction<string>>
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}
const ShortcutDefaults: ShortcutContextType = {
  tokenUrl: '',
  setTokenUrl: () => {},
  isEditing: false,
  setIsEditing: () => {},
}
const ShortcutContext = createContext<ShortcutContextType>(ShortcutDefaults)

const ShortcutProvider = ({ children }: { children: React.ReactNode }) => {
  const [tokenUrl, setTokenUrl] = useState(ShortcutDefaults.tokenUrl)
  const [isEditing, setIsEditing] = useState(ShortcutDefaults.isEditing)
  return (
    <ShortcutContext.Provider
      value={{ tokenUrl, setTokenUrl, isEditing, setIsEditing }}
    >
      {children}
    </ShortcutContext.Provider>
  )
}

const useShortcutProvider = () => useContext(ShortcutContext)

export { useShortcutProvider, ShortcutProvider }
