import { api } from '.'
import type { ClusterType, ListClusterType } from '../types/clusterTypes'

export const createCluster = ({
  assistantId,
  cluster,
}: {
  assistantId: string
  cluster: ListClusterType
}) => {
  return api
    .post(`v2/knowledge-base-ai/assistant/${assistantId}/cluster`, cluster)
    .then(({ data }) => data.data)
}

export const fetchClusters = async (
  assistantId: string
): Promise<ClusterType[]> => {
  const { data } = await api.get(
    `v2/knowledge-base-ai/assistant/${assistantId}/cluster`
  )
  return data.data
}

export const updateClusters = async ({
  assistantId,
  clusterId,
  cluster,
}: {
  assistantId: string
  clusterId: string
  cluster: ListClusterType
}) => {
  return api.patch(
    `v2/knowledge-base-ai/assistant/${assistantId}/cluster/${clusterId}`,
    cluster
  )
}
