import { Col, Row, Tooltip } from 'antd'
import styled from 'styled-components'
import { PrimaryBtn } from '../../components/StyledComponent'

type ListHeaderProps = {
  title: string
  desc: string
  onAddNewSource?: () => void
  status?: boolean
  isLoading?: boolean
}

export const ListHeader = ({
  title,
  desc,
  onAddNewSource,
  status,
  isLoading = false,
}: ListHeaderProps) => {
  return (
    <Wrapper>
      <Row justify="space-between">
        <Col>
          <div className="header-title">{title}</div>
          <div>{desc}</div>
        </Col>
        {onAddNewSource && (
          <Col className="button-col">
            <Tooltip
              placement="bottom"
              color="var(--color-purple-1)"
              title={
                status &&
                "You can't add a new source until the analysis is complete."
              }
            >
              <PrimaryBtn
                onClick={onAddNewSource}
                disabled={status || isLoading}
              >
                Add new Source
              </PrimaryBtn>
            </Tooltip>
          </Col>
        )}
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 16px;

  .header-title {
    color: var(--color-black-1);
    font-size: 16px;
    font-weight: 600;
  }

  .button-col {
    display: flex;
    align-items: center;
  }
`
