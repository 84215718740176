import { Button, Input, message, Modal, Select, Typography } from 'antd'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { fetchMembersForAdmins } from '../../../api/admins'
import { internalChannel } from '../../../api/misc'
import { useAuth } from '../../../context'
import { getRandomChannelName } from '../../../utils/textUtils'
const { Option } = Select
const { Text } = Typography
export const NewChannelModal = ({
  isNewChannel,
  setIsNewChannel,
}: {
  isNewChannel: boolean
  setIsNewChannel: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [channelName, setChannelName] = useState(getRandomChannelName())
  const { user } = useAuth()
  const [value, setValue] = useState<string[]>([user ? user?.id : ''])

  const handleChange = (e: string[]) => {
    setValue(e)
  }

  const { isLoading: isMemberLoading, data: membersData } = useQuery(
    ['fetchMembersSettings', 0, ''],
    () => fetchMembersForAdmins({ page: 0, search: '' })
  )
  const filterOption = (option: any, inputValue: any) => {
    return (
      (inputValue?.label || '').toLowerCase().includes(option.toLowerCase()) ||
      (inputValue?.key || '').toLowerCase().includes(option.toLowerCase())
    )
  }
  const queryClient = useQueryClient()
  const internalChannelMutation = useMutation(internalChannel, {
    onSuccess: () => {
      queryClient.invalidateQueries(['fetchPublicChannels'])
      queryClient.invalidateQueries(['onboardingChecklist'])

      message.success({
        content: 'A new channel is created successfully!',
      })
      setIsNewChannel(false)
      setValue([])
      setChannelName('')
    },
    onSettled: () => {
      queryClient.invalidateQueries(['fetchPublicChannels'])
    },
  })

  const requestCreateChannelSave = () => {
    internalChannelMutation.mutate({
      channelName: channelName,
      participants: value,
      // save: 'REQUEST_CHANNEL',
      save: '',
    })
  }

  return (
    <Modal
      centered
      footer={null}
      title="Create a new Channel"
      open={isNewChannel}
      width="550px"
      onCancel={() => setIsNewChannel(false)}
    >
      <div className="flex flex-col gap-2">
        {/* <p>
          Create a Slack internal channel to send all your alerts to that
          channel.
        </p> */}
        <div className="w-4/5 flex justify-between">
          <p>Internal channel</p>
          <p>Select participants</p>
        </div>
        <div className="flex justify-between gap-8">
          <Input
            placeholder="Enter channel name"
            className="w-1/2"
            onChange={(e) => setChannelName(e.target.value)}
            value={channelName}
          />
          <Select
            mode="multiple"
            showSearch
            className="w-1/2"
            placeholder="Enter names"
            size="middle"
            optionLabelProp="label"
            optionFilterProp="children"
            value={value}
            onChange={(e) => handleChange(e)}
            filterOption={(input, option) => filterOption(input, option)}
            loading={isMemberLoading}
            maxTagCount="responsive"
          >
            {membersData?.members?.map((item) => (
              <Option value={item.id} label={item.real_name} key={item.email}>
                <div className="flex flex-col">
                  <Text strong style={{ marginBottom: 0 }}>
                    {item.real_name}
                  </Text>
                  <Text>{item.email}</Text>
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <p className="flex justify-end mt-4">
          <Button
            type="primary"
            size="middle"
            className="flex justify-center items-center"
            onClick={requestCreateChannelSave}
            loading={internalChannelMutation.isLoading}
          >
            Create Channel
          </Button>
        </p>
      </div>
    </Modal>
  )
}
