import { Checkbox, message } from 'antd'
import type { CheckboxValueType } from 'antd/es/checkbox/Group'
import { useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import useSources from '../../../../hooks/useSources'
import type { SourceType } from '../../../../types'
import { UpdateResourceModal } from '../Modals/UpdateResourceModal'
import { PrimaryBtn } from '../StyledComponent'

export const SourceExpanded = ({ source }: { source: SourceType }) => {
  const [checkedPages, setCheckedPages] = useState(
    source.resources?.filter((item) => item.isActive).map((item) => item._id)
  )
  const [updateResourceModal, setUpdateResourceModal] = useState(false)
  const onChange = (checkedValues: CheckboxValueType[]) => {
    setCheckedPages(checkedValues as string[])
    source.resources?.map((item) => {
      if (checkedValues.includes(item._id)) {
        return { ...item, isActive: true }
      }
      return item
    })
  }
  const { id = '' } = useParams()
  const onUpdateResourceSuccess = () => {
    setUpdateResourceModal(false)
    message.success('Successfully updated!')
  }
  const onUpdateResourceError = () => {
    message.error('Something went wrong!')
  }

  const {
    updateResources,
    updateStatus: { isLoading },
  } = useSources({
    onUpdateResourceSuccess,
    onUpdateResourceError,
    assistantId: id,
  })

  const handleResources = () => {
    setUpdateResourceModal(true)
  }

  const updateResourceData = (checked = false) => {
    const sourceId = source._id
    const ids = source.resources
      ?.filter((item) => !checkedPages?.includes(item._id))
      .map((item) => item._id)
    updateResources(sourceId, {
      resourceIds: ids,
      isActive: false,
      verifiedQuestion: checked,
    })
  }
  return (
    <Wrapper>
      <div style={{ marginBottom: '12px' }}>
        You can selectively activate or deactivate specific sources, ensuring
        the assistant uses the most relevant and current data for responding to
        queries
      </div>
      <Checkbox.Group onChange={onChange} value={checkedPages}>
        {source.resources?.map((data) => (
          <div key={data._id}>
            <Checkbox value={data._id} disabled={!data.isActive}>
              {source.urlOrigin}
              {data.urlPath}
            </Checkbox>
          </div>
        ))}
      </Checkbox.Group>
      <div style={{ marginTop: 16 }}>
        <PrimaryBtn onClick={handleResources}>Update Changes</PrimaryBtn>
      </div>
      <UpdateResourceModal
        onOk={updateResourceData}
        isOpen={updateResourceModal}
        onCancel={() => {
          setUpdateResourceModal(false)
        }}
        isSaving={isLoading}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid var(--color-gray-3);
  padding: 16px;
  margin-bottom: 16px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-purple-1);
    border-color: var(--color-purple-1);
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: var(--color-purple-1);
  }
`
