import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useGlobalStorePersist } from '../store/globalStorePersist'

const useRedirects = () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const isAddNewWorkspaceFlow = useGlobalStorePersist(
    (state) => state.isAddNewWorkspaceFlow
  )

  useEffect(() => {
    if (pathname + search !== '/settings?tab=team' && isAddNewWorkspaceFlow) {
      navigate('/settings?tab=team')
      useGlobalStorePersist.dispatch({
        type: 'SET_IS_ADD_NEW_WORKSPACE_FLOW',
        payload: {
          isAddNewWorkspaceFlow: false,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNewWorkspaceFlow, pathname, search])

  useEffect(() => {
    if (pathname + search === '/settings?tab=web-to-slack') {
      navigate('/settings?tab=web-chat', { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search])
}
export default useRedirects
