import { Empty } from 'antd'
import styled from 'styled-components'
import NoListImg from '../../../../images/assets/no_list.svg'

export const NoList = ({ data }: { data: string }) => {
  return (
    <CustomEmpty
      image={<img src={NoListImg} alt="No Source" />}
      imageStyle={{ marginTop: '100px' }}
      description={
        <span className="empty-desc">You don't have {data} yet.</span>
      }
    />
  )
}

const CustomEmpty = styled(Empty)`
  .empty-desc {
    font-size: 16px;
    color: var(--color-black-1);
  }
`
